import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField
} from "@material-ui/core";
import NavDataContext from "../../../../contexts/NavDataContext";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Toast } from "bootstrap";

function AddNewMoveWorkitem() {
  const location = useLocation();
  const { addToast } = useToasts();
  const processingDashboardParam = location.state;
  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;
  const selectedWorkItemDetails = processingDashboardParam.selectedWorkItemDetails;
  let currentAcitvity = "";
  if (typeof processingDashboardParam.row.activityName !== "undefined") {
      currentAcitvity = processingDashboardParam.row.activityName;
  } else {
      currentAcitvity = processingDashboardParam.currentAcitvity;
  }
  let navData = [];  
  let row = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
      navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
      navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  let defaultFilter =
    "wi.UploadDate between dateadd(day,-10,getdate()) and getdate()";
  let assignedWorkItemDetails = React.useContext(NavDataContext);
  let selectedAssignWorkitem = {};
  const { React_APP_FetchWorkItemFieldsForChangeConfig } = process.env;
  const {React_APP_UpdateSubProcessChangeConfig}=process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { React_APP_GetDestinationWorkitemFields } = process.env;
  const {React_APP_FetchAllProcessSubProcessByGuid} =process.env;
  const currentPage = window.location.href;
  const [isLoading, set_IsLoading] = useState(false);
  const [WorkitemFields, setWorkitemFields] = useState([]);
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [SubprocessProcess, setSubprocessProcess] = useState("");
  const [ddlvalues, setddlValues] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [SelectedClientLocation, setSelectedClientLocation] = useState("");
  const assignmentXMLRequest=[];

  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const handlechangeNew =(e)=>{
    if(e.target.value==sessionStorage.getItem("oldID")){
      addToast("Please select different Destination Process-SubProcess", {
        appearance: "error",
        autoDismiss: true,
      });
    }else{
      sessionStorage.setItem("newID",e.target.value);
      FetchWorkitemFields();
    }
  }

  const FetchWorkitemFields = () => {
    getDropDownValues();
    getWorkitemFields();
  }

  const getDropDownValues = () => {
    let APIURL = getAPIURL(React_APP_GetDestinationWorkitemFields);
    let inputbody = {
      oldSubProcessID: sessionStorage.getItem("oldID"),
      newSubProcessID: sessionStorage.getItem("newID"),
    };
    let req = new Request(`${APIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(inputbody),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setddlValues(result.data);
        } else if (result.status === 401) {
          setddlValues(result.data);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          setddlValues(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }

  const handleSave = () => {
    WorkitemFields.forEach(
        (element) => {
            if (element.isselected) {
                const mappedinput=element.mapWorkitemFieldID.split("~")[0];
                const input = {
                    workitemField: "" + element.oldWorkitemFieldID,
                    mapedWorkitemField: "" + mappedinput,
                };
                assignmentXMLRequest.push(input);
            }
        }
    );
    SaveWorkitemdata(assignmentXMLRequest);
  }
  const SaveWorkitemdata=(e)=>{
    let APIURL = getAPIURL(React_APP_UpdateSubProcessChangeConfig);
    let inputbody={
      oldSubProcessID:sessionStorage.getItem("oldID"),
      newSubProcessID:sessionStorage.getItem("newID"),
      selectedWorkitemFields:e,
      subProcessChangeDetailID:0,
      changedBy:sessionStorage.getItem("PersonID"),
    }
    let req= new Request(`${APIURL}`,{
      method:"POST",
      headers:{
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body:JSON.stringify(inputbody),
    });
    fetch(req)
    .then((res)=>res.json())
    .then((result)=>{
      if(result.status===200){
        addToast("Sub-Process Change configuration saved successfully.",{
          appearance:"success",
          autoDismiss:true,
        });
        navigate("/SubProcessMoveWorkitem",{
          state:{
            row: row,
            selectedSubprocess,
            nav,
            userRoles,
            defaultFilter,
            assignedWorkItemDetails: selectedAssignWorkitem
          }
        });
      }else if(result.status===401){
        addToast("Session Expired/UnAuthorized. Please LogIn Again",{
          appearance:"error",
          autoDismiss:true,
        });
      }else{
        addToast("Error in saving Sub-Process Change configuration.",{
          appearance:"error",
          autoDismiss:true,
        });
      }
    })
  }

  const getWorkitemFields = () => {
    let APIURL = getAPIURL(React_APP_FetchWorkItemFieldsForChangeConfig);
    let inputbody = {
      oldSubProcessID: sessionStorage.getItem("oldID"),
      newSubProcessID: sessionStorage.getItem("newID"),
    };
    let req = new Request(`${APIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(inputbody),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setWorkitemFields(result.data);
        } else if (result.status === 401) {
          setWorkitemFields(result.data);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          setWorkitemFields(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }

  const navigate = useNavigate();

  let columns = [
    {
      title: "Select",
      field: "isselected",
      editComponent: (props) => {
        return (
          <input
            type="checkbox"
            checked={props.value}
            onChange={(e) => {
              props.onChange(e.target.checked);
              handleCheckboxChange(props.rowData.oldWorkitemFieldID);
            }}
          />
        );
      },
      render: (rowData) => (
        <>
          <input
            type="checkbox"
            id="chkisselected"
            checked={rowData["isselected"]}
            onChange={() => handleCheckboxChange(rowData.oldWorkitemFieldID)}
          />
        </>
      ), 
      cellStyle: {
        whiteSpace: 'nowrap',
        width: '10%',
      },
    },
    { title: "SubProcess Fields", field: "oldWorkItemField" },
    {
      title: "Destination SubProcess Fields",
      field: "",
      render: rowData => (
        <>
          <select
            className="form-control form-control-sm"
            onChange={e => handleDropdownchange(e, rowData)}
          >
            <option>Select</option>
            {ddlvalues.map((item) => (
              <option value={item.workItemFieldID} selected={item.workItemFieldID==rowData["mapWorkitemFieldID"]}>
                {item.labelText}
              </option>
            ))}
          </select>
        </>
      )
    }
  ];

  const handleDropdownchange=(e,rowData)=>{
    const newDataType=e.target.value.split("~")[1];
    const oldDataType=rowData.oldWorkItemFieldDataType;
    if(newDataType===oldDataType ||e.target.value=="Select"){
      if(e.target.value!="Select"){
        const temp=[...WorkitemFields];
        temp[rowData.tableData.id]["mapWorkitemFieldID"]=e.target.value;
        setWorkitemFields(temp);
      }else{
        const temp=[...WorkitemFields];
        temp[rowData.tableData.id]["mapWorkitemFieldID"]="";
        setWorkitemFields(temp);
      }
    }else{
      addToast("Please Select the appropriate field for the mentioned Workitem Field.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleCheckboxChange = (itemId) => {
    setWorkitemFields(prevList =>
      prevList.map(item => {
        if (item.oldWorkitemFieldID === itemId) {
          return { ...item, isselected: !item.isselected };
        }
        return item;
      })
    );
  };

  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
    }
    setSelectedLocation(e.target.value);
    setSelectedClientLocation(e.target.value.split("/")[1]);
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value.split("/")[1],
    };

    let loginUrl = getAPIURL(React_APP_FetchAllProcessSubProcessByGuid);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
          setprocessSubprocess([]);
        } else {
          setprocessSubprocess([]);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setClientLocations([]);
    }
    if (e.target.value !== "Select") {
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
  };

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(React_APP_FetchAllProcessSubProcessByGuid);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          FetchWorkitemFields();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
  };
  const handlechange = (e) => {
    sessionStorage.setItem("oldID",e.target.value);
  }

  const handleBack = ()=>{
    navigate("/SubProcessMoveWorkitem",{
      state:{
        row:row,
        selectedSubprocess,
        nav,
        userRoles,
        defaultFilter,
        assignedWorkItemDetails: selectedAssignWorkitem
      }
    });
  }

  useEffect(() => {
    if(location.state.flag==1){
      FetchDefaultValueForSelectedSubprocess(
        location.state.stringdepartmentID,
        location.state.stringlocationID,
        location.state.oldID,
      );
      sessionStorage.setItem("oldID",location.state.oldID);
      sessionStorage.setItem("newID",location.state.newID);
    }
    FetchDepartmentbyGUID();
    setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
  }, []);

  return (
    <><Header
      onpreferenceShow={null}
      pageName="Add New Move Workitem"
      timeCaptureActivityName="MoveWorkItem"
    />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="bussinessrules" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="centerSection">
            <div className="pageBody">
              {location.state.flag ? <div className="dashBoxs mb-3">
                <div className="formWrap">
                  <div className="row align-items-end">
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label"> Company Department</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchLocationClientsByGuid}
                          title="Select a Company-Department"
                          disabled
                        >
                          <option>Select</option>
                          {departments.map((department) => (
                            <option
                              value={
                                department.companyDepartment +
                                "/" +
                                department.departmentIDString
                              }
                              selected={
                                department.departmentIDString == companyDeparmentID
                              }
                            >
                              {department.companyDepartment}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Location Client</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchProcessSubProcessByGuid}
                          title="Select a Location-Client"
                          disabled
                        >
                          <option>Select</option>
                          {clientLocations.map((location) => (
                            <option
                              value={
                                location.locationClient +
                                "/" +
                                location.clientIDString
                              }
                              selected={location.clientIDString == locationClientID}
                            >
                              {location.locationClient}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Process SubProcess</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={handlechange}
                          title="Select a Process-SubProcess"
                          disabled
                        >
                          <option>Select</option>
                          {processSubprocess.map((subprocess) => (
                            <option
                              value={subprocess.subProcessIDString}
                              selected={
                                subprocess.subProcessIDString == location.state.oldID
                              }
                            >
                              {subprocess.processSubProcess}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Destination Process SubProcess</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={handlechangeNew}
                          title="Select a Destination Process-SubProcess"
                          disabled
                        >
                          <option >Select</option>
                          {processSubprocess.map((subprocess) => (
                            <option
                              value={subprocess.subProcessIDString}
                              selected={
                                subprocess.subProcessIDString == location.state.newID
                              }
                            >
                              {subprocess.processSubProcess}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : <div className="dashBoxs mb-3">
                <div className="formWrap">
                  <div className="row align-items-end">
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label"> Company Department</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchLocationClientsByGuid}
                          title="Select a Company-Department"
                        >
                          <option selected>Select</option>
                          {departments.map((department) => (
                            <option
                              value={
                                department.companyDepartment +
                                "/" +
                                department.departmentIDString
                              }
                            >
                              {department.companyDepartment}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Location Client</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchProcessSubProcessByGuid}
                          title="Select a Location-Client"
                        >
                          <option selected>Select</option>
                          {clientLocations.map((location) => (
                            <option
                              value={
                                location.locationClient +
                                "/" +
                                location.clientIDString
                              }
                            >
                              {location.locationClient}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Process SubProcess</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={handlechange}
                          title="Select a Process-SubProcess"
                        >
                          <option selected>Select</option>
                          {processSubprocess.map((subprocess) => (
                            <option
                              value={subprocess.subProcessIDString}
                            >
                              {subprocess.processSubProcess}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Destination Process SubProcess</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={handlechangeNew}
                          title="Select a Destination Process-SubProcess"
                        >
                          <option >Select</option>
                          {processSubprocess.map((subprocess) => (
                            <option
                              value={subprocess.subProcessIDString}
                            >
                              {subprocess.processSubProcess}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              <div className="dashBoxs">
                <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                  <div className="row align-items-center pb-1">
                    <div className="col-4">
                      <h6>Add New Move Workitem Config</h6></div>
                  </div>
                </div>
                <div className="tableWrap-body">
                  <table className="table table-borderless MatTable">
                    <MaterialTable
                      localization={{
                        pagination: {
                        },
                        toolbar: {
                          exportCSVName: "Export as Excel",
                          exportPDFName: "Export as pdf"
                        },
                        header: {
                          actions: ""
                        }
                      }}
                      options={{
                        headerStyle: {
                          position: "sticky",
                          top: "-5px"
                        },
                        maxBodyHeight: "420px",
                        showTitle: false,
                        exportButton: false,
                        sorting: true,
                        search: false,
                        selection: false,
                        toolbarButtonAlignment: "left",
                        overflowY: false,
                        headerStyle: {
                          height: 30
                        },
                        rowStyle: {
                          fontFamily: '"Yantramanav", sans-serif',
                          fontSize: "13px"
                        },
                        tableLayout: "auto",
                        headerStyle: {
                          fontFamily: '"Yantramanav", sans-serif',
                          fontWeight: "inherit",
                          fontSize: "14px",
                          textAlign: "left !Important"
                        }
                      }}
                      title="Add New Move WorkItem"
                      columns={columns}
                      icons={tableIcons}
                      data={WorkitemFields}
                      components={{
                        Container: props => (
                          <Paper {...props} elevation={0} />
                        ),
                        Toolbar: props => (
                          <div
                            style={{
                              backgroundColor: "rgb(206 206 206)",
                              fontSize: "0.75em"
                            }}
                          >
                            <MTableToolbar
                              {...props}
                              style={{
                                fontSize: "5px"
                              }}
                            />
                          </div>
                        )
                      }}
                    />
                  </table>
                </div>
                  <div className="dashBoxs-footer " style={{ maxHeight: "25px" }}>
                    <div className="row align-items-center pt-1">
                      <div className="col-4">
                        <div className="d-inline">
                          {(
                            <button
                              type="button"
                              className="btn btn-sm btn-primary ms-2"
                              onClick={() => {
                                handleSave();
                              }}
                              title="Click here to save/update Sub-Process Move Workitem Config"
                            >Save
                            </button>)}
                        </div>
                      <div className="d-inline">
                        {(
                          <button
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                              handleBack();
                            }}
                            title="Click here to view Sub-Process Move Workitem Config List"
                          >Back
                          </button>)}
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default AddNewMoveWorkitem;
import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { FaReply } from "react-icons/fa";

const EmailCompose = ({ email, onSend, onclose }) => {
  const [to, setTo] = useState(email.from.emailAddress.address.toString());
  const [cc, setCc] = useState(
    email.ccRecipients.map((item) => item.emailAddress.address).join(",")
  );
  const [subject, setSubject] = useState(email.subject);
  const [body, setBody] = useState("");

  const handleSend = (e) => {
    const emailData = { to, cc, subject, body };
    onSend(e, emailData);
  };
  const handleCloseSend = (e) => {
    onclose(e);
  };
  return (
    <Container className="mt-4">
      <p>
        {" "}
        <FaReply color="#85C1E9" /> <span className="mx-3">Compose reply</span>
        <button
          className="btn btn-primary btn-sm ms-3"
          title="Click here to send reply"
          onClick={(e) => handleSend(e)}
        >
          Send
        </button>
        <button
          className="btn btn-outline-primary btn-sm ms-3"
          title="Click here to cancel"
          onClick={(e) => handleCloseSend(e)}
        >
          Cancel
        </button>
      </p>{" "}
      <Form>
        <Form.Group as={Row} className="mb-2" controlId="emailTo">
          <Col md={1}>
            <Form.Label>To</Form.Label>
          </Col>
          <Col md={11}>
            <Form.Control
              type="text"
              placeholder="To email"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2" controlId="emailCc">
          <Col md={1}>
            <Form.Label>Cc</Form.Label>
          </Col>
          <Col md={11}>
            <Form.Control
              type="text"
              placeholder="Cc email"
              value={cc}
              onChange={(e) => setCc(e.target.value)}
            />
          </Col>
        </Form.Group>
        {/* <Form.Group as={Row} className="mb-2" controlId="emailSubject">
          <Col sm={2}>
            <Form.Label>Subject</Form.Label>
          </Col>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Email subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Col>
        </Form.Group> */}
        <Form.Group as={Row} className="mb-2" controlId="emailBody">
          <Col md={1}>
            <Form.Label></Form.Label>
          </Col>
          <Col md={11}>
            <Form.Control
              as="textarea"
              rows={10}
              value={body}
              onChange={(e) => setBody(e.target.value.replace(/[<>&]/g,""))}
            />
          </Col>
        </Form.Group>
      </Form>
    </Container>
  );
};

export default EmailCompose;

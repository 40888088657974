import React, { useEffect, useState, useContext } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import FormContext from "../../../../contexts/FormContext";
import { useNavigate, useLocation } from "react-router-dom"; 
import { ToastProvider, useToasts } from "react-toast-notifications";
//import { Table , TableBody , TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material'
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import tableIcons from "../../../../MaterialTableIcons";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Multiselect from 'multiselect-react-dropdown';
import {MenuItem, Paper, Select, TableContainer} from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";

export default function WorkActivityScreenDetailsPage(state) {
  const {REACT_APP_MultiWorkitemFieldValues} = process.env;

  const location = useLocation();
  const [DateFieldType , setDateFieldType] = useState("date");
  const [ editingRowIndex , setEditingRowIndex ] = useState(null);
  const [selectedOptions, setselectedOptions] =useState([]);
  const [ showActions, setshowActions] = useState(true);


  const formatDate = (dateString) => {
    let finalDate = "";
    if (dateString.trim() !== "") {
      const [month, date, year ] = dateString.replace(/\s{2,}/g, " ").trim().split(" ");

      if (date == undefined || year == undefined) {
        finalDate = dateString;
      } else {
        let monthNum =
            new Date(Date.parse(month + " 1, 2012")).getMonth() +
            1;
            let monthName =
            monthNum < 10
              ? "0" + monthNum.toString()
              : monthNum.toString();
              let dateVal =
              date < 10 ? "0" + date.toString() : date.toString();
              finalDate = `${year}-${monthName}-${dateVal}`;
      }
    }
    return finalDate;
  }


 

  

  const textFieldValueInitialization = () => {
    let initialValues = {};
    state.state.regGroup.forEach((group) => {
      const {groupName , screenFields } = group;

      if (screenFields.length > 0 && screenFields[0].isMultiValue) {

      const fieldsWithValuesPresent = screenFields.filter((field) => field.value)

      if (fieldsWithValuesPresent.length > 0 ) {
        initialValues[groupName] = {};

        fieldsWithValuesPresent.forEach((field) => {
          let value = field.value;
          if (field.dataType === 'System.DateTime' && value){
            value = formatDate(value)
          }
          
          initialValues[groupName][field.mapToField] = value;
          field.value='';
        });
      }
      }
    });
    return initialValues;
  }

  
  const [textFieldVaules, settextFieldVaules] = useState({});
  const [listBoxValues , setListBoxValues ] = useState({});

  useEffect(() =>{
    const initialValues = textFieldValueInitialization();
    settextFieldVaules(initialValues);
  }, []);

  const { addToast } = useToasts();
  let navigate = useNavigate();

  const processingDashboardParam = location.state;

  

  let oldElements = state;
  const getTextValue = (e) => {
    settextFieldVaules(e.target.value);
  };
  const hyperlinkOnClick = (controlid) => {
    let url = document.getElementById(controlid).value;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handleChange = useContext(FormContext);

  const handlekeypress = (e) =>{
    if(e.key === "ArrowDown" || e.key === "ArrowUp"){
      e.preventDefault();
    }
  }
  
  const OnDateclick = (e) =>{
    setDateFieldType("text");
  }
  
  const OnMouseOut = (e) =>{
    setDateFieldType("date");
  }

  const handleClearClick = (groupName) => {
    setListBoxValues((prevValues) => {
      const newValues = { ...prevValues };
      if (newValues[groupName]) {
        for (const field in newValues[groupName]) {
          newValues[groupName][field] = [];
        }
      }
      return newValues;
    });
    setselectedOptions([]);
    settextFieldVaules({...textFieldVaules, [groupName]: {},});
    setEditingRowIndex(null);
  }
  const ApplyLastTemplate = (groupName, index) =>{
    
    //let tableDataLength = state.state.tableData[groupName]?.length;
    const rowData = state.state.tableData[groupName][index];
    settextFieldVaules({...textFieldVaules, [groupName]: {...textFieldVaules[groupName], ...rowData,}});
    //setEditingRowIndex(rowIndex);
    let data = state.state.regGroup;
    let result = [];
    data.forEach(group=> {
      if (group.groupName === groupName){
        group.screenFields.forEach(field=> {
          if (field.uiControlType.includes("ListBox")) {
            result.push(field.mapToField);
          }
        })
      }
      return result;
    })
    
    let rowDataListBox = {};
    result.forEach(field => {
      
      if(rowData.hasOwnProperty(field)) {
        rowDataListBox[field] = rowData[field];
      }
      return rowDataListBox
    })
    
    setListBoxValues(convertToGroupFormat(rowDataListBox, groupName));
    
  }

  const handleEditClick = (groupName, rowIndex) => {
    
    const rowData = state.state.tableData[groupName][rowIndex];
    settextFieldVaules({...textFieldVaules, [groupName]: {...textFieldVaules[groupName], ...rowData,}});
    setEditingRowIndex(rowIndex);
    let data = state.state.regGroup;
    let result = [];
    data.forEach(group=> {
      if (group.groupName === groupName){
        group.screenFields.forEach(field=> {
          if (field.uiControlType.includes("ListBox")) {
            result.push(field.mapToField);
          }
        })
      }
      return result;
    })
    
    let rowDataListBox = {};
    result.forEach(field => {
      if(rowData.hasOwnProperty(field)) {
        rowDataListBox[field] = rowData[field];
      }
      return rowDataListBox
    })
    
    setListBoxValues(convertToGroupFormat(rowDataListBox, groupName));
    
  }
  const convertToGroupFormat = (rowDataListBox, groupName) => {
    
    const result = {};
    result[groupName] = {};
    Object.keys(rowDataListBox).forEach(field => {
      const values = rowDataListBox[field].split(',').map(value=> value.trim()).filter(value=>value !== "");
      const uniqueValues = [...new Set(values)];
      if (uniqueValues.length > 0) {
      result[groupName][field] = uniqueValues.map(value => ({
        name: value,
        value: value
      }));
    }
    });
    return result;
  }

  const handleSelect = (selectedList,fieldName, groupName,isReadOnly)=>{
    setselectedOptions(selectedList);
    setListBoxValues((prevValues) => ({
      ...prevValues,
      [groupName]: {
        ...prevValues[groupName],
        [fieldName]: selectedList
      }
    }));
    let selectedListString = selectedList.map(item=>item.value).join(",");
    handleInputChange(selectedListString,fieldName, groupName,isReadOnly);
  }
  const handleRemove = (selectedList,fieldName, groupName,isReadOnly)=>{
    setselectedOptions(selectedList);
    setListBoxValues((prevValues) => ({
      ...prevValues,
      [groupName]: {
        ...prevValues[groupName],
        [fieldName]: selectedList
      }
    }));
    let selectedListString = selectedList.map(item=>item.value).join(",");
    // if(selectedListString!="")
    // {
    //   handleInputChange(selectedListString,fieldName, groupName,isReadOnly);
    // }
    handleInputChange(selectedListString,fieldName, groupName,isReadOnly);
  }

  const handleInputChange = (event , fieldName, groupName, isReadOnly) => {
    let newValue = "";
    const updatedRegGroup = [...state.state.regGroup];

    const targetGroupIndex = updatedRegGroup.findIndex((group) => group.groupName===groupName);

    if (targetGroupIndex !== -1) {
      const targetGroup = updatedRegGroup[targetGroupIndex];
      const targetFieldIndex = targetGroup.screenFields.findIndex((field) => field.mapToField === fieldName);
      // alert(isReadOnly);
      if(isReadOnly==false)
      {
        if (targetFieldIndex !== -1) {
          if (targetGroup.screenFields[targetFieldIndex].uiControlType.includes("ListBox")) {
            if(!targetGroup.screenFields[0].isMultiValue){ 
              let selectedValues = Array.from(
                event.target.selectedOptions,
                option => option.value
              );
              newValue=selectedValues.join(",");
            }else{
              newValue = event;
            }
            // targetGroup.screenFields[targetFieldIndex].value = newValue;
          }else{
            newValue=event.target.value;
          }
          if (!targetGroup.screenFields[0].isMultiValue) {
          targetGroup.screenFields[targetFieldIndex].value = newValue;
        } else {
          targetGroup.screenFields[targetFieldIndex].value = "";
        }
      }
    }
  }
    settextFieldVaules({ ...textFieldVaules, [groupName]: {...textFieldVaules[groupName], [fieldName]: newValue},})
  };

  const handleDeleteClick = (groupName , rowIndex) => {

    const confirmation = window.confirm("Are you sure you want to delete this row?");

    if (confirmation) {
    const updatedTableData = {...state.state.tableData};
    if (updatedTableData[groupName]) {
      updatedTableData[groupName].splice(rowIndex, 1);
      state.state.settableData(updatedTableData);
    }
    handleClearClick(groupName);
        addToast("Row deleted successfully", {
          appearance: "success",
          autoDismiss: true,
    });
  }
};






  useEffect(() => {
    dataRetrieving();
    //setPredefinedValues();
  }, [])

  const transformDataStructure = (editTableData) => {
    
    const dataTablestructure = {};
    editTableData.forEach((entry) => {
      const {groupName, mapToField, fieldValues, rowNo} = entry;

      if (!dataTablestructure[groupName]) {
        dataTablestructure[groupName] = [];
      }

      while (dataTablestructure[groupName].length < rowNo) {
        dataTablestructure[groupName].push({});
      }
      dataTablestructure[groupName][rowNo-1][mapToField] = fieldValues;
    })
    return dataTablestructure;
  }

  const dataRetrieving = () => {
    
    let updatedTableData = {};
    if (state.state.editTableData ) {
      updatedTableData = transformDataStructure(state.state.editTableData);
    } else if (state.state.tableData) {
      updatedTableData= state.state.tableData;
    }
    state.state.settableData(updatedTableData);
  }
  const handleAddClick = (groupName) => {
    const updatedTableData = {...state.state.tableData};
    const group = state.state.regGroup.find(g => g.groupName === groupName);
    const requiredFields = group.screenFields.filter(field => field.required)
    const allRequiredFields = requiredFields.every(field => textFieldVaules[groupName]?.[field.mapToField]);
    if (!allRequiredFields) {
      alert ('Please fill out all required fields');
      return;
    }

    const emptyFieldsCheck = Object.entries(textFieldVaules[groupName] || {}).filter(([key]) => key !== 'tableData').every(([, value]) => value === '');
    //const emptyFieldsCheck = Object.values(textFieldVaules[groupName] || {}).every(value => value === '');

    if (emptyFieldsCheck) {
      alert('Cannot add a row with all blank values');
      return;
    }
    if (!updatedTableData[groupName] ) {
      updatedTableData[groupName] = [];
    }
    if (editingRowIndex != null) {
      updatedTableData[groupName][editingRowIndex] = {...textFieldVaules[groupName]};
      setEditingRowIndex(null);
    }
    else {
      updatedTableData[groupName].push({...textFieldVaules[groupName]});
    }
    state.state.settableData(updatedTableData);
    settextFieldVaules({...textFieldVaules, [groupName]: {},});
    handleClearClick(groupName);
  };

  return (
    <>
      <form className="form-inputs">
        {state.state.regGroup.map((data, index) => {
          
          const isMultiValue = data.screenFields[0].isMultiValue;
          const isEdit = data.screenFields[0].isEditable;
          let groupName = data.groupName;
          return (
            <div className="fieldSet mb-2" key={index}>
              <h4 className="regGroup-title mb-2">{data.groupName}</h4>
              <div className="row">
                {data.screenFields.map((values) => {
                  let bgcolor = values.setColor;
                  if (( isMultiValue && isEdit ) || (!isMultiValue)) {
                  if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("String")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                        <div style={{ width: "94%",float : "left" }}>
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>
                          </div>
                          <input
                            className="form-check-input"
                            // onChange={checkHandler}
                            // checked={isChecked}
                            // id="chkInternalUserNotes"
                            value={!values.isSearch}
                            id={"chk"+values.mapToField}
                            hidden={!values.isSearch}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange.onchange(
                                "chk"+values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                          <input
                            className="form-control  form-control-xs"
                            type="text"
                            //name={values.}
                            //pattern="\[A-za-z0–9_]\"
                            required={values.required}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={textFieldVaules[data.groupName]?.[values.mapToField] || values.value  || ""}
                            title={values.value}
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            // onChange={(event) =>
                            //   handleChange.onchange(
                            //     values.mapToField,
                            //     event,
                            //     oldElements
                            //   )
                            // }
                            //value={textFieldVaules}
                            onChange={(event) => handleInputChange(event, values.mapToField, data.groupName,values.isReadOnly)}
                            id={values.mapToField}
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("Double")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                        <div style={{ width: "94%",float : "left" }}>
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>
                          </div>
                          <input
                            className="form-check-input"
                            // onChange={checkHandler}
                            // checked={isChecked}
                            // id="chkInternalUserNotes"
                            value={!values.isSearch}
                            id={"chk"+values.mapToField}
                            hidden={!values.isSearch}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange.onchange(
                                "chk"+values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                          <input
                            className="form-control  form-control-xs"
                            type="number"
                            pattern="[0-9]+([,\.][0-9]+)?"
                            //name={values.}
                            required={values.required}
                            onKeyDown= {handlekeypress}
                            onKeyUp= {handlekeypress}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={textFieldVaules[data.groupName]?.[values.mapToField] || values.value  || ""}
                            title ={values.value}
                            // value={
                            //     values.value == ""
                            //         ? 0.0
                            //         : parseFloat(values.value).toFixed(2)
                            // }
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            // onChange={(event) =>
                            //   handleChange.onchange(
                            //     values.mapToField,
                            //     event,
                            //     oldElements
                            //   )
                            // }
                            onChange={(event) => handleInputChange(event, values.mapToField, data.groupName,values.isReadOnly)}
                            step="0.25"
                            
                            id={values.mapToField}
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("Float")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                        <div style={{ width: "94%",float : "left" }}>
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>
                          </div>
                          <input
                            className="form-check-input"
                            value={!values.isSearch}
                            id={"chk"+values.mapToField}
                            hidden={!values.isSearch}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange.onchange(
                                "chk"+values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                          <input
                            className="form-control  form-control-xs"
                            type="number"
                            pattern="/^[-+]?[0-9]+\. [0-9]+$/"
                            required={values.required}
                            onKeyDown= {handlekeypress}
                            onKeyUp= {handlekeypress}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={textFieldVaules[data.groupName]?.[values.mapToField] || values.value  || ""}
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) => handleInputChange(event, values.mapToField, data.groupName,values.isReadOnly)}
                            
                            //value={textFieldVaules}
                            id={values.mapToField}
                            step="0.25"
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("Int")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                        <div style={{ width: "94%",float : "left" }}>
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>
                          </div>
                          <input
                            className="form-check-input"
                            // onChange={checkHandler}
                            // checked={isChecked}
                            // id="chkInternalUserNotes"
                            value={!values.isSearch}
                            id={"chk"+values.mapToField}
                            hidden={!values.isSearch}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange.onchange(
                                "chk"+values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                          <input
                            className="form-control  form-control-xs"
                            type="number"
                            pattern="/^[0-9]*$/"
                            //name={values.}
                            title={values.value}
                            required={values.required}
                            onKeyDown= {handlekeypress}
                            onKeyUp= {handlekeypress}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={textFieldVaules[data.groupName]?.[values.mapToField] || values.value  || ""}
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) => handleInputChange(event, values.mapToField, data.groupName,values.isReadOnly)}
                            
                            //value={textFieldVaules}
                            id={values.mapToField}
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (values.uiControlType.includes("HtmlTextArea")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                        <div style={{ width: "94%",float : "left" }}>
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>
                          </div>
                          <input
                            className="form-check-input"
                            // onChange={checkHandler}
                            // checked={isChecked}
                            // id="chkInternalUserNotes"
                            value={!values.isSearch}
                            id={"chk"+values.mapToField}
                            hidden={!values.isSearch}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange.onchange(
                                "chk"+values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                          <textarea
                            className="form-control  form-control-xs"
                            //name={values.}
                            required={values.required}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={textFieldVaules[data.groupName]?.[values.mapToField] || values.value || ""}
                            readOnly={values.isReadOnly}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            // onChange={(event) =>
                            //   handleChange.onchange(
                            //     values.mapToField,
                            //     event,
                            //     oldElements
                            //   )
                            // }
                            onChange={(event) => handleInputChange(event, values.mapToField, data.groupName,values.isReadOnly)}
                          />
                        </div>
                      </div>
                    );
                  } else if (values.uiControlType.includes("HyperLink")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                        <div style={{ width: "94%",float : "left" }}>
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>
                        </div>
                          <input
                            className="form-check-input"
                            // onChange={checkHandler}
                            // checked={isChecked}
                            // id="chkInternalUserNotes"
                            value={!values.isSearch}
                            id={"chk"+values.mapToField}
                            hidden={!values.isSearch}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange.onchange(
                                "chk"+values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                          <input
                            className="form-control  form-control-xs"
                            type="text"
                            //name={values.}
                            required={values.required}
                            maxLength={values.maxLen}
                            value={values.value}
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            //value={textFieldVaules}
                            id={values.mapToField}
                          ></input>
                          <a
                            href="javascript:void(0)"
                            className="ahyperlink"
                            onClick={() => hyperlinkOnClick(values.mapToField)}
                          >
                            Click here to view.
                          </a>
                        </div>
                      </div>
                    );
                  } else if (
                    // values.uiControlType.includes("EditorControls") &&
                    values.dataType.includes("DateTime")
                  ) {
                    let finalDate = "";
                    if (values.value?.trim() != "" && values.value?.trim() != undefined) {
                      const [month, date, year, time] = values.value
                        .replace(/\s{2,}/g, " ")
                        .trim()
                        .split(" ");
                      if (date == undefined || year == undefined) {
                        finalDate = values.value;
                      } else {
                        let monthNum =
                          new Date(Date.parse(month + " 1, 2012")).getMonth() +
                          1;

                        let monthName =
                          monthNum < 10
                            ? "0" + monthNum.toString()
                            : monthNum.toString();
                        let dateVal =
                          date < 10 ? "0" + date.toString() : date.toString();
                        finalDate = year.concat("-", monthName, "-", dateVal);
                      }
                    }
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                        <div style={{ width: "94%",float : "left" }}>
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>
                        </div>
                          <input
                            className="form-check-input"
                            // onChange={checkHandler}
                            // checked={isChecked}
                            // id="chkInternalUserNotes"
                            value={!values.isSearch}
                            id={"chk"+values.mapToField}
                            hidden={!values.isSearch}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange.onchange(
                                "chk"+values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                            <input
                              type={DateFieldType}
                              min={values.isAllowPastDate === false ? new Date().toISOString().split('T')[0] : ""}
                              className="form-control form-control-xs"
                              name={values.name}
                              value={textFieldVaules[data.groupName]?.[values.mapToField]  || finalDate || ""}
                              readOnly={values.isReadOnly}
                              id={values.mapToField}
                              style={{ "background-color": bgcolor }}
                              //onClick={OnDateclick}
                              onDoubleClick={OnDateclick}
                              onMouseOut={OnMouseOut}
                              // onChange={(event) =>
                              //   handleChange.onchange(
                              //     values.mapToField,
                              //     event,
                              //     oldElements
                              //   )
                              // }
                              onChange={(event) => handleInputChange(event, values.mapToField, data.groupName,values.isReadOnly)}
                            />
                        </div>
                      </div>
                    );
                  }
                  //  else if (
                  //   values.uiControlType.includes("WebSchedule") &&
                  //   values.dataType.includes("DateTime")
                  // ) {
                  //   let finalDate = "";
                  //   if (values.value.trim() != "") {
                  //     const [month, date, year, time] = values.value
                  //       .replace(/\s{2,}/g, " ")
                  //       .trim()
                  //       .split(" ");
                  //     if (date == undefined || year == undefined) {
                  //       finalDate = values.value;
                  //     } else {
                  //       let monthNum =
                  //         new Date(Date.parse(month + " 1, 2012")).getMonth() +
                  //         1;

                  //       let monthName =
                  //         monthNum < 10
                  //           ? "0" + monthNum.toString()
                  //           : monthNum.toString();
                  //       let dateVal =
                  //         date < 10 ? "0" + date.toString() : date.toString();
                  //       finalDate = year.concat("-", monthName, "-", dateVal);
                  //     }
                  //   }
                  //   return (
                  //     <div className={state.state.fieldClassName}>
                  //       <div className="pb-3">
                  //         <label className="form-label">
                  //           {values.labelText}
                  //         </label>
                  //         {values.required ? (
                  //           <span id="" className="mandatory">
                  //             *
                  //           </span>
                  //         ) : null}

                  //         <input
                  //           type="date"
                  //           className="form-control form-control-xs"
                  //           name={values.name}
                  //           value={finalDate}
                  //           readOnly={values.isReadOnly}
                  //           id={values.mapToField}
                  //           style={{ "background-color": bgcolor }}
                  //           onChange={(event) =>
                  //             handleChange.onchange(
                  //               values.mapToField,
                  //               event,
                  //               oldElements
                  //             )
                  //           }
                  //         />
                  //       </div>
                  //     </div>
                  //   );
                  // }
                  else if (values.uiControlType.includes("DropDownList")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                        <div style={{ width: "94%",float : "left" }}>
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>
                        </div>
                          <input
                            className="form-check-input"
                            // onChange={checkHandler}
                            // checked={isChecked}
                            // id="chkInternalUserNotes"
                            value={!values.isSearch}
                            id={"chk"+values.mapToField}
                            hidden={!values.isSearch}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange.onchange(
                                "chk"+values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                          <select
                            className="form-control form-control-xs "
                            title={values.value}
                            onmouseenter = ""
                            disabled={values.isReadOnly}
                            required={values.required}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            //defaultValue={values.workItemDropdownValues[1]}
                            //value={values.workItemDropdownValues[0]}
                            // onChange={(event) =>
                            //   handleChange.onchange(
                            //     values.mapToField,
                            //     event,
                            //     oldElements
                            //   )
                            // }
                            //onChange={(event) => handleInputChange(event, values.mapToField)}
                            value={textFieldVaules[data.groupName]?.[values.mapToField] || values.value ||  ""}
                            onChange={(event) => handleInputChange(event, values.mapToField, data.groupName,values.isReadOnly)}
                          >
                            <option>{"Select"}</option>
                            {values.workItemDropdownValues.map((reason, i) => (
                              <option 
                                selected={values.value === reason}
                                value={reason}
                                key={i}
                                title = {reason}
                              >
                                {reason}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    );
                  } else if (values.uiControlType.includes("ListBox")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                        <div style={{ width: "94%",float : "left" }}>
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>
                        </div>
                          <input
                            className="form-check-input"
                            // onChange={checkHandler}
                            // checked={isChecked}
                            // id="chkInternalUserNotes"
                            value={!values.isSearch}
                            id={"chk"+values.mapToField}
                            hidden={!values.isSearch}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange.onchange(
                                "chk"+values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                          {values.isMultiValue ?
                            <Multiselect id="MultiSelectSubProcessId" showCheckbox="true"
                              style={{ zIndex: 100 }}
                              options={values.workItemDropdownValues.map((reason) =>
                              (
                                {
                                  name: reason,
                                  value: reason
                                }

                              ))}
                              avoidHighlightFirstOption
                              displayValue="name"
                              // ref={multiselectRef}
                              onSelect={(selectedList, selectedItem, event) => handleSelect(selectedList, values.mapToField, groupName,values.isReadOnly)}
                              selectedValues={listBoxValues?.[data.groupName]?.[values.mapToField]}
                              // selectedValues={null}
                              onRemove={(selectedList, selectedItem, event) => handleRemove(selectedList, values.mapToField, groupName,values.isReadOnly)}
                              // onRemove={onRemove}
                              placeholder="Select Options"
                            /> :
                          <select
                            multiple
                            className="form-control form-control-xs"
                            title=""
                            disabled={values.isReadOnly}
                            required={values.required}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            //defaultValue={values.workItemDropdownValues[1]}
                            //value={values.workItemDropdownValues[0]}
                            // onChange={
                            //   (event) =>
                            //     handleChange.onchange(
                            //       values.mapToField,
                            //       event,
                            //       oldElements
                            //     )
                            //   /* event => valueChange(event) */
                            // }
                            onChange={(event) => handleInputChange(event, values.mapToField, data.groupName,values.isReadOnly)}
                            // value={textFieldVaules[data.groupName]?.[values.mapToField] || values.value || ""}
                          >
                            {values.workItemDropdownValues.map((reason, i) => (
                              <option
                                selected={
                                  values.value != ""
                                    ? values.value.split(",").includes(reason)
                                    : null
                                }
                                value={reason}
                                key={i}
                              >
                                {reason}
                              </option>
                            ))}
                          </select>}
                        </div>
                      </div>
                    );}
                  }
                })}
              </div>

              {isMultiValue && data.screenFields[0].isEditable && (
              <button type="button" className="btn btn-primary buttonMarginAS"
              onClick={() => handleAddClick(groupName)}
              >
                {editingRowIndex != null ? "Update" : "Add"}</button>
            ) }
            {isMultiValue && data.screenFields[0].isEditable && (
              <button type="button" className="btn btn-primary buttonMarginAS"
              onClick={() => handleClearClick(groupName)}
              >Clear</button>
            ) }
            <div className="dynamicTableStyle">
            <table className="table table-borderless MatTable"
              >
              {isMultiValue && (
                  <MaterialTable
                  localization={{
                    pagination: {
                      labelRowsPerPage: "{50, 100, 250,500}",
                    },
                    toolbar: {
                      exportCSVName: "Export as Excel",
                      exportPDFName: "Export as pdf",
                    },
                  }}
                  actions={ [
                    ...(data.screenFields[0].isEditable)?[
                    {
                      icon: () => <EditIcon />,
                      tooltip: "Click here to Edit",
                      position: "row",
                      onClick: (event, rowData) => handleEditClick(groupName, rowData.tableData.id),
                      
                    },
                    {
                      icon: () => <DeleteIcon />,
                      tooltip: "Click here to Delete",
                      position: "row",
                      onClick: (event, rowData) => handleDeleteClick(groupName, rowData.tableData.id),
                    },
                    {
                      icon: () => <FileCopyIcon />,
                      tooltip: "Click here to Copy",
                      position: "row",
                      onClick: (event, rowData) => ApplyLastTemplate(groupName, rowData.tableData.id),
                    },
                    
                  ]: []
                  ]}
                  options={{
                    search: false,
                    overflowY: false,
                    showTitle: false,
                    filtering: true,
                    cellStyle: {
                    },
                    headerStyle: {
                      position: "sticky",
                    },
                    rowStyle: (rowData) =>  ({
                      fontFamily: '"Yantramanav", sans-serif',
                      backgroundColor: rowData.tableData.id === editingRowIndex? '#EEE' : '#FFF',
                    }),
                    selection:false,
                  }}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div>
                        <MTableToolbar {...props} />
                      </div>
                    ),
                  }}
                  
                  icons={tableIcons}
                  columns={data.screenFields.map(field => ({
                    title: field.labelText,
                    field: field.mapToField,
                  }))}
                  data={state.state.tableData[groupName] || []}
                  />
              )}
              </table>
            </div>
            </div>
          );
        })}
      </form>
    </>
  );
}

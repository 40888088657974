import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import DashboardContext from "../../../../contexts/DashboardContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

function ExportConfig(){
    const { addToast } = useToasts();
    const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const {React_APP_ExportConfig} = process.env;
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [departments, setDepartments] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [SelectedClientLocation, setSelectedClientLocation] = useState("");
    let navigate = useNavigate();
    const location = useLocation();
    const processingDashboardParam = location.state;
    const userRoles = processingDashboardParam.userRoles;
    const testRef = useRef(null);
    // const subProcessRef = useRef("");
    const [subProcessID, setSubProcessID] = useState("");
    let navData = [];
    if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
      navData = processingDashboardParam.assignedWorkItemDetails;
    } else {
      navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
    }
    const getAPIURL = (api) => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
      };
      const theme = createTheme({
        palette: {
          primary: {
            main: "#FB4E0B",
          },
          secondary: {
            main: "#FB4E0B",
          },
        },
      });
      useEffect(() => {
        FetchDefaultValueForSelectedSubprocess(
            sessionStorage.getItem("selectedDepartment"),
            sessionStorage.getItem("selectedLocation"),
            sessionStorage.getItem("ProcessId")
        );        
        // subProcessRef.current = sessionStorage.getItem("ProcessId");
        setSubProcessID(sessionStorage.getItem("ProcessId"));
        setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
        FetchDepartmentbyGUID(); 
    }, []);
    
    const handleClear = ()=>{
        setcompanyDeparmentID(n=>"");
        setcompanyDepartment(n=>"");
        setlocationClientID(n=>"");
        setlocationClient(n=>"");
        setprocessSubProcessID(n=>"");
        setprocessSubProcess(n=>"");
        setSubProcessID(n=>"");
        // setDepartments(n=>[]);
        setprocessSubprocess(n=>[]);
        setClientLocations(n=>[]);
    }
    
      const FetchLocationClientsByGuid = (e) => {
        setcompanyDeparmentID("");
        setcompanyDepartment("");
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        if (e.target.value == "Select") {
            setprocessSubprocess([]);
            setClientLocations([]);
        }
        if (e.target.value !== "Select") {
            // sessionStorage.setItem(
            //   "selectedDepartment",
            //   e.target.value.split("/")[1]
            // );
            // sessionStorage.setItem(
            //   "selectedDepartmentName",
            //   e.target.value.split("/")[0]
            // );
            setSelectedDepartment(e.target.value);
            let body = {
                guid: sessionStorage.getItem("GUID"),
                departmentId: e.target.value.split("/")[1],
            };
 
            let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
            let req = new Request(`${loginUrl}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    UserName: sessionStorage.getItem("UserName"),
                    Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                },
                body: JSON.stringify(body),
            });
            fetch(req)
                .then((res) => res.json())
                .then((result) => {
                    if (result.status == 200) {
                        setClientLocations(result.data);
                    } else if (result.status === 401) {
                        //unauthorized
                        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                        sessionStorage.clear();
                        sessionStorage.clear();
                        navigate("/");
                    }
                })
                .catch((error) => {
                    //if (result.status === 401) {
                    console.log("ERROR: ", error);
                    //   navigate("/");
                    // }
                });
        }
    };
 
    const FetchDepartmentbyGUID = () => {
        let body = {
            memberShipGuid: sessionStorage.getItem("GUID"),
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setDepartments(result.data);
                    // if (result.data.length > 1) {
                    //   setUserButtonShow(true);
                    // }
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };
 
    const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            departmentId: departmentId,
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setClientLocations(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //   navigate("/");
                // }
            });
    };
 
    const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: clientId,
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setprocessSubprocess(result.data);
                    //GetActivitiesForSubprocess();
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };
 
    const FetchSubprocessName = (subprocessId, clientId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: subprocessId,
            clientId: clientId,
        };
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    // sessionStorage.setItem(
                    //   "SelctedSubprocessName",
                    //   result.data[0].processSubProcess
                    // );
                    //SubProcessSubmitForTimer();
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };
 
    const FetchDefaultValueForSelectedSubprocess = (
        companyDeparmentID,
        locationClientID,
        processSubProcessID
    ) => {
        setcompanyDeparmentID(companyDeparmentID);
        setSelectedDepartment(companyDeparmentID);
        //sessionStorage.setItem("selectedDepartment", companyDeparmentID);
        FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
        setlocationClientID(locationClientID);
        setSelectedLocation(locationClientID);
        //sessionStorage.setItem("selectedLocation", locationClientID);
        FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
        setprocessSubProcessID(processSubProcessID);
        FetchSubprocessName(processSubProcessID, locationClientID);
    };
 
    const FetchProcessSubProcessByGuid = (e) => {
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        if (e.target.value == "Select") {
            setprocessSubprocess([]);
        }
        setSelectedLocation(e.target.value);
        setSelectedClientLocation(e.target.value.split("/")[1]);
        // sessionStorage.setItem("selectedLocation", e.target.value.split("/")[1]);
        // sessionStorage.setItem(
        //   "selectedLocationName",
        //   e.target.value.split("/")[0]
        // );
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: e.target.value.split("/")[1],
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setprocessSubprocess(result.data);
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                    setprocessSubprocess([]);
                } else {
                    setprocessSubprocess([]);
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //   navigate("/");
                // }
            });
    };
    const handleSubProcess =  (e) => {
        //   subProcessRef.current = e.target.value;
          setSubProcessID(e.target.value)
    }
    const exportConfig = () => { 
        let body ={            
          subProcessID : subProcessID
        };
        let exportConfigUrl = getAPIURL(React_APP_ExportConfig);
        let req = new Request(`${exportConfigUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            // debugger;
            if (result.status == 200) {
                if(subProcessID!=0){
                const blob = new Blob([result.data[0]['downloadXML']], { type: 'application/xml' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'data.xml';
                a.click();
                URL.revokeObjectURL(url);
                }
                else{
                    addToast("All Fields are Required", {
                        appearance: "error",
                        autoDismiss: true,
                      });
                }
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();            
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      const handleExport = () => {
        // debugger;
        exportConfig();
        
      };
    return (
        <>
            <Header onpreferenceShow={null} pageName="Export Configuration" />
            <ApplicationUserContext.Provider
                value={processingDashboardParam.userRoles}
            >
                <DashboardContext.Provider value={processingDashboardParam.nav}>
                    <NavDataContext.Provider value={navData}>
                        <Nav selectedMenu="admin" />
                    </NavDataContext.Provider>
                </DashboardContext.Provider>
            </ApplicationUserContext.Provider>
 
            <div className="centerSection">
                <div className="pageBody">
 
                    <div className="dashBoxs mb-3">
                        <div className="formWrap">
                            <div className="row align-items-end">
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label"> Company Department</label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={FetchLocationClientsByGuid}
                                        >
                                            <option>Select</option>
                                            {departments.map((department) => (
                                                <option
                                                    value={
                                                        department.companyDepartment +
                                                        "/" +
                                                        department.departmentIDString
                                                    }
                                                    selected={
                                                        department.departmentIDString == companyDeparmentID
                                                    }
                                                >
                                                    {department.companyDepartment}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Location Client</label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={FetchProcessSubProcessByGuid}
                                        >
                                            <option>Select</option>
                                            {clientLocations.map((location) => (
                                                <option
                                                    value={
                                                        location.locationClient +
                                                        "/" +
                                                        location.clientIDString
                                                    }
                                                    selected={location.clientIDString == locationClientID}
                                                >
                                                    {location.locationClient}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Process SubProcess</label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={handleSubProcess}
                                            // ref={subProcessRef}
                                        >
                                            <option>Select</option>
                                            {processSubprocess.map((subprocess) => (
                                                <option
                                                    value={subprocess.subProcessIDString}
                                                    selected={
                                                        subprocess.subProcessIDString == processSubProcessID
                                                    }
                                                >
                                                    {subprocess.processSubProcess}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <div className="dashBoxs mb-3">
                        {/* <div className="dashBoxs-header " style={{ maxHeight: "45px" }}> */}
                            <div className="row align-items-center">
                                <div className="col-8">
                                    
                                </div>
                                <div className="col-2">
                                    <div
                                        className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                                        style={{ marginTop: "-10px" }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary ms-2"
                                            onClick={() => {
                                                handleExport();
                                            }}
                                        >
                                            Export
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div
                                        className="d-grid d-sm-flex justify-content-md-start align-items-center gap-2"
                                        style={{ marginTop: "-10px" }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary ms-2"
                                            onClick={() => {
                                                handleClear();
                                            }}
                                        >
                                            Clear
                                        </button>
                                        
                                    </div>
                                {/* </div> */}
 
                            </div>
                        </div>
 
                        
                    </div>
 
                </div>
            </div>
 
            <Footer />
        </>
    );
}

export default ExportConfig;

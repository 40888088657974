import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { useToasts } from "react-toast-notifications";
import MaterialTable, { MTableToolbar } from "material-table";
import { createTheme } from "@material-ui/core/styles";
import tableIcons from "../../../../MaterialTableIcons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Paper } from "@material-ui/core";

function EmailConfiguration() {
  const SubjectRef = useRef(null);
  const TemplateNameRef = useRef(null);
  const RadioButtonRef = useRef(null);
  const ButtonTextRef = useRef("");
  const [buttonText, setbuttonText] = useState("");
  const [numberOfRecord, setnumberOfRecord] = useState(5);
  const [emailConfigID, setEmailConfigID] = useState(0);
  const [viewModal, setviewModal] = useState(false);

  const { addToast } = useToasts();

  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_DELETEEMAILTEMPLATE } = process.env;
  const { REACT_APP_GETEMAILTEMPLATEBYID } = process.env;
  const { REACT_APP_GETEMAILCONFIGURATIONLIST } = process.env;
  const { REACT_APP_GETWORKITEMTOKENSFORMAIL } = process.env;
  const { REACT_APP_ADDEDITEMAILTEMPLATE } = process.env;

  let navigate = useNavigate();
  const location = useLocation();
  const emailConfigurationParam = location.state;
  const nav = emailConfigurationParam.nav;
  const userRoles = emailConfigurationParam.userRoles;

  let navData = [];
  if (emailConfigurationParam.assignedWorkItemDetails.length > 0) {
    navData = emailConfigurationParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const [ExcelColumns,setExcelColumns] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [subject, setSubject] = useState("");
  const [emailContent,setEmailContent] = useState("");
  const [addTokensToSubject, setAddTokensToSubject] = useState("");
  const [checkedValue, setcheckedValue] = useState("3");
  const [prefenceValue, setprefenceValue] = useState(3);
  const getPreference = (e) => {
    setSubject("")
    setcheckedValue(e.target.value);
    setprefenceValue(parseInt(e.target.value));
  };
  const templateNameTextBox = (e) => {
    setTemplateName(e.target.value);
  }
  const addTokensToSubjectDropDown = (e) => {
    let addToken = e.target.value;
    let newSubject = subject+addToken
    setSubject(newSubject);
  }
  const subjectTextBox = (e) => {
    setSubject(e.target.value);
  }
  const [tokenList, setTokenList] = useState([]);
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [searchDivVisible, setsearchDivVisible] = useState(true);
  const [CheckProcessId, setCheckProcessId] = useState("");
  const [CheckActivityId, setCheckActivityId] = useState("");
  const [columns, setcolumns] = useState([]);

  let currentAcitvity = "";
  // debugger;
  if (typeof emailConfigurationParam.row.activityName !== "undefined") {
    currentAcitvity = emailConfigurationParam.row.activityName;
  } else {
    currentAcitvity = emailConfigurationParam.currentAcitvity;
  }
  useEffect(() => {
    // debugger;
    GetEmailConfigurationList();
    GetWorkItemTokensForMail();
  },[]
  );
  const updateColumns = useCallback(
    (keys) => {
      setCheckProcessId(sessionStorage.getItem("ProcessId"));
      setCheckActivityId(sessionStorage.getItem("PageId"));
      // console.log(sessionStorage.getItem("EmployeeVersionID"));
      const workflowstateID = sessionStorage.getItem("workflowstateID");
      const activityId = sessionStorage.getItem("PageId");
      let col = [];
      keys.forEach((item) => {
        //console.log(item)

        if (item === "WorkItemID") {
          col.push({
            title: item,
            field: item,
            // cellStyle: (e, rowData) => {
            //   return { backgroundColor: rowData.BGColor };
            // },
            render: (row) => (
              <>
                <span
                  style={{ backgroundColor: row.BGColor }}
                  class="dot"
                  title="Why this color? Click to see."
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasColor"
                  aria-controls="offcanvasColor"
                ></span>
                <Link
                  to="/ActivityScreen"
                  state={{
                    row,
                    // selectedSubprocess,
                    nav,
                    userRoles,
                    employeeVersionID:
                      sessionStorage.getItem("EmployeeVersionID"),
                    workflowstateID,
                    activityId,
                    assignedWorkItemDetails: navData,
                    workItemID: row[item],
                    currentAcitvity: currentAcitvity,
                  }}
                >
                  {row[item]}
                </Link>
              </>
            ),
            editable: false,
            //filtering: true,
          });
        } else if (
          item === "TotalCount" ||
          item === "PriorityState" ||
          item === "BGColor"
        ) {
          // hide this columns
        } else if (
          item === "OnBaseField" ||
          item === "IsWIP" ||
          item === "CWSOnBaseDocID"
        ) {
          // hide this columns
        } else if (
          item.toLowerCase().includes("amount") ||
          item.toLowerCase().includes("number")
        ) {
          col.push({
            title: item,
            field: item,
            type: Number,
            // cellStyle: (e, rowData) => {
            //   return { backgroundColor: rowData.BGColor };
            // },
          });
        } else {
          col.push({
            title: item,
            field: item,
            // cellStyle: (e, rowData) => {
            //   return { backgroundColor: rowData.BGColor };
            // },
          });
        }
      });
      //col.pop();
      setcolumns(col);
    },
    // [GridColLen]
  );


  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
    const columnsoftable = [
   
   
    {
      title: "Client Name",
      field: "clientName",
    },
    {
      title: "Process Name",
      field: "processName",
    },
    {
      title: "Sub Process Name",
      field: "subProcessName",
    },
    {
      title: "Template Name",
      field: "templateName",
    },
    {
      title: "Applicable For",
      field: "workItemMode",
    },
    {
      title: "Email Subject",
      field: "emailSubject",
    },
    {
      title: "Updated By",
      field: "updatedBy",
    },
  ];
 
  const openModal = () => {
    setviewModal(true);
  };
  const closeModal = () => {
    setviewModal(false);
    setTemplateName("");
    setAddTokensToSubject("");
    setSubject("");
    setEmailContent("");
    setcheckedValue("3");
  };
  // const resetButton = () => {
  //   setTemplateName("");
  //   setAddTokensToSubject("");
  //   setSubject("");
  //   setEmailContent("");
  //   GetWorkItemTokensForMail();
  // }

  const DeleteEmailTemplate = (row) => {
    // debugger;
    if (row.isDeleted) {
      addToast(
        "Email template - " + row.emailTemplateName + " is already deleted.",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      return false;
    }

    if (
      window.confirm(
        "Are you sure you want to delete the Email Template - " +
          row.templateName +
          " ?"
      )
    ) {
      let body = {
        strEmailConfigIds: ''+row.emailConfigId
        // UpdatedBy: sessionStorage.PersonID,
      };
      let loginUrl = getAPIURL(REACT_APP_DELETEEMAILTEMPLATE);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            // debugger;
            addToast("Email Template deleted successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            GetEmailConfigurationList();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };
  
    const GetEmailTemplatebyId = (row) => { 
      // debugger;
    let body = {
         
      intEmailConfigId: row.emailConfigId,
      
       };
     let GetEmailTemplatebyIdURL = getAPIURL(REACT_APP_GETEMAILTEMPLATEBYID);
     let req = new Request(`${GetEmailTemplatebyIdURL}`, {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
         UserName: sessionStorage.getItem("UserName"),
         Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
       },
        body: JSON.stringify(body),
     });
     fetch(req)
     .then((res) => res.json())
     .then((result) => {
       if (result.status == 200) {
        // debugger;
        setEmailConfigID(row.emailConfigId)
        setTemplateName(result.data[0].templateName)
        setSubject(result.data[0].emailSubject)

        setEmailContent(result.data[0].emailBody)
        
        if (result.data[0].workItemMode == "Multiple")
        setcheckedValue("0")
        else
        setcheckedValue("3")

         
       } else if (result.status === 401) {
         //unauthorized
         addToast("Session Expired/UnAuthorized. Please LogIn Again", {
           appearance: "error",
           autoDismiss: true,
         });
         sessionStorage.clear();
         sessionStorage.clear();
         navigate("/");
       }
     })
       .catch((error) => {
         console.log("ERROR: ", error);
       });
   };
   const GetWorkItemTokensForMail = () => {
      
    let body ={
      
      SubProcessID : sessionStorage.getItem("ProcessId"),
      
    };
    let GetWorkItemUrl = getAPIURL(REACT_APP_GETWORKITEMTOKENSFORMAIL);
    let req = new Request(`${GetWorkItemUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // debugger;
          setTokenList(result.data);
          
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  //   }
  // }
  }
  const handleSubmit = (rowData) => {
    if(templateName!="" && subject!=""){
    addEditEmailTemplate(rowData);
    GetEmailConfigurationList();
    closeModal(); 
    }
          // else if (templateName=="" && subject!="" )
          // addToast("Template Name is Required", {
          //   appearance: "error",
          //   autoDismiss: true,
          // });
          // else if (subject=""&& templateName!="" )
          // addToast("Subject is Required", {
          //   appearance: "error",
          //   autoDismiss: true,
          // });
          else
          {addToast("Template Name and Subject are Required", {
            appearance: "error",
            autoDismiss: true,
          });
        }
  }
   const addEditEmailTemplate = () => {
    let editMode = ""
    if (buttonText == "Save")
    editMode = "add"
    else
    editMode = "edit"
    let emailConfig = 0
    if (buttonText == "Update")
    emailConfig = emailConfigID
    else
    emailConfig=0
    let workItemMode = "";
    if (checkedValue == "3")
    workItemMode = "Single"
    else if(checkedValue == "0")
    workItemMode = "Multiple"
    let body ={
          
      EmailConfigId : emailConfig,
      subProcessID : sessionStorage.getItem("ProcessId"),
      strTemplateName: templateName,
      strWorkItemMode: workItemMode,
      strSubject: subject,
      strBody: emailContent,
      strMode: editMode,
      strCurrentUserID: sessionStorage.getItem("PersonID")
    };
    // debugger;
    let AddEditEmailUrl = getAPIURL(REACT_APP_ADDEDITEMAILTEMPLATE);
    let req = new Request(`${AddEditEmailUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // debugger;
          // updateColumns(Object.keys(result.data[0])); 
          addToast("Email Template Saved Successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }else if(result.StatusCode==500 && result.Message==="Record already exists."){
          addToast("Record already exists.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const getDisplayCriterion = (e) => {
    
    GetEmailConfigurationList(e.target.value);
  };

   const GetEmailConfigurationList = (display) => {
    
    setExcelColumns([]);

    
    let filterValue = "";
    let displayFilter = "";
    if (
      display === "3"
    ) {
      filterValue = '0'
      displayFilter = "all";

    } 
    else if (
      display === "1"
    ) {
      let longSubProcessId = Number(sessionStorage.getItem("ProcessId"))
      let longProcessId = (Math.floor(longSubProcessId/10000)*10000)
      filterValue = ""+(longProcessId)
      displayFilter = "process";
    } 
    else if (
      display === "2"
    ) {
      
      filterValue = sessionStorage.getItem("selectedLocation")   
      displayFilter = "client";

    } else {
      
      filterValue = sessionStorage.getItem("ProcessId")  
      displayFilter = "subprocess";

    }

    let body = {
      UserID : sessionStorage.getItem("GUID"), //the type is GUID
      stringFilterKey: displayFilter,
      longFilterValue: filterValue
    }; 
    // debugger;
        let EmailConfigurationListURL = getAPIURL(REACT_APP_GETEMAILCONFIGURATIONLIST);
        let req1 = new Request(`${EmailConfigurationListURL}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req1)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setisLoadingFigma(false);
              updateColumns(Object.keys(result.data[0]));
              setExcelColumns(result.data);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
  
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });

  const [IsFile, setIsFile] = useState(false);

  
  const [isOpen, setIsOpen] = useState(false);
 
 

 


      const NavElements = useMemo(() => {
        
        return (
          <>
            <Header onpreferenceShow={null} pageName="Sub Process Email Template Config" />
            <ApplicationUserContext.Provider
              value={emailConfigurationParam.userRoles}
            >
              <DashboardContext.Provider value={emailConfigurationParam.nav}>
                <NavDataContext.Provider value={navData}>
                  <Nav selectedMenu="Configuration" />
                </NavDataContext.Provider>
              </DashboardContext.Provider>
            </ApplicationUserContext.Provider>
          </>
        );
      }, [navData]);
      
      

    return (
      <>
      {NavElements}
      
      {/* <div className="centerSection"> */}
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
              
          </div>
                <div className="col-4">
                  <div className="mb-3">
                    

                  </div>
                

          </div>

        
          <div className="col-4">
                      {/* <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"> */}
          <div>
          <div className="d-inline">
                          <div className="input-group input-group-sm" >
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ float: 'right' }}
                            >
                              Display Criterion
                            </label>
                            <select
                              className="form-select"
                              id="inputGroupSelect01"
                              // defaultValue = "0"
                              
                              onChange ={getDisplayCriterion}
                              
                            >
                              
                              <option value="0">Sub-Process</option>
                              <option value="1">Process</option>
                              <option value="2">Client</option>
                              {/*<option value="3">All</option>*/}
                            </select>
                          </div>
                        </div>
                        </div>
                        {/* </div> */}
                        
          </div>
         </div>
       </div>
            {searchDivVisible ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Email Template List</h6>
                    {}
                  </div>
                </div>
              </div>
              { (
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          {
                            icon: () => <EditIcon />,
                            tooltip: "Edit Email Template",
                            
                            onClick: (event, rowData) =>
                            
                            {
                              openModal();
                              GetEmailTemplatebyId(rowData);
                              setbuttonText("Update");
                            },
                            position: "row",
                          },
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete Row",
                            onClick: (event, rowData) =>
                              DeleteEmailTemplate(rowData),
                            position: "row",
                          }
                        ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          pageSizeOptions: [5,10, 20, 50, 100],
                          exportButton: false,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,

                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          headerStyle: {
                            // height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            //                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          headerStyle: {
                            // fontFamily: '"Yantramanav", sans-serif',
                            // fontWeight: "inherit",
                            // fontSize: "14px",
                            // textAlign: "left !Important",
                          },
                        }}

                        columns={columnsoftable}
                        icons={tableIcons}
                        data={ExcelColumns}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : null}
            <div className="col-6 text-start mt-4">
                  <button
                              type="button"
                              className="btn btn-sm btn-primary ms-2"
                              onClick={() => {
                                openModal();
                                setbuttonText("Save");
                              }}
                            >
                              <b className="icon-plus"></b>Add New Email Template
                  </button>
          
        </div>
      </div>
      {/* </div> */}
    <Footer />    
    <Modal
        show={viewModal}
        dialogClassName="modal-100w"
        onHide={closeModal}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Email Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <>
      {NavElements}
      <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="tableWrap">
                <div className="tableWrap-body">
                  <table className="table brandTable">
                    <div>
                      <div className="fieldSet pt-1">
              <div className = "form-control form-control-sm">
                <label className="form-label">This email notification will contain details of *</label>
                  <div className="form-check form-check-inline mx-0"
                  defaultValue="3">
                    
                  
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio4"
                              value="3"
                              checked={checkedValue === "3" ?? "yes"}
                              onChange={getPreference}
                              ref={RadioButtonRef}
                              disabled={buttonText == "Update"}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio4"
                             
                            >
                              Single Workitem
                            </label>
                  </div>
                 
                  <div className="form-check form-check-inline ms-4 mx-0">
                            <input
                              className="form-check-input" style={{marginLeft:'55px'}}
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="0"
                              checked={checkedValue === "0" ?? "yes"}
                              onChange={getPreference}
                              ref={RadioButtonRef}
                              disabled={buttonText == "Update"}
                            />
                           
                            <label
                              className="form-check-label"
                              for="inlineRadio1" style={{marginLeft:'5px'}}
                            >
                              Multiple Workitems
                            </label>
                  </div>
                <div className="row align-items-end">
                <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Template Name *</label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          maxLength={50}                                                    
                          value={templateName}
                          ref={TemplateNameRef}
                          onChange={templateNameTextBox}
                          title="Maximum length is 50 characters."
                          
                        />
                      </div>
                </div>
                   
                <div className="col-3">
                    <div className="d-inline">
                          <div className="mb-3">
                            <label className="form-label">Add Tokens to Subject</label>
                            <select
                              className="form-control form-control-sm"
                              disabled={checkedValue === "0"}
                              onChange={addTokensToSubjectDropDown}
                              defaultValue = "-- Select Token --"
                            >
                              <option>-- Select Token --</option>
                              {tokenList.map((token) => (
                        <option
                        selected={
                          token.fieldName = (token.fieldName)
                        }
                          value={
                           token.tokenName
                          }  
                        >
                          {token.fieldName}
                        </option> 
                      ))}
                            </select>                            
                          </div>
                        </div>
                        {/* </div> */}                        
                </div>

                <div className="col-3">
                      <div className="mb-3">                        
                        <label className="form-label">Subject *</label>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          
                          maxLength={255}
                          title="Maximum length is 255 characters."
                          value={subject}
                          ref={SubjectRef}
                          onChange={subjectTextBox}
                        />
                      </div>
                </div>
                </div>
                <div className="formWrap">
              <div className="row align-items-end">
          {/* <div className="col-3"> */}
                       <div className="mb-3">
                       <div className="form-check-label" hidden={checkedValue != "0"}>Note: To include a table containing the Workitem Details, please use the relevant tokens separated by commas.</div>

                       <label className="form-label">Email Content</label>                    
                        <CKEditor
  editor={ClassicEditor}
  // data={inputValue}
  onReady={(editor) => {
    // console.log('CKEditor React Component is ready to use!', editor);
    
    // CKEditorInspector.attach(editor);
  }}
  
  
  config={{
    readOnly: false,
    toolbar: !false
      ? [
          'undo',
          'redo',
          '|',
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          '|',
          'link',
          'unlink',
          '|',
          'outdent',
          'indent',
          '|',
          'fontSize',
          '|',
          'fontFamily',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'removeFormat',
          '|',
          'insertTable',
        ]
        : [],
  }}
  data = {emailContent}
  onChange={(event, editor) => {
    const data = editor.getData();
    // debugger;
    setEmailContent(data);
    //  handleChange(data);
    // console.log({ event, editor, data });
  }}                          
/>
                      </div>
                    </div>
                    {/* </div> */}
                </div>
                        </div>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </ul>
          </div>
        </>
        </Modal.Body>
        <Modal.Footer>
        <div >
                    <button
                        type="submit"
                        className="btn btn-sm btn-primary ms-3"
                        title="Click here to save the Template"
                        ref={ButtonTextRef}
                        position= "row"
                        onClick={(rowData) => handleSubmit(rowData)}
                      >
                        {buttonText}
                      </button>
                      {/* <button
                        type="submit"
                        className="btn btn-sm btn-primary ms-3"
                        title="Click here to reset the Template"
                        ref={ButtonTextRef}
                        onClick={() => {
                          resetButton();
                        }}
                      >
                        Reset
                      </button> */}
                    </div>

        </Modal.Footer>
      </Modal>
       </>
     );
  }

  export default EmailConfiguration;
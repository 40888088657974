import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import FormContext from "../../../../contexts/FormContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import UserClassificationListGridDetailsPage from "./UserClassificationListGridDetailsPage";
import RuleUploadGridDetailsPage from "./RuleUploadGridDetailsPage";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";

import MuiAlert from "@material-ui/lab/Alert";
import EditIcon from "@mui/icons-material/Edit";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function InsertRuleForMovingWorkItem() {
    const { addToast } = useToasts();
    const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
    const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
    const { REACT_APP_API_URL } = process.env;
    const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
    const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;
    const { REACT_APP_GETWORKITEMFIELDSBYSUBPROCESSIDFORCLASSIFICATION } =
    process.env;
    const { REACT_APP_GetCurrentState } = process.env;
    const { REACT_APP_GetNextState } = process.env;
    const {REACT_APP_InsertIntoMoveWorkItemRuleWise} = process.env;
    const {REACT_APP_GetStatusNames } = process.env;
    const { REACT_APP_EDIT_WORKITEMDETAILS } = process.env;
    const { REACT_APP_GetWorkItemStatusDetails } = process.env;
    const { REACT_APP_GetMoveWorkitemTableData } = process.env;
    const { REACT_APP_DeleteMoveWorkitemTableRow } = process.env;

    let navigate = useNavigate();
  const location = useLocation();

  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const [
    WorkItemFieldsbySubProcessIDForClassification,
    setWorkItemFieldsbySubProcessIDForClassification,
  ] = useState([]);
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [Isdepartments, setIsDepartments] = useState(false);
  const [clientLocations, setClientLocations] = useState([]);
  const [IsclientLocations, setIsClientLocations] = useState(false);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [IsprocessSubprocess, setIsprocessSubprocess] = useState(false);
  const [currentStateID, setCurrentStateID] = useState("");
  const [nextStateID, setNextStateID] = useState("");
  const [ActivitiesForSubprocess, setActivitiesForSubprocess] = useState([]);
  const [sendToPreviousStateCheck , setSendToPreviousStateCheck] = useState(0);
  const [currentState, setCurrentSate] = useState([]);
  const [nextState , setNextState] = useState([]);
  const [statusNames, setStatusNames] = useState([]);
  const [statusID , setStatusID] = useState("");
  const [IsActivitiesForSubprocess, setIsActivitiesForSubprocess] =
    useState(false);
    const [statusNameDropDownVisble, setStatusNameDropDownVisble] = useState(false);

    const [JsonValues, setJsonValues] = useState([]);
    const [tableData , setTableData ] = useState([]);

    const [showModalButton, setShowModalButton] = useState(false);
    const [PriortyForSubProcessDetails, setPriortyForSubProcessDetails] =
    useState([]);
    const getAPIURL = (api) => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
        };

    const FetchCurrentStateID = (e) => {
      
      if (e.target.value === 'Select' || e.target.value === ''){
        setStatusNames([]);
        setStatusID('');
        setCurrentStateID('');
        setSendToPreviousStateCheck(0);
        setStatusNameDropDownVisble(true);
      }
    setCurrentStateID(e.target.value);
    setStatusNameDropDownVisble(!!e.target.value);
    
    };



    // const FetctNextStateID = (e) => {
    //   setNextStateID(e.target.value);
    // }

    const FetchDepartmentbyGUID = () => {
        let body = {
          memberShipGuid: sessionStorage.getItem("GUID"),
        };
    
        let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setDepartments(result.data);
              setIsDepartments(true);
              // if (result.data.length > 1) {
              //   setUserButtonShow(true);
              // }
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //navigate("/");
            //}
          });
      };

      const handelChange = (event,dropdownValue, oldElements) => {
        console.log("inside handle change")
        
        var newElements = { ...oldElements};
        newElements.state.WorkItemFieldsbySubProcessIDForClassification.forEach(
          (element) => {
            if (element.maptofield == event.target.id) {
              element.fieldValues = event.target.value;
              element.operator = dropdownValue;
            }
          }
        );
        setJsonValues(newElements);
        
        setWorkItemFieldsbySubProcessIDForClassification(newElements.state.WorkItemFieldsbySubProcessIDForClassification);
      };

      const handleCheckBoxChange = (e) => {
        setSendToPreviousStateCheck(e.target.checked ? 1: 0);
      }

      const InsertIntoMoveWorkItemRuleWise = () => {
        
        if (currentStateID === 'Select' || currentStateID === '' || currentStateID === null) {
          addToast("Please select Current State", {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        // if (nextStateID === 'Select' || nextStateID === '' || nextStateID === null) {
        //   addToast("Please select Next State", {
        //     appearance: "error",
        //     autoDismiss: true,
        //   });
        //   return;
        // }
        let skillSetConfigRequest = [];
        setActivitiesForSubprocess([]);
        console.log(JsonValues);
        JsonValues.state.WorkItemFieldsbySubProcessIDForClassification.forEach(
          (element) => {
            if (element.fieldValues != "") {
              const input = {
                wfid: "" + element.workitemfieldid,
                op: element.operator.replace(/</g , "&lt;").replace(/>/g, "&gt;"),
                val: "" + element.fieldValues,
              };
              skillSetConfigRequest.push(input);
            }
          }
        );
        function generateXML(skillSetConfigRequest) {
          let xml = '<rules>';
          skillSetConfigRequest.forEach((item) => {
            xml += '<rule>';
            xml += `<wfid>${item.wfid}</wfid>`;
            xml += `<op>${item.op}</op>`;
            xml += `<val>${item.val}</val>`;
            xml += '</rule>';
          });
          xml += '</rules>';
          return xml;
        }
        

        let SaveSkillSetConfigRequestXML = generateXML(skillSetConfigRequest);
        if (SaveSkillSetConfigRequestXML === '<rules></rules>'){
          addToast("Please select a rule to insert", {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        debugger;
        let body = {
          subProcessID: sessionStorage.getItem("ProcessId"),
          currentStateID: currentStateID,
          rulesXML: SaveSkillSetConfigRequestXML,
          workItemStatusID: statusID,
          PreviousActivityCheck: sendToPreviousStateCheck?? 0,
          
        };
    
        let loginUrl = getAPIURL(REACT_APP_InsertIntoMoveWorkItemRuleWise);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              
              addToast("Rule Inserted Successfully", {
                appearance: "success",
                autoDismiss: true,
              });
              GetMoveWorkitemTableData();
              // GetSavePriorityDetails(processSubProcessID);
              // setskillsetName("");
              // setActivityId("");
              // GetWorkItemFieldsbySubProcessIDForClassification(processSubProcessID);
              // GetActivitiesForSubprocess(processSubProcessID);
              // //setdropdownValue("Equals")
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };

      const GetWorkItemFieldsbySubProcessIDForClassification = (subProcessID) => {
        //setWorkItemFieldsbySubProcessIDForClassification([])
        let body = {
          subProcessID: subProcessID,
        };
        let getWorkitemFieldUrl = getAPIURL(
          REACT_APP_GETWORKITEMFIELDSBYSUBPROCESSIDFORCLASSIFICATION
        );
        let req = new Request(`${getWorkitemFieldUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setWorkItemFieldsbySubProcessIDForClassification(result.data);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };

      const FetchInvalidProcessSubprocessByGuid = async (e) => {
        if (e.target.value == "Select") {
          setShowModalButton(false);
          setActivitiesForSubprocess([]);
          setPriortyForSubProcessDetails([]);
          setIsprocessSubprocess(false);
          setIsActivitiesForSubprocess(false);
        }
        if (e.target.value !== "Select") {
          setShowModalButton(true);
          setIsprocessSubprocess(true);
          setSelectedSubprocess(e.target.value.split("/")[0]);
          GetWorkItemFieldsbySubProcessIDForClassification(
            e.target.value.split("/")[0]
          );
          //GetSavePriorityDetails(e.target.value.split("/")[0]);
          GetCurrentStates();
          GetNextStates();
        }
      };
      
      const GetCurrentStates = () => {
        let body = {
          subProcessID: sessionStorage.getItem("ProcessId"),
        };
        let activityUrl = getAPIURL(REACT_APP_GetCurrentState);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setCurrentSate(result.data);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      const GetNextStates = () => {
        let body = {
          subProcessID: sessionStorage.getItem("ProcessId"),
        };
        let activityUrl = getAPIURL(REACT_APP_GetNextState);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setNextState(result.data);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      
      const handleStatusChange = (e) => {
        
        if (e.target.value === 'Select' || e.target.value === ''){
          //setStatusNames([]);
          setStatusID('');
          // setCurrentStateID('');
           //setSendToPreviousStateCheck(1);
          // setStatusNameDropDownVisble(true);
        }
        else {
          setStatusID(e.target.value);
        }
        
      }
      
      const GetStatusNames = () => {
        let body = {
          subProcessID: sessionStorage.getItem("ProcessId"),
          activityID: currentStateID,
          mode: ''
        };
        let activityUrl = getAPIURL(REACT_APP_GetWorkItemStatusDetails);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              
              setStatusNames(result.data);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      useEffect(() => {
        GetMoveWorkitemTableData();
      }, [])

      const DeleteMoveWorkitemTableRow = (row) => {
        // 
        
    
        if (
          window.confirm(
            "Are you sure you want to delete the row?"
          )
        ) {
          let body = {
            ruleID: row.ruleID
            // UpdatedBy: sessionStorage.PersonID,
          };
          let loginUrl = getAPIURL(REACT_APP_DeleteMoveWorkitemTableRow);
          let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status === 200) {
                // 
                addToast("Row deleted successfully", {
                  appearance: "success",
                  autoDismiss: true,
                });
                GetMoveWorkitemTableData();
              } else if (result.status === 401) {
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                sessionStorage.clear();
                navigate("/");
              }
            })
            .catch((error) => {
              console.log("ERROR: ", error);
              //navigate("/");
            });
        }
      };
      const GetMoveWorkitemTableData = () => {
        let body = {
          subProcessID: sessionStorage.getItem("ProcessId")
        };
        let activityUrl = getAPIURL(REACT_APP_GetMoveWorkitemTableData);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              
              setTableData(result.data);
            }
            else if (result.status == 404){
              setTableData([]);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      const columnsoftable = [
   
   
        {
          title: "Current State",
          field: "currentStateID",
        },
        {
          title: "Next State",
          field: "nextStateID",
        },
        {
          title: "Rules",
          field: "rules",
        },
        

      ]

      const FetchProcessSubProcessByGuid = (e) => {
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        if (e.target.value == "Select") {
          setprocessSubprocess([]);
          setActivitiesForSubprocess([]);
          setPriortyForSubProcessDetails([]);
          setPriortyForSubProcessDetails([]);
          setShowModalButton(false);
          setIsprocessSubprocess(false);
          setIsActivitiesForSubprocess(false);
          setIsClientLocations(false);
        } else {
          if (e.target.value !== "Select") {
            setSelectedLocation(e.target.value);
            setIsClientLocations(true);
            let result = "";
            let locationArray = e.target.value.split("/");
            for (let i = 0; i < locationArray.length - 1; i++) {
              result = result.concat(locationArray[i]);
              if (i < locationArray.length - 2) {
                result = result.concat("/");
              }
            }
            // sessionStorage.setItem(
            //   "selectedLocation",
            //   e.target.value.split("/")[e.target.value.split("/").length - 1]
            // );
            // sessionStorage.setItem("selectedLocationName", result);
            let body = {
              guid: sessionStorage.getItem("GUID"),
              subProcessId: "",
              clientId: locationArray[locationArray.length - 1],
            };
    
            let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
            let req = new Request(`${loginUrl}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization:
                  "Bearer " + sessionStorage.getItem("authorizationToken"),
              },
              body: JSON.stringify(body),
            });
            fetch(req)
              .then((res) => res.json())
              .then((result) => {
                if (result.status == 200) {
                  setprocessSubprocess(result.data);
                } else if (result.status === 401) {
                  //unauthorized
                  addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                    appearance: "error",
                    autoDismiss: true,
                  });
                  sessionStorage.clear();
                  sessionStorage.clear();
                  navigate("/");
                  setprocessSubprocess([]);
                } else {
                  setprocessSubprocess([]);
                }
              })
              .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //   navigate("/");
                // }
              });
          }
        }
      };

      const FetchLocationClientsByGuid = (e) => {
        setcompanyDeparmentID("");
        setcompanyDepartment("");
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        if (e.target.value == "Select") {
          setprocessSubprocess([]);
          setShowModalButton(false);
          setClientLocations([]);
          setActivitiesForSubprocess([]);
          setIsDepartments(false);
          setIsClientLocations(false);
          //setIsDepartments(false);
          setIsprocessSubprocess(false);
          setIsActivitiesForSubprocess(false);
        }
        if (e.target.value !== "Select") {
          //   sessionStorage.setItem(
          //     "selectedDepartment",
          //     e.target.value.split("/")[1]
          //   );
          //   sessionStorage.setItem(
          //     "selectedDepartmentName",
          //     e.target.value.split("/")[0]
          //   );
          setSelectedDepartment(e.target.value);
          setIsDepartments(true);
          //setIsClientLocations(true);
          let body = {
            guid: sessionStorage.getItem("GUID"),
            departmentId: e.target.value.split("/")[1],
          };
    
          let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
          let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status == 200) {
                setClientLocations(result.data);
                setIsClientLocations(true);
              } else if (result.status === 401) {
                //unauthorized
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                sessionStorage.clear();
                navigate("/");
              }
            })
            .catch((error) => {
              //if (result.status === 401) {
              console.log("ERROR: ", error);
              //   navigate("/");
              // }
            });
        }
      };
      const FetchSubprocessName = (subprocessId, clientId) => {
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: subprocessId,
          clientId: clientId,
        };
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              sessionStorage.setItem(
                "SelctedSubprocessName",
                result.data[0].processSubProcess
              );
              //SubProcessSubmitForTimer();
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: clientId,
        };
    
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
              setIsprocessSubprocess(true);
              GetWorkItemFieldsbySubProcessIDForClassification(
                sessionStorage.getItem("ProcessId")
              );
              //GetSavePriorityDetails(sessionStorage.getItem("ProcessId"));
              GetCurrentStates();
              GetNextStates();
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
        let body = {
          guid: sessionStorage.getItem("GUID"),
          departmentId: departmentId,
        };
    
        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setClientLocations(result.data);
              setIsClientLocations(true);
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //   navigate("/");
            // }
          });
      };
      const FetchDefaultValueForSelectedSubprocess = (
        companyDeparmentID,
        locationClientID,
        processSubProcessID
      ) => {
        setcompanyDeparmentID(companyDeparmentID);
        setSelectedDepartment(companyDeparmentID);
        sessionStorage.setItem("selectedDepartment", companyDeparmentID);
        FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
        setlocationClientID(locationClientID);
        setSelectedLocation(locationClientID);
        sessionStorage.setItem("selectedLocation", locationClientID);
        FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
        setprocessSubProcessID(processSubProcessID);
        FetchSubprocessName(processSubProcessID, locationClientID);
      };

      useEffect(() => {
        FetchDefaultValueForSelectedSubprocess(
          sessionStorage.getItem("selectedDepartment"),
          sessionStorage.getItem("selectedLocation"),
          sessionStorage.getItem("ProcessId")
        );
        FetchDepartmentbyGUID();
        
      }, []);

      useEffect(() => {
        if (currentStateID) {
        GetStatusNames();
        }
      }, [currentStateID]);

      console.log(statusID, "statusID")
      console.log(sendToPreviousStateCheck, "sendToPreviousStateCheck")
      

      return(
        <>
        <Header
        onpreferenceShow={null}
        pageName="Insert Rule For Moving WorkItem"
      />

    <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="bussinessrules" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <FormContext.Provider value={{ onchange: handelChange }}>
        <div className="centerSection">
          <div className="pageBody">
            <div className="dashBoxs mb-3">
              <div className="formWrap">
                <div className="row align-items-end">
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label"> Company Department</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchLocationClientsByGuid}
                        //disabled
                      >
                        <option>Select</option>
                        {departments.map((department) => (
                          <option
                            value={
                              department.companyDepartment +
                              "/" +
                              department.departmentIDString
                            }
                            selected={
                              department.departmentIDString ==
                              companyDeparmentID
                            }
                          >
                            {department.companyDepartment}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Location Client</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchProcessSubProcessByGuid}
                        //disabled
                      >
                        <option>Select</option>
                        {clientLocations.map((location) => (
                          <option
                            value={
                              location.locationClient +
                              "/" +
                              location.clientIDString
                            }
                            selected={
                              location.clientIDString == locationClientID
                            }
                          >
                            {location.locationClient}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Process SubProcess</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchInvalidProcessSubprocessByGuid}
                        //disabled
                      >
                        <option>Select</option>
                        {processSubprocess.map((subprocess) => (
                          <option
                            value={
                              subprocess.subProcessIDString +
                              "/" +
                              subprocess.processSubProcess
                            }
                            selected={
                              subprocess.subProcessIDString ==
                              processSubProcessID
                            }
                          >
                            {subprocess.processSubProcess}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Current State</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchCurrentStateID}
                      >
                        <option>Select</option>
                        {currentState.map((subprocess) => (
                          <option value={subprocess.activityID}>
                            {subprocess.activityName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  
                  
                     
                  {statusNameDropDownVisble &&( 
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Status Name</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={handleStatusChange}
                        disabled={sendToPreviousStateCheck === 1}
                      >
                        <option>Select</option>
                        {statusNames.map((data) => (
                          <option value={data.workItemStatusID}>
                            {data.statusName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  )}
                  {statusNameDropDownVisble &&( 
                  <div className="col-3">
                  <div className="mb-3">
                    <label>
                      <input type="checkbox" checked={sendToPreviousStateCheck ===1} onChange={handleCheckBoxChange}
                      disabled={statusID}/>
                      Send To Previous State
                    </label>
                    </div>
                    </div>
                    )}

                </div>

              </div>
            </div>
            <h5 class="mb-3">Rule Details</h5>
            <hr></hr>

            <RuleUploadGridDetailsPage
              state={{ WorkItemFieldsbySubProcessIDForClassification }}
              // setState = {setWorkItemFieldsbySubProcessIDForClassification}
              
            />
            <div class="col-12 text-end">
                  <div class="mb-3">
                    <button
                      class="btn btn-primary ms-3"
                      onClick={() => {
                        InsertIntoMoveWorkItemRuleWise();}}
                      >

                      Add
                      </button>
                      </div>
                      </div>

                      <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete Row",
                            onClick: (event, rowData) =>
                              DeleteMoveWorkitemTableRow(rowData),
                            position: "row",
                          }
                        ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          //pageSize: numberOfRecord,
                          pageSizeOptions: [5,10, 20, 50, 100],
                          exportButton: false,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,

                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          headerStyle: {
                            // height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            //                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          headerStyle: {
                            // fontFamily: '"Yantramanav", sans-serif',
                            // fontWeight: "inherit",
                            // fontSize: "14px",
                            // textAlign: "left !Important",
                          },
                        }}

                        columns={columnsoftable}
                        icons={tableIcons}
                        data={tableData}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>

          

            
          </div>
        </div>
      </FormContext.Provider>
      <Footer />
        </>
      )

}
export default InsertRuleForMovingWorkItem;
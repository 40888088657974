
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
 
const ManageWorkItemDetails = (values) => {  
  return (
    <div>
      {!values.state.isDynamic?
        <>
        <div className="">
          {values.state.workItemDetailModel.map((groups) => {
            return (
              <div className="col-12">
                <form className="form-inputs">
                  <div className="fieldSet">                  
                    <div className="row">
                      <div className="row">
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="Workitem ID"
                            > Workitem ID
                            </label>
                            <label
                              className="form-label"
                              title={groups.workItemID}
                            >
                              {" "}
                              {groups.workItemID}
                            </label>

                        </div> 
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="SLA Start Date"
                            > SLA Start Date
                            </label>

                            <label
                              className="form-label"
                              title={groups.slaStartDate}
                            >
                              {" "}
                              {groups.slaStartDate}
                            </label>
                            
                        </div> 
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="SLA Due Date"
                            > SLA Due Date
                            </label>

                            <label
                              className="form-label"
                              title={groups.slaDueDate}
                            >
                              {" "}
                              {groups.slaDueDate}
                            </label>                            
                        </div> 
                        </div>
                        <div className="row">
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="SLA Completion Date"
                            > SLA Completion Date
                            </label>
                            <label
                              className="form-label"
                              title={groups.slaCompletionDate}
                            >
                              {" "}
                              {groups.slaCompletionDate}
                            </label>   
                            
                        </div> 
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="Current State"
                            > Current State
                            </label>
                            <label
                              className="form-label"
                              title={groups.workFlowStateName}
                            >
                              {" "}
                              {groups.workFlowStateName}
                            </label>  
                           
                        </div> 
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="Last Worked By"
                            > Last Worked By
                            </label>
                            <label
                              className="form-label"
                              title={groups.lastWorkedBy}
                            >
                              {" "}
                              {groups.lastWorkedBy}
                            </label> 
                           
                        </div> 
                        <div className="col-4 pb-3 pt-1">
                            <label
                            className="form-label"
                            title="Last Worked On"
                            > Last Worked On
                            </label>

                            <label
                              className="form-label"
                              title={groups.lastWorkedOn}
                            >
                              {" "}
                              {groups.lastWorkedOn}
                            </label>
                        </div>
                      </div>
                    </div>                   
                  </div>                  
                </form>
              </div>
              
            );
          })}
        </div>
        </>:
        <>
         <div className="row">
          {values.state.workItemFieldsModel.map((groups) => {
            return (
              <div className="col-4"  style={{ marginBottom: "0.5rem" }}>
                <form className="form-inputs">
                  <div className="fieldSet">
                    <div className="row">
                      <div>
                        <div className="pb-3 pt-1">
                            <label
                            className="form-label"
                            title={groups.labelText}
                            > {groups.labelText}
                            </label>

                            <label className="form-label" title={groups.value}>
                              {" "}
                              {groups.value}
                            </label>
                            
                        </div> 
                      
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            );
          })}
        </div>
        </>}
     
    </div>
  );
};
export default ManageWorkItemDetails;
 
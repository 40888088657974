import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavDataContext from "../../../../contexts/NavDataContext";
import { ToastProvider, useToasts } from "react-toast-notifications";

import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DownloadExcel,ArrayWithoutExtraColumn } from "../../Comman/ExcelFileDownload";
import { NavItem } from "react-bootstrap";

function EmployeeSummary() {
  const { addToast } = useToasts();
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GETEMPLOYEEACTIVITYSUMMARY } = process.env;
  const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const location = useLocation();
  let navigate = useNavigate();
  const processingDashboardParam = location.state;
  const [EmployeeTimeUtilization, setEmployeeTimeUtilization] = useState([]);
  const [EmployeeWorkItemCount, setEmployeeWorkItemCount] = useState([]);
  let navData = [];
  let columnUtilization = []
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });
  
  const columns_Time_Utilization = [
    {
      title: "Employee Id",
      field: "employeeid",
    },
    {
      title: "Employee Name",
      field: "employeename",
    },
    {
      title: "Activity Name",
      field: "activityname",
    },
    {
      title: "Previous Day",
      field: "previouS_DATE",
    },
    {
      title: "Current Day",
      field: "currenT_DATE",
    },
  ];

  const columns = [
    {
      title: "Employee Id",
      field: "employeeid",
    },
    {
      title: "Employee Name",
      field: "employeename",
    },
    {
      title: "Activity Name",
      field: "activityname",
    },
    {
      title: "Previous Day",
      field: "previousdate",
    },
    {
      title: "Current Day",
      field: "currentdate",
    },
  ];
 

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const GetPriortyForSubProcess = () => {
    let body = {
      employeeid: sessionStorage.getItem("PersonID"),
    };
    let activityUrl = getAPIURL(REACT_APP_GETEMPLOYEEACTIVITYSUMMARY);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setEmployeeTimeUtilization(
            result.data._list_Employee_Time_Utilization
          );
          setEmployeeWorkItemCount(result.data._list_Workitems_Count);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  useEffect(() => {
    GetPriortyForSubProcess();
  }, []);

  const downloadExcel = (condition) => {
 columnUtilization = [];
    if (condition == "TimeUtilization") {     
       EmployeeTimeUtilization.forEach((data) => {
        columnUtilization.push({
          EmployeeId: data.employeeid,
          EmployeeName: data.employeename,
          ActivityName: data.activityname,
          PreviousDay: data.previouS_DATE,
          CurrentDay: data.currenT_DATE,
        });
      });      
      DownloadExcel(columnUtilization,"EmployeeTimeUtilization");
    } else {   
      EmployeeWorkItemCount.forEach((data) => {
        columnUtilization.push({
          EmployeeId: data.employeeid,
          EmployeeName: data.employeename,
          ActivityName: data.activityname,
          PreviousDay: data.previousdate,
          CurrentDay: data.currentdate,
        });
      });
      DownloadExcel(columnUtilization,"EmployeeWorkItemCount");
    }
  };

  return (
    <>
      <Header onpreferenceShow={null} pageName="Employee Activity Summary" />
      <DashboardContext.Provider value={processingDashboardParam.nav}>
        <NavDataContext.Provider value={navData}>
          <Nav selectedMenu="activities" />
        </NavDataContext.Provider>
      </DashboardContext.Provider>

      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs">
            <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
              <div className="row align-items-center">
                <div className="col-4">
                  <h6>Employee Time Utilization</h6>
                  {/* //<h5>Processing Dashboard</h5> */}
                </div>
                <div className="col-8">
                  <div
                    className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                    style={{ marginTop: "-10px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-2"
                      onClick={() => {
                        downloadExcel("TimeUtilization");
                      }}
                    >
                      <b></b>Export
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <table
              className="table table-borderless MatTable"
              style={{ zIndex: "1", position: "relative" }}
            >
              <ThemeProvider theme={theme}>
                <MaterialTable
                  localization={{
                    pagination: {
                      //labelDisplayedRows: '50-51 of 100',
                      labelRowsPerPage: "{50, 100, 250,500}",
                    },
                    toolbar: {
                      // nRowsSelected: '{0} row(s) selected'
                      exportCSVName: "Export as Excel",
                      exportPDFName: "Export as pdf",
                    },
                  }}
                  title="Employee Time Utilization"
                  options={{
                    search: false,
                    overflowY: false,
                    showTitle: false,
                    cellStyle: {
                      // width: 100,
                      // minWidth: 100,
                    },
                    headerStyle: {
                      position: "sticky",
                      // top: "-5px",
                    },
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                    },
                  }}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                      </div>
                    ),
                  }}
                  columns={columns_Time_Utilization}
                  icons={tableIcons}
                  data={EmployeeTimeUtilization}
                />
              </ThemeProvider>
            </table>
          </div>
        </div>

        <div className="pageBody">
          <div className="dashBoxs">
            <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
              <div className="row align-items-center">
                <div className="col-4">
                  <h6>Workitems Count</h6>
                  {/* //<h5>Processing Dashboard</h5> */}
                </div>
                <div className="col-8">
                  <div
                    className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                    style={{ marginTop: "-10px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-2"
                      onClick={() => {
                        downloadExcel("WorkitemsCount");
                      }}
                    >
                      <b></b>Export
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <table
              className="table table-borderless MatTable"
              style={{ zIndex: "1", position: "relative" }}
            >
              <ThemeProvider theme={theme}>
                <MaterialTable
                  localization={{
                    pagination: {
                      //labelDisplayedRows: '50-51 of 100',
                      labelRowsPerPage: "{50, 100, 250,500}",
                    },
                    toolbar: {
                      // nRowsSelected: '{0} row(s) selected'
                      exportCSVName: "Export as Excel",
                      exportPDFName: "Export as pdf",
                    },
                  }}
                  title="Workitems Count"
                  options={{
                    search: false,
                    overflowY: false,
                    showTitle: false,
                    cellStyle: {
                      // width: 100,
                      // minWidth: 100,
                    },
                    headerStyle: {
                      position: "sticky",
                      // top: "-5px",
                    },
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                    },
                  }}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                      </div>
                    ),
                  }}
                  columns={columns}
                  icons={tableIcons}
                  data={EmployeeWorkItemCount}
                />
              </ThemeProvider>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EmployeeSummary;
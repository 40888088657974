import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Table, Button,Modal,Form } from 'react-bootstrap';
const { REACT_APP_ApproveRequest,REACT_APP_API_URL } = process.env;

const ApproveRequest = () => {
    // debugger
    const [status, setStatus] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const commentRef = React.useRef();

    // Extract the requestId from the query parameters
    const queryParams = new URLSearchParams(location.search);
    const requestId = queryParams.get('requestId');
    const getAPIURL = api => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
      };
    
    

    useEffect(() => {
        
    }, []);

    const ApproveRequest = async() => {
      // debugger
        let approvalUrl = getAPIURL(REACT_APP_ApproveRequest);
        //let approveRequestUrl =`${approvalUrl}?requestId=${requestId}`;
        let body = {
          requestId : +requestId,
          ApprovedBy : sessionStorage.getItem("UserFullName"),
          ApproverComments: commentRef.current.value
        }
        const response = await fetch(approvalUrl, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
              'Content-Type': 'application/json',
            },
          });
        fetch(response)
        .then((response) => {
            if (response.ok) {
                setStatus('Approval successful!');
            } else {
                setStatus('Failed to approve the request.');
            }
        })
        .catch(() => {
            setStatus('An error occurred while sending the approval request.');
        });

      };

    return (
        <div>
            {/* <h1>Approval Status</h1>
            <p>{status}</p>
            <button onClick={(e) => {
                e.preventDefault();
                alert("Approving....");
            } }>Approve</button>
            <button onClick={() => navigate('/')}>Go Back Home</button> */}
        <Modal show={true} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Approval PopUp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
            <Form.Group>
              <Form.Label> Comment </Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                ref = {commentRef}
                //onChange={handleInputChange}
                required
              />
            </Form.Group>
          <button className='btn btn-primary buttonMarginAS' type="submit" onClick={() => ApproveRequest()}>
              Approve
          </button>
          <button className='btn btn-primary buttonMarginAS' type="submit" onClick={() => navigate("/Home")}>
              Go To Home
          </button>
        </div>

        </Modal.Body>
      </Modal>
        </div>
    );
};

export default ApproveRequest;


import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import ReactDOM from "react-dom";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetReportids, reset } from "../../../../redux/reportsSlice";
import { HandleUnauthorized } from "../../../../components/common/functions";
import PowerBiFunctions from "./../../../../common/PowerBiFunctions";
import refreshstaticicon from "src/assets/images/icons/refresh-48.png";
import refreshloadingicon from "src/assets/images/icons/spinning-circle.gif";

import { autoLogOut } from "../../Comman/TimeCapture";

import allworkitem from "../../../../../src/assets/images/charts/allworkitem.png";
import performance from "../../../../../src/assets/images/charts/performance.png";
import priority from "../../../../../src/assets/images/charts/priority.png";
import pending from "../../../../../src/assets/images/charts/pending.png";
import completed from "../../../../../src/assets/images/charts/completed.png";

import { GetUpdatedTimeCapturing } from "src/redux/updateTimeCapturingSlice";

import {
  Container as ContainerFloat,
  Link as LinkFloat,
} from "react-floating-action-button";
import { Button as ButtonFloat } from "react-floating-action-button";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";

import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Cell,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import Dropdown from "react-bootstrap/Dropdown";

import TableFilter from "react-table-filter";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import { ToastProvider, useToasts } from "react-toast-notifications";

import "../../../../assets/scss/pages/_dashboard.scss";

import "react-table-filter/lib/styles.css";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";

import user01 from "../../../../assets/images/user/user01.jpg";
import user02 from "../../../../assets/images/user/user03.jpg";
import user03 from "../../../../assets/images/user/user07.jpg";
import user04 from "../../../../assets/images/user/user04.jpg";
import arrowright from "../../../../assets/images/icons/arrow_circle_right_black_36dp.svg";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import GridStatus from "./GridStatus";
import GlobalStyles from "@mui/material/GlobalStyles";
import {
  HomeDashboardPlaceholder,
  HomeGraphPlaceholder,
} from "src/bootsrap-placeholder";
import { Pending } from "@mui/icons-material";
let col = [];
function Home() {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { reportids } = useSelector((state) => state);
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_COMMONPBIDASHBOARDID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_FETCH_INVALIDPROCESSSUBPROCESS } = process.env;
  const { REACT_APP_FETCH_ASSIGNEDWORKITEMDETAILSFORUSER } = process.env;
  const { REACT_APP_FETCH_FETCHDEFAULTVALUE } = process.env;
  const { REACT_APP_FETCH_NEWLYCREATEDWORKITEMS } = process.env;
  const { REACT_APP_FETCH_BLACKLOGWORKITEMS } = process.env;
  const { REACT_APP_FETCH_COMPLETEDWORKITEMS } = process.env;
  const { REACT_APP_FETCH_REMAININGWORKITEMS } = process.env;
  const { REACT_APP_FETCH_WEEKLYPERFORMANCE } = process.env;
  const { REACT_APP_FETCH_OVERALLPERFORMANCE } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SUBPROCESSSUBMIT } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const { REACT_APP_UILOGEXTENSION } = process.env;
  const { REACT_APP_FETCHGRAPHTILESCOUNT } = process.env;
  const { REACT_APP_FetchGraphTilesCountbyUserId } = process.env;
  const { REACT_APP_FETCHISSYSTEMTIMEZONE } = process.env;
  
  const currentPage = window.location.href;
  // USER PREF. MODAL FUNCTION
  let interval = null;
  const [powerbiStore, setpowerbiStore] = useState(true);
  const [preferenceModal, setShow] = useState(false);
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [Ispreferenceshow, setIspreferenceshow] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [userRoles, setuserRoles] = useState([]);
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [invalidProcessSubprocess, setinvalidProcessSubprocess] = useState([]);
  const [showBtn, setUserButtonShow] = useState(false);
  const [assignedWorkItemDetails, setAssignedWorkItemDetails] = useState([]);
  const [allowLocation, setAllowLocation] = useState(false);
  const [allowSubprocess, setAllowSubprocess] = useState(false);
  const [showModalButton, setShowModalButton] = useState(false);
  const [list, setList] = useState([]);
  const [empUtilization, setempUtilization] = useState([]);
  const [entityDetails, setentityDetails] = useState({});
  const [newlyCreatedAssigned, setnewlyCreatedAssigned] = useState(0);
  const [newlyCreatedTotal, setnewlyCreatedTotal] = useState(0);
  const [refreshloading, setrefreshloading] = useState(0);
  const [CreatedKPI, setCreatedKPI] = useState([]);
  const [backlogItem, setbacklogItemCount] = useState(0);
  const [totalBacklogCount, settotalBacklogCount] = useState(0);
  const [BacklogKPI, setBacklogKPI] = useState([]);
  const [assignedTodayCount, setassignedTodayCount] = useState(0);
  const [totalCreatedToday, settotalCreatedToday] = useState(0);
  const [completedItemCount, setcompletedItemCount] = useState(0);
  const [totalCompletedCount, settotalCompletedCount] = useState(0);
  const [completedKPI, setcompletedKPI] = useState([]);
  const [totalRemaining, settotalRemaining] = useState(0);
  const [remainingItem, setremainingItem] = useState(0);
  const [remainingKPI, setremainingKPI] = useState([]);
  const [fetchWeeklyPerformance, setfetchWeeklyPerformance] = useState(0);
  const [fetchOverallPerformance, setfetchOverallPerformance] = useState(0);
  const [isLoading, set_IsLoading] = useState(false);
  const [isChart, set_isChart] = useState(false);
  const [isChartOverall, set_isChartOverall] = useState(false);
  const [timerStart, settimerStart] = useState("");
  const [btnShowBreakPanelDisabled, setbtnShowBreakPanelDisabled] =
    useState(false);
  const [btnShowBreakPanelColor, setbtnShowBreakPanelColor] = useState("Gray");
  const [ddlActivityEnabled, setddlActivityEnabled] = useState(false);
  const [objdataview, setobjdataview] = useState([]);
  const [ddlActivitySelectedValue, setddlActivitySelectedValue] = useState(0);
  const [txtBreakCommentsEnabled, settxtBreakCommentsEnabled] = useState(false);
  const [timerValue, settimerValue] = useState(0);
  const [hdnSystemBreakValue, sethdnSystemBreakValue] = useState("");
  const [imgActivityToolTip, setimgActivityToolTip] = useState("");
  const [tSaveTimerEnabled, settSaveTimerEnabled] = useState(false);
  const [breakStartTime, setbreakStartTime] = useState("");
  const [btnStartWorkTimerDisabled, setbtnStartWorkTimerDisabled] =
    useState(false);
  const [employeeTimeUtilization, setemployeeTimeUtilization] = useState([]);
  const [isLoadingDashboard, set_isLoadingDashboard] = useState(false);
  const [isSubmit, setisSubmit] = useState(false);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const { REACT_APP_FETCHWORKITEMSCOUNT } = process.env;
  const [GraphTilesRecords, setGraphTilesRecords] = useState(null);

  const [GraphTilesRecordsSLA, setGraphTilesRecordsSLA] = useState(null);
  const [GraphTilesRecordsAgeing, setGraphTilesRecordsAgeing] = useState(null);
  const [column, setcolumn] = useState([]);

  let navigate = useNavigate();
  let row = [];
  let defaultFilter =
    "wi.UploadDate between dateadd(day,-10,getdate()) and getdate()";
  // let defaultFilter =
  //   "wi.UploadDate between dateadd(day,-10,getdate()) and getdate()";
  //const [status, setStaus] = useState([]);
  var statusArr = [];

  const columns = [
    {
      title: "Activity Name",
      field: "activityName",
      render: (row) => (
        <Link
          title="Click to see all workitems"
          to="/WorkActivityDashboard"
          state={{
            row,
            selectedSubprocess,
            nav,
            userRoles,
            assignedWorkItemDetails,
            summaryItemCount: row.summaryItemCount,
            selectedWorkItemDetails: assignedWorkItemDetails.find(
              (e) => e.activityName === row.activityName
            ),
          }}
          onClick={() => setProcessName(row)}
        >
          {row["activityName"].toUpperCase()}
        </Link>
      ),
    },
    {
      title: "Status",
      field: "status",
      render: (row) => <GridStatus state={{ row, assignedWorkItemDetails }} />,
    },
    { title: "Assigned workitems count", field: "summaryItemCount" },
  ];
  const setProcessName = (row) => {
    clearInterval(interval);
    sessionStorage.setItem("ProcessName", row.activityName);
  };
  // const COLORSAgeing = ["#f4c587", "#ace0c7", "#bdd9f4", "#feccd2", "#c1bbb3"];
  const COLORSAgeing = ["#f4c587", "#7bbf98", "#85a6c7", "#cc9fa3", "#c1bbb3"];
  const COLORS = ["#ace0c7", "#e58279"];
  const SLACOLORS = ["#ace0c7", "#e58279"];
  const ImageTiles = [allworkitem, pending, performance, priority, completed];
  const [activeTab, setActiveTab] = useState("MyView");

  const TabsView = styled(Tabs)({
    "& .MuiTabs-indicator": {
      backgroundColor: "#ff7d47",
      textColor: "#ff7d47",
    },
    "& .Mui-selected": {
      color: "#ff7d47",
    },
  });

  const isSubprocessView = sessionStorage.getItem('is_SubprocessView') === 'true';
  let remainingPwdExpiryDays = sessionStorage.getItem("remainingPwdExpiryDays");
  let enablePwdPolicy = sessionStorage.getItem("EnablePwdPolicy");
  
  const ReactDashboard = () => {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-4">
            { isSubprocessView ? (
              <TabsView value={activeTab} sx={{marginLeft: '-60px',marginTop: '-20px'}} >
                <Tab label="MyView" onClick={handleUserWiseButtonClick} value="MyView" sx={{ color:'#ff7d47'}}/>
                <Tab label="Subprocess View" onClick={handleSubprocessWiseButtonClick} value="SubprocessView" sx={{color:'#ff7d47'}}  />
              </TabsView>
              ):(<TabsView value={activeTab} sx={{marginLeft: '-60px',marginTop: '-20px'}} >
              <Tab label="MyView" onClick={handleUserWiseButtonClick} value="MyView" sx={{ color:'#ff7d47'}}/>
            </TabsView> )}
            </div>
            <div className="col-8 d-flex justify-content-end">
              <div className="d-sm-inline-block margin-graph-time">
                <div className="dropdown userDropdown">
                  {GraphTilesRecords
                    ? GraphTilesRecords.homeRefreshDateTime.map(
                        (res, index) => (
                          <span>
                            Refreshed at: {formatDateTime(res.refreshDateTime)}
                          </span>
                        )
                      )
                    : null}
                </div>
              </div>
              <div className="d-sm-inline-block">
                <div className="dropdown userDropdown">
                  <a
                    className="navbar-dropdown-account-wrapper"
                    title="Click to refresh"
                  >
                    <div
                      className="avatar avatar-circle graph-refresh-icon"
                      style={{ background: "none" }}
                      onClick={handleRefreshHomePage}
                    >
                      {" "}
                      <div className="avatar-img">
                        {" "}
                        {refreshloading ? (
                          <img src={refreshloadingicon} alt="?" />
                        ) : (
                          <img src={refreshstaticicon} alt="?" />
                        )}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              {/* <Button class="material-symbols-outlined submenu-icon mb-1">
                refresh
              </Button> */}
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className="row">
            {GraphTilesRecords
              ? GraphTilesRecords.homePageTilesResponse.map((res, index) => (
                  <div className="col">
                    <div className="tileCard" data-activity>
                      <h3>{res.tileName}</h3>
                      <div className="tileCard-main">
                        <img
                          className="tileCard-imgsize"
                          src={ImageTiles[index]}
                          alt="Description of the image"
                        />
                        <div className="tileCard-seprator"></div>
                        <div
                          className="tileCard-info"
                          title={res.primaryDescription}
                        >
                          <div>{res.primmaryHeader}</div>
                          <div className="tileCard-count">
                            {res.primmaryHeaderValue}
                          </div>
                        </div>
                        <div
                          className="tileCard-info"
                          title={res.secondryDescription}
                        >
                          <div>{res.secondaryHeader}</div>
                          <div className="tileCard-count">
                            {res.secondaryHeaderValue}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className="mb-2">
          <div className="row align-items-center">
            {!GraphTilesRecords ? (
              <HomeGraphPlaceholder></HomeGraphPlaceholder>
            ) : (
              <div className="col-4">
                <div className="dashBoxs">
                  <div className="dashBoxs-header pb-2">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <h3>Ageing of Workitems </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="dashBoxs-body ">
                      <div className="chartWrap pt-4 pb-1">
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            data={GraphTilesRecordsAgeing}
                            margin={{
                              top: 0,
                              right: 0,
                              left: -20,
                              bottom: 0,
                            }}
                          >
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend
                              verticalAlign="top"
                              align="right"
                              iconSize="8"
                              height={20}
                            />
                            {(column || {}).map((result, index) => (
                              <Bar
                                dataKey={result}
                                fill={COLORSAgeing[index]}
                              />
                            ))}
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!GraphTilesRecords ? (
              <HomeGraphPlaceholder></HomeGraphPlaceholder>
            ) : (
              <div className="col-4">
                <div className="dashBoxs">
                  <div className="dashBoxs-header pb-2">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <h3>Activity Wise SLA</h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="dashBoxs-body ">
                      <div className="chartWrap pt-4 pb-1">
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            data={GraphTilesRecordsSLA}
                            margin={{
                              top: 0,
                              right: 0,
                              left: -20,
                              bottom: 0,
                            }}
                          >
                            <XAxis dataKey="activityName" />
                            <YAxis />
                            <Tooltip />
                            <Legend
                              verticalAlign="top"
                              align="right"
                              iconSize="8"
                              height={20}
                              style={{ fontWeight: 600 }}
                            />
                            <Bar
                              dataKey="withinSla"
                              fill="#ace0c7"
                              name="Wthin SLA"
                            />
                            <Bar
                              dataKey="outsideSla"
                              fill="#e58279"
                              name="Outside SLA"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!GraphTilesRecords ? (
              <HomeGraphPlaceholder></HomeGraphPlaceholder>
            ) : (
              <div className="col-4">
                <div className="dashBoxs">
                  <div className="dashBoxs-header pb-2 ">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <h3>WorkItems processed Today</h3>
                      </div>
                    </div>
                  </div>
                  <div className="dashBoxs-body ">
                    <div className="chartWrap pt-4 pb-1">
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          width={100}
                          height={100}
                          data={GraphTilesRecords.homepageGraphKPIResponse}
                          margin={{
                            top: 10,
                            right: 10,
                            left: -20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis datakey="hoursbefore" />
                          <YAxis />
                          <Tooltip />
                          <Legend
                            verticalAlign="top"
                            align="right"
                            iconSize="8"
                            height={20} />
                          <Line type="monotone" dataKey="completed" name="Completed" stroke="#8884d8" />
                          <Line type="monotone" dataKey="pending" name= "Pending" stroke="#82ca9d" />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleRefreshHomePage = () => {
    if (activeTab == "SubprocessView") {
      
      if(sessionStorage.getItem('is_SubprocessView') === 'true'){
        FetchGraphTilesRecords(sessionStorage.getItem("ProcessId"), true, true);
      }
    } else {
      FetchGraphTilesRecords(sessionStorage.getItem("ProcessId"), true, false);
    }
  };
  const preferenceClose = () => {
    setShow(false);
    setShowModalButton(false);
    //setDepartments([]);
    //setClientLocations([]);
    //setprocessSubprocess([]);
  };
  const preferenceShow = () => {
    // //debugger;
    setShow(true);
    sessionStorage.setItem("PreferenceShow", "true");
    setIspreferenceshow(true);
    //FetchDepartmentbyGUID();
    if (sessionStorage.getItem("ProcessId") === null) {
      FetchDefaultValue();
    } else {
      FetchDefaultValueForSelectedSubprocess(
        sessionStorage.getItem("selectedDepartment"),
        sessionStorage.getItem("selectedLocation"),
        sessionStorage.getItem("ProcessId")
      );
    }
  };

  const location = useLocation();
  const Roles = location.state;

  // FILTER TOGGLE FUNCTION
  const filterRef = React.useRef(null);
  const onFilterClick = (e) => {
    filterRef.current.classList.toggle("active");
  };

  const [rowData] = useState([
    { make: "Toyota", model: "Celica", price: 35000 },
    { make: "Ford", model: "Mondeo", price: 32000 },
    { make: "Porsche", model: "Boxter", price: 72000 },
  ]);

  const [columnDefs] = useState([
    { field: "make", sortable: true, filter: true },
    { field: "model", sortable: true, filter: true },
    { field: "price", sortable: true, filter: true },
  ]);

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const getDashboardDetails = async () => {
    let body = {
      userId: sessionStorage.getItem("GUID"),
      companyDeparmentID: sessionStorage.getItem("selectedDepartment"),
      locationClientID: sessionStorage.getItem("selectedLocation"),
      processSubProcessID: sessionStorage.getItem("ProcessId"),
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_FETCHDEFAULTVALUE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          let body = {
            subprocessID: result.data.processSubProcessID,
            personID: sessionStorage.getItem("PersonID"),
            personType: sessionStorage.getItem("PersonType"),
            userName: sessionStorage.getItem("UserName"),
            HRISEmployeeID: sessionStorage.getItem("HRISEmployeeID"),
            ApplicationName: sessionStorage.getItem("ApplicationURL"),
          };

          let loginUrl = getAPIURL(
            REACT_APP_FETCH_ASSIGNEDWORKITEMDETAILSFORUSER
          );
          let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization:
                "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status == 200) {
                setAssignedWorkItemDetails(result.data);
                //console.log(result.data);
                //console.log(nav);
                setnav(result.data);
                preferenceClose();
                set_isLoadingDashboard(false);
              } else if (result.status === 401) {
                //unauthorized
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                sessionStorage.clear();
                navigate("/");
              } else {
                setAssignedWorkItemDetails([]);
                setnav([]);
                preferenceClose();
                set_isLoadingDashboard(false);
              }
            })
            .catch((error) => {
              //if (result.status === 401) {
              //console.log("ERROR: ", error);
              if (
                error.name == "TypeError" &&
                error.message == "Failed to fetch"
              ) {
                BodydetailsinLog(
                  error.toString() + " getDashboardDetails 1 ",
                  body
                );
                //navigate("/ErrorPage");
                //window.location.href = "/Home";
              }
            });
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setAssignedWorkItemDetails([]);
          setnav([]);
          preferenceClose();
          set_isLoadingDashboard(false);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " getDashboardDetails 2 ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const formatDateTime = (dateString) => {
    var date = new Date(dateString);

    // More than a week, format as "03/18/24"
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const BodydetailsinLog = (error, body) => {
    // //debugger;
    let Bodydetails = "";
    for (let i = 0; i < Object.keys(body).length; i++) {
      if (
        Object.values(body)[i] != null &&
        typeof Object.values(body)[i] == "object"
      ) {
        Bodydetails = Bodydetails + ", \n" + Object.keys(body)[i] + ": {";
        for (let j = 0; j < Object.keys(Object.values(body)[i]).length; j++) {
          if (j != 0) {
            Bodydetails = Bodydetails + ",";
          }
          if (
            Object.values(Object.values(body)[i])[j] != null &&
            typeof Object.values(Object.values(body)[i])[j] == "object"
          ) {
            Bodydetails =
              Bodydetails +
              " " +
              Object.keys(Object.values(body)[i])[j] +
              ": {";
            for (
              let a = 0;
              a < Object.keys(Object.values(Object.values(body)[i])[j]).length;
              a++
            ) {
              if (a != 0) {
                Bodydetails = Bodydetails + ",";
              }
              Bodydetails =
                Bodydetails +
                " " +
                Object.keys(Object.values(Object.values(body)[i])[j])[a] +
                ": " +
                Object.values(Object.values(Object.values(body)[i])[j])[a];
            }
            Bodydetails = Bodydetails + " } ";
          } else {
            Bodydetails =
              Bodydetails +
              " " +
              Object.keys(Object.values(body)[i])[j] +
              ": " +
              Object.values(Object.values(body)[i])[j];
          }
        }
        Bodydetails = Bodydetails + " } ";
      } else {
        Bodydetails =
          Bodydetails +
          ", \n" +
          Object.keys(body)[i] +
          ": " +
          Object.values(body)[i];
      }
    }
    CorsLoggingError(error.toString() + " " + Bodydetails.toString());
  };
  const CorsLoggingError = (error) => {
    let logUrl = getAPIURL(REACT_APP_UILOGEXTENSION);
    let body = {
      personID: +sessionStorage.getItem("PersonID"),
      lanID: sessionStorage.getItem("UserName"),
      message: "Cors Error: " + error,
      pageName: currentPage.split("/")[currentPage.split("/").length - 1],
      logType: true,
    };
    let req = new Request(`${logUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
        } else {
          // addToast("Something went wrong", {
          //   appearance: "error",
          //   autoDismiss: true,
          // });
        }
      })
      .catch((error) => {
        //console.log("ERROR: ", error);
        // addToast("Something went wrong", {
        //   appearance: "error",
        //   autoDismiss: true,
        // });
        //navigate("/Home");
      });
  };
  const setFavorite = () => {
    set_isLoadingDashboard(true);
    sessionStorage.setItem("ProcessId", selectedSubprocess.split("/")[0]);
    sessionStorage.setItem(
      "SelctedSubprocessName",
      selectedSubprocess.split("/")[1]
    );
    GetSubprocessConfig(selectedSubprocess.split("/")[0]);
    getDashboardDetails();
    FetchSubprocessName(
      sessionStorage.getItem("selectedSubprocess"),
      sessionStorage.getItem("selectedLocation")
    );
    FetchNewlyCreatedWorkItems(selectedSubprocess.split("/")[0]);
    //FetchBacklogWorkItems(selectedSubprocess.split("/")[0]);
    //FetchCompletedWorkitems(selectedSubprocess.split("/")[0]);
    //FetchRemainingWorkitems(selectedSubprocess.split("/")[0]);
    FetchWeeklyPerformance(selectedSubprocess.split("/")[0]);
    FetchOverallPerformance(selectedSubprocess.split("/")[0]);

    

  };
  const SubProcessSubmitForTimer = async () => {
    if (sessionStorage.getItem("PreferenceShow") !== "true") {
      set_IsLoading(true);
    }
    let body = {
      ddlCompanyDepartmentID: sessionStorage.getItem("selectedDepartment"),
      ddlCompanyDepartmentText: sessionStorage.getItem(
        "selectedDepartmentName"
      ),
      ddlLocationClientID: sessionStorage.getItem("selectedLocation"),
      ddlLocationClientText: sessionStorage.getItem("selectedLocationName"),
      ddlSubProcessID: "",
      ddlProcessSubProcessID: sessionStorage.getItem("ProcessId"),
      ddlProcessText: "",
      ddlSubProcessText: "",
      ddlProcessSubProcessText: sessionStorage.getItem("SelctedSubprocessName"),
      personId: sessionStorage.getItem("PersonID"),
      applicationName: "productiondashboard",
    };

    let subprocessSubmitUrl = getAPIURL(REACT_APP_SUBPROCESSSUBMIT);
    let req = new Request(`${subprocessSubmitUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          if (result.data != undefined) {
            dispatch(reset());
            dispatch(GetReportids(result.data.selectedEntitiesDetail.reports));
            setpowerbiStore(false);
            sessionStorage.setItem(
              "SpecificPbiDashboardID",
              result.data.selectedEntitiesDetail.pbiDashboardID
            );
            sessionStorage.setItem(
              "PowerBiWorkspaceID",
              result.data.selectedEntitiesDetail.pbiWorkspaceId
            );
            sessionStorage.setItem(
              "selectedEntitiesDetail",
              JSON.stringify(result.data.selectedEntitiesDetail)
            );
            sessionStorage.setItem(
              "PageId",
              JSON.stringify(result.data.homeActivityId)
            );
            sessionStorage.setItem(
              "BreakTimeForClientURL",
              result.data.isBreakTimeEenableForClientURL
            );

            

          } else {
            sessionStorage.setItem("selectedEntitiesDetail", "");
            sessionStorage.setItem("PageId", 0);
          }
          if (sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == "1") {
            FetchBreakTimerOnLoad();
          }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
        if (sessionStorage.getItem("PreferenceShow") !== "true") {
          set_IsLoading(false);
        }
        sessionStorage.removeItem("PreferenceShow");
        setIspreferenceshow(false);
      })
      .catch((error) => {
        set_IsLoading(false);
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " SubProcessSubmitForTimer ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const SubmitDashboardData = () => {
    //debugger;
    const processId = sessionStorage.getItem("ProcessId");
    const companyDeparmentID = sessionStorage.getItem("selectedDepartment");
    const locationClientID = sessionStorage.getItem("selectedLocation");
    preferenceDefault(processId);

    // FetchWeeklyPerformance(processId);
    // FetchOverallPerformance(processId);
    // FetchNewlyCreatedWorkItems(processId);

    FetchGraphTilesRecords(processId);

    set_isLoadingDashboard(false);
    preferenceClose();
    setisSubmit(false);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    FetchSubprocessName(processId, locationClientID);
  };
  const subprocessSubmit = async (e) => {
    //Added By Dhanaraj on 16-01-2022 to clear sticky filter-start
    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    sessionStorage.removeItem(workflowactivityName + "_sortColumnPrimary");
    sessionStorage.removeItem(workflowactivityName + "_sortColumnSecondary");
    sessionStorage.removeItem(workflowactivityName + "_mapToCondition");
    sessionStorage.removeItem(workflowactivityName + "_condition");
    sessionStorage.removeItem(workflowactivityName + "_sortColumn");
    //End.
    setpowerbiStore(true);
    //set_isLoadingDashboard(true);
    setisSubmit(true);
    sessionStorage.setItem("ProcessId", selectedSubprocess.split("/")[0]);
    sessionStorage.setItem(
      "SelctedSubprocessName",
      selectedSubprocess.split("/")[1]
    );
    GetSubprocessConfig(selectedSubprocess.split("/")[0]);

    let subprocessName = sessionStorage.getItem("SubprocessName");
    if (subprocessName !== sessionStorage.getItem("SelectedSubprocessName")) {
      sessionStorage.removeItem("SubprocessName");
      sessionStorage.setItem(
        "SubprocessName",
        sessionStorage.getItem("SelectedSubprocessName")
      );
    }
    //debugger;
    SubProcessSubmitForTimer();
    SubmitDashboardData();
    //sessionStorage.setItem("ProcessId", selectedSubprocess);
    //FetchDefaultValue();
    // getDashboardDetails();
    // FetchNewlyCreatedWorkItems(selectedSubprocess);
    // FetchBacklogWorkItems(selectedSubprocess);
    // FetchCompletedWorkitems(selectedSubprocess);
    // FetchRemainingWorkitems(selectedSubprocess);
    // FetchWeeklyPerformance(selectedSubprocess);
    // FetchOverallPerformance(selectedSubprocess);
    //setDepartments([]);
  };
  const preferenceDefault = async (processSubProcessID) => {
    set_isLoadingDashboard(true);
    setSelectedSubprocess(processSubProcessID);
    sessionStorage.setItem("ProcessId", processSubProcessID);
    let body = {
      subprocessID: processSubProcessID,
      personID: sessionStorage.getItem("PersonID"),
      personType: sessionStorage.getItem("PersonType"),
      userName: sessionStorage.getItem("UserName"),
      HRISEmployeeID: sessionStorage.getItem("HRISEmployeeID"),
      ApplicationName: sessionStorage.getItem("ApplicationURL"),
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_ASSIGNEDWORKITEMDETAILSFORUSER);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          //console.log(result.data);
          setAssignedWorkItemDetails(result.data);
          sessionStorage.setItem(
            "allowedProcesses",
            JSON.stringify(result.data)
          );
          setnav(result.data);
          const navdata = result.data;
          // assignedWorkItemDetails.map((item) => {
          //   statusArr.push(getList(assignedWorkItemDetails, item.activityName));
          // });
          setList(statusArr);
          set_isLoadingDashboard(false);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setAssignedWorkItemDetails([]);
          setnav([]);
          setList([]);
          set_isLoadingDashboard(false);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " preferenceDefault ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " FetchDepartmentbyGUID ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const FetchDefaultValue = () => {
    let body = {};
    if (sessionStorage.getItem("ProcessId") == null) {
      body = {
        hrisEmployeeid: sessionStorage.getItem("HRISEmployeeID"), //added by #Santosh
        userId: sessionStorage.getItem("GUID"),
        companyDeparmentID: "",
        locationClientID: "",
        processSubProcessID: "",
      };
    } else {
      body = {
        hrisEmployeeid: sessionStorage.getItem("HRISEmployeeID"), //added by #Santosh
        userId: sessionStorage.getItem("GUID"),
        companyDeparmentID: sessionStorage.getItem("selectedDepartment"),
        locationClientID: sessionStorage.getItem("selectedLocation"),
        processSubProcessID: sessionStorage.getItem("ProcessId"),
      };
    }

    let loginUrl = getAPIURL(REACT_APP_FETCH_FETCHDEFAULTVALUE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200 && result.data.processSubProcessID != "") {
          preferenceDefault(result.data.processSubProcessID);
          //#Santosh
          
          if (result.data.isAutoLogOut == true) {
            autoLogOut();
          }
        
          if (result.data.clientLogoForUI != null) {
            sessionStorage.setItem("clientLogo", result.data.clientLogoForUI);
          }

          // FetchWeeklyPerformance(result.data.processSubProcessID);
          // FetchOverallPerformance(result.data.processSubProcessID);
          // FetchNewlyCreatedWorkItems(result.data.processSubProcessID);
          FetchGraphTilesRecords(result.data.processSubProcessID);
          set_isLoadingDashboard(false);
          preferenceClose();
          setisSubmit(false);
          setcompanyDeparmentID(result.data.companyDeparmentID);
          setSelectedDepartment(result.data.companyDeparmentID);
          sessionStorage.setItem(
            "selectedDepartment",
            result.data.companyDeparmentID
          );
          setcompanyDepartment(result.data.companyDepartment);
          sessionStorage.setItem(
            "selectedDepartmentName",
            result.data.companyDepartment
          );
          FetchLocationClientsByGuidOnPageLoad(result.data.companyDeparmentID);
          setlocationClientID(result.data.locationClientID);
          setSelectedLocation(result.data.locationClientID);
          GetSubprocessConfig(result.data.processSubProcessID);
          sessionStorage.setItem(
            "selectedLocation",
            result.data.locationClientID
          );
          setlocationClient(result.data.locationClient);
          sessionStorage.setItem(
            "selectedLocationName",
            result.data.locationClient
          );
          FetchProcessSubProcessByGuidOnPageLoad(result.data.locationClientID);
          setprocessSubProcessID(result.data.processSubProcessID);
          sessionStorage.setItem("ProcessId", result.data.processSubProcessID);
          setprocessSubProcess(result.data.processSubProcess);
          sessionStorage.setItem(
            "SelctedSubprocessName",
            result.data.processSubProcess
          );
          FetchSubprocessName(
            result.data.processSubProcessID,
            result.data.locationClientID
          );

          if(sessionStorage.getItem("redirectUrl")!= '' && sessionStorage.getItem("redirectUrl")!= null)
            {
              navigate(sessionStorage.getItem("redirectUrl"),{
                state:{
                  row,
                  selectedSubprocess,
                  nav,
                  userRoles,
                  defaultFilter,
                  assignedWorkItemDetails,
                }
              });
            }

          //SubProcessSubmitForTimer();
          // FetchNewlyCreatedWorkItems(result.data.processSubProcessID);
          // FetchBacklogWorkItems(result.data.processSubProcessID);
          // FetchCompletedWorkitems(result.data.processSubProcessID);
          // FetchRemainingWorkitems(result.data.processSubProcessID);
          // FetchWeeklyPerformance(result.data.processSubProcessID);
          // FetchOverallPerformance(result.data.processSubProcessID);
          //setfetchWeeklyPerformance
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " FetchDefaultValue ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    preferenceDefault(processSubProcessID);
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    //setcompanyDepartment(result.data.companyDepartment);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    sessionStorage.setItem("selectedLocation", locationClientID);
    //setlocationClient(result.data.locationClient);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    //setprocessSubProcess(locationClientID);
    FetchSubprocessName(processSubProcessID, locationClientID);
    // FetchNewlyCreatedWorkItems(processSubProcessID);
    // FetchBacklogWorkItems(processSubProcessID);
    // FetchCompletedWorkitems(processSubProcessID);
    // FetchRemainingWorkitems(processSubProcessID);
    // FetchWeeklyPerformance(processSubProcessID);
    // FetchOverallPerformance(processSubProcessID);
    //setfetchWeeklyPerformance
  };

  const FetchTilesNGraphsForSelectedSubprocess = (processSubProcessID) => {
    FetchWeeklyPerformance(processSubProcessID);
    FetchOverallPerformance(processSubProcessID);
    FetchNewlyCreatedWorkItems(processSubProcessID);
    //FetchBacklogWorkItems(processSubProcessID);
    //FetchCompletedWorkitems(processSubProcessID);
    //FetchRemainingWorkitems(processSubProcessID);
    //preferenceDefault(processSubProcessID);
  };
  const FetchOverallPerformance = (processSubProcessID) => {
    //set_IsLoading(true);
    set_isChartOverall(true);
    let body = {
      //personId: sessionStorage.getItem("PersonID"),
      subProcessId: processSubProcessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_OVERALLPERFORMANCE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setfetchOverallPerformance(result.data);
          set_isChartOverall(false);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          set_isChartOverall(false);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " FetchOverallPerformance ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        set_isChartOverall(false);
        //   navigate("/");
        // }
      });
    //set_IsLoading(false);
  };
  const FetchWeeklyPerformance = async (processSubProcessID) => {
    set_isChart(true);
    //set_IsLoading(true);
    let body = {
      personId: +sessionStorage.getItem("PersonID"),
      subProcessId: processSubProcessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_WEEKLYPERFORMANCE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setfetchWeeklyPerformance(result.data);
          set_isChart(false);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          set_isChart(false);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " FetchWeeklyPerformance ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        set_isChart(false);
        //set_IsLoading(false);
        //   navigate("/");
        // }
      });
  };
  const FetchNewlyCreatedWorkItems = async (processSubProcessID) => {
    let body = {
      personId: +sessionStorage.getItem("PersonID"),
      subProcessId: processSubProcessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_NEWLYCREATEDWORKITEMS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // setnewlyCreatedAssigned(result.data.data[0].value);
          // setnewlyCreatedTotal(result.data.data[1].value);
          // setCreatedKPI(result.data.data);
          setnewlyCreatedAssigned(result.data.data_DashBoard[0][0].value);
          setnewlyCreatedTotal(result.data.data_DashBoard[0][1].value);
          setCreatedKPI(result.data.data_DashBoard[0]);

          setbacklogItemCount(result.data.data_DashBoard[1][0].value);
          settotalBacklogCount(result.data.data_DashBoard[1][1].value);
          setBacklogKPI(result.data.data_DashBoard[1]);

          setcompletedItemCount(result.data.data_DashBoard[2][0].value);
          settotalCompletedCount(result.data.data_DashBoard[2][1].value);
          setcompletedKPI(result.data.data_DashBoard[2]);

          settotalRemaining(result.data.data_DashBoard[3][0].value);
          setremainingItem(result.data.data_DashBoard[3][1].value);
          setremainingKPI(result.data.data_DashBoard[3]);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " FetchNewlyCreatedWorkItems ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const FetchBacklogWorkItems = async (processSubProcessID) => {
    let body = {
      personId: +sessionStorage.getItem("PersonID"),
      subProcessId: processSubProcessID,
    };
    //console.log(body);
    let loginUrl = getAPIURL(REACT_APP_FETCH_BLACKLOGWORKITEMS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setbacklogItemCount(result.data.data[0].value);
          settotalBacklogCount(result.data.data[1].value);
          setBacklogKPI(result.data.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        // if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " FetchBacklogWorkItems ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const FetchCompletedWorkitems = async (processSubProcessID) => {
    let body = {
      personId: +sessionStorage.getItem("PersonID"),
      subProcessId: processSubProcessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_COMPLETEDWORKITEMS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setcompletedItemCount(result.data.data[0].value);
          settotalCompletedCount(result.data.data[1].value);
          setcompletedKPI(result.data.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " FetchCompletedWorkitems ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const FetchRemainingWorkitems = async (processSubProcessID) => {
    let body = {
      personId: +sessionStorage.getItem("PersonID"),
      subProcessId: processSubProcessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_REMAININGWORKITEMS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          settotalRemaining(result.data.data[1].value);
          setremainingItem(result.data.data[0].value);
          setremainingKPI(result.data.data);
          //console.log(result);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " FetchRemainingWorkitems ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };

  const updateColumns = (keys) => {
    col = [];
    keys.forEach((item) => {
      if (item == "subprocessId") {
      } else if (item == "name") {
      } else {
        col.push(item);
      }
    });
    setcolumn(col);
    ////;
  };
  const FetchGraphTilesRecords = (
    processSubProcessID,
    hardRefresh = false,
    flag = false
  ) => {
    setGraphTilesRecords(null);
    setGraphTilesRecordsAgeing([]);
    setGraphTilesRecordsSLA([]);
    setrefreshloading(true);
    const userWiseAPI = REACT_APP_FetchGraphTilesCountbyUserId;
    const subprocessWiseAPI = REACT_APP_FETCHGRAPHTILESCOUNT;

    const apiEndpoint = flag ? subprocessWiseAPI : userWiseAPI;

    let body = {
      subProcessId: sessionStorage.getItem("ProcessId"),
      refresh: hardRefresh,

      ...(flag ? {} : { personId: sessionStorage.getItem("PersonID") }),
    };
    let loginUrl = getAPIURL(apiEndpoint);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        setrefreshloading(false);
        if (result.status == 200) {
        //  console.log("abcd");
          sessionStorage.setItem("homegraphdata", JSON.stringify(result.data));
          setGraphTilesRecords(result.data);
          setGraphTilesRecordsAgeing(result.data.homePageGraphAgeingResponse);
          setGraphTilesRecordsSLA(result.data.homePageGraphSLAResponse);
          updateColumns(
            Object.keys(result.data.homePageGraphAgeingResponse[0])
          );
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        setrefreshloading(false);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " FetchGraphTilesRecords ", body);
        }
      });
  };

  const handleUserWiseButtonClick = () => {
    setActiveTab("MyView");
    FetchGraphTilesRecords(processSubProcessID, true, false); // Call user-wise API
  };

  const handleSubprocessWiseButtonClick = () => {
    if (isSubprocessView) {
      setActiveTab("SubprocessView");
      FetchGraphTilesRecords(processSubProcessID, false, true); // Call subprocess-wise API if super admin
    } else {
      window.alert("Insufficient permissions to access subprocess-wise data.");
    }
  };

  const FetchBreakTimerOnLoad = () => {
    // //debugger;
    if (
      sessionStorage.getItem("selectedEntitiesDetail") != "null" &&
      sessionStorage.getItem("selectedEntitiesDetail") != "undefined" &&
      sessionStorage.getItem("selectedEntitiesDetail") != null &&
      sessionStorage.getItem("PageId") != "" &&
      sessionStorage.getItem("PageId") != null &&
      sessionStorage.getItem("PageId") != "null" &&
      sessionStorage.getItem("PageId") != 0 &&
      sessionStorage.getItem("PageId") != "0"
    ) {
      let today = new Date();
      let hours = today.getHours();
      let minutes = today.getMinutes();
      let seconds = today.getSeconds();
      let currentdate =
        today.getMonth() +
        1 +
        "/" +
        today.getDate() +
        "/" +
        today.getFullYear();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      let strTime =
        currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

      let body = {
        timerStart: strTime,
        subProcessID:
          sessionStorage.getItem("ProcessId") == null
            ? ""
            : sessionStorage.getItem("ProcessId"),
        activityID:
          sessionStorage.getItem("PageId") == null
            ? 0
            : sessionStorage.getItem("PageId"), //for home page
        workItemID: 0,
        employeeTimeUtilization:
          sessionStorage.getItem("empTimeUtilization") == "null" ||
          sessionStorage.getItem("empTimeUtilization") == "undefined" ||
          sessionStorage.getItem("empTimeUtilization") == null
            ? []
            : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
        selectedEntitiesDetail:
          sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
          sessionStorage.getItem("selectedEntitiesDetail") == "undefined" ||
          sessionStorage.getItem("selectedEntitiesDetail") == null
            ? {}
            : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
        breakStartTime: "",
        txtBreakComments: "",
        timerValue:
          JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData")) ==
          null
            ? 1
            : JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
                .timerValue,
        personID: sessionStorage.getItem("PersonID"),
        tSaveTimerInterval: 60000,
      };
      let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_FETCHBREAKTIMERONLOAD);
      let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            //debugger
            // settimerStart(result.timerStart);
            // setbtnShowBreakPanelDisabled(result.btnShowBreakPanelDisabled);
            // setbtnShowBreakPanelColor(result.btnShowBreakPanelColor);
            // setddlActivityEnabled(result.ddlActivityEnabled);
            // setobjdataview(result.data[0].objdataview);
            if (result.data[0].tSaveTimerEnabled) {
              //  setrunSaveTImer(true);
            }
            sessionStorage.setItem(
              "objdataview",
              JSON.stringify(result.data[0].objdataview)
            );

           // debugger
            dispatch(GetUpdatedTimeCapturing(result.data[0]));

            // sessionStorage.setItem(
            //   "FetchBreakTimerOnLoadData",
            //   JSON.stringify(result.data[0])
            // );

            sessionStorage.setItem(
              "empTimeUtilization",
              JSON.stringify(result.data[0].employeeTimeUtilization)
            );
            // setddlActivitySelectedValue(result.ddlActivitySelectedValue);
            // settxtBreakCommentsEnabled(result.txtBreakCommentsEnabled);
            // settimerValue(result.timerValue);
            // sethdnSystemBreakValue(result.hdnSystemBreakValue);
            // setimgActivityToolTip(result.imgActivityToolTip);
            // settSaveTimerEnabled(result.tSaveTimerEnabled);
            // setbreakStartTime(result.breakStartTime);
            // setbtnStartWorkTimerDisabled(result.btnStartWorkTimerDisabled);
            // setemployeeTimeUtilization(result.employeeTimeUtilization);
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          //console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(
              error.toString() + " FetchBreakTimerOnLoad ",
              body
            );
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
        });
    }
  };
  const GetSubprocessConfig = async (processSubProcessID) => {
    debugger;
    let isSubmit=false;
    if(processSubProcessID!=0 && processSubProcessID!=undefined && processSubProcessID!=null)
    {
      isSubmit=true;
    }
if(isSubmit){
    let body = {
      
      subProcessID: processSubProcessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHISSYSTEMTIMEZONE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          debugger;
          if(result.data!=undefined && result.data!=null)
          {
            sessionStorage.setItem("IsSystemTimeZone",result.data);
          }
        } else if (result.status === 401) {
          
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          //navigate("/");
        } else {
          //debugger;
          
          addToast("Something Went Wrong", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
}

  useEffect(() => {
    if (sessionStorage.getItem("SelctedSubprocessName") === null) {
      preferenceShow();
    }
    if (sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == "1") {
      FetchBreakTimerOnLoad();
    }
    setUserButtonShow(sessionStorage.getItem("is_userPreference"));
    setShowModalButton(false);
    setuserRoles(Roles);
    FetchDefaultValue();
    
    FetchDepartmentbyGUID();
    
    if(enablePwdPolicy === '1' && sessionStorage.getItem("PersonType") === "Client" && remainingPwdExpiryDays < 8){
      addToast("Your password will expire after "+remainingPwdExpiryDays+" days !", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    
    return () => {
      clearInterval(interval);
      // alert('unmount')
    };
  }, []);
  // const getList = (assignedWorkItemDetails, activityName) => {
  //   const arr = [];
  //   let name = activityName;
  //   for (let i = 0; i < assignedWorkItemDetails.length; i++) {
  //     if (assignedWorkItemDetails[i].activityName != activityName) {
  //       let activity = assignedWorkItemDetails[i].activityName;
  //       if (assignedWorkItemDetails[i].activityName == "Client") {
  //         arr.push(
  //           '<li class="done" data-status="Assigned"><b class="icon-user-check"></b><span>' +
  //             activity +
  //             "</span></li>"
  //         );
  //       } else if (assignedWorkItemDetails[i].activityName == "Processing") {
  //         arr.push(
  //           '<li class="done" data-status="Progress"><b class="icon-clock"></b><span>' +
  //             activity +
  //             "</span></li>"
  //         );
  //       } else if (
  //         assignedWorkItemDetails[i].activityName.includes("Approver")
  //       ) {
  //         arr.push(
  //           '<li class="done" data-status="Review"><b class="icon-eye"></b><span>' +
  //             activity +
  //             "</span></li>"
  //         );
  //       } else if (assignedWorkItemDetails[i].activityName == "Exceptions") {
  //         arr.push(
  //           '<li class="done" data-status="Pending"><b class="icon-alert-triangle"></b><span>' +
  //             activity +
  //             "</span></li>"
  //         );
  //       } else
  //         arr.push(
  //           '<li class="done" data-status="Success"><b class="icon-check-circle"></b><span>' +
  //             activity +
  //             "</span></li>"
  //         );
  //     } else {
  //       if (activityName == "Client") {
  //         arr.push(
  //           '<li class="active" data-status="Assigned"><b class="icon-user-check"></b><span>' +
  //             name +
  //             "</span></li>"
  //         );
  //       } else if (activityName == "Processing") {
  //         arr.push(
  //           '<li class="active" data-status="Progress"><b class="icon-clock"></b><span>' +
  //             name +
  //             "</span></li>"
  //         );
  //       } else if (activityName.includes("Approver")) {
  //         arr.push(
  //           '<li class="active" data-status="Review"><b class="icon-eye"></b><span>' +
  //             name +
  //             "</span></li>"
  //         );
  //       } else if (activityName == "Exceptions") {
  //         arr.push(
  //           '<li class="active" data-status="Pending"><b class="icon-alert-triangle"></b><span>' +
  //             name +
  //             "</span></li>"
  //         );
  //       } else
  //         arr.push(
  //           '<li class="active" data-status="Success"><b class="icon-check-circle"></b><span>' +
  //             name +
  //             "</span></li>"
  //         );
  //     }
  //   }
  //   var finalList = "";
  //   for (let i = 0; i < arr.length; i++) {
  //     finalList = finalList + arr[i];
  //   }
  //   return finalList;
  // };
  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          //unauthorized
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " FetchLocationClientsByGuidOnPageLoad ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setShowModalButton(false);
      setClientLocations([]);
    }
    if (e.target.value !== "Select") {
      sessionStorage.setItem(
        "selectedDepartment",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedDepartmentName",
        e.target.value.split("/")[0]
      );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            //unauthorized
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          //console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(
              error.toString() + " FetchLocationClientsByGuid ",
              body
            );
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
        });
    }
  };
  const FetchInvalidProcessSubprocessByGuid = (e) => {
    if (e.target.value == "Select") {
      setShowModalButton(false);
    } else setShowModalButton(true);
    setSelectedSubprocess(e.target.value);
  };
  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // sessionStorage.setItem(
          //   "SelctedSubprocessName",
          //   result.data[0].processSubProcess
          // );
          SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " FetchSubprocessName ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setShowModalButton(false);
    } else {
      setSelectedLocation(e.target.value);
      let result = "";
      let locationArray = e.target.value.split("/");
      for (let i = 0; i < locationArray.length - 1; i++) {
        result = result.concat(locationArray[i]);
        if (i < locationArray.length - 2) {
          result = result.concat("/");
        }
      }
      sessionStorage.setItem(
        "selectedLocation",
        e.target.value.split("/")[e.target.value.split("/").length - 1]
      );
      sessionStorage.setItem("selectedLocationName", result);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        subProcessId: "",
        clientId: locationArray[locationArray.length - 1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setprocessSubprocess(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
            setprocessSubprocess([]);
          } else {
            setprocessSubprocess([]);
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          //console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(
              error.toString() + " FetchProcessSubProcessByGuid 1 ",
              body
            );
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
        });

      loginUrl = getAPIURL(REACT_APP_FETCH_INVALIDPROCESSSUBPROCESS);
      req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setinvalidProcessSubprocess(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
            setinvalidProcessSubprocess([]);
          } else {
            setinvalidProcessSubprocess([]);
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          //console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(
              error.toString() + " FetchProcessSubProcessByGuid 2 ",
              body
            );
            //navigate("/ErrorPage");
            //window.location.href = "/Home";
          }
        });
    }
  };
  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " FetchProcessSubProcessByGuidOnPageLoad ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };

  const FetchWorkitemsCount = async (processSubProcessID) => {
    let body = {
      personId: +sessionStorage.getItem("PersonID"),
      subProcessId: processSubProcessID,
    };
    //console.log(body);
    let loginUrl = getAPIURL(REACT_APP_FETCHWORKITEMSCOUNT);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          //debugger;
          setbacklogItemCount(result.data.data[0].value);
          settotalBacklogCount(result.data.data[1].value);
          setBacklogKPI(result.data.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        // if (result.status === 401) {
        //console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " FetchBacklogWorkItems ", body);
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };

  // BAR CHART FUNTION
  const dataBar = [
    {
      name: "Process A",
      uv: 40,
      pv: 24,
      amt: 14,
    },
    {
      name: "Process B",
      uv: 30,
      pv: 13,
      amt: 18,
    },
    {
      name: "Process C",
      uv: 20,
      pv: 68,
      amt: 24,
    },
    {
      name: "Process D",
      uv: 27,
      pv: 39,
      amt: 20,
    },
    {
      name: "Process E",
      uv: 18,
      pv: 48,
      amt: 15,
    },
    {
      name: "Process F",
      uv: 23,
      pv: 38,
      amt: 25,
    },
  ];

  // AREA CHART FUNTION
  const dataArea = [
    {
      name: "Qtr 1",
      Revenue: 100,
      Profit: 140,
    },
    {
      name: "Qtr 2",
      Revenue: 300,
      Profit: 240,
    },
    {
      name: "Qtr 3",
      Revenue: 250,
      Profit: 50,
    },
    {
      name: "Qtr 4",
      Revenue: 400,
      Profit: 140,
    },
  ];
  //PIE CHART FUNCTION
  const newlycreatedData = [
    { name: "Assigned", Count: newlyCreatedAssigned },
    { name: "Total Created", Count: newlyCreatedTotal },
  ];
  const backlogItemData = [
    { name: "Mine", Count: backlogItem },
    { name: "Total", Count: totalBacklogCount },
  ];
  const processedTodayCountData = [
    { name: "Mine", Count: completedItemCount },
    { name: "Total", Count: totalCompletedCount },
  ];
  const remainingItemData = [
    { name: "Mine", Count: remainingItem },
    { name: "Total", Count: totalRemaining },
  ];
  //console.log();

  // TABLE
  const dataTable = [
    {
      id: "01",
      fileName: "Sample_01.pdf",
      status: "Success",
      createDate: "2022-02-06",
      createTime: "11:00",
      assigned: {
        image: {
          url: "/static/media/user01.jpg",
        },
        name: "Anne Richard",
      },
    },
    {
      id: "02",
      fileName: "Sample_02.pdf",
      status: "Progress",
      createDate: "2022-02-12",
      createTime: "05:50",
      assigned: {
        image: {
          url: "/static/media/user02.jpg",
        },
        name: "David Harrison",
      },
    },
    {
      id: "03",
      fileName: "Sample_03.pdf",
      status: "Pending",
      createDate: "2022-03-22",
      createTime: "18:00",
      assigned: {
        image: {
          url: "/static/media/user03.jpg",
        },
        name: "Ella Lauda",
      },
    },
  ];

  const elementsHtml = dataTable.map((item, index) => {
    return (
      <tr key={"row_" + index}>
        <td className="text-center">
          <input className="form-check-input" type="checkbox" id="chkItemAssigned" />
        </td>
        <td>{item.fileName}</td>
        <td>
          <div className="userBox">
            <div className="userBox-img">
              {" "}
              <img
                className="avatar-img"
                id="imgItemAssigned"
                src={item.assigned.image.url}
                alt="#"
              />
            </div>
            <div className="userBox-info">{item.assigned.name}</div>
          </div>
        </td>
        <td>
          {item.createDate} {item.createTime}
        </td>
        <td>
          <span className="badge bg-success">{item.status}</span>
        </td>
        <td className="text-center">
          <a className="btn btn-sm btn-outline-ntv rnd">
            <b className="icon-edit-3"></b>
          </a>{" "}
          <a className="btn btn-sm btn-outline-danger ms-2 rnd">
            <b className="icon-trash-2"></b>
          </a>
        </td>
      </tr>
    );
  });

  return (
    <>
      <Header
        onpreferenceShow={preferenceShow}
        timeCaptureActivityName="Welcome"
      />
      <ApplicationUserContext.Provider value={Roles}>
        <DashboardContext.Provider value={nav}>
          <NavDataContext.Provider value={assignedWorkItemDetails}>
            <Nav selectedMenu="home" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <div className="centerSection">
        {/* <div className="pageHeader highLight">
          <div className=" text-end">
            <a
              title="Select Process/Sub-Process"
              className="showPreference"
              onClick={preferenceShow}
            >
              <b className="icon-bookmark"></b>
            </a>
          </div>
        </div> */}
        <div className="pageBody">
          {isLoading ? (
            <HomeDashboardPlaceholder />
          ) : sessionStorage.getItem("SpecificPbiDashboardID") != "undefined" &&
            sessionStorage.getItem("SpecificPbiDashboardID") != null &&
            sessionStorage.getItem("SpecificPbiDashboardID") != "null" ? (
            <>
              {sessionStorage.getItem("SpecificPbiDashboardID") != "" ? (
                <PowerBiFunctions
                  state={{
                    PowerbiWorkspaceId:
                      sessionStorage.getItem("PowerBiWorkspaceID"),
                    PowerbiReportid: sessionStorage.getItem(
                      "SpecificPbiDashboardID"
                    ),
                    cssStyle: "PowerBiHome",
                    centersetion: "CentersetionHome",
                    IsHome: "true",
                  }}
                />
              ) : REACT_APP_COMMONPBIDASHBOARDID != "" ? (
                <PowerBiFunctions
                  state={{
                    PowerbiWorkspaceId: "",
                    PowerbiReportid: "",
                    cssStyle: "PowerBiHome",
                    centersetion: "CentersetionHome",
                  }}
                />
              ) : (
                <ReactDashboard />
              )}
            </>
          ) : (
            <ReactDashboard />
          )}
          {isLoadingDashboard ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="worklogrow fieldSet">
                <h3 class="">Activity logs</h3>

                {assignedWorkItemDetails == undefined ||
                assignedWorkItemDetails.length === 0 ? (
                  <p>Nothing to show.</p>
                ) : (
                  <div className="">
                    <div className="row">
                      {assignedWorkItemDetails.map((dashboard, index) => (
                        <>
                          {index < 6 ? (
                            <>
                              {" "}
                              <div
                                className="col"
                                style={{
                                  maxWidth: "30%",
                                  minWidth: "15%",
                                  paddingRight: "0.5rem !important",
                                  paddingLeft: "0.5rem !important",
                                }}
                              >
                                <div
                                  className="antCard antCard-bottom-border-0"
                                  data-activity={dashboard.activityName}
                                >
                                  <h4>{dashboard.activityName}</h4>
                                  <div className="antCard-main">
                                    <div className="antCard-info">
                                      <div>
                                        {
                                          dashboard.summaryItemCount.split(
                                            "/"
                                          )[0]
                                        }{" "}
                                        <span>(Me)</span>
                                      </div>
                                      <div>
                                        {
                                          dashboard.summaryItemCount.split(
                                            "/"
                                          )[1]
                                        }{" "}
                                        <span>(Total)</span>
                                      </div>
                                    </div>
                                    <div
                                      className="antCard-chart"
                                      style={{ textAlign: "end" }}
                                    >
                                      <div className="avatar avatar-circle">
                                        {" "}
                                        <h3 className="avatar-img">
                                          {" "}
                                          <Link
                                            title="Click to see all workitems"
                                            id="linkToSeeAllWorkitems"
                                            to="/WorkActivityDashboard"
                                            state={{
                                              row: dashboard,
                                              selectedSubprocess,
                                              nav,
                                              userRoles,
                                              assignedWorkItemDetails,
                                              summaryItemCount:
                                                dashboard.summaryItemCount,
                                              selectedWorkItemDetails:
                                                assignedWorkItemDetails.find(
                                                  (e) =>
                                                    e.activityName ===
                                                    dashboard.activityName
                                                ),
                                            }}
                                            onClick={() =>
                                              setProcessName(dashboard)
                                            }
                                          >
                                            <img src={arrowright} alt="Open" />
                                          </Link>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="antCard-pro">
                              <p>(Total Remaining)</p>
                            </div> */}
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </div>
                    {assignedWorkItemDetails.length > 6 ? (
                      <div className="row pt-2">
                        {assignedWorkItemDetails.map((dashboard, index) => (
                          <>
                            {index > 5 ? (
                              <>
                                {" "}
                                <div
                                  className="col"
                                  style={{
                                    maxWidth: "30%",
                                    minWidth: "15%",
                                    paddingRight: "0.5rem !important",
                                    paddingLeft: "0.5rem !important",
                                  }}
                                >
                                  <div
                                    className="antCard antCard-bottom-border-0"
                                    data-activity={dashboard.activityName}
                                  >
                                    <h4>{dashboard.activityName}</h4>
                                    <div className="antCard-main">
                                      <div className="antCard-info">
                                        <div>
                                          {
                                            dashboard.summaryItemCount.split(
                                              "/"
                                            )[0]
                                          }{" "}
                                          <span>(Me)</span>
                                        </div>
                                        <div>
                                          {
                                            dashboard.summaryItemCount.split(
                                              "/"
                                            )[1]
                                          }{" "}
                                          <span>(Total)</span>
                                        </div>
                                      </div>
                                      <div
                                        className="antCard-chart"
                                        style={{ textAlign: "end" }}
                                      >
                                        <div className="avatar avatar-circle">
                                          {" "}
                                          <h3 className="avatar-img">
                                            {" "}
                                            <Link
                                              title="Click to see all workitems"
                                              id="linktoSeeAllWorkitems"
                                              to="/WorkActivityDashboard"
                                              state={{
                                                row: dashboard,
                                                selectedSubprocess,
                                                nav,
                                                userRoles,
                                                assignedWorkItemDetails,
                                                summaryItemCount:
                                                  dashboard.summaryItemCount,
                                                selectedWorkItemDetails:
                                                  assignedWorkItemDetails.find(
                                                    (e) =>
                                                      e.activityName ===
                                                      dashboard.activityName
                                                  ),
                                              }}
                                              onClick={() =>
                                                setProcessName(dashboard)
                                              }
                                            >
                                              <img
                                                src={arrowright}
                                                alt="Open"
                                              />
                                            </Link>
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="antCard-pro">
                              <p>(Total Remaining)</p>
                            </div> */}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      <Modal
        show={preferenceModal}
        id="preferenceModal"
        size="lg"
        onHide={preferenceClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Process/Sub-Process</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="optionWrap">
            <div className="row">
              <div className="col-6">
                <div className="pb-3">
                  <label className="form-label" id="lblCompanyDepartment">Company Department</label>
                  <select
                    id="ddlCompanyDepartment"
                    className="form-control form-control-sm"
                    onChange={FetchLocationClientsByGuid}
                  >
                    <option>Select</option>
                    {departments.map((department) => (
                      <option
                        value={
                          department.companyDepartment +
                          "/" +
                          department.departmentIDString
                        }
                        selected={
                          department.departmentIDString == companyDeparmentID
                        }
                      >
                        {department.companyDepartment}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="pb-3">
                  <label className="form-label" id="lblLocationClient">Location Client</label>
                  <select
                    className="form-control form-control-sm"
                    onChange={FetchProcessSubProcessByGuid}
                    id="ddlLocationClient"
                  >
                    <option>Select</option>
                    {clientLocations.map((location) => (
                      <option
                        value={
                          location.locationClient +
                          "/" +
                          location.clientIDString
                        }
                        selected={location.clientIDString == locationClientID}
                      >
                        {location.locationClient}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="pb-3">
                  <label className="form-label" id="lblProcessSubprocess">Process Sub-Process</label>
                  <select
                    className="form-control form-control-sm"
                    id="ddlProcessSubprocess"
                    onChange={FetchInvalidProcessSubprocessByGuid}
                  >
                    <option>Select</option>
                    {processSubprocess.map((subprocess) => (
                      <option
                        value={
                          subprocess.subProcessIDString +
                          "/" +
                          subprocess.processSubProcess
                        }
                        selected={
                          subprocess.subProcessIDString == processSubProcessID
                        }
                      >
                        {subprocess.processSubProcess}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="pb-3">
                  <label className="form-label" id="lblInvalidProcessSubProcess">
                    Invalid Process Sub-Process
                  </label>
                  <select
                    className="form-control form-control-sm"
                    id="ddlInvalidProcessSubProcess"
                    //onChange={FetchInvalidProcessSubprocessByGuid}
                  >
                    <option>Select</option>
                    {invalidProcessSubprocess.map((subprocess) => (
                      <option
                        value={
                          subprocess.subProcessIDString +
                          "/" +
                          subprocess.processSubProcess
                        }
                        selected={
                          subprocess.subProcessIDString == processSubProcessID
                        }
                      >
                        {subprocess.processSubProcess}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-primary me-3"
            id="btnSetFavorite"
            onClick={setFavorite}
            disabled={!showModalButton}
          >
            Save as favorite
          </button>
          <button
            className="btn btn-primary"
            id="btnSubprocessSubmit"
            onClick={subprocessSubmit}
            disabled={!showModalButton || isSubmit}
          >
            {!isSubmit ? "Submit" : "Loading..."}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Home;

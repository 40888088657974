import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import Modal from "react-bootstrap/Modal";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import MaterialTable, { MTableToolbar } from "material-table";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import tableIcons from "../../../../MaterialTableIcons";
import DeleteIcon from "@mui/icons-material/Delete"; /*added for Delete */
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import { MenuItem, Paper, Select } from "@material-ui/core";

function SubProcessAutoAssignment() {
  const ValueRef = useRef(null);
  const { addToast } = useToasts();
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GETWORKITEMFIELDLIST } = process.env;
  const { REACT_APP_GETACTIVITYFORAUTOASSIGNMENT } = process.env;
  //const {REACT_APP_FETCHEMPLOYEESFORSUBPROCESSACTIVITY} = process.env;
  const { REACT_APP_GETMAPTOFIELDID } = process.env;
  const { REACT_APP_GETWORKITEMFIELDSBYSUBPROCESSIDFORAUTOASSIGNMENT } =
    process.env;
  const { REACT_APP_MOVEORDERAUTOASSIGNWORKITEMFIELD } = process.env;
  const { REACT_APP_DELETEAUTOASSIGNWORKITEMFIELD } = process.env;
  const { REACT_APP_ADDWORKITEMFIELDLISTFORAUTOASSIGNMENTTEST } = process.env;

  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const testRef = useRef(null);
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [subProcessActivityConfigID, setsubProcessActivityConfigID] =
    useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedemployee, setselectedEmployee] = useState([]); //--------added by shrushti

  const [selectedStates, setSelectedStates] = useState({});

  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [showBtn, setUserButtonShow] = useState(false);
  const [assignedWorkItemDetails, setAssignedWorkItemDetails] = useState([]);
  const [allowLocation, setAllowLocation] = useState(false);
  const [allowSubprocess, setAllowSubprocess] = useState(false);
  const [showModalButton, setShowModalButton] = useState(false);
  const [ActivitiesForSubprocess, setActivitiesForSubprocess] = useState([]);
  //const [PriortyForSubProcessDetails, setPriortyForSubProcessDetails] =
  useState([]);
  //const [workitemFieldID, setWorkitemFieldID] = useState([]);
  //const [PriorityJson, setPriorityJson] = useState([]);
  const [Isdepartments, setIsDepartments] = useState([]);
  const [IsclientLocations, setIsClientLocations] = useState([]);
  const [IsprocessSubprocess, setIsprocessSubprocess] = useState([]);
  const [activities, setActivities] = useState([]);
  const [employeesByActivity, setEmployeesByActivity] = useState([]);
  const [ExcelColumns, setExcelColumns] = useState([]);
  const [viewSearchModal, setviewSearchModal] = useState(false);
  const [getWorkItemFields, setgetWorkItemFields] = useState([]);
  const [workitemfieldid, setworkitemfieldid] = useState([]);
  const [getWorkItemFieldList, setgetWorkItemFieldList] = useState([]);
  const [isDateField, setisDateField] = useState("");
  const [advFields, setadvFields] = useState("");
  const [advValue, setadvValue] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [advOperators, setadvOperators] = useState("");
  const [Operator, setOperator] = useState("");
  const [advSearchOperators, setadvSearchOperators] = useState("");
  const [advSearchMapToField, setadvSearchMapToField] = useState("");
  const [advSearchDetails, setadvSearchDetails] = useState([]);
  const [JsonValues, setJsonValues] = useState([]);
  const [advSearchMapToFieldDetails, setadvSearchMapToFieldDetails] = useState(
    []
  );
  const [notEqual, SetnotEqual] = useState("<>");
  const [lessThan, SetlessThan] = useState("<");
  const [lessThanEqual, SetlessThanEqual] = useState("<=");
  const [greaterThen, SetgreaterThen] = useState(">");
  const [greaterThenEqual, SetgreaterThenEqual] = useState(">=");
  const [Isresolve, setIsresolve] = useState(true);
  const [numericOperator, setnumericOperator] = useState(false);

  let advanceColumns = [
    { title: "Fields", field: "Fields" },
    { title: "Operator", field: "Operators" },
    { title: "Value ", field: "Value" },
  ];

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });

  const buttonStyle = {
    backgroundColor: isHovered ? '#FB4E0B' : 'transparent',
    color:isHovered ?  '#fff':'#FB4E0B',
    // color:'#FB4E0B',
    border: '1px solid #FB4E0B',
    padding: '5px 10px',
    cursor: 'pointer',
  };

  const showAdvanceSearch = () => {
    setviewSearchModal(true);
  };

  const viewSearchCloseAndRest = () => {
    setviewSearchModal(false);
    setadvSearchMapToFieldDetails([]);
    setadvFields("");
    setadvSearchMapToField("");
    setadvOperators("");
    //setadvSearchText("");
    setadvSearchOperators("");
    //setsearchWorkitemData([]);
    setadvSearchDetails([]);
  };

  const getAdvanceField = (e) => {
    if(e.target.value!="" && e.target.value!="Select")
    {
      setnumericOperator(false);
      setisDateField(false);
      //setadvValue("");

      let datetimeValue = e.target.value.split("/")[0].includes("DateTime");
      let intValue = e.target.value.split("/")[0].includes("Int");
      let DecimalValue = e.target.value.split("/")[0].includes("Decimal");
      let SLADueDateValue = e.target.value.split("/")[0].includes("SLADueDate");
      let ScanDateValue = e.target.value.split("/")[0].includes("ScanDate");
      let UploadDateValue = e.target.value.split("/")[0].includes("UploadDate");
      if (
        datetimeValue > 0 ||
        intValue > 0 ||
        DecimalValue > 0 ||
        SLADueDateValue > 0 ||
        ScanDateValue > 0 ||
        UploadDateValue > 0
      ) {
        setnumericOperator(true);
      }
      if (datetimeValue > 0) {
        setisDateField(true);
      }

      setadvFields(e.target.value.split("/")[1]);
      setadvSearchMapToField(e.target.value.split("/")[0]);
      setworkitemfieldid(e.target.value.split("/")[2]);
    }
    else{
      setnumericOperator(false);
      setadvFields("");
    }
  };
  const getAdvanceOperators = (e) => {
    if(e.target.value){
      //console.log(e.target.value);
      setadvOperators(e.target.value.split("/")[0]);
      setOperator(e.target.value.split("/")[1]);
    }else{
      setadvOperators("");
      setOperator("");
    }    
  };

  const HandlesetadvValue = (e) => {
    setadvValue(e.target.value);
  }

  const addAdvanceSearchConditions = () => {
    //setadvSearchText()
    // alert(advSearchField+' ' +advCondition+' '+SearchTextRef.current.value);
    // alert(advSearchMapToField+' ' +advCondition+' '+SearchTextRef.current.value);
    if (advFields != "" && advFields!="Select" && advOperators != "Select" && advOperators!="" && advValue != "") {
      let condition = {
        Fields: advFields,
        Operators: Operator,
        Value: advValue
      };

      let mapToCondition = {
        Fields: advSearchMapToField,
        Operators: advOperators == '0' ? 'Like' : advOperators == '1' ? 'Like': advOperators == '2' ? 'Like' :advOperators == '3' ? 'Not Like' : advOperators,
        // Value: 
        // advOperators == '0' ? "'"+ValueRef.current.value+"%'" : advOperators == '1' ? "'%"+ValueRef.current.value+"'": advOperators == '2' ? "'%"+ValueRef.current.value+"%'" : "'"+ValueRef.current.value+"'",
        //value:  advSearchMapToField + " " + Operator + " " + ValueRef.current.value,
        Value: 
        advOperators == '0' ? "'"+advValue+"%'" : advOperators == '1' ? "'%"+advValue+"'": advOperators == '2' ? "'%"+advValue+"%'" : "'"+advValue+"'",
      };
      setadvSearchDetails((advSearchDetails) => [
        ...advSearchDetails,
        condition,
      ]);
      setadvSearchMapToFieldDetails((advSearchMapToFieldDetails) => [
        ...advSearchMapToFieldDetails,
        mapToCondition,
      ]);
      setadvFields("");
      setadvSearchMapToField("");
      //setworkitemfieldid("");
      //setadvOperators("");
      setOperator("");
      setadvValue("");
    } else {
      addToast("Enter Rule Values", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    const dataDelete = [...advSearchDetails];
    const mapDataDelete = [...advSearchMapToFieldDetails];
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    mapDataDelete.splice(index, 1);
    setadvSearchDetails([...dataDelete]);
    setadvSearchMapToFieldDetails([...mapDataDelete]);
    resolve();
  };

  const viewSearchClose = () => {
    setviewSearchModal(false);
    //AddWorkItemFieldForAutoAssignMentTest();
  };

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setShowModalButton(false);
      setClientLocations([]);
      setActivitiesForSubprocess([]);
      setIsDepartments(false);
      setIsClientLocations(false);
      //setIsDepartments(false);
      setIsprocessSubprocess(false);
      //setIsActivitiesForSubprocess(false);
      //GetActivityForAutoAssignment([]);
    }
    if (e.target.value !== "Select") {
      setIsDepartments(true);
      sessionStorage.setItem(
        "selectedDepartment",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedDepartmentName",
        e.target.value.split("/")[0]
      );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setShowModalButton(false);
      setIsprocessSubprocess(false);
      setIsClientLocations(false);
    } else {
      if (e.target.value !== "Select") {
        setSelectedLocation(e.target.value);
        setIsClientLocations(true);
        let result = "";
        let locationArray = e.target.value.split("/");
        for (let i = 0; i < locationArray.length - 1; i++) {
          result = result.concat(locationArray[i]);
          if (i < locationArray.length - 2) {
            result = result.concat("/");
          }
        }
        sessionStorage.setItem(
          "selectedLocation",
          e.target.value.split("/")[e.target.value.split("/").length - 1]
        );
        sessionStorage.setItem("selectedLocationName", result);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: locationArray[locationArray.length - 1],
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
              GetActivityForAutoAssignment([]);
              GetWorkItemFieldListForAutoAssignment([]);
              GetWorkItemFieldsbySubProcessIDForAutoAssignment([]);
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
              setprocessSubprocess([]);
            } else {
              setprocessSubprocess([]);
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //   navigate("/");
            // }
          });
      }
    }
  };

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };

  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };

  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          GetActivityForAutoAssignment();
          GetWorkItemFieldListForAutoAssignment();
          GetWorkItemFieldsbySubProcessIDForAutoAssignment();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          sessionStorage.setItem(
            "SelctedSubprocessName",
            result.data[0].processSubProcess
          );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const HandleEmployeeChange = (e, activityID, name) => {
    let employeeID = parseInt(e.target.value);
    let selectedActivity = activities.find(
      (activity) => activity.activityID === activityID
    );
    let currentemployeeselection = selectedActivity.employeelist.find(
      (employee) => employee.employeeID === employeeID
    );

    let filteredSelections = selectedemployee.filter(
      (selection) => selection.currentActivity.activityID !== activityID
    );

    let obj = {
      currentActivity: selectedActivity,
      employeeselection: currentemployeeselection,
    };
    setselectedEmployee([...filteredSelections, obj]);
    // setSelectedStates(s => ({
    //   ...s,
    //   [activityID]:employeeID
    // }))
    
  };
  //added for addworkitemfield list

  const AddWorkItemFieldForAutoAssignMentTest = () => {
    let assignmentXMLRequest = [];
    if(advSearchMapToFieldDetails.length===0 || workitemfieldid.length===0 || advOperators.length===0){
      addToast("Please provide Fields Values for assignment.", {
        appearance: "error",
        autoDismiss: true,
      });
    }else{
      if(selectedemployee.length===0 || selectedemployee[0]=="" || selectedemployee[0]=="undefined"|| selectedemployee[0]=="null" || selectedemployee[0].employeeselection==undefined){
        addToast("Please select at least an employee for assignment.", {
          appearance: "error",
          autoDismiss: true,
        });
      }else{
        selectedemployee.forEach((element) => {
          const input = {
            activityID: "" + element.currentActivity.activityID,
            assignTo: "" + element.employeeselection.employeeID,
          };
          assignmentXMLRequest.push(input);
        });
        let body = {
          longSubProcessID: testRef.current.value,
          _assignmentXML: assignmentXMLRequest,
          workItemFieldID: workitemfieldid,
          operator: advOperators,
          value: advSearchMapToFieldDetails
            .map(
              ({ Fields, Operators, Value }) => `${Fields} ${Operators} ${Value}`
            )
            .join(" AND "),
          strCurrentUserID: sessionStorage.getItem("PersonID"),
          rules: advSearchDetails
            .map(
              ({ Fields, Operators, Value }) => `${Fields} ${Operators} ${Value}`
            )
            .join(" ,"),
        };
    
        let loginUrl = getAPIURL(
          REACT_APP_ADDWORKITEMFIELDLISTFORAUTOASSIGNMENTTEST
        );
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              addToast("Row added successfully", {
                appearance: "success",
                autoDismiss: true,
              });
              setselectedEmployee([]);
              GetWorkItemFieldListForAutoAssignment(processSubProcessID);
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      }
    }
   // setselectedEmployee([]);
  };

  var rows = [],
    i = 1,
    len = 200;
  while (i <= len) {
    rows.push(i);
    i++;
  }

  const columns = [
    {
      title: "Assign To",
      field: "name" /*call from API */,
    },
    {
      title: "Activity Name",
      field: "activityName" /*call from API */,
    },
    {
      title: "Rules",
      field: "rules" /*call from API */,
    },
  ];

  const GetWorkItemFieldListForAutoAssignment = (e) => {
    let body = {
      longSubProcessID: testRef.current.value,
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKITEMFIELDLIST);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setgetWorkItemFieldList(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }else if(result.status === 404){
          setgetWorkItemFieldList(result.data);
        }else{
          setgetWorkItemFieldList(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  useEffect(() => {
    setselectedEmployee([]);
  }, []);

  const DeleteAutoAssignWorkItemField = (rowData, resolve) => {
    {
      let body = {
        autoAssignmentId: rowData.autoAssignmentID,
      };
      let loginUrl = getAPIURL(REACT_APP_DELETEAUTOASSIGNWORKITEMFIELD);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            addToast("Row deleted successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            GetWorkItemFieldListForAutoAssignment(processSubProcessID);
            resolve();
            //setIsresolve(true);
          } else if (result.status === 401) {
            //setIsresolve(true);
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };

  const HandleMoveUp = (row) => {
    {
      let body = {
        autoAssignmentId: row.autoAssignmentID,
        longSubProcessID: row.subProcessID,
        moveOrder: "MoveUp",
        assignmentorder: row.assignmentOrder,
      };
      let loginUrl = getAPIURL(REACT_APP_MOVEORDERAUTOASSIGNWORKITEMFIELD);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            // addToast("MoveUp successful", {
            //   appearance: "success",
            //   autoDismiss: true,
            // });
            GetWorkItemFieldListForAutoAssignment(processSubProcessID);
            // GetErrorCategories();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };

  const HandleMoveDown = (row) => {
    {
      let body = {
        autoAssignmentId: row.autoAssignmentID,
        longSubProcessID: row.subProcessID,
        moveOrder: "MoveDown",
        assignmentorder: row.assignmentOrder,
      };
      let loginUrl = getAPIURL(REACT_APP_MOVEORDERAUTOASSIGNWORKITEMFIELD);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            // addToast("MoveDown successful", {
            //   appearance: "success",
            //   autoDismiss: true,
            // });
            GetWorkItemFieldListForAutoAssignment(processSubProcessID);
            // GetErrorCategories();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };

  const GetActivityForAutoAssignment = () => {
    // 
    let body = {
      SubProcessID: testRef.current.value,
    };
    let activityUrl = getAPIURL(REACT_APP_GETACTIVITYFORAUTOASSIGNMENT);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => {
        if (!res.ok) {
          // Handle non-successful status codes (e.g., 403, 404, etc.)
          throw new Error("HTTP status " + res.status);
        }
        return res.json();
      })
      .then((result) => {
        if (result.status == 200) {
          setActivities(result.data);
          // FetchEmployeesForSubProcessActivity(activities.subProcessWorkActivityConfigID);
          FetchDepartmentbyGUID();
        }
      })

      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    FetchDepartmentbyGUID();
  }, []);

  const GetWorkItemFieldsbySubProcessIDForAutoAssignment = () => {
    // 
    let body = {
      SubProcessID: testRef.current.value,
    };
    let loginUrl = getAPIURL(
      REACT_APP_GETWORKITEMFIELDSBYSUBPROCESSIDFORAUTOASSIGNMENT
    );
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setgetWorkItemFields(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const NavElements = useMemo(() => {
    return (
      <>
        <Header
          onpreferenceShow={null}
          pageName="Sub-Process Auto Assignment "
        />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav selectedMenu="Configuration" />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);

  return (
    <>
      {NavElements}
      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Company-Department</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchLocationClientsByGuid}
                    >
                      <option>Select</option>
                      {departments.map((department) => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.departmentIDString == companyDeparmentID
                          }
                        >
                          {department.companyDepartment}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Location-Client</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchProcessSubProcessByGuid}
                    >
                      <option>Select</option>
                      {clientLocations.map((location) => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.clientIDString == locationClientID}
                        >
                          {location.locationClient}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Process-SubProcess</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={
                        (e) => {
                          GetActivityForAutoAssignment(e);
                          //FetchEmployeesForSubProcessActivity(e);
                          GetWorkItemFieldsbySubProcessIDForAutoAssignment(e);
                          GetWorkItemFieldListForAutoAssignment(e);
                        }
                        // onChange={GetActivityForAutoAssignment}
                      }
                      ref={testRef}
                    >
                      <option>Select</option>
                      {processSubprocess.map((subprocess) => (
                        <option
                          value={subprocess.subProcessIDString}
                          selected={
                            subprocess.subProcessIDString == processSubProcessID
                          }
                        >
                          {subprocess.processSubProcess}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* <div className="row align-items-end"> */}

                {/* style={{ display: 'flex', flexWrap: 'wrap'}} */}

                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {activities.map((activity) => (
                    <div
                      className="mb-3"
                      key={activity.activityId}
                      style={{ marginRight: "20px" }}
                    >
                      {/* <div className="row align-items-end">  */}
                      <label className="form-label">
                        {activity.activityName +
                          " (" +
                          activity.workItemAllocationMode +
                          ")"}
                      </label>
                      <select
                        className="form-control form-control-sm"
                        style={{ width: "250px" }}
                        value={selectedemployee.find(se => se.currentActivity.activityID === activity.activityID)?.employeeselection?.employeeID || ''}
                        onChange={(e) => HandleEmployeeChange(e, activity.activityID)}
                        defaultValue="-- Select Employee --"
                        //onChange={FetchEmployeesForSubProcessActivity(activity.subProcessWorkActivityConfigID)}
                      >
                        <option selected={true} value="">-- Select Employee --</option>
                        {activity.employeelist.map((employee) => (
                          <option
                            key={employee.employeeID}
                            value={employee.employeeID}
                            label={employee.name+" ("+employee.hrisEmployeeID+")"}
                            selected={employee.name == selectedemployee}
                          />
                        ))}
                      </select>
                    </div>
                  ))}
                </div>

                <div className="col-3">
                  <div className="mb-3" style={{ bottom: "80%", right: "10%" }}>
                    <button
                      style={buttonStyle}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    
                     type="button"
                     className="btn btn-sm btn-outline-primary ms-2"
                     onClick={showAdvanceSearch}
                    >
                      <b className="icon-edit"></b> Assignment Rules
                    </button>
                  </div>
                </div>

                <Modal
                  show={viewSearchModal}
                  dialogClassName="modal-100w"
                  onHide={viewSearchCloseAndRest}
                  fullscreen={"lg-down"}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Assignment Rules</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="timeBreak">
                      <ul className="timeBreak-form">
                        <div className="tableWrap">
                          <div className="tableWrap-body">
                            <table className="table brandTable">
                              <div>
                                <div className="fieldSet pt-1">
                                  {/* <h4 className="mb-4">Advanced Search Criteria</h4> */}
                                  <div className="row align-items-end">
                                    <div className="col-3">
                                      {/* <div className="mb-3"> */}
                                      <label className="form-label">
                                        Fields{" "}
                                      </label>
                                      <select
                                        className="form-control form-control-sm"
                                        title="Select a field"
                                        // value={advFields}
                                        value={`${advSearchMapToField}/${advFields}/${workitemfieldid}`}
                                        onChange={getAdvanceField}
                                        defaultValue="Select"
                                      >
                                        <option value="">Select</option>
                                        {getWorkItemFields.map((status) => (
                                          <option
                                            value={
                                              status.maptofield +
                                              "/" +
                                              status.labeltext +
                                              "/" +
                                              status.workitemfieldid
                                            }
                                          >
                                            {status.labeltext}
                                          </option>
                                        ))}
                                      </select>
                                      {/* </div> */}
                                    </div>
                                    <div className="col-3">
                                      {/* <div className="mb-3"> */}
                                      <label className="form-label">
                                        Operator
                                      </label>
                                      {numericOperator ? (
                                        <select
                                          className="form-control form-control-sm"
                                          title="Select a field to apply filter"
                                          value={`${advOperators}/${Operator}`}
                                          onChange={getAdvanceOperators}
                                          defaultValue="Select"
                                          
                                        >
                                          <option value="">Select</option>
                                          <option value={"=" + "/" + "="}>=</option>
                                          <option value={"<>" + "/" + "<>"}>{notEqual}</option>
                                          <option value={"<"+ "/" + "<"}>{lessThan}</option>
                                          <option value={"<="+ "/" + "<="}>{lessThanEqual}</option>
                                          <option value={">" + "/" + ">"}>{greaterThen}</option>
                                          <option value={">=" + "/" + ">="}>{greaterThenEqual}</option>
                                        </select>
                                      ) : (
                                        <select
                                          className="form-control form-control-sm"
                                          title="Select a field to apply filter"
                                          value={`${advOperators}/${Operator}`}
                                          onChange={getAdvanceOperators}
                                          defaultValue="Select"
                                        >
                                          <option value=" ">Select</option>
                                          <option value={"=" + "/" + "Equals"}>Equal</option>
                                          <option value={"<>" + "/" + "Not Equals"}>Not Equals</option>
                                          <option value={"0" + "/" + "Starts With"}>Starts With</option>
                                          <option value={"1" + "/" + "Ends With"}>Ends With</option>
                                          <option value={"2" + "/" + "Contains"}>Contains</option>
                                          <option value={"3" + "/" + "Does Not Contain"}>Does Not Contain</option>
                                          <option value={"in" + "/" + "IN"}>In</option>
                                        </select>
                                      )}
                                    </div>
                                    <div className="col-3">
                                      {/* <div className="mb-3"> */}
                                      <label className="form-label" > 
                                        Value
                                      </label>
                                      {isDateField ? (
                                        <input
                                          type="date"
                                          className="form-control form-control-sm"
                                         // ref={ValueRef}
                                          value={advValue} 
                                          onChange={HandlesetadvValue}
                                        />
                                      ) : (
                                        <input
                                          className="form-control form-control-sm"
                                         // ref={ValueRef}
                                          value={advValue} 
                                          onChange={HandlesetadvValue}
                                        />
                                      )}
                                    </div>

                                    <div className="col-1">
                                      {/* <div className="mb-3"> */}
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-outline-primary w-100 text-uppercase"
                                        onClick={addAdvanceSearchConditions}
                                      >
                                        {" "}+
                                      </button>
                                      {/* </div> */}
                                    </div>
                                  </div>

                                  <div className="pt-2 pb-2">
                                    <div className="mb-5">
                                      <div>
                                        <table className="table table-borderless MatTable">
                                          {/* <ThemeProvider theme={theme}> */}
                                          <MaterialTable
                                            localization={{
                                              pagination: {
                                                //labelDisplayedRows: '{from}-{to} of {count}'
                                              },
                                              toolbar: {
                                                // nRowsSelected: '{0} row(s) selected'
                                              },
                                              header: {
                                                actions: "",
                                              },
                                            }}
                                            options={{
                                              search: false,
                                              exportButton: false,
                                              tableLayout: "auto",
                                              overflowY: false,
                                              showTitle: false,
                                              toolbarButtonAlignment: "left",
                                              paging: false,
                                              toolbar: false,
                                              headerStyle: {
                                                height: 30,
                                              },
                                              rowStyle: {
                                                // fontFamily: '"Yantramanav", sans-serif',
                                                // fontSize: "14px",
                                              },
                                              tableLayout: "auto",
                                              headerStyle: {
                                                // fontFamily: '"Yantramanav", sans-serif',
                                                // fontWeight: "inherit",
                                                // fontSize: "14px",
                                                // textAlign: "left !Important",
                                              },
                                            }}
                                            editable={{
                                              onRowDelete: (oldData) =>
                                                new Promise((resolve) => {
                                                  handleRowDelete(
                                                    oldData,
                                                    resolve
                                                  );
                                                }),
                                            }}
                                            columns={advanceColumns}
                                            icons={tableIcons}
                                            data={advSearchDetails}
                                          />
                                          {/* </ThemeProvider> */}
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </table>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn btn-outline-ntv mx-2"
                      onClick={() => {
                        AddWorkItemFieldForAutoAssignMentTest();
                        viewSearchCloseAndRest();
                      }}
                    >
                      Add
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>

          <table
            className="table table-borderless MatTable"
            style={{ zIndex: "1", position: "relative" }}
          >
            <ThemeProvider theme={theme}>
              <MaterialTable
                localization={{
                  pagination: {
                    //labelDisplayedRows: '50-51 of 100',
                    labelRowsPerPage: "{50, 100, 250,500}",
                  },
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected'
                    //exportCSVName: "Export as Excel",
                    // exportPDFName: "Export as pdf",
                  },
                  header: {
                    actions: "",
                  },
                }}
                options={{
                  search: false,
                  exportButton: false,
                  tableLayout: "auto",
                  overflowY: false,
                  showTitle: false,
                  toolbarButtonAlignment: "left",
                  paging: false,
                  toolbar: false,
                  headerStyle: {
                    height: 30,
                  },
                  rowStyle: {
                    // fontFamily: '"Yantramanav", sans-serif',
                    // fontSize: "14px",
                  },
                  tableLayout: "auto",
                  headerStyle: {
                    // fontFamily: '"Yantramanav", sans-serif',
                    // fontWeight: "inherit",
                    // fontSize: "14px",
                    // textAlign: "left !Important",
                  },
                }}
                components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                  Toolbar: (props) => (
                    <div
                      style={{
                        backgroundColor: "rgb(206 206 206)",
                        fontSize: "0.75em",
                      }}
                    >
                      <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                    </div>
                  ),
                }}
                columns={columns}
                icons={tableIcons}
                data={getWorkItemFieldList}
                editable={{
                  onRowDelete: (rowData) =>
                    new Promise((resolve) => {
                      DeleteAutoAssignWorkItemField(rowData, resolve);
                    }),
                }}
                actions={[
                  // {
                  //   icon: () => <DeleteIcon/>,
                  //   tooltip: "Delete",
                  //   onClick: (event, rowData) =>
                  //   DeleteAutoAssignWorkItemField(rowData),
                  //   position: "row",
                  // },

                  {
                    icon: () => <ArrowUpward />,
                    tooltip: "MoveUp",
                    onClick: (event, rowData) => HandleMoveUp(rowData),
                    position: "row",
                  },

                  {
                    icon: () => <ArrowDownward />,
                    tooltip: "MoveDown",
                    onClick: (event, rowData) => HandleMoveDown(rowData),
                    position: "row",
                  },
                ]}
              />
            </ThemeProvider>
          </table>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default SubProcessAutoAssignment;

import React, { useState, useEffect, useMemo, useRef } from "react";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import tableIcons from "../../../../MaterialTableIcons";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashboardContext from "../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import "react-table-filter/lib/styles.css";
import Modal from "react-bootstrap/Modal";

import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField
} from "@material-ui/core";

const ValidationRule = () => {
  const RuleNameRef = useRef(null);
  const RuleNameRefpop = useRef("");
  const RuleIDRef = useRef(0);
  const RuleIDRefpop = useRef(0);
  const RuleTypeRef = useRef(null);
  const RuleTypeRefpop = useRef("");
  const ValidatorNameRef = useRef(null);
  const ValidatorNameRefpop = useRef("");
  const ErrorMessageRef = useRef(null);
  const ErrorMessageRefpop = useRef("");
  const RelevantApplicationPageRef = useRef(null);
  const RelevantApplicationPageRefpop = useRef("");
  const isApplicableRef = useRef(null);
  const isApplicableRefpop = useRef("");
  const ButtonTextRef = useRef("");
  const [selectedColumns, setSelectedColumns] = useState();

  const { addToast } = useToasts();

  const { REACT_APP_API_URL } = process.env;

  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const { REACT_APP_GETVALIDATIONRULE } = process.env;
  const { REACT_APP_UPDATEVALIDATIONRULE } = process.env;
  const { REACT_APP_DELETEVALIDATIONRULE } = process.env;
  const { REACT_APP_ADDVALIDATIONRULE } = process.env;
  let interval = null;
  const location = useLocation();
  let navigate = useNavigate();
  const processingDashboardParam = location.state;
  //const [reducer,forceUodate]= useReducer(x => x+1,0);

  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const [searchWorkitemData, setsearchWorkitemData] = useState([]);

  const [Datacount, setDatacount] = useState(0);

  //const [displayOrderArry,setdisplayOrderArry] =useState([]);
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [numberOfRecord, setnumberOfRecord] = useState(100);
  const [searchDivVisible, setsearchDivVisible] = useState(true);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [buttonText, setbuttonText] = useState("Save");
  const [openPopUp, setopenPopUp] = useState(false);
  const [openUpdatePopUp, setopenUpdatePopUp] = useState(false);

  const [formError, setformError] = useState("");
  const [item, setitem] = useState({});

  const popupClose = () => {
    setopenPopUp(false);
    setformError("");
  };
  const popup = () => {
    setopenPopUp(true);
    setformError("");
    // editWorkItemStatus(item);
  };
  const UpdatepopupClose = () => {
    setformError("");
    setopenUpdatePopUp(false);
  };

  const resetForm = () => {
    setformError("");
    RuleNameRef.current.value = "";
    RuleTypeRef.current.value = "-- Select Type --";
    ValidatorNameRef.current.value = "";
    ErrorMessageRef.current.value = "";
    RelevantApplicationPageRef.current.value =
      "-- Select Relevant Application Page --";
    isApplicableRef.current.checked = false;
  };
  const resetUpdateForm = () => {
    setformError("");
    RuleNameRefpop.current.value = "";
    RuleTypeRefpop.current.value = "-- Select Type --";
    ValidatorNameRefpop.current.value = "";
    ErrorMessageRefpop.current.value = "";
    RelevantApplicationPageRefpop.current.value = "";
    isApplicableRefpop.current.checked = false;
  };
  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const updateColumns = keys => {
    // console.log(sessionStorage.getItem("EmployeeVersionID"));
    let col = [];
    keys.forEach(item => {
      if (item != "updatedBy") col.push({ title: item, field: item });
    });
  };
  const Options = [
    { label: "Error", value: "Error" },
    { label: "Warning", value: "Warning" }
  ];
  const Page = [
    {
      label: "Admin/SubProcessUploadExcelColumn.aspx",
      value: "Admin/SubProcessUploadExcelColumn.aspx"
    },
    { label: "Admin/WorkItemFields.aspx", value: "Admin/WorkItemFields.aspx" },
    {
      label: "Configuration/ManageSubProcessConfig.aspx",
      value: "Configuration/ManageSubProcessConfig.aspx"
    },
    {
      label: "Configuration/SubProcessWorkActivityConfigList.aspx",
      value: "Configuration/SubProcessWorkActivityConfigList.aspx"
    },
    {
      label: "Configuration/ManageSubProcessWorkflowConfig.aspx",
      value: "Configuration/ManageSubProcessWorkflowConfig.aspx"
    },
    {
      label: "Configuration/SubProcessWorkActivityDashboardConfigList.aspx",
      value: "Configuration/SubProcessWorkActivityDashboardConfigList.aspx"
    },
    {
      label: "Configuration/SubProcessWorkActivityScreenConfigList.aspx",
      value: "Configuration/SubProcessWorkActivityScreenConfigList.aspx"
    }
  ];

  const AddValidationRule = () => {
    setformError("");
    let isSubmit = true;
    let err = {};
    if (
      RuleNameRef.current.value.length == 0 &&
      RuleNameRef.current.value == ""
    ) {
      /* addToast("Rule Name is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.ruleName = "Rule Name is Required";
      isSubmit = false;
    }

    if (
      RuleTypeRef.current.value == "-- Select Type --" ||
      RuleTypeRef.current.value == ""
    ) {
      /* addToast("Rule Type is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.type = "Rule Type is Required";
      isSubmit = false;
    }
    if (
      ValidatorNameRef.current.value.length == 0 &&
      ValidatorNameRef.current.value == ""
    ) {
      /* addToast("Stored Procedure Name is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.sPName = "Stored Procedure Name is Required";
      isSubmit = false;
    }
    if (
      ErrorMessageRef.current.value.length == 0 &&
      ErrorMessageRef.current.value == ""
    ) {
      /* addToast("Error Message is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.message = "Error Message is Required";
      isSubmit = false;
    }
    if (
      RelevantApplicationPageRef.current.value.length == 0 ||
      RelevantApplicationPageRef.current.value == ""
    ) {
      /* addToast("Relevant Application Page is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.page = "Relevant Application Page is Required";
      isSubmit = false;
    }
    setformError({ ...err });

    if (isSubmit) {
      let loginUrl;
      let body;
      /* if (buttonText == "Save")
    { */
      loginUrl = getAPIURL(REACT_APP_ADDVALIDATIONRULE);
      body = {
        //StatusName: StatusNameRef.current.value,
        RuleID: 0,
        RuleName: RuleNameRef.current.value,
        RuleType: RuleTypeRef.current.value,
        ValidatorName: ValidatorNameRef.current.value,
        UserMessage: ErrorMessageRef.current.value,
        RelevantApplicationPage: RelevantApplicationPageRef.current.value,
        IsApplicable: isApplicableRef.current.checked,
        UpdatedBy: sessionStorage.getItem("PersonID")
      };

      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            addToast("Validation Rule saved successfully", {
              appearance: "success",
              autoDismiss: true
            });
            resetForm();
            GetValidationRule();
            setbuttonText("Save");
          } else if (result.status === 409) {
            // addToast("Record already exists", {
            //   appearance: "error",
            //   autoDismiss: true
            // });
            err = {};
            err.ruleName = "Record Already Exists";
            setformError({ ...err });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something Went Wrong", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
        });
    }
  };
  const AddValidationRuleSave = () => {
    setformError("");
    let isSubmit = true;
    let err = {};
    if (
      RuleNameRef.current.value.length == 0 &&
      RuleNameRef.current.value == ""
    ) {
      /* addToast("Rule Name is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.ruleName = "Rule Name is Required";
      isSubmit = false;
    }

    if (
      RuleTypeRef.current.value == "-- Select Type --" ||
      RuleTypeRef.current.value == ""
    ) {
      /* addToast("Rule Type is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.type = "Rule Type is Required";
      isSubmit = false;
    }
    if (
      ValidatorNameRef.current.value.length == 0 &&
      ValidatorNameRef.current.value == ""
    ) {
      /* addToast("Stored Procedure Name is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.sPName = "Stored Procedure Name is Required";
      isSubmit = false;
    }
    if (
      ErrorMessageRef.current.value.length == 0 &&
      ErrorMessageRef.current.value == ""
    ) {
      /* addToast("Error Message is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.message = "Error Message is Required";
      isSubmit = false;
    }
    if (
      RelevantApplicationPageRef.current.value.length == 0 ||
      RelevantApplicationPageRef.current.value == ""
    ) {
      /* addToast("Relevant Application Page is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.page = "Relevant Application Page is Required";
      isSubmit = false;
    }
    setformError({ ...err });
    if (isSubmit) {
      let loginUrl;
      let body;
      /* if (buttonText == "Save")
    { */
      loginUrl = getAPIURL(REACT_APP_ADDVALIDATIONRULE);
      body = {
        //StatusName: StatusNameRef.current.value,
        RuleID: 0,
        RuleName: RuleNameRef.current.value,
        RuleType: RuleTypeRef.current.value,
        ValidatorName: ValidatorNameRef.current.value,
        UserMessage: ErrorMessageRef.current.value,
        RelevantApplicationPage: RelevantApplicationPageRef.current.value,
        IsApplicable: isApplicableRef.current.checked,
        UpdatedBy: sessionStorage.getItem("PersonID")
      };

      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            setopenPopUp(false);
            addToast("Validation Rule saved successfully", {
              appearance: "success",
              autoDismiss: true
            });
            /*  resetForm(); */
            GetValidationRule();
            /*  setbuttonText("Save"); */
          } else if (result.status === 409) {
            // addToast("Record already exists", {
            //   appearance: "error",
            //   autoDismiss: true
            // });
            err = {};
            err.ruleName = "Record Already Exists";
            setformError({ ...err });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something Went Wrong", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
        });
    }
  };

  const updateValidationRule = () => {
    setformError("");
    let err = {};
    let isSubmit = true;
    if (
      RuleNameRefpop.current.value.length == 0 &&
      RuleNameRefpop.current.value == ""
    ) {
      /* addToast("Rule Name is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.ruleName = "Rule Name is Required";
      isSubmit = false;
    }

    if (
      RuleTypeRefpop.current.value == "-- Select Type --" ||
      RuleTypeRefpop.current.value == ""
    ) {
      /* addToast("Rule Type is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.type = "Rule Type is Required";
      isSubmit = false;
    }
    if (
      ValidatorNameRefpop.current.value.length == 0 &&
      ValidatorNameRefpop.current.value == ""
    ) {
      /* addToast("Stored Procedure Name is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.sPName = "Stored Procedure Name is Required";
      isSubmit = false;
    }
    if (
      ErrorMessageRefpop.current.value.length == 0 &&
      ErrorMessageRefpop.current.value == ""
    ) {
      /* addToast("Error Message is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.message = "Error Message is Required";
      isSubmit = false;
    }
    if (
      RelevantApplicationPageRefpop.current.value.length == 0 ||
      RelevantApplicationPageRefpop.current.value == ""
    ) {
      /* addToast("Relevant Application Page is Required.", {
        appearance: "error",
        autoDismiss: true,
      }); */
      err.page = "Relevant Application Page is Required";
      isSubmit = false;
    }
    setformError({ ...err });

    if (isSubmit) {
      let loginUrl;
      let body;

      {
        loginUrl = getAPIURL(REACT_APP_UPDATEVALIDATIONRULE);
        body = {
          RuleID: RuleIDRefpop.current,
          RuleName: RuleNameRefpop.current.value,
          RuleType: RuleTypeRefpop.current.value,
          ValidatorName: ValidatorNameRefpop.current.value,
          UserMessage: ErrorMessageRefpop.current.value,
          RelevantApplicationPage: RelevantApplicationPageRefpop.current.value,
          IsApplicable: isApplicableRefpop.current.checked,
          UpdatedBy: sessionStorage.getItem("PersonID")
        };
      }

      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            setopenUpdatePopUp(false);
            addToast("Validation Rule saved successfully", {
              appearance: "success",
              autoDismiss: true
            });
            /*  resetForm(); */
            GetValidationRule();
            /*  setbuttonText("Save"); */
          } else if (result.status === 409) {
            // addToast("Record already exists", {
            //   appearance: "error",
            //   autoDismiss: true
            // });
            err = {};
            err.ruleName = "Record Already Exists";
            setformError({ ...err });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Something Went Wrong", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
        });
    }
  };

  const columns = [
    {
      title: "Rule Name",
      field: "ruleName"
    },
    {
      title: "Rule Type",
      field: "ruleType"
    },
    {
      title: "Validator Name",
      field: "validatorName"
    },

    {
      title: "Error Message",
      field: "userMessage"
    },
    {
      title: "Relevant Application Page",
      field: "relevantApplicationPage"
    },
    {
      title: "Is Applicable",
      field: "isApplicable",
      render: rowData => (
        <>
          <input
            type="checkbox"
            id="chkIsApplicable"
            disabled
            checked={rowData["isApplicable"]}
          />
        </>
      )
    }
  ];

  const editValidationRule = item => {
    setformError("");
    setopenUpdatePopUp(true);
    setitem(item);
  };
  useEffect(() => {
    if (Object.keys(item).length > 0) {
      RuleIDRefpop.current = item.ruleID;
      RuleNameRefpop.current.value = item.ruleName;
      RuleTypeRefpop.current.value = item.ruleType;
      ValidatorNameRefpop.current.value = item.validatorName;
      ErrorMessageRefpop.current.value = item.userMessage;
      RelevantApplicationPageRefpop.current.value =
        item.relevantApplicationPage;
      isApplicableRefpop.current.checked = item.isApplicable;
    }
  }, [openUpdatePopUp]);

  const DeleteValidationRule = row => {
    if (!row.isApplicable) {
      addToast("Validation Rule - " + row.ruleName + " is already deleted.", {
        appearance: "success",
        autoDismiss: true
      });
      return false;
    }

    if (
      window.confirm(
        "Are you sure you want to delete the Validation Rule - " +
          row.ruleName +
          " ?"
      )
    ) {
      let body = {
        RuleID: row.ruleID,
        UpdatedBy: sessionStorage.PersonID
      };
      let loginUrl = getAPIURL(REACT_APP_DELETEVALIDATIONRULE);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            addToast("Validation Rule deleted successfully", {
              appearance: "success",
              autoDismiss: true
            });
            GetValidationRule();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };

  useEffect(() => {
    if (runSaveTImer == true && interval == null) {
      interval = setInterval(() => {
        SaveTimerOnTick();
        //window.scrollTo({top:0,behavior:'smooth'});
        // window.scrollTo(0,0);
        //clearInterval(interval);
      }, 60000);
    }
    return () => {
      //  console.log("ending dashboard 1");
      clearInterval(interval);
    };
  }, [runSaveTImer]);
  const SaveTimerOnTick = () => {
    // console.log("entered in savetimeontick");
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakactivity")
          : sessionStorage.getItem("ActivityId") == null
          ? 0
          : sessionStorage.getItem("ActivityId"), //for home page
      workItemID: 0,
      employeeTimeUtilization:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
        sessionStorage.getItem("empTimeUtilization") == "undefined" ||
        sessionStorage.getItem("empTimeUtilization") == null
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
        sessionStorage.getItem("selectedEntitiesDetail") == "undefined" ||
        sessionStorage.getItem("selectedEntitiesDetail") == null
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime:
        sessionStorage.getItem("breakStartTime") == "" ||
        sessionStorage.getItem("breakStartTime") == "undefined" ||
        sessionStorage.getItem("breakStartTime") == null
          ? ""
          : JSON.parse(sessionStorage.getItem("breakStartTime")),
      txtBreakComments:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakcomment")
          : "",
      timerValue: JSON.parse(
        sessionStorage.getItem("FetchBreakTimerOnLoadData")
      ).timerValue,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 60000
    };

    let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_SAVETIMEONTICK);
    let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result != null) {
          if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            let FetchBreakTimerOnLoadData = JSON.parse(
              sessionStorage.getItem("FetchBreakTimerOnLoadData")
            );
            FetchBreakTimerOnLoadData.timerStart = result.timerStart;
            FetchBreakTimerOnLoadData.timerValue = result.timerValue;
            FetchBreakTimerOnLoadData.tSaveTimerEnabled =
              result.tSaveTimerEnabled;
            FetchBreakTimerOnLoadData.btnStartWorkTimerDisabled =
              result.btnStartWorkTimerDisabled;
            // FetchBreakTimerOnLoadData.txtBreakCommentsEnabled =
            //   result.txtBreakCommentsEnabled;
            // FetchBreakTimerOnLoadData.ddlActivityEnabled =
            //   result.ddlActivityEnabled;
            FetchBreakTimerOnLoadData.btnShowBreakPanelDisabled =
              result.btnShowBreakPanelDisabled;
            // sessionStorage.setItem(
            //   "objdataview",
            //   JSON.stringify(result.data[0].objdataview)
            // );
            sessionStorage.setItem(
              "FetchBreakTimerOnLoadData",
              JSON.stringify(FetchBreakTimerOnLoadData)
            );
            sessionStorage.setItem(
              "empTimeUtilization",
              JSON.stringify(result.employeeTimeUtilization)
            );
          }
        }
        // else if(result.status === 401)
        // {
        //   navigate("/")
        // }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  useEffect(() => {
    GetValidationRule();
    setsearchDivVisible(true);
    setisLoadingFigma(false);
  }, []);

  const GetValidationRule = () => {
    let ValidationRuleURL = getAPIURL(REACT_APP_GETVALIDATIONRULE);
    let req1 = new Request(`${ValidationRuleURL}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      }
      // body: JSON.stringify(body),
    });
    fetch(req1)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setSelectedColumns(columns);
          setisLoadingFigma(false);
          updateColumns(Object.keys(result.data[0]));
          setsearchWorkitemData(result.data);
          setDatacount(result.data.length);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const NavElements = useMemo(() => {
    return (
      <>
        <Header pageName="Validation Rules" />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
  return (
    <>
      {NavElements}
      <GlobalStyles
        styles={{
          ".MuiTableCell-root": {
            padding: "5px !important",
            paddingbottom: "50px !important",
            "min-width": "10px !Important"
          },
          ".MuiTableFooter-root": {
            position: "absolute !Important"
          },
          ".MuiTypography-root": {
            "font-size": "1.0rem !important"
          },
          ".MuiCheckbox-root": { padding: "5px !important" },
          ".MuiSvgIcon-root": {
            width: "0.7em !important",
            height: "0.7em !important",

            "font-size": "1.5rem !important"
          },
          ".MuiIconButton-root": {
            "font-size": "1rem !important"
          },
          ".MuiTablePagination-toolbar": {
            width: "0px !important"
            // "max-width": "1000px !important",
          },
          ".MuiToolbar-regular": {
            "min-height": "0px !important"
          },
          ".MuiTableFooter-root": {
            position: "sticky!Important",
            "background-color": "#fff6f0 !Important",
            padding: "0px !important"
          }
        }}
      />

      <div className="col-12">
        {searchDivVisible ? (
          <div className="dashBoxs">
            <div className="dashBoxs-header pb-3 pt-2">
              <div class="row  align-items-center">
                <div class="col">
                  <h6 /* className="pageName" */> MANAGE VALIDATION RULES</h6>
                </div>
                <div class="col"></div>
                <div class="col text-end">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary ms-3"
                    //style = "float: right;"
                    //color = "#f194ff"
                    onClick={() => {
                      popup();
                    }}
                  >
                    + Add New Validation Rule
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="dashBoxs-header">
              <div className="row align-items-center">
                <div className="col-12">
                  <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                    <div className="d-inline">
                      <div className="input-group input-group-sm"></div>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-right mt-2"></div>
              </div>
            </div> */}
            {isLoadingFigma ? (
              <LoadingSpinnerFigma />
            ) : (
              <div className="tableWrap">
                <span
                  style={{
                    fontSize: "13px",
                    color: "blue",
                    marginLeft: "1rem"
                  }}
                >
                  {Datacount} out of {Datacount} records displayed
                </span>
                <div className="tableWrap-body">
                  <table className="table table-borderless MatTable">
                    {/* <ThemeProvider theme={theme}> */}
                    <MaterialTable
                      title="List of Validation Rules"
                      localization={{
                        pagination: {
                          //labelDisplayedRows: '{from}-{to} of {count}'
                        },
                        toolbar: {
                          // nRowsSelected: '{0} row(s) selected'
                          exportCSVName: "Export as Excel"
                        },

                        header: {
                          actions: ""
                        }
                      }}
                      actions={[
                        {
                          icon: () => <EditIcon />,
                          tooltip: "Update",
                          onClick: (event, rowData) =>
                            /* Update(rowData) */ editValidationRule(rowData),
                          position: "row"
                        },
                        {
                          icon: () => <DeleteIcon />,
                          tooltip: "Delete",
                          onClick: (event, rowData) =>
                            DeleteValidationRule(rowData),
                          position: "row"
                        }
                      ]}
                      title="MANAGE VALIDATION RULES"
                      options={{
                        headerStyle: {
                          position: "sticky",
                          top: "-5px"
                        },
                        maxBodyHeight: "420px",
                        //pagination:true,
                        //paginationType:"normal",
                        //paginationPosition: "bottom",
                        // pageSize: 20,
                        // pageSizeOptions: [20, 50, 100, 200],
                        paging: false,
                        exportButton: { csv: true },
                        sorting: true,
                        filtering: false,
                        search: false,
                        selection: false,
                        tableLayout: "auto",
                        overflowY: false,
                        showTitle: false,
                        toolbarButtonAlignment: "right",
                        headerStyle: {
                          height: 30
                        },
                        rowStyle: {
                          fontFamily: '"Yantramanav", sans-serif',
                          fontSize: "13px"
                          // whiteSpace: "nowrap",
                        },
                        tableLayout: "auto",
                        headerStyle: {
                          fontFamily: '"Yantramanav", sans-serif',
                          fontWeight: "inherit",
                          fontSize: "14px",
                          textAlign: "left !Important"
                        }
                      }}
                      columns={columns}
                      icons={tableIcons}
                      data={searchWorkitemData}
                      components={{
                        Container: props => <Paper {...props} elevation={0} />,
                        Toolbar: props => (
                          <div
                            style={{
                              backgroundColor: "rgb(206 206 206)",
                              fontSize: "0.75em"
                            }}
                          >
                            <MTableToolbar
                              {...props}
                              style={{
                                fontSize: "5px"
                              }}
                            />
                          </div>
                        )
                      }}
                    />
                    {/* </ThemeProvider> */}
                  </table>
                </div>
              </div>
            )}
          </div>
        ) : null}

        {/*  </div> */}
      </div>

      <Footer />
      {/* Add Modal */}

      <Modal
        show={openPopUp}
        //onBackdropClick={false}
        //onHide={!openPopUp}
        dialogClassName="modal-100w"
        onHide={popupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Validation Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pageBody pd-top-0">
            <div className="dashBoxs mb-3">
              <div className="formWrap">
                <div className="fieldSet">
                  {/* <h4 className="mb-4">Add/Edit Validation Rule</h4> */}

                  <div className="row align-items-end">
                    <div className="col-3">
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {formError.ruleName}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Rule Name
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <input
                          className="form-control form-control-sm"
                          type="text"
                          name="RuleName"
                          title={"Rule name should not exceed 150 characters"}
                          maxLength={150}
                          //onChange={onChangeHandler}
                          //value={formData.RuleName}
                          //required={true}

                          ref={RuleNameRef}
                        />
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {formError.type}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Type
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <select
                          className="form-control form-control-sm"
                          name="Type"
                          // onChange={onChangeHandler()}
                          ref={RuleTypeRef}
                          title={"Select Type."}
                        >
                          <option>-- Select Type --</option>
                          {Options.map(option => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-3" /* style={{ "height": "81px" }} */>
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {formError.sPName}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Stored Procedure Name
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <input
                          className="form-control form-control-sm"
                          name="StoredProcedureName"
                          type="text"
                          /* onChange={onChangeHandler()} */ ref={
                            ValidatorNameRef
                          }
                          title={
                            "Validator Name should not exceed 100 characters"
                          }
                          maxLength={100}
                        ></input>
                      </div>
                    </div>

                    <div className="col-3" /* style={{ "height": "81px" }} */>
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {formError.message}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Error Message
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <textarea
                          name="Text1"
                          cols="40"
                          rows="2"
                          maxLength="4000"
                          title={
                            "Message Name should not exceed 4000 characters"
                          }
                          /* value={remarks} onChange={onChangeremarks} */ ref={
                            ErrorMessageRef
                          }
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-4">
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {formError.page}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Relevant Application Page
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <select
                          className="form-control form-control-sm"
                          name="RelevantApplicationPage"
                          // onChange={onChangeHandler()}
                          ref={RelevantApplicationPageRef}
                          title={"Select Relevent Application Page."}
                        >
                          <option></option>

                          {Page.map(option => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-1" />

                    <div className="col-3" /* style={{ "height": "81px" }} */>
                      <div className="mb-3">
                        <label className="form-label">Is Applicable</label>
                        <input
                          type="checkbox"
                          id="chkIsApplicableForm"
                          ref={isApplicableRef}
                          title={"check this for the Relevant Application page"}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <hr className="mb-4" /> */}

                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2"></div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetForm}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          title={
                            "Click here to save validation rules and return to validation rules list page"
                          }
                          onClick={() => {
                            //onSubmitHandler();
                            //validateform();
                            AddValidationRuleSave();
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          title={
                            "Click here to save more than one validation rules"
                          }
                          onClick={() => {
                            //onSubmitHandler();
                            //validateform();
                            AddValidationRule();
                          }}
                        >
                          Save And Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {searchDivVisible ? (
              <div className="dashBoxs">
                {isLoadingFigma ? (
                  <LoadingSpinnerFigma />
                ) : (
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table className="table table-borderless MatTable">
                        {/* <ThemeProvider theme={theme}> */}
                        <MaterialTable
                          localization={{
                            pagination: {
                              //labelDisplayedRows: '{from}-{to} of {count}'
                            },

                            header: {
                              actions: ""
                            }
                          }}
                          actions={[]}
                          title="Existing Entries"
                          options={{
                            headerStyle: {
                              position: "sticky",
                              top: "-5px"
                            },
                            maxBodyHeight: "420px",
                            //pagination:true,
                            //paginationType:"normal",
                            //paginationPosition: "bottom",
                            // pageSize: 20,
                            // pageSizeOptions: [20, 50, 100, 200],
                            paging: false,
                            exportButton: false,
                            sorting: true,
                            filtering: false,
                            search: false,
                            selection: false,
                            tableLayout: "auto",
                            overflowY: false,
                            showTitle: true,
                            toolbarButtonAlignment: "right",
                            headerStyle: {
                              height: 30
                            },
                            rowStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontSize: "13px"
                              // whiteSpace: "nowrap",
                            },
                            tableLayout: "auto",
                            headerStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              fontSize: "14px",
                              textAlign: "left !Important"
                            }
                          }}
                          columns={columns}
                          icons={tableIcons}
                          data={searchWorkitemData}
                          components={{
                            Container: props => (
                              <Paper {...props} elevation={0} />
                            ),
                            Toolbar: props => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em"
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{
                                    fontSize: "5px"
                                  }}
                                />
                              </div>
                            )
                          }}
                        />
                        {/* </ThemeProvider> */}
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>

      {/* Add Modal End */}

      {/* Edit Modal */}

      <Modal
        show={openUpdatePopUp}
        //onBackdropClick={false}
        //onHide={!openPopUp}
        dialogClassName="modal-100w"
        onHide={UpdatepopupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Eidt Validation Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pageBody pd-top-0">
            <div className="dashBoxs mb-3">
              <div className="formWrap">
                <div className="fieldSet">
                  {/* <h4 className="mb-4">Add/Edit Validation Rule</h4> */}

                  <div className="row align-items-end">
                    <div className="col-3">
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {formError.ruleName}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Rule Name
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <input
                          className="form-control form-control-sm"
                          type="text"
                          name="RuleName"
                          /*  onChange={onChangeruleName}
                         value={ruleName} */
                          //required={true}

                          ref={RuleNameRefpop}
                          title={"Rule name should not exceed 150 characters"}
                          maxLength={150}
                        />
                      </div>
                    </div>

                    <div className="col-2">
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {formError.type}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Type
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <select
                          className="form-control form-control-sm"
                          name="Type"
                          /* onChange={onChangetype}
                          value={type} */
                          ref={RuleTypeRefpop}
                          title={"Select Type."}
                        >
                          <option>-- Select Type --</option>
                          {Options.map(option => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-3" /* style={{ "height": "81px" }} */>
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {formError.sPName}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Stored Procedure Name
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <input
                          className="form-control form-control-sm"
                          name="StoredProcedureName"
                          type={"text"}
                          /* onChange={onChangesPName} value={sPName} */ ref={
                            ValidatorNameRefpop
                          }
                          title={
                            "Validator Name should not exceed 100 characters"
                          }
                          maxLength={100}
                        ></input>
                      </div>
                    </div>

                    <div className="col-3" /* style={{ "height": "81px" }} */>
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {formError.message}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Error Message
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <textarea
                          name="Text1"
                          cols="40"
                          rows="2"
                          maxLength="4000"
                          title={
                            "Message Name should not exceed 4000 characters"
                          }
                          /* value={remarks} onChange={onChangeremarks} */ ref={
                            ErrorMessageRefpop
                          }
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-4">
                      <div>
                        <span
                          style={{
                            color: "red"
                          }}
                        >
                          {formError.page}
                        </span>
                      </div>
                      <div className="mb-3">
                        <label className="mandatory">
                          Relevant Application Page
                          <span
                            id=""
                            className="mandatory"
                            style={{ color: "#ff0000" }}
                          >
                            *
                          </span>
                        </label>

                        <select
                          className="form-control form-control-sm"
                          name="RelevantApplicationPage"
                          /* onChange={onChangepage}
                         value={page} */
                          ref={RelevantApplicationPageRefpop}
                          title={"Select Relevent Application Page."}
                        >
                          <option></option>

                          {Page.map(option => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-1" />

                    <div className="col-3" /* style={{ "height": "81px" }} */>
                      <div className="mb-3">
                        <label className="form-label">Is Applicable</label>
                        <input
                          type="checkbox"
                          id="chkIsApplicableForm"
                          ref={
                            isApplicableRefpop
                          } /* defaultChecked={isApplicable} onChange={onChangeisApplicable} */
                          title={"check this for the Relevant Application page"}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <hr className="mb-4" /> */}
                  {/* <hr className="mb-4" /> */}
                  {/* <div className="fieldSet"> */}
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2"></div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetUpdateForm}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          title={"Click here to update Validation Rule"}
                          onClick={() => {
                            //onSubmitHandler();
                            //validateform();
                            updateValidationRule();
                          }}
                        >
                          Update
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {searchDivVisible ? (
              <div className="dashBoxs">
                {isLoadingFigma ? (
                  <LoadingSpinnerFigma />
                ) : (
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table className="table table-borderless MatTable">
                        {/* <ThemeProvider theme={theme}> */}
                        <MaterialTable
                          localization={{
                            pagination: {
                              //labelDisplayedRows: '{from}-{to} of {count}'
                            },

                            header: {
                              actions: ""
                            }
                          }}
                          actions={[]}
                          title="Existing Entries"
                          options={{
                            headerStyle: {
                              position: "sticky",
                              top: "-5px"
                            },
                            maxBodyHeight: "420px",
                            //pagination:true,
                            //paginationType:"normal",
                            //paginationPosition: "bottom",
                            // pageSize: 20,
                            // pageSizeOptions: [20, 50, 100, 200],
                            paging: false,
                            exportButton: false,
                            sorting: true,
                            filtering: false,
                            search: false,
                            selection: false,
                            tableLayout: "auto",
                            overflowY: false,
                            showTitle: true,
                            toolbarButtonAlignment: "right",
                            headerStyle: {
                              height: 30
                            },
                            rowStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontSize: "13px"
                              // whiteSpace: "nowrap",
                            },
                            tableLayout: "auto",
                            headerStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              fontSize: "14px",
                              textAlign: "left !Important"
                            }
                          }}
                          columns={columns}
                          icons={tableIcons}
                          data={searchWorkitemData}
                          components={{
                            Container: props => (
                              <Paper {...props} elevation={0} />
                            ),
                            Toolbar: props => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em"
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{
                                    fontSize: "5px"
                                  }}
                                />
                              </div>
                            )
                          }}
                        />
                        {/* </ThemeProvider> */}
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit Modal End */}
    </>
  );
};

export default React.memo(ValidationRule);

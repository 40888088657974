import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
 
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import Modal from "react-bootstrap/Modal";
import GlobalStyles from "@mui/material/GlobalStyles";
 
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import MaterialTable, { MTableToolbar } from "material-table";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import tableIcons from "../../../../MaterialTableIcons";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

 
import {
  MenuItem,
  Paper, Select
} from "@material-ui/core";
import session from "redux-persist/lib/storage/session";
import { CleaningServices } from "@mui/icons-material";




 
function SubProcessUserGroupConfiguration() {
  const { addToast } = useToasts();
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  //const { REACT_APP_UPLOADEXCELCOLUMNS } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GetActivitiesForSubprocessinUserGroup } = process.env;
  const {REACT_APP_DeleteSubProcessUserGroupConfig} = process.env;
  const { REACT_APP_GetUserGroupConfigDetailsByID } = process.env;
  const { REACT_APP_GetSubProcessUserGroupConfigList } = process.env;
  const { REACT_APP_SubprocessActivityGroupUserDetails } = process.env;
  const { REACT_APP_SaveUserGroupConfigDetails } = process.env;
 
  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const [SelectedPrefrencesFieldsToShow, setSelectedPrefrencesFieldsToShow] = useState([]);


 const [ ErrorCompanyDepartment ,setErrorCompanyDepartment] = useState("DepartmentID")
 const [ errorlocationclient ,seterrorlocationclient] =useState("ClientID")

 const [ errorprocesssubprocess, seterrorprocesssubprocess] = useState(sessionStorage.getItem("ProcessId"))
  
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState(sessionStorage.getItem("ProcessId"));
  const [selectedcompanyDepartment, setSelectedcompanyDepartment] = useState(sessionStorage.getItem("selectedDepartment"));

  const [selectedLocationClient, setSelectedLocationClient] = useState(sessionStorage.getItem("selectedLocation"));
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [showBtn, setUserButtonShow] = useState(false);
  const [assignedWorkItemDetails, setAssignedWorkItemDetails] = useState([]);
  const [allowLocation, setAllowLocation] = useState(false);
  const [allowSubprocess, setAllowSubprocess] = useState(false);
  const [showModalButton, setShowModalButton] = useState(false);
  const [ActivitiesForSubprocess, setActivitiesForSubprocess] = useState([]);
  const [PriortyForSubProcessDetails, setPriortyForSubProcessDetails] =
    useState([]);
  const [PriorityJson, setPriorityJson] = useState([]);
  const [Isdepartments, setIsDepartments] = useState([]);
  const [IsclientLocations, setIsClientLocations] = useState([]);
  const [IsprocessSubprocess, setIsprocessSubprocess] = useState([]);
  const [IsActivitiesForSubprocess, setIsActivitiesForSubprocess] = useState([]);
  const [ExcelColumns,setExcelColumns] = useState([]);
  const [tableColumns,setTableColumns] = useState([]);
  const [workitemFieldID, setWorkitemFieldID] = useState([]);
  const [ userGroupData,setUserGroupData] =useState([]);
  const [ userGroupData2,setUserGroupData2] =useState([]);
  const [ selectedUserName, setselectedUserName] = useState([]);
  const [ selectedUserNameUserGroup , setselectedUserNameUserGroup] = useState([]);
  const [ groupNameInput, setgroupNameInput ] = useState("")
  const [ ActivityID, setActivityID ] = useState(null);
  const [ EditableData , setEditableData] = useState([]);
  const initialGroupName = EditableData?.personalDetails && EditableData?.personalDetails.length>0? EditableData.personalDetails[0].groupName: "";
  const [ editGroupName , seteditGroupName] = useState(initialGroupName);
 
  





  const [openPopUp, setopenPopUp] = useState(false);
  const [EditPopUp, setEditPopUp] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const StatusNameRef = useRef("");
  const StatusNameRefpop = useRef("");
  const WorkItemStatusIDRef = useRef(0);
  const WorkItemStatusIDRefpop = useRef(0);
  const displayOrderRef = useRef("");
  const displayOrderRefpop = useRef("");
  const RemarksRef = useRef("");
  const LocationClientRef = useRef("");
  const companyDepartmentRef = useRef("");
  const processSubprocessRef = useRef("");
  const RemarksRefpop = useRef("");
  const isDeletedRef = useRef("");
  const isDeletedRefpop = useRef("");
  const ButtonTextRef = useRef("");
  const [displayOrderArry, setdisplayOrderArry] = useState([]);
  const [searchDivVisible, setsearchDivVisible] = useState(true);
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [numberOfRecord, setnumberOfRecord] = useState(100);
  const [SaveDisabled, setSaveDisabled] = useState(true);
  const [col,setColumns] = useState([]);
  const testRef = useRef(null);
  const [workitemFields, setWorkitemFields] = useState([]);
  const [UserConfigList, setUserConfigList] = useState([]);
  const [filtercriteria, setfiltercriteria] = useState('All');
  const userRef = useRef(null);
  const userGroupRef = useRef(null);
  const [saveuserGroupCheck, setsaveuserGroupCheck] = useState("");
  const [updateuserGroupCheck, setupdateuserGroupCheck] = useState("");
  const [ SubProcessID , setSubProcessID ] = useState("")
  const [ clientID , setclientID ] = useState("")


  const handleselectchange = (event) => {
    const value = event.target.value;
    setTableColumns([]);
    setfiltercriteria(value);
    sessionStorage.setItem('displayCriteria',value);
    GetUserGroupConfigList();
    
  };
 
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });


  const pop = () => {
    
    setopenPopUp(true);
    seterrorMessage("");
    setgroupNameInput("");
    setsaveuserGroupCheck("");
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    FetchDepartmentbyGUID();

  };

  
  const popupClose = () => {
    setopenPopUp(false);
    seterrorMessage("");
    setUserGroupData([]);
    setUserGroupData2([]);
    setActivityID("");
    setclientID("");
    sessionStorage.setItem("selectedDepartment", selectedcompanyDepartment);
    sessionStorage.setItem("selectedLocation", selectedLocationClient);
    sessionStorage.setItem("ProcessId", selectedSubprocess);
    GetUserGroupConfigList();


  };
  const EditpopupClose = () => {
    setEditPopUp(false);
    seterrorMessage("");
    setUserGroupData([]);
    setUserGroupData2([]);
    setupdateuserGroupCheck("");
    
  };
  

  const resetForm = () => {
    StatusNameRef.current.value = "";
    displayOrderRef.current.value = "Select";
    RemarksRef.current.value = "";
    isDeletedRef.current.checked = false;
    seterrorMessage("");
  };



  const downloadExcel = () => {
    const exportType = "xls";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const filteredColumn = tableColumns.map(item => ({
      
        "Client Name": item.clientName,
        "Process Name": item.processName,
        "SubProcess Name": item.subProcessName,
        "Activity": item.activityName,
        "Group Name": item.groupName
    }));
 
      const fileName = "ListofUserGroupConfigDetails";
      const ws = XLSX.utils.json_to_sheet(filteredColumn);
      const wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
  };
 
  const [IsFile, setIsFile] = useState(false);
 
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

   const handleActivityChange = (e) => {

    const ActivityID = e.target.value;
    SubProcessACtivityGroupUserDetails(ActivityID);
   };

  const FetchLocationClientsByGuid = (e) => {
    seterrorlocationclient("Select");
      seterrorprocesssubprocess("Select")
      setClientLocations([]);
    setErrorCompanyDepartment(e.target.value);
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    setActivitiesForSubprocess([]);
    if (e.target.value == "Select") {
      
      setActivityID("")

      setprocessSubprocess([]);
      setShowModalButton(false);
      setClientLocations([]);
      setActivitiesForSubprocess([]);
      setIsDepartments(false);
      setIsClientLocations(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
      setUserGroupData([]);
      setUserGroupData2([]);
    }
    if (e.target.value !== "Select") {
      
      setUserGroupData([]);
      setUserGroupData2([]);
      setIsDepartments(true);
      sessionStorage.setItem(
        "selectedDepartment",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedDepartmentName",
        e.target.value.split("/")[0]
      );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };
 
      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
  };
 
  const FetchProcessSubProcessByGuid = (e) => {
    seterrorlocationclient(e.target.value);
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    setActivitiesForSubprocess([]);
    if (e.target.value == "Select") {
      seterrorprocesssubprocess("");
      setActivityID("")
      setprocessSubprocess([]);
      setActivitiesForSubprocess([]);
      setPriortyForSubProcessDetails([]);
      setPriortyForSubProcessDetails([]);
      setShowModalButton(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
      setIsClientLocations(false);
      setUserGroupData([]);
      setUserGroupData2([]);
    } else {
      if (e.target.value !== "Select") {
        setSelectedLocation(e.target.value);
        setIsClientLocations(true);
        setUserGroupData([]);
        setUserGroupData2([]);
        let result = "";
        let locationArray = e.target.value.split("/");
        for (let i = 0; i < locationArray.length - 1; i++) {
          result = result.concat(locationArray[i]);
          if (i < locationArray.length - 2) {
            result = result.concat("/");
          }
        }
        sessionStorage.setItem(
          "selectedLocation",
          e.target.value.split("/")[e.target.value.split("/").length - 1]
        );
        sessionStorage.setItem("selectedLocationName", result);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: locationArray[locationArray.length - 1],
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
              setprocessSubprocess([]);
            } else {
              setprocessSubprocess([]);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      }
    }
  };

  const userDataXML = (array) => {
    let xmlString = "<EIDs>";
    array.forEach((item) => {
        xmlString += `<EID><ID>${item.employeeID}</ID></EID>`;
    })
    xmlString += "</EIDs>";
    return xmlString;
  }



  const saveUserGroupData = () => {
   
    let error = {};
    let save = true;
    //ErrorCompanyDepartment === undefined || ErrorCompanyDepartment ==="Select" || ErrorCompanyDepartment === null || ErrorCompanyDepartment === ""
    if (companyDepartmentRef.current.value == "Select"){
      error.ErrorCompanyDepartment="Company Department is required."
      save = false;
    }   
    //errorlocationclient === undefined || errorlocationclient ==="Select" || errorlocationclient === null || errorlocationclient === "" || LocationClientRef.current=="Select"
    if (LocationClientRef.current.value == "Select"){
      error.errorlocationclient="Location Client is required."
      save = false;
    }   
     
    //errorprocesssubprocess === undefined || errorprocesssubprocess ==="Select" || errorprocesssubprocess === null || errorprocesssubprocess === ""
    if (processSubprocessRef.current.value=="Select"){
      error.errorprocesssubprocess = "Process Sub-Process is required."
      save = false
    }

    if(ActivityID == null || ActivityID === "Select" || ActivityID == 0 || ActivityID == "")
    {
      error.ActivityID="Activity is required."
      save = false;
    }
    
    if ( userGroupData2 === undefined || userGroupData2 == [] || userGroupData2.length == 0){
      error.userGroupData2="Atleast one user required to user group box."
      save = false;
    }
    if (groupNameInput === ""){
      error.groupNameInput="Group Name is required."
      save = false;
    }
    
    setsaveuserGroupCheck({...error});
    if (save){
    let loginUrl = getAPIURL(REACT_APP_SaveUserGroupConfigDetails);
    let body = {
      SubProcessID: sessionStorage.getItem("ProcessId"),
      activityID: ActivityID,
      configID: 0,
      groupName: groupNameInput,
      xmlEmpIDS: userDataXML(userGroupData2),
      lastUpdatedBy: sessionStorage.getItem("PersonID"),
    };

  
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    }); 
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status === 200) {
                popupClose();
                addToast("Group Data Saved Successfully", {
                  appearance: "success",
                  autoDismiss: true,
                });
                setgroupNameInput("");
                GetUserGroupConfigList();
              } else if (result.status === 401) {
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                navigate("/");
              } else if (result.StatusCode === 500) {
                popupClose();
                addToast("Group Name already exits for selected Sub-Process and Activity", {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            })
            .catch((error) => {
              console.log("ERROR: ", error);
            });
  }};

  


  const updateUserGroupData = () => {
    setupdateuserGroupCheck("");
    let error = {};
    let update = {};
    if ( userGroupData2 === undefined || userGroupData2 == [] || userGroupData2.length == 0){
      error.userGroupData2="Atleast one user required to user group box."
      update = false;
    }
    if (editGroupName === ""){
      error.editGroupName="Group Name is required."
      update = false;
    }
    setupdateuserGroupCheck({...error});
    if (update){

    let loginUrl = getAPIURL(REACT_APP_SaveUserGroupConfigDetails);
    let body = {
      SubProcessID: sessionStorage.getItem("ProcessId"),
      activityID: EditableData?.personalDetails.map((item) => (
        item.activityID
    ))[0],
      configID: EditableData?.personalDetails.map((item) => (
        item.configID
    ))[0],
      groupName: editGroupName,
      xmlEmpIDS: userDataXML(userGroupData2),
      lastUpdatedBy: sessionStorage.getItem("PersonID"),
  } 
  
  let req = new Request(`${loginUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      UserName: sessionStorage.getItem("UserName"),
      Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
    },
    body: JSON.stringify(body),
  });
  fetch(req)
    .then((res) => res.json())
    .then((result) => {
      if (result.status === 200) {
        EditpopupClose();
        addToast("Record Updated Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        setgroupNameInput("");
        GetUserGroupConfigList();
      } else if (result.status === 401) {
        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
          appearance: "error",
          autoDismiss: true,
        });
        sessionStorage.clear();
        sessionStorage.clear();
        navigate("/");
      }
    })
    .catch((error) => {
      console.log("ERROR: ", error);
    });
}};
  

      const EditUserGroupConfig = (row) => {
        setEditPopUp(true);
        let body = {
           configID: row.configID
        };
        let loginUrl = getAPIURL(REACT_APP_GetUserGroupConfigDetailsByID);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            setEditableData(result.data)
            
            
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }

      

    const DeleteUserGroup = (row) => {
        const confirmation = window.confirm("Are you sure you want to delete the User Group Config?")
        if (confirmation === true){
        let body = {
            ConfigID: row.configID
        };
        let loginUrl = getAPIURL(REACT_APP_DeleteSubProcessUserGroupConfig);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
    });
    fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            addToast("User Group Configuration Deleted Successfully.", {
              appearance: "success",
              autoDismiss: true,
            });
            GetUserGroupConfigList();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
        };
        
      const GetUserGroupConfigList = () => {
        if (sessionStorage.getItem("ProcessId") != null && sessionStorage.getItem("displayCriteria") == null) {
            sessionStorage.setItem('displayCriteria',"subprocess")
        }
        let stringFilterKey = sessionStorage.getItem("displayCriteria");
        let longFilterValue = sessionStorage.getItem("ProcessId");
        if (stringFilterKey === "subprocess") {
        }
        else if (stringFilterKey === "process") {
          longFilterValue = longFilterValue.slice(0,-4) + "0000";
        }
        else if (stringFilterKey === "client") {
          longFilterValue = longFilterValue.slice(0,-8) + "00000000";
        }
        let body = {
          UserID: sessionStorage.getItem("GUID"),
          stringFilterKey: stringFilterKey,
          longFilterValue: longFilterValue
        }

        let activityUrl = getAPIURL(REACT_APP_GetSubProcessUserGroupConfigList);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setTableColumns(
                result.data
              );
            }
            else if (result.status === 404) {
              setTableColumns([]);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };

      const GetActivities = () => {
        let body = {
          SubProcessID: errorprocesssubprocess,
        };
        let loginUrl = getAPIURL(REACT_APP_GetActivitiesForSubprocessinUserGroup);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          mode: 'cors',
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            
            if (result.status == 200) {
              setActivitiesForSubprocess(result.data)
            }
            else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              localStorage.clear();
              navigate("/");
            }
            else if (result.status === 404) {
              setActivityID("Select");
              setActivitiesForSubprocess([]);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
 
      useEffect(() => {
        sessionStorage.setItem('displayCriteria',"subprocess")
        FetchDefaultValueForSelectedSubprocess(
          sessionStorage.getItem("selectedDepartment"),
          sessionStorage.getItem("selectedLocation"),
          sessionStorage.getItem("ProcessId")
        );
        FetchDepartmentbyGUID();
        // GetActivities();
        GetUserGroupConfigList();
        
      }, []);


      const SubProcessACtivityGroupUserDetails = (e) =>{
        setActivityID(e.target.value)
        let body ={
            subProcessID: errorprocesssubprocess,

            activityID: e.target.value,
            configID: 0
        }
        let loginUrl = getAPIURL(REACT_APP_SubprocessActivityGroupUserDetails);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setUserGroupData(result.data);
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      
 
      const FetchDepartmentbyGUID = () => {
        let body = {
          memberShipGuid: sessionStorage.getItem("GUID"),
        };
   
        let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setDepartments(result.data);
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      
      const FetchDefaultValueForSelectedSubprocess = (
        companyDeparmentID,
        locationClientID,
        processSubProcessID
      ) => {
        setcompanyDeparmentID(JSON.parse(sessionStorage.selectedEntitiesDetail).departmentID);
        setSelectedDepartment(JSON.parse(sessionStorage.selectedEntitiesDetail).departmentID);
        seterrorlocationclient(JSON.parse(sessionStorage.selectedEntitiesDetail).clientID)
        sessionStorage.setItem("selectedDepartment", companyDeparmentID);
        FetchLocationClientsByGuidOnPageLoad(JSON.parse(sessionStorage.selectedEntitiesDetail).departmentID);
        setlocationClientID(JSON.parse(sessionStorage.selectedEntitiesDetail).clientID);
        setSelectedLocation(JSON.parse(sessionStorage.selectedEntitiesDetail).clientID);
        seterrorprocesssubprocess(JSON.parse(sessionStorage.selectedEntitiesDetail).subProcessID);
        sessionStorage.setItem("selectedLocation", locationClientID);
        FetchProcessSubProcessByGuidOnPageLoad(JSON.parse(sessionStorage.selectedEntitiesDetail).clientID);
        setprocessSubProcessID(JSON.parse(sessionStorage.selectedEntitiesDetail).subProcessID);
        sessionStorage.getItem("ProcessId",processSubProcessID);
        FetchSubprocessName(JSON.parse(sessionStorage.selectedEntitiesDetail).subProcessID
          , JSON.parse(sessionStorage.selectedEntitiesDetail).clientID);
          GetActivities();
      };
 
      const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
        setActivitiesForSubprocess([]);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          departmentId: departmentId,
        };
   
        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setClientLocations(result.data);
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: clientId,
        };
   
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
              // GetActivities();
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
 
      const FetchSubprocessName = (subprocessId, clientId) => {
        setActivitiesForSubprocess([]);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: subprocessId,
          clientId: clientId,
        };
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              sessionStorage.setItem(
                "SelctedSubprocessName",
                result.data[0].processSubProcess
              );
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
 

var rows = [], i = 1, len = 200;
while (i <= len)
{
  rows.push(i);
  i++;
}
 
      const columns = [
       
        {
          title: "Client Name",
          field: "clientName",
        },
        {
          title: "Process Name",
          field: "processName",
         

       
        },
        {
          title: "SubProcess Name",
          field: "subProcessName",
         
        },
        {
          title: "Activity",
          field: "activityName",
         
        },
        {
          title: "Group Name",
          field: "groupName",
         
        },
       
      ];

      useEffect(() => {
        GetActivities();
      },[errorprocesssubprocess]);

      const subprocesOnchange = (SubProcessID) => {
        seterrorprocesssubprocess(SubProcessID.target.value)
        setActivitiesForSubprocess([]);
        if(SubProcessID.target.value !== "Select"){
        setWorkitemFieldID([]);
        setTableColumns([]);
        setSaveDisabled(true);
        setColumns([]);
        setUserGroupData([]);
        setUserGroupData2([]);
        }
        if (SubProcessID.target.value === "Select"){
          setActivityID("Select");
          setActivitiesForSubprocess([]);
          setUserGroupData([]);
          setUserGroupData2([]);
        }   
      };

        const addUserToUserGroup = () => {
            for(let index = 0; index < userRef.current.options.length; index++)
            {
            userRef.current.options[index].selected = false;
            }
            let selectedEmpName = [];
            let UserGroupArray = [];
            selectedUserName.map((e) => {
                const selectedEmp = e;
                selectedEmpName= [...selectedEmpName, selectedEmp];
            });
            const filteredField =userGroupData && userGroupData.filter(
                (item) => !selectedEmpName.includes(item.employeeID.toString())
              );
              const selectedField = userGroupData && userGroupData.filter((item) =>
              selectedEmpName.includes(item.employeeID.toString())
              );
              setUserGroupData(filteredField);
              if(userGroupData2 != undefined){
              UserGroupArray = userGroupData2;
              }
              selectedField.map((e) => {
                if(UserGroupArray){
                    UserGroupArray = [...UserGroupArray, e];
                }
               
              });
              setUserGroupData2(UserGroupArray);
            setselectedUserName([]);
        };

        

        const getCurrentEmp = (e) => {
            const selectedEmp = Array.from(
                e.target.selectedOptions,
                (option) => option.value
            );
            setselectedUserName(selectedEmp);
        } 
        
        const removeUserFromUserGroup = () => {
            // After moving item to another dropdown, next available items get selected automatically. This code will deselect those items
            for(let index = 0; index < userGroupRef.current.options.length; index++)
            {
              userGroupRef.current.options[index].selected = false;
            }
            let finalSelectedArray = [];
            let selectedEmpName = [];
            let UserGroupArray = [];
            selectedUserNameUserGroup.map((e) => {
                const selectedEmp = e;
                selectedEmpName= [...selectedEmpName, selectedEmp];
            });
            const filteredField = userGroupData2.filter(
              (item) => !selectedEmpName.includes(item.employeeID.toString())
            );
            const selectedField = userGroupData2.filter((item) =>
            selectedEmpName.includes(item.employeeID.toString())
            );
            setUserGroupData2(filteredField);
            UserGroupArray = userGroupData;
            selectedField.map((e) => {
              UserGroupArray = [...UserGroupArray, e];
            });
            setUserGroupData(UserGroupArray);
            setselectedUserNameUserGroup([]);
          };

          const getCurrentFieldUserGroup = (e) => {
            const selectedOptions = Array.from(
                e.target.selectedOptions,
                (option) => option.value
              );
              setselectedUserNameUserGroup(selectedOptions);
          };

          const addAllUserstoUserGroup = () => {
            let finalSelectedArray = userGroupData2;
            userGroupData.map((e) => {
                if(finalSelectedArray){
                    finalSelectedArray = [...finalSelectedArray, e];
                }    
            });
            setUserGroupData2(finalSelectedArray);
            setUserGroupData([]);
          };

          const removeAllUsersfromUserGroup = () => {
            let finalSelectedArray = userGroupData;
            userGroupData2.map((e) => {
              finalSelectedArray = [...finalSelectedArray, e];
            });
            setUserGroupData(finalSelectedArray);
            setUserGroupData2([]);
          };
 
      /*const GetAddEditExcelColumns = () => {
        let body = {
          subprocessId: sessionStorage.getItem("ProcessId"),
        };
        let activityUrl = getAPIURL(REACT_APP_UPLOADEXCELCOLUMNS);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setExcelColumns(
                result.data
              );
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      useEffect(() => {
        GetAddEditExcelColumns();
      }, []);*/
 
      const NavElements = useMemo(() => {
        return (
          <>
            <Header onpreferenceShow={null} pageName="Sub-Process User Group Configuration List" />
            <ApplicationUserContext.Provider
              value={processingDashboardParam.userRoles}
            >
              <DashboardContext.Provider value={processingDashboardParam.nav}>
                <NavDataContext.Provider value={navData}>
                  <Nav selectedMenu="Configuration" />
                </NavDataContext.Provider>
              </DashboardContext.Provider>
            </ApplicationUserContext.Provider>
          </>
        );
      }, [navData]);
 
      useEffect(() => {
        setUserGroupData(EditableData.employeeDetails)
        setUserGroupData2(EditableData.empDetails)
        if (EditableData && EditableData.personalDetails && EditableData.personalDetails.length>0 ) {
        seteditGroupName(EditableData.personalDetails[0].groupName)}
      }, [EditableData]) ;


    return (
      <>
      {NavElements}
      <GlobalStyles
        styles={{
          ".MuiTableCell-root": {
            padding: "5px !important",
            paddingbottom: "50px !important",
            "min-width": "10px !Important",
          },
          ".MuiTableFooter-root": {
            position: "fixed !Important",
          },
          ".MuiTypography-root": {
            "font-size": "1.0rem !important",
          },
          ".MuiCheckbox-root": { padding: "5px !important" },
          ".MuiSvgIcon-root": {
            width: "0.7em !important",
            height: "0.7em !important",
 
            "font-size": "1.5rem !important",
          },
          ".MuiIconButton-root": {
            "font-size": "1rem !important",
          },
          ".MuiTablePagination-toolbar": {
            width: "0px !important",
            // "max-width": "1000px !important",
          },
          ".MuiToolbar-regular": {
            "min-height": "0px !important",
          },
          ".MuiTableFooter-root": {
            position: "sticky!Important",
            "background-color": "#fff6f0 !Important",
            padding: "0px !important",
          },
        }}
      />
      
      {/* <div className="centerSection"> */}
        <div className="pageBody">
          <div className="dashBoxs mb-3">
          {/* <h3> Sub-Process User Group Configuration List </h3> */}
          
            <div className="formWrap">
              
              
            <div className="mb-3">
            <label htmlFor="mb-3"><b>Display Criterion</b></label><br></br>
            <select id="mb-3" defaultValue={filtercriteria} onChange={handleselectchange}>
              <option value="subprocess">Sub-Process</option>
              <option value="process">Process</option>
              <option value="client">Client</option>
              <option value="all">All</option>
            </select>
          </div>
          
              <div className="row align-items-end">
              
              </div>
            </div>
          </div>
          <table
              className="table table-borderless MatTable"
              style={{ zIndex: "1", position: "relative" }}
          >
            <ThemeProvider theme={theme}>
                <MaterialTable
                  localization={{
                    pagination: {
                      labelRowsPerPage: "{50, 100, 250,500}",
                    },
                    toolbar: {
                      exportCSVName: "Export as Excel",
                      exportPDFName: "Export as pdf",
                    },
                  }}
                  actions={[
                    {
                      icon: () => <EditIcon />,
                      tooltip: "Click here to Edit Employee Group Configuration.",
                      onClick: (event, rowData) => 
                      EditUserGroupConfig(rowData),
                      position: "row"
                      
                    },
                    {
                      icon: () => <DeleteIcon />,
                      tooltip: "Click here to Delete Employee Group Configuration.",
                      onClick: (event, rowData) =>
                            DeleteUserGroup(rowData),
                      position: "row",
                    },
                  ]}
                  options={{
                    search: false,
                    overflowY: false,
                    showTitle: false,
                    filtering: true,
                    cellStyle: {
                    },
                    headerStyle: {
                      position: "sticky",
                    },
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                    },
                    selection:false,
                  }}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                      </div>
                    ),
                  }}
                  columns={columns}
                  icons={tableIcons}
                  data={tableColumns}
                />
              </ThemeProvider>
            </table>
            <div className="col-3">
                <div className="mb-3">
             
                  
                </div>
              </div>
              <div className="col-3">
                <div class="buttons">
                  {/* <div
                    style={{ marginTop: "5px",marginLeft: "10px" }}
                  > */}
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-1"
                      onClick={() => {
                        pop();
                      }}
                      
                    >
                     Add New Employee Group
                   
                    </button>
                  {/* </div> */}

                  {/* <div
                    style={{ marginTop: "5px",marginLeft: "10px" }}
                  > */}
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-1"
                      onClick={() => {
                        downloadExcel();
                      }}
                    >
                     Export to Excel
                   
                    </button>
                    
                  {/* </div> */}
                </div>
             
              </div>
        </div>
      {/* </div> */}
      

    <Modal
        show={openPopUp}
        dialogClassName="modal-100w"
        onHide={popupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Sub-Process User Group Configuration</Modal.Title>
          
        </Modal.Header>

        <Modal.Body>
        
        <div className="pageBody">
        
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Company-Department</b><span style={{color:"red"}}> *</span></label>
                    <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.ErrorCompanyDepartment}</span>
                    </div>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchLocationClientsByGuid}
                      ref = {companyDepartmentRef}
                    >
                      <option>Select</option>
                      {departments.map((department) => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.departmentIDString == companyDeparmentID
                          }
                        >
                          {department.companyDepartment}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Location-Client</b><span style={{color:"red"}}> *</span></label>
                    <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.errorlocationclient}</span>
                    </div>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchProcessSubProcessByGuid}
                      ref={LocationClientRef}
                    >
                      <option value="Select">Select</option>
                      {clientLocations.map((location) => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.clientIDString == locationClientID}
                        >
                          {location.locationClient}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Process-SubProcess</b><span style={{color:"red"}}> *</span></label>
                    <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.errorprocesssubprocess}</span>
                    </div>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => subprocesOnchange(e)}
                      ref={processSubprocessRef}
                    >
                      <option>Select</option>
                      {processSubprocess.map((subprocess) => (
                        <option
                          value={
                            subprocess.subProcessIDString 
                            // +
                            // "/" +
                            // subprocess.processSubProcess
                          }
                          selected={
                            subprocess.subProcessIDString == processSubProcessID
                          }
                        >
                          {subprocess.processSubProcess}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              
                <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Activity</b><span style={{color:"red"}}> *</span></label>
                    <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.ActivityID}</span>
                    </div>
                    <select
                      className="form-control form-control-sm"
                      onChange={SubProcessACtivityGroupUserDetails}
                    >
                      <option value={0}>Select</option>
                      {ActivitiesForSubprocess.map((activity) => (
                        <option
                        value={activity.activityID}
                        selected={false}
                        defaultValue={0}
                        >
                        {activity.activityName}
                        </option>
                      ))}


                    </select>
                  </div>
                </div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>Group Name</b><span
                         style={{color:"red"}}> *</span></label>
                          <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.groupNameInput}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                         maxLength = "100"
                         height={200}
                         value={groupNameInput}
                         onChange={(e)=>setgroupNameInput(e.target.value)}
                        />   
                  </div>
                </div>

                <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="pt-1">
                
                <div className="row " style={{ width: "115%" }}>
                  <div className="col-5">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label mb-3 fs-6 ">
                        Users
                      </label>
                      <select
                        multiple
                        size="10"
                        className="form-select"
                        title="Select a field to apply filter"
                        onChange={getCurrentEmp}
                        ref={userRef}
                      >
                        {
                           userGroupData && userGroupData?.length>0 && userGroupData?.map((user) => (
                            <option value={user.employeeID}>
                                {user.nameEmployeeID}
                            </option>
                           ) )
                        }
                        
                      </select>
                    </div>
                  </div>
                  <div className="col-1 pt-3 ">
                    <div className="mb-3 d-flex flex-column align-items-center flex-column mt-5">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={addUserToUserGroup}
                        
                      >
                        {">"}
                      </button>

                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={removeUserFromUserGroup}
                        
                      >
                        {"<"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={addAllUserstoUserGroup}
                      >
                        {">>"}
                      </button>
                      
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={removeAllUsersfromUserGroup}
                      >
                        {"<<"}
                      </button>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label mb-3 fs-6 ">
                        User Group <span
                         style={{color:"red"}}> *</span>
                      </label>
                      <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.userGroupData2}</span>
                    </div>

                      <select
                        multiple
                        size="10"
                        className="form-select"
                        title="Select a field to apply filter"
                        onChange={getCurrentFieldUserGroup}
                        ref={userGroupRef}
                      >
                        {/* <option>Select</option> */}
                        {
                           userGroupData2 && userGroupData2?.length>0 && userGroupData2?.map((user) => (
                            <option value={user.employeeID}>
                                {user.nameEmployeeID}
                            </option>
                           ) )
                        }
                        
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
       </div>
      </div>

            <div className="fieldSet pt-1">
                <div className="fieldSet pt-1">
                  <button type="button" 
                  class="btn btn-sm btn-primary btn-link" 
                  onClick={saveUserGroupData}
                  ><b>Save</b></button>
                
                  {/* <button type="button" class="btn btn-sm btn-primary btn-link"><b>Back</b></button> */}
                  
                  </div>
                </div>  
            </div>
          </div>
        </div>
      


            
            
          </Modal.Body>
        
        
        
        </Modal>   

        <Modal
        show={EditPopUp}
        dialogClassName="modal-100w"
        onHide={EditpopupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Sub-Process User Group Configuration</Modal.Title>
          
        </Modal.Header>

        <Modal.Body>
        
        <div className="pageBody">
   
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Company-Department</b><span style={{color:"red"}}> *</span></label>
                    <select
                      className="form-control form-control-sm"
                      disabled
                    >
                      <option>Select</option>
                      {EditableData?.personalDetails?.map((department) => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.company
                          }
                        >
                          {department.company +' ' + "~" + ' ' + department.department}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Location-Client</b><span style={{color:"red"}}> *</span></label>
                    <select
                      className="form-control form-control-sm"
                      disabled
                    >
                      <option>Select</option>
                      {EditableData?.personalDetails?.map((location) => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.location}
                        >
                          {location.location +' ' + "~" + ' ' + location.clientName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Process-SubProcess</b><span style={{color:"red"}}> *</span></label>
                    <select
                      className="form-control form-control-sm"
                      disabled
                    >
                      <option>Select</option>
                      {EditableData?.personalDetails?.map((subprocess) => (
                        <option
                          value={
                            subprocess.subProcessIDString +
                            "/" +
                            subprocess.processSubProcess
                          }
                          selected={
                            subprocess.processName
                          }
                        >
                          {subprocess.processName +' ' + "~" + ' ' + subprocess.subProcessName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              
                <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Activity</b><span style={{color:"red"}}> *</span></label>
                    <select
                      className="form-control form-control-sm"
                      disabled
                    >
                      <option>Select</option>
                      {EditableData?.personalDetails?.map((activity) => (
                        <option
                        value={activity.subProcessWorkActivityConfigID}
                        selected={activity.activityName}
                        >
                        {activity.activityName}
                        </option>
                      ))}


                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    
                        <label className="form-label"><b>Group Name</b><span style={{color:"red"}}> *</span></label>
                        <div>
                      <span style = {{color:'red'}}>{updateuserGroupCheck.editGroupName}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                         maxLength = "100"
                         height={200}
                         value={editGroupName}

                         onChange={(e)=>seteditGroupName(e.target.value)}
                        />   
                  </div>
                </div>
                
            <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="pt-1">
                
                <div className="row " style={{ width: "115%" }}>
                  <div className="col-5">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label mb-3 fs-6 ">
                        Users
                      </label>
                      <select
                        multiple
                        size="10"
                        className="form-select"
                        title="Select a field to apply filter"
                        onChange={getCurrentEmp}
                        ref={userRef}
                      >
                        {
                           userGroupData && userGroupData.length > 0 && userGroupData?.map((user) => (
                            <option value={user.employeeID}>
                                {user.nameEmployeeID}
                            </option>
                           ) )
                        }
                        
                        {/* <option>Select</option> */}
                        {/* {CurrentPrefrencesFieldsToShow.map((field) => (
                          
                          <option
                            value={
                              field.labeltext + "/" + field.workitemfieldid
                            }
                          >
                            {field.labeltext}
                          </option>
                        ))} */}
                      </select>
                    </div>
                  </div>
                  <div className="col-1 pt-3 ">
                    <div className="mb-3 d-flex flex-column align-items-center flex-column mt-5">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={addUserToUserGroup}
                        
                      >
                        {">"}
                      </button>

                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={removeUserFromUserGroup}
                        
                      >
                        {"<"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={addAllUserstoUserGroup}
                       
                      >
                        {">>"}
                      </button>
                      
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-primary mb-1 text-uppercase"
                        onClick={removeAllUsersfromUserGroup}
                      >
                        {"<<"}
                      </button>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label mb-3 fs-6 ">
                        User Group <span
                         style={{color:"red"}}> *</span>
                      </label>
                      <div>
                      <span style = {{color:'red'}}>{updateuserGroupCheck.userGroupData2}</span>
                    </div>

                      <select
                        multiple
                        size="10"
                        className="form-select"
                        title="Select a field to apply filter"
                        onChange={getCurrentFieldUserGroup}
                        ref={userGroupRef}
                      >
                        {
                           userGroupData2 && userGroupData2.length > 0 && userGroupData2?.map((user) => (
                            <option value={user.employeeID}>
                                {user.nameEmployeeID}
                            </option>
                           ) )
                        }
                        
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
                </div>
            </div>

            <div className="fieldSet pt-1">
                <div className="fieldSet pt-1">
                  <button type="button" class="btn btn-sm btn-primary btn-link"
                  onClick={updateUserGroupData}
                  ><b>Update</b></button>
                
                  {/* <button type="button" class="btn btn-sm btn-primary btn-link"><b>Back</b></button> */}
                  
                  </div>
                </div>  
            </div>
          </div>
        </div>
      
          </Modal.Body>
        </Modal>   
      </>
    );
    
};
  export default SubProcessUserGroupConfiguration;
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  MenuItem,
  Container,
  ListItem,
  TextField,
  Tooltip
} from "@material-ui/core";
import Select from 'react-select';
import NavDataContext from "../../../../contexts/NavDataContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Toast } from "bootstrap";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AttachmentIcon from "@mui/icons-material/Attachment";
import EditIcon from "@mui/icons-material/Edit";
import exportFromJSON from "export-from-json";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  DownloadExcel,
  ArrayWithoutExtraColumn
} from "../../Comman/ExcelFileDownload";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";


const SubProcessInstructionListConfig = () => {
  let navigate = useNavigate();
  let approveflag;
  const location = useLocation();
  const { addToast } = useToasts();
  const processingDashboardParam = location.state;
  const [isLoading, setisLoading] = useState(false);
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [SubprocessProcess, setSubprocessProcess] = useState("");
  const [ddlvalues, setddlValues] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [SelectedClientLocation, setSelectedClientLocation] = useState("");
  const [isUpload, setisUpload] = useState(false);
  const [uploadedFileMainpage, setuploadedFileMainpage] = useState("");
  const [approverdata, setapproverData] = useState([]);
  // const columns=[];
  const [tableData, settableData] = useState([]);
  const [ActivitiesForSubprocess, setActivitiesForSubprocess] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState("0");
  const [selectedprocessSubprocess, setselectedprocessSubprocess] = useState("");
  const [instructionId, setInstructionId] = useState(0);
  const [instructionchecklistId, setInstructionchecklistId] = useState(0);
  const [displayOrderValue, setDisplayOrderValue] = useState(0);
  const [instructionTitle, setInstructionTitle] = useState("");
  const [instructionType, setInstructionType] = useState("");
  const [displayOrder, setDisplayOrder] = useState("");
  const [isActiveCheck, setIsActiveCheck] = useState(true);
  const [activityType, setActivityType] = useState("");
  const [instructionContent, setInstructionContent] = useState("");
  const [nextReviewDate, setNextReviewDate] = useState(new Date());
  const [TargetFieldColor, setTargetFieldColor] = useState("#FFFFFF");
  const [approvalStatus, setApprovalStatus] = useState("Draft");
  const [checkListDescription, setCheckListDescription] = useState("");
  const [comments, setComments] = useState("");
  const [isMandatoryCheck, setIsMandatoryCheck] = useState(false);
  const [isActiveCheckTable, setIsActiveCheckTable] = useState(true);
  const [dataCheckListDescription, setDataCheckListDescription] = useState([]);
  const [updateDate, setUpdatedDate] = useState(new Date().toISOString());
  const [AttchmentModal, setAttchmentModal] = useState(false);
  const [AttachmentDetails, setAttachmentDetails] = useState([]);
  const [Isresolve, setIsresolve] = useState(true);
  const [selectedFile, setselectedFile] = useState(null);
  const [name, setname] = useState("");
  const [uploadedFile, setuploadedFile] = useState("");
  const [buttonText, setButtonText] = useState("Add");
  const [ActivityId, setActivityId] = useState("0");
  const [isActivitiesForSubprocess, setIsActivitiesForSubprocess] = useState(false);
  const [getUserRoles, setGetUserRoles] = useState([]);
  const [rowId, setRowId] = useState(1);
  const [WorkitemFields, setWorkitemFields] = useState([]);
  const [instructionListConfigId, setInstructionListConfigId] = useState(0);
  const [userRoleName, setUserRoleName] = useState("InstructorApprover");
  const [userApplicationName, setUserAppilcationName] = useState("ProductionDashboard");
  const [auditData, setAuditData] = useState([]);
  const [fieldValues, setFieldValues] = useState([]);
  const [mainModalview, setMainModalview] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [viewRejectModal, setviewRejectModal] = useState(false);
  const [deleteVisibility, setdeleteVisibility] = useState(false);
  const [editVisibility, seteditVisibility] = useState(false);
  const [addNewVisibility, setaddNewVisibility] = useState(false);
  const [approveVisibility, setapproveVisibility] = useState(false);
  const [sendforApprovalVisibility, setsendforApprovalVisibility] = useState(false);
  const [rejectVisibility, setrejectVisibility] = useState(false);
  const [importfromExcelVisibility, setimportfromExcelVisibility] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showSaveAndContinue, setShowSaveAndContinue] = useState(false);
  const [showSaveAndSendforApproval, setShowSaveAndSendforApproval] = useState(false);
  const [showSaveandApprove, setShowSaveandApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [editChecklistData, setEditChecklistData] = useState([]);
  const [viewRejectModalAddEdit, setViewRejectModalAddEdit] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [savebuttonText, setSavebuttonText] = useState("Save");
  const [ExportData, setExportData] = useState([]);

  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { React_App_GetDashboardforInstructionListConfigs } = process.env;
  const { React_App_GetUsersByRole } = process.env;
  const { React_App_DeleteInstructionListConfig } = process.env;
  const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;
  const { React_App_AddEditInstructionListConfig } = process.env;
  const { React_App_DeleteInstructionListFile } = process.env;
  const { React_App_GetInstructionListFiles } = process.env;
  const { React_App_SaveAttachmentForInstruction } = process.env;
  const { React_App_AddEditInstructionChecklistConfig } = process.env;
  const { React_App_GetInstructTracAndChecklistDetailsById } = process.env;
  const { React_App_GetInstructChecklistDetailsById } = process.env;
  const { React_App_AddInstructionListFromExcel } = process.env;
  const { React_App_GetInstructionToExport } = process.env;
  const { React_App_RejectInstructionListConfig } = process.env;
  const { React_App_ApproveInstructionListConfig } = process.env;
  const { React_App_GetWorkItemFieldsForInstructionList } = process.env;
  const { React_App_UploadInstructionListFileSourceAsync } = process.env;
  const rolesList = sessionStorage.getItem("rolesList");
  const parsedrolesList = JSON.parse(rolesList);
  const roles = parsedrolesList.map(role => role.roleName);

  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;
  const selectedWorkItemDetails = processingDashboardParam.selectedWorkItemDetails;
  let currentAcitvity = "";
  if (typeof processingDashboardParam.row.activityName !== "undefined") {
      currentAcitvity = processingDashboardParam.row.activityName;
  } else {
      currentAcitvity = processingDashboardParam.currentAcitvity;
  }
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
      navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
      navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  function convertToGMTISOString(dateString) {
    const dateObject = new Date(dateString);
    dateObject.setMinutes(dateObject.getMinutes() - dateObject.getTimezoneOffset());
    return dateObject.toISOString();
  }

  const RoleBasedAuthorizationforMainPage = () => {
    if (roles.includes("InstructorAuthor") || roles.includes("Super Admin")) {
      setaddNewVisibility(true);
    }
    if (roles.includes("InstructorApprover") || roles.includes("Super Admin")) {
      setapproveVisibility(true);
    }
    if (roles.includes("Super Admin" || roles.includes("InstructorAuthor"))) {
      setsendforApprovalVisibility(true);
    }
    if (roles.includes("InstructorApprover") || roles.includes("Super Admin")) {
      setrejectVisibility(true);
    }
  }

  const rolebasedauthorization = () => {
    if (roles.includes("InstructorAuthor")) {
      if (approvalStatus !== null) {
        if (approvalStatus.toLowerCase().includes("draft")) {
          setShowSave(true);
          setShowSaveAndContinue(true);
          setShowSaveAndSendforApproval(true);
        }
        if (approvalStatus.toLowerCase().includes("awaiting")) {
          setShowSave(true);
        }
        if (approvalStatus.toLowerCase().includes("approve")) {
          setShowSaveAndSendforApproval(true);
        }
      }
      else {
        if (instructionId == null)   //check
        {
          setShowSave(true);
          setShowSaveAndContinue(true);
          setShowSaveAndSendforApproval(true);
        }
      }
      setShowAuditTrail(false);
    }
    if (roles.includes("InstructorApprover")) {
      if (approvalStatus.toLowerCase().includes("awaiting")) {
        setShowSave(true);
        setShowSaveandApprove(true);
        setShowReject(true);
      }
      if (approvalStatus.toLowerCase().includes("approve")) {
        setShowSaveAndSendforApproval(true);
      }
      setShowAuditTrail(true);

    }
    if (roles.includes("Super Admin")) {
      if (approvalStatus !== null) {
        if (instructionId == 0) {
          setShowSaveAndContinue(true);
        }
        if (approvalStatus.toLowerCase().includes("draft")) {
          setShowSave(true);
          setShowSaveAndContinue(true);
          setShowSaveAndSendforApproval(true);
        }
        if (approvalStatus.toLowerCase().includes("awaiting")) {
          setShowSave(true);
          setShowSaveandApprove(true);
          setShowReject(true);
        }
        if (approvalStatus.toLowerCase().includes("approve")) {
          setShowSaveAndSendforApproval(true);
        }
      }
      else {
        if (instructionId == null)   //check
        {
          setShowSave(true);
          setShowSaveAndContinue(true);
          setShowSaveAndSendforApproval(true);
        }
      }
      setShowAuditTrail(true);
    }
  }
  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  useEffect(() => {
    
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId"),
    );
    FetchDepartmentbyGUID();
    FetchInstructionListDataonPageLoad();
    getDataForExport();
    FetchApproversList();
    RoleBasedAuthorizationforMainPage();
  }, []);
  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchActivitiesBySubProcessID(processSubProcessID);
    setselectedprocessSubprocess(processSubProcessID);
  };

  let checklistTex = "";
  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchLocationClientsByGuid = (e) => {
    settableData([]);
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setClientLocations([]);
      setActivitiesForSubprocess([]);
    }
    if (e.target.value !== "Select") {
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };
      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
  };

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchProcessSubProcessByGuid = (e) => {
    settableData([]);
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setActivitiesForSubprocess([]);
    }
    setSelectedLocation(e.target.value);
    setSelectedClientLocation(e.target.value.split("/")[1]);
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value.split("/")[1],
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          setprocessSubprocess([]);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchActivitiesBySubProcessID = (e) => {
    let body = {
      subProcessID: e,
    };
    let activityUrl = getAPIURL(REACT_APP_GETACTIVITIESFORSUBPROCESS);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setActivitiesForSubprocess(result.data._listworkitemgridData);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }

  const FetchInstructionListDataonPageLoad = () => {
    
    let body = {
      subProcessID: sessionStorage.getItem("ProcessId"),
      userID: sessionStorage.getItem("GUID"),
      stringFilterKey: "all",
      longFilterValue: "0",
      activityId: 0
    };
    let loginUrl = getAPIURL(React_App_GetDashboardforInstructionListConfigs);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          settableData(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          settableData([]);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }

  const FetchInstructionListData = (e) => {
    let body = {
      subProcessID: sessionStorage.getItem("ProcessId"),
      userID: sessionStorage.getItem("GUID"),
      stringFilterKey: "all",
      longFilterValue: "0",
      activityId: e
    };
    let loginUrl = getAPIURL(React_App_GetDashboardforInstructionListConfigs);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          settableData(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          settableData([]);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }

  const GetWorkItemFieldsForInstructionList = (row) => {
    let listconfigid = row ? row.instructionListConfigID : 0;
    
    let body = {
      subProcessID: sessionStorage.getItem("ProcessId"),
      instructionListValuesID: listconfigid
    };
    let activityUrl = getAPIURL(React_App_GetWorkItemFieldsForInstructionList);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setWorkitemFields(result.data);
          const initialFieldValues = result.data.map(({ workitemFieldID,labelText,condition, fieldValues }) => ({
            wfid:workitemFieldID,
            label:labelText,
            conditions: condition,
            inputText: fieldValues
          }));
          setFieldValues(initialFieldValues);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const AddEditInstructionChecklistConfig = () => {
    
    let AssignActionCode;
    if (buttonText === "Add") {
      AssignActionCode = 'I'
    }
    else {
      AssignActionCode = 'U'
    }
    let body = {
      ActionCode: AssignActionCode,
      InstChecklistConfigId: instructionchecklistId, //
      SubProcessID: sessionStorage.getItem("ProcessId"),
      InstructionListConfigID: instructionId, //0
      CheckListDesc: checkListDescription,
      IsMandatory: isMandatoryCheck,
      IsActive: isActiveCheckTable.toString(),
      UpdatedBy: sessionStorage.getItem("PersonID"),
      DisplayOrder: displayOrderValue
    };
    let activityUrl = getAPIURL(React_App_AddEditInstructionChecklistConfig);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          addToast(" Checklist Add/Edit Sucessful!", {
            appearance: "success",
            autoDismiss: true,
          });
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };

  const AddEditInstructionListConfig = () => {
    
    // let statuscomment = rowData ? rowData.comments : "";
    
    let currentFlagStatus;
    if (approveflag === 1) {
      currentFlagStatus = "Approved";
    } else if (approveflag === 2) {
      currentFlagStatus = "Awaiting Approval";
    } else {
      currentFlagStatus = approvalStatus;
    }

    let body = {
      SubProcessID: sessionStorage.getItem("ProcessId"),
      InstructionTitle: instructionTitle,
      CurrentStatus: currentFlagStatus,
      NextReviewDate: convertToGMTISOString(nextReviewDate),
      Comments: "",
      Type: instructionType,
      DisplayOrder: displayOrder,
      DisplayColor: TargetFieldColor,
      Rules: JSON.stringify(fieldValues),
      Instructions: instructionContent,
      IsActive: isActiveCheck,
      UpdatedBy: parseInt(sessionStorage.getItem("PersonID"), 10),
      IsSendforApproval: 0,
      InstructionListValuesID: instructionId,
      CheckListData: JSON.stringify(dataCheckListDescription),
      InstructionListFile: "",
      activityId: ActivityId,
      returnResult: '500'
    };
    let activityUrl = getAPIURL(React_App_AddEditInstructionListConfig);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          alert("Add/Edit Sucessful!");
        }
        else {
          alert("Not Able to Add/Edit!");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
    
  };

  const handleUploadPDFfile = () => {
    
    if(uploadedFile===''){
      alert("Please Select a File to Upload");
    }
    else{
    const formData = new FormData();
    formData.append('file', uploadedFile);

    let loginUrl = getAPIURL(React_App_UploadInstructionListFileSourceAsync);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        //  "Content-Type": "multipart/form-data",
        UserName: sessionStorage.getItem("UserName"),
        Authorization:
          "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: formData,
    });
    fetch(req)
      .then((res) => res.json())
      .then((res) => {
        if (res.data !== "") {
          IMPORTFILE(res.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
    }
  }
  


  const IMPORTFILE = (path) => {
    
    // var input = document.querySelector('input[type="file"]');
    var input = uploadedFile;

    let body = {
      InstructionConfigID: instructionId,
      FileName: uploadedFileName,
      FilePath: path

    }
    let activityUrl = getAPIURL(React_App_SaveAttachmentForInstruction);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
      // body: formData
    });
    fetch(req)
      .then(response => {
        if (!response.ok) {
          throw new Error('Upload failed.');
        }
        return response.json();
      })
      .then(data => {
        alert('File uploaded successfully.');
        setUploadedFileName("");
      })
      .catch(error => {
        console.error('Upload error:', error);
        alert('Upload failed. Please try again later.');
      });
  }

  const instructionTitleTextBox = (e) => {
    setInstructionTitle(e.target.value);
  }

  const instructionTypeDropdown = (e) => {
    setInstructionType(e.target.value);
  }

  const displayOrderDropdown = (e) => {
    setDisplayOrder(e.target.value);
  }

  const isActiveCheckbox = (e) => {
    var check = isActiveCheck;
    setIsActiveCheck(!check);
  }


  const multiselectRef = useRef();
  const resetSelectField = () => {
    multiselectRef.current.resetSelectedValues();
    setActivityId("0");
  }; 

 
  const onRemove= (selectedList) => {
    setActivityId("0");
    setActivityType("");
    const strCommaSeperatedIds = selectedList.map(item => item.value).join('|');
    setActivityId(strCommaSeperatedIds);
    const selectedActivityTypes = selectedList.map(option => {
      const subprocess = ActivitiesForSubprocess.find(subprocess => subprocess.ActivityID === option.value);
      return subprocess ? subprocess.ActivityName : '';
    });
  
    const selectedActivityType = selectedActivityTypes.join(', '); // Assuming you want to join multiple activity types with a comma
    setActivityType(selectedActivityType);

  }

  const FetctActivityID = (selectedOptions) => {
    
    const strCommaSeperatedIds = selectedOptions.map(item => item.value).join('|');
    setActivityId(strCommaSeperatedIds);
  
    // const isActivitiesSelected = selectedOptions.length > 0;
    // setIsActivitiesForSubprocess(isActivitiesSelected);
  
    const selectedActivityTypes = selectedOptions.map(option => {
      const subprocess = ActivitiesForSubprocess.find(subprocess => subprocess.ActivityID === option.value);
      return subprocess ? subprocess.ActivityName : '';
    });
  
    const selectedActivityType = selectedActivityTypes.join(', '); // Assuming you want to join multiple activity types with a comma
    setActivityType(selectedActivityType);
  };
  
  
  const handleConditionDropdownChange = (index, event) => {
    const newFieldValues = [...fieldValues];
    newFieldValues[index].conditions = event.target.value;
    setFieldValues(newFieldValues);
  };

  const handleConditionInputChange = (index, event) => {
    const newFieldValues = [...fieldValues];
    newFieldValues[index].inputText = event.target.value;
    setFieldValues(newFieldValues);
  };


  const FetchOnchangeTC = (e) => {
    //debugger
    if (e.target.value === "undefined") {
      setTargetFieldColor("#FFFFFF");
    } else {
      setTargetFieldColor(e.target.value);
    }
  };
  const handleResetColor = () => {
    setTargetFieldColor("#FFFFFF"); 
  };
  const checkListDescriptionTextbox = (e) => {
    setCheckListDescription(e.target.value);
  }

  const isActiveCheckboxTable = (e) => {
    var check = isActiveCheckTable;
    setIsActiveCheckTable(!check);
  }
  const isMandatoryCheckbox = (e) => {
    var check = isMandatoryCheck;
    setIsMandatoryCheck(!check);
  }

  const handleCheckListDetails = () => {
    if (checkListDescription === "") {
      alert("Please add Check list Description");
      
    } else {
      if (buttonText === "Add") {
        const isDisplayOrderUnique = dataCheckListDescription.every(
          item => item.DisplayOrder !== displayOrderValue
        );
        if (!isDisplayOrderUnique) {
          alert("Display Order Should be unique");
        } else {
          // Add new item
          AddEditInstructionChecklistConfig();
          const newParameter = {
            rowNo: rowId,
            CheckListDesc: checkListDescription,
            IsMandatory: isMandatoryCheck,
            IsActive: isActiveCheckTable,
            UpdatedOn: updateDate,
            DisplayOrder: displayOrderValue,
          };
          setDataCheckListDescription(prevListState => [...prevListState, newParameter]);
          setIsActiveCheckTable(true);
          setCheckListDescription("");
          setIsMandatoryCheck(false);
          setDisplayOrderValue(0);
          setUpdatedDate(new Date().toISOString());
          setRowId(prevRowId => prevRowId + 1);
        }
      } else {
        const isDisplayOrderUniqueEdit = dataCheckListDescription.every(
          item => item.DisplayOrder !== displayOrderValue || item.rowNo === rowId
        );
        if (!isDisplayOrderUniqueEdit) {
          alert("Display Order Should be unique");
          
        } else {
          // Edit existing item
          AddEditInstructionChecklistConfig();
          const updatedList = dataCheckListDescription.map(item => {
            if (item.rowNo === rowId) {
              return {
                ...item,
                CheckListDesc: checkListDescription,
                IsMandatory: isMandatoryCheck,
                IsActive: isActiveCheckTable,
                UpdatedOn: updateDate,
                DisplayOrder: displayOrderValue,
              };
            }
            return item;
          });
          setDataCheckListDescription(updatedList);
          setIsActiveCheckTable(true);
          setCheckListDescription("");
          setIsMandatoryCheck(false);
          setDisplayOrderValue(0);
          setUpdatedDate(new Date().toISOString());
          setButtonText("Add");
        }
      }
    }
  };
  


  const Reset = (reset) => {
    if (reset == 0) {
      setCheckListDescription("");
      setIsActiveCheckTable(true);
      setDisplayOrderValue(0);
      setIsMandatoryCheck(false);
    }
  }

  const AttachmentClose = () => {
    setAttchmentModal(false);
  };
  const Mainmodalclose = () => {
    setMainModalview(false);
    FetchInstructionListData(selectedActivity);
    getDataForExport();
  }
  // const AttachmentOpen = () => {
  //     return AttchmentModal;
  // }

  const handleChecklistUpdate = (row) => {
    setRowId(row.rowNo);
    setInstructionchecklistId(row.InstChecklistConfigId);
    setCheckListDescription(row.CheckListDesc);
    setIsActiveCheckTable(row.IsActive);
    setDisplayOrderValue(row.DisplayOrder);
    setIsMandatoryCheck(row.IsMandatory);
  }

  const DefaultStates = () => {
    
    setInstructionId(0);
    setInstructionListConfigId(0);
    setInstructionTitle("");
    setInstructionType("");
    setNextReviewDate(new Date);
    setDisplayOrder("");
    setTargetFieldColor("#FFFFFF");
    setApprovalStatus("Draft");
    setIsActiveCheck(true);
    setActivityType("");
    setActivityId("0");
    setDataCheckListDescription([]);
    Reset(0);
    setInstructionContent("");
    setFieldValues([]);
    setAuditData([]);
    setAttachmentDetails([]);
    setShowAuditTrail(false);
    setUploadedFileName("");
    GetWorkItemFieldsForInstructionList();
    setSavebuttonText("Save");
  }

  const allInputTextEmpty = fieldValues.every(({ inputText }) => inputText.trim() === '');

  const handleSaveAndContinue = () => {
    
    if (instructionTitle=== "" || instructionContent===""){
      alert("Please Fill all the Mandatory Fields");
    }
    else{
      if(allInputTextEmpty){
        alert("Please add Rule Details for atleast one Field");
      }
      else{
    AddEditInstructionListConfig();
    // AddEditInstructionChecklistConfig();
    DefaultStates();
    }
  }
  }

  const handleSave = () => {
    if (instructionTitle=== "" || instructionContent===""){
      alert("Please Fill all the Mandatory Fields");
    }
    else{
      if(allInputTextEmpty){
        alert("Please add Rule Details for atleast one Field");
      }
      else{
    AddEditInstructionListConfig();
    // AddEditInstructionChecklistConfig();
    Mainmodalclose();
    DefaultStates();
    }
  }
  }

  const handleSaveAndSendforApproval = () => {
    if (instructionTitle=== "" || instructionContent===""){
      alert("Please Fill all the Mandatory Fields");
    }
    else{
      if(allInputTextEmpty){
        alert("Please add Rule Details for atleast one Field");
      }
      else{
    approveflag = 2;
    // AddEditInstructionChecklistConfig();
    AddEditInstructionListConfig();
    Mainmodalclose();
    DefaultStates();
    }
  }
  }

  const handleBack = () => {
    Mainmodalclose();
  }

  const handleSaveandApprove = () => {
    if (instructionTitle=== "" || instructionContent===""){
      alert("Please Fill all the Mandatory Fields");
    }
    else{
      if(allInputTextEmpty){
        alert("Please add Rule Details for atleast one Field");
      }
      else{
    approveflag = 1;
    // AddEditInstructionChecklistConfig();
    AddEditInstructionListConfig();
    Mainmodalclose();
    DefaultStates();
    }
  }
  }

  const handleRejectonAddEdit = () => {
    let flag = 1;
    if (approvalStatus == 'Rejected') {
      flag = 0;
    }
    if (approvalStatus == 'Approved') {
      flag = -1;
    }
    if (flag == 1) {
      setViewRejectModalAddEdit(true);
    } else if (flag == 0) {
      alert("Instruction has already been rejected.");
     
    } else {
      alert("Selected instruction is either approved or inactive.");
    }
  }

  const RejectModalcloseAddEdit = () => {
    setViewRejectModalAddEdit(false);
  }


  const handleRejectAddEdit = () => {
    setViewRejectModalAddEdit(false);
    reject();
  }

  const getValueByType = (field) => {
    switch (field.type) {
      case 'checkbox':
        return field.value ? '1' : '0';
      case 'yesno':
        return field.value ? '1' : '0';
      case 'numeric':
        return field.value;
      case 'truefalse':
        return field.value ? '1' : '0';
      default:
        return field.value;
    }
  };

 

  const instructionTypeList = [
    { value: "Override", name: "Override" },
    { value: "Informational", name: "Informational" }

  ]
  const displayOrderList = [
    { value: "High", name: "High" },
    { value: "Med High", name: "Med High" },
    { value: "Normal", name: "Normal" },
    { value: "Med Low", name: "Med Low" },
    { value: "Low", name: "Low" }
  ]


  const ruleDropdownList = [
    { value: "Equals", name: "Equals" },
    { value: "BeginsWith", name: "Begins With" },
    { value: "Contains", name: "Contains" }
  ]

  const AuditColumns = [
    { title: 'Status', field: 'currentStatus' },
    { title: 'Updated On', field: 'updatedOn', type: 'date' },
    { title: 'Updated By', field: 'name' },
    {
      title: 'Comments', field: 'rejectComment',
      // hidden: (rowData) => rowData.CurrentStatus.tolower() != 'rejected'
    }
  ]

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // Ensure the input is a valid integer (optional: handle negative values)
    const intValue = parseInt(inputValue, 10);

    if (!isNaN(intValue)) {
      setDisplayOrderValue(intValue);
    }
  };

  const readUploadFile = (e) => {
    const extension = e.target.files[0].name.split(".").pop();
    if (extension === "pdf") {
      if (e.target.files[0].size <= 10240000) 
      {
        setuploadedFile(e.target.files[0]);
        setUploadedFileName(e.target.value);
      } 
      else {
        alert("The file size exceeded the maximum limit of 10 MB.");
            }}
     else {
      alert("Upload Failed! File Type is not supported! Choose .pdf file!");
    }
  };
  
  const Checklistcolumns = [
    {
      title: 'Active', type: 'checkbox', field: 'IsActive', render: rowData => (
        <input
          type="checkbox"
          disabled
          checked={rowData.IsActive}
        />
      ),
    },
    { title: 'Check List Description', field: 'CheckListDesc', type: 'text' },
    {
      title: 'Mandatory', field: 'IsMandatory', type: 'text', render: rowData => (
        <span>{rowData.IsMandatory ? 'Yes' : 'No'}</span>
      ),
    },
    { title: 'Display Order', field: 'DisplayOrder', type: 'numeric' },
    { title: 'Updated Date', field: 'UpdatedOn', type: 'datetime' },
  ];

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });


  const FetchUploadDetails = () => {
    
    let body = {
      InstructionListConfigID: instructionId
    };
    let loginUrl = getAPIURL(React_App_GetInstructionListFiles);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setAttachmentDetails(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const columnsModal = [
    {
      title: "UploadPath",
      field: "filePath",
      hidden: true,
    },
    {
      title: "PDF List ID",
      field: "pdfListID",
      hidden: true,
    },
    {
      title: "Uploaded FileName", field: "fileName", render: rowData => {
        const filePath = rowData.fileName;
        const fileName = filePath.split('\\').pop();
        return fileName;
      }
    }
  ];

  const FetchApproversList = () => {
    
    let body = {
      roleName: "InstructorApprover",
      applicationName: "ProductionDashboard",
      subProcessID: sessionStorage.getItem("ProcessId")
    };
    let loginUrl = getAPIURL(React_App_GetUsersByRole);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setapproverData(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          setapproverData([]);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }
  let columns = [
    { title: "ID", field: "instructionListConfigID", cellStyle: { width: "5%", } },
    { title: "Instruction Title", field: "instructionTitle" },
    {
      title: "Rule", field: "rules",
      render: (row) => (
        <div dangerouslySetInnerHTML={{ __html: row["rules"] }}></div>
      ), cellStyle: { width: "15%", }
    },
    { title: "Instruction Type", field: "type" },
    { title: "Current Status", field: "currentStatus" },
    { title: "Display Order", field: "displayOrder" },
    {
      title: "Display Colour",
      field: "displayColor",
      render: row => (
        <>
          <span
            style={{ backgroundColor: row.displayColor }}
            class="dot"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasColor"
            aria-controls="offcanvasColor"
          ></span></>
      ), cellStyle: { width: "3%", }
    },
    {
      title: "Next Review Date", field: "nextReviewDate",
      render: rowData => {
        const date = new Date(rowData.nextReviewDate);
        const options = {
          timeZone: 'IST',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
        }
        return date.toLocaleString('en-US', options);
      },
    },
    { title: "Status", field: "status" },
    {
      title: "Created Date", field: "createdOn",
      render: rowData => {
        const date = new Date(rowData.createdOn);
        const options = {
          timeZone: 'IST',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
        }
        return date.toLocaleString('en-US', options);
      },
    },
    { title: "Activity Name", field: "activityName" }
  ];
  const approverColumns = [
    {
      title: "Name", field: "name",
      render: rowData => (
        <Tooltip title={rowData.name} arrow>
          <div className="col-6">{rowData.name}</div>
        </Tooltip>
      )
    }
  ]
  const handleSubprocessChange = (e) => {
    
    sessionStorage.setItem("ProcessId", e.target.value);
    setselectedprocessSubprocess(e.target.value);
    FetchActivitiesBySubProcessID(e.target.value);
    FetchInstructionListData("0");
    getDataForExport();
  }
  const handleActivityChange = (e) => {
    
    setSelectedActivity(e.target.value);
    FetchInstructionListData(e.target.value);
    ActivitiesForSubprocess.map((subprocess) => (
      setActivityType(subprocess.ActivityName)
    ))
  }

  const GetInstructTracAndChecklistDetailsById = (row) => {
    
    let body = {
      instructionListConfigID: row.instructionListConfigID
    };
    let loginUrl = getAPIURL(React_App_GetInstructTracAndChecklistDetailsById);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setAuditData(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          settableData([]);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }
  const GetInstructChecklistDetailsById = (row) => {
    
    let body = {
      instructionListConfigID: row.instructionListConfigID
    };
    let loginUrl = getAPIURL(React_App_GetInstructChecklistDetailsById);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          const EditChecklistData = result.data.map(({ instChecklistConfigId, instructionListConfigID, checkListDesc, isActive, isMandatory, displayOrder, updatedOn }) => ({
            rowNo: displayOrder,
            InstChecklistConfigId: instChecklistConfigId,
            InstructionListConfigID: instructionListConfigID,
            CheckListDesc: checkListDesc,
            IsActive: isActive,
            IsMandatory: isMandatory,
            UpdatedOn: updatedOn,
            DisplayOrder: displayOrder
          }));
          setDataCheckListDescription(EditChecklistData);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          settableData([]);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }

  const EditFieldsPopulate = (row) => {
    
    setSavebuttonText("Update");
    setInstructionId(row.instructionListConfigID);
    setInstructionTitle(row.instructionTitle);
    setInstructionType(row.type);
    
    setNextReviewDate(new Date(row.nextReviewDate));
    // setNextReviewDate(moment(new Date(row.nextReviewDate)).format("MM/dd/yyyy"));
    setDisplayOrder(row.displayOrder);
    setTargetFieldColor(row.displayColor);
    setApprovalStatus(row.currentStatus);
    let bool = row.status === 'Active' ? true : false;
    setIsActiveCheck(bool);
    if (row.activityName == null || row.activityName.includes(',')) {
      setActivityType('');
    }
    else {
      setActivityType(row.activityName);
    }
    setFieldValues([]);
    setInstructionContent(row.instructions);
    GetInstructTracAndChecklistDetailsById(row);
    GetInstructChecklistDetailsById(row);
  }

  const EditInstructions = (row) => {
    
    DefaultStates();
    setShowAuditTrail(true);
    setMainModalview(true);
    EditFieldsPopulate(row);
    GetWorkItemFieldsForInstructionList(row);
    rolebasedauthorization();
  }

  const AddEditInstructions = () => {
    
    DefaultStates();
    setMainModalview(true);
    GetWorkItemFieldsForInstructionList();

    rolebasedauthorization();
    setShowAuditTrail(false);
  }
 

  const handleAttachmentDelete = (row) => {
    
    let flag = AttachmentDetails.length === 1 ? 1 : 0;
    if (window.confirm("Are you sure want to delete this record?")) {
      let body = {
        PdfListID: row.pdfListID
      };
      let attachmentDeleteUrl = getAPIURL(React_App_DeleteInstructionListFile);
      let req = new Request(`${attachmentDeleteUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            alert("Delete Sucessful!");
            setIsresolve(true);
            if (flag === 1) {
              setAttachmentDetails([]);
            }
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
            setIsresolve(true);
          } else {
            addToast("Error occured!", {
              appearance: "error",
              autoDismiss: true,
            });
            setIsresolve(true);
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            let Bodydetails = "";
            for (let i = 0; i < Object.keys(body).length; i++) {
              Bodydetails =
                Bodydetails +
                ", " +
                Object.keys(body)[i] +
                ": " +
                Object.values(body)[i];
            }
          }
          setIsresolve(true);
        })
        .finally(() => {
          FetchUploadDetails();
        });
    }
  };

  const handleRowDelete = (e) => {
    if (e.status == 'InActive') {
      addToast("Instruction List is already InActive.", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      let body = {
        instructionListConfigID: e.instructionListConfigID,
        updatedBy: sessionStorage.getItem("PersonID")
      };
      let loginUrl = getAPIURL(React_App_DeleteInstructionListConfig);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            addToast("Instruction List Deleted Successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            FetchInstructionListData(selectedActivity);
            getDataForExport();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Instruction List Deletion Failed", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
  }

  const getDataForExport = () => {
    
    let body = {
      subProcessID: sessionStorage.getItem("ProcessId")
    };
    let loginUrl = getAPIURL(React_App_GetInstructionToExport);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setExportData(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          setExportData([]);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }

  const handleButtonClick = () => {
    const colToRemove = new Set(["fieldName3","condition3","value3","fieldName4","condition4","value4","fieldName5","condition5","value5","id","instructionListConfigID"]);
    const sheetData = ArrayWithoutExtraColumn(ExportData,colToRemove);
    DownloadExcel(sheetData, "ListOfInstructionsListConfig");
  }

  const ImportFile = () => {
    // console.log("imported");
  }
  const handleApprove = () => {
    
    let flag = 1;
    const selectedIDs = selectedRows.map(row => row.instructionListConfigID).join(',');
    for (let row of selectedRows) {
      if (row.currentStatus == 'Draft' || row.currentStatus == 'Rejected' || row.status == 'InActive') {
        flag = 0;
        break;
      }
      if (row.currentStatus == 'Approved') {
        flag = -1;
        break;
      }
    }
    if (flag == 1) {
      approve(selectedIDs);
    } else if (flag == 0) {
      addToast("Draft/Rejected Instruction(s) cannot be approved. Please Send For Approval.", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      addToast("Instruction list is already approved.", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }
  const approve = (e) => {
    
    let body = {
      instructionListConfigID: e,
      currentStatus: "Approved",
      subProcessID: selectedprocessSubprocess,
      updatedBy: sessionStorage.getItem("PersonID")
    };
    let loginUrl = getAPIURL(React_App_ApproveInstructionListConfig);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          addToast("Approved Selected Instruction Lists", {
            appearance: "success",
            autoDismiss: true,
          });
          FetchInstructionListData(selectedActivity);
          getDataForExport();
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Approval Failed", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }
  const handleReject = () => {
    setviewRejectModal(false);
    const selectedIDs = selectedRows.map(row => row.instructionListConfigID).join(',');
    reject(selectedIDs);
  }

  const reject = (e) => {
    let listconfigid = e ? e : instructionId;
    let body = {
      instructionListConfigID: listconfigid.toString(),
      subProcessID: selectedprocessSubprocess,
      currentStatus: "Rejected",
      comments: comments,
      updatedBy: sessionStorage.getItem("PersonID")
    };
    let loginUrl = getAPIURL(React_App_RejectInstructionListConfig);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          addToast("Rejected Selected Instruction List(s)", {
            appearance: "success",
            autoDismiss: true,
          });
          setviewRejectModal(false);
          FetchInstructionListData(selectedActivity);
          getDataForExport();
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Rejection Failed", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }
  const handleSendforApproval = () => {
    
    let flag = 1;
    const selectedIDs = selectedRows.map(row => row.instructionListConfigID).join(',');
    for (let row of selectedRows) {
      if (row.currentStatus == 'Awaiting Approval') {
        flag = 0;
        break;
      }
      if (row.currentStatus == 'Approved' || row.status == 'InActive') {
        flag = -1;
        break;
      }
    }
    if (flag == 1) {
      sendforApproval(selectedIDs);
    } else if (flag == 0) {
      addToast("Instruction has already been sent for approval.", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      addToast("Instruction list are either approved or Inactive.", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }
  const sendforApproval = (e) => {
    
    let body = {
      instructionListConfigID: e,
      currentStatus: "Awaiting Approval",
      subProcessID: selectedprocessSubprocess,
      updatedBy: sessionStorage.getItem("PersonID")
    };
    let loginUrl = getAPIURL(React_App_ApproveInstructionListConfig);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          addToast("Selected Instructions successfully sent for approval.", {
            appearance: "success",
            autoDismiss: true,
          });
          FetchInstructionListData(selectedActivity);
          getDataForExport();
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Sending for approval failed", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }
  const RejectonClick = () => {
    
    let flag = 1;
    for (let row of selectedRows) {
      if (row.currentStatus == 'Rejected') {
        flag = 0;
        break;
      }
      if (row.currentStatus == 'Approved' || row.status == 'InActive') {
        flag = -1;
        break;
      }
    }
    if (flag == 1) {
      setviewRejectModal(true);
    } else if (flag == 0) {
      addToast("Instruction has already been rejected.", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      addToast("Selected instructions are either approved or inactive.", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }
  const RejectModalclose = () => {
    setviewRejectModal(false);
  }
  const onCommentAdd = (e) => {
    setComments(e.target.value);
  }
  return (
    <><Header
      onpreferenceShow={null}
      pageName="SubProcess Instruction List"
      timeCaptureActivityName="InstructionList"
    />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="admin" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      {isLoading ? (
        <LoadingSpinnerFigma />
      ) : (
        <><Modal
          show={AttchmentModal}
          dialogClassName="modal-50w" // Adjust the width of the modal
          onHide={AttachmentClose}
          fullscreen={"lg-down"}
          aria-labelledby="contained-modal-title-vcenter"
          centered // Center the modal vertically
        >
          <Modal.Header closeButton>
            <Modal.Title>Attachment Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row justify-content-center"> {/* Center the modal horizontally */}
                <div className="col-lg-12"> {/* Adjust the column width as needed */}
                  <div className="timeBreak">
                    <ul className="timeBreak-form">
                      <div className="tableWrap">
                        <div className="tableWrap-body">
                          <table className="table brandTable">
                            <div>
                              <div className="fieldSet pt-1">
                                <div className="pt-2 pb-2">
                                  <div className="mb-5">
                                    <div>
                                      <table className="table MatTable">
                                        <MaterialTable
                                          localization={{
                                            pagination: {
                                              //labelDisplayedRows: '{from}-{to} of {count}'
                                            },
                                            toolbar: {
                                              // nRowsSelected: '{0} row(s) selected'
                                            },
                                            header: {
                                              actions: "",
                                            },
                                          }}
                                          actions={[
                                            {
                                              icon: () => <DeleteIcon />,
                                              tooltip: "Delete",
                                              onClick: (event, rowData) =>
                                                handleAttachmentDelete(rowData),
                                              position: "row",
                                            },
                                          ]}
                                          options={{
                                            search: false,
                                            // selection: true,
                                            tableLayout: "auto",
                                            debounceInterval: 700,
                                            overflowY: false,
                                            showTitle: false,
                                            toolbarButtonAlignment: "left",
                                            paging: false,
                                            toolbar: false,
                                            headerStyle: {
                                              height: 30,
                                            },
                                            rowStyle: {
                                              fontFamily: '"Yantramanav", sans-serif',
                                              fontSize: "14px",
                                            },
                                            tableLayout: "auto",
                                            headerStyle: {
                                              fontFamily: '"Yantramanav", sans-serif',
                                              fontWeight: "inherit",
                                              fontSize: "14px",

                                              textAlign: "left !Important",
                                            },
                                          }}
                                          columns={columnsModal}
                                          icons={tableIcons}
                                          data={AttachmentDetails}
                                        />
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </table>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>


          <Modal
            show={viewRejectModalAddEdit}
            dialogClassName="modal-30w"
            onHide={RejectModalcloseAddEdit}
            fullscreen={"lg-down"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div>
                  <textarea
                    name="Text1"
                    cols="50"
                    rows="5"
                    maxLength="4000"
                    onChange={onCommentAdd}
                  ></textarea>
                </div>
                <div className="center">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary ms-2"
                    onClick={() => {
                      handleRejectAddEdit();
                    }}
                    title="Click here to add new instruction"
                  >
                    <b></b>Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary ms-2"
                    onClick={() => {
                      RejectModalcloseAddEdit();
                    }}
                    title="Click here to add new instruction"
                  >
                    <b></b>Cancel
                  </button>
                </div>
              </>
            </Modal.Body>
          </Modal>
          <Modal
            show={mainModalview}
            dialogClassName="modal-100w"
            onHide={Mainmodalclose}
            fullscreen={"lg-down"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add/Edit Sub-Process Instructions List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="timeBreak">
                  <ul className="timeBreak-form">
                    <div className="tableWrap">
                      <div className="tableWrap-body">
                        <table className="table brandTable">
                          <div>
                            <div className="fieldSet pt-1">
                              <div className="form-control form-control-sm">
                                <div className="dashBoxs mb-3">
                                  <div className="formWrap">
                                    <h6>Instruction Details</h6>
                                    <div className="row align-items-end">
                                      <div className="col-4">
                                        <div className="mb-3">
                                          <label className="form-label"> Instruction Id </label>
                                          <input className="form-control form-control-sm read-only" 
                                            type="text"
                                            value={instructionId === 0 ? ("") : instructionId}
                                            title="Instruction List Config ID"
                                            readOnly
                                        
                                          />
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="mb-3">
                                          <label className="form-label">Instruction Title *</label>
                                          <input
                                            className="form-control form-control-sm"
                                            // onChange={FetchProcessSubProcessByGuid}
                                            type="text"
                                            value={instructionTitle}
                                            onChange={instructionTitleTextBox}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="mb-3">
                                          <label className="form-label">Instruction Type </label>
                                          <select
                                            className="form-control form-control-sm"
                                            value={instructionType}
                                            onChange={instructionTypeDropdown}
                                            defaultValue="-- Select Instruction Type --"
                                          >
                                            <option value="">-- Select Instruction Type --</option>
                                            {instructionTypeList.map((sourceType, index) => (
                                              <option
                                                key={index}
                                                value={sourceType.value}
                                              >
                                                {sourceType.name}
                                              </option>
                                            ))}



                                          </select>
                                        </div>
                                      </div>

                                    </div>
                                    <div className="row align-items-end">
                                      <div className="col-4">
                                        <div className="mb-3">
                                          <label className="form-label">Display Order </label>
                                          <select
                                            className="form-control form-control-sm"
                                            value={displayOrder}
                                            onChange={displayOrderDropdown}
                                            defaultValue="-- Select Display Order --"
                                          >
                                            <option value="">-- Select Display Order --</option>
                                            {displayOrderList.map((sourceType, index) => (
                                              <option
                                                key={index}
                                                value={sourceType.value}
                                              >
                                                {sourceType.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-4 mb-3">
                                        <label className="form-label">Background Color</label>
                                          <div className="form-control form-control-sm backgroundfieldColor">
                                            <input
                                              type="color"
                                              className="coloricon"
                                              onChange={FetchOnchangeTC}
                                              id="favcolor"
                                              name="favcolor"
                                              value={TargetFieldColor}
                                         
                                            />
                                            <span className="colorcode">{TargetFieldColor}</span>

                                            <button  className="btn btn-primary ms-3" onClick={handleResetColor}>Reset</button>
                                          </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="mb-3">
                                          <label className="form-label"> Next Review Date </label>
                                          <DatePicker
                                            className="form-control form-control-sm"
                                            selected={nextReviewDate}
                                            onChange={(date) => setNextReviewDate(date)}
                                            dateFormat="MM/dd/yyyy" // Customize the date format
                                            placeholderText="Select a date"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row align-items-end">
                                      <div className="col-4">
                                        <div className="mb-3">
                                          <label className="form-label"> Approval Status </label>
                                          <input className="form-control form-control-sm read-only"
                                            type="text"
                                            value={approvalStatus === "Draft" ? ("") : approvalStatus}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="mb-3">
                                          <label className="form-label">Activity Type </label>
                                          
                                            <Select
                                              defaultValue={[]}
                                              isMulti
                    
                                              options={ActivitiesForSubprocess.map((subprocess) => (     
                                                {
                                                  label:subprocess.ActivityName,
                                                  value: subprocess.ActivityID
                                                }
                                              ))}
                                              onChange={FetctActivityID}
                                              onRemove={onRemove}
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                            />
                                            
                                          
                                           
                                          
                                        </div>
                                      </div>
                                      <div className="col-4">
                                        <div className="mb-3">
                                          <label className="form-label">Is Active *</label>
                                          <input
                                            type="checkbox"
                                            checked={isActiveCheck}
                                            onChange={isActiveCheckbox}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="dashBoxs mb-3">
                                  <div className="formWrap">
                                    <h6>Rule Details *</h6>
                                   
                                    <div className="row align-items-end">
                                      {WorkitemFields.map((field, index) => (
                                        <div className="col-6" key={index}>
                                          <div className="row mb-3">
                                            <div className="col-2">
                                              <label className="form-label">{field.labelText}</label>
                                            </div>
                                            <div className="col-4 mb-1">
                                              <select
                                                className="form-control form-control-sm"
                                                value={fieldValues[index]?.conditions}
                                                onChange={(event) => handleConditionDropdownChange(index, event)}
                                                defaultValue="Equals"
                                              >
                                                {ruleDropdownList.map((sourceType, idx) => (
                                                  <option key={idx} value={sourceType.value}>
                                                    {sourceType.name}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                            <div className="col-4 mb-1">
                                              <input
                                                className="form-control form-control-sm"
                                                type="text"
                                                value={fieldValues[index]?.inputText}
                                                onChange={(event) => handleConditionInputChange(index, event)}
                                              />
                                            </div>
                                            <div className="col-2">
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="dashBoxs mb-3">
                                  <div className="formWrap">
                                    <h6>Instruction and Checklist Details</h6>
                                    <div className="row align-items-end">
                                      <div className="col-6 mb-3">
                                        <div className="mb-3 instructions-top" >
                                          <label className="form-label editor-container"> Instructions *</label>
                                      
                                          <CKEditor
                                            editor={ClassicEditor}
                                            onReady={(editor) => {
                                              // console.log('CKEditor React Component is ready to use!', editor);
                                            }}
                                            config={{
                                              readOnly: false,
                                              toolbar: !false
                                                ? [
                                                  'undo',
                                                  'redo',
                                                  '|',
                                                  'heading',
                                                  '|',
                                                  'bold',
                                                  'italic',
                                                  'underline',
                                                  'strikethrough',
                                                  '|',
                                                  'link',
                                                  'unlink',
                                                  '|',
                                                  'outdent',
                                                  'indent',
                                                  '|',
                                                  'fontSize',
                                                  '|',
                                                  'fontFamily',
                                                  '|',
                                                  'bulletedList',
                                                  'numberedList',
                                                  '|',
                                                  'removeFormat',
                                                  '|',
                                                  'insertTable'
                                                ]
                                                : [],
                                            }}
                                            data={instructionContent}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              setInstructionContent(data);
                                            }}
                                          />
                                        </div>
                                        <div className="row align-items-end">
                                          <div className="col-3">
                                            <label className="form-label fileupload">
                                              File to Upload:
                                            </label>
                                          </div>

                                          <div className="col-5 mb-1">
                                            <input
                                              className="form-control form-control-sm"
                                              title={selectedFile ? name : "Click here to select a file to upload"}
                                              type="file"
                                              onChange={readUploadFile}
                                              accept=".pdf" // Accept only PDF files
                                              value={uploadedFileName}
                                            />
                                          </div>

                                          <div className="col-4 mb-1 buttonUploadandView">
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-primary buttonspacing"
                                              onClick={() => {
                                                handleUploadPDFfile();
                                              }}
                                              title="Click here to upload file"
                                              
                                            >
                                              Upload
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-primary"
                                              onClick={() => {
                                                FetchUploadDetails();
                                                setAttchmentModal(true);

                                              }}
                                              title="click here to view file"
                                            >
                                              View
                                            </button>
                                          </div>

                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="row align-items-end">
                                          <div className="col-5">
                                            <div className="mb-3 chcklistdesc">
                                              <label className="form-label"> Check List Description *</label>

                                              <textarea
                                                name="Text1"
                                                cols="30"
                                                rows="2"
                                                maxLength="4000"
                                                value={checkListDescription}
                                                onChange={checkListDescriptionTextbox}
                                              ></textarea>
                                            </div>
                                          </div>

                                          <div className="col-2">
                                            <div className="mb-1">

                                              <div>

                                                <label className="form-label">Is Active </label>
                                                <input
                                                  type="checkbox"
                                                  checked={isActiveCheckTable}
                                                  onChange={isActiveCheckboxTable}
                                                />

                                                <label className="form-label">Mandatory </label>
                                                <input
                                                  type="checkbox"
                                                  checked={isMandatoryCheck}
                                                  onChange={isMandatoryCheckbox}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-3">
                                            <div className="mb-3">
                                              <label className="form-label"> Display Order</label>
                                              <input
                                                className="form-control form-control-sm"
                                                type="number"
                                                value={displayOrderValue}
                                                onChange={handleInputChange}
                                                min="0" // Optional: Set minimum value (e.g., prevent negative values)
                                                step="1" // Optional: Set step to 1 (only allow integer increments)
                                              />
                                            </div>
                                          </div>
                                          <div className="col-2">
                                            <div className="mb-0">
                                              <button
                                                className="btn btn-primary ms-3 mb-1"
                                                onClick={() => {

                                                  handleCheckListDetails();
                                                }}
                                              >
                                                {buttonText}
                                              </button>
                                              <button
                                                className="btn btn-primary ms-3"
                                                onClick={() => {
                                                  Reset(0);
                                                }}
                                              >
                                                Reset
                                              </button>
                                            </div>
                                          </div>
                                        </div>

                                        <table
                                          className="table table-borderless MatTable"
                                          style={{ zIndex: "1", position: "relative" }}
                                        >
                                          <ThemeProvider theme={theme}>
                                            <MaterialTable
                                              localization={{
                                                pagination: {
                                                 
                                                  labelRowsPerPage: "{50, 100, 250,500}",
                                                },
                                               
                                              }}
                                              actions={[
                                                {
                                                  icon: () => <EditIcon />,
                                                  tooltip: "Click here to Edit.",
                                                  onClick: (event, rowData) => {
                                                    handleChecklistUpdate(rowData);
                                                    //   position: "row"
                                                    setButtonText("Update");
                                                  }

                                                }
                                              ]}
                                              options={{
                                                search: false,
                                                overflowY: false,
                                                showTitle: false,
                                                filtering: true,
                                                cellStyle: {
                                                  // width: 100,
                                                  // minWidth: 100,
                                                },
                                                headerStyle: {
                                                  position: "sticky",
                                                  // top: "-5px",

                                                },
                                                rowStyle: {
                                                  fontFamily: '"Yantramanav", sans-serif',
                                                },
                                                selection: false,
                                              }}
                                              components={{
                                                Container: (props) => <Paper {...props} elevation={0} />,
                                                Toolbar: (props) => (
                                                  <div
                                                    style={{
                                                      backgroundColor: "rgb(206 206 206)",
                                                      fontSize: "0.75em",
                                                    }}
                                                  >
                                                    <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                                                  </div>
                                                ),
                                              }}
                                              columns={Checklistcolumns}
                                              icons={tableIcons}
                                              data={dataCheckListDescription}
                                            />
                                          </ThemeProvider>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 text-start mt-2 mb-2">
                                    {showSaveAndContinue ? <button
                                      className="btn btn-primary ms-3"
                                      onClick={handleSaveAndContinue}
                                    >
                                      Save & Continue
                                    </button> : null}
                                    {showSave ? <button
                                      className="btn btn-primary ms-3"
                                      onClick={handleSave}
                                    >
                                      {savebuttonText}
                                    </button> : null}
                                    {showSaveAndSendforApproval ? <button
                                      className="btn btn-primary ms-3"
                                      onClick={handleSaveAndSendforApproval}
                                    >
                                      Save & Send for Approval
                                    </button> : null}
                                    {showSaveandApprove ? <button
                                      className="btn btn-primary ms-3"
                                      onClick={handleSaveandApprove}
                                    >
                                      Save & Approve
                                    </button> : null}
                                    <button
                                      className="btn btn-primary ms-3"
                                      onClick={handleBack}
                                    >
                                      Back
                                    </button>
                                    {showReject ? <button
                                      className="btn btn-primary ms-3"
                                      onClick={handleRejectonAddEdit}
                                    >
                                      Reject
                                    </button> : null}
                                  </div>
                                </div>
                                {showAuditTrail ? <div className="dashBoxs mb-3">
                                  <div className="tableWrap">
                                    <div className="tableWrap-body">
                                      <table className="table table-borderless MatTable">
                                        <MaterialTable
                                          localization={{
                                            pagination: {
                                            },
                                            toolbar: {
                                              // nRowsSelected: '{0} row(s) selected'

                                              exportCSVName: "Export as Excel",
                                              exportPDFName: "Export as pdf"
                                            },
                                            header: {
                                              actions: ""
                                            }
                                          }}
                                         
                                          options={{
                                            headerStyle: {
                                              position: "sticky",
                                              top: "-5px"
                                            },
                                            maxBodyHeight: "420px",
                                            showTitle: false,
                                            exportButton: false,
                                            sorting: true,
                                            search: false,
                                            selection: false,
                                            toolbarButtonAlignment: "left",
                                            overflowY: false,
                                            headerStyle: {
                                              height: 30
                                            },
                                            rowStyle: {
                                              fontFamily: '"Yantramanav", sans-serif',
                                              fontSize: "13px"
                                              // whiteSpace: "nowrap",
                                            },
                                            tableLayout: "auto",
                                            headerStyle: {
                                              fontFamily: '"Yantramanav", sans-serif',
                                              fontWeight: "inherit",
                                              fontSize: "14px",
                                              textAlign: "left !Important"
                                            }
                                          }}
                                          title="Employee Mapping Details"
                                          columns={AuditColumns}
                                          icons={tableIcons}
                                          data={auditData}
                                          components={{
                                            Container: props => (
                                              <Paper {...props} elevation={0} />
                                            ),
                                            Toolbar: props => (
                                              <div
                                                style={{
                                                  backgroundColor: "rgb(206 206 206)",
                                                  fontSize: "0.75em"
                                                }}
                                              >
                                                <MTableToolbar
                                                  {...props}
                                                  style={{
                                                    fontSize: "5px"
                                                  }}
                                                />
                                              </div>
                                            )
                                          }}
                                        />
                                      </table>
                                    </div>
                                  </div>
                                </div> : null}
                              </div>
                            </div>
                          </div>
                        </table>
                      </div>
                    </div>
                  </ul>
                </div>
              </>
            </Modal.Body>
          </Modal>
          <Modal
            show={viewRejectModal}
            dialogClassName="modal-30w"
            onHide={RejectModalclose}
            fullscreen={"lg-down"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div>
                  <textarea
                    name="Text1"
                    cols="50"
                    rows="5"
                    maxLength="4000"
                    onChange={onCommentAdd}
                  ></textarea>
                </div>
                <div className="center">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary ms-2"
                    onClick={() => {
                      handleReject();
                    }}
                    title="Click here to add new instruction"
                  >
                    <b></b>Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary ms-2"
                    onClick={() => {
                      RejectModalclose();
                    }}
                    title="Click here to add new instruction"
                  >
                    <b></b>Cancel
                  </button>
                </div>
              </>
            </Modal.Body>
          </Modal>
          <div className="centerSection">
            <div className="pageBody">
              <div className="dashBoxs mb-3">
                <div className="formWrap">
                  <div className="row align-items-end">
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label"> Company Department</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchLocationClientsByGuid}
                          title="Select a Company-Department"
                        >
                          <option>Select</option>
                          {departments.map((department) => (
                            <option
                              value={
                                department.companyDepartment +
                                "/" +
                                department.departmentIDString
                              }
                              selected={
                                department.departmentIDString == companyDeparmentID
                              }
                            >
                              {department.companyDepartment}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Location Client</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchProcessSubProcessByGuid}
                          title="Select a Location-Client"
                        >
                          <option>Select</option>
                          {clientLocations.map((location) => (
                            <option
                              value={
                                location.locationClient +
                                "/" +
                                location.clientIDString
                              }
                              selected={location.clientIDString == locationClientID}
                            >
                              {location.locationClient}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Process SubProcess</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={handleSubprocessChange}
                          title="Select a Process-SubProcess"
                        >
                          <option>Select</option>
                          {processSubprocess.map((subprocess) => (
                            <option
                              value={subprocess.subProcessIDString}
                              selected={
                                subprocess.subProcessIDString == processSubProcessID
                              }
                            >
                              {subprocess.processSubProcess}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Activity Name</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={handleActivityChange}
                          title="Select an Activity"
                        >
                          <option value="0">-- All --</option>
                          {ActivitiesForSubprocess.map((item) => (
                            <option
                              value={item.ActivityID}
                            >
                              {item.ActivityName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashBoxs">
                {/* {isTableLoading ? (
                <LoadingSpinnerFigma />
              ) : */}
                {/* ( */}
                <div className="tableWrap-body">
                  <table className="table table-borderless MatTable">

                    <MaterialTable
                      localization={{
                        pagination: {
                        },
                        toolbar: {
                          exportCSVName: "Export as Excel",
                          exportPDFName: "Export as pdf"
                        },
                        header: {
                          actions: ""
                        }
                      }}
                      actions={[
                        {
                          icon: () => <DeleteIcon />,
                          tooltip: "Delete instruction Details",
                          onClick: (event, rowData) => handleRowDelete(rowData),
                          position: "row",
                          hidden: deleteVisibility
                        },
                        {
                          icon: () => <EditIcon />,
                          tooltip: "Update instruction Details",
                          onClick: (event, rowData) => EditInstructions(rowData),
                          position: "row",
                          hidden: editVisibility
                        }
                      ]}
                      //   tableRef={tableRef}
                      options={{
                        headerStyle: {
                          position: "sticky",
                          top: "-5px"
                        },
                        maxBodyHeight: "420px",
                        showTitle: false,
                        exportButton: false,
                        sorting: true,
                        search: false,
                        selection: true,
                        selectionProps: rowData => ({
                          disabled: !((['Draft', 'Awaiting Approval', 'Rejected'].includes(rowData.currentStatus)) && (['Active'].includes(rowData.status)))
                        }),
                        toolbarButtonAlignment: "left",
                        overflowY: false,
                        headerStyle: {
                          height: 30
                        },
                        rowStyle: {
                          fontFamily: '"Yantramanav", sans-serif',
                          fontSize: "13px"
                        },
                        tableLayout: "auto",
                        headerStyle: {
                          fontFamily: '"Yantramanav", sans-serif',
                          fontWeight: "inherit",
                          fontSize: "14px",
                          textAlign: "left !Important"
                        }
                      }}
                      onSelectionChange={(rows) => setSelectedRows(rows)}
                      title="Instruction List Details"
                      columns={columns}
                      icons={tableIcons}
                      data={tableData}
                      components={{
                        OverlayLoading: props => <LoadingSpinnerFigma />,
                        Container: props => (
                          <Paper {...props} elevation={0} />
                        ),
                        Toolbar: props => (
                          <div
                            style={{
                              backgroundColor: "rgb(206 206 206)",
                              fontSize: "0.75em"
                            }}
                          >
                            <MTableToolbar
                              {...props}
                              style={{ fontSize: "5px" }}
                            />
                          </div>
                        )
                      }}
                    />
                  </table>
                </div>
               
                <div className="row align-items-center pt-2">
                  <div className="col">
                    {addNewVisibility ? <div className="d-inline">
                      {(
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            AddEditInstructions("");
                          }}
                          title="Click here to add new instruction"
                        >
                          <b></b>Add New Instruction
                        </button>
                      )}
                    </div> : null}
                    {sendforApprovalVisibility ? <div className="d-inline">
                      {(
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            handleSendforApproval();
                          }}
                          title="Click here to send for approval"
                        >
                          <b></b>Send for approval
                        </button>
                      )}
                    </div> : null}
                    {approveVisibility ? <div className="d-inline">
                      {(
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            handleApprove();
                          }}
                          title="Click here to approve selected instructions"
                        >
                          <b></b>Approve
                        </button>
                      )}
                    </div> : null}
                    {rejectVisibility ? <div className="d-inline">
                      {(
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            RejectonClick();
                          }}
                          title="Click here to reject selected instructions"
                        >
                          <b></b>Reject
                        </button>
                      )}
                    </div> : null}
                    <div className="d-inline">
                      {(
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            handleButtonClick();
                          }}
                          title="Click here to export Instructions List to Excel"
                        >
                          <b></b>Export to Excel
                        </button>
                      )}
                    </div>
                    {importfromExcelVisibility ? <div className="d-inline">
                      {(
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          disabled={isUpload}
                          title="Click here to import instructions"
                          onClick={ImportFile}
                        >
                          Import
                        </button>
                      )}
                    </div> : null}
                    {importfromExcelVisibility ? <div className="d-inline ms-2">
                      <input
                        className="form-control-sm"
                        title="Excel File with .xls and .xlsx extensions are allowed"
                        type="file"
                        onChange={readUploadFile}
                        value={uploadedFileMainpage}
                      />
                    </div> : null}
                  </div>
                </div>
                {/* </div> */}
              </div>
              <div className="dashBoxs col-3">
              
                <div className="tableWrap-body">
                  <table className="table table-borderless MatTable">

                    <MaterialTable
                      localization={{
                        pagination: {
                        },
                        toolbar: {
                          exportCSVName: "Export as Excel",
                          exportPDFName: "Export as pdf"
                        },
                        header: {
                          actions: ""
                        }
                      }}
                      
                      options={{
                        headerStyle: {
                          position: "sticky",
                          top: "-5px"
                        },
                        // hideHeader:true,//test this later
                        maxBodyHeight: "420px",
                        showTitle: false,
                        exportButton: false,
                        sorting: true,
                        search: false,
                        selection: true,
                        toolbarButtonAlignment: "left",
                        overflowY: false,
                        headerStyle: {
                          height: 30
                        },
                        rowStyle: {
                          fontFamily: '"Yantramanav", sans-serif',
                          fontSize: "13px"
                        },
                        tableLayout: "auto",
                        headerStyle: {
                          fontFamily: '"Yantramanav", sans-serif',
                          fontWeight: "inherit",
                          fontSize: "14px",
                          textAlign: "left !Important"
                        }
                      }}
                      title="Approver Details"
                      columns={approverColumns}
                      icons={tableIcons}
                      data={approverdata}
                      components={{
                        OverlayLoading: props => <LoadingSpinnerFigma />,
                        Container: props => (
                          <Paper {...props} elevation={0} />
                        ),
                        Toolbar: props => (
                          <div
                            style={{
                              backgroundColor: "rgb(206 206 206)",
                              fontSize: "0.75em"
                            }}
                          >
                            <MTableToolbar
                              {...props}
                              style={{ fontSize: "5px" }}
                            />
                          </div>
                        )
                      }}
                    />
                  </table>
                </div>
              
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default SubProcessInstructionListConfig;
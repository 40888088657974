import LoadingSpinner from "../../../../LoadingSpinner";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import '../../../../assets/scss/nvh_style.scss'

const { REACT_APP_LOGIN } = process.env;
const { REACT_APP_API_URL } = process.env;
const { REACT_APP_NAME } = process.env;
const { REACT_APP_AllowClientResetPassword } = process.env;
const { REACT_APP_CLIENTURL } = process.env;
const getAPIURL = (api) => {
  let apiURL = REACT_APP_API_URL;
  return `${apiURL}${api}`;
};

//toast.configure()
function NerveHubLogin({ onSSOLogin }) {
  let navigate = useNavigate();
  const [appInitial, setAppInitial] = useState("");
  const [appName, setAppName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const urlQueryString = window.location.search;
    const params = new URLSearchParams(urlQueryString);
    const platform = params.get("platform");

    if(platform) {
      //import('../../../../assets/scss/nvh_style.scss');
      sessionStorage.setItem("ApplicationURL", window.location.origin);
      setAppInitial(params.get("appinitials"));
      setAppName(params.get("appname"));
      NerveHubSSO(platform);
    }
  }, []);


  const NerveHubSSO = (ssoSource) => {
    let body = {
      SSOSource: ssoSource,
      applicationname: sessionStorage.getItem("ApplicationURL"),
      ForceLogin: false
    };

    onSSOLogin(body);
  }

  return (
    <div className="light-mode abs-center overflow-hidden">
      <div className="nvh_container">
        <div className="separator"></div>
        <div className="app-initials">
          <span>{appInitial}</span>
        </div>
        <div className="app-name">{appName}</div>
        <div className="loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="separator"></div>
        <div className="logo">
          <div className="platform-logo">
            <div className="platform-logo__box">
              <div className="platform-logo__box__logo">
                {/* <img alt="logo" className="b-w-filter" src="https://finance-exleratortest1.exlservice.com/platform-api/v2/assets/exl_logo_rgb_orange_pos_94.png" /> */}
                EXL
              </div>
            </div>&nbsp;
            <div className="platform-logo__name">
              <span className="platform-logo__name__1"> NerveHub<sup>TM</sup></span>
              <span className="platform-logo__name__3">
                <span className="platform-logo__name__2"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>  
  );
}

export default NerveHubLogin;

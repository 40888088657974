import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { useToasts } from "react-toast-notifications";

function ImportSubProcessConfiguration() {
  const { addToast } = useToasts();
  let navigate = useNavigate();
  const location = useLocation();
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_IMPORTXMLFILE } = process.env;
  const { React_APP_FetchAllProcessSubProcessByGuid } = process.env;
  const processingDashboardParam = location.state;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [uploadedFile, setuploadedFile] = useState("");
  const [selectedFile, setselectedFile] = useState();
  const [name, setname] = useState("");
  const [UploadingFile, setUploadingFile] = useState("");
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [COMPANY, setCOMPANY] = useState("");
  const [DEPARTMENT, setDEPARTMENT] = useState("");
  const [LOCATION, setLOCATION] = useState("");
  const [CLIENT, setCLIENT] = useState("");
  const [PROCESSNAME, setPROCESSNAME] = useState("");
  const [SUBPROCESSNAME, setSUBPROCESSNAME] = useState("");

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  let company = (sessionStorage.getItem("selectedDepartmentName").split('~')[0].trim());
  let Department = (sessionStorage.getItem("selectedDepartmentName").split('~')[1].trim());
  let locationn = (sessionStorage.getItem("selectedLocationName").split('~')[0].trim());
  let client = (sessionStorage.getItem("selectedLocationName").split('~')[1].trim());
  let processs = (sessionStorage.getItem("SelctedSubprocessName").split('~')[0].trim());
  let subprocess = (sessionStorage.getItem("SelctedSubprocessName").split('~')[1].trim());

  const ImportFile = (event) => {
    if (!uploadedFile) {
      addToast('Select a file to import.', {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    if (!uploadedFile.endsWith('.xml')) {
      addToast("Only .xml files are allowed to be imported", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (company === COMPANY && Department === DEPARTMENT && locationn === LOCATION && client === CLIENT && processs === PROCESSNAME && subprocess === SUBPROCESSNAME) {
      uploadFileOnServer(event);
    } else {
      addToast("The uploaded file does not match with the selected subprocess.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }

  const uploadFileOnServer = (event) => {
    const formData = new FormData();

    formData.append("strCompany", sessionStorage.getItem("selectedDepartmentName").split('~')[0].trim());
    formData.append("strDepartment", sessionStorage.getItem("selectedDepartmentName").split('~')[1].trim());
    formData.append("strLocation", sessionStorage.getItem("selectedLocationName").split('~')[0].trim());
    formData.append("strClient", sessionStorage.getItem("selectedLocationName").split('~')[1].trim());
    formData.append("strProcess", sessionStorage.getItem("SelctedSubprocessName").split('~')[0].trim());
    formData.append("strSubProcess", sessionStorage.getItem("SelctedSubprocessName").split('~')[1].trim());
    formData.append("SubProcessId", sessionStorage.getItem("ProcessId"));
    formData.append("file", UploadingFile);
    formData.append("ForceUpload", event);

    let loginUrl = getAPIURL(REACT_APP_IMPORTXMLFILE);

    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: formData
    });

    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.path !== "") {
          if (result.data == 1) {
            addToast("File has been imported successfully", {
              appearance: "success",
              autoDismiss: true,
            });
          }
          else if (result.data == 2) {
            addToast("The Sub-Process Details To Be Imported Does Not Match With The Values In EXL MDM.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
          else if (result.data == 3) {
            const confirmOverride = window.confirm("A configuration for the selected sub process already exists. Do you want to overwrite the existing configuration with new configuration?");
            if (confirmOverride) {
              uploadFileOnServer(1);
            } else {
              return;
            }
          }
          else if (result.data == 4){
            addToast("The Activities In The Import File Does Not Match With The Existing Values In Production Dashboard.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
          else if (result.data == 5) {
            addToast("The Workflow States In The Import File Does Not Match With The Existing Values In Production Dashboard.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
          else if (result.data == 6) {
            addToast("The Work Item Statuses In The Import File Does Not Match With The Existing Values In Production Dashboard.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
        else {
          alert("Something went wrong", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const readUploadFile = (e) => {
    const type = e.target.files[0].type;
    const file = e.target.files[0];
    setselectedFile(e.target.files[0]);
    setname(e.target.files[0].name);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const xmlText = e.target.result;
        compareDropdownValues(xmlText);
      };
      reader.readAsText(file);
    }
    if (
      type.includes("text") ||
      type.includes("msword") ||
      type.includes("application/octet-stream") ||
      type.includes("excel") ||
      type.includes("image") ||
      type.includes("powerpoint") ||
      type.includes("pdf") ||
      type.includes("openxmlformats-officedocument")
    ) {
      if (e.target.files[0].size <= 10240000) {
        setUploadingFile(e.target.files[0]);
        setuploadedFile(e.target.value);
      } else {
        addToast("Please Upload xml file.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      addToast("Invalid Format or the file is open.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const compareDropdownValues = (xmlText) => {
    var parser = new DOMParser();
    var doc, setstrCompanyXml, setstrDepartmentXml, setstrLocationXml, setstrClientNameXml, setstrProcessNameXml, setstrSubProcessNameXml;
    doc = parser.parseFromString(xmlText, 'text/xml');
    var x = doc.getElementsByTagName("SubProcessIDRoot");

    for (let i = 0; i < x.length; i++) {
      setstrCompanyXml = (x[i].getElementsByTagName("Company")[0].childNodes[0].nodeValue);
      setCOMPANY(setstrCompanyXml);
      setstrDepartmentXml = (x[i].getElementsByTagName("Department")[0].childNodes[0].nodeValue);
      setDEPARTMENT(setstrDepartmentXml);
      setstrLocationXml = (x[i].getElementsByTagName("Location")[0].childNodes[0].nodeValue);
      setLOCATION(setstrLocationXml);
      setstrClientNameXml = (x[i].getElementsByTagName("ClientName")[0].childNodes[0].nodeValue);
      setCLIENT(setstrClientNameXml);
      setstrProcessNameXml = (x[i].getElementsByTagName("ProcessName")[0].childNodes[0].nodeValue);
      setPROCESSNAME(setstrProcessNameXml);
      setstrSubProcessNameXml = (x[i].getElementsByTagName("SubProcessName")[0].childNodes[0].nodeValue);
      setSUBPROCESSNAME(setstrSubProcessNameXml);
    }
  }

  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setlocationClientID("");
    setprocessSubProcessID("");
    setprocessSubprocess([]);
    if (e.target.value == "Select") {
      setClientLocations([]);
    }
    if (e.target.value !== "Select") {
      sessionStorage.setItem(
        "selectedDepartment",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedDepartmentName",
        e.target.value.split("/")[0]
      );
      setcompanyDeparmentID(e.target.value.split("/")[1]);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
  };

  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setprocessSubProcessID("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
    } else {
      if (e.target.value !== "Select") {
        let result = "";
        let locationArray = e.target.value.split("/");
        for (let i = 0; i < locationArray.length - 1; i++) {
          result = result.concat(locationArray[i]);
          if (i < locationArray.length - 2) {
            result = result.concat("/");
          }
        }
        setlocationClientID(e.target.value.split("/")[0]);
        sessionStorage.setItem(
          "selectedLocation",
          e.target.value.split("/")[e.target.value.split("/").length - 1]
        );
        sessionStorage.setItem("selectedLocationName", result);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: locationArray[locationArray.length - 1],
        };

        let loginUrl = getAPIURL(React_APP_FetchAllProcessSubProcessByGuid);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:"Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              navigate("/");
            } else {
              setprocessSubprocess([]);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      }
    }
  };
  
  const handleSubProcesChange = (e) => {
    if (e.target.value !== "Select") {
      sessionStorage.setItem("ProcessId", e.target.value.split("/")[0]);
      sessionStorage.setItem(
        "SelctedSubprocessName",
        e.target.value.split("/")[1]
      );
      setprocessSubProcessID( e.target.value.split("/")[0]);
    }
  };

  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    FetchDepartmentbyGUID();
  }, []);

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };

  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(React_APP_FetchAllProcessSubProcessByGuid);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        }else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(React_APP_FetchAllProcessSubProcessByGuid);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          sessionStorage.setItem(
            "SelctedSubprocessName",
            result.data[0].processSubProcess
          );
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };


  const NavElements = useMemo(() => {
    return (
      <>
        <Header onpreferenceShow={null} pageName=" Import Sub-Process Configuration" />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav selectedMenu="Configuration" />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);


  return (
    <>
      {NavElements}
      {/* <div className="centerSection"> */}
        <div className="pageBody">
          {/* <h5 class="mb-3" style={{ marginLeft: '7px' }}>Import Configuration</h5> */}
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label" span style={{ fontWeight: 'bold' }} >Company-Department</label>
                    <select
                      className="form-control form-control-sm" 
                      title="Please select a Company-Department"
                      onChange={FetchLocationClientsByGuid}
                    >
                      <option>Select</option>
                      {departments.map((department) => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.departmentIDString == companyDeparmentID
                          }
                        >
                          {department.companyDepartment}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label" span style={{ fontWeight: 'bold' }}>Location-Client</label>
                    <select
                      className="form-control form-control-sm" 
                      title="Please select a Location-Client"
                      onChange={FetchProcessSubProcessByGuid}
                    >
                      <option>Select</option>
                      {clientLocations.map((location) => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.clientIDString == locationClientID}
                        >
                          {location.locationClient}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label" span style={{ fontWeight: 'bold' }} >Process-SubProcess</label>
                    <select
                      className="form-control form-control-sm" 
                      title="Please select a Process-SubProcess"
                      onChange={handleSubProcesChange}
                    >
                      <option>Select</option>
                      {processSubprocess.map((subprocess) => (
                        <option
                          value={
                            subprocess.subProcessIDString +
                            "/" +
                            subprocess.processSubProcess
                          }
                          selected={
                            subprocess.subProcessIDString == processSubProcessID
                          }
                        >
                          {subprocess.processSubProcess}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="dashBoxs mb-3">
                  <div className="formWrap">
                    <div className="fieldSet">
                      <div className="row align-items-end">
                        <div>
                          <label className="form-label" span style={{ fontWeight: 'bold' }}> File to Upload
                          </label>
                        </div>
                        <div className="col-3">
                          <input
                            className="form-control form-control-sm" title={selectedFile ? name : "Click here to select a file to upload"}
                            type="file"
                            onChange={readUploadFile}
                          />
                        </div>
                        <div style={{ paddingTop: "5px" }}>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary "
                            onClick={() => {
                              ImportFile(0);
                            }}
                            title="click here to import subprocess configuration"
                          >
                            Import
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      {/* </div> */}

      <Footer />
    </>
  );
}
export default ImportSubProcessConfiguration;
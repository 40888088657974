import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DashboardContext from "../../../../contexts/DashboardContext";


import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';


const WorkitemUpdation = () => {

    
        const location = useLocation();
        const processingDashboardParam = location.state;

    return (
        <>
            <Header />



 <DashboardContext.Provider value={processingDashboardParam.nav}>
        <Nav />
      </DashboardContext.Provider>
            <div className="centerSection">
                <div className="pageHeader highLight">
                    <div className="row  align-items-center">
                        <div className="col-6">
                            <h2 className="pageName">Workitem Bulk Updation</h2>
                        </div>
                    </div>
                </div>
                <div className="pageBody">
                    <div className="dashBoxs mb-4">
                        <div className="formWrap">
                            <div className="row align-items-end">
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Company Department</label>
                                        <select className="form-control form-control-sm" title="Select a field to apply filter">
                                            <option>Select</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Location Client</label>
                                        <select className="form-control form-control-sm"><option>Select</option></select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Process Sub-process</label>
                                        <select className="form-control form-control-sm"><option>Select</option></select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Date From - To</label>
                                        <DateRangePicker initialSettings={{ startDate: '02/06/2022', endDate: '03/05/2022', autoApply:true, opens:'left' }} >
                                            <input type="text" className="form-control form-control-sm" />
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Bulk Updation File Name</label>
                                        <select className="form-control form-control-sm"><option>Select</option></select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="pb-3">
                                        <label className="form-label">Select File</label>
                                        <input className="form-control  form-control-sm" type="file" />
                                    </div>
                                </div>
                            </div>
                                
                        </div>
                    </div>
                    <div className="col-12 text-end mt-4">
                        <button className="btn btn-primary ms-3">Upload</button>
                    </div>
                    
                 </div>

            </div>
            <Footer />

        </>
    )
};

export default WorkitemUpdation;
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import LoadingSpinner from "../../../../LoadingSpinner";

//toast.configure()
function SSORedirect() {
  
const { addToast } = useToasts();
const [isLoading, set_IsLoading] = useState(false);

let navigate = useNavigate();

useEffect(() => {
  navigate("/SSOLogin");
  }, []);

  return (
    <>
    {isLoading ? (
      <LoadingSpinner />
    ) : (
    // <h4>Redirecting to CWS...Please wait</h4>
    <h4></h4>
    )}
    </>
  );
}

export default SSORedirect;
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import { useIdleTimer } from 'react-idle-timer'

const IdleTimeout = ({ onLogout }) => {
  const { REACT_APP_CLIENTURL } = process.env;
  const { REACT_APP_IdleTimeout, REACT_APP_Timeout } = process.env;
  const timeout = REACT_APP_IdleTimeout ? parseInt(REACT_APP_IdleTimeout) * 1000 : 3600000;
  const promptBeforeIdle = REACT_APP_Timeout ? parseInt(REACT_APP_Timeout) * 1000 : 120000;
  const promptBeforeIdleInSec = promptBeforeIdle / 1000;  
  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(timeout);
  const [openTimeoutPromptModal, setOpenTimeoutPromptModal] = useState(false);
  const currentURL = window.location.href;
  let isClientEnvironment = currentURL.includes(REACT_APP_CLIENTURL);

  const onIdle = () => {
    setState('Idle');
    if(isClientEnvironment) {      
      setOpenTimeoutPromptModal(false);
      handleLogOut(null, true);
    }
  }

  const onActive = () => {
    setState('Active');
    if(isClientEnvironment) {
      setOpenTimeoutPromptModal(false);
    }
  }

  const onPrompt = () => {
    setState('Prompted');
    if(isClientEnvironment) {
      setOpenTimeoutPromptModal(true);
    }
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    crossTab: true,
    emitOnAllTabs: true,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStayLoggedIn = () => {
    setOpenTimeoutPromptModal(false);
    activate();
    //setShowModal(false);
  };

  const handleLogOut = (e, autoLogout = false) => {
    setOpenTimeoutPromptModal(false);
    onLogout(null, autoLogout);
  };

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return (minutes > 0 ? minutes + " minute(s) " : "") + seconds + " seconds";
  }

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

  return (
    <>
      {/* handle isIdle for the modal */}
      <Modal
        show={openTimeoutPromptModal}
        dialogClassName="modal-25w"
        onHide={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Idle Timeout Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
              <p>You are about to be logged out due to inactivity.</p>
              Time remaining: {millisToMinutesAndSeconds(remaining * 1000)}
              <progress className="inactivity" value={remaining} max={promptBeforeIdleInSec} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-ntv mx-2" onClick={handleLogOut}>
            Logout
          </button>
          <button className="btn btn-primary" onClick={handleStayLoggedIn}>
            Stay Logged In
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IdleTimeout;

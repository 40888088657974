import React, { useState } from 'react';
import { Container, Row, Col, Table, Button,Modal,Form } from 'react-bootstrap';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Popover } from "@mui/material/Popover";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MaterialTable, { MTableToolbar } from "material-table";
import { Paper } from "@material-ui/core";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import { version } from 'react';
import { render } from '@testing-library/react';
import tableIcons from "src/MaterialTableIcons.js";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CryptoJS from 'crypto-js';

import "react-datepicker/dist/react-datepicker.css";
import DashboardContext from "src/contexts/DashboardContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import NavDataContext from "src/contexts/NavDataContext";

import Nav from '../nav/Nav';
import ApplicationUserContext from 'src/contexts/ApplicationUserContext';
import { useEffect } from 'react';
import { CryptoOps } from '@azure/msal-browser/dist/internals';
const Help = () => {
  const { REACT_APP_API_URL,REACT_APP_GETDocuments,REACT_APP_DownloadDocumentTest,REACT_APP_DOWNLOADWORKITEMATTACHMENT,
    REACT_APP_AddDocument,
    REACT_APP_UploadNewFileVersion,
    REACT_APP_UpdateDocumentLink,
    REACT_APP_AddVersion,
    REACT_APP_SendApprovalMail,
    REACT_APP_GetNewVersion,
    REACT_APP_GetDocVersionDetailsByDocId,
    REACT_APP_GetDocVersionByRequestId,
    REACT_APP_ApproveRequest,
    
   } = process.env;
  const { addToast } = useToasts();
  const [documents, setAdocuments] = useState([]);
  const [documentObj, setdocumentObj] = useState([]);
  const [openText, setopenText] = useState(false);
  const [IsVersion, setIsVersion] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [comments, setComments] = useState("");
  const [approvalRequestId, setApprovalRequestId] = useState("");
  const [versionDescription, setVersionDescription] = useState("");
  const [commentInput, setCommentInput] = useState("");
  const ApprovalRequestRef = React.useRef(null);
  const [count, setcount] = useState("");
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [documentLinkForDownload, setDocumentLinkForDownload] = useState("");
  let navigate = useNavigate();
  var isConfiguration = false;
  var isSuperAdmin = false;
   // Sample document data with versions
   const documents2 = [
    { 
      title: 'Document 1', 
      description: 'This document covers the initial release notes for the software, including key features and bug fixes.',
      versions: [
        { 
          version: 'v1.0', 
          date: '2024-10-01', 
          description: 'Initial release',
          uploadedBy: 'John Doe', 
          uploaderComments: 'First version uploaded after beta testing.',
          approvedBy: 'Jane Smith', 
          approvedOn: '2024-10-02', 
          approverComments: 'Approved for general use.'
        },
        { 
          version: 'v0.9', 
          date: '2024-09-20', 
          description: 'Beta release',
          uploadedBy: 'John Doe', 
          uploaderComments: 'This version was released for internal testing.',
          approvedBy: 'Jane Smith', 
          approvedOn: '2024-09-25', 
          approverComments: 'Approved for beta testing only.'
        },
      ]
    },
    { 
      title: 'Document 2', 
      description: 'This document contains updates and fixes in version 2.1 for performance improvements.',
      versions: [
        { 
          version: 'v2.1', 
          date: '2024-09-15', 
          description: 'Minor bug fixes',
          uploadedBy: 'Alice Brown', 
          uploaderComments: 'Small bug fixes in this version.',
          approvedBy: 'John Doe', 
          approvedOn: '2024-09-17', 
          approverComments: 'Looks good after fixes.'
        },
        { 
          version: 'v2.0', 
          date: '2024-08-25', 
          description: 'Major update',
          uploadedBy: 'Alice Brown', 
          uploaderComments: 'Major release with new features.',
          approvedBy: 'John Doe', 
          approvedOn: '2024-08-27', 
          approverComments: 'Major update approved for deployment.'
        },
      ]
    },
    // Other documents...
  ];

  useEffect(()=>{
    //  isConfiguration = JSON.parse(sessionStorage.getItem("rolesList")).some(obj => obj["roleName"] == "Configuration");
    //  isSuperAdmin = JSON.parse(sessionStorage.getItem("rolesList")).some(obj => obj["roleName"] == "Super Admin");
    getDocuments();
  },[])
  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const getDocuments = () => {
  
    let docURL = getAPIURL(REACT_APP_GETDocuments);
    let req = new Request(`${docURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
           
          setAdocuments(result.data.documents);
          setcount(result.data.documents.length);
        
        } else if (result.status === 401) {
          //;
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Error occured!", {
            appearance: "error",
            autoDismiss: true
          });
          //set_IsLoading(false);
        }
      })
      .catch(error => {
        //if (result.status === 401) {
        ;
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
        //navigate("/");
        //}
      });
  };
  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentVersion, setSelectedDocumentVersion] = useState(null);
  const [showAddDocModal, setShowAddDocModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [ShowDocumentReviewModal, setShowDocumentReviewModal] = useState(false);
  const [documentData, setDocumentData] = useState({
    title: '',
    description: '',
    versionNumber: '',
    versionDescription: '',
    uploadedBy: sessionStorage.getItem("PersonID"),
    uploaderComments: ''
  });
  const [documentVersion, setDocumentVersion] = useState("");
  const documentVersionRef = React.useRef(null);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#008000"
      },
      secondary: {
        main: "#FB4E0B"
      }
    }
  });


  // Function to handle modal open
  const handleShowModal = (document) => {
    setSelectedDocument(document);
    setShowModal(true);
    setSelectedDocumentVersion(document.versions);
  };

  // Inside your React component
const downloadFile = async (docId, docVersion, filepath, filename) => {
  try {
    let downloadurl = getAPIURL(REACT_APP_DownloadDocumentTest);
    
    let AttachmentUrl =
    getAPIURL(REACT_APP_DOWNLOADWORKITEMATTACHMENT) +
    "?fileName=" +
    filename+
    "&folderPath=" +
    filepath;
    let apiURL = REACT_APP_API_URL;
    if(!apiURL.toLowerCase().includes("workflowapi"))
    { 
    AttachmentUrl=`${downloadurl}?docId=${encodeURIComponent(docId)}&docVersion=${encodeURIComponent(docVersion)}`;
    } 
   const response = await fetch(AttachmentUrl, {
      method: 'GET',
    });

    if (!response.ok) {
      throw new Error('File download failed');
    }

    // Extract the blob from the response
    const blob = await response.blob();
    
    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);
    
    // Construct the file name for download
    //const fileName = `${docId}_v${docVersion}.txt`; // Adjust as necessary

    // Create a temporary link to initiate download
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and link
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};


const readbleDate=(dbDate)=>{
  const readableDate = dbDate? new Date(dbDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }):"";
  return readableDate;
}
  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDocument(null);
  };
  const handleAddVersionModal = (rowData) => {
    //alert("upload new version....");
    setDocumentVersion("");
    setVersionDescription("");
    setCommentInput("");
    setIsVersion(true);
    GetVersionNumberForDocument(rowData.documentId);
    setShowModal(false);
    setShowAddDocModal(true)
    setSelectedDocument(rowData);
  };
  const [file, setFile] = useState(null);

  const handleOpenAddDocModal = () => {
    setError("");
    setSelectedDocument(null);
    setDocumentVersion("");
    setVersionDescription("");
    setCommentInput("");
    setShowAddDocModal(true);
    setIsVersion(false);
    GetVersionNumberForDocument(-1);
  }
  const handleCloseAddDocModal = () => setShowAddDocModal(false);
  const handleShowUploadModal = () => setShowUploadModal(true);
  const handleCloseUploadModal = () => setShowUploadModal(false);
  const handleCloseDocumentReviewModal = () => setShowDocumentReviewModal(false);
  const handleOpenDocumentReviewModal = (row) => {
    setShowDocumentReviewModal(true);
    GetDocumentVersionDetailsByDocId(row.documentId)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDocumentData((prevData) => ({ ...prevData, [name]: value.replace(/[<>&]/g,"") }));
  };
  const handleVersionDescriptionInputChange = (e) =>{
    setVersionDescription(e.target.value.replace(/[<>&]/g,""));
  }
  const handleCommentInputChange = (e) =>{
    setCommentInput(e.target.value.replace(/[<>&]/g,""));
  }

  const GetDocumentVersionDetailsByDocId = (docId)=>{
    let docVersionUrl = getAPIURL(REACT_APP_GetDocVersionDetailsByDocId);
    let req =  new Request(`${docVersionUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName")
      },
      body: JSON.stringify({
        docId : docId
      })
    });
    fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            // console.log(result.data._documentVersionDetailsResponseModal);
            setTableData(result.data._documentVersionDetailsResponseModal);
            //setDocumentVersion(result.data[0].versionId);
            //setDocumentVersion(result.data._versionDetailsResponseModal[0].versionId);
            //setWorkFlowStatuses(result.data._listStatus);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
   }

 const GetVersionNumberForDocument = (docId)=>{
  let newVersionUrl = getAPIURL(REACT_APP_GetNewVersion);
  let req =  new Request(`${newVersionUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      UserName: sessionStorage.getItem("UserName")
    },
    body: JSON.stringify({
      docId : docId
    })
  });
  fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          // console.log(result.data._versionDetailsResponseModal[0].versionId);
          //setDocumentVersion(result.data[0].versionId);
          setDocumentVersion(result.data._versionDetailsResponseModal[0].versionId);
          //setWorkFlowStatuses(result.data._listStatus);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
 }
 const handleAddandUploadDocumentSubmit = async(e) =>{
  e.preventDefault();
  if(!isError){
  if(!selectedDocument)
    {
    // POST request to API endpoint
    let newdocURL = getAPIURL(REACT_APP_AddDocument);
    try {
      const response = await fetch(`${newdocURL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      
        },
        body: JSON.stringify({
          title: documentData.title,
          description: documentData.description,
          versions: [{
            versionNumber: documentVersion,
            versionDescription: versionDescription,
            uploadedBy: documentData.uploadedBy,
            uploaderComments: commentInput
          }]
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      else if(response.ok){
      const result = await response.json();
      // console.log("Document created successfully:", result);
      setdocumentObj(result.data);
      await handleUploadSubmit(result.data);
      }
      // Open the upload modal if the document is created successfully
      // setShowAddDocModal(false);
      // handleShowUploadModal();
  
    } catch (error) {
      console.error("Failed to create document:", error);
    }
    finally{
      getDocuments();
    }
  }else
  {
    // console.log(selectedDocument)
    let newverURL = getAPIURL(REACT_APP_AddVersion);
    try {
      const response = await fetch(`${newverURL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      
        },
        body: JSON.stringify({
          documentId:selectedDocument.documentId,
            versionNumber: documentVersion,
            versionDescription: versionDescription,
            uploadedBy: documentData.uploadedBy,
            uploaderComments: commentInput
          
        })
      });
  
      if (!response.ok) {
        //throw new Error(`HTTP error! Status: ${response.status}`);
        addToast("Error Occurred while uploading Document!", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      else if(response.ok){
      const result = await response.json();
      // console.log("Document created successfully:", result);
      setdocumentObj(result.data);
      await handleUploadSubmit(result.data);
      }
   
      // Open the upload modal if the document is created successfully
      // setShowAddDocModal(false);
      // handleShowUploadModal();
  
    } catch (error) {
      console.error("Failed to create document:", error);
    }
    finally{
      getDocuments();
    }
  }
  }
  else{
    setError("Uploaded File doesn't meet the required specification.Please upload the correct document");
  }

 }



 // Function to submit document details to API
 const handleAddDocumentSubmit = async (e) => {
  e.preventDefault();

  if(!selectedDocument)
  {
  // POST request to API endpoint
  let newdocURL = getAPIURL(REACT_APP_AddDocument);
 
  try {
    const response = await fetch(`${newdocURL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
    
      },
      body: JSON.stringify({
        title: documentData.title,
        description: documentData.description,
        versions: [{
          versionNumber: documentVersion,
          versionDescription: versionDescription,
          uploadedBy: documentData.uploadedBy,
          uploaderComments: documentData.uploaderComments
        }]
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    // console.log("Document created successfully:", result);
    setdocumentObj(result.data);
 
    // Open the upload modal if the document is created successfully
    setShowAddDocModal(false);
    handleShowUploadModal();

  } catch (error) {
    console.error("Failed to create document:", error);
  }
}else
{
  // console.log(selectedDocument)
  alert("only version submit");
  let newverURL = getAPIURL(REACT_APP_AddVersion);
  try {
    const response = await fetch(`${newverURL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
    
      },
      body: JSON.stringify({
        documentId:selectedDocument.documentId,
          versionNumber: documentVersion,
          versionDescription: versionDescription,
          uploadedBy: documentData.uploadedBy,
          uploaderComments: documentData.uploaderComments
        
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    // console.log("Document created successfully:", result);
    setdocumentObj(result.data);
 
    // Open the upload modal if the document is created successfully
    setShowAddDocModal(false);
    handleShowUploadModal();

  } catch (error) {
    console.error("Failed to create document:", error);
  }
}
};



const handleUploadSubmit = async (doc) => {
 // e.preventDefault();
  if (!file) {
    alert("Please select a file to upload.");
    return;
  }
if(!documentObj)
{
  alert("Document version creation failed!");
  return;
}
if(documentObj.Result==="Failure")
{
  alert("Please retry adding the correct values!");
  return;
}
  // Sample document ID and version number to be passed in the query
  const docId = doc.documentId; // Replace with actual document ID
  const docVersion = doc.versionNumber; ; // Replace with actual document version

  // Creating FormData to send the file
  const formData = new FormData();
  formData.append('file', file);

  try {
    // Construct the URL with query parameters
    let uploadURL = getAPIURL(REACT_APP_UploadNewFileVersion);
    const url = `${uploadURL}?docId=${encodeURIComponent(docId)}&docVersion=${encodeURIComponent(docVersion)}`;

    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    // console.log("File uploaded successfully:", result);
    handleUpdateDocLink(doc.versionId,result.data.s3bucketfolderPath,result.data.filekeyName);
    // Reset fields after successful upload
    handleSendMail(doc.versionId);
    setFile(null);
    handleCloseAddDocModal();

  } catch (error) {
    console.error("Failed to upload file:", error);
  }
};

const handleSendMail = async(docVersionID) =>{
  try{
    let SendMailUrl = getAPIURL(REACT_APP_SendApprovalMail);
    //const URL =`${SendMailUrl}?doc=${encodeURIComponent("")}`;
    let Url = window.location.origin;
    let body = {
      applicationUrl : Url,
      docVersionID : +docVersionID
    }
    const response = await fetch(SendMailUrl, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    else{
      console.log("Mail Sent");
    }

    // let req = new Request(`${SendMailUrl}`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(body)
    // });
    // await fetch(req)
    // .then((res) => res.json())
    // .then((res)=>{
    //   console.log("Mail Sent");
    // })
    // .catch((error) => {
    //   console.log("ERROR: ", error);
    //   //navigate("/");
    // });

    // if (!response.ok) {
    //   throw new Error(`Mail Not Sent: ${response.status}`);
    // }
    // else console.log("Mail Sent");
  }
  catch{

  }
}
const onCommentChange = e => {
  setComments(e.target.value);
};
const encryptFilePath = (Path) =>{

  const key = CryptoJS.lib.WordArray.random(256/8);
  const iv = CryptoJS.lib.WordArray.random(128/8);

  const filePath = CryptoJS.AES.encrypt(Path, key, {iv});

  return {
    encryptedFilePath : filePath.ciphertext.toString(CryptoJS.enc.Base64),
    key : key.toString(CryptoJS.enc.Base64),
    iv : iv.toString(CryptoJS.enc.Base64),
  };
};

const handleUpdateDocLink = async (docVersionId,S3bucketLInk,fileName) => {
 
  if (!docVersionId) {
    alert("Something went worng while updating document link");
    return;
  }
if(!S3bucketLInk)
{
  alert("Empty document link!");
  return;
}
  try {
    // Construct the URL with query parameters
    let uploadURL = getAPIURL(REACT_APP_UpdateDocumentLink);
    const {encryptedFilePath, key, iv} = encryptFilePath(S3bucketLInk);

    const response = await fetch(uploadURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
    
      },
      body: JSON.stringify({
        docVersionId: docVersionId,
        documentLInk: encryptedFilePath,
        fileName:fileName,
        passphrase: key,
        iv : iv
       
      }),
    });

    if (!response.ok) {
      addToast("Document failed to upload!", {
        appearance: "error",
        autoDismiss: true,
      });
    }else
    {
      addToast("Document added Successfully and sent for approval!", {
        appearance: "success",
        autoDismiss: true,
      });
    }

    const result = await response.json();
    console.log("document link result", result);
   
    // Reset fields after successful upload
    

  } catch (error) {
    console.error("Failed to upload file:", error);
  }
};

  useEffect(()=>{
    
    const queryParams = new URLSearchParams(location.search);
    const requestId = queryParams.get('requestId');
    if(requestId != null && requestId != undefined && requestId != "")
    {
    GetDocVersionDetailsByRequestId(requestId);
    }
  },[])

  const GetDocVersionDetailsByRequestId =(requestId) =>{

    
    let requestUrl = getAPIURL(REACT_APP_GetDocVersionByRequestId);
    let req =  new Request(`${requestUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      UserName: sessionStorage.getItem("UserName")
    },
    body: JSON.stringify({
      requestId : +requestId
    })
  });
  fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setShowDocumentReviewModal(true);
          GetDocumentVersionDetailsByDocId(result.data.documentId);
          
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //navigate("/");
      });

  }

  // Function to get the latest version of a document
  const getLatestVersion = (versions) => {
    //return versions[0]; // Assuming the versions are in descending order by date

    return versions.reduce((latest, current) => {
      const latestDate = new Date(latest.uploadedDate);
      const currentDate = new Date(current.uploadedDate);

      return currentDate > latestDate ? current : latest;
    });
  };
  const handleInfoIconClick = ()=>{
    setopenText(!openText);
  }
  const columns=[
    {
        title: "Title",
        field: "title",
      },
      {
        title: "Latest Version",
        field: "versionNumber",
        render: (rowData) => (            
          getLatestVersion(rowData.versions)?.versionNumber
        ),
      },
      {
        title: "Uploaded Date",
        render: (rowData) => (            
          readbleDate(getLatestVersion(rowData.versions)?.uploadedDate)
        ),
      },
      // {
      //   title: "Actions",
      //   render: (rowData) => (            
      //       <>
      //         <Button variant="primary" 
      //                   size="sm" onClick={()=> handleShowModal(rowData)}> View/Download </Button>
      //       </>
      //     ),
      // },
      // {
      //     title: "Document Review",
      //     render: (rowData) => (            
      //       <>
      //         <Button variant="primary" 
      //                   size="sm" onClick={()=> handleOpenDocumentReviewModal(rowData)}> View Details </Button>
      //       </>
      //     ),
      // }
  ];

  const columnsDocument=[
    {
        title: "Date",
        field: "date",
        render: (rowData) => (    
          readbleDate(rowData.uploadedDate)
        ), 
      },
      {
        title: "Version Number",
        field: "versionNumber",
      },
      {
        title: "UploadedBy",
        field: "uploadedBy", //readbleDate(
      },
      {
        title: "Uploaded Date",
        field: "uploadedDate",
        render: (rowData) => (   
          readbleDate(rowData.uploadedDate)
        ), 
      },
      {
        title: "Approved By",
        field: "approvedBy"
      },
      {
          title: "Approved On",
          field: "approvedOn",
          render: (rowData) => (    
            readbleDate(rowData.approvedDate)
          ), 
      },
      {
        title: "Approver Comments",
        field: "approverComments",
    },
    // {
    //   title: "Download",
    //   render: (rowData) => (            
    //         <>
    //           <Button variant="primary" 
    //                     size="sm" onClick={()=> downloadFile(rowData.documentId,rowData.versionNumber,rowData.downloadLink,rowData.fileName)}>Download {rowData.versionNumber}</Button>
    //         </>
    //       ),
      
    // }
  ];

  const location = useLocation();
  
  const processingDashboardParam = location.state;
  // let navigate = useNavigate();
  // const { addToast } = useToasts();
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails?.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;
  const tableColumns = [
    {title: "Document Name", field: "fileName"},
    {title: "Document Version No", field: "versionNumber"},
    {title: "Uploaded By", field: "uploadedBy"},
    {title: "Uploaded Date",
     field: "uploadedDate",
     render: (rowData) => (            
      readbleDate(rowData.uploadedDate)
    ),},
    {title: "Version Description", field: "versionDescription"},
    {title: "Comments", field: "uploaderComments"},
    // {title: "Commented By", field: "commentedBy"},
    // {title: "View Document", render: (rowData) => (
    //   <Button
    //   variant='primary'
    //   size='sm'
    //   onClick={() => openApproveRejectModal(rowData)}>
    //     View
    //   </Button>
    // )},
    
  ]

  // const tableData = [{
  //   name: 'Document',
  //   version: '1.0',
  //   uploadedBy: 'Sahil',
  //   uploadedDate: '01.01.1988',
  //   description: 'Test Description',
  //   comments: 'No issues',
  //   commentedBy: 'Mohd',
  //   link: "/Docs/test.pdf",
  // }]

  const [showApproveRejectModal, setShowApproveRejectModal ] = useState(false);
  const [selectedApproveRejectDocument , setSelectedApproveRejectDocument ] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const openApproveRejectModal = (rowData) => {
    setApprovalRequestId(rowData.approvalRequestId);
    ApprovalRequestRef.current = rowData.approvalRequestId;
    setSelectedApproveRejectDocument(rowData);
    setDocumentLinkForDownload(rowData.downloadLink);
    setShowApproveRejectModal(true);
    setIsChecked(false);
  }
  const closeApproveRejectModal = () => {
    setShowApproveRejectModal(false);
    setSelectedApproveRejectDocument(null);
    let queryParams = new URLSearchParams(location.search);
                if(queryParams.has(sessionStorage.getItem("redirectUrl")))
                {
                  queryParams.delete(sessionStorage.getItem("redirectUrl"));
                }
                const newUrl = `${location.pathname}`;
                window.history.replaceState(null,'',newUrl);
  }

  const handleCheckBoxChange = (e) => {
    setIsChecked(e.target.checked);
  }

  const handleApprove = async() => {
        let approvalUrl = getAPIURL(REACT_APP_ApproveRequest);
        let requestId = ApprovalRequestRef.current;
        //let approveRequestUrl =`${approvalUrl}?requestId=${requestId}`;
        let body = {
          requestId : +requestId,
          ApprovedBy : sessionStorage.getItem("UserFullName"),
          ApproverComments: comments,
          action: "approve"
        }
        const response = await fetch(approvalUrl, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
              'Content-Type': 'application/json',
            },
          });
        fetch(response)
        .then((response) => {
            if (response.ok) {
                //setStatus('Approval successful!');
                addToast("Approval Request Approved Successfully", {
                  appearance: "success",
                  autoDismiss: true
                });
                
            } else {
                //setStatus('Failed to approve the request.');
            }
        })
        .catch(() => {
            //setStatus('An error occurred while sending the approval request.');
        });


    closeApproveRejectModal();
  }
  const handleUploadFile = (e)=>{
    //setFile(e.target.files[0]);
    setError("");
    setIsError(false);
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024;
    if(file){
      if(!file.type.includes('text') && !file.type.includes('txt') )
      {
        setError("Only text files are supported");
        setIsError(true);
        return;
      }
      if(file.size > maxSize)
      {
        setError("File size should not exceed 5MB");
        setIsError(true);
        return;
      }
      setFile(e.target.files[0]);
    }


  };

  const handleReject = async() => {
    let approvalUrl = getAPIURL(REACT_APP_ApproveRequest);
    let requestId = ApprovalRequestRef.current;
    //let approveRequestUrl =`${approvalUrl}?requestId=${requestId}`;
    let body = {
      requestId : +requestId,
      ApprovedBy : sessionStorage.getItem("UserFullName"),
      ApproverComments: comments,
      action: "reject"
    }
    const response = await fetch(approvalUrl, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    fetch(response)
    .then((response) => {
        if (response.ok) {
            //setStatus('Approval successful!');
            addToast("Approval Request Rejected Successfully", {
              appearance: "success",
              autoDismiss: true
            });
        } else {
            //setStatus('Failed to approve the request.');
        }
    })
    .catch(() => {
        //setStatus('An error occurred while sending the approval request.');
    });


closeApproveRejectModal();
  }



  return (
    <>
    <Header onpreferenceShow={null} pageName="HELP SECTION" />
    
    <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
    <div className="centerSection">
      <div className="pageBody">
          <div className="dashBoxs">
          <div className="dashBoxs-header " style={{ maxHeight: "45px" ,position:"relative"}}>
          <div className="row align-items-center">
          <div className="col-4">
            <h6>HELP SECTION
              <InfoOutlinedIcon style={{ marginLeft: 4, cursor: "pointer"}}
                  onClick={handleInfoIconClick}/>
            </h6>
            { openText && (
              <div className="p-4 bg-light" style={{position:"absolute",zIndex:1000}}>
                <h2>Digital Library</h2>
                <p>
                  Welcome to the digital library. Here, you can find various documents
                  that might be useful to guide you through different processes. If
                  you have any further questions, feel free to contact our support
                  team.
                </p>
              </div>
            )}
          </div>
          <div className="col-8">
            <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                style={{ marginTop: "-10px" }}>
                  <Button onClick={handleOpenAddDocModal} variant="primary" className="mb-3">
                          Add New Document
                  </Button>
                </div>
            </div>
          </div>
          </div>

          <div className="tableWrap">
            <div className="tableWrap-body">
                      <table
                        className="table table-borderless MatTable"
                        id = "tblDashboard"
                        style={{ zIndex: "1", position: "relative" }}
                      >
              <MaterialTable
                      //title="Table"
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          {
                            icon: () => <UploadFileIcon/>,
                            tooltip: "Add New Version",
                            onClick: (event, rowData) => {handleAddVersionModal(rowData)},
                            position: "row",
                          },
                          (JSON.parse(sessionStorage.getItem("rolesList")).some(obj => obj["roleName"] == "Configuration")) &&
                          {
                            icon: () => <FileDownloadOutlinedIcon />,
                            tooltip: "View/Download",
                            onClick: (event, rowData) => handleShowModal(rowData),
                            position: "row",
                          }
                          ,
                          (JSON.parse(sessionStorage.getItem("rolesList")).some(obj => obj["roleName"] == "Super Admin")) &&
                          {
                            icon: () => <PageviewOutlinedIcon />,
                            tooltip: "View Details",
                            onClick: (event, rowData) => handleOpenDocumentReviewModal(rowData),
                            position: "row",
                          }
                        ]}
                        editable={{
                        }}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: 0, zIndex: 1 
                          },
                          maxBodyHeight: "420px",
                          pageSize: 20,
                          pageSizeOptions: [10, 20, 50, 100],
                          exportButton: false,
                          sorting: true,
                          filtering: false,
                          showEmptyDataSourceMessage: true,
                          emptyRowsWhenPaging: false,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "right",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important",
                          },
                        }}
                        columns={columns}
                        icons={tableIcons}
                        data={documents}
                        components={{
                              Container: props => (
                                <Paper {...props} elevation={0} />
                              ),
                              Toolbar: props => (
                                <div
                                  style={{
                                    backgroundColor: "rgb(206 206 206)",
                                    fontSize: "0.75em"
                                  }}
                                >
                                  <MTableToolbar
                                    {...props}
                                    style={{ fontSize: "5px" }}
                                  />
                                </div>
                              )
                        }}
                      />
      {/* Second Section: Documents Table (Latest Version Only) */}
      
      </table>
      </div>
      </div>
      
      </div>
    <div className="formWrap">
    <div className="container mt-2">
    <Container className="mt-4">
      {/* First Section: Paragraph Content */}
      

           {/* Modal for Document Details */}
        <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedDocument?.title} - Versions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Document Description */}
          {selectedDocument && (
            <div className="mb-4">
              <h5>Description</h5>
              <p>{selectedDocument.description}</p>
            </div>
          )}
          
          {/* Document Versions */}
          <div className="tableWrap">
          <div className="tableWrap-body">
                      <table
                        className="table table-borderless MatTable"
                        id = "tblDashboard"
                        style={{ zIndex: "1", position: "relative" }}
                      >
             <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          {
                            icon: () => <FileDownloadOutlinedIcon />,
                            tooltip: rowData => `Download ${rowData.versionNumber}`,
                            onClick: (event, rowData) => downloadFile(rowData.documentId,rowData.versionNumber,rowData.downloadLink,rowData.fileName),
                            position: "row",
                          }
                        ]}
                        editable={{
                        }}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: 0, zIndex: 1 
                          },
                          maxBodyHeight: "420px",
                          pageSize: 10,
                          pageSizeOptions: [10, 20, 50, 100],
                          exportButton: false,
                          sorting: true,
                          filtering: false,
                          search: false,
                          selection: false,
                          showEmptyDataSourceMessage: true,
                          emptyRowsWhenPaging: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "right",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "justify !Important",
                          },
                        }}
                        columns={columnsDocument}
                        icons={tableIcons}
                        data={selectedDocumentVersion}
                        components={{
                              Container: props => (
                                <Paper {...props} elevation={0} />
                              ),
                              Toolbar: props => (
                                <div
                                  style={{
                                    backgroundColor: "rgb(206 206 206)",
                                    fontSize: "0.75em"
                                  }}
                                >
                                  <MTableToolbar
                                    {...props}
                                    style={{ fontSize: "5px" }}
                                  />
                                </div>
                              )
                        }}
                      />

              </table>
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        {/* <Button variant="primary" onClick={handleAddVersionModal}>
            Add new version
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
      {/* Add Document Modal */}
      <Modal show={showAddDocModal} onHide={handleCloseAddDocModal}>
        <Modal.Header closeButton>
          <Modal.Title> {!IsVersion? <>Add New Document</>:<>Add New Version</>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddandUploadDocumentSubmit}>
          <Form.Group style={{marginBottom: '6px'}}>
              <Form.Label style={{marginBottom: '-5px'}}>Version Number</Form.Label>
              <Form.Control
                type="text"
                name="versionNumber"
                value={documentVersion}
                disabled
                //onChange={handleInputChange}
                //required
              />
            </Form.Group>
            {!IsVersion?
           (
           <> 
            <Form.Group style={{marginBottom: '6px'}}>
              <Form.Label style={{marginBottom: '-5px'}}>Document Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={documentData.title}
                onChange={handleInputChange}
                required
                
              />
            </Form.Group>
            <Form.Group style={{marginBottom: '6px'}}>
              <Form.Label style={{marginBottom: '-5px'}}>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={documentData.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            </>):<></>
}
            
            <Form.Group style={{marginBottom: '6px'}}>
              <Form.Label style={{marginBottom: '-5px'}}>Version Description</Form.Label>
              <Form.Control
                as="textarea"
                name="versionDescription"
                value={versionDescription}
                onChange={handleVersionDescriptionInputChange}
                required
              />
            </Form.Group>
            <Form.Group style={{marginBottom: '6px'}}>
              <Form.Label style={{marginBottom: '-5px'}}>Comments</Form.Label>
              <Form.Control
                type="text"
                name="uploaderComments"
                value={commentInput}
                onChange={handleCommentInputChange}
                required
              />
            </Form.Group>
            {/* <Button variant="primary" type="submit">
              Next
            </Button> */}
          {/* </Form> */}
        {/* </Modal.Body> */}
      {/* </Modal>

      {/* Upload Document Modal */}
      {/* <Modal show={showUploadModal} onHide={handleCloseUploadModal}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Document File</Modal.Title>
        </Modal.Header>
        <Modal.Body> */} 
          {/* <Form onSubmit={handleUploadSubmit}> */}
            <Form.Group style={{marginBottom: '6px'}}>
              <Form.Label>Upload File</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => handleUploadFile(e)}
                required
                accept=".txt, text/plain"
              />
              {error && <p style={{color: 'red'}}>{error}</p>}
            </Form.Group>
            <Button variant="primary" type="submit">
              Upload
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={ShowDocumentReviewModal} onHide={handleCloseDocumentReviewModal} size="lg" centered>
      <Modal.Header closeButton>
          <Modal.Title>Document Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="tableWrap-body">
          <table
            className="table table-borderless MatTable"
            style={{ zIndex: "1", position: "relative" }}
          >
          <ThemeProvider theme={theme}>
              <MaterialTable
                localization={{
                  pagination: true,
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected'
                  },
                  header: {
                    actions: ""
                  }
                }}
                actions={[
                  {
                    icon: () => <PageviewOutlinedIcon />,
                    tooltip: "View Details",
                    onClick: (event, rowData) => openApproveRejectModal(rowData),
                    position: "row",
                  }
                ]}
                options={{
                  search: false,
                  // selection: true,
                  tableLayout: "auto",
                  overflowY: false,
                  showTitle: false,
                  toolbarButtonAlignment: "left",
                  toolbar: false,
                  rowStyle: {
                    fontFamily: '"Yantramanav", sans-serif'
                  },
                  showEmptyDataSourceMessage: true,
                  emptyRowsWhenPaging: false,
                  tableLayout: "auto",
                  headerStyle: {
                    position: "sticky",
                    top: 0, zIndex: 1 
                  },
                  maxBodyHeight: "420px",
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                }}
                
                columns={tableColumns}
                icons={tableIcons}
                data={tableData}
              />
            </ThemeProvider>
          </table>
        </div>
        

        </Modal.Body>
      </Modal>

      <Modal show={showApproveRejectModal} onHide={closeApproveRejectModal} centered style={{width: '100%', height:'450px', border: 'none'}}>
        <Modal.Header>
          <Modal.Title>Approve/Reject</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedApproveRejectDocument && (
            
            <div>
            {/* if(selectedApproveRejectDocument.fileName.includes("txt"))
            {
              // <pre style={{whiteSpace}}></pre>
            }
            else{
              <iframe
              src={selectedApproveRejectDocument.downloadLink}
              title="Document Viewer"
              style={{width: '100%', height:'450px', border: 'none'}}
              >
              </iframe>
                } */}
              <p style={{margin:"5px 0 0 ", fontSize:"12px", color:"#555"}}>Click to Download</p>
              <span 
                style={{ cursor: "pointer", color: "blue", textDecoration: "underline"}}
                onClick={()=>downloadFile(selectedApproveRejectDocument.documentId,selectedApproveRejectDocument.versionNumber,selectedApproveRejectDocument.downloadLink,selectedApproveRejectDocument.fileName)} >
                {selectedApproveRejectDocument.fileName}
              </span>
              
              <div style={{marginTop: '20px'}}>
                  <label>
                    <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckBoxChange} />
                    {' '}
                    I have read the document
                  </label>
                </div>
                <div className = "d-flex" style={{marginTop: '20px' , display:'flex' , alignItems: 'center'}}>
                <textarea class="form-control me-3" rows="5" style={{ flex: "1"}}
                              id="txtComment"
                              placeholder="Type here...."
                              onChange={onCommentChange}
                              value={comments}
                              required
                            ></textarea>
                <div className="d-flex flex-column" style={{ display: 'flex' , gap: '10px'}}>
                  <Button
                  variant="primary" 
                  size="sm"
                  onClick={() => {handleApprove()}}
                  disabled={!isChecked}
                  >
                    Approve
                  </Button>

                  <Button
                  variant="primary" 
                  size="sm"
                  onClick={handleReject}
                  disabled={!isChecked}
                  >
                    Reject
                  </Button>

                <Button 
                  variant="primary" 
                  size="sm"
                  onClick={closeApproveRejectModal}
                  >Back</Button>
                  </div>
                  </div>
            </div>
          )}
        </Modal.Body>
      </Modal>


    </Container>

    </div>
    </div>
    
    </div>
    </div>
    <Footer />
    </>
  );
};

export default Help;

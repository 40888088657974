import React, { useEffect, useState, useContext } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import FormContext from "../../../../contexts/FormContext";
import Multiselect from 'multiselect-react-dropdown';

export default function EditWorkActivityDetailsPage(state) {
  const [textFieldVaules, settextFieldVaules] = useState([]);
  const [listBoxValues , setListBoxValues ] = useState({});
  const [selectedOptions, setselectedOptions] =useState([]);

  let oldElements = state;
  const getTextValue = (e) => {
    settextFieldVaules(e.target.value);
  };
  const handleSelect = (selectedList,fieldName, groupName,isReadOnly)=>{
    setselectedOptions(selectedList);
    setListBoxValues((prevValues) => ({
      ...prevValues,
      [groupName]: {
        ...prevValues[groupName],
        [fieldName]: selectedList
      }
    }));
    let selectedListString = selectedList.map(item=>item.value).join(",");
    handleInputChange(selectedListString,fieldName, groupName,isReadOnly);
  }
  const handleRemove = (selectedList,fieldName, groupName,isReadOnly)=>{
    setselectedOptions(selectedList);
    setListBoxValues((prevValues) => ({
      ...prevValues,
      [groupName]: {
        ...prevValues[groupName],
        [fieldName]: selectedList
      }
    }));
    let selectedListString = selectedList.map(item=>item.value).join(",");
    // if(selectedListString!="")
    // {
    //   handleInputChange(selectedListString,fieldName, groupName,isReadOnly);
    // }
    handleInputChange(selectedListString,fieldName, groupName,isReadOnly);
  }

  const handleInputChange = (event , fieldName, groupName, isReadOnly) => {
    let newValue = "";
    const updatedRegGroup = [...state.state.regGroup];

    const targetGroupIndex = updatedRegGroup.findIndex((group) => group.groupName===groupName);

    if (targetGroupIndex !== -1) {
      const targetGroup = updatedRegGroup[targetGroupIndex];
      const targetFieldIndex = targetGroup.screenFields.findIndex((field) => field.mapToField === fieldName);
      // alert(isReadOnly);
      if(isReadOnly==false)
      {
        if (targetFieldIndex !== -1) {
          if (targetGroup.screenFields[targetFieldIndex].uiControlType.includes("ListBox")) {
            if(!targetGroup.screenFields[0].isMultiValue){ 
              let selectedValues = Array.from(
                event.target.selectedOptions,
                option => option.value
              );
              newValue=selectedValues.join(",");
            }else{
              newValue = event;
            }
            // targetGroup.screenFields[targetFieldIndex].value = newValue;
          }else{
            newValue=event.target.value;
          }
          if (!targetGroup.screenFields[0].isMultiValue) {
          targetGroup.screenFields[targetFieldIndex].value = newValue;
        } else {
          targetGroup.screenFields[targetFieldIndex].value = "";
        }
      }
    }
  }
    settextFieldVaules({ ...textFieldVaules, [groupName]: {...textFieldVaules[groupName], [fieldName]: newValue},})
  };
  const hyperlinkOnClick = (controlid) => {
    let url = document.getElementById(controlid).value;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handlekeypress = (e) =>{
    if(e.key === "ArrowDown" || e.key === "ArrowUp"){
      e.preventDefault();
    }
  }
  const [DateFieldType, setDateFieldType] = useState("date");
  
  const OnDateclick = (e) =>{
    setDateFieldType("text");
  }
  
  const OnMouseOut = (e) =>{
    setDateFieldType("date");
  }
  const handleChange = useContext(FormContext);
  return (
    <>
      <form className="form-inputs">
        {state.state.regGroup.map((data, index) => {
          let groupName = data.groupName;
          return (
            <div className="fieldSet mb-4" key={index}>
              <h4 className="mb-2">{data.groupName}</h4>
              <div className="row">
                {data.screenFields.map((values) => {
                  let bgcolor = values.setColor;
                  if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("String")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>

                          <input
                            className="form-control  form-control-sm"
                            type="text"
                            //name={values.}
                            //pattern="\[A-za-z0–9_]\"
                            required={values.required}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={values.value}
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                            //value={textFieldVaules}
                            id={values.mapToField}
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("Double")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>

                          <input
                            className="form-control  form-control-sm"
                            type="number"
                            pattern="[0-9]+([,\.][0-9]+)?"
                            required={values.required}
                            onKeyDown= {handlekeypress}
                            onKeyUp= {handlekeypress}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={values.value}
                            // value={
                            //   values.value == ""
                            //     ? 0.0
                            //     : parseFloat(values.value).toFixed(2)
                            // }
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                            step="0.25"
                            //value={textFieldVaules}
                            id={values.mapToField}
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("Float")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>

                          <input
                            className="form-control  form-control-sm"
                            type="number"
                            pattern="/^[-+]?[0-9]+\. [0-9]+$/"
                            //name={values.}
                            required={values.required}
                            onKeyDown= {handlekeypress}
                            onKeyUp= {handlekeypress}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={values.value}
                            // value={
                            //   values.value == ""
                            //     ? 0.0
                            //     : parseFloat(values.value).toFixed(2)
                            // }
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                            //value={textFieldVaules}
                            id={values.mapToField}
                            step="0.25"
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (
                    values.uiControlType.includes("TextBox") &&
                    values.dataType.includes("Int")
                  ) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>

                          <input
                            className="form-control  form-control-sm"
                            type="number"
                            pattern="/^[0-9]*$/"
                            //name={values.}
                            required={values.required}
                            onKeyDown= {handlekeypress}
                            onKeyUp= {handlekeypress}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={values.value == "" ? "" : values.value}
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                            //value={textFieldVaules}
                            id={values.mapToField}
                          ></input>
                        </div>
                      </div>
                    );
                  } else if (values.uiControlType.includes("HtmlTextArea")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>

                          <textarea
                            className="form-control  form-control-sm"
                            //name={values.}
                            required={values.required}
                            maxLength={values.maxLen}
                            datatype={values.dataType}
                            value={values.value}
                            readOnly={values.isReadOnly}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  } else if (values.uiControlType.includes("HyperLink")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>

                          <input
                            className="form-control  form-control-sm"
                            type="text"
                            //name={values.}
                            required={values.required}
                            maxLength={values.maxLen}
                            defaultValue={values.value}
                            readOnly={values.isReadOnly}
                            style={{ "background-color": bgcolor }}
                            //value={textFieldVaules}
                            id={values.mapToField}
                          ></input>                         
                        </div>
                      </div>
                    );
                  } else if (
                    // values.uiControlType.includes("EditorControls") &&
                    values.dataType.includes("DateTime")
                  ) {
                    let finalDate = "";
                    if (values.value.trim() != "") {
                      const [month, date, year, time] = values.value
                        .replace(/\s{2,}/g, " ")
                        .trim()
                        .split(" ");
                      if (date == undefined || year == undefined) {
                        finalDate = values.value;
                      } else {
                        let monthNum =
                          new Date(Date.parse(month + " 1, 2012")).getMonth() +
                          1;

                        let monthName =
                          monthNum < 10
                            ? "0" + monthNum.toString()
                            : monthNum.toString();
                        let dateVal =
                          date < 10 ? "0" + date.toString() : date.toString();
                        finalDate = year.concat("-", monthName, "-", dateVal);
                      }
                    }
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>

                          <input
                            type={DateFieldType}
                            className="form-control form-control-xs"
                            name={values.name}
                            value={finalDate}
                            readOnly={values.isReadOnly}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            //onClick={OnDateclick}
                            onDoubleClick={OnDateclick}
                            onMouseOut={OnMouseOut}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                  //  else if (
                  //   values.uiControlType.includes("WebSchedule") &&
                  //   values.dataType.includes("DateTime")
                  // ) {
                  //   let finalDate = "";
                  //   if (values.value.trim() != "") {
                  //     const [month, date, year, time] = values.value
                  //       .replace(/\s{2,}/g, " ")
                  //       .trim()
                  //       .split(" ");
                  //     if (date == undefined || year == undefined) {
                  //       finalDate = values.value;
                  //     } else {
                  //       let monthNum =
                  //         new Date(Date.parse(month + " 1, 2012")).getMonth() +
                  //         1;

                  //       let monthName =
                  //         monthNum < 10
                  //           ? "0" + monthNum.toString()
                  //           : monthNum.toString();
                  //       let dateVal =
                  //         date < 10 ? "0" + date.toString() : date.toString();
                  //       finalDate = year.concat("-", monthName, "-", dateVal);
                  //     }
                  //   }
                  //   return (
                  //     <div className={state.state.fieldClassName}>
                  //       <div className="pb-3">
                  //         <label className="form-label">
                  //           {values.labelText}
                  //         </label>
                  //         {values.required ? (
                  //           <span id="" className="mandatory">
                  //             *
                  //           </span>
                  //         ) : null}

                  //         <input
                  //           type="date"
                  //           className="form-control form-control-sm"
                  //           name={values.name}
                  //           value={finalDate}
                  //           readOnly={values.isReadOnly}
                  //           id={values.mapToField}
                  //           style={{ "background-color": bgcolor }}
                  //           onChange={(event) =>
                  //             handleChange.onchange(
                  //               values.mapToField,
                  //               event,
                  //               oldElements
                  //             )
                  //           }
                  //         />
                  //       </div>
                  //     </div>
                  //   );
                  // }
                  else if (values.uiControlType.includes("DropDownList")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>

                          <select
                            className="form-control form-control-sm"
                            title=""
                            disabled={values.isReadOnly}
                            required={values.required}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            //defaultValue={values.workItemDropdownValues[1]}
                            //value={values.workItemDropdownValues[0]}
                            onChange={(event) =>
                              handleChange.onchange(
                                values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          >
                            <option>{"Select"}</option>
                            {values.workItemDropdownValues.map((reason, i) => (
                              <option
                                selected={values.value === reason}
                                value={reason}
                                key={i}
                              >
                                {reason}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    );
                  } else if (values.uiControlType.includes("ListBox")) {
                    return (
                      <div className={state.state.fieldClassName}>
                        <div className="pb-3">
                        <div style={{ width: "94%",float : "left" }}>
                          <label
                            className="form-label"
                            title={values.labelText}
                          >
                            {values.labelText}
                            {values.required ? (
                              <span id="" className="mandatory">
                                *
                              </span>
                            ) : null}
                          </label>
                        </div>
                          <input
                            className="form-check-input"
                            // onChange={checkHandler}
                            // checked={isChecked}
                            // id="chkInternalUserNotes"
                            value={!values.isSearch}
                            id={"chk"+values.mapToField}
                            hidden={!values.isSearch}
                            type="checkbox"
                            onChange={(event) =>
                              handleChange.onchange(
                                "chk"+values.mapToField,
                                event,
                                oldElements
                              )
                            }
                          />
                          {values.isMultiValue ?
                            <Multiselect id="MultiSelectSubProcessId" showCheckbox="true"
                              style={{ zIndex: 100 }}
                              options={values.workItemDropdownValues.map((reason) =>
                              (
                                {
                                  name: reason,
                                  value: reason
                                }

                              ))}
                              avoidHighlightFirstOption
                              displayValue="name"
                              // ref={multiselectRef}
                              onSelect={(selectedList, selectedItem, event) => handleSelect(selectedList, values.mapToField, groupName,values.isReadOnly)}
                              selectedValues={listBoxValues?.[data.groupName]?.[values.mapToField]}
                              // selectedValues={null}
                              onRemove={(selectedList, selectedItem, event) => handleRemove(selectedList, values.mapToField, groupName,values.isReadOnly)}
                              // onRemove={onRemove}
                              placeholder="Select Options"
                            /> :
                          <select
                            multiple
                            className="form-control form-control-xs"
                            title=""
                            disabled={values.isReadOnly}
                            required={values.required}
                            id={values.mapToField}
                            style={{ "background-color": bgcolor }}
                            //defaultValue={values.workItemDropdownValues[1]}
                            //value={values.workItemDropdownValues[0]}
                            // onChange={
                            //   (event) =>
                            //     handleChange.onchange(
                            //       values.mapToField,
                            //       event,
                            //       oldElements
                            //     )
                            //   /* event => valueChange(event) */
                            // }
                            onChange={(event) => handleInputChange(event, values.mapToField, data.groupName,values.isReadOnly)}
                            // value={textFieldVaules[data.groupName]?.[values.mapToField] || values.value || ""}
                          >
                            {values.workItemDropdownValues.map((reason, i) => (
                              <option
                                selected={
                                  values.value != ""
                                    ? values.value.split(",").includes(reason)
                                    : null
                                }
                                value={reason}
                                key={i}
                              >
                                {reason}
                              </option>
                            ))}
                          </select>}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          );
        })}
      </form>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import { Paper } from "@material-ui/core";
import MaterialTableCore, {
  MTableToolbar as MTableToolbarCore,
} from "@material-table/core";

const ActivityScreenTrackingDetails = (values) => {
  //debugger;
  const { REACT_APP_WORKITEMTRACKINGDETAILS } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const [TrackingDetails, selectTrackingDetails] = useState(null);
  const [Tracking, selectTracking] = useState(false);
  const [noChanges, setNoChanges] = useState(false);
  const [noTrackingDetails, setNoTrackingDetails] = useState(false);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [fieldsData, setFieldsData] = useState([]);

  useEffect(() => {
    GetPreviousScreenData();
  }, []);
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const GetPreviousScreenData = async () => {
    //debugger
    let body = {
      subProcessID: values.state.subprcoess,
      workItemID: values.state.workitemid,
      workflowStateID: values.state.workflowid,
      activityID: values.state.activityid,
      personID: values.state.personid,
      workitemtrackingid: values.state.trackingid == null ? 0 : values.state.trackingid,
    };
    let loginUrl = getAPIURL(REACT_APP_WORKITEMTRACKINGDETAILS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    await fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          //debugger;
          if (result.data.length == 0) {
            setNoChanges(true);
          }
          else if (result.data[0].labelText == 'NA' && result.data[0].oldValue == 'NA' && result.data[0].newValue == 'NA') {
            setNoTrackingDetails(true);
            setNoChanges(false);
          } else {
            setNoChanges(false);
            setNoTrackingDetails(false);
            selectTrackingDetails(result.data);

            const transformedData = result.data.reduce((acc, item) => {
              acc[0] = acc[0] || {};
              acc[0][item.labelText] = item.oldValue === "" ? item.newValue : `${item.oldValue} -> ${item.newValue}`;
              return acc;
            }, []);
            setFieldsData(transformedData);

            const dynamicColumnsData = result.data.map(item => ({
              title: item.labelText,
              field: item.labelText
            }));
            setDynamicColumns(dynamicColumnsData);
          }
          //selectTrackingDetails(result.data);
          //console.log(result.data);
        } else if (result.status === 401) {
          //unauthorized
          // addToast("Session Expired/UnAuthorized. Please LogIn Again", {
          //   appearance: "error",
          //   autoDismiss: true,
          // });
          sessionStorage.clear();
          localStorage.clear();
          //navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        //console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };
  return (
    <div className="m-2">
      {noChanges ? (
        <div className="fieldSet form-label">No workitem field values were changed.</div>
      ) : noTrackingDetails ? (
        <div className="fieldSet form-label">No workitem field value tracking details available.</div>
      ) : TrackingDetails ? (
        // <div className="row">
        //   <div className="col-12">
        //     <form className="form-inputs">
        //       <div className="fieldSet">
        //         <div className="row flex-nowrap overflow-auto">
        //           {TrackingDetails.map((fields, index) => (
        //             <div className="col-auto" key={index}>
        //               <div className="pb-3">
        //                 <label
        //                   className="form-label form-check-label"
        //                   title={fields.labelText}
        //                 >
        //                   {fields.labelText}
        //                 </label>
        //                 <input                          
        //                   className="form-control form-control-sm auto-width"
        //                   type="text"
        //                   value={
        //                     (fields.oldValue ? fields.oldValue : '" "') + " -> " + 
        //                     (fields.newValue ? fields.newValue : '" "')
        //                   }
        //                   title={
        //                     (fields.oldValue ? fields.oldValue : '" "') + " -> " + 
        //                     (fields.newValue ? fields.newValue : '" "')
        //                   }                          
        //                   readOnly={true}
        //                 />
        //               </div>
        //             </div>
        //           ))}
        //         </div>
        //       </div>
        //     </form>
        //   </div>
        // </div>
        <div className="tableWrap">
          <div className="tableWrap-body">
            <table className="table table-borderless MatTable">
              <MaterialTableCore
                options={{
                  maxBodyHeight: "60px",
                  pageSize: 1,
                  search: false,
                  sorting: false,
                  filtering: false,
                  tableLayout: "fixed",
                  columnResizable: "true",
                  tableWidth: "variable",
                  overflowY: false,
                  showTitle: false,
                  pageSizeOptions: [5],
                  padding: "dense",
                  cellStyle: {
                    whiteSpace: "Normal",
                    wordWrap: "break-word",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden"
                  },
                  headerStyle: {
                    position: "sticky"
                  },
                  rowStyle: {
                    fontFamily: '"Yantramanav", sans-serif',
                  },
                }}
                // options={{
                //   headerStyle: {
                //     position: "sticky",
                //     top: "-5px",
                //   },
                //   maxBodyHeight: "50px",
                //   columnResizable: "true",
                //   tableWidth: "variable",
                //   pageSize: 1,
                //   //pageSizeOptions: [5, 10],
                //   exportButton: false,
                //   sorting: false,
                //   filtering: false,
                //   search: false,
                //   selection: false,
                //   //paging: false,
                //   overflowY: false,
                //   overflowX: true,
                //   showTitle: false,
                //   toolbarButtonAlignment: "left",
                //   rowStyle: {
                //     fontFamily: '"Yantramanav", sans-serif',
                //     //                            fontSize: "13px",
                //     // whiteSpace: "nowrap",
                //   },
                // }}

                columns={dynamicColumns}
                icons={tableIcons}
                data={fieldsData}
                components={{
                  Toolbar: props => (
                    <div
                      className="tableStyle"
                    >
                      <MTableToolbarCore
                        {...props}
                        className="MTableToolbarfont"
                      />
                    </div>
                  ),
                  Pagination: () => null,
                }}
              />
            </table>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};
export default ActivityScreenTrackingDetails;

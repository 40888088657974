import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";
import "react-table-filter/lib/styles.css";
import MaterialTable, { MTableToolbar, MTableBody } from "@material-table/core";
import Tooltip from "@material-ui/core/Tooltip";
import tableIcons from "../../../../MaterialTableIcons";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import PageviewRoundedIcon from "@mui/icons-material/PageviewRounded";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import viewDetails from "../../../../resources/images/icons8-view-details-24.png";
import { FcSearch } from "react-icons/fc";
import { FcImageFile } from "react-icons/fc";
import ReactTooltip from "react-tooltip";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import GlobalStyles from "@mui/material/GlobalStyles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Hidden, TablePagination, Typography } from "@material-ui/core";
import NavDataContext from "../../../../contexts/NavDataContext";
import { CsvBuilder } from "filefy";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { ToastProvider, useToasts } from "react-toast-notifications";
import Iframe from "react-iframe";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
//import  DisplayTimeZone from "../../Comman/Timezone";
import { DisplayTimeZone, UploaddateLocalTime } from "../../Comman/Timezone";
import green from "../../../../assets/images/icons/green.png";
import red from "../../../../assets/images/icons/red.png";
import orange from "../../../../assets/images/icons/orange.png";

import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import exportFromJSON from "export-from-json";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {
  DownloadExcel,
  ArrayWithoutExtraColumn
} from "../../Comman/ExcelFileDownload";
//let tableRef = React.createRef();
function Processing() {
  let tableRef = React.useRef();
  //let filterRef = useRef("");
  const { addToast } = useToasts();
  const { REACT_APP_FETCHDASHBAORDDATAFOREXPORT } = process.env;
  const { REACT_APP_FETCH_DASHBOARDDATAGRID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_DASHBOARDCONFIGURATIONS } = process.env;
  const { REACT_APP_FETCH_SUBPROCESSACTIVITYNAMES } = process.env;
  const { REACT_APP_GET_EMPLOYEESFORSUBPROCESSACTIVITY } = process.env;
  const { REACT_APP_FETCH_EMPLOYEESFORSUBPROCESS } = process.env;
  const { REACT_APP_GET_STATUSESFORCURRENTWORKFLOWSTATE } = process.env;
  const { REACT_APP_GET_GETSTATUSREASONSBYSUBPROCESSID } = process.env;
  const { REACT_APP_GET_WORKITEMDETAILS } = process.env;
  const { REACT_APP_CREATENEWWORKITEM } = process.env;
  const { REACT_APP_STATUSCHANGEANDASSIGNWORKITEM } = process.env;
  const { REACT_APP_REASSIGNWORKITEM } = process.env;
  const { REACT_APP_SUBPROCESSSUBMIT } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const { REACT_APP_GETNEXTITEM } = process.env;
  const { REACT_APP_UILOGEXTENSION } = process.env;
  const { REACT_APP_FETCH_REASSIGNEMPLOYEESFORSUBPROCESS } = process.env;
  const { REACT_APP_SAVEWORKITEMTYPEFORTHEWORKITEM } = process.env;
  const { REACT_APP_GETSUBPROCESSWORKFLOWCONFIGBYSUBPROCESSID } = process.env;
  const currentPage = window.location.href;
  const btnref = useRef();
  const IsChangeStatus = useRef(true);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20
  });
  const [rowCount, setRowCount] = useState(0);
  const [isExportFirstTime, setisExportFirstTime] = useState(false);
  let interval = null;
  const navigate = useNavigate();

  const asignmentdetailsColumns = [
    {
      title: "Description",
      field: "description"
    },
    { title: "Assigned By", field: "assignedBy" },
    {
      title: "Assignment Date",
      field: "assignmentDate",
      customSort: (a, b) =>
        Date.parse(a.assignmentDate) - Date.parse(b.assignmentDate)
    }
  ];

  const listWorkItemHistoryColumns = [
    {
      title: "From State",
      field: "fromState"
    },
    { title: "Status Name", field: "statusName" },
    { title: "To State", field: "toState" },
    { title: "Status By", field: "statusedBy" },
    { title: "Status DateTime", field: "statusDateTime" },
    { title: "Status Comment", field: "statusComment" },
    { title: "Status Reason", field: "statusReasons" },
    { title: "Time Zone", field: "timeZone" }
  ];
  const [dashboardGridDataModels, setDashboardGridDataModels] = useState([]);

  // FILTER TOGGLE FUNCTION
  const [isLoading, set_IsLoading] = useState(false);
  const [isLoadingDashboard, set_isLoadingDashboard] = useState(false);
  const [filterModal, filterShow] = useState(false);
  const [GridfilterValue, setGridfilterValue] = useState("");
  const [Gridstate, setGridstate] = useState({});
  const [GridColLen, setGridColLen] = useState(0);
  const [CheckProcessId, setCheckProcessId] = useState("");
  const [CheckActivityId, setCheckActivityId] = useState("");
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [showButtonExcel, setshowButtonExcel] = useState(false);
  const [showButtoncsv, setshowButtoncsv] = useState(false);
  const [showButton, setshowButton] = useState(false);
  const [Button, setButton] = useState(true);
  const [ImageModeOn, setImageModeOn] = useState(false);
  const selectedItemref = useRef("");
  const [typeConfigs, settypeConfigs] = useState([]);
  const [ddlWorkItemTypeValue, setddlWorkItemTypeValue] = useState("");
  const [WorkitemtypeButton, setWorkitemtypeButton] = useState(true);
  let columnUtilization = [];

  const handleButtonClick = () => {
    setshowButton(true);
    setshowButtonExcel(true);
    setshowButtoncsv(true);
    setButton(false);
  };

  const options = [
    { id: 1, title: "Export as Excel" },
    { id: 2, title: "Export as csv" }
  ];

  window.addEventListener("click", e => {
    if (e.target.textContent !== "Export") {
      setshowButton(false);
      setButton(true);
    }
  });

  const filterClose = () => filterShow(false);
  //NOTIFY MODAL FUNCTION
  const [filterStyle, setfilterStyle] = useState(
    "btn btn-sm btn-outline-primary ms-2"
  );
  //"btn btn-sm ms-2 btn-primary active"

  const modalShow = () => {
    filterShow(true);
    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    let sortColumn = sessionStorage.getItem(
      workflowactivityName + "_sortColumn"
    );

    setisDefaultValue(true);
    setisDateField(false);
  };
  const ApplyFilter = advSearchDetails => {
    
    if (
      (advSearchDetails.length != 0 &&
        advSearchDetails.length != undefined &&
        advSearchDetails.length != null) ||
      document.getElementById("ddlPrimaryFilter").value != "Select" ||
      document.getElementById("ddlSecondaryFilter").value != "Select"
    ) {
      setfilterStyle("btn btn-sm btn-primary ms-2");
      let sortColumn = "";
      let sortColumn_Value = "";
      let workflowactivityName = sessionStorage.getItem("workflowactivityName");
      if (document.getElementById("ddlPrimaryFilter").value != "Select") {
        sortColumn = document
          .getElementById("ddlPrimaryFilter")
          .value.split("/")[0];
        sessionStorage.setItem(
          workflowactivityName + "_sortColumnPrimary",
          sortColumn
        );
        sortColumn_Value = sortColumn + " " + checkedValue;

        sessionStorage.setItem(
          "OrderByMaptoFeildName",
          document.getElementById("ddlPrimaryFilter").value.split("/")[1]
        );

        sessionStorage.setItem("SortingOrder", checkedValue);
      }

      if (document.getElementById("ddlSecondaryFilter").value != "Select") {
        sortColumn = document
          .getElementById("ddlSecondaryFilter")
          .value.split("/")[0];
        sessionStorage.setItem(
          "OrderByMaptoFeildNameSecondary",
          document.getElementById("ddlSecondaryFilter").value.split("/")[1]
        );
        sessionStorage.setItem(
          workflowactivityName + "_sortColumnSecondary",
          sortColumn
        );
        if (sortColumn_Value == "")
          sortColumn_Value = sortColumn + " " + checkedValue;
        else sortColumn_Value += "," + sortColumn + " " + checkedValue;
      }

      sessionStorage.setItem(
        workflowactivityName + "_sortColumn",
        sortColumn_Value
      );

      setSortColumn(sortColumn_Value);
      //test(advSearchMapToFieldDetails);

      sessionStorage.setItem(
        "" + workflowactivityName + "_mapToCondition",
        JSON.stringify(advSearchMapToFieldDetails)
      );
      sessionStorage.setItem(
        "" + workflowactivityName + "_condition",
        JSON.stringify(advSearchDetails)
      );
      //setfilterStyle("btn btn-sm btn-primary ms-2")
      setIsClearFilter(false);
      //setIsClearFilter(true);
      tableRef.current.onQueryChange();
      //FetchDashboardData(sessionStorage.getItem("EmployeeVersionID"), 0);
      //advSearchMapToFieldDetails
    } else {
      alert("Please select filter condition.");
      return false;
    }
  };

  const [notifyModal, setShow] = useState(false);
  const [viewWorkitemModal, setviewWorkitemModal] = useState(false);
  const [viewDocumentModal, setviewDocumentModal] = useState(false);
  const [NewBlankWorkItem, setNewBlankWorkItem] = useState(true);
  const [allowStatusChange, setAllowStatusChange] = useState(false);
  const [columns, setcolumns] = useState([]);
  const [Dropdown, setDropdown] = useState(0);
  const [allowAssignment, setallowAssignment] = useState(false);
  const [isUplodDateTime, setisUplodDateTime] = useState(false);
  const [allowAdhocAdditionOfItems, setAllowAdhocAdditionOfItems] = useState(
    false
  );
  const [
    allowAdhocAdditionOfItemsForUpload,
    setAllowAdhocAdditionOfItemsForUpload
  ] = useState(false);
  const href = "";
  const [imageId, setimageId] = useState("");
  const [imageKey, setimageKey] = useState("");
  const [imageDocId, setimageDocId] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [imageUrlTemplate, setimageUrlTemplate] = useState("");
  const imageUrlTemplateRef = useRef("");

  const [uplodadedDateTimeFrom, setuplodadedDateTimeFrom] = useState(
    moment(new Date())
      .subtract(1, "month")
      .format("MM/DD/YYYY")
  );
  const [uplodadedDateTimeTo, setuplodadedDateTimeTo] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );
  const [showLastActivityDetail, setShowLastActivityDetail] = useState(false); // To show last activity details on grid
  const [allowEmailNotification, setAllowEmailNotification] = useState(false);
  const [isAllowSubProcessChange, setAllowSubProcessChange] = useState(false);
  const [allowBulkUpdateBulk, setAllowBulkUpdateBulk] = useState(false);
  const [workAllocationMode, setworkAllocationMode] = useState(false);
  const [IsAssignedToRequired, setIsAssignedToRequired] = useState(false);
  const [workItemAllocationMode, setWorkItemAllocationMode] = useState("");
  const [selectedEmployee, setselectedEmployee] = useState("");
  const [statusChange, setstatusChange] = useState("");
  const [selectedActivityName, setselectedActivityName] = useState("");
  const [statusAssignedTo, setstatusAssignedTo] = useState("");
  const [statusReason, setstatusReason] = useState([]);
  const [statusComment, setstatusComment] = useState("");
  const [isClientActivity, setIsClientActivity] = useState(false);
  const [isSuperUser, setisSuperUser] = useState(false);
  const [isReason, setReason] = useState(false);
  const [assignTo, setAssignTo] = useState(false);
  const [employeeVersionID, setemployeeVersionID] = useState("");
  const [filterValue, setfilterValue] = useState(0);
  const [workitemMode, setworkitemMode] = useState("0");
  const [workItemStatus, setworkItemStatus] = useState([]);
  const [subProcessActivityName, setsubProcessActivityName] = useState([]);
  const [dashboardorderings, setdashboardorderings] = useState([]);
  const [employeesToAssign, setemployeesToAssign] = useState([]);
  const [newBlankItemDetails, setnewBlankItemDetails] = useState("");
  const [
    employeesForSubprocessActivity,
    setemployeesForSubprocessActivity
  ] = useState([]);
  const [statusReasonForSubprocess, setstatusReasonForSubprocess] = useState(
    []
  );
  const [ShowDashBoardGrid, setShowDashBoardGrid] = useState(false);
  const [IsSuperAdmin, setIsSuperAdmin] = useState(false);
  const [workitemHistory, setworkitemHistory] = useState([]);
  const [workitemDetails, setworkitemDetails] = useState([]);
  const [selectedActivityId, setselectedActivityId] = useState("");
  const [selectedWorkflowStateId, setselectedWorkflowStateId] = useState("");
  const [selectedCheckboxDetails, setselectedCheckboxDetails] = useState([]);
  const [activityName, setactivityName] = useState("");
  const [workflowStateID, setworkflowStateID] = useState("");
  const [pageChange, setpageChange] = useState("");
  const [isComment, setisComment] = useState(false);
  const [isReasonshow, setisReasonshow] = useState(false);
  const [timerStart, settimerStart] = useState("");
  const [btnShowBreakPanelDisabled, setbtnShowBreakPanelDisabled] = useState(
    false
  );
  const [btnShowBreakPanelColor, setbtnShowBreakPanelColor] = useState("Gray");
  const [ddlActivityEnabled, setddlActivityEnabled] = useState(false);
  const [objdataview, setobjdataview] = useState([]);
  const [ddlActivitySelectedValue, setddlActivitySelectedValue] = useState(0);
  const [selectedWorkitemValue, setselectedWorkitemValue] = useState(0);
  const [txtBreakCommentsEnabled, settxtBreakCommentsEnabled] = useState(false);
  const [timerValue, settimerValue] = useState(0);
  const [hdnSystemBreakValue, sethdnSystemBreakValue] = useState("");
  const [imgActivityToolTip, setimgActivityToolTip] = useState("");
  const [tSaveTimerEnabled, settSaveTimerEnabled] = useState(false);
  const [firstdashboardfetch, setfirstdashboardfetch] = useState(true);
  const [breakStartTime, setbreakStartTime] = useState("");
  const [btnStartWorkTimerDisabled, setbtnStartWorkTimerDisabled] = useState(
    false
  );
  const [employeeTimeUtilization, setemployeeTimeUtilization] = useState([]);
  const [IsClearFilter, setIsClearFilter] = useState(false);
  const notifyClose = () => setShow(false);
  const notifyShow = () => setShow(true);
  const viewWorkitemClose = () => {
    setworkitemHistory([]);
    setworkitemDetails([]);
    setviewWorkitemModal(false);
  };
  //added by Santosh
  const [auditTrail, setbtnAuditTrailVisible] = useState(false);
  //added by Santosh
  const [userNotes, setbtnUserNotesVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  //added by Santosh

  const checkHandler = () => {
    setIsChecked(!isChecked);
  };
  //
  const AutoImageModeRef = useRef(false);
  const AutoImageMode = "";
  //Added by Pradip Rai
  const { REACT_APP_Image_URL } = process.env;
  const { REACT_APP_Image_URL_HUBBELL } = process.env;
  const { REACT_APP_HUBBELL_CLIENTID } = process.env;
  const { REACT_APP_C520_PROGRESS_CLIENTID } = process.env;
  const { REACT_APP_Image_URL_GEC } = process.env;
  const { REACT_APP_GEC_CLIENTID } = process.env;
  //Ended by Pradip Rai
  const viewDoucmentClose = () => {
    setviewDocumentModal(false);
  };
  const location = useLocation();
  //let row=sessionStorage.getItem("row")
  //let assignedWorkItems=sessionStorage.getItem("selectedAssignWorkitem")
  const processingDashboardParam = location.state;
  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;
  const selectedWorkItemDetails =
    processingDashboardParam.selectedWorkItemDetails;
  let currentAcitvity = "";
  if (typeof processingDashboardParam.row.activityName !== "undefined") {
    currentAcitvity = processingDashboardParam.row.activityName;
  } else {
    currentAcitvity = processingDashboardParam.currentAcitvity;
  }
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const activityID = processingDashboardParam.nav[0].workflowStateID;
  let count = 0;
  // SETTING MODAL FUNCTION
  const [settingModal, settingShow] = useState(false);

  const moveActionsToLeft = rows => {
    alert("You selected " + rows.length + " rows");
  };
  const settingClose = () => {
    settingShow(false);
    setstatusComment("");
    setAssignTo(false);
    setReason(false);
    setisComment(false);
    setIsAssignedToRequired(false);
    //setReason(false);
    //setAssignTo(false);
  };
  const assignToEmployee = data => {
    settingShow(true);
    setselectedCheckboxDetails(data);
  };
  const employeeAssignedForStatusChange = e => {
    setstatusAssignedTo(e.target.value);
  };
  const reasonForStatusChange = e => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    setstatusReason(value);
  };
  const commentForStatusChange = e => {
    setstatusComment(e.target.value);
  };
  const statusChangeSubmit = e => {
    e.preventDefault();

    const ChangeStausDropdownRefvalue = ChangeStausDropdownRef.current.value;
    if (ChangeStausDropdownRefvalue === "0") {
      addToast("Please select the change status.", {
        appearance: "error",
        autoDismiss: true
      });
      return false;
    }

    let isSubmit = true;
    if (isReasonshow && statusReason == "") {
      addToast("Status reason is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true
      });
      isSubmit = false;
    }

    if (workAllocationMode === "Manual" && statusAssignedTo === "") {
      addToast("Please select a relevant value from the Assigned To field.", {
        appearance: "error",
        autoDismiss: true
      });
      isSubmit = false;
    }
    if (isComment && statusComment == "") {
      addToast("Status Comment is mandatory for this workflow.", {
        appearance: "error",
        autoDismiss: true
      });
      isSubmit = false;
    }

    

    let body = {      
      subProcessId: processingDashboardParam.selectedSubprocess,
      CurrentState: sessionStorage.getItem("workflowstateID"),
      WorkItemStatusID: statusChange,
    };
    let loginUrl = getAPIURL(REACT_APP_GETSUBPROCESSWORKFLOWCONFIGBYSUBPROCESSID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          
          if(result.data[0].showMessageOnStatusChange === "True" && result.data[0].messageText != null){
              const confirmBox = window.confirm(
                result.data[0].messageText
              );
              if (confirmBox === true) {
                IsChangeStatus.current=true;
              }
              else{
                IsChangeStatus.current=false;
              }
              
          }
           
          if(isSubmit && IsChangeStatus.current === true){

            setstatusComment("");

            let body = {
              workItemIDs: selectedCheckboxDetails
                .map(e => e["WorkItemID"])
                .join(","),
              subProcessID: processingDashboardParam.selectedSubprocess,
              workflowStateID: +sessionStorage.getItem("workflowstateID"),
              statusID: +statusChange,
              employeeID: +sessionStorage.getItem("PersonID"),
              statusComent: statusComment,
              Internal: isChecked, //added by Santosh
              assignmentEmployeeVersionID: statusAssignedTo,
              selectedActivity: selectedActivityName,
              statusReasons: statusReason,
              isForcedStateChange: true
            };
            let loginUrl = getAPIURL(REACT_APP_STATUSCHANGEANDASSIGNWORKITEM);
            let req = new Request(`${loginUrl}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
              },
              body: JSON.stringify(body)
            });
            fetch(req)
              .then(res => res.json())
              .then(result => {
                if (result.status === 200) {
                  settingClose();
                  tableRef.current.onQueryChange();
                  //FetchDashboardData(sessionStorage.getItem("EmployeeVersionID"),filterValue);
                } else if (result.status === 401) {
                  addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                    appearance: "error",
                    autoDismiss: true
                  });
                  sessionStorage.clear();
                  sessionStorage.clear();
                  navigate("/");
                } else {
                  addToast("Something went wrong!", {
                    appearance: "error",
                    autoDismiss: true
                  });
                  //settingClose()
                }
              })
              .catch(error => {
                console.log("ERROR: ", error);
                if (error.name == "TypeError" && error.message == "Failed to fetch") {
                  //window.location.href = "/Home";
                  BodydetailsinLog(error.toString() + " statusChangeSubmit ", body);
                  //navigate("/ErrorPage");
                }
              });
          } else {
            // setisReasonshow(false)
            // setisComment(false)
            //settingClose();
          }  

          setIsChecked(false); //added by Santosh       
         
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Something went wrong!", {
            appearance: "error",
            autoDismiss: true
          });
          //settingClose()
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          BodydetailsinLog(error.toString() + " statusChangeSubmit ", body);
          //navigate("/ErrorPage");
        }
      });    
    
  };

  const BodydetailsinLog = (error, body) => {
    let Bodydetails = "";
    
    for (let i = 0; i < Object.keys(body).length; i++) {
      if (
        Object.values(body)[i] != null &&
        typeof Object.values(body)[i] == "object"
      ) {
        Bodydetails = Bodydetails + ", \n" + Object.keys(body)[i] + ": {";
        for (let j = 0; j < Object.keys(Object.values(body)[i]).length; j++) {
          if (j != 0) {
            Bodydetails = Bodydetails + ",";
          }
          if (
            Object.values(Object.values(body)[i])[j] != null &&
            typeof Object.values(Object.values(body)[i])[j] == "object"
          ) {
            Bodydetails =
              Bodydetails +
              " " +
              Object.keys(Object.values(body)[i])[j] +
              ": {";
            for (
              let a = 0;
              a < Object.keys(Object.values(Object.values(body)[i])[j]).length;
              a++
            ) {
              if (a != 0) {
                Bodydetails = Bodydetails + ",";
              }
              Bodydetails =
                Bodydetails +
                " " +
                Object.keys(Object.values(Object.values(body)[i])[j])[a] +
                ": " +
                Object.values(Object.values(Object.values(body)[i])[j])[a];
            }
            Bodydetails = Bodydetails + " } ";
          } else {
            Bodydetails =
              Bodydetails +
              " " +
              Object.keys(Object.values(body)[i])[j] +
              ": " +
              Object.values(Object.values(body)[i])[j];
          }
        }
        Bodydetails = Bodydetails + " } ";
      } else {
        Bodydetails =
          Bodydetails +
          ", \n" +
          Object.keys(body)[i] +
          ": " +
          Object.values(body)[i];

      }
    }
    CorsLoggingError(error.toString() + " " + Bodydetails.toString());
  };
  const CorsLoggingError = error => {
    let logUrl = getAPIURL(REACT_APP_UILOGEXTENSION);
    let body = {
      personID: +sessionStorage.getItem("PersonID"),
      lanID: sessionStorage.getItem("UserName"),
      message: "Cors Error: " + error,
      pageName: currentPage.split("/")[currentPage.split("/").length - 1],
      logType: true
    };
    let req = new Request(`${logUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
        } else {
          // addToast("Something went wrong", {
          //   appearance: "error",
          //   autoDismiss: true,
          // });
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        // addToast("Something went wrong", {
        //   appearance: "error",
        //   autoDismiss: true,
        // });
        //navigate("/Home");
      });
  };
  const statusForStatusChange = e => {
    setisComment(false);
    setisReasonshow(false);
    setIsAssignedToRequired(false);
    let statusselected = e.target.value.split("|")[0];
    let StatusCommentMandatory = 0;
    let StatusReasonMandatory = 0;

    if (e.target.value !== "Select") {
      let activityselected = e.target.value
        .split("(")[1]
        .replace(")", "")
        .trim();
      setselectedActivityName(activityselected);
      getEmployeesForSubprocessActivity(activityselected);
      getstatusReasonForSubprocess(statusselected);
    } else {
      // setReason(false);
      // setAssignTo(false);
    }
    dashboardorderings[0].value.split(";").forEach(val => {
      if (val.split("~")[0].trim()==statusselected) {
        StatusCommentMandatory = val.split("~")[1];
      }
    });
    dashboardorderings[1].value.split(";").forEach(val => {
      if (val.split("~")[0].trim()==statusselected) {
        StatusReasonMandatory = val.split("~")[1];
      }
    });
    if (StatusCommentMandatory === "1") {
      setisComment(true);
    }
    if (StatusReasonMandatory === "1") {
      setisReasonshow(true);
    }
    setstatusChange(statusselected);
  };
  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  // const NavElements = useMemo(() => {
  //   return (
  //     <>
  //       <Header />
  //       <ApplicationUserContext.Provider
  //         value={processingDashboardParam.userRoles}
  //       >
  //         <DashboardContext.Provider value={processingDashboardParam.nav}>
  //           <NavDataContext.Provider value={navData}>
  //             <Nav />
  //           </NavDataContext.Provider>
  //         </DashboardContext.Provider>
  //       </ApplicationUserContext.Provider>
  //     </>
  //   );
  // }, [navData]);
  const updateColumns = useCallback(

    keys => {
      //debugger
      setCheckProcessId(sessionStorage.getItem("ProcessId"));
      setCheckActivityId(sessionStorage.getItem("PageId"));
      // console.log(sessionStorage.getItem("EmployeeVersionID"));
      const workflowstateID = sessionStorage.getItem("workflowstateID");
      const activityId = sessionStorage.getItem("PageId");

      let col = [];
      // console.log(keys)
      keys.forEach(item => {
        //console.log(item)
          if (item === "AgeDays")
          {
          const redTootltip = "Age > "
          const greenTooltip = "Age <= "
          const orangeTooltip = "Age > "
          const orangeTooltip1 = " and Age <= "
          const Days = " day(s)"
          col.push({
            title: "!",
            field: item,
            render: (rowData) =>
              rowData["AgeDays"] == "green" ?
                <img src={green} className="avatar-img" alt="Image_Description" title={greenTooltip + rowData.AmberDays + Days}/>:
              rowData["AgeDays"] == "orange"?
                <img src={orange} className="avatar-img" alt="Image_Description" title={orangeTooltip+rowData.AmberDays+ Days +orangeTooltip1+rowData.RedDays + Days}/>:
                  rowData["AgeDays"] == "red"?
                 <img src={red} className="avatar-img" alt="Image_Description" title={redTootltip + rowData.RedDays +Days}/>:null,
            editable: false
          });
        }

        if (item === "IsSLA") {
          col.push({
            title: "!",
            field: item,
            render: (rowData) =>
              rowData["IsSLA"] == "green" ?
                <img src={green} className="avatar-img" alt="Image_Description" /> :
                rowData["IsSLA"] == "orange" ?
                  <img src={orange} className="avatar-img" alt="Image_Description" /> :
                  rowData["IsSLA"] == "red" ?
                    <img src={red} className="avatar-img" alt="Image_Description" /> : null,
            editable: false

          });
        }
        else if (
          item === "AgeDays" ||
          item === "RedDays" ||
          item === "AmberDays"
        ) {
          //hide the  columns
        }

        else if (item === "WorkItemID") {
          col.push({
            title: item,
            field: item,
            width: "100px",
            // cellStyle: (e, rowData) => {
            //   return { backgroundColor: rowData.BGColor };
            // },
            render: row => (
              <>
                <span
                  style={{ backgroundColor: row.BGColor }}
                  class="dot"
                  title="Why this color? Click to see."
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasColor"
                  aria-controls="offcanvasColor"
                ></span>
                <Link
                  to="/ActivityScreen"
                  state={{
                    row,
                    selectedSubprocess,
                    nav,
                    userRoles,
                    employeeVersionID: sessionStorage.getItem(
                      "EmployeeVersionID"
                    ),
                    workflowstateID,
                    activityId,
                    assignedWorkItemDetails: navData,
                    workItemID: row[item],
                    currentAcitvity: currentAcitvity,
                    AutoImageMode: sessionStorage.getItem("ImageModeValue")
                  }}
                >
                  {row[item]}
                </Link>
              </>
            ),
            editable: false
            //filtering: true,
          });
        } else if (
          item === "TotalCount" ||
          item === "PriorityState" ||
          item === "BGColor"
        ) {
          // hide this columns
        } else if (
          item === "OnBaseField" ||
          item === "IsWIP" ||
          item === "CWSOnBaseDocID"

        ) {
          // hide this columns
        } else if (
          item.toLowerCase().includes("amount") ||
          item.toLowerCase().includes("number")
        ) {
          col.push({
            title: item,
            field: item,
            width: "100px",
            type: Number
            // render: rowData => {
            //   return <Tooltip className="grid-tooltip" title={rowData[item]}><span>{rowData[item]}</span></Tooltip>
            // }
            // cellStyle: (e, rowData) => {
            //   return { backgroundColor: rowData.BGColor };
            // },
          });
        } else {
          col.push({
            title: item,
            field: item,
            width: "100px"
            // render: rowData => {
            //   return <Tooltip className="grid-tooltip" title={rowData[item]}><span>{rowData[item]}</span></Tooltip>
            // }
            // cellStyle: (e, rowData) => {
            //   return { backgroundColor: rowData.BGColor };
            // },
          });
        }
      });
      //col.pop();
      setcolumns(col);
    },
    [GridColLen]
  );
  const exportAllSelectedRows = data => {
    setselectedCheckboxDetails(data);
    new CsvBuilder("DashboardData.csv")
      .setColumns(columns.map(col => col.title))
      // .addRows(data.map((row) => columns.map((col) => " \t"+ row[col.field])))
      .addRows(data.map(row => columns.map(col => '="' + row[col.field] + '"')))
      //.addRows(data.map((row) => columns.map((col) => row[col.field])))
      .exportFile();
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#008000"
      },
      secondary: {
        main: "#FB4E0B"
      }
    }
  });
  // const getStatusReasons=(statusId)=>{
  //   let body = {
  //     subProcessID: processingDashboardParam.selectedSubprocess,
  //     activityID:
  //       processingDashboardParam.row.length > 0 ||
  //       processingDashboardParam.row.workflowStateID !== undefined
  //         ? processingDashboardParam.row.workflowStateID
  //         : workflowStateID,
  //     intNextStateID:1,
  //     employeeVersionID: employeeVersionID,
  //     personID: sessionStorage.getItem("PersonID"),
  //   };
  //   let loginUrl = getAPIURL(REACT_APP_CREATENEWWORKITEM);
  //   let req = new Request(`${loginUrl}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(body),
  //   });
  //   fetch(req)
  //     .then((res) => res.json())
  //     .then((result) => {

  //       if (result.status === 200) {
  //         navigate("/WorkActivityScreen",{state:{row:result.data,nav:nav}})
  //         //setnewBlankItemDetails(result.data.newWorkItem);
  //       }
  //       //addNewBlankItem()
  //     });
  // }
  const viewWorkitemShow = item => {
    setviewWorkitemModal(true);
    getSelectedWorkItem(item.WorkItemID);
  };
  const documnetShow = row => {
    if (
      row.CWSOnBaseDocID != undefined &&
      row.CWSOnBaseDocID != "" &&
      row.CWSOnBaseDocID != 0
    ) {
      //setviewDocumentModal(true);
      // console.log(row.CWSOnBaseDocID);
      // setimageUrl(
      //   //imageUrlTemplate.replace("@onBaseDocumentID", row.CWSOnBaseDocID)
      //   window.open(
      //     imageUrlTemplate.replace("@onBaseDocumentID", row.CWSOnBaseDocID),
      //     "_blank",
      //     "fullscreen=yes"
      //   )
      // );

      setimageUrl(
        //imageUrlTemplate.replace("@onBaseDocumentID", row.CWSOnBaseDocID)
        window.open(
          imageUrlTemplateRef.current.replace("@onBaseDocumentID", row.CWSOnBaseDocID),
          "_blank",
          "fullscreen=yes"
        )
      );
    } //setimageId(id)
    //setimageKey(key)
    //setimageDocId(docId)
  };
  const addNewBlankItem = () => {
    const workflowstateID = sessionStorage.getItem("workflowstateID");
    navigate("/ActivityScreen", {
      state: {
        selectedSubprocess,
        nav,
        userRoles,
        employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
        workflowstateID,
        workItemID: newBlankItemDetails
      }
    });
    //return <Navigate to="/WorkActivityScreen" state={id} />
  };
  const createNewBlankItemDetails = () => {
    if (btnref.current) {
      btnref.current.setAttribute("disabled", "disabled");
    }

    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      activityID: sessionStorage.getItem("PageId"),
      intNextStateID: sessionStorage.getItem("workflowstateID"), //1,
      employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
      personID: sessionStorage.getItem("PersonID")
    };
    let loginUrl = getAPIURL(REACT_APP_CREATENEWWORKITEM);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          let row = {
            activityName: sessionStorage.getItem("workflowactivityName"),
            workflowStateID: sessionStorage.getItem("workflowstateID")
          };
          let assignedWorkItemDetails = navData;
          const workflowstateID = sessionStorage.getItem("workflowstateID");
          const activityId = sessionStorage.getItem("PageId");
          navigate("/ActivityScreen", {
            state: {
              row,
              selectedSubprocess,
              nav,
              userRoles,
              employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
              workflowstateID,
              activityId,
              assignedWorkItemDetails: assignedWorkItemDetails,
              workItemID: result.data.newWorkItem,
              Iscanceled: 1
            }
          });

          //setnewBlankItemDetails(result.data.newWorkItem);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }

        //addNewBlankItem()
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(
            error.toString() + " createNewBlankItemDetails ",
            body
          );
          //navigate("/ErrorPage");
          //window.location.href = "/Home";
        }
      });
  };
  const GetNextItemDetails = () => {
    let body = {
      activityID: +sessionStorage.getItem("PageId"),
      employeeId: +sessionStorage.getItem("PersonID"),
      workflowStateID: +sessionStorage.getItem("workflowstateID"),
      subProcessID: processingDashboardParam.selectedSubprocess,
      workItemID: 0,
      dashBoardFilter: "",
      sortedFieldList: "",
      isSuperUser: false,
      displayCriteria: 0,
      mode: "",
      fromDate: "",
      toDate: ""
    };
    let loginUrl = getAPIURL(REACT_APP_GETNEXTITEM);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          let row = {
            activityName: sessionStorage.getItem("workflowactivityName"),
            workflowStateID: sessionStorage.getItem("workflowstateID"),
            DifferentBatch: result.data[0].differentBatch,
            Assigned: result.data[0].assigned,
            isStartWork: true
          };
          let assignedWorkItemDetails = navData;
          const workflowstateID = sessionStorage.getItem("workflowstateID");
          const activityId = sessionStorage.getItem("PageId");
          navigate("/ActivityScreen", {
            state: {
              row,
              selectedSubprocess,
              nav,
              userRoles,
              employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
              workflowstateID,
              activityId,
              assignedWorkItemDetails: assignedWorkItemDetails,
              workItemID: result.data[0].workItemID
              //Iscanceled: 1,
            }
          });
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          BodydetailsinLog(error.toString() + " GetNextItemDetails ", body);
          //navigate("/ErrorPage");
        }
      });
  };
  const createNewItemDetails = () => {
    let row = {
      activityName: sessionStorage.getItem("workflowactivityName"),
      workflowStateID: sessionStorage.getItem("workflowstateID")
    };
    let assignedWorkItemDetails = navData;
    const workflowstateID = sessionStorage.getItem("workflowstateID");
    const activityId = sessionStorage.getItem("PageId");
    navigate("/ActivityScreenWorkItem", {
      state: {
        row,
        selectedSubprocess,
        nav,
        userRoles,
        employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
        workflowstateID,
        activityId,
        assignedWorkItemDetails: assignedWorkItemDetails
      }
    });
  };
  const getSelectedWorkItem = workItemId => {
    setselectedWorkitemValue(workItemId);
    let body = {
      workItemID: workItemId,
      personid: sessionStorage.getItem("PersonID"), //#1
      subprocessid: processingDashboardParam.selectedSubprocess //#1
    };
    let loginUrl = getAPIURL(REACT_APP_GET_WORKITEMDETAILS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          //Add By Gajendra
          // ;
          if(sessionStorage.getItem("IsSystemTimeZone")=="true")
          {
          DisplayTimeZone(result.data.listWorkItemHistory);
          DisplayTimeZone(result.data.asignmentdetails);
          }
          setworkitemDetails(result.data.asignmentdetails);
          setworkitemHistory(result.data.listWorkItemHistory);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          BodydetailsinLog(error.toString() + " getSelectedWorkItem ", body);
          //navigate("/ErrorPage");
        }
      });
  };
  const DisplayCriteriaOnChange = e => {
    sessionStorage.setItem("WorkitemMode", e.target.value);
    setworkitemMode(e.target.value);
    tableRef.current.onQueryChange();
    //getDashboardData(query)
    //getAllData();
  };
  const FilterActivity = filter => {
    //Changing UI components
    setfilterValue(filter);
  };
  const handleChangeRowSize = (event, newSize) => {
    // alert(event.target.value);
    // console.log("value", event.target.value);
    // console.log("newSize", newSize);
    setPagination(old => ({ ...old, pageSize: event.target.value }));
  };
  const handleChangePage = (event, newPage) => {
    // alert(newPage);
    setPagination(old => ({ ...old, pageIndex: newPage }));
  };
  const PostClearFilterData = filterValue => {
    setadvSearchDetails([]);
    setadvSearchMapToFieldDetails([]);
    setSortColumn("");
    setIsClearFilter(true);
    setisDefaultValue(true);
    setisDateField(false);
    let workflowactivityName = sessionStorage.getItem("workflowactivityName");

    sessionStorage.removeItem("" + workflowactivityName + "_mapToCondition");
    sessionStorage.removeItem("" + workflowactivityName + "_condition");
    sessionStorage.removeItem("" + workflowactivityName + "_sortColumn");
    sessionStorage.removeItem(
      "" + workflowactivityName + "_sortColumnSecondary"
    );
    sessionStorage.removeItem("" + workflowactivityName + "_sortColumnPrimary");
    //sessionStorage.removeItem("workflowactivityName");

    // setfilterValue("");
    // setDropdown(0);
    setfilterStyle("btn btn-sm btn-outline-primary ms-2");
    sessionStorage.removeItem("SortingOrder");
    setcheckedValue("");
    tableRef.current.onQueryChange();
    //FetchDashboardData(sessionStorage.getItem("EmployeeVersionID"), 0);
    //FetchDashboardDataPostFilter(employeeVersionID);
    
  };
  const handleApplyUpload = (event, picker) => {
    setuplodadedDateTimeFrom(picker.startDate.format("MM/DD/YYYY"));
    setuplodadedDateTimeTo(picker.endDate.format("MM/DD/YYYY"));
    setIsClearFilter(false);
    tableRef.current.onQueryChange();
    //FetchDashboardData(employeeVersionID, filterValue);
  };

  // const FetchDashboardDataPostFilter = (employeeVersionID) => {
  //   set_isLoadingDashboard(true);
  //   let activity = "";
  //   let mode = "";
  //   if (sessionStorage.getItem("workflowactivityName").includes("Exception")) {
  //     activity = "Exceptions";
  //     mode = "Exceptions";
  //   } else if (
  //     sessionStorage.getItem("workflowactivityName").includes("Complete")
  //   ) {
  //     activity = "Complete";
  //     mode = "Complete";
  //   } else {
  //     activity = sessionStorage.getItem("workflowactivityName");
  //   }
  //   let body = {
  //     mode: mode,
  //     userId: sessionStorage.getItem("GUID"),
  //     subProcessID: processingDashboardParam.selectedSubprocess,
  //     activityID: sessionStorage.getItem("PageId"),
  //     workflowStateID: sessionStorage.getItem("workflowstateID"),
  //     filtercriteria: "",
  //     sortcriteria: "",
  //     selection: activity,
  //     fromDate: uplodadedDateTimeFrom,
  //     toDate: uplodadedDateTimeTo,
  //     isSuperUser: isSuperUser,
  //     displayCriteria: +sessionStorage.getItem("WorkitemMode"),
  //     employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
  //     personID: sessionStorage.getItem("PersonID"),
  //   };
  //   let loginUrl = getAPIURL(REACT_APP_FETCH_DASHBOARDDATAGRID);
  //   let req = new Request(`${loginUrl}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       UserName: sessionStorage.getItem("UserName"),
  //       Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
  //     },
  //     body: JSON.stringify(body),
  //   });
  //   fetch(req)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.status === 200) {
  //         if (result.data.dashboardGridDataModels.length > 0) {
  //           setDashboardGridDataModels(result.data.dashboardGridDataModels);
  //           updateColumns(Object.keys(result.data.dashboardGridDataModels[0]));
  //         } else {
  //           setDashboardGridDataModels([]);
  //           updateColumns([]);
  //         }
  //         //Added by Pradip Rai
  //         if (REACT_APP_HUBBELL_CLIENTID == sessionStorage.selectedLocation) {
  //           setimageUrl(
  //             REACT_APP_Image_URL_HUBBELL.replace(
  //               "@onBasePassword",
  //               encodeURIComponent(result.data.onBasePassword)
  //             ).replace(
  //               "@onBaseUserID",
  //               encodeURIComponent(result.data.onBaseUserID)
  //             )
  //           );
  //         } else if (
  //           REACT_APP_GEC_CLIENTID == sessionStorage.selectedLocation
  //         ) {
  //           setimageUrl(
  //             REACT_APP_Image_URL_GEC.replace(
  //               "@onBasePassword",
  //               encodeURIComponent(result.data.onBasePassword)
  //             ).replace(
  //               "@onBaseUserID",
  //               encodeURIComponent(result.data.onBaseUserID)
  //             )
  //           );
  //         } else {
  //           setimageUrl(
  //             REACT_APP_Image_URL.replace(
  //               "@onBasePassword",
  //               encodeURIComponent(result.data.onBasePassword)
  //             ).replace(
  //               "@onBaseUserID",
  //               encodeURIComponent(result.data.onBaseUserID)
  //             )
  //           );
  //         }
  //         // const ImagetempUrl =
  //         //   "https://unitydocumentviewer.exlservice.com/ImageViewer.aspx?OBXID=" +
  //         //   result.data.onBaseUserID +
  //         //   "&OBKey=" +
  //         //   result.data.onBasePassword +
  //         //   "&DocID=@DocId&IsCWS=1";
  //         // setimageUrl(ImagetempUrl);
  //         //Ended by pradip Rai
  //         set_isLoadingDashboard(false);
  //       } else if (result.status === 401) {
  //         navigate("/");
  //       } else {
  //         set_isLoadingDashboard(false);
  //         addToast("Something went wrong!", {
  //           appearance: "error",
  //           autoDismiss: true,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("ERROR: ", error);
  //       //navigate("/");
  //     });
  // };
  // useEffect(() => {
  //   // alert();
  //   getDashboardData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const getAllData = (query) => new Promise((resolve) => {
  //   // prepare the data:
  //   console.log(query)
  //   let activity = "";
  //   let mode = "";
  //   let filtercriteria = "";
  //   if (sessionStorage.getItem("workflowactivityName").includes("Exception")) {
  //     activity = "Exceptions";
  //     mode = "Exceptions";
  //   } else if (
  //     sessionStorage.getItem("workflowactivityName").includes("Complete")
  //   ) {
  //     activity = "Complete";
  //     mode = "Complete";
  //   } else {
  //     activity = sessionStorage.getItem("workflowactivityName");
  //   }
  //   if (filterValue > 0) {
  //     filtercriteria =
  //       "wi.UploadDate between dateadd(day," +
  //       filterValue +
  //       ",getdate()) and getdate()";
  //   } else {
  //     filtercriteria = "";
  //   }

  //   let workflowactivityName = sessionStorage.getItem("workflowactivityName");
  //   let condition = sessionStorage.getItem(
  //     "" + workflowactivityName + "_mapToCondition"
  //   );
  //   let mapToCondition = sessionStorage.getItem(
  //     "" + workflowactivityName + "_condition"
  //   );
  //   let sortColumn = sessionStorage.getItem(
  //     "" + workflowactivityName + "_sortColumn"
  //   );

  //   if (mapToCondition != null) {
  //     setadvSearchDetails(JSON.parse(mapToCondition));
  //   } else {
  //     setadvSearchDetails([]);
  //   }

  //   if (condition != null) {
  //     setadvSearchMapToFieldDetails(JSON.parse(condition));
  //   } else {
  //     setadvSearchMapToFieldDetails([]);
  //   }
  //   //setSortColumn(sortColumn);

  //   let body = {
  //     mode: mode,
  //     userId: sessionStorage.getItem("GUID"),
  //     subProcessID: processingDashboardParam.selectedSubprocess,
  //     activityID: +sessionStorage.getItem("PageId"),
  //     workflowStateID: +sessionStorage.getItem("workflowstateID"),
  //     filtercriteria: filtercriteria,
  //     sortcriteria:
  //       sessionStorage.getItem("" + workflowactivityName + "_sortColumn") ==
  //       null
  //         ? ""
  //         : sessionStorage.getItem("" + workflowactivityName + "_sortColumn"), //SortColumnValue,
  //     selection: activity,
  //     fromDate: uplodadedDateTimeFrom,
  //     toDate: uplodadedDateTimeTo,
  //     isSuperUser:
  //       sessionStorage.getItem("isSuperUser") == "true" ? true : false,
  //     displayCriteria:
  //       sessionStorage.getItem("WorkitemMode") == null
  //         ? 0
  //         : +sessionStorage.getItem("WorkitemMode"),
  //     employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
  //     personID: +sessionStorage.getItem("PersonID"),
  //     dashboardPageCondition:
  //       JSON.parse(condition) == null ? [] : JSON.parse(condition),
  //   };
  //   let url = getAPIURL(REACT_APP_FETCH_DASHBOARDDATAGRID) + "?";
  //   url += "per_page=" + query.pageSize;
  //   url += "&page=" + (query.page+1);
  //   let req = new Request(`${url}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       UserName: sessionStorage.getItem("UserName"),
  //       Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
  //     },
  //     body: JSON.stringify(body),
  //   });
  //   fetch(req)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.status === 200) {
  //       filterShow(false);
  //       setfirstdashboardfetch(false);
  //       if (result.data.dashboardGridDataModels.length > 0) {
  //         //setDashboardGridDataModels(result.data.dashboardGridDataModels);
  //         updateColumns(Object.keys(result.data.dashboardGridDataModels[0]));

  //         //setRowCount(result.data.dashboardGridDataModels[0].TotalCount);
  //       } else {
  //         setDashboardGridDataModels([]);
  //         updateColumns([]);
  //       }
  //       //Added by Pradip Rai
  //       if (REACT_APP_HUBBELL_CLIENTID == sessionStorage.selectedLocation) {
  //         setimageUrl(
  //           REACT_APP_Image_URL_HUBBELL.replace(
  //             "@onBasePassword",
  //             encodeURIComponent(result.data.onBasePassword)
  //           ).replace(
  //             "@onBaseUserID",
  //             encodeURIComponent(result.data.onBaseUserID)
  //           )
  //         );
  //       } else if (REACT_APP_GEC_CLIENTID == sessionStorage.selectedLocation) {
  //         setimageUrl(
  //           REACT_APP_Image_URL_GEC.replace(
  //             "@onBasePassword",
  //             encodeURIComponent(result.data.onBasePassword)
  //           ).replace(
  //             "@onBaseUserID",
  //             encodeURIComponent(result.data.onBaseUserID)
  //           )
  //         );
  //       } else {
  //         setimageUrl(
  //           REACT_APP_Image_URL.replace(
  //             "@onBasePassword",
  //             encodeURIComponent(result.data.onBasePassword)
  //           ).replace(
  //             "@onBaseUserID",
  //             encodeURIComponent(result.data.onBaseUserID)
  //           )
  //         );
  //       }
  //       //set_isLoadingDashboard(false);
  //       resolve({
  //         data: result.data.dashboardGridDataModels,
  //         page:query.page,
  //         totalCount:result.data.dashboardGridDataModels[0].TotalCount
  //       });
  //       }
  //       else if (result.status === 401) {
  //         navigate("/");
  //       } else {
  //         set_isLoadingDashboard(false);
  //         addToast("Something went wrong!", {
  //           appearance: "error",
  //           autoDismiss: true,
  //         });
  //       }
  //     })
  // });
  //useCallback(getDashboardData,sessionStorage.getItem("WorkitemMode"))

  //useCallback(()=>{getDashboardData()},[,sessionStorage.getItem("WorkitemMode")])
  // const FetchDashboardData = (employeeVersionID, filterValue) => {
  //   set_isLoadingDashboard(true);
  //   let activity = "";
  //   let mode = "";
  //   let filtercriteria = "";
  //   if (sessionStorage.getItem("workflowactivityName").includes("Exception")) {
  //     activity = "Exceptions";
  //     mode = "Exceptions";
  //   } else if (
  //     sessionStorage.getItem("workflowactivityName").includes("Complete")
  //   ) {
  //     activity = "Complete";
  //     mode = "Complete";
  //   } else {
  //     activity = sessionStorage.getItem("workflowactivityName");
  //   }
  //   if (filterValue > 0) {
  //     filtercriteria =
  //       "wi.UploadDate between dateadd(day," +
  //       filterValue +
  //       ",getdate()) and getdate()";
  //   } else {
  //     filtercriteria = "";
  //   }

  //   let workflowactivityName = sessionStorage.getItem("workflowactivityName");
  //   let condition = sessionStorage.getItem(
  //     "" + workflowactivityName + "_mapToCondition"
  //   );
  //   let mapToCondition = sessionStorage.getItem(
  //     "" + workflowactivityName + "_condition"
  //   );
  //   let sortColumn = sessionStorage.getItem(
  //     "" + workflowactivityName + "_sortColumn"
  //   );

  //   if (mapToCondition != null) {
  //     setadvSearchDetails(JSON.parse(mapToCondition));
  //   } else {
  //     setadvSearchDetails([]);
  //   }

  //   if (condition != null) {
  //     setadvSearchMapToFieldDetails(JSON.parse(condition));
  //   } else {
  //     setadvSearchMapToFieldDetails([]);
  //   }
  //   //setSortColumn(sortColumn);

  //   let body = {
  //     mode: mode,

  //     userId: sessionStorage.getItem("GUID"),

  //     subProcessID: processingDashboardParam.selectedSubprocess,

  //     activityID: +sessionStorage.getItem("PageId"),

  //     workflowStateID: +sessionStorage.getItem("workflowstateID"),

  //     filtercriteria: filtercriteria,

  //     sortcriteria:
  //       sessionStorage.getItem("" + workflowactivityName + "_sortColumn") ==
  //       null
  //         ? ""
  //         : sessionStorage.getItem("" + workflowactivityName + "_sortColumn"), //SortColumnValue,

  //     selection: activity,

  //     fromDate: uplodadedDateTimeFrom,

  //     toDate: uplodadedDateTimeTo,

  //     isSuperUser:
  //       sessionStorage.getItem("isSuperUser") == "true" ? true : false,

  //     displayCriteria:
  //       sessionStorage.getItem("WorkitemMode") == null
  //         ? 0
  //         : +sessionStorage.getItem("WorkitemMode"),

  //     employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),

  //     personID: +sessionStorage.getItem("PersonID"),

  //     dashboardPageCondition:
  //       JSON.parse(condition) == null ? [] : JSON.parse(condition),
  //   };

  //   let url = getAPIURL(REACT_APP_FETCH_DASHBOARDDATAGRID) + "?";
  //   url += "per_page=" + pagination.pageSize;
  //   url += "&page=" + (pagination.pageIndex + 1);
  //   let req = new Request(`${url}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       UserName: sessionStorage.getItem("UserName"),
  //       Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
  //     },
  //     body: JSON.stringify(body),
  //   });
  //   fetch(req)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.status === 200) {
  //         filterShow(false);
  //         setfirstdashboardfetch(false);
  //         if (result.data.dashboardGridDataModels.length > 0) {
  //           setDashboardGridDataModels(result.data.dashboardGridDataModels);
  //           updateColumns(Object.keys(result.data.dashboardGridDataModels[0]));

  //           setRowCount(result.data.dashboardGridDataModels[0].TotalCount);
  //         } else {
  //           setDashboardGridDataModels([]);
  //           updateColumns([]);
  //         }
  //         //Added by Pradip Rai
  //         if (REACT_APP_HUBBELL_CLIENTID == sessionStorage.selectedLocation) {
  //           setimageUrl(
  //             REACT_APP_Image_URL_HUBBELL.replace(
  //               "@onBasePassword",
  //               encodeURIComponent(result.data.onBasePassword)
  //             ).replace(
  //               "@onBaseUserID",
  //               encodeURIComponent(result.data.onBaseUserID)
  //             )
  //           );
  //         } else if (
  //           REACT_APP_GEC_CLIENTID == sessionStorage.selectedLocation
  //         ) {
  //           setimageUrl(
  //             REACT_APP_Image_URL_GEC.replace(
  //               "@onBasePassword",
  //               encodeURIComponent(result.data.onBasePassword)
  //             ).replace(
  //               "@onBaseUserID",
  //               encodeURIComponent(result.data.onBaseUserID)
  //             )
  //           );
  //         } else {
  //           setimageUrl(
  //             REACT_APP_Image_URL.replace(
  //               "@onBasePassword",
  //               encodeURIComponent(result.data.onBasePassword)
  //             ).replace(
  //               "@onBaseUserID",
  //               encodeURIComponent(result.data.onBaseUserID)
  //             )
  //           );
  //         }
  //         // const ImagetempUrl =
  //         //   "https://unitydocumentviewer.exlservice.com/ImageViewer.aspx?OBXID=" +
  //         //   result.data.onBaseUserID +
  //         //   "&OBKey=" +
  //         //   result.data.onBasePassword +
  //         //   "&DocID=@DocId&IsCWS=1";
  //         // setimageUrl(ImagetempUrl);
  //         //Ended by pradip Rai
  //         set_isLoadingDashboard(false);
  //       } else if (result.status === 401) {
  //         navigate("/");
  //       } else {
  //         set_isLoadingDashboard(false);
  //         addToast("Something went wrong!", {
  //           appearance: "error",
  //           autoDismiss: true,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("ERROR: ", error);
  //       //navigate("/");
  //     });
  // };
  // const data = (query) =>
  //   new Promise((resolve, reject) => {
  //     resolve({
  //       data: dashboardData.data,
  //       page: dashboardData.page,
  //       totalCount: dashboardData.TotalCount,
  //     });
  //   });
  const assignedEmployee = e => {
    setselectedEmployee(e.target.value);
  };
  const assignEmployee = e => {
    e.preventDefault();
    const ReAssignedDropdownRefvalue = ReAssignedDropdownRef.current.value;
    if (ReAssignedDropdownRefvalue === "0") {
      addToast("Please select the assignment.", {
        appearance: "error",
        autoDismiss: true
      });
    } else {
      let workItemIds = selectedCheckboxDetails
        .map(item => item.WorkItemID)
        .join(",");
      let body = {
        workItemIDs: workItemIds,
        activityID: sessionStorage.getItem("PageId"),
        AssignmentEmployeeVersionID: selectedEmployee,
        personId: sessionStorage.getItem("PersonID")
      };

      let loginUrl = getAPIURL(REACT_APP_REASSIGNWORKITEM);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            setIsClearFilter(false);
            tableRef.current.onQueryChange();
            // FetchDashboardData(
            //   sessionStorage.getItem("EmployeeVersionID"),
            //   filterValue
            // );
            settingClose();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Reassigment Failed", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            //window.location.href = "/Home";
            BodydetailsinLog(error.toString() + " assignEmployee ", body);
            //navigate("/ErrorPage");
          }
        });
    }
  };
  
  const SetWorkitemtypeClick = e => {
    const ReAssignedWorkItemTypeRefvalue = ReAssignedWorkItemTypeRef.current.value;
    if (ReAssignedWorkItemTypeRefvalue === "0") {
      addToast("Please select the workitem type.", {
        appearance: "error",
        autoDismiss: true
      });
    } 
    else
    {
      let SelectedWorkItemsWithBlankWorkItemType = selectedCheckboxDetails
      .filter(item => item.WorkItemType=="")
      .map(item => item.WorkItemID).join(",");
      const WithBlankWorkItemType = SelectedWorkItemsWithBlankWorkItemType.split(",");

      let SelectedWorkItemsWithWorkItemType = selectedCheckboxDetails
      .filter(item => item.WorkItemType!="")
      .map(item => item.WorkItemID).join(",");
      const WithWorkItemType = SelectedWorkItemsWithWorkItemType.split(",");

      if(WithBlankWorkItemType != null && WithWorkItemType[0]=="")
      {
        let text = "Are you sure you want to update "+ WithBlankWorkItemType.length +" selected workitems";
        if (window.confirm(text) == true) {
          SetWorkitemType('Yes')
        }
      }  
      else if(WithWorkItemType[0]!="")
      {
        let text = WithWorkItemType.length +" of the selected workitems have Workitem Type already. Are you sure you want to overwrite them? Please select �Yes� to overwrite them, �No� to ignore them and �Cancel� to abort this operation.";
        if (window.confirm(text) == true) {
          setWorkitemtypeButton(false);
        }
      }
    }
  };
  const workItemTypeChange = e => {
    setddlWorkItemTypeValue(e.target.value.split("|")[0]);
  };
  const SetWorkitemType = (e) => {
    if(e=="Cancel")
    {
      setWorkitemtypeButton(true);
      settingClose();
      e.preventDefault();
    }
    else {
      // let workItemIds = selectedCheckboxDetails
      //   .map(item => item.WorkItemID)
      //   .join(",");
      let SelectedWorkItemsWithBlankWorkItemType = selectedCheckboxDetails
      .filter(item => item.WorkItemType=="")
      .map(item => item.WorkItemID).join(",");
      const WithBlankWorkItemType = SelectedWorkItemsWithBlankWorkItemType.split(",");

      let SelectedWorkItemsWithWorkItemType = selectedCheckboxDetails
      .filter(item => item.WorkItemType!="")
      .map(item => item.WorkItemID).join(",");
      const WithWorkItemType = SelectedWorkItemsWithWorkItemType.split(",");

      let workItemIds="";
      if(WithBlankWorkItemType[0]!="" && WithWorkItemType[0]=="")
      {
        workItemIds=SelectedWorkItemsWithBlankWorkItemType;
      }
      else if(e=="Yes")
      {
        if(WithWorkItemType[0]!="")
        {
          if(WithBlankWorkItemType[0]!="")
          {
            workItemIds=selectedCheckboxDetails
              .map(item => item.WorkItemID)
              .join(",");
          }
          else
          {
            workItemIds=SelectedWorkItemsWithWorkItemType;
          }
        }
      }
      else if(e=="No")
      {
        if(WithBlankWorkItemType[0]!="" )
        {
          workItemIds=SelectedWorkItemsWithBlankWorkItemType;
        }
      }
      
      let body = {
        workItemIDs: workItemIds,
        subprocessID: processingDashboardParam.selectedSubprocess,
        WorkItemTypeValue : ddlWorkItemTypeValue
      };

      let loginUrl = getAPIURL(REACT_APP_SAVEWORKITEMTYPEFORTHEWORKITEM);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            setIsClearFilter(false);
            tableRef.current.onQueryChange();
            setWorkitemtypeButton(true);
            settingClose();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast("Failed", {
              appearance: "error",
              autoDismiss: true
            });
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            //window.location.href = "/Home";
            BodydetailsinLog(error.toString() + " SaveWorkItemTypeForTheWorkItem ", body);
            //navigate("/ErrorPage");
          }
        });
    }
  }
  const subProcessActivityNames = subProcessId => {
    let body = {
      subProcessID: subProcessId
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_SUBPROCESSACTIVITYNAMES);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setsubProcessActivityName(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });

          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          BodydetailsinLog(
            error.toString() + " subProcessActivityNames ",
            body
          );
          //navigate("/ErrorPage");
        }
      });
  };

  const workItemStatuses = subProcessId => {
    let body = {
      workflowStateID: sessionStorage.getItem("workflowstateID"),
      subProcessID: subProcessId
    };

    let loginUrl = getAPIURL(REACT_APP_GET_STATUSESFORCURRENTWORKFLOWSTATE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setworkItemStatus(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          BodydetailsinLog(error.toString() + " workItemStatuses ", body);
          //navigate("/ErrorPage");
        }
      });
  };
  const getEmployeesToAssign = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      activityID: sessionStorage.getItem("PageId")
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_REASSIGNEMPLOYEESFORSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setemployeesToAssign(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          BodydetailsinLog(error.toString() + " getEmployeesToAssign ", body);
          //navigate("/ErrorPage");
        }
      });
  };
  const getEmployeesForSubprocessActivity = activityselectedName => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      ActivityName: activityselectedName
    };

    let loginUrl = getAPIURL(REACT_APP_GET_EMPLOYEESFORSUBPROCESSACTIVITY);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setemployeesForSubprocessActivity(result.data.subprocessempName);
          setworkAllocationMode(result.data.workAllocationMode);
          if (result.data.workAllocationMode === "Manual") {
            setIsAssignedToRequired(true);
          }
          setAssignTo(true);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setAssignTo(true);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          BodydetailsinLog(
            error.toString() + " getEmployeesForSubprocessActivity ",
            body
          );
          //navigate("/ErrorPage");
        }
      });
  };
  const getstatusReasonForSubprocess = statusid => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      status: statusid + "",
      isDeleted: false
    };
    let reasonURL = getAPIURL(REACT_APP_GET_GETSTATUSREASONSBYSUBPROCESSID);
    let reasonreq = new Request(`${reasonURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(reasonreq)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          setstatusReasonForSubprocess(result.data._listStatusReason);
          if (result.data._listStatusReason.length > 0) setReason(true);
          else setReason(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setReason(false);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          BodydetailsinLog(
            error.toString() + " getstatusReasonForSubprocess ",
            body
          );
          //navigate("/ErrorPage");
        }
      });
  };

  const FetchBreakTimerOnLoad = () => {
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;
    let activityId = sessionStorage.getItem("PageId");
    sessionStorage.setItem("PageId", activityId);
    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID: sessionStorage.getItem("PageId"), //for home page
      workItemID: 0,
      employeeTimeUtilization:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
          sessionStorage.getItem("empTimeUtilization") == "undefined"
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
          sessionStorage.getItem("selectedEntitiesDetail") == "undefined"
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime: "",
      txtBreakComments: "",
      timerValue: 1,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 60000
    };

    let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_FETCHBREAKTIMERONLOAD);
    let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          // settimerStart(result.timerStart);
          // setbtnShowBreakPanelDisabled(result.btnShowBreakPanelDisabled);
          // setbtnShowBreakPanelColor(result.btnShowBreakPanelColor);
          // setddlActivityEnabled(result.ddlActivityEnabled);
          // setobjdataview(result.objdataview);

          // if (result.data[0].tSaveTimerEnabled) {
          //   //  setrunSaveTImer(true);
          // }
          sessionStorage.setItem(
            "objdataview",
            JSON.stringify(result.data[0].objdataview)
          );
          sessionStorage.setItem(
            "FetchBreakTimerOnLoadData",
            JSON.stringify(result.data[0])
          );
          sessionStorage.setItem(
            "empTimeUtilization",
            JSON.stringify(result.data[0].employeeTimeUtilization)
          );
          // setddlActivitySelectedValue(result.ddlActivitySelectedValue);
          // settxtBreakCommentsEnabled(result.txtBreakCommentsEnabled);
          // settimerValue(result.timerValue);
          // sethdnSystemBreakValue(result.hdnSystemBreakValue);
          // setimgActivityToolTip(result.imgActivityToolTip);
          // settSaveTimerEnabled(result.tSaveTimerEnabled);
          // setbreakStartTime(result.breakStartTime);
          // setbtnStartWorkTimerDisabled(result.btnStartWorkTimerDisabled);
          // setemployeeTimeUtilization(result.employeeTimeUtilization);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          BodydetailsinLog(error.toString() + " FetchBreakTimerOnLoad ", body);
          //navigate("/ErrorPage");
        }
      });
  };
  const handleSetSelectedRows = e => {
    //setSelectedRows(e);

    // console.log(e);
  };
  const handleFilterChange = e => {
    //setSelectedRows(e);

    // console.log(e);
  };
  const getDashboardData = query =>
    new Promise(resolve => {
      // prepare the data

      if (CheckActivityId != sessionStorage.getItem("PageId")) {
        setcolumns([]);
        query.filters = [];
      }

      // console.log(query);
      let filterValue = "";
      let activity = "";
      let mode = "";
      let filtercriteria = "";
      let filtercriteria2 = "";
      if (
        sessionStorage.getItem("workflowactivityName").includes("Exception")
      ) {
        activity = "Exceptions";
        mode = "Exceptions";
      } else if (
        sessionStorage.getItem("workflowactivityName").includes("Complete")
      ) {
        activity = "Complete";
        mode = "Complete";
      } else {
        activity = sessionStorage.getItem("workflowactivityName");
      }
      if (filterValue > 0) {
        filtercriteria =
          "wi.UploadDate between dateadd(day," +
          filterValue +
          ",getdate()) and getdate()";
      } else {
        filtercriteria = "";
      }

      let workflowactivityName = sessionStorage.getItem("workflowactivityName");
      let condition = sessionStorage.getItem(
        "" + workflowactivityName + "_mapToCondition"
      );
      let mapToCondition = sessionStorage.getItem(
        "" + workflowactivityName + "_condition"
      );
      let sortColumn = sessionStorage.getItem(
        "" + workflowactivityName + "_sortColumn"
      );

      if (mapToCondition != null) {
        setadvSearchDetails(JSON.parse(mapToCondition));
      } else {
        setadvSearchDetails([]);
      }

      if (condition != null) {
        setadvSearchMapToFieldDetails(JSON.parse(condition));
      } else {
        setadvSearchMapToFieldDetails([]);
      }
      //setSortColumn(sortColumn);
      // if(query.orderBy)
      // {
      //   url+="&_sort="+(query.orderBy.field)+"&_order="+(query.orderDirection)
      // }
      //// ;
      setdashboardfilterValue("");
      if (query.filters.length > 0) {
        const filter = query.filters.map(filter => {
          return filter.column.field + filter.operator + filter.value + " and ";
        });
        filterValue += filter.join("");
        setdashboardfilterValue(filterValue);
      }
debugger;
      let body = {
        mode: mode,
        userId: sessionStorage.getItem("GUID"),
        subProcessID: processingDashboardParam.selectedSubprocess,
        activityID: +sessionStorage.getItem("PageId"),
        workflowStateID: +sessionStorage.getItem("workflowstateID"),
        filtercriteria: filtercriteria,
        filtercriteria2: "",

        sortcriteria:
          sessionStorage.getItem("" + workflowactivityName + "_sortColumn") ==
            null
            ? ""
            : sessionStorage.getItem("" + workflowactivityName + "_sortColumn"), //SortColumnValue,
        selection: activity,
        fromDate: uplodadedDateTimeFrom,
        toDate: uplodadedDateTimeTo,
        isSuperUser:
          sessionStorage.getItem("isSuperUser") == "true" ? true : false,
        displayCriteria:
          sessionStorage.getItem("WorkitemMode") == null
            ? 0
            : +sessionStorage.getItem("WorkitemMode"),
        employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
        personID: +sessionStorage.getItem("PersonID"),
        personName: sessionStorage.getItem("UserFullName"),
        sortField:
          query.orderBy != undefined && !IsClearFilter
            ? query.orderBy.field
            : "",
        sortOrder:
          query.orderDirection != "" && !IsClearFilter
            ? query.orderDirection
            : "",
        gridFilter:
          query.filters.length > 0 && !IsClearFilter ? filterValue : "",
        dashboardPageCondition:
          JSON.parse(condition) == null ? [] : JSON.parse(condition),
        TimezoneName: sessionStorage.getItem("IsSystemTimeZone")=="true"?
        Intl.DateTimeFormat().resolvedOptions().timeZone:
        new Date()
          .toLocaleDateString(undefined, {
            day: "2-digit",
            timeZoneName: "long"
          })
          .substring(4),

        primarySorting:
          sessionStorage.getItem(
            "" + workflowactivityName + "_sortColumnPrimary"
          ) == null
            ? ""
            : sessionStorage.getItem(
              "" + workflowactivityName + "_sortColumnPrimary"
            ),

        secondarySorting:
          sessionStorage.getItem(
            "" + workflowactivityName + "_sortColumnSecondary"
          ) == null
            ? ""
            : sessionStorage.getItem(
              "" + workflowactivityName + "_sortColumnSecondary"
            ),

        SortingOrder:
          sessionStorage.getItem("SortingOrder") == null
            ? ""
            : sessionStorage.getItem("SortingOrder"),

        ClientName:
          sessionStorage.getItem("selectedLocationName").split("~").length == 2
            ? sessionStorage
              .getItem("selectedLocationName")
              .split("~")[1]
              .trim()
            : ""

        //workflowactivityName + "_sortColumnPrimary"
      };
      setIsClearFilter(false);
      let url = getAPIURL(REACT_APP_FETCH_DASHBOARDDATAGRID) + "?";
      url += "per_page=" + query.pageSize;
      url += "&page=" + (query.page + 1);
      // console.log(url);
      let req = new Request(`${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status === 200) {
            settypeConfigs(result.data.typeConfigs);
            filterShow(false);
            //setfirstdashboardfetch(false)
            setfirstdashboardfetch(false);
            let count = result.data.dashboardGridDataModels.length;
            //if(result.data.dashboardGridDataModels[0].LastStatus=="Complete"){
            if (count !== 0) {
              if (
                result.data.dashboardGridDataModels[0].InvoiceReceivedDate !==
                undefined
              ) {
                for (var i = 0; i < count; i++) {
                  // ;
                  result.data.dashboardGridDataModels[
                    i
                  ].InvoiceReceivedDate = result.data.dashboardGridDataModels[
                    i
                  ].InvoiceReceivedDate.slice(0, 9);
                }
              }
            }

            if (result.data.dashboardGridDataModels.length > 0) {
              sessionStorage.setItem(
                "filtercriteria",
                result.data.filtercriteria
              );
              sessionStorage.setItem(
                "filtercriteria2",
                result.data.filtercriteria2
              );

              //setDashboardGridDataModels(result.data.dashboardGridDataModels);
              setGridColLen(result.data.dashboardGridDataModels.length);

              if (
                query.filters.length == 0 ||
                CheckActivityId != sessionStorage.getItem("PageId")
              ) {
                // if (CheckProcessId != sessionStorage.getItem("ProcessId")) {
                // if (result.data.dashboardGridDataModels.length > 0) {
                //debugger

                updateColumns(
                  Object.keys(result.data.dashboardGridDataModels[0])
                );
                // }
                // }
              }
              //setRowCount(result.data.dashboardGridDataModels[0].TotalCount);
            } else {
              setDashboardGridDataModels([]);
              //updateColumns([]);
              //setCheckProcessId("");
            }
            //Added by Pradip Rai
            if (REACT_APP_HUBBELL_CLIENTID == sessionStorage.selectedLocation || REACT_APP_C520_PROGRESS_CLIENTID == sessionStorage.selectedLocation) {
              // setimageUrlTemplate(
              //   REACT_APP_Image_URL_HUBBELL.replace(
              //     "@onBasePassword",
              //     encodeURIComponent(result.data.onBasePassword)
              //   ).replace(
              //     "@onBaseUserID",
              //     encodeURIComponent(result.data.onBaseUserID)
              //   )
              // );
              imageUrlTemplateRef.current = REACT_APP_Image_URL_HUBBELL.replace(
                "@onBasePassword",
                encodeURIComponent(result.data.onBasePassword)
              ).replace(
                "@onBaseUserID",
                encodeURIComponent(result.data.onBaseUserID)
              );
            } else if (
              REACT_APP_GEC_CLIENTID == sessionStorage.selectedLocation
            ) {
              // setimageUrlTemplate(
              //   REACT_APP_Image_URL_GEC.replace(
              //     "@onBasePassword",
              //     encodeURIComponent(result.data.onBasePassword)
              //   ).replace(
              //     "@onBaseUserID",
              //     encodeURIComponent(result.data.onBaseUserID)
              //   )
              // );
              imageUrlTemplateRef.current = REACT_APP_Image_URL_GEC.replace(
                "@onBasePassword",
                encodeURIComponent(result.data.onBasePassword)
              ).replace(
                "@onBaseUserID",
                encodeURIComponent(result.data.onBaseUserID)
              )
            } else {
              // setimageUrlTemplate(
              //   REACT_APP_Image_URL.replace(
              //     "@onBasePassword",
              //     encodeURIComponent(result.data.onBasePassword)
              //   ).replace(
              //     "@onBaseUserID",
              //     encodeURIComponent(result.data.onBaseUserID)
              //   )
              // );
              imageUrlTemplateRef.current = REACT_APP_Image_URL.replace(
                "@onBasePassword",
                encodeURIComponent(result.data.onBasePassword)
              ).replace(
                "@onBaseUserID",
                encodeURIComponent(result.data.onBaseUserID)
              )
            }
            //set_isLoadingDashboard(false);
            tableRef.current.dataManager.changePageSize(query.pageSize);
            // let data = DisplayTimeZone(result.data.dashboardGridDataModels);
            // data = UploaddateLocalTime(result.data.dashboardGridDataModels);
            resolve({
              data: result.data.dashboardGridDataModels,
              page: query.page,
              totalCount:
                result.data.dashboardGridDataModels.length > 0
                  ? result.data.dashboardGridDataModels[0].TotalCount
                  : 0
            });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            set_isLoadingDashboard(false);
            addToast("Something went wrong!", {
              appearance: "error",
              autoDismiss: true
            });
          }
        });
    });

  const getDashboardDataForExportCSV = () => {
    // set_IsLoading(true);
    // if(isExportFirstTime)
    // {
    //   set_IsLoading(false);
    //   alert("Excel upload is restricted to download only once, if you need it thn go to Home page then try");
    //   return false;
    // }
    // setisExportFirstTime(true);
    // ;
    // let SelectedExportOption = e.id;
    setshowButton(false);
    setButton(true);
    // new Promise((resolve) => {
    // prepare the data
    //// ;
    if (CheckActivityId != sessionStorage.getItem("PageId")) {
      setcolumns([]);
    }
    // console.log(query);
    let filterValue = "";
    let activity = "";
    let mode = "";
    let filtercriteria = "";
    if (sessionStorage.getItem("workflowactivityName").includes("Exception")) {
      activity = "Exceptions";
      mode = "Exceptions";
    } else if (
      sessionStorage.getItem("workflowactivityName").includes("Complete")
    ) {
      activity = "Complete";
      mode = "Complete";
    } else {
      activity = sessionStorage.getItem("workflowactivityName");
    }
    if (filterValue > 0) {
      filtercriteria =
        "wi.UploadDate between dateadd(day," +
        filterValue +
        ",getdate()) and getdate()";
    } else {
      filtercriteria = "";
    }
    // }

    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    let condition = sessionStorage.getItem(
      "" + workflowactivityName + "_mapToCondition"
    );
    let mapToCondition = sessionStorage.getItem(
      "" + workflowactivityName + "_condition"
    );
    let sortColumn = sessionStorage.getItem(
      "" + workflowactivityName + "_sortColumn"
    );
    if (mapToCondition != null) {
      setadvSearchDetails(JSON.parse(mapToCondition));
    } else {
      setadvSearchDetails([]);
    }
    if (condition != null) {
      setadvSearchMapToFieldDetails(JSON.parse(condition));
    } else {
      setadvSearchMapToFieldDetails([]);
    }
    //// ;
    //filterValue
    let body = {
      mode: mode,
      userId: sessionStorage.getItem("GUID"),
      subProcessID: processingDashboardParam.selectedSubprocess,
      activityID: +sessionStorage.getItem("PageId"),
      workflowStateID: +sessionStorage.getItem("workflowstateID"),
      filtercriteria: filtercriteria,
      TimezoneName: sessionStorage.getItem("LocalTimezoneName"),
      sortcriteria:
        sessionStorage.getItem("" + workflowactivityName + "_sortColumn") ==
          null
          ? ""
          : sessionStorage.getItem("" + workflowactivityName + "_sortColumn"), //SortColumnValue,
      selection: activity,
      fromDate: uplodadedDateTimeFrom,
      toDate: uplodadedDateTimeTo,
      isSuperUser:
        sessionStorage.getItem("isSuperUser") == "true" ? true : false,
      displayCriteria:
        sessionStorage.getItem("WorkitemMode") == null
          ? 0
          : +sessionStorage.getItem("WorkitemMode"),
      employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
      personID: +sessionStorage.getItem("PersonID"),
      sortField: "",
      // query.orderBy != undefined && !IsClearFilter
      //   ? query.orderBy.field
      //   : "",
      sortOrder: "",
      // query.orderDirection != "" && !IsClearFilter
      //   ? query.orderDirection
      //   : "",
      gridFilter: dashboardfilterValue,
      // query.filters.length > 0 && !IsClearFilter ? filterValue : "",
      dashboardPageCondition:
        JSON.parse(condition) == null ? [] : JSON.parse(condition),
      TimezoneName: new Date()
        .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "long" })
        .substring(4),
      ClientName:
        sessionStorage.getItem("selectedLocationName").split("~").length == 2
          ? sessionStorage
            .getItem("selectedLocationName")
            .split("~")[1]
            .trim()
          : ""
    };
    let url = getAPIURL(REACT_APP_FETCHDASHBAORDDATAFOREXPORT) + "?";
    url += "per_page=" + 0;
    url += "&page=" + 0;
    // console.log(url);
    let req = new Request(`${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          set_IsLoading(false);
          exportAllSelectedRows(result.data.dashboardGridDataModels);
          // const data=JSON.parse(result.data.dashboardGridDataModels);
          // const fileName='DashboardData'
          // const exportType=exportFromJSON.types.csv;
          // exportFromJSON({data,fileName,exportType});
          //console.log("Data");

          //result.data.jsonArrayDashbaordData;

          // else if (SelectedExportOption == 1){
          //   const data1=result.data.dashboardGridDataModels;
          //   const exportType = "xls";
          //   const fileType =
          //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          //   const fileExtension=".xlsx";
          //   const fileName="DashboardData"
          //   // ;
          //   const ws = XLSX.utils.json_to_sheet(data1);
          //   const wb = { Sheets: {data: ws},SheetNames: ["data"]};
          //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          //   const data = new Blob([excelBuffer], { type: fileType });
          //   FileSaver.saveAs(data, fileName + fileExtension);
          //      }
        } else if (result.status == 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          set_isLoadingDashboard(false);
          addToast("Something went wrong!", {
            appearance: "error",
            autoDismiss: true
          });
        }
      });
    //  }
    // );
  };
  const getDashboardDataForExportExcel = () => {
    // set_IsLoading(true);
    // if(isExportFirstTime)
    // {
    //   set_IsLoading(false);
    //   alert("Excel upload is restricted to download only once, if you need it thn go to Home page then try");
    //   return false;
    // }
    // setisExportFirstTime(true);
    // ;
    // let SelectedExportOption = e.id;
    setshowButton(false);
    setButton(true);
    // new Promise((resolve) => {
    // prepare the data
    //// ;
    if (CheckActivityId != sessionStorage.getItem("PageId")) {
      setcolumns([]);
    }
    // console.log(query);
    let filterValue = "";
    let activity = "";
    let mode = "";
    let filtercriteria = "";
    if (sessionStorage.getItem("workflowactivityName").includes("Exception")) {
      activity = "Exceptions";
      mode = "Exceptions";
    } else if (
      sessionStorage.getItem("workflowactivityName").includes("Complete")
    ) {
      activity = "Complete";
      mode = "Complete";
    } else {
      activity = sessionStorage.getItem("workflowactivityName");
    }
    if (filterValue > 0) {
      filtercriteria =
        "wi.UploadDate between dateadd(day," +
        filterValue +
        ",getdate()) and getdate()";
    } else {
      filtercriteria = "";
    }
    // }

    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    let condition = sessionStorage.getItem(
      "" + workflowactivityName + "_mapToCondition"
    );
    let mapToCondition = sessionStorage.getItem(
      "" + workflowactivityName + "_condition"
    );
    let sortColumn = sessionStorage.getItem(
      "" + workflowactivityName + "_sortColumn"
    );
    if (mapToCondition != null) {
      setadvSearchDetails(JSON.parse(mapToCondition));
    } else {
      setadvSearchDetails([]);
    }
    if (condition != null) {
      setadvSearchMapToFieldDetails(JSON.parse(condition));
    } else {
      setadvSearchMapToFieldDetails([]);
    }
    //// ;
    //filterValue
    let body = {
      mode: mode,
      userId: sessionStorage.getItem("GUID"),
      subProcessID: processingDashboardParam.selectedSubprocess,
      activityID: +sessionStorage.getItem("PageId"),
      workflowStateID: +sessionStorage.getItem("workflowstateID"),
      filtercriteria: filtercriteria,
      TimezoneName: sessionStorage.getItem("LocalTimezoneName"),
      sortcriteria:
        sessionStorage.getItem("" + workflowactivityName + "_sortColumn") ==
          null
          ? ""
          : sessionStorage.getItem("" + workflowactivityName + "_sortColumn"), //SortColumnValue,
      selection: activity,
      fromDate: uplodadedDateTimeFrom,
      toDate: uplodadedDateTimeTo,
      isSuperUser:
        sessionStorage.getItem("isSuperUser") == "true" ? true : false,
      displayCriteria:
        sessionStorage.getItem("WorkitemMode") == null
          ? 0
          : +sessionStorage.getItem("WorkitemMode"),
      employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
      personID: +sessionStorage.getItem("PersonID"),
      sortField: "",
      // query.orderBy != undefined && !IsClearFilter
      //   ? query.orderBy.field
      //   : "",
      sortOrder: "",
      // query.orderDirection != "" && !IsClearFilter
      //   ? query.orderDirection
      //   : "",
      gridFilter: dashboardfilterValue,
      // query.filters.length > 0 && !IsClearFilter ? filterValue : "",
      dashboardPageCondition:
        JSON.parse(condition) == null ? [] : JSON.parse(condition),
      TimezoneName: new Date()
        .toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "long" })
        .substring(4),
      ClientName:
        sessionStorage.getItem("selectedLocationName").split("~").length == 2
          ? sessionStorage
            .getItem("selectedLocationName")
            .split("~")[1]
            .trim()
          : ""
    };
    let url = getAPIURL(REACT_APP_FETCHDASHBAORDDATAFOREXPORT) + "?";
    url += "per_page=" + 0;
    url += "&page=" + 0;
    // console.log(url);
    let req = new Request(`${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          set_IsLoading(false);
          const data1 = result.data.dashboardGridDataModels;
          var count = data1[0].TotalCount;
          data1.map(obj => {
            for (var i = 0; i < count; i++) {
              delete data1[i].TotalCount;
              delete data1[i].CWSOnBaseDocID;
              delete data1[i].OnBaseField;
              delete data1[i].IsWIP;
              delete data1[i].PriorityState;
              //if(data[i] )
              if (data1[i].SecondPriority != undefined) {
                delete data1[i].SecondPriority;
              }
            }
          });
          DownloadExcel(data1, "DashboardData");
        } else if (result.status == 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          set_isLoadingDashboard(false);
          addToast("Something went wrong!", {
            appearance: "error",
            autoDismiss: true
          });
        }
      });
    //  }
    // );
  };

  // useEffect(() => {
  //   const fetchData = async query => {
  //     let url = `${GET_ORDERS_URL}qtt=${query.pageSize}&page=${query.page}`;

  //     const response = await fetch(url);
  //     const result = response.json();

  //     setData({
  //       data: result.data,
  //       page: result.page,
  //       totalCount: result.totalElems
  //     });
  //   };
  //   fetchData();
  // }, [data]);
  useEffect(() => {
    //getDashboardData(query<any>)

    // console.log(advSearchDetails);
    // console.log(document.getElementById("ddlPrimaryFilter"));
    let sortColumn = sessionStorage.getItem("filtercriteria");
    let a = sessionStorage.getItem("workflowactivityName");
    let Sortvalue = sessionStorage.getItem("" + a + "_sortColumn");
    if (
      (sortColumn == "" || sortColumn == null || sortColumn == undefined) &&
      (Sortvalue == "" || Sortvalue == null || Sortvalue == undefined)
    ) {
      setfilterStyle("btn btn-sm btn-outline-primary ms-2");
    } else {
      setfilterStyle("btn btn-sm btn-primary ms-2");
    }
    setisUplodDateTime(false);
    // setfilterValue(
    //   "wi.UploadDate between dateadd(day,-10,getdate()) and getdate()"
    // );
    let PageDetails = [];
    if (selectedWorkItemDetails) {
      if (selectedWorkItemDetails.activityName.includes("Complete")) {
        setisUplodDateTime(true);
      }
      PageDetails = selectedWorkItemDetails;
    } else {
      let menu = sessionStorage.getItem("navMenu");
      if (menu.includes("Complete")) {
        setisUplodDateTime(true);
      }
      PageDetails = navData.find(e => e.activityName == menu);
    }

    let activityName = PageDetails.activityName;
    let activityID = PageDetails.activityId;
    let workflowstateID = PageDetails.workflowStateID;
    sessionStorage.setItem("workflowactivityName", activityName);
    sessionStorage.setItem("workflowstateID", workflowstateID);
    sessionStorage.setItem("PageId", activityID);
    setactivityName(activityName);
    setworkflowStateID(workflowstateID);
    setpageChange(activityName);
    let text = activityName;
    let result = text.substring(0, 6);
    // if (result == "PrePro" || result == "Proces") {
    //   setNewBlankWorkItem(true);
    // }
    setcheckedValue(sessionStorage.getItem("SortingOrder") != null ?sessionStorage.getItem("SortingOrder"):"");
    setselectedActivityId(activityID);
    setselectedWorkflowStateId(workflowstateID);
    setemployeeVersionID(sessionStorage.getItem("EmployeeVersionID"));
    set_IsLoading(true);
    settingShow(false);
    setviewDocumentModal(false);
    setviewWorkitemModal(false);
    //  subProcessActivityNames(processingDashboardParam.selectedSubprocess);
    workItemStatuses(processingDashboardParam.selectedSubprocess);
    getEmployeesToAssign();
    if (sessionStorage.getItem("ALLOWCLIENTTIMECAPTURING") == "1") {
      FetchBreakTimerOnLoad();
    }
    let body = {
      mode: "",
      userID: sessionStorage.getItem("GUID"),
      activityID: +activityID,
      subprocessID: processingDashboardParam.selectedSubprocess,
      workflowstateID: +sessionStorage.getItem("workflowstateID"),
      dashboardPageCondition: advSearchMapToFieldDetails,
      personID: +sessionStorage.getItem("PersonID")
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DASHBOARDCONFIGURATIONS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          //setImageModeOn(result.data.dashboard_Configurations[0].chkAutoImageModeOn);
          sessionStorage.setItem(
            "ImageModeValue",
            result.data.dashboard_Configurations[0].chkAutoImageModeOn
          );
          //AutoImageModeRef.current = result.data.dashboard_Configurations[0].chkAutoImageModeOn;
          //console.log(AutoImageModeRef.current);
          setisSuperUser(result.data.isSuperAdmin);
          sessionStorage.setItem("isSuperUser", result.data.isSuperAdmin);

          // console.log("activityID=" + activityID)
          // console.log("result.data.isSuperAdmin=" + result.data.isSuperAdmin);
          // console.log("ShowDashboard value=" + result.data.dashboard_Configurations[0].showDashboardGrid);

          setdashboardorderings(result.data.dashboard_Orderings);
          sessionStorage.setItem(
            "DashboardOrderings",
            JSON.stringify(result.data.dashboard_Orderings)
          );
          setsearchItemFields(result.data.dashboard_ColumnsDetails);

          let FieldValues = result.data.dashboard_ColumnsDetails.filter(
            e => e.workitemFieldIDString !== "0"
          );
          setsearchItemFilterFields(FieldValues);

          setemployeeVersionID(sessionStorage.getItem("EmployeeVersionID"));
          setShowDashBoardGrid(
            result.data.dashboard_Configurations[0].showDashboardGrid
          );
          sessionStorage.setItem(
            "ShowDashboard",
            result.data.dashboard_Configurations[0].showDashboardGrid
          );

          setIsSuperAdmin(result.data.isSuperAdmin);
          // if (result.data.isSuperAdmin) {
          //   //FetchDashboardData(sessionStorage.getItem("EmployeeVersionID"), 0);
          //   getDashboardData()
          // }
          setAllowStatusChange(
            result.data.dashboard_Configurations[0]
              .allowStatusChangeFromDashboard
          );
          setallowAssignment(
            result.data.dashboard_Configurations[0].allowAssignmentFromDashboard
          );
          setAllowAdhocAdditionOfItems(
            result.data.dashboard_Configurations[0].allowAdhocAdditionOfItems
          );
          setAllowAdhocAdditionOfItemsForUpload(
            result.data.dashboard_Configurations[0]
              .allowAdhocAdditionOfItemsForUpload
          );
          setShowLastActivityDetail(
            result.data.dashboard_Configurations[0]
              .showLastActivityDetailOnDashboard
          ); // To show last activity details on grid
          setAllowEmailNotification(
            result.data.dashboard_Configurations[0].allowEmailNotification
          );
          setAllowSubProcessChange(
            result.data.dashboard_Configurations[0].isAllowSubProcessChange
          );
          setAllowBulkUpdateBulk(
            result.data.dashboard_Configurations[0].allowBulkUpdate
          );
          //const [allocationUnit, setAllocationUnit] = useState('');
          setWorkItemAllocationMode(
            result.data.dashboard_Configurations[0].workItemAllocationMode
          );
          setIsClientActivity(result.data.isClientActivity[0]);
          //  setisSuperUser(result.data.isSuperAdmin);
          // addToast("Configurations Loaded Successfully!", {
          //   appearance: "success",
          //   autoDismiss: true,
          // });
          //added by Santosh
          setbtnAuditTrailVisible(
            result.data.dashboard_Configurations[0].auditTrail
          );
          setbtnUserNotesVisible(
            result.data.dashboard_Configurations[0].userNotes
          );

          if (result.data.dashboard_Configurations[0].userNotes == true) {
            setbtnUserNotesVisible(false);
          } else {
            setbtnUserNotesVisible(true);
          }
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Configuration Loading Failed!", {
            appearance: "error",
            autoDismiss: true
          });
        }
        set_IsLoading(false);
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          BodydetailsinLog(error.toString() + " useEffect ", body);
          //navigate("/ErrorPage");
        }
        set_IsLoading(false);
      });
    //test(advSearchMapToFieldDetails);

    return () => {
      // console.log("ending dashboard 2");
      clearInterval(interval);
      //   alert('unmount')
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem("navMenu")]);

  const [searchItemFilterFields, setsearchItemFilterFields] = useState([]);
  const [IsCustomField, setIsCustomField] = useState("0");
  const [disableDate, setdisableDate] = useState(true);

  //const [modeChange, setModeChange] = useState(false);
  const [showUserDefinedFields, setshowUserDefinedFields] = useState(false);
  const [searchItemFields, setsearchItemFields] = useState([]);
  const [notEqual, SetnotEqual] = useState("<>");
  const [lessThan, SetlessThan] = useState("<");
  const [lessThanEqual, SetlessThanEqual] = useState("<=");
  const [greaterThen, SetgreaterThen] = useState(">");
  const [greaterThenEqual, SetgreaterThenEqual] = useState(">=");
  const [numericOperator, setnumericOperator] = useState(false);
  const [advCondition, setadvCondition] = useState("");
  const [advsubmitandcontinue, setadvsubmitandcontinue] = useState("");
  const [isDateField, setisDateField] = useState(false);
  const [advSearchText, setadvSearchText] = useState("");
  const [advSearchTextDate, setadvSearchTextDate] = useState("");
  const [advSearchField, setadvSearchField] = useState("");
  const [advSearchMapToField, setadvSearchMapToField] = useState("");
  const [advSearchDetails, setadvSearchDetails] = useState([]);
  const [advSearchMapToFieldDetails, setadvSearchMapToFieldDetails] = useState(
    []
  );
  const [checkedValue, setcheckedValue] = useState("Desc");
  const [dashboardfilterValue, setdashboardfilterValue] = useState("Desc");

  const [SortColumnValue, setSortColumn] = useState("");

  const getAdvanceCondition = e => {
    if (
      e.target.value == "Equals" ||
      e.target.value == "Before" ||
      e.target.value == "After"
    ) {
      setdisableDate(false);
    } else {
      setdisableDate(true);
    }
    setisDefaultValue(false);
    setadvCondition(e.target.value);
  };

  const getPreference = e => {
    
    setcheckedValue(e.target.value);
    //alert(e.target.value);
    //setprefenceValue(parseInt(e.target.value));
  };

  const ModeOnChange = e => {
    // setModeChange(true);
    // setshowUserDefinedFields(!e.target.checked);
    //console.log(showUserDefinedFields);
    let IsCustomField = "";
    if (e.target.checked === false) {
      setIsCustomField("0");
      IsCustomField = "0";
      let FieldValues = searchItemFields.filter(
        e => e.workitemFieldIDString !== "0"
      );
      setsearchItemFilterFields(FieldValues);
    } else {
      setIsCustomField("1");
      IsCustomField = "1";
      setsearchItemFilterFields(searchItemFields);
    }
    // console.log(isCustomField);
    // setModeChange(true);
  };

  //let isDateTimeFeild=false;
  const [isDateTimeFeild, setisDateTimeFeild] = useState(false);
  const [isDefaultValue, setisDefaultValue] = useState(false);
  const getAdvanceField = e => {
    e.preventDefault();
    setnumericOperator(false);
    setisDateField(false);
    setadvSearchText("");
    setisDateTimeFeild(false);
    setadvCondition("");
    setisDefaultValue(true);

    let datetimeValue = e.target.value.split("/")[0].includes("DateTime");
    let intValue = e.target.value.split("/")[0].includes("Int");
    let DecimalValue = e.target.value.split("/")[0].includes("Decimal");
    let DoubleValue = e.target.value.split("/")[0].includes("Double");
    let SLADueDateValue = e.target.value.split("/")[0].includes("SLADueDate");
    let ScanDateValue = e.target.value.split("/")[0].includes("ScanDate");
    let UploadDateValue = e.target.value.split("/")[0].includes("UploadDate");
    if (intValue > 0 || DecimalValue > 0 || DoubleValue > 0) {
      setnumericOperator(true);
      //setadvCondition("=");
      setisDateTimeFeild(false);
    } else {
      //setadvCondition("Equals");
    }
    if (
      datetimeValue > 0 ||
      SLADueDateValue > 0 ||
      ScanDateValue > 0 ||
      UploadDateValue > 0
    ) {
      setisDateField(true);
      setisDateTimeFeild(true);
      setdisableDate(true);
    }

    // setadvSearchField(e.target.value.split("/")[1]);
    // setadvSearchMapToField(e.target.value.split("/")[0]);
    //debugger;
    setadvSearchField(e.target.value.split("/")[1].split("$")[0]);
    setadvSearchMapToField(e.target.value.split("/")[0]);
    setadvsubmitandcontinue(
      e.target.value
        .split("/")[1]
        .split("$")[1]
        .split("~")[0]
    );
  };

  const getAdvanceText = e => {
    setadvSearchTextDate("");
    setadvSearchText(e.target.value);
  };

  const getAdvanceDateText = e => {
    setadvSearchText("");
    setadvSearchTextDate(e.target.value);
  };

  const addAdvanceFilterSearchConditions = () => {
    if (
      advSearchField == "Select" ||
      advSearchField == "" ||
      advSearchField == undefined ||
      advCondition == "Select" ||
      advCondition == "" ||
      advCondition == undefined ||
      (isDateField === true && (advCondition == "Equals" || advCondition == "After" || advCondition == "Before") &&  advSearchTextDate == "")
      || (isDateField === false &&  (advSearchText == ""|| advSearchText == undefined ))
    ) {
      alert("Please select category title,operator & keyword."); //addedbyishika
      return false;
    }

    let searchText = "";
    setdisableDate(true);
    if (isDateTimeFeild) {
      setadvSearchText(document.getElementById("txtSearchDate").value);
      searchText = document.getElementById("txtSearchDate").value;
    } else if (numericOperator) {
      if (!isNaN(document.getElementById("txtSearchNumber").value)) {
        setadvSearchText(document.getElementById("txtSearchNumber").value);
        searchText = document.getElementById("txtSearchNumber").value;
      } else if (
        document.getElementById("txtSearchNumber").value.includes(",")
      ) {
        if (
          !isNaN(
            document.getElementById("txtSearchNumber").value.replace(/,/g, "")
          )
        ) {
          setadvSearchText(document.getElementById("txtSearchNumber").value);
          searchText = document.getElementById("txtSearchNumber").value;
        } else {
          alert("Please select proper keyword.");
          return false;
        }
      } else {
        alert("Please select proper keyword.");
        return false;
      }
    } else {
      setadvSearchText(document.getElementById("txtSearchText").value);
      searchText = document.getElementById("txtSearchText").value.trim();
    }
    searchText = advSearchText === "" ? advSearchTextDate : advSearchText;
    //For Table Bind
    let maxId = advSearchDetails.reduce((max, item)=> 
      (item.id > max? item.id: max),0); 
    let condition = {
      id: maxId+1,
      searchField: advSearchField, //document.getElementById('ddlCategoryTitle').value,//
      condition: advCondition,
      searchText: searchText, //advSearchText
      searchField2: advsubmitandcontinue,
    };


    //For JSON DATA to share with API
    let mapToCondition = {
      id: maxId+1,
      searchField: advSearchMapToField, //document.getElementById('ddlCategoryTitle').value,
      condition: advCondition,
      searchText: searchText, //advSearchText
      searchField2: advsubmitandcontinue
    };
    setadvSearchDetails(advSearchDetails => [...advSearchDetails, condition]);
    setadvSearchMapToFieldDetails(advSearchMapToFieldDetails => [
      ...advSearchMapToFieldDetails,
      mapToCondition
    ]);

    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    sessionStorage.setItem(
      "" + workflowactivityName + "_mapToCondition",
      JSON.stringify(advSearchMapToFieldDetails)
    );
    sessionStorage.setItem(
      "" + workflowactivityName + "_condition",
      JSON.stringify(advSearchDetails)
    );

    setadvSearchField("");
    // console.log(advSearchField);
    setadvCondition("");
    // console.log(advCondition);
    setadvSearchText("");
    // console.log(advSearchText);
    setadvSearchMapToField("");
    setadvSearchTextDate("");
    // console.log(advSearchTextDate);
    // console.log(advSearchDetails);
  };

  const handleRowDelete = (oldData, resolve) => {
    
    //const dataDelete = [...advSearchDetails];
    //const mapDataDelete = [...advSearchMapToFieldDetails];
    const index = oldData.id;

    const dataDelete = advSearchDetails.filter((item)=> item.id !== oldData.id)
    const mapDataDelete = advSearchMapToFieldDetails.filter((item)=> item.id !== oldData.id)
    // dataDelete.splice(index-1, 1);
    // mapDataDelete.splice(index-1, 1);


    setadvSearchDetails([...dataDelete]);
    setadvSearchMapToFieldDetails([...mapDataDelete]);

    let workflowactivityName = sessionStorage.getItem("workflowactivityName");
    sessionStorage.setItem(
      "" + workflowactivityName + "_mapToCondition",
      JSON.stringify(mapDataDelete)
    );
    sessionStorage.setItem(
      "" + workflowactivityName + "_condition",
      JSON.stringify(dataDelete)
    );
    resolve();
  };

  let advanceColumns = [
    { title: "Id", field: "id", hidden: true },
    { title: "Search Field", field: "searchField" },
    { title: "Condition", field: "condition" },
    { title: "Search Text", field: "searchText" }
    //{ title: "Search Field2", field: "searchField2" }
  ];

  const ReAssignedDropdownRef = useRef(null);
  const ReAssignedWorkItemTypeRef = useRef(null);
  const ChangeStausDropdownRef = useRef(null);
  return (
    <>
      <Header
        onpreferenceShow={null}
        pageName="ActivityDashboard"
        timeCaptureActivityName="WorkActivityDashboard"
      />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="activities" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="centerSection">
            <div className="pageBody">
              <div className="dashBoxs">
                <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                  <div className="row align-items-center">
                    <div className="col-4">
                      <h6>
                        {selectedWorkItemDetails
                          ? selectedWorkItemDetails.activityName.includes(
                            "Complete"
                          )
                            ? "Complete".toUpperCase() + " DASHBOARD"
                            : selectedWorkItemDetails.activityName.includes(
                              "Exception"
                            )
                              ? "Exceptions".toUpperCase() + " DASHBOARD"
                              : selectedWorkItemDetails.activityName.toUpperCase() +
                              " DASHBOARD"
                          : sessionStorage
                            .getItem("navMenu")
                            .includes("Complete")
                            ? "Complete".toUpperCase() + " DASHBOARD"
                            : sessionStorage
                              .getItem("navMenu")
                              .includes("Exception")
                              ? "Exceptions".toUpperCase() + " DASHBOARD"
                              : sessionStorage.getItem("navMenu").toUpperCase() +
                              " DASHBOARD"}
                      </h6>
                      {/* //<h5>Processing Dashboard</h5> */}
                    </div>
                    <div className="col-8">
                      <div
                        className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                        style={{ marginTop: "-10px" }}
                      >
                        {isUplodDateTime ? (
                          <div className="d-inline">
                            <div className="input-group input-group-sm">
                              <label
                              id="lblUploadDateTime"
                                className="input-group-text"
                                for="inputGroupSelect01"
                              >
                                Upload Date & time
                              </label>
                              <DateRangePicker id = "dateRangePicker"
                                initialSettings={{
                                  autoApply: true,
                                  autoUpdateInput: false,
                                  opens: "right",
                                  timePicker: false,
                                  locale: { format: "M/DD/YY hh:mm A" }
                                }}
                                onApply={handleApplyUpload}
                              >
                                <input
                                  id  = "txtUploadDateTime"
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder="Select Date"
                                  value={
                                    uplodadedDateTimeFrom +
                                    "-" +
                                    uplodadedDateTimeTo
                                  }
                                />
                              </DateRangePicker>
                            </div>
                          </div>
                        ) : null}

                        {!(
                          sessionStorage.getItem("isSuperUser") == "false" &&
                          sessionStorage.getItem("ShowDashboard") == "true" &&
                          (sessionStorage.getItem("PersonType") == "Employee" ||
                            sessionStorage.getItem("PersonType") == "Client")
                        ) ? (
                          <>
                            <div className="d-inline">
                              <div className="input-group input-group-sm">
                                <label
                                  className="input-group-text"
                                  id="lblShow"
                                  for="inputGroupSelect01"
                                >
                                  Show
                                </label>
                                <select
                                  className="form-select font-Standard-11"
                                  id="inputGroupSelect01"
                                  onChange={DisplayCriteriaOnChange}
                                  disabled={
                                    !(
                                      sessionStorage.getItem("isSuperUser") ==
                                      "true"
                                    )
                                  }
                                >
                                  {sessionStorage.getItem("WorkitemMode") ==
                                    null ? (
                                    <>
                                      <option selected value="0">
                                        My Work Items
                                      </option>
                                      <option value="1">
                                        Other's Work Items
                                      </option>
                                      <option value="2">All Work Items</option>
                                    </>
                                  ) : +sessionStorage.getItem("WorkitemMode") ==
                                    0 ? (
                                    <>
                                      <option selected value="0">
                                        My Work Items
                                      </option>
                                      <option value="1">
                                        Other's Work Items
                                      </option>
                                      <option value="2">All Work Items</option>
                                    </>
                                  ) : +sessionStorage.getItem("WorkitemMode") ==
                                    1 ? (
                                    <>
                                      <option value="0">My Work Items</option>
                                      <option selected value="1">
                                        Other's Work Items
                                      </option>
                                      <option value="2">All Work Items</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="0">My Work Items</option>
                                      <option value="1">
                                        Other's Work Items
                                      </option>
                                      <option selected value="2">
                                        All Work Items
                                      </option>
                                    </>
                                  )}

                                  {/* <option value="3">Other's Work Items</option> */}
                                </select>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {/* {allowBulkUpdateBulk ? (
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary ms-2"
                            disabled="true"
                          >
                            <b className="icon-edit"></b> Bulk Edit
                          </button>
                        ) : null} */}
                        {/* this is for current sprit use allowBulkUpdateBulk to show and hide */}
                        {false ? (
                          <button
                            type="button"
                            id = "btnBulkEdit"
                            className="btn btn-sm btn-outline-primary ms-2"
                            disabled="true"
                          >
                            <b className="icon-edit"></b> Bulk Edit
                          </button>
                        ) : null}
                        {/* <button
                          type="button"
                          className="btn btn-sm btn-outline-primary ms-2"
                          onClick={settingShow}
                        >
                          <b className="icon-settings"></b> Options
                        </button> */}
                        {allowEmailNotification ? (
                          <button
                            type="button"
                            id = "btnNotify"
                            className="btn btn-sm btn-outline-primary ms-2"
                            onClick={notifyShow}
                          >
                            <b className="icon-bell"></b> Notify
                          </button>
                        ) : null}

                        {/* <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            getDashboardDataForExport();
                          }}
                        >
                          <b></b>Export
                        </button> */}

                        {
                        sessionStorage.getItem("isSuperUser") == "false" &&
                        sessionStorage.getItem("ShowDashboard") == "true" &&
                        (sessionStorage.getItem("PersonType") == "Employee" ||
                          sessionStorage.getItem("PersonType") == "Client") ? null : Button ? (
                          <button
                            type="button"
                            id = "btnExport"
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                              handleButtonClick();
                            }}
                          >
                            <b></b>Export
                          </button>
                        ) : null}
                        {showButton && (
                          <div className="box">
                            <ul>
                              <li
                                className="export-popup"
                                id = "exportAsCsvPopUp"
                                onClick={() => getDashboardDataForExportCSV()}
                              >
                                Export as CSV
                              </li>
                              <li
                                className="export-popup"
                                id = "exportAsExcelPopUp"
                                onClick={() => getDashboardDataForExportExcel()}
                              >
                                Export as Excel
                              </li>
                            </ul>
                          </div>
                        )}

                        <div className="">
                          {allowAdhocAdditionOfItems &&
                            workItemAllocationMode === "batch" ? (
                            <a
                              href={href}
                              id = "btnNewBatch"
                              className="btn btn-sm btn-primary ms-2"
                            >
                              <b className="icon-plus"></b>New Batch
                            </a>
                          ) : null}
                          {NewBlankWorkItem &&
                            allowAdhocAdditionOfItems &&
                            workItemAllocationMode !== "batch" ? (
                            <button
                              type="button"
                              id = "btnNewBlankWorkitem"
                              className="btn btn-sm btn-primary ms-2"
                              ref={btnref}
                              onClick={() => {
                                set_IsLoading(true);
                                createNewBlankItemDetails();
                              }}
                            >
                              <b className="icon-plus"></b>New Blank Workitem
                            </button>
                          ) : null}
                          {allowAdhocAdditionOfItemsForUpload &&
                            workItemAllocationMode !== "batch" ? (
                            <button
                              type="button"
                              id = "btnNewWorkitem"
                              className="btn btn-sm btn-primary ms-2"
                              onClick={() => {
                                createNewItemDetails();
                              }}
                            >
                              <b className="icon-plus"></b>New Workitem
                            </button>
                          ) : null}
                        </div>
                        {sessionStorage.getItem("isSuperUser") == "false" &&
                          sessionStorage.getItem("ShowDashboard") == "true" &&
                          sessionStorage.getItem("PersonType") ==
                          "Employee" ? null : ( // </button> //   <b className="icon-plus"></b>Start Work // > //   }} //     GetNextItemDetails(); //   onClick={() => { //   className="btn btn-primary" //   type="button" // <button
                          <h3>
                            {/* Row Coloring Criteria{" "} */}
                            <button
                              type="button"
                              id = "btnShowModal"
                              className={filterStyle + ""}
                              onClick={() => {
                                modalShow();
                              }}
                            >
                              <b className="icon-sliders"></b>
                            </button>
                            <span>
                              <a
                                className="resetData"
                                id = "clearFilter"
                                style={
                                  {
                                    // pointerEvents: "none",
                                    // cursor: "not-allowed",
                                  }
                                }
                                onClick={() => {
                                  PostClearFilterData("");
                                }}
                                data-for="attachmentTip"
                                data-tip
                              >
                                Clear Filter
                              </a>

                              <ReactTooltip
                                id="attachmentTip"
                                place="top"
                                effect="solid"
                              >
                                {filterValue > 0 ? (
                                  <>Last {filterValue} day's Data</>
                                ) : (
                                  "Clear Filter(Display all data)"
                                )}
                              </ReactTooltip>
                            </span>
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {isLoadingDashboard ? (
                  <LoadingSpinnerFigma />
                ) : sessionStorage.getItem("isSuperUser") == "false" &&
                  sessionStorage.getItem("ShowDashboard") == "true" &&
                  (sessionStorage.getItem("PersonType") == "Employee" ||
                    sessionStorage.getItem("PersonType") == "Client") ? null : (
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table
                        className="table table-borderless MatTable"
                        id = "tblDashboard"
                        style={{ zIndex: "1", position: "relative" }}
                      >
                        <ThemeProvider theme={theme}>
                          <MaterialTable
                            localization={{
                              pagination: {
                                //labelDisplayedRows: '{from}-{to} of {count}'
                              },
                              toolbar: {
                                // nRowsSelected: '{0} row(s) selected'
                              },
                              header: {
                                actions: ""
                              }
                            }}
                            tableRef={tableRef}
                            //filterRef={filterRef}
                            title={
                              selectedWorkItemDetails
                                ? selectedWorkItemDetails.activityName.includes(
                                  "Complete"
                                )
                                  ? "Complete".toUpperCase() + " DASHBOARD"
                                  : selectedWorkItemDetails.activityName.includes(
                                    "Exception"
                                  )
                                    ? "Exceptions".toUpperCase() + " DASHBOARD"
                                    : selectedWorkItemDetails.activityName.toUpperCase() +
                                    " DASHBOARD"
                                : sessionStorage
                                  .getItem("navMenu")
                                  .includes("Complete")
                                  ? "Complete".toUpperCase() + " DASHBOARD"
                                  : sessionStorage
                                    .getItem("navMenu")
                                    .includes("Exception")
                                    ? "Exceptions".toUpperCase() + " DASHBOARD"
                                    : sessionStorage
                                      .getItem("navMenu")
                                      .toUpperCase() + " DASHBOARD"
                            }
                            actions={[
                              {
                                icon: () => <FcSearch />,
                                id : "viewDetailIcon",
                                tooltip: "View workitem's detail",
                                onClick: (event, rowData) =>
                                  viewWorkitemShow(rowData),
                                position: "row"
                              },
                              {
                                icon: () => <FcImageFile />,
                                tooltip: "View document",
                                id : "viewDocumentIcon",
                                onClick: (event, rowData) =>
                                  documnetShow(rowData),
                                position: "row"
                              },
                              {
                                tooltip: "Actions",
                                id : "actionsIcon",
                                icon: () => (
                                  <PendingActionsIcon className="btn btn-sm btn-outline-primary" />
                                ),

                                onClick: (event, rowData) =>
                                  assignToEmployee(rowData)
                              },
                              {
                                tooltip: "Export All Selected Rows",
                                id : "exportAllSelectedRowsIcon",
                                icon: () => <CloudDownloadIcon />,
                                onClick: (event, rowData) =>
                                  exportAllSelectedRows(rowData)
                              }
                            ]}
                            options={{
                              headerStyle: { position: 'sticky', top: 0, zIndex: 1 },
                              maxBodyHeight: "420px",
                              pageSize: 20,
                              exportAllData: true,
                              showEmptyDataSourceMessage: true,
                              emptyRowsWhenPaging: false,
                              pageSizeOptions: [20, 50, 100, 200, 500, 1000],
                              debounceInterval: 700,
                              exportButton: false,
                              // exportCsv: () => {
                              //   getDashboardDataForExport();
                              // },
                              sorting: true,
                              filtering: true,
                              // filterRowStyle:{
                              //   backgroundColor: red,
                              // },
                              // filterCellStyle:{
                              //   backgroundColor: red,
                              // },
                              search: false,
                              selection: true,
                              //tableLayout: "auto",
                              overflowY: false,
                              showTitle: false,
                              toolbarButtonAlignment: "left",
                              headerStyle: {
                                //  height: 30,
                              },
                              rowStyle: {},
                              tableLayout: "fixed",
                              columnResizable: "true",
                              tableWidth: "variable",
                              
                              // actionsCellStyle:{
                              //   width: "80px",
                              //   backgroundColor: "red",
                              // },
                              // cellStyle:{
                              //   width: "100px"
                              // },
                              headerStyle: {
                                // fontFamily: '"Yantramanav", sans-serif',
                                fontWeight: "inherit"
                                // fontSize: "14px",
                                // textAlign: "left !Important",
                              }
                            }}
                            columns={columns}
                            icons={tableIcons}
                            data={getDashboardData}
                            onFilterChange={(columns, type) =>
                              handleFilterChange(columns)
                            }
                            onSelectionChange={(e, a) => {
                              handleSetSelectedRows(e);
                            }}
                            state={Gridstate}
                            components={{
                              OverlayLoading: props => <LoadingSpinnerFigma />,
                              Container: props => (
                                <Paper {...props} elevation={0} />
                              ),
                              Toolbar: props => (
                                <div
                                  style={{
                                    backgroundColor: "rgb(206 206 206)",
                                    fontSize: "0.75em"
                                  }}
                                >
                                  <MTableToolbar
                                    {...props}
                                    style={{ fontSize: "5px" }}
                                  />
                                </div>
                              )
                            }}
                          />
                        </ThemeProvider>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              {sessionStorage.getItem("isSuperUser") == "false" &&
                sessionStorage.getItem("ShowDashboard") == "true" &&
                (sessionStorage.getItem("PersonType") == "Employee" ||
                  sessionStorage.getItem("PersonType") == "Client") ? (
                <div
                  className="flex-center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh"
                  }}
                >
                  <button
                    type="button"
                    id = "btnStartWork"
                    className="btn btn-primary"
                    onClick={() => {
                      GetNextItemDetails();
                    }}
                  >
                    <b className="icon-plus"></b>Start Work
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <Footer />

          {/** NOTIFY MODAL **/}
          <Modal
            show={notifyModal}
            id = "notifyModal"
            dialogClassName="modal-25w"
            onHide={notifyClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Notify</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="timeBreak">
                <ul className="timeBreak-form">
                  <li>
                    <label className="form-label" id="lblSendTo">Send To</label>
                    <textarea className="form-control"></textarea>
                    <div class="form-text">
                      Note: Separate multiple email IDs using comma (,)
                    </div>
                  </li>
                  <li>
                    <label className="form-label" id="lblMessage" >Message</label>
                    <textarea
                      className="form-control"
                      placeholder="Type here...."
                    ></textarea>
                  </li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-outline-ntv mx-2"
                id = "btnCancelNotifyClose"
                onClick={notifyClose}
              >
                Cancel
              </button>
              <button className="btn btn-primary" id="btnSend">Send</button>
            </Modal.Footer>
          </Modal>

          {/** VIEW WORKITEM MODAL **/}
          <Modal
            show={viewWorkitemModal}
            id = "viewWorkitemModal"
            dialogClassName="modal-100w"
            onHide={viewWorkitemClose}
            fullscreen={"lg-down"}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton> WORKITEM'S DETAILS</Modal.Header>
            <Modal.Body>
              <div className="timeBreak">
                {auditTrail ? (
                  <div className="dashBoxs">
                    <div
                      className="dashBoxs-header "
                      style={{ maxHeight: "45px" }}
                    >
                      <div className="row align-items-center">
                        <div className="col-4">
                          <h6>Workitem Audit Trail</h6>
                          {/* //<h5>Processing Dashboard</h5> */}
                        </div>
                      </div>
                    </div>
                    <ul className="timeBreak-form">
                      <div className="tableWrap">
                        <div className="tableWrap-body">
                          <table
                            className="table table-borderless MatTable"
                            id="tblWorkitemAuditTrail"
                            style={{ zIndex: "1", position: "relative" }}
                          >
                            <MaterialTable
                              title="Workitem Audit Trail"
                              options={{
                                search: false,
                                overflowY: false,
                                showTitle: false,
                                cellStyle: {
                                  // width: 100,
                                  // minWidth: 100,
                                  whiteSpace: "Normal"
                                },
                                headerStyle: {
                                  position: "sticky"
                                  // top: "-5px",
                                },
                                rowStyle: {
                                  fontFamily: '"Yantramanav", sans-serif'
                                }
                              }}
                              columns={listWorkItemHistoryColumns}
                              icons={tableIcons}
                              data={workitemHistory}
                              components={{
                                Container: props => (
                                  <Paper {...props} elevation={0} />
                                ),
                                Toolbar: props => (
                                  <div
                                    style={{
                                      backgroundColor: "rgb(206 206 206)",
                                      fontSize: "0.75em"
                                    }}
                                  >
                                    <MTableToolbar
                                      {...props}
                                      style={{ fontSize: "5px" }}
                                    />
                                  </div>
                                )
                              }}
                            />
                          </table>
                        </div>
                      </div>
                    </ul>
                  </div>
                ) : (
                  <></>
                )}

                <div className="dashBoxs">
                  <div
                    className="dashBoxs-header "
                    style={{ maxHeight: "45px" }}
                  >
                    <div className="row align-items-center">
                      <div className="col-4">
                        <h6>Asignment Details</h6>
                        {/* //<h5>Processing Dashboard</h5> */}
                      </div>
                    </div>
                  </div>
                  <ul className="timeBreak-form">
                    <div className="tableWrap">
                      <div className="tableWrap-body">
                        <table
                          className="table table-borderless MatTable"
                          id="tblAssignmentDetails"
                          style={{ zIndex: "1", position: "relative" }}
                        >
                          <MaterialTable
                            title="Asignment Details"
                            options={{
                              search: false,
                              overflowY: false,
                              showTitle: false,
                              cellStyle: {
                                // width: 100,
                                // minWidth: 100,
                                whiteSpace: "Normal"
                              },
                              headerStyle: {
                                position: "sticky"
                                // top: "-5px",
                              },
                              rowStyle: {
                                fontFamily: '"Yantramanav", sans-serif'
                              }
                            }}
                            columns={asignmentdetailsColumns}
                            icons={tableIcons}
                            data={workitemDetails}
                            components={{
                              Container: props => (
                                <Paper {...props} elevation={0} />
                              ),
                              Toolbar: props => (
                                <div
                                  style={{
                                    backgroundColor: "rgb(206 206 206)",
                                    fontSize: "0.75em"
                                  }}
                                >
                                  <MTableToolbar
                                    {...props}
                                    style={{
                                      fontSize: "5px"
                                    }}
                                  />
                                </div>
                              )
                            }}
                          />
                        </table>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/** VIEW DOCUMENT MODAL **/}
          <Modal
            show={viewDocumentModal}
            id = "viewDocumentModal"
            dialogClassName="modal-100w"
            onHide={viewDoucmentClose}
            fullscreen={"lg-down"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>WORKITEM'S DOCUMENT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="timeBreak">
                <ul className="timeBreak-form">
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table className="table brandTable" id="tblBrand">
                        <div>
                          <Iframe
                            url={imageUrl}
                            width="1150px"
                            height="450px"
                            id="myId"
                            display="initial"
                            position="relative"
                          />
                        </div>
                      </table>
                    </div>
                  </div>
                </ul>
              </div>
            </Modal.Body>
          </Modal>

          {/** ASSIGN & CHANGE STATUS MODAL **/}
          <Modal
            show={settingModal}
            id = "assignAndChangeStatusModal"
            dialogClassName="modal-25w"
            onHide={settingClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Status or Re-assign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="splitfull b_0">
                {allowStatusChange ? (
                  <>
                    <form onSubmit={statusChangeSubmit} id ="statusChangeForm">
                      <div className="fieldSet mb-4">
                        <ul className="timeBreak-form">
                          <li>
                            <label className="form-label" id="lblChangeStatus">Change Status</label>
                            <select
                              className="form-control form-control-sm"
                              id="ddlChangeStatus"
                              onChange={statusForStatusChange}
                              ref={ChangeStausDropdownRef}
                            >
                              <option value={0}>Select</option>
                              {workItemStatus.map(status => (
                                <option
                                  value={
                                    status.workItemStatusID +
                                    "|" +
                                    status.statusName
                                  }
                                >
                                  {status.statusName}
                                </option>
                              ))}
                            </select>
                          </li>
                          {assignTo ? (
                            <li>
                              <label className="form-label" id="lblAssignTo">Assign To</label>
                              {IsAssignedToRequired ? (
                                <span
                                  id=""
                                  className="mandatory"
                                  style={{ color: "#ff0000" }}
                                >
                                  *
                                </span>
                              ) : null}
                              <select
                                className="form-control form-control-sm"
                                id = "ddlEmployeeAssigned"
                                onChange={employeeAssignedForStatusChange}
                              >
                                <option>Select</option>
                                {employeesForSubprocessActivity.map(
                                  employee => (
                                    <option value={employee.employeeVersionID}>
                                      {employee.nameEmployeeID}
                                    </option>
                                  )
                                )}
                              </select>
                            </li>
                          ) : null}
                          {isReason ? (
                            <li>
                              <label className="form-label" id="lblStatusReason">
                                Status Reason
                              </label>
                              {isReasonshow ? (
                                <span
                                  id=""
                                  className="mandatory"
                                  style={{ color: "#ff0000" }}
                                >
                                  *
                                </span>
                              ) : null}
                              <select
                                multiple
                                className="form-control form-control-sm"
                                id = "ddlReasonForStatusChange"
                                onChange={reasonForStatusChange}
                              >
                                {statusReasonForSubprocess.map(reason => (
                                  <option value={reason.statusReasonID}>
                                    {reason.reason}
                                  </option>
                                ))}
                              </select>
                            </li>
                          ) : null}

                          {/* {isComment ? ( */}
                          <li>
                            <div className="row">
                              <div
                                style={{ width: "400px" }}
                                className="col-md-6"
                              >
                                Status Comment
                                {isComment ? (
                                  <span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span>
                                ) : null}
                                &nbsp;&nbsp;
                                {/* added by Santosh */}
                                {userNotes ? (
                                  <label className="checkbox-inline" id="lblChkInternalUserNotes">
                                    <input
                                      className="form-check-input"
                                      onChange={checkHandler}
                                      checked={isChecked}
                                      id="chkInternalUserNotes"
                                      type="checkbox"
                                    />
                                    &nbsp;&nbsp;Internal
                                  </label>
                                ) : null}
                              </div>
                            </div>
                            <textarea
                              className="form-control"
                              placeholder="Type here...."
                              onChange={commentForStatusChange}
                              value={statusComment}
                              id = "txtCommentForStatusChange"
                            ></textarea>
                          </li>
                          {/* ) : null} */}
                        </ul>
                      </div>
                      <div className="col-12 text-end">
                        <button type="submit" className="btn btn-primary" id = "btnSubmit">
                          Submit
                        </button>
                      </div>
                    </form>
                    <hr />
                  </>
                ) : null}
                <form onSubmit={assignEmployee}>
                  <div className="fieldSet mb-4">
                    <h4 className="mb-2">Re-Assign</h4>
                    {allowAssignment ? (
                      <ul className="timeBreak-form">
                        <li>
                          <select
                            className="form-control form-control-sm"
                            onChange={assignedEmployee}
                            ref={ReAssignedDropdownRef}
                            id = "ddlAssignEmployee"
                          >
                            <option value={0}>Select Employee to Assign</option>
                            {employeesToAssign.map(employee => (
                              <option value={employee.employeeVersionID}>
                                {employee.nameEmployeeID}
                              </option>
                            ))}
                          </select>
                        </li>
                      </ul>
                    ) : null}
                  </div>
                  <div className="col-12 text-end">
                    <button className="btn btn-primary" type="submit" id="btnAssign">
                      Assign
                    </button>
                  </div>
                </form>
                <hr / >
                {typeConfigs ? (
                // <form onSubmit={assignworkItemType}>
                <form>
                  <div className="fieldSet mb-4">
                    <h4 className="mb-2">Workitem Type</h4>
                    {allowAssignment ? (
                      <ul className="timeBreak-form">
                        <li>
                          <select
                            className="form-control form-control-sm"
                            onChange={workItemTypeChange}
                            ref={ReAssignedWorkItemTypeRef}
                          >
                            {/* <option value={0}>Select Workitem Type</option>
                            {employeesToAssign.map(employee => (
                              <option value={employee.employeeVersionID}>
                                {employee.nameEmployeeID}
                              </option> */}
                              
                              <option value={0}>Select Workitem Type</option>
                                {typeConfigs.map(workItem => (
                                  <option
                                    value={
                                      workItem.workItemTypeID +
                                      "|" +
                                      workItem.workItemType
                                    }
                                    // selected={
                                    //   workItem.workItemTypeID ==
                                    //   ddlWorkItemTypeValue
                                    // }
                                  >
                                    {workItem.workItemType}
                                  </option>
                            ))}
                          </select>
                        </li>
                      </ul>
                    ) : null}
                  </div>
                  <div className="col-12 text-end">
                    {WorkitemtypeButton==false  ?(
                      <div>
                        <li className="btn btn-primary"
                          onClick={() => SetWorkitemType('Yes')} >
                          Yes
                        </li>&nbsp;
                        <li className="btn btn-primary"
                          onClick={() => SetWorkitemType('No')} >
                          No
                        </li>&nbsp;
                        <li className="btn btn-primary"
                          onClick={() => SetWorkitemType('Cancel')} >
                          Cancel
                        </li> 
                      </div> 
                    ):null}
                    {WorkitemtypeButton ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          SetWorkitemtypeClick();
                        }}
                      >
                        <b></b>Set Workitem Type
                      </button>
                    ) : null}
                    {/* <button className="btn btn-primary" type="submit">
                      Set Workitem Type
                    </button> */}
                  </div>
                </form>
                ) : null}
              </div>
            </Modal.Body>
          </Modal>
          {/** FILTER & SORT MODAL **/}
          <Modal
            show={filterModal}
            id = "filterAndSortModal"
            dialogClassName="modal-25w"
            onHide={filterClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Filter & Sort</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="filterWrap">
                <div className="pb-2 pt-2">
                  <h4>Filter</h4>
                  <div class="col-md-5">
                    <label className="form-label form-check form-switch mb-1 pb-1" id="lblCheckDefinedFields">
                      {" "}
                      Include System Defined Fields
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        onChange={ModeOnChange}
                        checked={IsCustomField == "1" ? true : false}
                      />
                    </label>
                  </div>

                  <div className="row align-items-end">
                    <div className="col-6">
                      <div className="mb-3">
                        <label className="form-label" id="lblCategoryTitle">Category Title</label>
                        <select
                          id="ddlCategoryTitle"
                          className="form-control form-control-sm"
                          title="Select a field to apply filter"
                          onChange={getAdvanceField}
                          value={advSearchField == "" ? "Select" : null}
                        >
                          <option value={"Select"}>Select</option>
                          {searchItemFilterFields.map(status => (
                            <option
                              value={
                                status.mapToField +
                                "~" +
                                status.dataType +
                                "/" +
                                status.labelText +
                                "$" +
                                status.maptoFieldAndDataType
                              }

                            // selected={status.mapToField===advSearchField?true:false}
                            >
                              {status.labelText}
                            </option>
                          ))}
                          <option
                            value={
                              "IsAssigned~System.String/Assigned$p.name~System.String"
                            }
                          >
                            Assigned
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label className="form-label" id="lblOperator">Operator</label>
                        {numericOperator ? (
                          <select
                          id="ddlOperator"
                            className="form-control form-control-sm"
                            title="Select a field to apply filter"
                            onChange={getAdvanceCondition}
                            value={isDefaultValue ? "Select" : advCondition}
                          >
                            <option value="Select">Select</option>
                            <option value="=">=</option>
                            <option value="<>">{notEqual}</option>
                            <option value="<">{lessThan}</option>
                            <option value="<=">{lessThanEqual}</option>
                            <option value=">">{greaterThen}</option>
                            <option value=">=">{greaterThenEqual}</option>
                          </select>
                        ) : isDateField ? (
                          <select
                            className="form-control form-control-sm"
                            id = "ddlAdvanceCondition"
                            title="Select a field to apply filter"
                            onChange={getAdvanceCondition}
                            value={isDefaultValue ? "Select" : advCondition}
                          >
                            <option value="Select">Select</option>
                            <option value="Equals">Equals</option>
                            <option value="Before">Before</option>
                            <option value="After">After</option>
                            <option value="Tomorrow">Tomorrow</option>
                            <option value="Today">Today</option>
                            <option value="Yesterday">Yesterday</option>
                            <option value="NextWeek">NextWeek</option>
                            <option value="ThisWeek">ThisWeek</option>
                            <option value="LastWeek">LastWeek</option>
                            <option value="NextMonth">NextMonth</option>
                            <option value="ThisMonth">ThisMonth</option>
                            <option value="LastMonth">LastMonth</option>
                            <option value="NextQuarter">NextQuarter</option>
                            <option value="ThisQuarter">ThisQuarter</option>
                            <option value="LastQuarter">LastQuarter</option>
                            <option value="NextYear">NextYear</option>
                            <option value="ThisYear">ThisYear</option>
                            <option value="LastYear">LastYear</option>
                            <option value="YearToDate">YearToDate</option>
                            <option value="IsNull">IsNull</option>
                            <option value="IsNotNull">IsNotNull</option>
                          </select>
                        ) : (
                          <select
                            id="ddlWordOpertator"
                            className="form-control form-control-sm"
                            title="Select a field to apply filter"
                            onChange={getAdvanceCondition}
                            value={isDefaultValue ? "Select" : advCondition}
                          >
                            <option value="Select">Select</option>
                            <option value="Equals">Equals</option>
                            <option value="Begin with">Begin with</option>
                            <option value="Contains">Contains</option>
                            <option value="End with">End with</option>
                            <option value="Does Not Contain">
                              Does Not Contain
                            </option>
                          </select>
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label className="form-label" id="lblKeyword">Keyword</label>
                        {isDateField ? (
                          <input
                            id="txtSearchDate"
                            type="date"
                            className="form-control form-control-sm"
                            disabled={disableDate}
                            onChange={getAdvanceDateText}
                            value={advSearchTextDate}
                          />
                        ) : numericOperator ? (
                          <input
                            id="txtSearchNumber"
                            type="int"
                            className="form-control form-control-sm"
                            onChange={getAdvanceText}
                            value={advSearchText}
                          />
                        ) : (
                          <input
                            id="txtSearchText"
                            type="text"
                            className="form-control form-control-sm"
                            onChange={getAdvanceText}
                            value={advSearchText}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <button
                          className="btn btn-sm btn-outline-primary w-40 text-uppercase"
                          id="btnAddAdvanceFilterSearchConditions"
                          onClick={addAdvanceFilterSearchConditions}
                        >
                          Add
                        </button>
                      </div>
                    </div>

                    <div className="pt-2 pb-2">
                      <div className="mb-5">
                        <div className="tableWrap">
                          <div className="tableWrap-body">
                            <table
                              className="table table-borderless MatTable"
                              id = "tblAdvanceFilter"
                              style={{ zIndex: "1", position: "relative" }}
                            >
                              <ThemeProvider theme={theme}>
                                <MaterialTable
                                  localization={{
                                    pagination: {
                                      //labelDisplayedRows: '{from}-{to} of {count}'
                                    },
                                    toolbar: {
                                      // nRowsSelected: '{0} row(s) selected'
                                    },
                                    header: {
                                      actions: ""
                                    }
                                  }}
                                  options={{
                                    search: false,
                                    // selection: true,
                                    tableLayout: "auto",
                                    overflowY: false,
                                    showTitle: false,
                                    toolbarButtonAlignment: "left",
                                    paging: false,
                                    toolbar: false,
                                    headerStyle: {
                                      height: 30
                                    },
                                    rowStyle: {
                                      fontFamily: '"Yantramanav", sans-serif'
                                    },
                                    tableLayout: "auto",
                                    headerStyle: {}
                                  }}
                                  editable={{
                                    onRowDelete: oldData =>
                                      new Promise(resolve => {
                                        handleRowDelete(oldData, resolve);
                                      })
                                  }}
                                  columns={advanceColumns}
                                  icons={tableIcons}
                                  data={advSearchDetails}
                                />
                              </ThemeProvider>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 pt-4 border-top">
                  <h4>Sort</h4>
                  <div className="form-check form-check-inline mx-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="Desc"
                      //checked
                      checked={checkedValue === "Desc" ? "Desc" : null}
                      onChange={getPreference}
                    />
                    <label className="form-label" for="inlineRadio1" id="lblDesc">
                      Desc
                    </label>
                  </div>
                  <div className="form-check form-check-inline ms-4 mx-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="Asc"
                      checked={checkedValue === "Asc" ? "Asc" : null}
                      onChange={getPreference}
                    />
                    <label className="form-label" for="inlineRadio2" id="lblAsc">
                      Asc
                    </label>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-6">
                      <div>
                        <label className="form-label" id="lblPrimaryCategoryTitle">
                          Primary Category Title
                        </label>
                        <select
                          id="ddlPrimaryFilter"
                          className="form-control form-control-sm"
                          title="Select a field to apply filter"
                          onChange={getAdvanceField}
                        >
                          <option>Select</option>
                          {searchItemFields.map(status => (
                            <option
                              value={status.labelText + "/" + status.mapToField}
                              selected={
                                status.labelText ==
                                sessionStorage.getItem(
                                  sessionStorage.getItem(
                                    "workflowactivityName"
                                  ) + "_sortColumnPrimary"
                                )
                              }
                            >
                              {status.labelText}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <label className="form-label" id="lblSecondaryCategoryTitle">
                          Secondary Category Title
                        </label>
                        <select
                          id="ddlSecondaryFilter"
                          className="form-control form-control-sm"
                          title="Select a field to apply filter"
                          onChange={getAdvanceField}
                        >
                          <option>Select</option>
                          {searchItemFields.map(status => (
                            <option
                              //value={status.labelText}
                              value={status.labelText + "/" + status.mapToField}
                              selected={
                                status.labelText ==
                                sessionStorage.getItem(
                                  sessionStorage.getItem(
                                    "workflowactivityName"
                                  ) + "_sortColumnSecondary"
                                )
                              }
                            >
                              {status.labelText}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-outline-ntv mx-2"
                id = "btnCancelFilterClose"
                onClick={filterClose}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  ApplyFilter(advSearchDetails);
                }}
                id="btnApply"
                className="btn btn-primary"
              >
                Apply
              </button>
            </Modal.Footer>
          </Modal>

          {/* Color Criteria offcanvas */}
          <div
            class="offcanvas offcanvas-top"
            tabindex="-1"
            id="offcanvasColor"
            aria-labelledby="offcanvasColorLabel"
            style={{ "--bs-offcanvas-height": "25vh" }}
          >
            <div
              class="offcanvas-header"
              style={{ "--bs-offcanvas-padding-y": "0.5rem" }}
            >
              <h5 id="offcanvasColorLabel">Dot Coloring Criteria</h5>
              <button
                type="button"
                id = "btnClose"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <div class="">
                <div class="row">
                  <div class="col">
                    <span
                      style={{ backgroundColor: "#3CB371" }}
                      class="dot"
                    ></span>{" "}
                    = Is Work In Progress
                  </div>
                  <div class="col">
                    <span
                      style={{ backgroundColor: "#80daeb" }}
                      class="dot"
                    ></span>{" "}
                    = Is assigned to Others
                  </div>
                  <div class="col">
                    <span
                      style={{ backgroundColor: "#FFBB33" }}
                      class="dot"
                    ></span>{" "}
                    = Is assigned to you and is not a rework
                  </div>
                  <div class="col">
                    <span
                      style={{ backgroundColor: "#6D77B5" }}
                      class="dot"
                    ></span>{" "}
                    = Is not assigned to you but is a rework
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span
                      style={{ backgroundColor: "#007FFF" }}
                      class="dot"
                    ></span>{" "}
                    = Is not assigned to you and is not a rework
                  </div>

                  <div class="col">
                    <span
                      style={{ backgroundColor: "#ffff00" }}
                      class="dot"
                    ></span>{" "}
                    = Is assigned to you and is a rework
                  </div>
                  <div class="col">
                    <span
                      style={{ backgroundColor: "#FF00EA" }}
                      class="dot"
                    ></span>{" "}
                    = Is priority
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Processing;

import React, { useState } from "react";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

import pdfViewer from '../../../../assets/images/placeholder/pdf_viewer.jpg';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const Process = () => {


    return (
        <>
            <Header />
            <Nav />
            <div className="centerSection">
                <div className="splitScreen">
                    <div className="splitLeft">
                        <div className="fieldBody">
                            <h3 className="mb-4 pt-2 pb-3 px-2">Processing Screen</h3>
                            <div className="fieldSet mb-4">
                                <h4 className="mb-2">Onbase Document Upload</h4>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Document Type</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Upload Document</label>
                                            <input className="form-control  form-control-sm" type="file" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="fieldSet mb-4">
                                <h4 className="mb-2">Vendor Details</h4>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Onbase Doc. ID</label>
                                            <input className="form-control  form-control-sm" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Scan Timestamp</label>
                                            <input className="form-control  form-control-sm" type="text" />
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Vendor Code</label>
                                            <input className="form-control  form-control-sm" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">PO Number</label>
                                            <input className="form-control  form-control-sm" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Payment Terms</label>
                                            <input className="form-control  form-control-sm" type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="fieldSet mb-4">
                                <h4 className="mb-2">Invoice Details</h4>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Invoice Number</label>
                                            <input className="form-control  form-control-sm" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Invoice Date</label>

                                            <DateRangePicker initialSettings={{ autoApply:true, opens:'right' }} >
                                            <input type="text" className="form-control form-control-sm" />
                                        </DateRangePicker>

                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Invoice Amount</label>
                                            <input className="form-control  form-control-sm" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Discount Amount</label>
                                            <input className="form-control  form-control-sm" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Amount Paid</label>
                                            <input className="form-control  form-control-sm" type="text" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Due Date</label>
                                            <DateRangePicker initialSettings={{ autoApply:true, opens:'right' }} >
                                            <input type="text" className="form-control form-control-sm" />
                                        </DateRangePicker>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Currency</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">ERP Voucher Number</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="fieldSet mb-4">
                                <h4 className="mb-2">Upload Files</h4>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Upload Document</label>
                                            <input className="form-control  form-control-sm" type="file" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="fieldSet mb-4">
                                <h4 className="mb-2">Status</h4>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Status</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="pb-3">
                                            <label className="form-label">Comment</label>
                                            <textarea className="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fieldFooter">
                            <div className="row">
                                <div className="col-6 text-start">
                                    <button className="btn btn-outline-ntv ">Back to dashboard</button>
                                    <button className="btn btn-link ms-3">Skip</button>
                                </div>
                                <div className="col-6 text-end">
                                    <button className="btn btn-link text-primary">Save Data</button>
                                    <button className="btn btn-outline-primary ms-3">Submit & Continue</button>
                                    <button className="btn btn-primary ms-3">Submit</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="splitRight">
                        <img src={pdfViewer} alt="#" />
                    </div>
                </div>
            </div>
            <Footer />




        </>
    )
};

export default Process;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import DashboardContext from "../../../../contexts/DashboardContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";

import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import { Paper } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Modal from "react-bootstrap/Modal";

function SubProcessTaxonomyMappingConfig() {
    const { addToast } = useToasts();

    const { REACT_APP_API_URL } = process.env;
    const { REACT_APP_FetchTaxonomyMappingListAsync } = process.env;
    const { REACT_APP_FetchTaxonomyMappingListByID } = process.env;
    const { REACT_APP_DeleteTaxonomyMapping } = process.env;
    const { REACT_APP_SaveTaxonomyMapping } = process.env;
    const { REACT_APP_UpdateTaxonomyMapping } = process.env;
    

    let navigate = useNavigate();
    const location = useLocation();
    const processingDashboardParam = location.state;
    const userRoles = processingDashboardParam.userRoles;
    let navData = [];
    if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
        navData = processingDashboardParam.assignedWorkItemDetails;
    } else {
        navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
    }

    const [taxonomyList, setTaxonomyList] = useState([]);
    const [ taxonomyEditableData, setTaxonomyEditableData ] = useState([]);
    const [openPopUp, setopenPopUp] = useState(false);
    const [EditPopUp, setEditPopUp] = useState(false);
    const [saveTaxonomyCheck, setsaveTaxonomyCheck] = useState("");
    const [updateTaxonomyCheck , setupdateTaxonomyCheck ] = useState("");

    //For Add Modal
    const [ processCode, setprocessCode ] = useState("");
    const [ processName, setprocessName ] = useState("");
    const [ subProcessCode, setsubProcessCode ] = useState("");
    const [ subProcessName, setsubProcessName ] = useState("");
    const [ workItemTypeCode, setworkItemTypeCode ] = useState("");
    const [ workItemType, setworkItemType ] = useState("");
    const [ isExcluded, setisExcluded ] = useState(false);

    //For Edit Modal
    const [ editprocessCode, seteditprocessCode ] = useState("");
    const [ editprocessName, seteditprocessName ] = useState("");
    const [ editsubProcessCode, seteditsubProcessCode ] = useState("");
    const [ editsubProcessName, seteditsubProcessName ] = useState("");
    const [ editworkItemTypeCode, seteditworkItemTypeCode ] = useState("");
    const [ editworkItemType, seteditworkItemType ] = useState("");
    const [ editisExcluded, seteditisExcluded ] = useState(false);

    useEffect(() => {
        FetchTaxonomyMappingListOnPageLoad();
    }, []);

    const columns = [
        {
            title: "Process Code",
            field: "processCode",
        },
        {
            title: "Process Name",
            field: "processName",
        },
        {
            title: "SubProcess Code",
            field: "subProcessCode",
        },
        {
            title: "SubProcess Name",
            field: "subProcessName",
        },
        {
            title: "WorkItem Type Code",
            field: "workItemTypeCode",
        },
        {
            title: "WorkItem Type",
            field: "workItemType",
        },
        {
            title: "Updated On",
            field: "updatedOn",
        },
        {
            title: "Is Excluded",
            field: "isExcluded",
        }
    ]

    const getAPIURL = (api) => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
    };

    const pop = () => {
        setopenPopUp(true);
        setprocessCode("");
        setprocessName("");
        setsubProcessCode("");
        setsubProcessName("");
        setworkItemTypeCode("");
        setworkItemType("");
        setisExcluded(false);
        setsaveTaxonomyCheck("");
    }

    const popupClose= () => {
        setopenPopUp(false);
        FetchTaxonomyMappingListOnPageLoad();
    }

    const EditpopupClose = () => {
        setEditPopUp(false);
        seteditprocessCode("");
        seteditprocessName("");
        seteditsubProcessCode("");
        seteditsubProcessName("");
        seteditworkItemTypeCode("");
        seteditworkItemType("");
        seteditisExcluded(false);
        FetchTaxonomyMappingListOnPageLoad();
        setupdateTaxonomyCheck("");
    }
    const downloadExcel = () => {
        const exportType = "xlsx";
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
    
        const filteredColumn = taxonomyList.map(item => ({
          
            "Process Code": item.processCode,
            "Process Name": item.processName,
            "SubProcess Code": item.subProcessCode,
            "SubProcess Name": item.subProcessName,
            "WorkItemType Code": item.workItemTypeCode,
            "WorkItemType": item.workItemType,
            "Created On": item.updatedOn,
            "Is Excluded": item.isExcluded
        }));
     
          const fileName = "ListofSubProcessWorkActivityConfigs";
          const ws = XLSX.utils.json_to_sheet(filteredColumn);
          const wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
          const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, fileName + fileExtension);
      };

    const FetchTaxonomyMappingListOnPageLoad =  () => {
        let body = {

        };

        let loginUrl = getAPIURL(REACT_APP_FetchTaxonomyMappingListAsync);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setTaxonomyList(result.data);
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };

    const editTaxonomyMapping = (row) => {

        let body = {
            taxonomyMappingID : row.taxonomyMappingID
        }
        let activityUrl = getAPIURL(REACT_APP_FetchTaxonomyMappingListByID);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            
            if (result.status == 200) {
              setTaxonomyEditableData(result.data)
              seteditprocessCode(result.data[0].processCode)
              seteditprocessName(result.data[0].processName)
              seteditsubProcessCode(result.data[0].subProcessCode)
              seteditsubProcessName(result.data[0].subProcessName)
              seteditworkItemTypeCode(result.data[0].workItemTypeCode)
              seteditworkItemType(result.data[0].workItemType)
              seteditisExcluded(result.data[0].isExcluded)
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });

    }

    const DeleteTaxonomyMapping = (row) => {
        
        const confirmation = window.confirm("AAre you sure want to delete the Taxonomy Mapping Config?")
        if (confirmation === true) {
        let body = {
            
            taxonomyMappingID : row.taxonomyMappingID,
            updatedBy: sessionStorage.getItem("PersonID"),
        }
        let activityUrl = getAPIURL(REACT_APP_DeleteTaxonomyMapping);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            
            if (result.status == 200) {
              addToast("Taxonomy Mapping Config Deleted Successfully.", {
                appearance: "success",
                autoDismiss: true,
              })
            }
            FetchTaxonomyMappingListOnPageLoad();
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      }
    };

    const clearInputValues = () => {
        setprocessCode("");
        setprocessName("");
        setsubProcessCode("");
        setsubProcessName("");
        setworkItemTypeCode("");
        setworkItemType("");
    }

    const clearEditInputValues = () => {
        seteditprocessCode("");
        seteditprocessName("");
        seteditsubProcessCode("");
        seteditsubProcessName("");
        seteditworkItemTypeCode("");
        seteditworkItemType("");
    }

    const onChangeIsExcluded = (event) => {
        setisExcluded(event.target.checked)
    }

    const onChangeEditIsExcluded = (event) => {
        seteditisExcluded(event.target.checked)
    }

    const saveTaxonomyMapping = () => {
        let error = {};
        let save = true;
        
        if (processCode === ""){
            error.processCode="Process Code is required."
            save = false;
          }
          if (processName === ""){
            error.processName="Process Name is required."
            save = false;
          }
          if (subProcessCode === ""){
            error.subProcessCode="SubProcess Code is required."
            save = false;
          }
          if (subProcessName === ""){
            error.subProcessName="SubProcess Name is required."
            save = false;
          }
          if (workItemTypeCode === ""){
            error.workItemTypeCode="WorkItemType Code is required."
            save = false;
          }
          if (workItemType === ""){
            error.workItemType="WorkItemType is required."
            save = false;
          }

          const validateField = (value, fieldName) => {
            const regex = /^[a-zA-Z0-9 ]*$/;
            if (!regex.test(value)) {
              error[fieldName] = "Special Characters Not Allowed"
              save = false;
            }
          }
          validateField(processCode,"processCode");
          validateField(processName,"processName");
          validateField(subProcessCode,"subProcessCode");
          validateField(subProcessName,"subProcessName");
          validateField(workItemTypeCode,"workItemTypeCode");
          validateField(workItemType,"workItemType");

          setsaveTaxonomyCheck({...error});
          if (save){
        let body = {
            processCode: processCode,
            processName: processName,
            subProcessCode: subProcessCode,
            subProcessName: subProcessName,
            workItemTypeCode: workItemTypeCode,
            workItemType: workItemType,
            updatedOn: new Date().toISOString(),
            updatedBy: sessionStorage.getItem("PersonID"),
            isExcluded: isExcluded
        }
        let activityUrl = getAPIURL(REACT_APP_SaveTaxonomyMapping);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            
            if (result.status == 200) {
              popupClose();
              addToast("Taxonomy Mapping Config Saved Successfully.", {
                appearance: "success",
                autoDismiss: true,
              })
            }
            FetchTaxonomyMappingListOnPageLoad();
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      
    }};

    const updateTaxonomyMapping = () => {
        let error = {};
        let save = true;
        if (editprocessCode === ""){
            error.editprocessCode="Process Code is required."
            save = false;
          }
          if (editprocessName === ""){
            error.editprocessName="Process Name is required."
            save = false;
          }
          if (editsubProcessCode === ""){
            error.editsubProcessCode="SubProcess Code is required."
            save = false;
          }
          if (editsubProcessName === ""){
            error.editsubProcessName="SubProcess Name is required."
            save = false;
          }
          if (editworkItemTypeCode === ""){
            error.editworkItemTypeCode="WorkItemType Code is required."
            save = false;
          }
          if (editworkItemType === ""){
            error.editworkItemType="WorkItemType is required."
            save = false;
          }

          const validateField = (value, fieldName) => {
            const regex = /^[a-zA-Z0-9 ]*$/;
            if (!regex.test(value)) {
              error[fieldName] = "Special Characters Not Allowed"
              save = false;
            }
          }
          validateField(editprocessCode,"editprocessCode");
          validateField(editprocessName,"editprocessName");
          validateField(editsubProcessCode,"editsubProcessCode");
          validateField(editsubProcessName,"editsubProcessName");
          validateField(editworkItemTypeCode,"editworkItemTypeCode");
          validateField(editworkItemType,"editworkItemType");

          setupdateTaxonomyCheck({...error});
          if (save){
        let body = {
            taxonomyMappingId: taxonomyEditableData[0].taxonomyMappingID,
            processCode: editprocessCode,
            processName: editprocessName,
            subProcessCode: editsubProcessCode,
            subProcessName: editsubProcessName,
            workItemTypeCode: editworkItemTypeCode,
            workItemType: editworkItemType,
            updatedOn: new Date().toISOString(),
            updatedBy: sessionStorage.getItem("PersonID"),
            isExcluded: editisExcluded
        }
        let activityUrl = getAPIURL(REACT_APP_UpdateTaxonomyMapping);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            
            if (result.status == 200) {
              EditpopupClose();
              addToast("Taxonomy Mapping Config Updated Successfully.", {
                appearance: "success",
                autoDismiss: true,
              })
            }
            FetchTaxonomyMappingListOnPageLoad();
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      
    }}


    return (
        <>
            <Header onpreferenceShow={null} pageName="Sub-Process Taxonomy Mapping Config" />
            <ApplicationUserContext.Provider value={processingDashboardParam.userRoles}>
                <DashboardContext.Provider value={processingDashboardParam.nav}>
                    <NavDataContext.Provider value={navData}>
                        <Nav selectedMenu="admin" />
                    </NavDataContext.Provider>
                </DashboardContext.Provider>
            </ApplicationUserContext.Provider>

            <div className="centerSection">
                <div className="pageBody">

                    <div className="dashBoxs">
                        <div className="dashBoxs-header dashBoxs-header-MaxHeight">
                            <div className="row align-items-center">
                                <div className="col-4">
                                    <h6>Sub-Process Taxonomy Mapping List</h6>
                                    { }
                                </div>
                            </div>
                        </div>
                        {(
                            <div className="tableWrap">
                                <div className="tableWrap-body">
                                    <table className="table table-borderless MatTable">
                                        {/* <ThemeProvider theme={theme}> */}
                                        <MaterialTable
                                            localization={{
                                                pagination: {
                                                    //labelDisplayedRows: '{from}-{to} of {count}'
                                                },
                                                toolbar: {
                                                    // nRowsSelected: '{0} row(s) selected'
                                                    // exportCSVName: "Export as Excel",
                                                    // exportPDFName: "Export as pdf",
                                                },
                                                header: {
                                                    actions: "",
                                                },
                                            }}
                                            actions={[
                                                {
                                                    icon: () => <EditIcon />,
                                                    tooltip: "Click here to edit Taxonomy-Mapping configuration",
                                                    onClick: (event, rowData) => {
                                                        setEditPopUp(true);
                                                        editTaxonomyMapping(rowData);
                                                        //setbuttonText("Update");
                                                    },
                                                    position: "row",
                                                },
                                                {
                                                    icon: () => <DeleteIcon />,
                                                    tooltip: "Click here to delete Taxonomy-Mapping configuration",
                                                    onClick: (event, rowData) =>
                                                        DeleteTaxonomyMapping(rowData),
                                                    position: "row",
                                                }
                                            ]}
                                            options={{
                                                headerStyle: {
                                                    position: "sticky",
                                                    top: "-5px",
                                                },
                                                maxBodyHeight: "420px",
                                                //pagination:true,
                                                //paginationType:"normal",
                                                //paginationPosition: "bottom",
                                                pageSize: 10,
                                                pageSizeOptions: [5, 10, 20, 50, 100],
                                                exportButton: false,
                                                sorting: true,
                                                filtering: true,
                                                search: false,
                                                selection: false,
                                                //paging: false,

                                                overflowY: false,
                                                showTitle: false,
                                                toolbarButtonAlignment: "left",
                                                headerStyle: {
                                                    // height: 30,
                                                },
                                                rowStyle: {
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    //                            fontSize: "13px",
                                                    // whiteSpace: "nowrap",
                                                },
                                                headerStyle: {
                                                    // fontFamily: '"Yantramanav", sans-serif',
                                                    // fontWeight: "inherit",
                                                    // fontSize: "14px",
                                                    // textAlign: "left !Important",
                                                },
                                            }}

                                            columns={columns}
                                            icons={tableIcons}
                                            data={taxonomyList}
                                            components={{
                                                Container: (props) => (
                                                    <Paper {...props} elevation={0} />
                                                ),
                                                Toolbar: (props) => (
                                                    <div
                                                        className='tableStyle'
                                                    >
                                                        <MTableToolbar
                                                            {...props}
                                                            className='tableToolbar'
                                                        />
                                                    </div>
                                                ),
                                            }}
                                        />
                                        {/* </ThemeProvider> */}
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col-6 text-start mt-4">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                        onClick={() => {
                            pop();
                            //setbuttonText("Save");
                        }}
                        >
                            <b className="icon-plus"></b>Add New Sub-Process Taxonomy Mapping Config
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                        onClick={() => {
                            downloadExcel();
                        }}
                        >
                            <b className=""></b>Export to Excel
                        </button>
                    </div>

                </div>
            </div>

            <Footer />
            <Modal
        show={openPopUp}
        dialogClassName="modal-100w"
        onHide={popupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Taxonomy Mapping Configuration</Modal.Title>
          
        </Modal.Header>

        <Modal.Body>
        
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
                <div>
                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>Process Code</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{saveTaxonomyCheck.processCode}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter Process Code"
                         maxLength = "100"
                         height={200}
                        value={processCode}
                        onChange={(e)=>setprocessCode(e.target.value)}
                         
                        />   
                  </div>
                </div>
                <div></div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>Process Name</b><span
                          className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{saveTaxonomyCheck.processName}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter Process Name"
                         maxLength = "100"
                         height={200}
                         value={processName}
                         onChange={(e)=>setprocessName(e.target.value)}
                         
                        />   
                  </div>
                </div>
                <div></div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>SubProcess Code</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{saveTaxonomyCheck.subProcessCode}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter SubProcess Code"
                         maxLength = "100"
                         height={200}
                         value={subProcessCode}
                         onChange={(e)=>setsubProcessCode(e.target.value)}
                         
                        />   
                  </div>
                </div>

                <div></div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>SubProcess Name</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{saveTaxonomyCheck.subProcessName}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter SubProcess Name"
                         maxLength = "100"
                         height={200}
                         value={subProcessName}
                         onChange={(e)=>setsubProcessName(e.target.value)}
                         
                        />   
                  </div>
                </div>
                <div></div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>WorkItemType Code</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{saveTaxonomyCheck.workItemTypeCode}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter WorkItemType Code"
                         maxLength = "100"
                         height={200}
                         value={workItemTypeCode}
                         onChange={(e)=>setworkItemTypeCode(e.target.value)}
                         
                        />   
                  </div>
                </div>

                <div></div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>WorkItemType</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{saveTaxonomyCheck.workItemType}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter WorkItemType"
                         maxLength = "100"
                         height={200}
                         value={workItemType}
                         onChange={(e)=>setworkItemType(e.target.value)}
                         
                        /> 

                        <br></br>
                        <div className="col-3 height81">
                      <div className="mb-1">
                      <label className="form-label"><b>Is Excluded</b></label>
                      <input type="checkbox" className='LeftTopMargin2' 
                      onChange={onChangeIsExcluded}
                      checked={isExcluded}
                      
                      />
                      </div>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
            <div className="col-6 text-start mt-4">
                {/* <div className='dashBoxs mb-3'> */}
                
                  <button type="button" 
                  class="btn btn-sm btn-primary ms-2" 
                  onClick={saveTaxonomyMapping}
                  title="Click here to add Taxonomy Mapping Config"
                  ><b>Save & Continue</b></button>
                
                  
                  
                 
                  <button type="button" 
                  class="btn btn-sm btn-primary ms-2" 
                  onClick={clearInputValues}
                  title="Click here to clear values"
                  ><b>Clear</b></button>
              
                  
              
            
          </div>
        </div>
      


            
            
          </Modal.Body>
        
        
        
        </Modal>

        <Modal
        show={EditPopUp}
        dialogClassName="modal-100w"
        onHide={EditpopupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Taxonomy Mapping Configuration</Modal.Title>
          
        </Modal.Header>

        <Modal.Body>
        
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>Process Code</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{updateTaxonomyCheck.editprocessCode}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter Process Code"
                         maxLength = "100"
                         height={200}
                        value={editprocessCode}
                        onChange={(e)=>seteditprocessCode(e.target.value)}
                         
                        />   
                  </div>
                </div>
                <div></div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>Process Name</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{updateTaxonomyCheck.editprocessName}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter Process Name"
                         maxLength = "100"
                         height={200}
                         value={editprocessName}
                         onChange={(e)=>seteditprocessName(e.target.value)}
                         
                        />   
                  </div>
                </div>
                <div></div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>SubProcess Code</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{updateTaxonomyCheck.editsubProcessCode}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter SubProcess Code"
                         maxLength = "100"
                         height={200}
                         value={editsubProcessCode}
                         onChange={(e)=>seteditsubProcessCode(e.target.value)}
                         
                        />   
                  </div>
                </div>

                <div></div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>SubProcess Name</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{updateTaxonomyCheck.editsubProcessName}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter SubProcess Name"
                         maxLength = "100"
                         height={200}
                         value={editsubProcessName}
                         onChange={(e)=>seteditsubProcessName(e.target.value)}
                         
                        />   
                  </div>
                </div>
                <div></div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>WorkItemType Code</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{updateTaxonomyCheck.editworkItemTypeCode}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter WorkItemType Code"
                         maxLength = "100"
                         height={200}
                         value={editworkItemTypeCode}
                         onChange={(e)=>seteditworkItemTypeCode(e.target.value)}
                         
                        />   
                  </div>
                </div>

                <div></div>

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>WorkItemType</b><span
                         className='redColor'> *</span></label>
                          <div>
                      <span className='redColor'>{updateTaxonomyCheck.editworkItemType}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Enter WorkItemType"
                         maxLength = "100"
                         height={200}
                         value={editworkItemType}
                         onChange={(e)=>seteditworkItemType(e.target.value)}
                         
                        /> 

                        <br></br>
                        <div className="col-3 height81">
                      <div className="mb-1">
                      <label className="form-label"><b>Is Excluded</b></label>
                      <input type="checkbox" className='LeftTopMargin2' 
                      onChange={onChangeEditIsExcluded}
                      checked={editisExcluded}
                      
                      />
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>  
                

                
       
      

            <div className="col-6 text-start mt-4">
                
                  <button type="button" 
                  class="btn btn-sm btn-primary ms-2" 
                  onClick={updateTaxonomyMapping}
                  title="Click here to add Taxonomy Mapping Config"
                  ><b>Update & Continue</b></button>
                
                  <button type="button" 
                  class="btn btn-sm btn-primary ms-2" 
                  onClick={clearEditInputValues}
                  title="Click here to clear values"
                  ><b>Clear</b></button>
              
                  
                
            
          </div>
        </div>
      


            
            
          </Modal.Body>
        
        
        
        </Modal>
        </>
    );

};

export default SubProcessTaxonomyMappingConfig;
import { useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import React, { useState, useContext, useEffect, useMemo } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import SampleGraph from "../assets/images/SampleGraph.png";
import PowerBIHomeErrorPNG from "../assets/images/PowerBIHomeNotFound.png";
import BrowserNotFound from "../assets/images/404-error.png";

function PowerBiFunctions(state) {
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GETEMBEDINFO } = process.env;
  const { addToast } = useToasts();
  let navigate = useNavigate();
  const [reportId, setreportId] = useState("");
  const [embedUrl, setembedUrl] = useState("");
  const [accessToken, setaccessToken] = useState("");
  const [PbiError, setPbiError] = useState(false);
  const [submitApiCalled, setsubmitApiCalled] = useState(false);
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  useEffect(() => {
    setPbiError(false);
    let body = {
      WorkspaceId: state.state.PowerbiWorkspaceId,
      ReportId: state.state.PowerbiReportid,
    };
    let EmbedInfoUrl = getAPIURL(REACT_APP_GETEMBEDINFO);
    let req = new Request(`${EmbedInfoUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // debugger;
          setreportId(result.data[0].embedReport[0].reportId);
          setaccessToken(result.data[0].embedToken.token);
          setembedUrl(result.data[0].embedReport[0].embedUrl);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          // debugger;
          setPbiError(true);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
    setsubmitApiCalled(true);
  }, [state.state.PowerbiReportid]);
  // useEffect(() => {
  //   const powerbiReport=return (
  //     <>
  //       <div>
  //         <div className="fieldSet">
  //           {embedUrl != "" ? (
  //             <PowerBIEmbed
  //               embedConfig={{
  //                 type: "report",
  //                 id: reportId,
  //                 embedUrl: embedUrl,
  //                 accessToken: accessToken,
  //                 tokenType: models.TokenType.Embed,
  //                 //pageView: "fitToWidth",
  //                 settings: {
  //                   panes: {
  //                     filters: {
  //                       expanded: false,
  //                       visible: false,
  //                     },
  //                   },
  //                   //background: models.BackgroundType.Transparent,
  //                   layoutType: models.LayoutType.Custom,
  //                   customLayout: {
  //                     displayOption: models.DisplayOption.FitToPage,
  //                   },
  //                 },
  //               }}
  //               eventHandlers={
  //                 new Map([
  //                   [
  //                     "loaded",
  //                     function () {
  //                       console.log("Report Loaded");
  //                     },
  //                   ],
  //                   [
  //                     "rendered",
  //                     function () {
  //                       console.log("Report Rendered");
  //                     },
  //                   ],
  //                   [
  //                     "error",
  //                     function (event) {
  //                       console.log(event.detail);
  //                     },
  //                   ],
  //                 ])
  //               }
  //               cssClassName={state.state.cssStyle}
  //               getEmbeddedComponent={(embeddedReport) => {
  //                 window.report = embeddedReport;
  //               }}
  //             />
  //           ) : null}
  //         </div>
  //       </div>
  //     </>
  //   );
  // }, [embedUrl]);
  return (
    <div className={state.state.centersetion}>
      <div>
        {!PbiError ? (
          <div>
            <div className="">
              {embedUrl != "" ? (
                <PowerBIEmbed
                  embedConfig={{
                    type: "report",
                    id: reportId,
                    embedUrl: embedUrl,
                    accessToken: accessToken,
                    tokenType: models.TokenType.Embed,
                    //pageView: "fitToWidth",
                    settings: {
                      panes: {
                        filters: {
                          expanded: false,
                          visible: false,
                        },
                      },
                      //background: models.BackgroundType.Transparent,
                      layoutType: models.LayoutType.Custom,
                      customLayout: {
                        displayOption: models.DisplayOption.FitToPage,
                      },
                    },
                  }}
                  eventHandlers={
                    new Map([
                      [
                        "loaded",
                        function () {
                          console.log("Report Loaded");
                        },
                      ],
                      [
                        "rendered",
                        function () {
                          console.log("Report Rendered");
                        },
                      ],
                      [
                        "error",
                        function (event) {
                          console.log(event.detail);
                        },
                      ],
                    ])
                  }
                  cssClassName={state.state.cssStyle}
                  getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                  }}
                />
              ) : null}
            </div>
          </div>
        ) : (
          <div id="notfound">
            <div className="notfound">
              <div className="notfound-404">
                {/* <h1>404</h1> */}

                <img
                  style={{ height: "6rem", width: "6rem" }}
                  src={BrowserNotFound}
                />

                {state.state.IsHome == "false" ? (
                  <div className="notfound-title" style={{}}>
                    PowerBI Report not found!
                  </div>
                ) : (
                  <div className="notfound-title" style={{}}>
                    PowerBI Dashboard not found!
                  </div>
                )}
                <div className="notfound-body" style={{ color: "Black" }}>
                  It may be not available or not configured. If you want it to
                  configured, please email us at{" "}
                  <a href="mailto:#pdsupport@exlservice.com">
                    #pdsupport@exlservice.com
                  </a>
                </div>
              </div>

              {/* {state.state.IsHome == "false" ? (
                <a href="#">
                  <button type="button" className="btn btn-primary">
                    <span className="arrow"></span> Home
                  </button>
                </a>
              ) : null} */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PowerBiFunctions;

import { createSlice } from '@reduxjs/toolkit'

const initialState = [];

export const addWorkItemSlice = createSlice({
  name: 'advWorkItemDetails',
  initialState,
  reducers: {
    addWorkItem: (state,action) => {
      //  console.log("slice",action.payload);
        
      state.push(action.payload); 
       },
    deleteWorkItem: (state,action) => {
       // console.log("slice",action.payload);  
       state.splice(action.payload, 1);
       },
    
  },
})

// Action creators are generated for each case reducer function
export const { addWorkItem,deleteWorkItem} = addWorkItemSlice.actions

export default addWorkItemSlice.reducer
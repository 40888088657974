import logo from "../../../../assets//images/logo/exl_logo_rgb_orange_pos.png";
import scItem_01 from "../../../../assets/images/icons/icon_folder.png";
import scItem_02 from "../../../../assets/images/icons/icon_customization.png";
import scItem_03 from "../../../../assets/images/icons/icon_sub_process.png";
import scItem_04 from "../../../../assets/images/icons/icon_manage.png";
import scItem_05 from "../../../../assets/images/icons/icon_analysis.png";
import scItem_06 from "../../../../assets/images/icons/icon_review.png";
import scItem_07 from "../../../../assets/images/icons/icon_authorization.png";
import Slider from "react-slick";
import LoadingSpinner from "../../../../LoadingSpinner";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { object } from "prop-types";
import { red } from "@material-ui/core/colors";
import Nav from "../../../../components/nav/Nav";
import { ToastProvider, useToasts } from "react-toast-notifications";
import bcrypt from "bcryptjs";
import { Buffer } from "buffer";
import { TbUnderline } from "react-icons/tb";
import Footer from "src/components/footer/Footer";
import NerveHubLogin from "./NerveHubLogin";
import {
  RefreshToken,
  setupRefreshTokenTimer,
} from "src/components/common/functions";

import ApplicationUserContext from "src/contexts/ApplicationUserContext";
import DashboardContext from "src/contexts/DashboardContext";
import NavDataContext from "src/contexts/NavDataContext";

const { REACT_APP_LOGIN } = process.env;
const { REACT_APP_API_URL } = process.env;
const { REACT_APP_NAME } = process.env;
const { REACT_APP_AllowClientResetPassword } = process.env;
const { REACT_APP_CLIENTURL } = process.env;
const { REACT_APP_UPDATEMEMBERSHIPLOGININFO } = process.env;
const getAPIURL = (api) => {
  let apiURL = REACT_APP_API_URL;
  return `${apiURL}${api}`;
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//toast.configure()
function Login() {
  let navigate = useNavigate();
  const { addToast } = useToasts();
  const currentURL = window.location.href;
  let ClientURL = REACT_APP_CLIENTURL;
  const [username, set_username] = useState("");
  const [password, set_password] = useState("");
  const [isLoading, set_IsLoading] = useState(false);
  const [isShow, set_isShow] = useState(false);
  const [roles, setRoles] = useState([]);
  const [error_msg, set_error_msg] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [is_error, set_is_error] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [is_authenticated, set_is_authenticated] = useState(false);
  //roles
  const [is_admin, set_is_admin] = useState(false);
  const [is_superadmin, set_is_superadmin] = useState(false);
  const [is_Configuration, set_is_Configuration] = useState(false);
  const [is_workItemFieldadmin, set_is_workItemFieldadmin] = useState(false);
  const [is_instructionauthor, set_is_instructionauthor] = useState(false);
  const [is_instructionapprover, set_is_instructionapprover] = useState(false);
  const [is_manageUsers, set_is_manageUsers] = useState(false);
  const [is_report, set_is_report] = useState(false);
  const [is_activities, set_is_activities] = useState(false);
  const [is_upload, set_is_upload] = useState(false);
  const [is_client, set_is_client] = useState(false);
  const [is_exceptions, set_is_exceptions] = useState(false);
  const [is_complete, set_is_complete] = useState(false);
  const [is_vendor, set_is_vendor] = useState(false);
  const [is_workFlowstatechange, set_is_workFlowstatechange] = useState(false);
  const [is_reassignment, set_is_reassignment] = useState(false);
  const [is_adhocEditing, set_is_adhocEditing] = useState(false);
  const [is_undoActivity, set_is_undoActivity] = useState(false);
  const [is_clientDailyInfo, set_is_clientDailyInfo] = useState(false);
  const [is_workitemBulkUpdation, set_is_workitemBulkUpdation] =
    useState(false);
  const [is_cwsRestrictedSearch, set_is_cwsRestrictedSearch] = useState(false);
  const [showAutoLogoutMessage, setShowAutoLogoutMessage] = useState(document.location.search.includes("?autoLogout") ?? false);
  const [showNervehubSSO, setShowNervehubSSO] = useState(false);

  var settings = {
    dots: true,
    infinite: true,
    speed: 1600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    adaptiveHeight: false,
  };

  let row = [];
  const userRoles = React.useContext(ApplicationUserContext);
  const nav = React.useContext(DashboardContext);
  let assignedWorkItemDetails = React.useContext(NavDataContext);
  const selectedSubprocess = sessionStorage.getItem("ProcessId");
  let defaultFilter =
    "wi.UploadDate between dateadd(day,-10,getdate()) and getdate()";


  const ChangeHandle = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      set_password(value);
    }
    if (name === "username") {
      set_username(value);
    }
  };
  
  const ForceLoginHandle = (body) => {
    set_IsLoading(true);
    // setFormErrors(validate(username, password));
    setIsSubmit(true);
    sessionStorage.setItem("ApplicationURL", window.location.origin);
    if (body != null && body!= "") {
      set_isShow(true);

      // Commented after nervehub login issue

      // let UserToken = "";
      // let indexp = 0;
      // password.split("").forEach((character) => {
      //   UserToken = UserToken + "=c" + password.charCodeAt(indexp);
      //   indexp = indexp + 1;
      // });
      // var Buffer = require("buffer/").Buffer;

      // let buff = new Buffer(UserToken);
      // let encryptedUserToken = buff.toString("base64");
      // //let encryptedpassword = Buffer.from(password, "base64");
      // let body = {
      //   username: username,
      //   UserToken: encryptedUserToken,
      //   applicationname: sessionStorage.getItem("ApplicationURL"),
      //   ForceLogin: true,
      // };

      // Commented for nervehub issue end 

      let loginUrl = getAPIURL(REACT_APP_LOGIN);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
        },
        credentials: 'include',
        body: JSON.stringify(body)
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          set_IsLoading(false);
          debugger
          if (result.status == 500) {
            set_is_authenticated(false);
            set_is_error(true);
            set_isShow(false);
            addToast(result.message, {
              appearance: "error",
              autoDismiss: true,
            });
          } else if (result.status != 200) {
            set_is_authenticated(false);
            set_is_error(true);
            set_isShow(false);
            addToast("Incorrect Username or Password!", {
              appearance: "error",
              autoDismiss: true,
            });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            if(result.data[0].restrictCWSActive=="Y" && sessionStorage.getItem("platform")=='null')
            {
              const confirmBox = window.confirm(
                result.data[0].descriptions
              );
              if (confirmBox === true) {
                // window.open(result.data[0].redirectURLLink);
                window.location.href = result.data[0].redirectURLLink;
              }
            }
            else
            {
              if (result.status == 200) {
                addToast(result.message, {
                  appearance: "info",
                  autoDismiss: true
                });
              }

              //If any change in this portion adding value in session.
              //Please add this session change to SSOLogin page also --Omkar
            
              sessionStorage.setItem("IsSystemTimeZone",false);
              sessionStorage.setItem("authorizationToken", result.data[0].token);
              sessionStorage.setItem("RefreshToken", result.data[0].refreshToken);
              sessionStorage.setItem("remainingPwdExpiryDays", result.data[0].remainingPwdExpiryDays);
              sessionStorage.setItem("EnablePwdPolicy", result.data[0].enablePwdPolicy);
              if (result.data[0].isMultipleSubProcessAllowed) {
                sessionStorage.setItem("is_userPreference", true);
              } else {
                sessionStorage.setItem("is_userPreference", false);
              }
              const rolesName = result.data[0].rolesName;
              setRoles(rolesName);
              // Navigation Blacklisting according to current development- Aman
              sessionStorage.setItem("rolesList", JSON.stringify(rolesName));
              //Added by Gajendra  for local timezone name
              var timezonename = new Date()
                .toLocaleTimeString("en-us", { timeZoneName: "long" })
                .split(" ")[2];
              sessionStorage.setItem("LocalTimezoneName", timezonename);

              for (let i = 0; i < rolesName.length; i++) {
                if (rolesName[i].roleName === "Admin1")
                  sessionStorage.setItem("is_admin", true);
                else if (rolesName[i].roleName === "Super Admin" || rolesName[i].roleName === "SuperAdmin")
                  sessionStorage.setItem("is_superadmin", true);
                else if (rolesName[i].roleName === "One And Done")
                  sessionStorage.setItem("is_OneandDone", true);
                else if (rolesName[i].roleName === "Upload")
                  sessionStorage.setItem("is_upload", true);
                else if (
                  rolesName[i].roleName === "Activities" ||
                  rolesName[i].roleName === "CWSActivities"
                )
                  sessionStorage.setItem("is_activities", true);
                else if (rolesName[i].roleName === "Client")
                  sessionStorage.setItem("is_client", true);
                else if (rolesName[i].roleName === "Exceptions")
                  sessionStorage.setItem("is_exceptions", true);
                else if (rolesName[i].roleName === "Complete")
                  sessionStorage.setItem("is_complete", true);
                else if (rolesName[i].roleName === "Configuration")
                  sessionStorage.setItem("is_Configuration", true);
                else if (rolesName[i].roleName === "Vendor")
                  sessionStorage.setItem("is_vendor", true);
                else if (
                  rolesName[i].roleName === "Report" ||
                  rolesName[i].roleName === "Reports"
                )
                  sessionStorage.setItem("is_report", true);

                else if (rolesName[i].roleName === "Manage WorkItems")
                  sessionStorage.setItem("is_manageWorkItems", true);
                else if (rolesName[i].roleName === "CWSSearch" || rolesName[i].roleName === "CWSRestrictedSearch")
                  sessionStorage.setItem("is_search", true);
                else if (rolesName[i].roleName === "Upload and Assignment")
                  sessionStorage.setItem("is_UploadandAssignment", true);
                else if (rolesName[i].roleName === "Queue Management")
                  sessionStorage.setItem("is_QueueManagement", true);
                else if (rolesName[i].roleName === "Bucket Management")
                  sessionStorage.setItem("is_BucketManagement", true);
                else if (rolesName[i].roleName === "Real Time Dashboard")
                  sessionStorage.setItem("is_RealTimeDashboard", true);
                else if (rolesName[i].roleName === "Configuration")
                  sessionStorage.setItem("is_Configuration", true);
                else if (rolesName[i].roleName === "User Classification Mapping")
                  sessionStorage.setItem("is_UserClassificationMapping", true);
                else if (rolesName[i].roleName === "User Classification List")
                  sessionStorage.setItem("is_UserClassificationList", true);
                else if (rolesName[i].roleName === "Workitem Fields Rules")
                  sessionStorage.setItem("is_WorkitemFieldsRules", true);
                else if (rolesName[i].roleName === "SubprocessView")
                  sessionStorage.setItem("is_SubprocessView", true);
              }
              //set_is_authenticated(true);

              //sessionStorage.setItem("ApplicationURL", window.location.origin);
              sessionStorage.setItem("is_Authenticated", true);
                sessionStorage.setItem("authorizationToken", result.data[0].token);
                debugger;
                
              //  console.log("before refresh token timer");

                let timerid = setupRefreshTokenTimer();
                clearTimeout(timerid);
              let userNameVal = "";
              if (body.SSOSource == "nervehub") {
                  userNameVal = result.data[0].CWSUserName;
              } else {
                  userNameVal = username;
              }
              sessionStorage.setItem("UserName", userNameVal);
              sessionStorage.setItem("UserFullName", result.data[0].fullName);
              sessionStorage.setItem("UserEmail", result.data[0].email);
              sessionStorage.setItem("GUID", result.data[0].guid);
              sessionStorage.setItem("PersonID", result.data[0].personID);
              sessionStorage.setItem("PersonType", result.data[0].personType);
              sessionStorage.setItem(
                "HRISEmployeeID",
                result.data[0].hrisEmployeeID
              );

            sessionStorage.setItem(
              "EmployeeVersionID",
              result.data[0].employeeVersionID
            );
           // set_is_authenticated(true);
            UpdateMemberShipLogIn()
            debugger
            if(result.data[0].enablePwdPolicy === 1 && result.data[0].remainingPwdExpiryDays < 0 && sessionStorage.getItem("PersonType") === "Client"){
              set_is_authenticated(false);
              navigate("/Reset");
            }
            else{
              set_is_authenticated(true);
            }

          }
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    } else {
      set_isShow(set_isShow);
    }
  };
  const UpdateMemberShipLogIn = (e) =>{
    var applicationUrl = sessionStorage.getItem("ApplicationURL");
    var applicationName = "";
    if(applicationUrl.includes(REACT_APP_CLIENTURL))
    {
      applicationName = "ClientFeedback"
    }
    else applicationName = "ProductionDashboard"
    var userName = "";
    if(sessionStorage.getItem("PersonType").toString() === "Client")
    {
      userName = sessionStorage.getItem("UserName");
    }
    else userName = sessionStorage.getItem("HRISEmployeeID");
    let body = {
      userName: userName,
      applicationname: applicationName,
    };
    let loginUrl = getAPIURL(REACT_APP_UPDATEMEMBERSHIPLOGININFO);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body)
      });
      fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          // console.log("Updating Membership Info");
          
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          //setReason(false);
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          //window.location.href = "/Home";
          // console.log("Updating Membership Info failed");
          //navigate("/ErrorPage");
        }
      });

  }
  const LoginHandle = (e) => {
    setShowAutoLogoutMessage(false);
    e.preventDefault();
    setFormErrors(validate(username, password));
    setIsSubmit(true);
    sessionStorage.setItem("ApplicationURL", window.location.origin);
    if (username != "" && password != "") {
      set_isShow(true);
      let UserToken = "";
      let indexp = 0;
      password.split("").forEach((character) => {
        UserToken = UserToken + "=c" + password.charCodeAt(indexp);
        indexp = indexp + 1;
      });
      var Buffer = require("buffer/").Buffer;

      let buff = new Buffer(UserToken);
      let encryptedUserToken = buff.toString("base64");
      //let encryptedpassword = Buffer.from(password, "base64");
      let body = {
        username: username,
        UserToken: encryptedUserToken,
        applicationname: sessionStorage.getItem("ApplicationURL"),
        ForceLogin: false,
      };
     
      LoginUser(body);
    } else {
      set_isShow(set_isShow);
    }
  };

  
  const LoginUser = (body) => {
    setShowAutoLogoutMessage(false);
    set_IsLoading(true);
    sessionStorage.setItem("ApplicationURL", window.location.origin);
    set_isShow(true);
    let UserToken = "";
    let indexp = 0;

    let loginUrl = getAPIURL(REACT_APP_LOGIN);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
      },
      credentials: 'include',
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        set_IsLoading(false);
        if (result.status == 500) {
          set_is_authenticated(false);
          set_is_error(true);
          set_isShow(false);
            
            if (!body.ForceLogin) {
                const confirmBox = window.confirm(
                    "You are already Loggedin in other place! If you want to login here click OK"
                );
                if (confirmBox === true) {
                    body.ForceLogin=true;
                    ForceLoginHandle(body);
                    debugger
                }
                // addToast(result.message, {
                //   appearance: "error",
                //   autoDismiss: true,
                // });
            }
          } else if (result.status == 200) {
            if(result.data[0].restrictCWSActive=="Y" && sessionStorage.getItem("platform")=='null')
            {
              const confirmBox = window.confirm(
                result.data[0].descriptions
              );
              if (confirmBox === true) {
                // window.open(result.data[0].redirectURLLink);
                window.location.href = result.data[0].redirectURLLink;
              }
            }
            else
            {
              
              addToast(result.message, {
                appearance: "info",
                autoDismiss: true
              });
  
              //If any change in this portion adding value in session.
              //Please add this session change to SSOLogin page also --Omkar
  
              sessionStorage.setItem("authorizationToken", result.data[0].token);
              sessionStorage.setItem("RefreshToken", result.data[0].refreshToken);
              if (result.data[0].isMultipleSubProcessAllowed) {
                sessionStorage.setItem("is_userPreference", true);
              } else {
                sessionStorage.setItem("is_userPreference", false);
              }
              const rolesName = result.data[0].rolesName;
  
              setRoles(rolesName);
  
              //Added by Gajendra  for local timezone name
  
              var timezonename = new Date()
                .toLocaleTimeString("en-us", { timeZoneName: "long" })
                .split(" ")[2];
              sessionStorage.setItem("LocalTimezoneName", timezonename);
  
              // Navigation Blacklisting according to current development- Aman
              sessionStorage.setItem("rolesList", JSON.stringify(rolesName));
              for (let i = 0; i < rolesName.length; i++) {
                if (rolesName[i].roleName === "Admin1")
                  sessionStorage.setItem("is_admin", true);
                else if (rolesName[i].roleName === "One And Done")
                  sessionStorage.setItem("is_OneandDone", true);
  
                else if (rolesName[i].roleName === "Super Admin" || rolesName[i].roleName === "SuperAdmin")
                  sessionStorage.setItem("is_superadmin", true);
                else if (rolesName[i].roleName === "Upload")
                  sessionStorage.setItem("is_upload", true);
                else if (
                  rolesName[i].roleName === "Activities" ||
                  rolesName[i].roleName === "CWSActivities"
                )
                  sessionStorage.setItem("is_activities", true);
                else if (rolesName[i].roleName === "Client")
                  sessionStorage.setItem("is_client", true);
                else if (rolesName[i].roleName === "Exceptions")
                  sessionStorage.setItem("is_exceptions", true);
                else if (rolesName[i].roleName === "Complete")
                  sessionStorage.setItem("is_complete", true);
                else if (rolesName[i].roleName === "Configuration")
                  sessionStorage.setItem("is_Configuration", true);
                else if (rolesName[i].roleName === "Vendor")
                  sessionStorage.setItem("is_vendor", true);
                //       else if (rolesName[i].roleName === "WorkFlow State Change")
                //       sessionStorage.setItem("is_workFlowstatechange", true);
                //   else if (rolesName[i].roleName === "Reassignment")
                //   sessionStorage.setItem("is_reassignment", true);
                //else if (rolesName[i].roleName === "AdhocEditing")
                // sessionStorage.setItem("is_adhocEditing", true);
                //else if (rolesName[i].roleName === "Undo Activity")
                // sessionStorage.setItem("is_undoActivity", true);
                //else if (rolesName[i].roleName === "ClientDailyInfo")
                // sessionStorage.setItem("is_clientDailyInfo", true);
                // else if (rolesName[i].roleName === "Workitem Bulk Updation")
                //   sessionStorage.setItem("is_workitemBulkUpdation", true);
                // else if (rolesName[i].roleName === "CWSRestrictedSearch")
                //  sessionStorage.setItem("is_cwsRestrictedSearch", true);
                else if (
                  rolesName[i].roleName === "Report" ||
                  rolesName[i].roleName === "Reports"
                )
                  sessionStorage.setItem("is_report", true);
  
                else if (rolesName[i].roleName === "Manage WorkItems")
                  sessionStorage.setItem("is_manageWorkItems", true);
                else if (rolesName[i].roleName === "CWSSearch" || rolesName[i].roleName === "CWSRestrictedSearch")
                  sessionStorage.setItem("is_search", true);
                else if (rolesName[i].roleName === "Upload and Assignment")
                  sessionStorage.setItem("is_UploadandAssignment", true);
                else if (rolesName[i].roleName === "Queue Management")
                  sessionStorage.setItem("is_QueueManagement", true);
                else if (rolesName[i].roleName === "Bucket Management")
                  sessionStorage.setItem("is_BucketManagement", true);
                else if (rolesName[i].roleName === "Real Time Dashboard")
                  sessionStorage.setItem("is_RealTimeDashboard", true);
                // else if (rolesName[i].roleName === "ManageUsers")
                //   sessionStorage.setItem("is_manageUsers", true);
                // else if (rolesName[i].roleName === "WorkItemField Admin")
                //   sessionStorage.setItem("is_workItemFieldadmin", true);
                else if (rolesName[i].roleName === "Configuration")
                  sessionStorage.setItem("is_Configuration", true);
                else if (rolesName[i].roleName === "User Classification Mapping")
                  sessionStorage.setItem("is_UserClassificationMapping", true);
                else if (rolesName[i].roleName === "User Classification List")
                  sessionStorage.setItem("is_UserClassificationList", true);
                else if (rolesName[i].roleName === "Workitem Fields Rules")
                  sessionStorage.setItem("is_WorkitemFieldsRules", true);
                else if (rolesName[i].roleName === "SubprocessView")
                  sessionStorage.setItem("is_SubprocessView", true);
                //  else if (rolesName[i].roleName === "Instruction Author")
                //    sessionStorage.setItem("is_instructionauthor", true);
                //  else if (rolesName[i].roleName === "Instruction Approver")
                //   sessionStorage.setItem("is_instructionapprover", true);
              }
              set_is_authenticated(true);
              //sessionStorage.setItem("ApplicationURL", window.location.origin);
              sessionStorage.setItem("is_Authenticated", true);
              sessionStorage.setItem("authorizationToken", result.data[0].token);
             // console.log("before refresh token timer");
              let timerid = setupRefreshTokenTimer();
              clearTimeout(timerid);
              let userNameVal = "";
              if(body.SSOSource == "nervehub") {
                userNameVal = result.data[0].CWSUserName;
              } else {
                userNameVal = username;
              }
              sessionStorage.setItem("UserName", userNameVal);
              sessionStorage.setItem("UserFullName", result.data[0].fullName);
              sessionStorage.setItem("UserEmail", result.data[0].email);
              sessionStorage.setItem("GUID", result.data[0].guid);
              sessionStorage.setItem("PersonID", result.data[0].personID);
              sessionStorage.setItem("PersonType", result.data[0].personType);
              sessionStorage.setItem(
                "HRISEmployeeID",
                result.data[0].hrisEmployeeID
              );
              sessionStorage.setItem(
                "EmployeeVersionID",
                result.data[0].employeeVersionID
              );
              UpdateMemberShipLogIn();
              // if(sessionStorage.getItem("redirectUrl")!= '' && sessionStorage.getItem("redirectUrl")!= null)
              //   {
              //     navigate(sessionStorage.getItem("redirectUrl"));
              //   }
            }
            
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            set_is_authenticated(false);
            set_is_error(true);
            set_isShow(false);

          //set_error_msg("Username or Password is wrong!");
          addToast("Incorrect Username or Password!", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  }
  
  useEffect(() => {
    
    const urlQueryString = window.location.search;
    const params = new URLSearchParams(urlQueryString);
    const platform = params.get("platform");
    const redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl') || '';
    sessionStorage.setItem("redirectUrl",redirectUrl);
    sessionStorage.setItem("platform", platform);
    if(platform) {
      setShowNervehubSSO(true);
    }
  }, []);

  useEffect(() => {
    if (formErrors.length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = async (username, password) => {
    const errors = {};
    set_error_msg("");
    const regax = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (!username) {
      addToast("Username is required!", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (!password) {
      addToast("Password is required!", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    return errors;
  };
  if (!is_authenticated) {
    sessionStorage.setItem("is_Authenticated", false);
  }

  const handleLoginRedirect = () => {
    navigate("/SSOLogin");
  };
  
  return (
    <>
      {is_authenticated ? (
        <Navigate to="/Home" state={roles} />
      ) : (
        <>
          {showNervehubSSO ? (
            <NerveHubLogin onSSOLogin={LoginUser}></NerveHubLogin>
          ) : (
          <>
          <h1
            id="hdrCWS"
            style={{
              marginLeft: "82px",
              color: "#FB4E0B",
              fontSize: "25px",
              position: "absolute",
              top: "20px",
              textDecoration: "underline",
              backgroundColor: "rgb(249 249 249)",
            }}
          >
            <img
              style={{
                height: "19px",
                verticalAlign: "middle",
                marginRight: "4px",
              }}
              src={logo}
              alt="EXL"
            />
            &#124;Custom Workflow Solution
          </h1>

          <div className="authCenter">
            <div className="authWrap">
              <div className="authLeft">
                <div className="authDetail">
                  {/* <h1>Custom Workflow Solution</h1>
                <p>
                  Is a powerful workflow based application that works in tandem
                  with EXL’s production dashboard and enables EXL’s client users
                  to collaborate with EXL employees in real-time by acting upon
                  the work items routed to them.
                </p>
                <div>
                  <Slider className="scWrap" {...settings}>
                    <div>
                      <div className="scWrap-item">
                        <div className="scWrap-icon">
                          <img src={scItem_01} alt="#" />
                        </div>
                        <p className="scWrap-text">
                          Configurable set of data fields and activities
                          specific to each sub-process
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="scWrap-item">
                        <div className="scWrap-icon">
                          <img src={scItem_02} alt="#" />
                        </div>
                        <p className="scWrap-text">
                          Configurable dashboards and screens for each
                          sub-process activity
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="scWrap-item">
                        <div className="scWrap-icon">
                          <img src={scItem_03} alt="#" />
                        </div>
                        <p className="scWrap-text">
                          Configurable sub-process workflow to efficiently route
                          workitems between activities
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="scWrap-item">
                        <div className="scWrap-icon">
                          <img src={scItem_04} alt="#" />
                        </div>
                        <p className="scWrap-text">
                          Facility to upload, create, assign, search and manage
                          sub-process level workitems
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="scWrap-item">
                        <div className="scWrap-icon">
                          <img src={scItem_05} alt="#" />
                        </div>
                        <p className="scWrap-text">
                          Facility to generate powerful reports to track and
                          manage day-to-day operations at process / sub-process
                          level
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="scWrap-item">
                        <div className="scWrap-icon">
                          <img src={scItem_06} alt="#" />
                        </div>
                        <p className="scWrap-text">
                          Facility to monitor process health through SLA
                          compliance and performance review reports
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="scWrap-item">
                        <div className="scWrap-icon">
                          <img src={scItem_07} alt="#" />
                        </div>
                        <p className="scWrap-text">
                          Role based authorization to ensure right-level of
                          access to team members, leads, managers and EXL’s
                          Client users
                        </p>
                      </div>
                    </div>
                  </Slider>
                </div> */}
                </div>
              </div>
              <div className="authRight">                
                <div className="authForm-main">
                  {/* <div className="brandName">
                  <img src={logo} alt="#" />
                </div> */}
                  <h2>Sign In</h2>
                  {/* {is_error ? <Alert severity="error">{error_msg}</Alert> : null} */}
                  {showAutoLogoutMessage && (
                    <div>
                      <Alert severity="error">
                        You are logged out as the session has expired
                      </Alert>
                      <br />
                    </div>
                  )}
                  <form onSubmit={LoginHandle}>
                    <ul>
                      <li>
                        <label id="lblUserName">Username</label>
                        <input
                          id="txtUserName"
                          className="form-control"
                          type="text"
                          placeholder="Username"
                          name="username"
                          value={username}
                          onChange={ChangeHandle}
                        />
                        {/* <p style={{color: "red", fontSize: "15px"}}>{formErrors.username}</p> */}
                        {typeof formErrors.username === "string" ? (
                          <Alert severity="error">{formErrors.username}</Alert>
                        ) : null}
                      </li>
                      <li>
                        <label id="lblPassword">Password</label>
                        <input
                          id="password"
                          className="form-control"
                          type="password"
                          placeholder="Password"
                          name="password"
                          //value={password}
                          onChange={ChangeHandle}
                        />
                        {/* <p style={{color: "red", fontSize: "15px"}}>{formErrors.password}</p> */}
                        {typeof formErrors.password === "string" ? (
                          <Alert severity="error">{formErrors.password}</Alert>
                        ) : null}
                        {REACT_APP_AllowClientResetPassword === "true" ? (
                          // <Link
                          //   className="forgotText"
                          //   to="Client/ResetPassword"
                          // >
                          //   Trouble Login in?
                          // </Link>
                          <Link
                            id="linkForgotPassword"
                            className="forgotText"
                            to="/reset"
                            state={{
                              option: "forgotpassword",
                            }}
                          >
                            Forgot Password
                          </Link>
                        ) : (
                          <></>
                        )}
                      </li>
                      <li>
                        {!isShow ? (
                          <>
                            <button
                              className="btn btn-primary d-block w-100"
                              id="btnLogIn"
                              type="submit"
                            >
                              Login
                            </button>
                            {/* <button className="btn btn-primary" onClick={handleLoginRedirect}>
                          SSO Login
                        </button> */}
                          </>
                        ) : (
                          <LoadingSpinner />
                        )}
                        {/* <p style={{ color: "red", fontSize: "15px" }}>
                        {error_msg}
                      </p> */}
                        {/* {typeof error_msg === "string"?<Alert severity="error">{error_msg}</Alert>:null} */}
                      </li>
                    </ul>
                    <h2 className="ssoDivision">
                      <span>OR</span>
                    </h2>
                    <div>
                      <Link
                        id="linkSSOLogin"
                        className="btn btn-outline-primary d-block"
                        to="/SSORedirect"
                      >
                        Sign in with EXL ID (SSO)
                      </Link>
                    </div>
                  </form>
                </div>                
              </div>
            </div>
          </div>
          <Footer />
          </>
          )}
        </>
      )}
    </>
  );
}

export default Login;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
 
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import DashboardContext from "../../../../contexts/DashboardContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
 
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import {
    Checkbox,
    ListItemText,
    Input,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    InputLabel,
    Select,
    MenuItem,
    Container,
    ListItem,
    TextField,
} from "@material-ui/core";
 
 
function SubProcessInstructionFieldConfig() {
    const { addToast } = useToasts();
 
    const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
    const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
    const { REACT_APP_API_URL } = process.env;
    const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
    const { REACT_APP_FETCHSUBPROCESSINSTRUCTIONFIELDCONFIG } = process.env
    const { REACT_APP_ADDEDITINSTRUCTIONFIELDCONFIG } = process.env
 
    let navigate = useNavigate();
    const location = useLocation();
    const processingDashboardParam = location.state;
    const userRoles = processingDashboardParam.userRoles;
    let navData = [];
    if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
        navData = processingDashboardParam.assignedWorkItemDetails;
    } else {
        navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
    }
 
    const [companyDeparmentID, setcompanyDeparmentID] = useState("");
    const [companyDepartment, setcompanyDepartment] = useState("");
    const [locationClientID, setlocationClientID] = useState("");
    const [locationClient, setlocationClient] = useState("");
    const [processSubProcessID, setprocessSubProcessID] = useState("");
    const [processSubProcess, setprocessSubProcess] = useState("");
    const [clientLocations, setClientLocations] = useState([]);
    const [processSubprocess, setprocessSubprocess] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [departments, setDepartments] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState("");
    const [SelectedClientLocation, setSelectedClientLocation] = useState("");
 
    const [instructionList, setInstructionList] = useState([]);
 
    const getAPIURL = (api) => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
    };
 
    const columns = [
        {
            title: "Label Text",
            field: "labelText",
            editable: 'never'
        },
        {
            title: "Select",
            field: "isSelected",
            editComponent: (props) => {
                // console.log(props);
                return (
                    <input
                        type="checkbox"
                        checked={props.value}
                        onChange={ (e) => {
                            props.onChange(e.target.checked);
                            handleCheckboxChange(props.rowData.workItemFieldID);
                        }}
                    />
                );
            },
            render: (rowData) => (
                <>
                    <input
                        type="checkbox"
                        id=""
                        checked={rowData.isSelected}
                        onChange={() => handleCheckboxChange(rowData.workItemFieldID)}
                    />
                </>
            ),
        },
    ];
 
    const FetchLocationClientsByGuid = (e) => {
        setcompanyDeparmentID("");
        setcompanyDepartment("");
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        setInstructionList([]);
        if (e.target.value == "Select") {
            setprocessSubprocess([]);
            setClientLocations([]);
        }
        if (e.target.value !== "Select") {
            // sessionStorage.setItem(
            //   "selectedDepartment",
            //   e.target.value.split("/")[1]
            // );
            // sessionStorage.setItem(
            //   "selectedDepartmentName",
            //   e.target.value.split("/")[0]
            // );
            setSelectedDepartment(e.target.value);
            let body = {
                guid: sessionStorage.getItem("GUID"),
                departmentId: e.target.value.split("/")[1],
            };
 
            let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
            let req = new Request(`${loginUrl}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    UserName: sessionStorage.getItem("UserName"),
                    Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                },
                body: JSON.stringify(body),
            });
            fetch(req)
                .then((res) => res.json())
                .then((result) => {
                    if (result.status == 200) {
                        setClientLocations(result.data);
                    } else if (result.status === 401) {
                        //unauthorized
                        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                        sessionStorage.clear();
                        sessionStorage.clear();
                        navigate("/");
                    }
                })
                .catch((error) => {
                    //if (result.status === 401) {
                    console.log("ERROR: ", error);
                    //   navigate("/");
                    // }
                });
        }
    };
 
    const FetchDepartmentbyGUID = () => {
        let body = {
            memberShipGuid: sessionStorage.getItem("GUID"),
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setDepartments(result.data);
                    // if (result.data.length > 1) {
                    //   setUserButtonShow(true);
                    // }
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };
 
    const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            departmentId: departmentId,
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setClientLocations(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //   navigate("/");
                // }
            });
    };
 
    const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: clientId,
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setprocessSubprocess(result.data);
                    //GetActivitiesForSubprocess();
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };
 
    const FetchSubprocessName = (subprocessId, clientId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: subprocessId,
            clientId: clientId,
        };
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    // sessionStorage.setItem(
                    //   "SelctedSubprocessName",
                    //   result.data[0].processSubProcess
                    // );
                    //SubProcessSubmitForTimer();
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };
 
    const FetchDefaultValueForSelectedSubprocess = (
        companyDeparmentID,
        locationClientID,
        processSubProcessID
    ) => {
        setcompanyDeparmentID(companyDeparmentID);
        setSelectedDepartment(companyDeparmentID);
        //sessionStorage.setItem("selectedDepartment", companyDeparmentID);
        FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
        setlocationClientID(locationClientID);
        setSelectedLocation(locationClientID);
        //sessionStorage.setItem("selectedLocation", locationClientID);
        FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
        setprocessSubProcessID(processSubProcessID);
        FetchSubprocessName(processSubProcessID, locationClientID);
    };
 
    const FetchProcessSubProcessByGuid = (e) => {
        setlocationClientID("");
        setlocationClient("");
        setprocessSubProcessID("");
        setprocessSubProcess("");
        setInstructionList([]);
        if (e.target.value == "Select") {
            setprocessSubprocess([]);
        }
        setSelectedLocation(e.target.value);
        setSelectedClientLocation(e.target.value.split("/")[1]);
        // sessionStorage.setItem("selectedLocation", e.target.value.split("/")[1]);
        // sessionStorage.setItem(
        //   "selectedLocationName",
        //   e.target.value.split("/")[0]
        // );
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: e.target.value.split("/")[1],
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setprocessSubprocess(result.data);
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                    setprocessSubprocess([]);
                } else {
                    setprocessSubprocess([]);
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //   navigate("/");
                // }
            });
    };
 
    useEffect(() => {
        FetchDefaultValueForSelectedSubprocess(
            sessionStorage.getItem("selectedDepartment"),
            sessionStorage.getItem("selectedLocation"),
            sessionStorage.getItem("ProcessId")
        );
        setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
        FetchDepartmentbyGUID();
        FetchSubProcessInstructionListOnPageLoad();
    }, []);
 
    const FetchSubProcessInstructionListOnPageLoad = async () => {
        let body = {
            subProcessId: sessionStorage.getItem("ProcessId"),
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCHSUBPROCESSINSTRUCTIONFIELDCONFIG);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setInstructionList(result.data);
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };
 
    const FetchSubProcessInstructionListOnsubProcessChange = async (e) => {
        if (e.target.value == "Select") {
            setInstructionList([]);
        }
        // console.log(e.target.value);
        setprocessSubProcessID(e.target.value);
 
        let body = {
            subProcessId: e.target.value,
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCHSUBPROCESSINSTRUCTIONFIELDCONFIG);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setInstructionList(result.data);
                    //console.log(instructionList);
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    };
 
    const handleCheckboxChange = (itemId) => {
        setInstructionList(prevList =>
            prevList.map(item => {
                if (item.workItemFieldID === itemId) {
                    return { ...item, isSelected: !item.isSelected };
                }
                return item;
            })
        );
    };
 
    const addEditInstructionFieldConfig = (assignmentXml) => {
        let body = {
            subProcessId: processSubProcessID,
            _assignmentXml: assignmentXml
        };
        //console.log(body);
 
        let loginUrl = getAPIURL(REACT_APP_ADDEDITINSTRUCTIONFIELDCONFIG);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    //debugger;
                    addToast("Sub-Process Instructions Field Config has been saved successfully.", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                } else if (result.status === 401) {
                    //unauthorized
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                //if (result.status === 401) {
                console.log("ERROR: ", error);
                //navigate("/");
                //}
            });
    }
 
    const handleSave = async () => {
        let assignmentXMLRequest = [];
 
        instructionList.forEach(
            (element) => {
                if (element.isSelected) {
                    const input = {
                        workItemFieldID: "" + element.workItemFieldID,
                        instructionConfigID: "" + element.instructionConfigID,
                        subProcessId: "" + processSubProcessID,
                        updatedBy: "" + sessionStorage.getItem("PersonID")
                    };
                    assignmentXMLRequest.push(input);
                }
            }
        );
        //console.log(assignmentXMLRequest);
        addEditInstructionFieldConfig(assignmentXMLRequest);
        FetchSubProcessInstructionListOnPageLoad();
 
        
    };
 
    const handleCancel = () => {
        //navigate("/SubProcessInstructionFieldConfig");
       // window.location.href = "/SubProcessInstructionFieldConfig";
       FetchSubProcessInstructionListOnPageLoad()
    };
 
    return (
        <>
            <Header onpreferenceShow={null} pageName="Sub Process Instruction Field Config" />
            <ApplicationUserContext.Provider
                value={processingDashboardParam.userRoles}
            >
                <DashboardContext.Provider value={processingDashboardParam.nav}>
                    <NavDataContext.Provider value={navData}>
                        <Nav selectedMenu="admin" />
                    </NavDataContext.Provider>
                </DashboardContext.Provider>
            </ApplicationUserContext.Provider>
 
            {/* <div className="centerSection"> */}
                <div className="pageBody">
 
                    <div className="dashBoxs mb-3">
                        <div className="formWrap">
                            <div className="row align-items-end">
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label"> Company Department</label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={FetchLocationClientsByGuid}
                                        >
                                            <option>Select</option>
                                            {departments.map((department) => (
                                                <option
                                                    value={
                                                        department.companyDepartment +
                                                        "/" +
                                                        department.departmentIDString
                                                    }
                                                    selected={
                                                        department.departmentIDString == companyDeparmentID
                                                    }
                                                >
                                                    {department.companyDepartment}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Location Client</label>
                                        <select
                                            className="form-control form-control-sm"
                                            onChange={FetchProcessSubProcessByGuid}
                                        >
                                            <option>Select</option>
                                            {clientLocations.map((location) => (
                                                <option
                                                    value={
                                                        location.locationClient +
                                                        "/" +
                                                        location.clientIDString
                                                    }
                                                    selected={location.clientIDString == locationClientID}
                                                >
                                                    {location.locationClient}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Process SubProcess</label>
                                        <select
                                            className="form-control form-control-sm"
                                            //onChange={FetchLockedWorkitemsBySubProcessID}
                                            onChange={FetchSubProcessInstructionListOnsubProcessChange}
                                        >
                                            <option>Select</option>
                                            {processSubprocess.map((subprocess) => (
                                                <option
                                                    value={subprocess.subProcessIDString}
                                                    selected={
                                                        subprocess.subProcessIDString == processSubProcessID
                                                    }
                                                >
                                                    {subprocess.processSubProcess}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <div className="dashBoxs mb-3">
                        <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                            <div className="row align-items-center">
                                <div className="col-8">
                                    <h6>Select field(s) to build instruction rule</h6>
                                </div>
                                <div className="col-2">
                                    <div
                                        className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                                        style={{ marginTop: "-10px" }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary ms-2"
                                            onClick={() => {
                                                handleCancel();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div
                                        className="d-grid d-sm-flex justify-content-md-start align-items-center gap-2"
                                        style={{ marginTop: "-10px" }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary ms-2"
                                            onClick={() => {
                                                handleSave();
                                            }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
 
                            </div>
                        </div>
 
                        <div className="tableWrap">
                            <div className="tableWrap-body">
                                <table className="table table-borderless MatTable" style={{ zIndex: "1", position: "relative" }}>
                                    <MaterialTable
                                        title="Select field(s) to build instruction rule"
                                        options={{
                                            showTitle: false,
                                            exportButton: false,
                                            sorting: true,
                                            filtering: false,
                                            search: false,
                                            paging: false,
                                            cellStyle: {
                                                // width: 100,
                                                // minWidth: 100,
                                            },
                                            headerStyle: {},
                                            rowStyle: {
                                                // fontFamily: '"Yantramanav", sans-serif',
                                            }
                                        }}
                                        components={{
                                            Container: props => <Paper {...props} elevation={0} />,
                                            Toolbar: props => (
                                                <div
                                                    style={
                                                        {
                                                            // backgroundColor: "rgb(206 206 206)",
                                                            // fontSize: "0.75em",
                                                        }
                                                    }
                                                >
                                                    <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                                                </div>
                                            )
                                        }}
                                        columns={columns}
                                        icons={tableIcons}
                                        data={instructionList}
                                    />
 
                                </table>
                            </div>
                        </div>
                    </div>
 
                </div>
            {/* </div> */}
 
            <Footer />
        </>
    );
};
 
export default SubProcessInstructionFieldConfig;
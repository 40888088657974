import React from "react";

const ApplicationUserContext = React.createContext();

export default ApplicationUserContext;

// import React,{useState,useContext} from 'react'

// const ApplicationUserContext = React.createContext()
// const ApplicationUserUpdateContext = React.createContext()

// export function useApplicationUser()
// {
//     return useContext(ApplicationUserContext)
// }
// export function useApplicationUserUpdate()
// {
//
//     return useContext(ApplicationUserUpdateContext())
// }
// export function getRoles(roles)
// {
//     const userroles=roles;
// }
// export function ApplicationUserProvider({childern})
// {
//     ;
//     const [useRoles,SetUserRoles]=useState([]);
//     SetUserRoles(userroles);
//     return(
//         <ApplicationUserContext.Provider value={useRoles}>
//             <ApplicationUserUpdateContext.Provider value={rolesName}>
//                {childern}
//             </ApplicationUserUpdateContext.Provider>
//         </ApplicationUserContext.Provider>
//     )
// }
// export default ApplicationUserContext

import React, { useEffect, useRef, useState, useContext } from "react";
import LoadingSpinner from "../../../../LoadingSpinner";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import GlobalStyles from "@mui/material/GlobalStyles";

let advanceColumns = [
  { title: "Search Field", field: "searchField" },
  { title: "Condition", field: "condition" },
  { title: "Search Text", field: "searchText" },
];
const theme = createTheme({
  palette: {
    primary: {
      main: "#008000",
    },
    secondary: {
      main: "#FB4E0B",
    },
  },
});
const WorkActivityConfig = () => {
  return (
    <>
      <div className="dashBoxs">
        <div className="formWrap">
          <div className="row align-items-end">
            <div className="col-4">
              <div className="mb-3">
                <label className="form-label">Activity</label>
                <select
                  className="form-control form-control-sm"
                  //onChange={FetchLocationClientsByGuid}
                >
                  <option>Select</option>
                  {/* {departments.map((department) => (
                            <option
                              value={
                                department.companyDepartment +
                                "/" +
                                department.departmentIDString
                              }
                              selected={
                                department.departmentIDString ==
                                companyDeparmentID
                              }
                            >
                              {department.companyDepartment}
                            </option>
                          ))} */}
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <label className="form-label">
                  Activity Standard Time (in mins) *
                </label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  //onChange={saveSkillsetname}
                  //value={skillsetName}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="mb-3">
                <label className="form-label">Workitem Allocation Mode</label>
                <select
                  className="form-control form-control-sm"
                  //onChange={FetchInvalidProcessSubprocessByGuid}
                >
                  <option>Select</option>
                  {/* {processSubprocess.map((subprocess) => (
                            <option
                              value={
                                subprocess.subProcessIDString +
                                "/" +
                                subprocess.processSubProcess
                              }
                              selected={
                                subprocess.subProcessIDString ==
                                processSubProcessID
                              }
                            >
                              {subprocess.processSubProcess}
                            </option>
                          ))} */}
                </select>
              </div>
            </div>
          </div>
          <div className="row align-items-end">
            <div className="col-3">
              <div className="mb-3">
                <div className="form-check form-check-inline ms-0 mx-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="option1"
                    //onChange={handleunassignedcheckbox}
                  />
                  <label className="form-check-label" for="inlineCheckbox1">
                    Copy Workitem Type In Activity Screen
                  </label>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <div className="form-check form-check-inline ms-0 mx-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="option1"
                    //onChange={handleunassignedcheckbox}
                  />
                  <label className="form-check-label" for="inlineCheckbox1">
                    Copy Workitem Type In Activity Screen
                  </label>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <div className="form-check form-check-inline ms-0 mx-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="option1"
                    //onChange={handleunassignedcheckbox}
                  />
                  <label className="form-check-label" for="inlineCheckbox1">
                    Copy Workitem Type In Activity Screen
                  </label>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <div className="form-check form-check-inline ms-0 mx-0">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value="option1"
                    //onChange={handleunassignedcheckbox}
                  />
                  <label className="form-check-label" for="inlineCheckbox1">
                    Copy Workitem Type In Activity Screen
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashBoxs">
        <div className="formWrap">
          <div className="row align-items-end">
            <div className="col-3">
              <div className="mb-3">
                <label className="form-label">Workitem Type</label>
                <select
                  className="form-control form-control-sm"
                  //onChange={FetchLocationClientsByGuid}
                >
                  <option>Select</option>
                  {/* {departments.map((department) => (
                            <option
                              value={
                                department.companyDepartment +
                                "/" +
                                department.departmentIDString
                              }
                              selected={
                                department.departmentIDString ==
                                companyDeparmentID
                              }
                            >
                              {department.companyDepartment}
                            </option>
                          ))} */}
                </select>
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <label className="form-label">Sub Activity</label>
                <select
                  className="form-control form-control-sm"
                  //onChange={FetchInvalidProcessSubprocessByGuid}
                >
                  <option>Select</option>
                  {/* {processSubprocess.map((subprocess) => (
                            <option
                              value={
                                subprocess.subProcessIDString +
                                "/" +
                                subprocess.processSubProcess
                              }
                              selected={
                                subprocess.subProcessIDString ==
                                processSubProcessID
                              }
                            >
                              {subprocess.processSubProcess}
                            </option>
                          ))} */}
                </select>
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <label className="form-label">Standard Time (in mins)*</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  //onChange={saveSkillsetname}
                  //value={skillsetName}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="mb-3">
                <button className="btn btn-outline-primary me-3">Add</button>
                {/* <label className="form-label">Standard Time (in mins)*</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  //onChange={saveSkillsetname}
                  //value={skillsetName}
                /> */}
              </div>
            </div>
          </div>
          <Typography>
            {/* <div className="pt-2 pb-2">
              <div className="mb-5"> */}
            {/* <div className="tableWrap">
              <div className="tableWrap-body"> */}
            <table
              className="table table-borderless MatTable"
              style={{
                zIndex: "1",
                position: "relative",
                padding: "0 !important",
              }}
            >
              <ThemeProvider theme={theme}>
                <MaterialTable
                  options={{
                    search: false,
                    tableLayout: "auto",
                    overflowY: false,
                    showTitle: false,
                    toolbarButtonAlignment: "left",
                    paging: false,
                    toolbar: false,
                    headerStyle: {
                      height: 30,
                    },
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                    },
                    tableLayout: "auto",
                    headerStyle: {},
                  }}
                  columns={advanceColumns}
                  icons={tableIcons}
                  //data={advSearchDetails}
                />
              </ThemeProvider>
            </table>
            {/* </div>
            </div> */}
            {/* </div>
            </div> */}
          </Typography>
        </div>
      </div>
      <div className="dashBoxs">
        <div className="formWrap">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                backgroundColor: "#eeeeee !important",
                height: "40px !important",
                padding: "5px !important",
                minHeight: "0px !important",
                width: "100% !important",
              }}
            >
              <Typography>
                <h6>Workitem Status</h6>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="row">
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                      //onChange={handleunassignedcheckbox}
                    />
                    <label className="form-check-label" for="inlineCheckbox1">
                      Activity 1
                    </label>
                  </div>
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                      //onChange={handleunassignedcheckbox}
                    />
                    <label className="form-check-label" for="inlineCheckbox1">
                      Activity 2
                    </label>
                  </div>
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                      //onChange={handleunassignedcheckbox}
                    />
                    <label className="form-check-label" for="inlineCheckbox1">
                      Activity 3
                    </label>
                  </div>
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                      //onChange={handleunassignedcheckbox}
                    />
                    <label className="form-check-label" for="inlineCheckbox1">
                      Activity 4
                    </label>
                  </div>
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                      //onChange={handleunassignedcheckbox}
                    />
                    <label className="form-check-label" for="inlineCheckbox1">
                      Activity 5
                    </label>
                  </div>
                  <div className="form-check form-check-inline ms-0 mx-0">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                      //onChange={handleunassignedcheckbox}
                    />
                    <label className="form-check-label" for="inlineCheckbox1">
                      Activity 6
                    </label>
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              sx={{
                backgroundColor: "#eeeeee !important",
                height: "40px !important",
                padding: "5px !important",
                minHeight: "0px !important",
                width: "100% !important",
              }}
            >
              <Typography>
                <h6>Sub Activites</h6>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              sx={{
                backgroundColor: "#eeeeee !important",
                height: "40px !important",
                padding: "5px !important",
                minHeight: "0px !important",
                width: "100% !important",
              }}
            >
              <Typography>
                <h6>Reminder Notification</h6>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
              sx={{
                backgroundColor: "#eeeeee !important",
                height: "40px !important",
                padding: "5px !important",
                minHeight: "0px !important",
                width: "100% !important",
              }}
            >
              <Typography>
                <h6>Work Activity Escalation Config</h6>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* <GlobalStyles
            styles={{
              ".MuiButtonBase-root.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26) !important",
                cursor: "default !important",
                PointerEvent: "none !important",
              },
              ".MuiButton-root": {
                color: "#1976d2 !important",               
              },
            }}
          /> */}
    </>
  );
};
export default WorkActivityConfig;

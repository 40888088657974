import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import { ToastProvider, useToasts } from "react-toast-notifications";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";

import Multiselect from 'multiselect-react-dropdown';

import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import zIndex from "@material-ui/core/styles/zIndex";


function CommonFieldMapping() {
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_COMMONMAPPINGWORKITEMFILEDS } = process.env;
  const { REACT_APP_SAVECOMMONMAPPINGFIELDSONEANDDONE } = process.env;
  const { REACT_APP_GETSAVEDCOMMONMAPPINGFIELDSONEANDDONE } = process.env;
  const { REACT_APP_DELETEONEANDDONEMAPPINGFIELD } = process.env;
  
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;

  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const { addToast } = useToasts();
  //#Santosh
  const [SelectedSubProcessIds, setSelectedSubProcessIds] = useState("");
  const [showdropdown, setshowdropdown] = useState(false);
  const ButtonTextRef = useRef("");
  const [selectedFieldsDashbord, setselectedFieldsDashbord] = useState("");
  const [selectedprocess, setselectedprocess] = useState("");
  

  const [SubProcessIds, setSubProcessIds] = useState("");
  const [CommaSeperatedIdsDashboards, setCommaSeperatedIdsDashboards] = useState("");
  const [CommaSeperatedIdsfilter, setCommaSeperatedIdsfilter] = useState("");
  const [CommaSeperatedIdsGroupingfilter, setCommaSeperatedIdsGroupingfilter] = useState("");
  const [CommonMappingFiledData, setCommonMappingFiledData] = useState([]);

  const [buttonText, setbuttonText] = useState("Save");

  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [numberOfRecord, setnumberOfRecord] = useState(100);

  const values = [];
  // ...
  // const options =
  //   [
  //     { name: 'Thing 1', value: 1 },
  //     { name: 'Thing 2', value: 2 }
  //   ];

  const onSelectSubProcessId = (selectedList, selectedItem) => {

    const strCommaSeperatedIds = selectedList.map(item => item.value).join(',');
    //console.log(selectedItem,selectedList)
    setSubProcessIds(strCommaSeperatedIds)
    FetchCommonMappingWorkItemdFields(strCommaSeperatedIds);

  }

  const onSelectDashboardMapping = (selectedList, selectedItem) => {

    const strCommaSeperatedIdsDashboard = selectedList.map(item => item.name).join(',');
    //console.log(selectedItem,selectedList)
   // var outputArray = selectedList.split(',').map(function(item) { return "'" + item.name + "'"; });
   //  var resultString = outputArray.join(',');
    //const b = "'" + strCommaSeperatedIdsDashboard.split( "," ).join( "','" ) + "'";
     //console.log(b);


    setCommaSeperatedIdsDashboards(strCommaSeperatedIdsDashboard)
    //FetchCommonMappingWorkItemdFields(strCommaSeperatedIdsDashboard);

  }

  const onSelectFilterMapping = (selectedList, selectedItem) => {

    const strCommaSeperatedIdsfilter = selectedList.map(item => item.name).join(',');
    //console.log(selectedItem,selectedList)
    //const c = "'" + strCommaSeperatedIdsfilter.split( "," ).join( "','" ) + "'";
    setCommaSeperatedIdsfilter(strCommaSeperatedIdsfilter)
    //FetchCommonMappingWorkItemdFields(strCommaSeperatedIdsfilter);

  }

  const onSelectGroupingMapping = (selectedList, selectedItem) => {

    const strCommaSeperatedIdsfilter = selectedList.map(item => item.name).join(',');
    setCommaSeperatedIdsGroupingfilter(strCommaSeperatedIdsfilter)

  }

  const onRemove = (selectedList, selectedItem) => {

    setSelectedSubProcessIds("");
    setSelectedSubProcessIds([]);
    setSubProcessIds("")
    showdropdown(false);
    const strCommaSeperatedIds = selectedList.map(item => item.value).join(',');
    //console.log(selectedItem,selectedList)
    setSubProcessIds(strCommaSeperatedIds)
    FetchCommonMappingWorkItemdFields(SubProcessIds);

  }

  // const resetForm = () => {

  //   setSelectedSubProcessIds("");
  //   setSelectedSubProcessIds([]);
  //   setSubProcessIds("")
  //   showdropdown(false);
  //   //FetchCommonMappingWorkItemdFields(SubProcessIds);
  //   //resetValues();
  //   //alert(errorCategoryNameRef.current.value);
  // };

  
  const multiselectRef = useRef();
  const multiselectRef2 = useRef();
  const multiselectRef3 = useRef();

  const resetSelectField = () => {
    ButtonTextRef.current.value = "Save";
    multiselectRef.current.resetSelectedValues();
    multiselectRef2.current.resetSelectedValues();
    multiselectRef3.current.resetSelectedValues();
    setSubProcessIds("");
    FetchCommonMappingWorkItemdFields(SubProcessIds);
  };

 
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [uploadedFile, setuploadedFile] = useState("");
  const [uploadedFileName, setuploadedFileName] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const [selectedFile, setselectedFile] = useState();
  const [UploadingFile, setUploadingFile] = useState("");
  const [ImgURL, setImgURL] = useState(undefined);
  const [SelectedClientLocation, setSelectedClientLocation] = useState("");
  const [AttachmentDetails, setAttachmentDetails] = useState([]);
  const { REACT_APP_UPLOADCLIENTLOGOFILEDETAILS } = process.env;
  const { REACT_APP_GETWORKITEMATTACHMENTS } = process.env;

  
  const [processnamelist, setprocessnamelist] = useState([]);

  const [ClientID, setClientID] = useState("");



  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  
  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    setSelectedSubProcessIds("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setSelectedSubProcessIds([]);
    }
    
    setSelectedLocation(e.target.value);
    setSelectedClientLocation(e.target.value.split("/")[1]);
   
    // sessionStorage.setItem("selectedLocation", e.target.value.split("/")[1]);
    // sessionStorage.setItem(
    //   "selectedLocationName",
    //   e.target.value.split("/")[0]
    // );
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value.split("/")[1],
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          setprocessnamelist(result.data);
          // console.log(processnamelist);
          //GetCommonMappingFieldsDetails();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          setprocessSubprocess([]);
        } else {
          setprocessSubprocess([]);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    setSelectedSubProcessIds("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setClientLocations([]);
      setSelectedSubProcessIds([]);
    }
    if (e.target.value !== "Select") {
      // sessionStorage.setItem(
      //   "selectedDepartment",
      //   e.target.value.split("/")[1]
      // );
      // sessionStorage.setItem(
      //   "selectedDepartmentName",
      //   e.target.value.split("/")[0]
      // );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
           
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };


  //=================#Santosh===========
  const FetchCommonMappingWorkItemdFields = (subprocessids) => {
    
    let body = {
      SubProcessListIds: subprocessids,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_COMMONMAPPINGWORKITEMFILEDS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setSelectedSubProcessIds(result.data);
          setshowdropdown(true);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };

  const updateColumns = (keys) => {
    // console.log(sessionStorage.getItem("EmployeeVersionID"));
    let col = [];
    keys.forEach((item) => {
      if (item != "updatedBy") col.push({ title: item, field: item });
    });
    //col.pop();
    //setcolumns(col);
  };
  
  const saveMappingWorkItemFieldsData = () => {
    let loginUrl;
    let body;
    if (buttonText == "Save") {
      loginUrl = getAPIURL(REACT_APP_SAVECOMMONMAPPINGFIELDSONEANDDONE);
      body = {
        SubProcessId: SubProcessIds,
        WorkItemFieldID: CommaSeperatedIdsDashboards,
        IsUniqueIdentifier: CommaSeperatedIdsfilter,
        IsUniqueIdentifierGrouping: CommaSeperatedIdsGroupingfilter,
        UpdatedBy: sessionStorage.getItem("PersonID"),
        CompanyDepartment:selectedDepartment,
        LocationClient:selectedLocation,
        ProcessSubProcess:selectedSubprocess,
      };
    } else {
      loginUrl = getAPIURL(REACT_APP_SAVECOMMONMAPPINGFIELDSONEANDDONE);
      body = {

        SubProcessId: SubProcessIds,
        WorkItemFieldID: CommaSeperatedIdsfilter,
        IsUniqueIdentifier: CommaSeperatedIdsfilter,
        IsUniqueIdentifierGrouping: CommaSeperatedIdsGroupingfilter,
        UpdatedBy: sessionStorage.getItem("PersonID"),
        CompanyDepartment:selectedDepartment,
        LocationClient:selectedLocation,
        ProcessSubProcess:selectedSubprocess,
      };
    }
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
           setClientID(selectedLocation);
           GetCommonMappingFieldsDetails(selectedLocation);
           addToast("Common Mapping Fields saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
         
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };


  const GetCommonMappingFieldsDetails= (clientId) => {
    let GetCommingMappingFieldURL = getAPIURL(REACT_APP_GETSAVEDCOMMONMAPPINGFIELDSONEANDDONE);
    let body;
    // console.log(clientId);
    body = {
      SubProcessListIds:"",
      ID:"",
      UpdatedBY:sessionStorage.PersonID,
      ClientId:clientId,
      MappingGroupID:""

    };
    let req1 = new Request(`${GetCommingMappingFieldURL}`, {
    
      method: "POST",
      
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      
      body: JSON.stringify(body),
     

      
    });
    fetch(req1)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setisLoadingFigma(false);
         // updateColumns(Object.keys(result.data[0]));
          setCommonMappingFiledData(result.data);
          setClientID(clientId);
          
          // var a=processSubprocess;
          // setprocessSubprocess
          const strdropdownitems = processSubprocess.map(item => item.name).join(',');
          // console.log(strdropdownitems);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };



   
 
  //======================


  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };
    
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          setClientID(clientId);
          GetCommonMappingFieldsDetails(clientId);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    //sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    setClientID(locationClientID);
    //sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
     //setClientID(clientId);
     
  };

  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // sessionStorage.setItem(
          //   "SelctedSubprocessName",
          //   result.data[0].processSubProcess
          // );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId"),
      setisLoadingFigma(false)
    );
    setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
    
    FetchDepartmentbyGUID();


  }, []);


  




  const columns = [
    {
      title: "SNo.",
      field: "serialNumber",
    },
    {
      title: "Location Client",
      field: "locationClient",
    },
    {
      title: "Process SubProcess",
      field: "processSubProcess",
      
    },
    {
      title: "Common Field Mapping For Dashboard",
      field: "commonFieldMappingForDashboard",
    },
    {
      title: "Common Field Mapping For Filter",
      field: "commonFieldMappingForFilter",
    },
    {
      title: "Common Field Mapping For Grouping Field",
      field: "commonfieldmappingforGrouping",
    },
    // {
    //   title: "GroupID",
    //   field: "mappingGroupID",
    // }
    
    // {
    //   title: "IsDeleted",
    //   field: "isDeleted",
    //   render: (rowData) => (
    //     <>
    //       <input
    //         type="checkbox"
    //         id="chkIsDeleted"
    //         disabled
    //         checked={rowData["isDeleted"]}
    //       />
    //     </>
    //   ),
    // },
  ];


  const editCommonMappingField = (item) => {
    //const str = "1,2,3,4,5,6";
  //   var temp = new Array();
  //  // This will return an array with strings "1", "2", etc.
  values = item.commonFieldMappingForDashboard.split(",");

    setselectedprocess(item.processSubProcess);
    // console.log(item.processSubProcess);
    setselectedFieldsDashbord(values);
    // isDeletedRef.current.checked = item.isDeleted;
    ButtonTextRef.current.value = "Update";
    setbuttonText("Update");
  };

  const DeleteCommonMappingField = (row) => {
    if (row.isDeleted) {
      addToast(
        "Common Mapping Field - " + row.serialNumber + " is already deleted.",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      return false;
    }

    if (
      window.confirm(
        "Are you sure you want to delete this Common Mapping Field " +
          " ?"
      )
    ) {
      let body = {
        ID: row.serialNumber,
        UpdatedBy: sessionStorage.PersonID,
        ClientId:ClientID,
        MappingGroupID: row.mappingGroupID
      };
      let loginUrl = getAPIURL(REACT_APP_DELETEONEANDDONEMAPPINGFIELD);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200 || result.status==404) {
            GetCommonMappingFieldsDetails(ClientID);
            addToast("Common Mapping Field deleted successfully", {
              appearance: "success",
              autoDismiss: true,
            });
          
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          addToast("Common Mapping Field deleted failed!", {
            appearance: "error",
            autoDismiss: true,
          });

          //navigate("/");
        });
    }
  };


  return (
    <>
      <Header onpreferenceShow={null} pageName="One-And-Done Common Field Mapping" />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="admin" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>

      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
            <h4 className="mb-4">Add/Edit | One-and-Done Common Field Mapping</h4>
              <div className="row align-items-end">
                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label"> Company Department</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchLocationClientsByGuid}
                    >
                      <option>Select</option>
                      {departments.map((department) => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.departmentIDString == companyDeparmentID
                          }
                        >
                          {department.companyDepartment}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">Location Client</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchProcessSubProcessByGuid}
                    >
                      <option>Select</option>
                      {clientLocations.map((location) => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.clientIDString == locationClientID}
                        >
                          {location.locationClient}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3" style={{zIndex:1000,position:"relative"}}>
                    <label className="form-label">Process SubProcess</label>

                    <Multiselect id="MultiSelectSubProcessId" showCheckbox="true"
                      options={processSubprocess.map((subprocess) =>
                      (
                        {
                          name: subprocess.processSubProcess,
                          value: subprocess.subProcessIDString
                        }

                       ))}
                      displayValue="name"
                       ref={multiselectRef}
                      onSelect={onSelectSubProcessId}
                      selectedValues={setselectedprocess} 
                      onRemove={onRemove}
                    />

                    <div className="row align-items-end">

                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="mb-3" style={{zIndex:1000,position:"relative"}}>

                  {showdropdown ? (
                    <label className="form-label">Common Field Mapping For Dashboard
                    </label>
                     ) : null}
                    {showdropdown ? (
                      <Multiselect id="MultiSelectFields" showCheckbox="true"

                        options={SelectedSubProcessIds.map((e) =>
                        (
                          {
                            name: e.labelText,
                            value: e.workItemFieldID
                          }

                        ))}
                        ref={multiselectRef2}
                        selectedValues={selectedFieldsDashbord} 
                        onSelect={onSelectDashboardMapping}
                      
                        displayValue="name" />
                    ) : null}

                  </div>
                </div>

                <div className="col-4">
                  <div className="mb-3" style={{zIndex:1000,position:"relative"}}>
                  {showdropdown ? (
                    <label className="form-label">Common Field Mapping For Filter

                    </label>
                     ) : null}
                    {showdropdown ? (
                      <Multiselect id="MultiSelectFields" showCheckbox="true"

                        options={SelectedSubProcessIds.map((f) =>
                        (
                          {
                            name: f.labelText,
                            value: f.workItemFieldID
                          }

                        ))}
                        onSelect={onSelectFilterMapping}
                        ref={multiselectRef3}
                        displayValue="name" />
                    ) : null}
                  </div>
                </div>

                <div className="col-4">
                  <div className="mb-3" style={{zIndex:1000,position:"relative"}}>
                  {showdropdown ? (
                    <label className="form-label">Common Field Mapping For Grouping Field

                    </label>
                     ) : null}
                    {showdropdown ? (
                      <Multiselect id="MultiSelectFields" showCheckbox="true"

                        options={SelectedSubProcessIds.map((f) =>
                        (
                          {
                            name: f.labelText,
                            value: f.workItemFieldID
                          }

                        ))}
                        onSelect={onSelectGroupingMapping}
                        ref={multiselectRef3}
                        displayValue="name" />
                    ) : null}
                  </div>
                </div>

                <div className="col-12 text-right">
                  <div  className="mb-3">
                   
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary ms-3"
                      ref={ButtonTextRef}
                      onClick={() => {
                        saveMappingWorkItemFieldsData();
                      }}

                    >
                      {buttonText}
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-3"
                      onClick={resetSelectField}
                    >
                      Reset
                    </button>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
      
    

      <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>One And Done Common Mapping Fields Details</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              {isLoadingFigma ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          // {
                          //   icon: () => <EditIcon />,
                          //   tooltip: "Edit Common Mapping Field",
                          //   onClick: (event, rowData) =>
                          //     editCommonMappingField(rowData),
                          //   position: "row",
                          // },
                           {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete",
                            onClick: (event, rowData) =>
                            DeleteCommonMappingField(rowData),
                            position: "row",
                          },
                        ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          pageSizeOptions: [10, 20, 50, 100],
                          exportButton: false,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,

                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          headerStyle: {
                            // height: 30,
                          },
                          cellStyle: {
                             width: 200,
                             minWidth: 200,
                             whiteSpace: "Normal",
                             warap:true,
                             
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            //                            fontSize: "13px",
                             whiteSpace: "wrap",
                          },
                          headerStyle: {
                            // fontFamily: '"Yantramanav", sans-serif',
                            // fontWeight: "inherit",
                            // fontSize: "14px",
                            // textAlign: "left !Important",
                          },
                        }}
                        // editable={{
                        //     onRowDelete: (oldData) =>
                        //       new Promise((resolve) => {
                        //         handleRowDelete(oldData, resolve);
                        //       }),
                        //   }}
                        columns={columns}
                        icons={tableIcons}
                        data={CommonMappingFiledData}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
            </div>
      <Footer />
    </>
  );
}

export default CommonFieldMapping;

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DashboardContext from "../../../../contexts/DashboardContext";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

const ExportConfiguration = () => {
  const location = useLocation();
  const processingDashboardParam = location.state;

  return (
    <>
      <Header />
      <DashboardContext.Provider value={processingDashboardParam.nav}>
        <Nav />
      </DashboardContext.Provider>
      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-4">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Company Department</label>
                    <select
                      className="form-control form-control-sm"
                      title="Select a field to apply filter"
                    >
                      <option>Select</option>
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Location Client</label>
                    <select className="form-control form-control-sm">
                      <option>Select</option>
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Process Sub-process</label>
                    <select className="form-control form-control-sm">
                      <option>Select</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-end">
              <button className="btn btn-outline-primary ms-3">Clear</button>
              <button className="btn btn-primary ms-3">Export</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ExportConfiguration;

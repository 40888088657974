import React, { useEffect, useState } from "react";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

import pdfViewer from "../../../../assets/images/placeholder/pdf_viewer.jpg";
import DashboardContext from "../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap-daterangepicker/daterangepicker.css";
//import WorkActivityScreenDetailsPage from "../WorkActivityScreen/WorkActivityScreenDetailsPage";
import UploadItems from "../ActivityScreen/UploadItems";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import Iframe from "react-iframe";
import LoadingSpinner from "../../../../LoadingSpinner";
import "react-table-filter/lib/styles.css";

import FormContext from "../../../../contexts/FormContext";
import { ToastProvider, useToasts } from "react-toast-notifications";

const Process = () => {
  const { addToast } = useToasts();
  const location = useLocation();
  let navigate = useNavigate();
  const { REACT_APP_SAVEUPLOADNEWITEM } = process.env;
  const { REACT_APP_GET_WORKITEMDETAILS } = process.env;
  const { REACT_APP_GET_EMPLOYEESFORSUBPROCESSACTIVITY } = process.env;
  const { REACT_APP_GET_GETSTATUSREASONSBYSUBPROCESSID } = process.env;
  const { REACT_APP_API_URL } = process.env;

  const { REACT_APP_SAVEUPLOADNEWITEMS } = process.env;

  const { REACT_APP_SAVETIMEONTICK } = process.env;
  let interval = null;
  const [isLoading, set_IsLoading] = useState(false);

  const [regGroup, setregGroup] = useState([]);

  const [workItemTypeList, setworkItemTypeList] = useState([]);
  const [workFlowStateList, setworkFlowStateList] = useState([]);
  const [activityname, setactivityname] = useState("");
  const [SubmitDisabled, setSubmitDisabled] = useState(false);
  const [SubmitAndContinueDisabled, setSubmitAndContinueDisabled] =
    useState(false);
  const [SubmitAndAddBlankItemDisabled, setSubmitAndAddBlankItemDisabled] =
    useState(false);
  const [SkipDisabled, setSkipDisabled] = useState(false);

  const [btnSubmitVisible, setbtnSubmitVisible] = useState(false);

  const [btnSubmitAndAddBlankItemVisible, setbtnSubmitAndAddBlankItemVisible] =
    useState(false);
  const [btnSkipVisible, setbtnSkipVisible] = useState(false);
  const [fieldClassName, setfieldClassName] = useState("col-4");
  const [divClassName, setdivClassName] = useState("splitfull");
  const [iframeactive, setiframeactive] = useState(false);
  const [JsonValues, setJsonValues] = useState([]);
  const [NeedRefresh, setNeedRefresh] = useState(false);
  const [statusReasonForSubprocess, setstatusReasonForSubprocess] = useState(
    []
  );

  //const [lbOnBaseDocVisible,setlbOnBaseDocVisible]=useState(false);
  //const [lbOnBaseDocumentText,setlbOnBaseDocumentText]=useState("");
  //const [ibcopyonbaseVisible,setibcopyonbaseVisible]=useState(false);
  const [divOnbaseDocUploadVisible, setdivOnbaseDocUploadVisible] =
    useState(false);
  const [uconbaseDocIsUploadChecked, setuconbaseDocIsUploadChecked] =
    useState(false);
  const [divOnbaseDocAppendVisible, setdivOnbaseDocAppendVisible] =
    useState(false);
  const [ucOnbaseDocAppendDocID, setucOnbaseDocAppendDocID] = useState(0);
  const [OnbaseUploadDocURL, setOnbaseUploadDocURL] = useState("");
  //const [AllowAppendImage,setAllowAppendImage]=useState(false);
  const [OnBaseDocumentID, setOnBaseDocumentID] = useState(0);
  const [OnBaseUserID, setOnBaseUserID] = useState("");
  const [OnBasePassword, setOnBasePassword] = useState("");

  const [AttachmentDetails, setAttachmentDetails] = useState([]);
  const [isReason, setReason] = useState(false);
  const [isComment, setisComment] = useState(true);
  const [statusAssignedTo, setstatusAssignedTo] = useState("");
  const [statusReason, setstatusReason] = useState([]);
  const [statusComment, setstatusComment] = useState("");
  const [assignTo, setAssignTo] = useState(false);
  const [nextWorkItemId, setnextWorkItemId] = useState("");
  const [inputvaluesBody, setinputvaluesBody] = useState([]);
  const [workitem_Id, setworkitem_Id] = useState(0);
  const [hfActivityName, sethfActivityName] = useState("");
  const [ddlStatusId, setddlStatusId] = useState(0);
  const [hdnOverrideUniqueCheck, sethdnOverrideUniqueCheck] = useState("0");
  const [ddlWorkItemTypeValue, setddlWorkItemTypeValue] = useState("");
  const [uploadedFileName, setuploadedFileName] = useState("");
  const [UploadingFile, setUploadingFile] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [IsUploadOrAppend, setIsUploadOrAppend] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const [UploadDocumetId, setUploadDocumetId] = useState(0);
  const [timerStart, settimerStart] = useState("");
  const [btnShowBreakPanelDisabled, setbtnShowBreakPanelDisabled] =
    useState(false);
  const [btnShowBreakPanelColor, setbtnShowBreakPanelColor] = useState("Gray");
  const [ddlActivityEnabled, setddlActivityEnabled] = useState(false);
  const [objdataview, setobjdataview] = useState([]);
  const [ddlActivitySelectedValue, setddlActivitySelectedValue] = useState(0);
  const [txtBreakCommentsEnabled, settxtBreakCommentsEnabled] = useState(false);
  const [timerValue, settimerValue] = useState(0);
  const [hdnSystemBreakValue, sethdnSystemBreakValue] = useState("");
  const [imgActivityToolTip, setimgActivityToolTip] = useState("");
  const [tSaveTimerEnabled, settSaveTimerEnabled] = useState(false);
  const [breakStartTime, setbreakStartTime] = useState("");
  const [btnStartWorkTimerDisabled, setbtnStartWorkTimerDisabled] =
    useState(false);
  const [employeeTimeUtilization, setemployeeTimeUtilization] = useState([]);
  const [activityName, setactivityName] = useState(
    sessionStorage.getItem("activityName")
  );
  const [workflowStateID, setworkflowStateID] = useState(
    sessionStorage.getItem("workflowstateId")
  );
  const [AttchmentModal, setAttchmentModal] = useState(false);

  const [IFrameURL, setIFrameURL] = useState("");

  const modeRef = React.useRef(null);
  const actRef = React.useRef(null);

  const onModeClick = (e) => {
    modeRef.current.classList.toggle("show-hide");
    actRef.current.classList.toggle("active");
    accRef.current.classList.remove("show-hide1");
    activeRef.current.classList.remove("active");
  };
  const accRef = React.useRef(null);
  const activeRef = React.useRef(null);
  const onToggleClick = (e) => {
    accRef.current.classList.toggle("show-hide1");
    activeRef.current.classList.toggle("active");
    modeRef.current.classList.remove("show-hide");
    actRef.current.classList.remove("active");
  };

  // MODAL ONCLICK TOGGLE FUNCTION
  const [breakModal, setShow] = useState(false);

  const breakClose = () => setShow(false);
  const breakShow = () => setShow(true);

  const [workitemModal, setShow1] = useState(false);

  const workitemClose = () => setShow1(false);
  const workitemShow = () => setShow1(true);
  const showAttachmentDetails = () => {
    setAttchmentModal(true);
  };
  const AttachmentClose = () => {
    setAttchmentModal(false);
  };
  let processingDashboardParam = [
    {
      row: [],
      selectedSubprocess: "",
      nav: [],
      userRoles: [],
      defaultFilter: "",
      assignedWorkItemDetails: [],
    },
  ];
  //if (sessionStorage.getItem("is_Authenticated") == "true") {
  processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const columns = [
    {
      title: "Uploaded By",
      field: "uploadedBy",
    },
    {
      title: "Uploaded FileName",
      field: "uploadedFileName",
    },
    { title: "Uploaded On", field: "uploadedOn" },
  ];
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  let disabledStyle = {
    display: "none",
  };

  const [NextStepID, setNextStepID] = useState(0);
  const [workItemTypeValue, setworkItemTypeValue] = useState("");

  const nextStateChange = (e) => {
    setNextStepID(e.target.value);
  };

  const workItemTypeListChange = (e) => {
    setworkItemTypeValue(e.target.value);
  };

  const handelSubmit = (e) => {
    // debugger;
    let isSubmit = true;
    set_IsLoading(true);
    e.preventDefault();

    if (NextStepID == "Select Next State") {
      addToast("Next State is required!", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    if (NextStepID == 0) {
      addToast("Next State is required!", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
      // return false;
    }

    let inputalues = [];

    if (JsonValues.length >= 0) {
      JsonValues.forEach((element) => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (
            element.screenFields[i].value === "" &&
            (element.screenFields[i].dataType === "System.Decimal" ||
              element.screenFields[i].dataType === "System.Double" ||
              element.screenFields[i].dataType === "System.Float")
          ) {
            element.screenFields[i].value = "0.0";
          }
          if (
            element.screenFields[i].value === "" &&
            element.screenFields[i].dataType === "System.Int32"
          ) {
            element.screenFields[i].value = "0";
          }
          if (
            element.screenFields[i].allowBlank &&
            element.screenFields[i].value === ""
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true,
            });
            isSubmit = false;
          } else {
            const input = {
              mapToTable: element.screenFields[i].mapToTable,
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          }
        }
      });
    } else {
      JsonValues.state.regGroup.forEach((element) => {
        for (let i = 0; i < element.screenFields.length; i++) {
          if (
            element.screenFields[i].allowBlank &&
            element.screenFields[i].value === ""
          ) {
            addToast(element.screenFields[i].labelText + " is required!", {
              appearance: "error",
              autoDismiss: true,
            });
            isSubmit = false;
          } else {
            const input = {
              mapToTable: "",
              mapToField: element.screenFields[i].mapToField,
              value: element.screenFields[i].value,
            };
            inputalues.push(input);
          }
        }
      });
    }

    if (isSubmit) {
      let workItemIDInputParam =
        nextWorkItemId == ""
          ? +processingDashboardParam.workItemID
          : nextWorkItemId;
      let body = {
        inputValues: inputalues,
        nextState: NextStepID, //"Processing",
        SubProcessID: processingDashboardParam.selectedSubprocess,
        PersonID: sessionStorage.getItem("PersonID"),
        workItemTypeValue: workItemTypeValue,
      };
      let loginUrl = getAPIURL(REACT_APP_SAVEUPLOADNEWITEMS);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            backToDashBoard();
            set_IsLoading(false);
          } else if (result.status === 401) {
            //console.log("ERROR: ", error);
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            addToast(result.message, {
              appearance: "info",
              autoDismiss: true,
            });
            set_IsLoading(false);
          }
        })
        .catch((error) => {
          // debugger;
          // if (result.status === 401) {
          console.log("ERROR: ", error);
          //navigate("/");
          //}
        });
    } else {
      set_IsLoading(false);
    }
  };

  const handelChange = (id, event, oldElements) => {
    const newElements = { ...oldElements };
    newElements.state.regGroup.forEach((element) => {
      for (let i = 0; i < element.screenFields.length; i++) {
        if (element.screenFields[i].mapToField === id) {
          element.screenFields[i].value = event.target.value;
        }
      }
    });

    setJsonValues(newElements);
  };
  const backToDashBoard = () => {
    const row = processingDashboardParam.row;
    const selectedSubprocess = processingDashboardParam.selectedSubprocess;
    const nav = processingDashboardParam.nav;
    const userRoles = processingDashboardParam.userRoles;
    const defaultFilter = processingDashboardParam.defaultFilter;
    const assignedWorkItemDetails =
      processingDashboardParam.assignedWorkItemDetails;
    let activityName = "";
    if (typeof row.activityName === "undefined") {
      activityName = processingDashboardParam.currentAcitvity;
    } else {
      activityName = row.activityName;
    }
    navigate("/WorkActivityDashboard", {
      state: {
        row,
        selectedSubprocess,
        nav,
        userRoles,
        defaultFilter,
        assignedWorkItemDetails: navData,
        selectedWorkItemDetails: navData.find(
          (e) => e.activityName === activityName
        ),
        currentAcitvity: activityName,
      },
    });
  };

  const [isWorkItemTypeList, setIsWorkItemTypeList] = useState(false);

  useEffect(() => {
    // debugger;
    //FetchBreakTimerOnLoad();
    setworkitem_Id(processingDashboardParam.workItemID);
    set_IsLoading(true);
    let body = {
      WorkflowStateID: processingDashboardParam.workflowstateID,
      subProcessID: processingDashboardParam.selectedSubprocess,
    };
    let screenAPIURL = getAPIURL(REACT_APP_SAVEUPLOADNEWITEM);
    let req = new Request(`${screenAPIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setJsonValues(result.data.screenFieldGroups.sort((a, b) => a.groupName.localeCompare(b.groupName, undefined, { numeric: true, sensitivity: 'base' })));
          setregGroup(result.data.screenFieldGroups.sort((a, b) => a.groupName.localeCompare(b.groupName, undefined, { numeric: true, sensitivity: 'base' })));
         
          setactivityname(result.data.hfActivityName);
          setworkFlowStateList(result.data.workFlowStateList);
          if (result.data.workItemTypeList.length > 0) {
            setworkItemTypeList(result.data.workFlowStateList);
            setIsWorkItemTypeList(true);
          } else {
            setIsWorkItemTypeList(false);
          }

          set_IsLoading(false);
        } else if (result.status === 401) {
          //console.log("ERROR: ", error);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("WorkItemLocked 'This workitem is locked by another user!", {
            appearance: "error",
            autoDismiss: true,
          });
          setisComment(false);
          set_IsLoading(false);
          //backToDashBoard()
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
      });

    return () => {
      clearInterval(interval);
      //   alert('unmount')
    };
  }, [NeedRefresh]);

  return (
    <>
      <Header />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="activities" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <FormContext.Provider value={{ onchange: handelChange }}>
        <div className="centerSection">
          <form>
            {/* One Scenario */}
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <div className="splitScreen">
                  <div className={divClassName}>
                    <div className="fieldBody">
                      <h3 className="mb-4 pt-2 pb-3 px-2">Add New WorkItem</h3>

                      <UploadItems state={{ regGroup, fieldClassName }} />

                      <div className="row">
                        <div className="col-3">
                          {isWorkItemTypeList ? (
                            <div className="mb-3">
                              <label className="form-label">
                                Workitem Type
                              </label>
                              <select
                                className="form-control form-control-sm"
                                onChange={workItemTypeListChange}
                              >
                                <option value="0">Select Workitem Type</option>
                                {workItemTypeList.map((status) => (
                                  <option value={status.workItemTypeID}>
                                    {status.workItemType}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-3">
                          <div className="mb-3">
                            <label className="form-label">Next State *</label>
                            <select
                              className="form-control form-control-sm"
                              onChange={nextStateChange}
                            >
                              <option value="0">Select Next State</option>
                              {workFlowStateList.map((status) => (
                                <option value={status.workflowStateID}>
                                  {status.workflowStateName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fieldFooter col-4">
                      <div className="row">
                        <div className="col-6 text-start">
                          {isComment ? (
                            <button
                              className="btn btn-outline-primary ms-3"
                              disabled={SubmitAndContinueDisabled}
                              title="Click here to submit this workitem and go to the next WorkItem"
                              onClick={handelSubmit}
                            >
                              Submit
                            </button>
                          ) : null}
                          {btnSubmitVisible ? (
                            <button
                              className="btn btn-outline-primary ms-3"
                              disabled={SubmitDisabled}
                              title="Click here to submit this workitem and go back to dashboard"
                              onClick={handelSubmit}
                            >
                              Submit
                            </button>
                          ) : null}
                          {isComment ? (
                            <button
                              className="btn btn-outline-primary ms-3"
                              title="Click here to go back to the Dashboard"
                              onClick={backToDashBoard}
                            >
                              Back to dashboard
                            </button>
                          ) : null}{" "}
                        </div>
                        <div className="col-2 text-center">
                          {btnSkipVisible ? (
                            <button
                              className="btn btn-outline-primary ms-3"
                              disabled={SkipDisabled}
                            >
                              Skip
                            </button>
                          ) : null}
                          {btnSubmitAndAddBlankItemVisible ? (
                            <button
                              className="btn btn-outline-primary ms-3"
                              disabled={SubmitAndAddBlankItemDisabled}
                              title="Click here to submit this workitem"
                            >
                              Submit
                            </button>
                          ) : null}{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      iframeactive == false ? "display-none" : "splitRight"
                    }
                  >
                    <Iframe
                      url={IFrameURL}
                      width="100%"
                      height="100%"
                      id="myId"
                      display="initial"
                      position="relative"
                    />
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </FormContext.Provider>
      <Footer />
    </>
  );
};

export default Process;

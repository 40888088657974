function UnsecuredApp() {
  return (
    <>
      <div>
        <h1 style={{ color: "red" }}>
          If you see this page, Production Dashboard link you have clicked on is
          under Clickjacking security attack.
        </h1>
        <h2>Please report this to EXL ISG team.</h2>
        <h2>
          Click{" "}
          <a
            style={{ "text-decoration": "none" }}
            href={window.self.location.href}
            title="Custom Workflow Solutions"
            target="blank"
          >
            here
          </a>{" "}
          to access Production Dashboard safely.
        </h2>
      </div>
    </>
  );
}

export default UnsecuredApp;

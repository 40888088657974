import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from "react";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField
} from "@material-ui/core";
import NavDataContext from "../../../../contexts/NavDataContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Toast } from "bootstrap";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import AddNewMoveWorkitem from "./AddNewMoveWorkitem";
import { Link, Navigate, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import exportFromJSON from "export-from-json";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {
  DownloadExcel,
  ArrayWithoutExtraColumn
} from "../../Comman/ExcelFileDownload";

function SubProcessMoveWorkitem() {
  let tableRef = React.useRef();
  const { addToast } = useToasts();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const selectedSubprocess = processingDashboardParam.selectedSubprocess;
  const nav = processingDashboardParam.nav;
  const userRoles = processingDashboardParam.userRoles;
  const selectedWorkItemDetails = processingDashboardParam.selectedWorkItemDetails;
  let row = [];
  let currentAcitvity = "";
  if (typeof processingDashboardParam.row.activityName !== "undefined") {
      currentAcitvity = processingDashboardParam.row.activityName;
  } else {
      currentAcitvity = processingDashboardParam.currentAcitvity;
  }
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
      navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
      navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  let defaultFilter =
    "wi.UploadDate between dateadd(day,-10,getdate()) and getdate()";
  let assignedWorkItemDetails = React.useContext(NavDataContext);
  let selectedAssignWorkitem = {};
  const currentPage = window.location.href;
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [DisplayCriteria, setDisplayCriteria] = useState("all");
  const [selectedcriteria, setselectedcriteria] = useState('0')
  const { React_APP_UpdateSubProcessChangeConfig } = process.env;
  const { React_APP_DeleteSubProcessChangeConfig } = process.env;
  const { React_APP_FetchWorkItemFieldsForChangeConfig } = process.env;
  const { React_APP_FetchSubProcessChangeConfig } = process.env;
  const { React_APP_FetchChangeDetailsBySubProcessID } = process.env;
  const { REACT_APP_API_URL } = process.env;

  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const DeleteUser = (e) => {
    let APIURL = getAPIURL(React_APP_DeleteSubProcessChangeConfig);
    let inputbody = {
      oldSubProcessID: e.oldSubProcessID,
      newSubProcessID: e.newSubProcessID,
    };
    let req = new Request(`${APIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(inputbody),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          addToast("Sub-Process Change Configuration Deleted Successfully.", {
            appearance: "error",
            autoDismiss: true,
          });
          getTableData();
        } else if (result.status === 401) {
          settableData(result.data);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          settableData(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }
  const DisplayCriteriaOnChange = (e) => {
    sessionStorage.setItem("DisplayCriteria", e.target.value);
    getTableData();
  }

  const getTableData = () => {
    setIsTableLoading(true);
    let APIURL = getAPIURL(React_APP_FetchSubProcessChangeConfig);
    if (sessionStorage.getItem("DisplayCriteria") == 'subprocess') {
      let input = processingDashboardParam.selectedSubprocess;
      sessionStorage.setItem("SelectedCriteria", input);
    } else if (sessionStorage.getItem("DisplayCriteria") == 'process') {
      let longSubProcessId = Number(processingDashboardParam.selectedSubprocess)
      let input = (Math.floor(longSubProcessId / 10000) * 10000)
      let stringInput = input.toString();
      sessionStorage.setItem("SelectedCriteria", stringInput);
    } else if (sessionStorage.getItem("DisplayCriteria") == 'client') {
      let input = sessionStorage.getItem("selectedLocation");
      sessionStorage.setItem("SelectedCriteria", input);
    } else if (sessionStorage.getItem("DisplayCriteria") == 'all') {
      let input = '0';
      sessionStorage.setItem("SelectedCriteria", input);
    }
    let inputbody = {
      userId: sessionStorage.getItem("GUID"),
      Filterkey: sessionStorage.getItem("DisplayCriteria"),
      Filtervalue: sessionStorage.getItem("SelectedCriteria")
    };
    let req = new Request(`${APIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(inputbody),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setIsTableLoading(false);
          settableData(result.data);
        } else if (result.status === 401) {
          settableData(result.data);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        } else {
          settableData(result.data);
          setIsTableLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }

  const handleButtonClick = (e) => {
    const colToRemove = new Set(["delete", "edit","tableData"]);
    const sheetData = ArrayWithoutExtraColumn(tableData, colToRemove);
    DownloadExcel(sheetData, "MoveWorkItemConfigData");
  }
  const navigate = useNavigate();
  const AddNewMoveWorkitem = (e) => {
    const flag = 0;
    navigate("/AddNewMoveWorkitem", {
      state: {
        row: row,
        selectedSubprocess,
        nav,
        userRoles,
        defaultFilter,
        assignedWorkItemDetails: selectedAssignWorkitem,
        employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
        flag,
      }
    });
  }
  const UpdateUser = (e) => {
    const flag = 1;
    const newID = e.newSubProcessID;
    const oldID = e.oldSubProcessID;
    let longSubProcessId = Number(newID);
    let locationinput = (Math.floor(longSubProcessId / 100000000) * 100000000);
    let departmentinput = (Math.floor(longSubProcessId / 100000000000000) * 100000000000000);
    let stringlocationID = locationinput.toString();
    let stringdepartmentID = departmentinput.toString();
    navigate("/AddNewMoveWorkitem", {
      state: {
        row: row,
        selectedSubprocess,
        nav,
        userRoles,
        defaultFilter,
        assignedWorkItemDetails: selectedAssignWorkitem,
        employeeVersionID: sessionStorage.getItem("EmployeeVersionID"),
        flag,
        oldID,
        newID,
        stringlocationID,
        stringdepartmentID,
      }
    });
  }
  useEffect(() => {
    let APIURL = getAPIURL(React_APP_FetchSubProcessChangeConfig);
    let inputbody = {
      UserID: sessionStorage.getItem("GUID"),
      FilterKey: 'all',
      FilterValue: '0',
    };
    let req = new Request(`${APIURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(inputbody),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          settableData(result.data);
        } else if (result.status === 401) {
          settableData(result.data);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }, []);
  let columns = [
    { title: "Client Name", field: "clientName" },
    { title: "Process Name", field: "processName" },
    { title: "SubProcess Name", field: "subProcessName" },
    { title: "Old SubProcess ID", field: "oldSubProcessID" },
    { title: "New SubProcess ID", field: "newSubProcessID" },
    { title: "New SubProcess Name", field: "newSubProcessName", cellStyle: { width: "15%", } },
    { title: "Change ID", field: "changeID" }
  ];
  return (
    <><Header
      onpreferenceShow={null}
      pageName="SubProcess Move Workitem"
      timeCaptureActivityName="MoveWorkItem"
    />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="bussinessrules" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      {isLoading ? (
        <LoadingSpinnerFigma />
      ) : (
        <>
          <div className="centerSection">
            <div className="pageBody">
              <div className="dashBoxs">
                <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                  <div className="row align-items-center pb-1">
                    <div className="col-4">
                      <h6>SubProcess Move Workitem Config</h6></div>
                    <div className="col-4">
                      <div className="d-inline">
                        <div className="input-group input-group-sm">
                          <label
                            className="input-group-text"
                            for="inputGroupSelect01"
                          >
                            Display Criterion
                          </label>
                          <select
                            className="form-select font-Standard-11"
                            id="inputGroupSelect01"
                            onChange={DisplayCriteriaOnChange}
                            title="Display Criterion"
                          >
                            {(
                              <>
                                <option value="subprocess">
                                  Sub-Process
                                </option>
                                <option value="process">
                                  Process
                                </option>
                                <option value="client">Client</option>
                                <option selected value="all">All</option>
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="d-inline">
                        {(
                          <button
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                              handleButtonClick();
                            }}
                            title="Click here to export this table"
                          >
                            <b></b>Export
                          </button>
                        )}
                      </div>
                      <div className="d-inline">
                        {(
                          <button
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                              AddNewMoveWorkitem();
                            }}
                            title="Click here to add new Move Workitem Configuration"
                          >
                            <b className="icon-plus"></b>New SubProcess Move Workitem Config
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {isTableLoading ? (
                  <LoadingSpinnerFigma />
                ) : (
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">

                      <MaterialTable
                        localization={{
                          pagination: {
                          },
                          toolbar: {
                            exportCSVName: "Export as Excel",
                            exportPDFName: "Export as pdf"
                          },
                          header: {
                            actions: ""
                          }
                        }}
                        actions={[
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete",
                            onClick: (event, rowData) => DeleteUser(rowData),
                            position: "row",
                          },
                          {
                            icon: () => <EditIcon />,
                            tooltip: "Edit",
                            onClick: (event, rowData) => UpdateUser(rowData),
                            position: "row",
                          }
                        ]}
                        tableRef={tableRef}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px"
                          },
                          maxBodyHeight: "420px",
                          showTitle: false,
                          exportButton: false,
                          sorting: true,
                          search: false,
                          selection: false,
                          toolbarButtonAlignment: "left",
                          overflowY: false,
                          headerStyle: {
                            height: 30
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px"
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important"
                          }
                        }}
                        title="Move WorkItem Details"
                        columns={columns}
                        icons={tableIcons}
                        data={tableData}
                        components={{
                          OverlayLoading: props => <LoadingSpinnerFigma />,
                          Container: props => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: props => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em"
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{ fontSize: "5px" }}
                              />
                            </div>
                          )
                        }}
                      />
                    </table>
                  </div>)}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SubProcessMoveWorkitem;
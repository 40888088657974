import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import FormContext from "../../../../contexts/FormContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import UserClassificationListGridDetailsPage from "../BusinessRules/UserClassificationListGridDetailsPage";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";

import MuiAlert from "@material-ui/lab/Alert";
import EditIcon from "@mui/icons-material/Edit";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function SubProcessUserClassificationList() {
  const { addToast } = useToasts();
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;
  const { REACT_APP_GETWORKITEMFIELDSBYSUBPROCESSIDFORCLASSIFICATION } =
    process.env;
  const { REACT_APP_GETSAVEPRIORITYDETAILS } = process.env;
  const { REACT_APP_DELETEPRIORITYDETAILS } = process.env;
  const { REACT_APP_SAVESKILLSETCONFIG } = process.env;
  const { REACT_APP_GETSKILLSETCONFIGFOREDIT } = process.env;
  const { REACT_APP_UPDATESKILLSETCONFIG } = process.env;

  let navigate = useNavigate();
  const location = useLocation();

  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [Isdepartments, setIsDepartments] = useState(false);
  const [clientLocations, setClientLocations] = useState([]);
  const [IsclientLocations, setIsClientLocations] = useState(false);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [IsprocessSubprocess, setIsprocessSubprocess] = useState(false);
  const [showBtn, setUserButtonShow] = useState(false);
  const [assignedWorkItemDetails, setAssignedWorkItemDetails] = useState([]);
  const [allowLocation, setAllowLocation] = useState(false);
  const [allowSubprocess, setAllowSubprocess] = useState(false);
  const [showModalButton, setShowModalButton] = useState(false);
  const [ActivitiesForSubprocess, setActivitiesForSubprocess] = useState([]);
  const [IsActivitiesForSubprocess, setIsActivitiesForSubprocess] =
    useState(false);
  const [skillsetName, setskillsetName] = useState("");
  const [IsskillsetName, setIsskillsetName] = useState(false);
  const [SkillSetId, setSkillSetId] = useState("");
  const [ActivityId, setActivityId] = useState("");
  const [PriortyForSubProcessDetails, setPriortyForSubProcessDetails] =
    useState([]);
  const [PriorityJson, setPriorityJson] = useState([]);
  const [
    WorkItemFieldsbySubProcessIDForClassification,
    setWorkItemFieldsbySubProcessIDForClassification,
  ] = useState([]);
  const [GetSavePriorityGridDetails, setGetSavePriorityGridDetails] = useState(
    []
  );
  const [Isresolve, setIsresolve] = useState(true);
  const [IsEdit, setIsEdit] = useState(false);
  const [JsonValues, setJsonValues] = useState([]);
  const columns = [
    {
      title: "SkillSet ID",
      field: "skillSetID",
      hidden: true,
    },
    {
      title: "Rules",
      field: "rules",
      hidden: true,
    },
    {
      title: "Clasification Name",
      field: "skillSetName",
    },
    {
      title: "Condition",
      field: "condition",
      render: (row) => (
        <div dangerouslySetInnerHTML={{ __html: row["condition"] }}></div>
      ),
    },
    {
      title: "Activity Name",
      field: "activityName",
    },
  ];

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const saveSkillsetname = (e) => {
    setskillsetName(e.target.value);
    e.target.value != "" ? setIsskillsetName(true) : setIsskillsetName(false);
  };
  const FetctActivityID = (e) => {
    setActivityId(e.target.value);
    e.target.value != "Select"
      ? setIsActivitiesForSubprocess(true)
      : setIsActivitiesForSubprocess(false);
  };
  const handelChange = (event,dropdownValue, oldElements) => {
    // console.log("inside handle change")
    
    var newElements = { ...oldElements};
    newElements.state.WorkItemFieldsbySubProcessIDForClassification.forEach(
      (element) => {
        if (element.maptofield == event.target.id) {
          element.fieldValues = event.target.value;
          element.operator = dropdownValue;
        }
      }
    );
    setJsonValues(newElements);
    
    setWorkItemFieldsbySubProcessIDForClassification(newElements.state.WorkItemFieldsbySubProcessIDForClassification);
  };



  const GetSkillSetConfig = (skillId) => {
    setIsEdit(true);
    setWorkItemFieldsbySubProcessIDForClassification([]);
    GetWorkItemFieldsbySubProcessIDForClassification(processSubProcessID);
    let body = {
      skillSetId: "" + skillId,
      subProcessID: processSubProcessID,
    };

    let loginUrl = getAPIURL(REACT_APP_GETSKILLSETCONFIGFOREDIT);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          let editedGrid = result.data.filter((data) => data.skillSetID > 0);
          setSkillSetId(editedGrid[0].skillSetID);
          setskillsetName(editedGrid[0].skillSetName);
          setActivityId("" + editedGrid[0].activityId);
          let OldGrid = WorkItemFieldsbySubProcessIDForClassification;
          let newGrid = OldGrid.map((obj) => {
            for (let i = 0; i < editedGrid.length; i++) {
              if (obj.labeltext == editedGrid[i].labeltext) {
                obj.fieldValues = editedGrid[i].fieldValues;
              } else {
                if (editedGrid.some((el) => el.labeltext === obj.labeltext)) {
                  if (obj.fieldValues == "") {
                    obj.fieldValues = "";
                  }
                } else {
                  obj.fieldValues = "";
                }
              }
            }
            return obj;
          });
          
          setWorkItemFieldsbySubProcessIDForClassification(newGrid);
          setIsresolve(true);
        } else if (result.status === 401) {
          setIsresolve(true);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        setIsresolve(true);
        console.log("ERROR: ", error);
      });
  };
  const EditSkillSet = () => {
    let skillSetConfigRequest = [];
    setActivitiesForSubprocess([]);
    JsonValues.state.WorkItemFieldsbySubProcessIDForClassification.forEach(
      (element) => {
        if (element.fieldValues != "") {
          const input = {
            wfid: "" + element.workitemfieldid,
            cn: "Equals",
            ve: "" + element.fieldValues,
          };
          skillSetConfigRequest.push(input);
        }
      }
    );
    let body = {
      skillSetId: "" + SkillSetId,
      skillSetName: skillsetName,
      _SaveSkillSetConfigRequestXML: skillSetConfigRequest,
      updatedBy: sessionStorage.getItem("PersonID"),
    };

    let loginUrl = getAPIURL(REACT_APP_UPDATESKILLSETCONFIG);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setIsEdit(false);
          GetSavePriorityDetails(processSubProcessID);
          setskillsetName("Select");
          setActivityId("");
          GetWorkItemFieldsbySubProcessIDForClassification(processSubProcessID);
          GetActivitiesForSubprocess(processSubProcessID);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const SaveSkillSet = () => {
    
    let skillSetConfigRequest = [];
    setActivitiesForSubprocess([]);
    // console.log(JsonValues);
    JsonValues.state.WorkItemFieldsbySubProcessIDForClassification.forEach(
      (element) => {
        if (element.fieldValues != "") {
          const input = {
            wfid: "" + element.workitemfieldid,
            cn: element.operator,
            ve: "" + element.fieldValues,
          };
          skillSetConfigRequest.push(input);
        }
      }
    );
    let body = {
      subProcessID: processSubProcessID,
      skillSetName: skillsetName,
      _SaveSkillSetConfigRequestXML: skillSetConfigRequest,
      activityId: ActivityId,
      updatedBy: sessionStorage.getItem("PersonID"),
    };

    let loginUrl = getAPIURL(REACT_APP_SAVESKILLSETCONFIG);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          GetSavePriorityDetails(processSubProcessID);
          setskillsetName("");
          setActivityId("");
          GetWorkItemFieldsbySubProcessIDForClassification(processSubProcessID);
          GetActivitiesForSubprocess(processSubProcessID);
          //setdropdownValue("Equals")
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          setIsDepartments(true);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };
  const GetSavePriorityDetails = (subProcessID) => {
    let body = {
      subProcessID: subProcessID,
    };
    let getWorkitemFieldUrl = getAPIURL(REACT_APP_GETSAVEPRIORITYDETAILS);
    let req = new Request(`${getWorkitemFieldUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setGetSavePriorityGridDetails(result.data);
        } else {
          setGetSavePriorityGridDetails([]);
        }
      })
      .catch((error) => {
        setGetSavePriorityGridDetails([]);
        console.log("ERROR: ", error);
      });
  };
  const GetWorkItemFieldsbySubProcessIDForClassification = (subProcessID) => {
    //setWorkItemFieldsbySubProcessIDForClassification([])
    let body = {
      subProcessID: subProcessID,
    };
    let getWorkitemFieldUrl = getAPIURL(
      REACT_APP_GETWORKITEMFIELDSBYSUBPROCESSIDFORCLASSIFICATION
    );
    let req = new Request(`${getWorkitemFieldUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setWorkItemFieldsbySubProcessIDForClassification(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchInvalidProcessSubprocessByGuid = async (e) => {
    if (e.target.value == "Select") {
      setShowModalButton(false);
      setActivitiesForSubprocess([]);
      setPriortyForSubProcessDetails([]);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
    }
    if (e.target.value !== "Select") {
      setShowModalButton(true);
      setIsprocessSubprocess(true);
      setSelectedSubprocess(e.target.value.split("/")[0]);
      GetWorkItemFieldsbySubProcessIDForClassification(
        e.target.value.split("/")[0]
      );
      GetSavePriorityDetails(e.target.value.split("/")[0]);
      GetActivitiesForSubprocess();
    }
  };
  const GetActivitiesForSubprocess = () => {
    let body = {
      subProcessID: sessionStorage.getItem("ProcessId"),
    };
    let activityUrl = getAPIURL(REACT_APP_GETACTIVITIESFORSUBPROCESS);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setActivitiesForSubprocess(result.data._listworkitemgridData);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setActivitiesForSubprocess([]);
      setPriortyForSubProcessDetails([]);
      setPriortyForSubProcessDetails([]);
      setShowModalButton(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
      setIsClientLocations(false);
    } else {
      if (e.target.value !== "Select") {
        setSelectedLocation(e.target.value);
        setIsClientLocations(true);
        let result = "";
        let locationArray = e.target.value.split("/");
        for (let i = 0; i < locationArray.length - 1; i++) {
          result = result.concat(locationArray[i]);
          if (i < locationArray.length - 2) {
            result = result.concat("/");
          }
        }
        // sessionStorage.setItem(
        //   "selectedLocation",
        //   e.target.value.split("/")[e.target.value.split("/").length - 1]
        // );
        // sessionStorage.setItem("selectedLocationName", result);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: locationArray[locationArray.length - 1],
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
              setprocessSubprocess([]);
            } else {
              setprocessSubprocess([]);
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //   navigate("/");
            // }
          });
      }
    }
  };
  const DeletePriorityDetails = (skillId) => {
    let body = {
      skillSetId: "" + skillId,
    };

    let deleteUrl = getAPIURL(REACT_APP_DELETEPRIORITYDETAILS);
    let req = new Request(`${deleteUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          GetSavePriorityDetails(processSubProcessID);
          //FetchDefaultValueForSelectedSubprocess(companyDeparmentID,locationClientID,processSubProcessID);
          //setClientLocations(result.data);
          setIsresolve(true);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          //resolve();
        } else if (result.StatusCode == 500) {
          // <Alert severity="error">{result.Message}</Alert>
          alert("Record has references.");
          // addToast("Record has references.", {
          //   appearance: "error",
          //   autoDismiss: true,
          // });
        } else {
          setIsresolve(true);
        }
      })
      .catch((error) => {
        setIsresolve(true);
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setShowModalButton(false);
      setClientLocations([]);
      setActivitiesForSubprocess([]);
      setIsDepartments(false);
      setIsClientLocations(false);
      //setIsDepartments(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
    }
    if (e.target.value !== "Select") {
      //   sessionStorage.setItem(
      //     "selectedDepartment",
      //     e.target.value.split("/")[1]
      //   );
      //   sessionStorage.setItem(
      //     "selectedDepartmentName",
      //     e.target.value.split("/")[0]
      //   );
      setSelectedDepartment(e.target.value);
      setIsDepartments(true);
      //setIsClientLocations(true);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
            setIsClientLocations(true);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };
  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          sessionStorage.setItem(
            "SelctedSubprocessName",
            result.data[0].processSubProcess
          );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          setIsprocessSubprocess(true);
          GetWorkItemFieldsbySubProcessIDForClassification(
            sessionStorage.getItem("ProcessId")
          );
          GetSavePriorityDetails(sessionStorage.getItem("ProcessId"));
          GetActivitiesForSubprocess();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
          setIsClientLocations(true);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };
  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    FetchDepartmentbyGUID();
    
  }, []);
  return (
    <>
      <Header
        onpreferenceShow={null}
        pageName="Sub-Process Classification Config"
      />

      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="bussinessrules" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>

      <FormContext.Provider value={{ onchange: handelChange }}>
        <div className="centerSection">
          <div className="pageBody">
            <div className="dashBoxs mb-3">
              <div className="formWrap">
                <div className="row align-items-end">
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label"> Company Department</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchLocationClientsByGuid}
                        disabled
                      >
                        <option>Select</option>
                        {departments.map((department) => (
                          <option
                            value={
                              department.companyDepartment +
                              "/" +
                              department.departmentIDString
                            }
                            selected={
                              department.departmentIDString ==
                              companyDeparmentID
                            }
                          >
                            {department.companyDepartment}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Location Client</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchProcessSubProcessByGuid}
                        disabled
                      >
                        <option>Select</option>
                        {clientLocations.map((location) => (
                          <option
                            value={
                              location.locationClient +
                              "/" +
                              location.clientIDString
                            }
                            selected={
                              location.clientIDString == locationClientID
                            }
                          >
                            {location.locationClient}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Process SubProcess</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchInvalidProcessSubprocessByGuid}
                        disabled
                      >
                        <option>Select</option>
                        {processSubprocess.map((subprocess) => (
                          <option
                            value={
                              subprocess.subProcessIDString +
                              "/" +
                              subprocess.processSubProcess
                            }
                            selected={
                              subprocess.subProcessIDString ==
                              processSubProcessID
                            }
                          >
                            {subprocess.processSubProcess}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Activity</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetctActivityID}
                      >
                        <option>Select</option>
                        {ActivitiesForSubprocess.map((subprocess) => (
                          <option value={subprocess.ActivityID}>
                            {subprocess.ActivityName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <label className="form-label">Classification Name</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        onChange={saveSkillsetname}
                        value={skillsetName}
                      />
                    </div>
                  </div>
                </div>

                {/* <hr className="mb-4" /> */}
              </div>
            </div>
            <h5 class="mb-3">Rule Details</h5>
            <hr></hr>

            <UserClassificationListGridDetailsPage
              state={{ WorkItemFieldsbySubProcessIDForClassification }}
              // setState = {setWorkItemFieldsbySubProcessIDForClassification}
              
            />
            {!IsEdit ? (
              <>
                <div class="col-12 text-end">
                  <div class="mb-3">
                    <button
                      class="btn btn-primary ms-3"
                      onClick={() => {
                        SaveSkillSet();
                      }}
                      disabled={
                        !Isdepartments ||
                        !IsclientLocations ||
                        !IsprocessSubprocess ||
                        !IsActivitiesForSubprocess ||
                        !IsskillsetName
                      }
                    >
                      Add
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="col-12 text-end">
                  <div class="mb-3">
                    <button
                      class="btn btn-primary ms-3"
                      onClick={() => {
                        EditSkillSet();
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </>
            )}

            <div className="dashBoxs">
              <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                  <div className="col-4">
                    <h6>Priority Details</h6>
                    {/* //<h5>Processing Dashboard</h5> */}
                  </div>
                </div>
              </div>
              <table
                className="table table-borderless MatTable"
                style={{ zIndex: "1", position: "relative" }}
              >
                <MaterialTable
                  title="Priority Details"
                  editable={{
                    // onRowUpdate: (selectedRow) =>
                    //   new Promise((resolve, reject) => {
                    //     GetSkillSetConfig(selectedRow.skillSetID);
                    //     resolve();
                    //   }, Isresolve),
                    onRowDelete: (selectedRow) =>
                      new Promise((resolve, reject) => {
                        DeletePriorityDetails(selectedRow.skillSetID);
                        resolve();
                      }, Isresolve),
                  }}
                  actions={[
                    {
                      icon: () => <EditIcon />,
                      tooltip: "Edit Row",
                      onClick: (event, rowData) =>
                        GetSkillSetConfig(rowData.skillSetID),
                    },
                  ]}
                  options={{
                    //exportButton: true,
                    showTitle: false,
                    sorting: true,
                    filtering: true,
                    search: false,
                    paging: false,
                    cellStyle: {
                      // width: 100,
                      // minWidth: 100,
                    },
                    headerStyle: {},
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                    },
                  }}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                      </div>
                    ),
                  }}
                  columns={columns}
                  icons={tableIcons}
                  data={GetSavePriorityGridDetails}
                />
              </table>
            </div>
          </div>
        </div>
      </FormContext.Provider>
      <Footer />
    </>
  );
}

export default SubProcessUserClassificationList;

import Login from "../../cws/containers/pages/login/Login";
import { Route, Routes } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

let globalTriggerRelogin=null;

export const setGlobaltriggerRelogin=
(triggerFunction)=>{globalTriggerRelogin=triggerFunction};

export const triggerGlobalRelogin = (showModal)=>{

  if(globalTriggerRelogin)
  {globalTriggerRelogin(showModal);}
}

export async function HandleUnauthorized() {
  let navigate = useNavigate();
  return navigate("/");
}
export async function setupRefreshTokenTimer() {
  let timeoutId;

 console.log("start refreshtimer");
  timeoutId = setTimeout(() => {
    RefreshToken(false);
  },  840000);

  return timeoutId;
}

export async function RefreshToken(breakcycle) {
 // console.log("in  refreshtoken");

  const { REACT_APP_RefreshToken, REACT_APP_API_URL } = process.env;
  let apiURL = REACT_APP_API_URL;
  var refreshTokenUrl = `${apiURL}${REACT_APP_RefreshToken}`;
  let body = {
    jwtToken: sessionStorage.getItem("authorizationToken"),
    refreshToken: sessionStorage.getItem("RefreshToken"),
    userName: sessionStorage.getItem("UserName"),
  };
  let req = new Request(`${refreshTokenUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      UserName: sessionStorage.getItem("UserName"),
      Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
    },
    body: JSON.stringify(body),
  });
  fetch(req)
  .then((res) => {
    if(res.status===401)
    {
      if(globalTriggerRelogin)
        {globalTriggerRelogin(true);}
    }
    return res.json();})
    .then((result) => {
      if(!(breakcycle===true))
    {  var timerid = setupRefreshTokenTimer();
      clearTimeout(timerid);
    }
      if (result.status == 200) {
        sessionStorage.setItem("authorizationToken", result.data.jwtToken);
        sessionStorage.setItem("RefreshToken", result.data.refreshToken);
      
      } else if (result.status === 401) {
       
       if(globalTriggerRelogin)
       {globalTriggerRelogin(true);}
        // if(JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData")).timerValue != 2)
        // {
        // alert("Session Expired/UnAuthorized. Please LogIn Again !");
        // sessionStorage.clear();
        // sessionStorage.clear();
        // }
        
      } else if (
        result.StatusCode == 500 &&
        result.Message == "Invalid Token Passed!!"
      ) {
        alert("Session Expired/UnAuthorized. Please LogIn Again !");
        sessionStorage.clear();
        sessionStorage.clear();
        // navigate("/");
      }
    })
    .catch((error) => {
      if(!(breakcycle===true))
        {  var timerid = setupRefreshTokenTimer();
          clearTimeout(timerid);
        }
      if (error.message.includes('ERR_NAME_NOT_RESOLVED')) {
        // Do nothing
        console.log('Network error ignored: ', error.message);
    } else {
        // Handle other errors
        console.error('An error occurred:', error);
    }
     // console.log("ERROR: ", error);
      //navigate("/");
    });
}

import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import DashboardContext from "../../../../contexts/DashboardContext";
import FormContext from "../../../../contexts/FormContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "react-select";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  MenuItem,
  Container,
  ListItem,
  TextField,
  Hidden,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

function HierachyReportConfig() {
  const { addToast } = useToasts();
  const { REACT_APP_API_URL } = process.env;

  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;

  // added by ram
  const { REACT_APP_GETALLUSERSFORJSON } = process.env;
  const { REACT_APP_GETALLEMPLOYEECONFIGMAPPINGDETAILS } = process.env;
  const { REACT_APP_INSERTEMPLOYEETOREPORTCONFIGDETAILS } = process.env;

  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const CuurentWithRef = useRef(null);
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [Isdepartments, setIsDepartments] = useState(false);
  const [clientLocations, setClientLocations] = useState([]);
  const [IsclientLocations, setIsClientLocations] = useState(false);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [IsprocessSubprocess, setIsprocessSubprocess] = useState(false);
  const [showBtn, setUserButtonShow] = useState(false);
  const [assignedWorkItemDetails, setAssignedWorkItemDetails] = useState([]);
  const [allowLocation, setAllowLocation] = useState(false);
  const [allowSubprocess, setAllowSubprocess] = useState(false);
  const [showModalButton, setShowModalButton] = useState(false);
  const [ActivitiesForSubprocess, setActivitiesForSubprocess] = useState([]);
  const [IsActivitiesForSubprocess, setIsActivitiesForSubprocess] =
    useState(false);
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [skillsetName, setskillsetName] = useState("");
  const [IsskillsetName, setIsskillsetName] = useState(false);
  const [SkillSetId, setSkillSetId] = useState("");
  const [ActivityId, setActivityId] = useState("");
  const [PriortyForSubProcessDetails, setPriortyForSubProcessDetails] =
    useState([]);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [GetSavePriorityGridDetails, setGetSavePriorityGridDetails] = useState(
    []
  );
  const [user1, setuser1] = useState("");
  const [user2, setuser2] = useState("");
  const [selectedOption1, setselectedOption1] = useState("");
  const [selectedOption2, setselectedOption2] = useState("");
  const [GetUsers, SetGetUsers] = useState([]);
  const [GetAllEmployees, setGetAllEmployees] = useState([]);
  const [GetAllHierarchy, setGetAllHierarchy] = useState([]);
  const [JsonValues, setJsonValues] = useState([]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });

  const columns = [
    {
      title: "Employee Name",
      field: "rUserName",
    },
    {
      title: "Reporter's Name",
      field: "sUserName",
    },
  ];

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const handelChange = (event, oldElements) => {
    const newElements = { ...oldElements };
    newElements.state.WorkItemFieldsbySubProcessIDForClassification.forEach(
      (element) => {
        if (element.maptofield == event.target.id) {
          element.fieldValues = event.target.value;
        }
      }
    );
    setJsonValues(newElements);
  };

  const GetAllEmployeeConfigMappingDetails = () => {
    let body = {};
    let GetAllEmployeeConfigMappingDetailsURL = getAPIURL(
      REACT_APP_GETALLEMPLOYEECONFIGMAPPINGDETAILS
    );
    let req = new Request(`${GetAllEmployeeConfigMappingDetailsURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setisLoadingFigma(false);
          SetGetUsers(result.data);
        }else{
          SetGetUsers([]);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const GetAllEmployee = () => {
    let body = {
      UserName: "",
      Mode: "",
      EmployeeType: "SA",
    };
    let GetAllUsersForJsonURL = getAPIURL(REACT_APP_GETALLUSERSFORJSON);

    let req = new Request(`${GetAllUsersForJsonURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });

    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setisLoadingFigma(false);
          setGetAllEmployees(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const getAllHierarchies = () => {
    let body = {
      UserName: "",
      Mode: "",
      EmployeeType: "GENERAL",
    };
    let GetAllUsersForJsonURL = getAPIURL(REACT_APP_GETALLUSERSFORJSON);

    let req = new Request(`${GetAllUsersForJsonURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });

    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setisLoadingFigma(false);
          setGetAllHierarchy(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  let Hierachy=[];
  GetAllHierarchy.map((user) => {
    const uservalue = user.userName.split(" ~ ");
    let x = { value: uservalue[0], label: user.userName };
    Hierachy.push(x);
  });

  let employeeList = [];
  GetAllEmployees.map((user) => {
    const uservalue = user.userName.split(" ~ ");
    let x = { value: uservalue[0], label: user.userName };
    employeeList.push(x);
  });

  const AddUser = () => {
    if (user1 == "" || user2 == "") {
      addToast("Please enter the employee detail for mapping.", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      const hasUsers = GetUsers.some(row =>
        row.rUserID == user1 && row.sUserID == user2);
      if (hasUsers || user1 == user2) {
        addToast("Mapping already exist for this employee or kindly select different employees.", {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        let body = {
          UserID: user1,
          RUserID: user2,
          mode: "insert",
        };
        let loginUrl = getAPIURL(REACT_APP_INSERTEMPLOYEETOREPORTCONFIGDETAILS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status === 200) {
              addToast("New mapping has been inserted successfully.", {
                appearance: "success",
                autoDismiss: true,
              });
              GetAllEmployeeConfigMappingDetails();
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      }
    }

  };

  const DeleteUser = (row) => {
    if (row.isDeleted) {
      addToast("Selected mapping has been deleted successfully.", {
        appearance: "success",
        autoDismiss: true,
      });
      return false;
    }

    if (
      window.confirm(
        "Are you sure you want to delete the Mapping " + row.rUserName + " ?"
      )
    ) {
      let body = {
        UserID: row.rUserID,
        RUserID: row.sUserID,
        mode: "delete",
      };
      let loginUrl = getAPIURL(REACT_APP_INSERTEMPLOYEETOREPORTCONFIGDETAILS);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            addToast("Selected mapping has been deleted successfully.", {
              appearance: "success",
              autoDismiss: true,
            });
            GetAllEmployeeConfigMappingDetails();
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
  };

  useEffect(() => {
    GetAllEmployeeConfigMappingDetails();
    GetAllEmployee();
    getAllHierarchies();
  }, []);
  return (
    <>
      <Header onpreferenceShow={null} pageName="Hierarchy Report Config" />

      <ApplicationUserContext.Provider value={processingDashboardParam.userRoles}>
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="admin" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>

      {/* <FormContext.Provider value={{ onchange: handelChange }}> */}
      {/* <div className="centerSection"> */}
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <h4 className="mb-4">Search Criteria</h4>
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3" style={{ zIndex: 1, position: "relative" }}>
                    <label className="form-label mb-2">Employee name:</label>

                    <Select
                      defaultValue={""}
                      isSearchable={isSearchable}
                      isClearable={isClearable}
                      options={employeeList}
                      onChange={(e) => {
                        setselectedOption1(e ? e.label : "");
                        setuser1(e ? e.value : "")
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3" style={{ zIndex: 1, position: "relative" }}>
                    <label className="form-label mb-2">Employee Mapping for Hierarchy:</label>
                    <Select
                      defaultValue={""}
                      isClearable={isClearable}
                      isSearchable={isSearchable}
                      options={Hierachy}
                      onChange={(e) => {!e?
                        setselectedOption2(""):setselectedOption2(e.label);
                        !e?
                        setuser2(""):setuser2(e.value);
                      }}
                    />

                  </div>
                </div>
                <div className="col-3">
                  <div className="mb-3" style={{ zIndex: 1, position: "relative", marginLeft: "150px" }}>
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary w-100"
                      onClick={AddUser}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashBoxs" style={{ zIndex: 0, position: "relative" }}>
          <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
            <div className="row align-items-center">
            </div>
          </div>
          {isLoadingFigma ? (
            <LoadingSpinnerFigma />
          ) : (
            <div className="tableWrap">
              <div className="tableWrap-body">
                <table className="table table-borderless MatTable">
                  <MaterialTable
                    localization={{
                      header: {
                        actions: "",
                      },
                    }}
                    actions={[
                      {
                        icon: () => <DeleteIcon />,
                        tooltip: "Delete",
                        onClick: (event, rowData) =>
                          DeleteUser(rowData),
                        position: "row",
                      },
                    ]}
                    options={{
                      headerStyle: {
                        position: "sticky",
                        top: "-5px",
                      },
                      maxBodyHeight: "420px",
                      exportButton: false,
                      sorting: true,
                      filtering: true,
                      search: false,
                      selection: false,
                      toolbarButtonAlignment: "left",
                      overflowY: false,
                      showTitle: false,
                      tableLayout: "auto",
                      rowStyle: {
                        fontFamily: '"Yantramanav", sans-serif',
                        fontSize: "13px",
                        whiteSpace: "wrap",
                      },
                      headerStyle: {
                        fontFamily: '"Yantramanav", sans-serif',
                        fontWeight: "inherit",
                        fontSize: "25px",
                        textAlign: "left !Important",
                      }
                    }}
                    title="Employee Mapping Details"
                    columns={columns}
                    icons={tableIcons}
                    data={GetUsers}
                    components={{
                      Container: props => (
                        <Paper {...props} elevation={0} />
                      ),
                      Toolbar: (props) => (
                        <div
                          style={{
                            backgroundColor: "rgb(206 206 206)",
                            fontSize: "0.75em",
                          }}
                        >
                          <MTableToolbar
                            {...props}
                            style={{
                              fontSize: "5px",
                            }}
                          />
                        </div>
                      ),
                    }}
                  />
                </table>
              </div>
            </div>
          )}
        </div>
      {/* </div> */}
      {/* </FormContext.Provider> */}
      < Footer />
    </>
  );
}
export default HierachyReportConfig;

import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import CryptoJS from "crypto-js";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
//import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
//import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "react-table-filter/lib/styles.css";
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import NavDataContext from "../../../../contexts/NavDataContext";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FcSearch } from "react-icons/fc";
import { FcFinePrint } from "react-icons/fc";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import WorkActivityScreenDetailsPage from "../WorkActivityScreen/WorkActivityScreenDetailsPage";
import FormContext from "../../../../contexts/FormContext";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import { Statement } from "@babel/types";
import Alert from "@material-ui/lab/Alert";
//import { setDatasets } from "react-chartjs-2/dist/utils";

const EmployeeConfig = (props) => {
  const { addToast } = useToasts();
  const inputRefs = useRef([]);
  const { REACT_APP_API_URL } = process.env;

  //const { REACT_APP_FETCHSUBPROCESSCONFIG } = process.env;

  const { REACT_APP_FETCHSUBPROCESSACTIVITYFORSUBPROCESSID } = process.env;

  const { REACT_APP_FETCHEMPLOYEECONFIGDETAILS } = process.env;

  //const { REACT_APP_GETWORKITEMTYPE } = process.env;
  const { REACT_APP_UPDATEEMPLOYEECONFIGDETAILS } = process.env;
  //const { REACT_APP_UPDATESUBPROCESSCONFIG } = process.env;

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  //Jinnat

  const [Subprocessworkactivity, setSubprocessworkactivity] = useState([]);
  const [CopySubprocessworkactivity, setCopySubprocessworkactivity] = useState(
    []
  );

  const [isLoading, set_IsLoading] = useState(false);
  const [checkedValue, setcheckedValue] = useState("");
  const [Data, setData] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [SubProcessActivityConfigID, setSubProcessActivityConfigID] =
    useState(0);

  //useState
  const [focusedLabelText, setFocusedLabelText] = useState(null);
  const [Workactivity, setWorkactivity] = useState("");

  const [Copyscreenconfig, setCopyscreenconfig] = useState("");

  const [Excludeworkitemsolderthan, setExcludeworkitemsolderthan] = useState(0);

  const [enable, setenable] = useState(false);
  const [copyEnable, setcopyEnable] = useState(false);
  const [workActivityforEmployeeConfigId, setworkActivityforEmployeeConfigId] = useState("0");

  //Handle Onchange

  //DocumentIdField

  //End Handle Onchange

  const ShowOnbaseDocumentLinkRef = useRef(false);
  const UploadDocumenttoOnbaseRef = useRef(false);
  const AppendImagetoOnbasedocumentRef = useRef(false);
  const HideWorkitemAuditTrailRef = useRef(false);
  const HideWorkitemAssignmentDetailsRef = useRef(false);
  const HideWorkitemChecklistCommentDetailsRef = useRef(false);
  const AllowassignuserfornextactivityRef = useRef(false);
  const AllowuploadattachmentfortheworkitemsRef = useRef(false);
  const AllowskipbuttonRef = useRef(false);
  const EnableUniqueCheckforthisactivityasRef = useRef(false);
  const AllowAutoPopupofImageRef = useRef(false);
  const ShowCountdownTimerRef = useRef(false);
  const AllowDataValidationRef = useRef(false);

  //First

  // const HandleSaveandUpdate = () => {
  //
  //   if (buttonSave) {
  //   SaveEmployeeConfig();
  //   } else {
  //     UpdateSubprocessConfig();
  //   }
  // };
  const GetSubProcessActivityForSubProcessID = () => {
    let body = {
      subprocessID: props.reference.SubprocessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHSUBPROCESSACTIVITYFORSUBPROCESSID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setSubprocessworkactivity(result.data);
          setcopyEnable(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",

            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          //navigate("/");
        } else {
          addToast("Fetching Activity details Failed", {
            appearance: "error",

            autoDismiss: true,
          });
        }
      })

      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const GetEmployeeConfigDetails = (DisplayCriteria, workActivityId) => {
    set_IsLoading(true);
    //setCopyscreenconfig("0");
    setcheckedValue(DisplayCriteria);
    let body = {
      //SubProcessWorkActivityConfigID: SubProcessWorkActivityConfigID
      //SubProcessWorkActivityConfigID: props.reference.workActivityforEmployeeConfig.toString(),
      SubProcessWorkActivityConfigID: workActivityId,
      SubProcessId: props.reference.SubprocessID,
      DisplayCreteria: DisplayCriteria,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHEMPLOYEECONFIGDETAILS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          set_IsLoading(false);

          setData(result.data.getEmployeeConfigDetails);
          
          const initialCheckboxStates = result.data.getEmployeeConfigDetails.reduce((acc, item) => ({
            ...acc,
            [item.userName]: item.value
          }), {});
          
          setCheckboxStates(initialCheckboxStates);
          if (
            result.data.subProcessActivityConfigID[0].column1 != null &&
            result.data.subProcessActivityConfigID[0].column1 != 0
          ) {
            setSubProcessActivityConfigID(
              result.data.subProcessActivityConfigID[0].column1
            );
          }
        } else if (result.status === 401) {
          set_IsLoading(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",

            autoDismiss: true,
          });
          sessionStorage.clear();

          //navigate("/");
        } else {
          set_IsLoading(false);
          addToast("Fetching Employee Config Details Failed", {
            appearance: "error",

            autoDismiss: true,
          });
        }
      })

      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  //Second
  const [displayOrderArry, setdisplayOrderArry] = useState([]);

  const Handleassign = (e, rowData) => {
    let a = 0;
    if (e.target.checked) {
      a = 1;
    } else {
      a = 0;
    }
    const newData = [...Data];

    newData[rowData.tableData.id]["assign"] = a;
    setData(newData);
    const newValue = e.target.checked;
    
    const checkboxValue=checkboxStates;
    setCheckboxStates(prevStates => ({
      ...prevStates,
      [rowData.userName]: newValue,
    }));
    setFocusedLabelText(rowData.userName);
  };

  const Displaycreteria = (e) => {
    GetEmployeeConfigDetails(e.target.value, workActivityforEmployeeConfigId);
  };

  const HandleisSuperUser = (e, rowData) => {
    let a = 0;
    if (e.target.checked) {
      a = 1;
    } else {
      a = 0;
    }
    const newData = [...Data];

    newData[rowData.tableData.id]["isSuperUser"] = a;
    setData(newData);
  };

  const HandlereminderNotificationDays = (e, rowData) => {
    //toolTipRef.current.value = e.target.value;
    const newData = [...Data];

    newData[rowData.tableData.id]["remenderNotificationDays"] = parseInt(
      e.target.value
    );
    setData(newData);

    //toolTipRef.current.value = e.target.value;
  };
  // useEffect(() => {
  //   // Focus on the last input field that was changed
  //   
  //   if (focusedLabelText !== null && inputRefs.current[focusedLabelText]) {
  //     inputRefs.current[focusedLabelText].focus();
  //   }
  // }, [focusedLabelText]);
  const columns = [
    {
      title: "Assign",
      field: "assign",
      render: (assign) => (
        <>
          
          <input
            type="checkbox"
            id="chkIsassign"
            onChange={e => Handleassign(e, assign)}
            checked={assign["assign"] == 1}
          />
        </>
      ),
    },
    {
      title: "Is Super User",
      field: "isSuperUser",
      render: (isSuperUser) => (
        <>
          <input
            type="checkbox"
            id="chkIsassign"
            onChange={(e) => HandleisSuperUser(e, isSuperUser)}
            checked={isSuperUser["isSuperUser"] == 1}
          />
        </>
      ),
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "User Name",
      field: "userName",
    },
    {
      title: "User Type",
      field: "personType",
    },

    {
      title: "Reminder Notification Days",
      field: "remenderNotificationDays",
      render: (reminderNotificationDays) => (
        <>
          <input
            type="number"
            className="form-label"
            style={{ fontSize: "12px", color: "black" }}
            id="txtremenderNotificationDays"
            defaultValue={0}
            onChange={(e) =>
              HandlereminderNotificationDays(e, reminderNotificationDays)
            }
            value={reminderNotificationDays["remenderNotificationDays"]}

            //onBlur={e => HandleBlurToolTip(e, toolTip)}

            //checked={toolTip["toolTip"]}
          />
        </>
      ),
    },
  ];

  //onbase

  //Onbase

  const [buttonSave, setbuttonSave] = useState(true);

  //Start SaveEmployeeConfig

  const SaveEmployeeConfig = () => {
    // set_IsLoading(true);
    let isSubmit = true;
    if (
      workActivityforEmployeeConfigId == 0 ||
      workActivityforEmployeeConfigId == "0" ||
      workActivityforEmployeeConfigId == ""
    ) {
      // err.Allocationunit = "Allocation Unit is Required.";
      addToast("Activity is Required.", {
        appearance: "error",
        autoDismiss: true,
      });
      isSubmit = false;
    }
    //seterrorMessage({ ...err });

    if (isSubmit) {
      //set_IsLoading(true);
      //
      let body = {
        UpdateEmployeeConfigDetails: Data,
        DisplayCreteria: checkedValue,
        SubProcessWorkActivityConfigID: SubProcessActivityConfigID,
        UpdatedBy: sessionStorage.getItem("PersonID")
      };

      let loginUrl = getAPIURL(REACT_APP_UPDATEEMPLOYEECONFIGDETAILS);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            //set_IsLoading(false);
            setbuttonSave(true);

            addToast("Subprocess Employee Config saved successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            //
          } else if (result.status === 401) {
            //set_IsLoading(false);
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            //navigate("/");
          } else {
            //set_IsLoading(false);
            addToast("SubProcess Employee Config Failed to save", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };

  useEffect(
    () => {
      GetSubProcessActivityForSubProcessID();
      GetEmployeeConfigDetails("All", workActivityforEmployeeConfigId);
      // console.log(props.reference.workActivityforEmployeeConfig.toString());
    },
    [
      //props.reference.SubprocessID
    ]
  );

  /*useEffect(() => {
    GetEmployeeConfigDetails("All");
  }, [props.reference.workActivityforEmployeeConfig]);*/

  const handleWorkActivityEmployeeConfigDropdown = (e) => {
    setworkActivityforEmployeeConfigId(e.target.value.toString());
    GetEmployeeConfigDetails("All", e.target.value.toString());
  }

  return (
    <>
      {/* One Scenario */}
      <div className="">
        {isLoading ? (
          <div
            className="full-screen-loader d-flex align-items-center"
            style={{ top: "-20%" }}
          >
            <LoadingSpinner />
          </div>
        ) : null}
        <div>
          <div className="dashBoxs mb-3" style={{ marginTop: "1.0rem" }}>
            <div className="formWrap">
              <div className="fieldSet mb-4">
                {" "}
                <div className="row align-items-end">
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px", color: "black" }}
                      >
                        Activity
                        <span
                          id=""
                          className="mandatory"
                          style={{ color: "#ff0000" }}
                        >
                          *
                        </span>
                      </label>

                      <select
                        className="form-control form-control-sm"
                        //required={true}
                        //disabled={true}
                        //ref={AllocationunitRef}
                        //onChange={handleworkactivity}
                        //value={Workactivity}
                        //defaultValue={selectedTask != "" ? selectedTask : ""}

                        value={workActivityforEmployeeConfigId}
                        onChange={handleWorkActivityEmployeeConfigDropdown}
                        defaultValue="Select"
                      >
                        <option value="0">Select</option>
                        {Subprocessworkactivity.map((activity) => (
                          <option
                            // selected={
                            //   activity.activityID ==
                            //   props.reference.workActivityforEmployeeConfig
                            // }
                            selected={
                              activity.activityID ==
                              workActivityforEmployeeConfigId
                            }
                            value={activity.activityID}
                          >
                            {activity.activityName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashBoxs mb-3" style={{ marginTop: "1.0rem" }}>
              <div className="col-4">
                <label
                  className="form-check-label"
                  style={{
                    fontSize: "13px",

                    marginLeft: "0.725rem",
                  }}
                >
                  Display Criterion
                </label>
                <div className="pt-3 pt-1">
                  <div
                    className="form-check form-check-inline"
                    style={{
                      marginLeft: "0.725rem",
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio4"
                      value="All"
                      checked={checkedValue === "All" ? "yes" : null}
                      onChange={Displaycreteria}
                    />
                    <label
                      className="form-check-label"
                      for="inlineRadio4"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      All
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="Employee"
                      checked={checkedValue === "Employees" ? "yes" : null}
                      onChange={Displaycreteria}
                    />
                    <label
                      className="form-check-label"
                      for="inlineRadio4"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Employees
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="Client"
                      checked={checkedValue === "Clients" ? "yes" : null}
                      onChange={Displaycreteria}
                    />
                    <label
                      className="form-check-label"
                      for="inlineRadio4"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Clients
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table className="table table-borderless MatTable">
                {/* <ThemeProvider theme={theme}> */}
                <MaterialTable
                  localization={{
                    pagination: {
                      //labelDisplayedRows: '{from}-{to} of {count}'
                    },
                    toolbar: {},

                    header: {
                      actions: "",
                    },
                  }}
                  actions={[]}
                  //title="Set Work Activity Screen For a Activity"
                  options={{
                    headerStyle: {
                      position: "sticky",
                      top: "-5px",
                    },
                    // maxBodyHeight: "500px",
                    pagination: true,
                    paginationType: "normal",
                    paginationPosition: "bottom",
                    //pageSize: numberOfRecord,
                    pageSizeOptions: [20, 50, 100, 200],
                    paging: true,
                    draggable: true,
                    pageSize: 20,
                    exportButton: false,
                    sorting: true,
                    //filtering: true,
                    search: true,
                    actionsColumnIndex:-1,
                    selection: false,
                    tableLayout: "auto",
                    overflowY: false,
                    showTitle: false,
                    toolbarButtonAlignment: "right",
                    headerStyle: {
                      height: 30,
                    },
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                      fontSize: "13px",
                      // whiteSpace: "nowrap",
                    },
                    tableLayout: "auto",
                    headerStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                      fontWeight: "inherit",
                      fontSize: "14px",
                      textAlign: "left !Important",
                    },
                  }}
                  columns={columns}
                  icons={tableIcons}
                  data={Data}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar
                          {...props}
                          style={{
                            fontSize: "5px",
                          }}
                        />
                      </div>
                    ),
                  }}
                />
                {/* </ThemeProvider> */}
              </table>
            </div>
          </div>

          {/* {buttonSave ? (
              // <div className="mb-3">
              <button
                style={{ display: "none" }}
                ref={props.reference.SubprocessConfigRef}
                //ref={ButtonTextRef}
                onClick={() => {
                  SaveEmployeeConfig();
                }}
              ></button>
            ) : (
              // </div>
              // <div className="mb-3"> */}
          <button
            // type="submit"
            // className="btn btn-sm btn-primary ms-3"

            ref={props.reference.EmployeeConfigRef}
            style={{ display: "none" }}
            onClick={() => {
              SaveEmployeeConfig();
            }}
          >
            {/* Update */}
          </button>
          {/* //{" "} */}
          {/* </div> */}
          {/* )}
          </div> */}
        </div>
      </div>
      {/* <GlobalStyles
            styles={{
              ".MuiButtonBase-root.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26) !important",
                cursor: "default !important",
                PointerEvent: "none !important",
              },
              ".MuiButton-root": {
                color: "#1976d2 !important",               
              },
            }}
          /> */}
    </>
  );
};

export default EmployeeConfig;

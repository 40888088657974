import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const addOriginalElementSlice = createSlice({
  name: "selectedFlows",
  initialState,
  reducers: {
    addselectedFlowId: (state, action) => {
    //  console.log("slice", action.payload);
      state.push(action.payload);
    },
    addselectedTaskId: (state, action) => {
   //   console.log("slice", action.payload);
      state.push(action.payload);
    },
    addOriginNodes:(state,action)=>{
      state.push(action.payload);
    },
    resetFlowIds: (state, action) => {
      return initialState;
    },
    resetTaskIds: (state, action) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addselectedFlowId,addselectedTaskId,addOriginNodes,resetFlowIds,resetTaskIds} = addOriginalElementSlice.actions;

export default addOriginalElementSlice.reducer;

import React, { useState } from "react";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';

import Dropdown from 'react-bootstrap/Dropdown';
import DashboardContext from "../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import user01 from '../../../../assets/images/user/user01.jpg';



const ViewUploadHistroy = () => {
    const location = useLocation();
    const processingDashboardParam = location.state;
    return (
        <>
            <Header />
            <DashboardContext.Provider value={processingDashboardParam.nav}>
        <Nav />
      </DashboardContext.Provider>
            <div className="centerSection">
                <div className="pageHeader highLight">
                    <div className="row  align-items-center">
                        <div className="col-6">
                            <h2 className="pageName">View Upload Histroy</h2>
                        </div>
                        <div className="col-6 text-end">
                        </div>
                    </div>
                </div>

                <div className="pageBody">
                    <div className="dashBoxs mb-3">

                        <div className="formWrap">
                            <div className="fieldSet">

                                <div className="row align-items-end">
                                    <div className="col-3">
                                        <div className="mb-3">
                                            <label className="form-label">Company Department</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="mb-3">
                                            <label className="form-label">Client location</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="mb-3">
                                            <label className="form-label">Process -Sub-process</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                          
                                    <div className="col-12 pb-2"></div>
                                    <div className="col-12">
                                        <div className="mb-2">
                                            <label className="form-label">Upload between</label>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="mb-3">
                                            <label className="form-label"> From</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="mb-3">
                                            <label className="form-label">To</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                               
                                </div>
                            </div>
                           

                            <hr className="mb-4" />
                            <div className="fieldSet pt-1">
                                <div className="mb-2 text-end">
                                    <button type="button" className="btn btn-sm btn-link">Reset</button>
                                    <button type="button" className="btn btn-sm btn-primary ms-3">Get Error Details</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="dashBoxs">
                        <div className="dashBoxs-header pb-3 pt-2">
                            <div className="row align-items-center">
                                <div className="col-3">
                               
                                </div>
                                <div className="col-9">
                                    <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                                 
                                        <Dropdown className="ms-2">
                                            <Dropdown.Toggle variant="outline-ntv" className="btn-sm dropdown-toggle w-100" id="dropdown-basic"><b className="icon-download"></b>  Export </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Header>Options</Dropdown.Header>
                                                <Dropdown.Item>Print</Dropdown.Item>
                                                <Dropdown.Divider></Dropdown.Divider>
                                                <Dropdown.Header>Download options</Dropdown.Header>
                                                <Dropdown.Item >Excel</Dropdown.Item>
                                                <Dropdown.Item >Pdf</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tableWrap">
                            <div className="tableWrap-body">

                                <table className="table table-borderless brandTable">
                                    <thead>
                                            <th>View Error details</th>
                                            <th>Upload Date</th>
                                            <th>Total No. of Work Items</th>
                                            <th>Total No. of Work Items Succesfully Uploaded</th>
                                            <th>No. of Error Items</th>
                          
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td>06-12-2022</td>
                                            <td>5</td>
                                            <td>5</td>
                                            <td>0</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tableWrap-footer">
                                <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                                    <div className="col-sm mb-2 mb-sm-0">
                                        <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                                            <span className="me-2 text-info">Showing:</span>
                                            <span className="text-info">1-5 out of 19 records</span>
                                        </div>
                                    </div>

                                    <div className="col-sm-auto">
                                        <div className="d-flex justify-content-center justify-content-sm-end">
                                            <nav>
                                                <div className="paging_simple_numbers">
                                                    <ul className="pagination">
                                                        <li className="paginate_item page-item disabled"><a className="paginate_button previous page-link"><span>Prev</span></a></li>
                                                        <li className="paginate_item page-item active"><a className="paginate_button page-link">1</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">2</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">3</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">4</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button next page-link"><span aria-hidden="true">Next</span></a></li>
                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
};

export default ViewUploadHistroy;
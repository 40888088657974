import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import { ToastProvider, useToasts } from "react-toast-notifications";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import * as FileSaver from "file-saver";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

function UploadClientLogo() {
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;

  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const { addToast } = useToasts();

  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [uploadedFile, setuploadedFile] = useState("");
  const [uploadedFileName, setuploadedFileName] = useState("");
  const [IsUpload, setIsUpload] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const [selectedFile, setselectedFile] = useState();
  const [UploadingFile, setUploadingFile] = useState("");
  const [ImgURL, setImgURL] = useState(undefined);
  const [SelectedClientLocation, setSelectedClientLocation] = useState("");
  const [AttachmentDetails, setAttachmentDetails] = useState([]);
  const { REACT_APP_UPLOADCLIENTLOGOFILEDETAILS } = process.env;
  const { REACT_APP_GETWORKITEMATTACHMENTS } = process.env;

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const uploadFileOnServer = () => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", UploadingFile);
    formData.append("PersonID", sessionStorage.getItem("PersonID"));
    formData.append("ClientID", SelectedClientLocation);

    let loginUrl = getAPIURL(REACT_APP_UPLOADCLIENTLOGOFILEDETAILS);

    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        //"Content-Type": "multipart/form-data",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: formData,
    });

    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.path !== "") {
          setuploadedFileName(result.path);
          setIsUpload(true);
          setImgURL(result.data);
          alert(result.message);
        } else {
          alert("Something went wrong");
          // addToast("Something went wrong", {
          //     appearance: "error",
          //     autoDismiss: true,
          // });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const readUploadFile = (e) => {
    const extension = e.target.files[0].name.split(".").pop();
    const type = e.target.files[0].type;
    setselectedFile(e.target.files[0]);
    if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "bmp" ||
      extension === "png" ||
      extension === "gif" ||
      extension === "tiff" ||
      extension === "txt" ||
      extension === "doc" ||
      extension === "xls" ||
      extension === "pdf" ||
      extension === "ppt" ||
      extension === "xlsx" ||
      extension === "docx" ||
      extension === "pptx" ||
      extension === "msg"
    ) {
      if (
        type.includes("text") ||
        type.includes("msword") ||
        type.includes("application/octet-stream") ||
        type.includes("excel") ||
        type.includes("image") ||
        type.includes("powerpoint") ||
        type.includes("pdf") ||
        type.includes("openxmlformats-officedocument")
      ) {
        if (e.target.files[0].size <= 10240000) {
          setUploadingFile(e.target.files[0]);
          setuploadedFile(e.target.value);

          //uploadFileOnServer(e.target.files[0]);
        } else {
          addToast("The file size exceeded the maximum limit of 10 MB.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } else {
        addToast("Invalid Format or the file is open.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      alert("Upload Failed! File Type is not supported!");
    }
  };

  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
    }
    setSelectedLocation(e.target.value);
    setSelectedClientLocation(e.target.value.split("/")[1]);
    // sessionStorage.setItem("selectedLocation", e.target.value.split("/")[1]);
    // sessionStorage.setItem(
    //   "selectedLocationName",
    //   e.target.value.split("/")[0]
    // );
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value.split("/")[1],
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          setprocessSubprocess([]);
        } else {
          setprocessSubprocess([]);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setClientLocations([]);
    }
    if (e.target.value !== "Select") {
      // sessionStorage.setItem(
      //   "selectedDepartment",
      //   e.target.value.split("/")[1]
      // );
      // sessionStorage.setItem(
      //   "selectedDepartmentName",
      //   e.target.value.split("/")[0]
      // );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };

  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          //GetActivitiesForSubprocess();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    //sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    //sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };

  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // sessionStorage.setItem(
          //   "SelctedSubprocessName",
          //   result.data[0].processSubProcess
          // );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
    FetchDepartmentbyGUID();
  }, []);

  return (
    <>
      <Header onpreferenceShow={null} pageName="Upload Client Logo" />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="admin" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>

      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"> Company Department</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchLocationClientsByGuid}
                    >
                      <option>Select</option>
                      {departments.map((department) => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.departmentIDString == companyDeparmentID
                          }
                        >
                          {department.companyDepartment}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Location Client</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchProcessSubProcessByGuid}
                    >
                      <option>Select</option>
                      {clientLocations.map((location) => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.clientIDString == locationClientID}
                        >
                          {location.locationClient}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Process SubProcess</label>
                    <select
                      className="form-control form-control-sm"
                      //onChange={FetchLockedWorkitemsBySubProcessID}
                    >
                      <option>Select</option>
                      {processSubprocess.map((subprocess) => (
                        <option
                          value={subprocess.subProcessIDString}
                          selected={
                            subprocess.subProcessIDString == processSubProcessID
                          }
                        >
                          {subprocess.processSubProcess}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="fieldSet">
                <div className="row align-items-end">
                  <div>
                    <span>
                      Note: Image is best viewed for the size 116w*66h (pixels).
                      <br />
                      (File size should not exceed 2MB)
                    </span>
                  </div>
                  <div className="col-3">
                    <input
                      className="form-control form-control-sm"
                      type="file"
                      onChange={readUploadFile}
                      value={uploadedFile}
                    />
                  </div>
                  <div style={{ paddingTop: "5px" }}>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary "
                      onClick={() => {
                        uploadFileOnServer();
                      }}
                      //disabled={IsUpload}
                    >
                      Upload
                      {/* {IsUploading ? "Uploading..." : "Upload"}    */}
                    </button>
                  </div>
                </div>
                <div>
                  {IsUpload ? (
                    <img
                      src={`data:image/jpeg;base64,${ImgURL}`}
                      height="100px"
                      width="100px"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UploadClientLogo;

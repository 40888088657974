import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import MaterialTable, { MTableToolbar } from "material-table";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import tableIcons from "../../../../MaterialTableIcons";
import DateRangePicker from "react-bootstrap-daterangepicker";
// import DatePicker from "react-bootstrap-datepicker";
// import "bootstrap-daterangepicker/daterangepicker.css";
import { MenuItem, Paper, Select, withWidth } from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, Navigate } from "react-router-dom";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import moment from "moment";

function AddEditNews() {
  const { addToast } = useToasts();
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;

  const { REACT_APP_UPDATENEWS } = process.env;
  const { REACT_APP_UPDATENEWSFORCLIENT } = process.env;
  const { REACT_APP_GETNEWSBYID } = process.env;
  const { REACT_APP_GETNEWSBYIDFORCLIENT } = process.env;
  // const {REACT_APP_DELETENEWS} = process.env;
  const { REACT_APP_FetchClientsByGuid } = process.env;
  const { REACT_APP_FetchProcessesByGuid } = process.env;
  const { REACT_APP_FetchSubProcessesByGuidAsync } = process.env;

  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const [AdvanceText, setAdvanceText] = useState("");
  const [ExpiryDateText, setExpiryDateText] = useState("");
  const [NewsBodyText, setNewsBodyText] = useState("");
  const [PriorityText, setPriorityText] = useState("");
  const [ApplicationNewsTypeText, setApplicationNewsTypeText] = useState("");
  const [issavecontinue, setissavecontinue] = useState(false);

  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [showModalButton, setShowModalButton] = useState(false);
  const [ActivitiesForSubprocess, setActivitiesForSubprocess] = useState([]);
  const [PriortyForSubProcessDetails, setPriortyForSubProcessDetails] = useState([]);
  const [Isdepartments, setIsDepartments] = useState([]);
  const [IsclientLocations, setIsClientLocations] = useState([]);
  const [IsprocessSubprocess, setIsprocessSubprocess] = useState([]);
  const [IsActivitiesForSubprocess, setIsActivitiesForSubprocess] = useState([]);
  const [buttonText, setbuttonText] = useState("Save");
  const [dropdownsDivVisible, setdropdownsDivVisible] = useState(true);

  const [clients, setClients] = useState([]);
  const [clientID, setClientID] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [processes, setProcesses] = useState([]);
  const [processIDstr, setprocessIDstr] = useState("");
  const [selectedProcessName, setselectedProcessName] = useState("");
  const [subProcesses, setsubprocesses] = useState([]);
  const [subProcessIDstr, setsubProcessIDstr] = useState("");
  const [selectedSubProcessName, setselectedSubProcessName] = useState("");

  const ButtonTextRef = useRef("");
  const testref = useRef(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setShowModalButton(false);
      setClientLocations([]);
      setActivitiesForSubprocess([]);
      setIsDepartments(false);
      setIsClientLocations(false);
      //setIsDepartments(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
    }
    if (e.target.value !== "Select") {
      setIsDepartments(true);
      sessionStorage.setItem(
        "selectedDepartment",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedDepartmentName",
        e.target.value.split("/")[0]
      );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setActivitiesForSubprocess([]);
      setPriortyForSubProcessDetails([]);
      setPriortyForSubProcessDetails([]);
      setShowModalButton(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
      setIsClientLocations(false);
    } else {
      if (e.target.value !== "Select") {
        setSelectedLocation(e.target.value);
        setIsClientLocations(true);
        let result = "";
        let locationArray = e.target.value.split("/");
        for (let i = 0; i < locationArray.length - 1; i++) {
          result = result.concat(locationArray[i]);
          if (i < locationArray.length - 2) {
            result = result.concat("/");
          }
        }
        sessionStorage.setItem(
          "selectedLocation",
          e.target.value.split("/")[e.target.value.split("/").length - 1]
        );
        sessionStorage.setItem("selectedLocationName", result);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: locationArray[locationArray.length - 1],
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();

              sessionStorage.clear();
              navigate("/");
              setprocessSubprocess([]);
            } else {
              setprocessSubprocess([]);
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //   navigate("/");
            // }
          });
      }
    }
  };

  const FetchInvalidProcessSubprocessByGuid = (e) => {
    if (e.target.value == "Select") {
      setShowModalButton(false);
      setActivitiesForSubprocess([]);
      setPriortyForSubProcessDetails([]);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
    }
    if (e.target.value !== "Select") {
      setShowModalButton(true);
      setIsprocessSubprocess(true);
      setSelectedSubprocess(e.target.value.split("/")[0]);
      sessionStorage.setItem("ProcessId", e.target.value.split("/")[0]);
      sessionStorage.setItem(
        "SelctedSubprocessName",
        e.target.value.split("/")[1]
      );
      GetActivitiesForSubprocess();
    }
  };

  const GetActivitiesForSubprocess = () => {
    let body = {
      subProcessID: sessionStorage.getItem("ProcessId"),
    };
    let activityUrl = getAPIURL(REACT_APP_GETACTIVITIESFORSUBPROCESS);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setActivitiesForSubprocess(result.data._listworkitemgridData);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };

  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };

  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          GetActivitiesForSubprocess();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          sessionStorage.setItem(
            "SelctedSubprocessName",
            result.data[0].processSubProcess
          );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    FetchDepartmentbyGUID();
    FetchClientsbyGUID();

    if (location.state.checkedNewsType == "0")
      GetNewsById();
    else
      GetNewsByIdForClient();

    setbuttonText(sessionStorage.getItem("buttonText"));

    if (location.state.checkedNewsType == "0")
      setdropdownsDivVisible(true);
    else
      setdropdownsDivVisible(false);

    // console.log(location.state)
  }, []);

  const FetchClientsbyGUID = () => {
    let body = {
      Guid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FetchClientsByGuid);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClients(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };

  const FetchProcessesByGuid = (e) => {
    if (e.target.value == "Select") {
      setClientID("");
      setSelectedClient("");
      setProcesses([]);
      setprocessIDstr("");
      setselectedProcessName("");
      setsubprocesses([]);
      setsubProcessIDstr("");
      setselectedSubProcessName("");
    }
    if (e.target.value !== "Select") {
      sessionStorage.setItem(
        "selectedClientID",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedClientName",
        e.target.value.split("/")[0]
      );
      setClientID(e.target.value.split("/")[1]);
      setSelectedClient(e.target.value.split("/")[0]);

      let body = {
        guid: sessionStorage.getItem("GUID"),
        processId: "",
        clientId: e.target.value.split("/")[1]
      };

      let loginUrl = getAPIURL(REACT_APP_FetchProcessesByGuid);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setProcesses(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const FetchSubProcessesByGuid = (e) => {
    if (e.target.value == "Select") {
      setprocessIDstr("");
      setselectedProcessName("");
      setsubprocesses([]);
      setsubProcessIDstr("");
      setselectedSubProcessName("");
    }
    if (e.target.value !== "Select") {
      sessionStorage.setItem(
        "selectedProcessID",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedProcessName",
        e.target.value.split("/")[0]
      );
      setprocessIDstr(e.target.value.split("/")[1]);
      setselectedProcessName(e.target.value.split("/")[0]);

      let body = {
        guid: sessionStorage.getItem("GUID"),
        processId: e.target.value.split("/")[1],
        clientId: clientID
      };

      let loginUrl = getAPIURL(REACT_APP_FetchSubProcessesByGuidAsync);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setsubprocesses(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const handleSubProcessDropdownChange = (e) => {
    if (e.target.value == "Select") {
      setsubProcessIDstr("");
      setselectedSubProcessName("");
    }
    if (e.target.value !== "Select") {
      sessionStorage.setItem(
        "selectedSubProcessID",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedSubProcessName",
        e.target.value.split("/")[0]
      );
      setsubProcessIDstr(e.target.value.split("/")[1]);
      setselectedSubProcessName(e.target.value.split("/")[0]);
    }
  }

  const getAdvanceText = (e) => {
    //debugger;
    setAdvanceText(e.target.value);
    // console.log(e.target.value);
  };
  const getExpiryDateText = (e) => {
    //debugger;
    setExpiryDateText(e.target.value);
    // console.log(e.target.value);
  };
  const getNewsBodyText = (data) => {
    setNewsBodyText(data);
    // console.log(data);
  };
  const getPriorityText = (e) => {
    //debugger;
    setPriorityText(e.target.value);
    // console.log(e.target.value);
  };
  const getApplicationNewsTypeText = (e) => {
    //debugger;
    setApplicationNewsTypeText(e.target.value);
    // console.log(e.target.value);
  };

  const updateNewsData = (isRedirect) => {
    let body;

    let loginUrl = getAPIURL(REACT_APP_UPDATENEWS);
    body = {
      intNewsID: location.state.newsID,
      stringNewsTitle: AdvanceText,
      datetimeExpiryDate: ExpiryDateText,
      stringNewsText: NewsBodyText,
      stringPriority: PriorityText,
      intEmployeeId: sessionStorage.getItem("PersonID"),
      applicationNewsType: ApplicationNewsTypeText,
    };

    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          // debugger;

          addToast(" saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          if (isRedirect) {
            // navigate("/ManageNews", {
            //   nav:nav,
            //   assignedWorkItemDetails: navData
            // }
            // );
            navigate("/ManageNews", {
              state: {
                nav,
                assignedWorkItemDetails: navData,
                checkedNewsType: sessionStorage.getItem("checkedValue")
              },
            });
          }
          // updatenews();
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const updateNewsDataForClient = (isRedirect) => {
    let body;

    let _entity;
    let _entityType;
    if (subProcessIDstr !== "") {
      _entity = subProcessIDstr;
      _entityType = "SubProcess";
    }
    else if (processIDstr !== "" && clientID !== "" && subProcessIDstr == "") {
      _entity = processIDstr;
      _entityType = "Process";
    }
    else if (clientID !== "" && processIDstr == "" && subProcessIDstr == "") {
      _entity = clientID;
      _entityType = "Client";
    }

    let loginUrl = getAPIURL(REACT_APP_UPDATENEWSFORCLIENT);
    body = {
      intNewsID: location.state.newsID,
      stringNewsTitle: AdvanceText,
      datetimeExpiryDate: ExpiryDateText,
      stringPriority: PriorityText,
      stringNewsText: NewsBodyText,
      intEmployeeId: sessionStorage.getItem("PersonID"),
      applicationNewsType: ApplicationNewsTypeText,
      entity: _entity,
      entityType: _entityType
    };

    
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          // debugger;
          addToast(" saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          if (isRedirect) {
            // navigate("/ManageNews", {
            //   nav:nav,
            //   assignedWorkItemDetails: navData
            // }
            // );
            navigate("/ManageNews", {
              state: {
                nav,
                assignedWorkItemDetails: navData,
                checkedNewsType: sessionStorage.getItem("checkedValue")
              },
            });
          }

          // updatenews();
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const GetNewsById = () => {
    
    let body = {
      intNewsID: location.state.newsID,
      // guid: sessionStorage.getItem("GUID"),
      // subProcessId: subprocessId,
      // clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_GETNEWSBYID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // console.log(result.data);
          setAdvanceText(result.data[0].newsTitle);
          setApplicationNewsTypeText(result.data[0].applicationNewsType);
          setPriorityText(result.data[0].priority);
          setNewsBodyText(result.data[0].newsBody);

          const apiDateString = result.data[0].expiryDate.toString();
          // console.log(moment(new Date(apiDateString)).format("YYYY-MM-DD"));
          setExpiryDateText(moment(new Date(apiDateString)).format("YYYY-MM-DD"));

        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const GetNewsByIdForClient = () => {
    let body = {
      intNewsID: location.state.newsID,
      // guid: sessionStorage.getItem("GUID"),
      // subProcessId: subprocessId,
      // clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_GETNEWSBYIDFORCLIENT);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // console.log(result.data);
          setAdvanceText(result.data[0].newsTitle);
          setApplicationNewsTypeText(result.data[0].applicationNewsType);
          setPriorityText(result.data[0].priority);
          setNewsBodyText(result.data[0].newsBody);
          const apiDateString = result.data[0].expiryDate.toString();
          setExpiryDateText(moment(new Date(apiDateString)).format("YYYY-MM-DD"));

          setClientID(result.data[0].clientID.toString());
          if (result.data[0].clientID.toString() !== "0") {
            FetchProcessesByGuidOnFectchingDetails(result.data[0].clientID.toString(), result.data[0].processID.toString());

            if (result.data[0].processID.toString() !== "0")
              setprocessIDstr(result.data[0].processID.toString());
            else
              setprocessIDstr("");
          }
          if (result.data[0].clientID.toString() !== "0" && result.data[0].processID.toString() !== "0") {
            FetchSubProcessesByGuidOnFectchingDetails(result.data[0].clientID.toString(), result.data[0].processID.toString(), result.data[0].subProcessID.toString());
            if (result.data[0].subProcessID.toString() !== "0")
              setsubProcessIDstr(result.message);
            else
              setsubProcessIDstr("");
          }

          // sessionStorage.setItem(
          //   "SelctedSubprocessName",
          //   result.data[0].processSubProcess
          // );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchProcessesByGuidOnFectchingDetails = (client, process) => {
    if (client == "0") {
      setClientID("");
      setSelectedClient("");
      setProcesses([]);
      setprocessIDstr("");
      setselectedProcessName("");
      setsubprocesses([]);
      setsubProcessIDstr("");
      setselectedSubProcessName("");
    }
    if (client !== "0") {
      let body = {
        guid: sessionStorage.getItem("GUID"),
        processId: "",
        clientId: client
      };

      let loginUrl = getAPIURL(REACT_APP_FetchProcessesByGuid);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setProcesses(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const FetchSubProcessesByGuidOnFectchingDetails = (client, process, subProcess) => {
    if (process == "0") {
      setprocessIDstr("");
      setselectedProcessName("");
      setsubprocesses([]);
      setsubProcessIDstr("");
      setselectedSubProcessName("");
    }
    if (process !== "0") {

      let body = {
        guid: sessionStorage.getItem("GUID"),
        processId: process,
        clientId: client
      };

      let loginUrl = getAPIURL(REACT_APP_FetchSubProcessesByGuidAsync);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setsubprocesses(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const NavElements = useMemo(() => {
    return (
      <>
        <Header onpreferenceShow={null} pageName="Add/Edit News" />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav selectedMenu="Configuration" />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);

  return (
    <>
      {NavElements}

      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              {dropdownsDivVisible ? (
                <div className="row align-items-end">
                  <div className="col-4">
                    <div className="mb-3">
                      <label className="form-label"> Company Department</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchLocationClientsByGuid}
                      >
                        <option>Select</option>
                        {departments.map((department) => (
                          <option
                            value={
                              department.companyDepartment +
                              "/" +
                              department.departmentIDString
                            }
                            selected={
                              department.departmentIDString == companyDeparmentID
                            }
                          >
                            {department.companyDepartment}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label className="form-label">Location Client</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchProcessSubProcessByGuid}
                      >
                        <option>Select</option>
                        {clientLocations.map((location) => (
                          <option
                            value={
                              location.locationClient +
                              "/" +
                              location.clientIDString
                            }
                            selected={location.clientIDString == locationClientID}
                          >
                            {location.locationClient}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label className="form-label">Process SubProcess</label>
                      <select
                        className="form-control form-control-sm"
                        onChange={FetchInvalidProcessSubprocessByGuid}
                        ref={testref}
                      >
                        <option>Select</option>
                        {processSubprocess.map((subprocess) => (
                          <option
                            value={
                              subprocess.subProcessIDString +
                              "/" +
                              subprocess.processSubProcess
                            }
                            selected={
                              subprocess.subProcessIDString == processSubProcessID
                            }
                          >
                            {subprocess.processSubProcess}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )
                : (
                  <div className="row align-items-end">
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="form-label"> Clients * </label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchProcessesByGuid}
                        >
                          <option>Select</option>
                          {clients.map((client) => (
                            <option
                              value={
                                client.clientName +
                                "/" +
                                client.clientIDString
                              }
                              selected={
                                client.clientIDString == clientID
                              }
                            >
                              {client.clientName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="form-label">Process</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchSubProcessesByGuid}
                        >
                          <option>Select</option>
                          {processes.map((process) => (
                            <option
                              value={
                                process.processName +
                                "/" +
                                process.processIDString
                              }
                              selected={process.processIDString == processIDstr}
                            >
                              {process.processName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="form-label">SubProcess</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={handleSubProcessDropdownChange}
                          ref={testref}
                        >
                          <option>Select</option>
                          {subProcesses.map((subprocess) => (
                            <option
                              value={
                                subprocess.subProcessName +
                                "/" +
                                subprocess.subProcessIDString
                              }
                              selected={
                                subprocess.subProcessIDString == subProcessIDstr
                              }
                            >
                              {subprocess.subProcessName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}

            </div>

            <div className="formWrap">
              <div className="row align-items-end">

                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">News Title *</label>
                    <input
                      className="form-control form-control-sm"
                      //ref={SearchTextRef}
                      onChange={getAdvanceText}
                      value={AdvanceText}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Expiry Date* </label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      onChange={getExpiryDateText}
                      value={ExpiryDateText}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Priority</label>

                    <select
                      className="form-control form-control-sm"
                      onChange={getPriorityText}
                      value={PriorityText}
                      defaultValue="Select"
                    >
                      <option value="">Select</option>
                      <option>High</option>
                      <option>Normal</option>
                    </select>
                  </div>
                </div>

                <div className="col-3">
                  <div className="mb-3">
                    <label className="form-label">Application News Type</label>
                    <select
                      onChange={getApplicationNewsTypeText}
                      className="form-control form-control-sm"
                      value={ApplicationNewsTypeText}
                      defaultValue="Select"
                    >
                      <option value="">Select</option>
                      <option>Both</option>
                      <option>CWS</option>
                      <option>PD</option>
                    </select>
                  </div>
                </div>
                <div className="row align-items-end"></div>

              </div>

              <div className="mb-3">
                <label className="form-label">Enter The News Text Here*</label>

                <CKEditor
                  editor={ClassicEditor}
                  // data={inputValue}
                  onReady={(editor) => {
                    // console.log(
                    //   "CKEditor React Component is ready to use!",
                    //   editor
                    // );
                    // CKEditorInspector.attach(editor);
                  }}
                  data={NewsBodyText}
                  config={{
                    readOnly: false,
                    toolbar: !false
                      ? [
                        "undo",
                        "redo",
                        "|",
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "|",
                        "link",
                        "unlink",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        "fontSize",
                        "|",
                        "fontFamily",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "removeFormat",
                        "|",
                        "insertTable",
                      ]
                      : [],
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    getNewsBodyText(data);
                    // console.log({ event, editor, data });
                  }}
                />
                {/* onChange={getNewsBodyText} */}

                <div class="mb-3" style={{ marginTop: "10px" }}>
                  {/* <button type="submit" class="btn btn-sm btn-primary ms-3"
                       onClick={() => {
                                  
                                  // UpdateNews();
                                }}> Save & Continue</button> */}
                  <button
                    type="submit"
                    className="btn btn-sm btn-primary ms-3"
                    ref={ButtonTextRef}
                    disabled={buttonText == "Update"}
                    onClick={() => {
                      if (NewsBodyText != "" && ExpiryDateText != "" && AdvanceText != "") {
                        if (sessionStorage.getItem("checkedValue") == "1") {
                          if (clientID != "") {
                            updateNewsDataForClient(true);
                            setApplicationNewsTypeText("");
                            setPriorityText("");
                            setNewsBodyText("");
                            setExpiryDateText("");
                            setAdvanceText("");
                            setClientID("");
                            setProcesses([]);
                            setprocessIDstr("");
                            setsubprocesses([]);
                            setsubProcessIDstr("");
                          }
                          else {
                            addToast("Please select a client", {
                              appearance: "error",
                              autoDismiss: true,
                            });
                          }
                        }
                        else {
                          updateNewsData(false);
                          setApplicationNewsTypeText("");
                          setPriorityText("");
                          setNewsBodyText("");
                          setExpiryDateText("");
                          setAdvanceText("");
                        }
                      }
                      else {
                        addToast("Please fill mandatory fields marked with *", {
                          appearance: "error",
                          autoDismiss: true,
                        });
                      }
                    }}
                  >
                    Save & Continue
                  </button>

                  <button
                    type="submit"
                    className="btn btn-sm btn-primary ms-3"
                    ref={ButtonTextRef}
                    onClick={() => {
                      if (NewsBodyText != "" && ExpiryDateText != "" && AdvanceText != "") {
                        if (sessionStorage.getItem("checkedValue") == "1") {
                          if (clientID != "") {
                            updateNewsDataForClient(true);
                            setApplicationNewsTypeText("");
                            setPriorityText("");
                            setNewsBodyText("");
                            setExpiryDateText("");
                            setAdvanceText("");
                            setClientID("");
                            setProcesses([]);
                            setprocessIDstr("");
                            setsubprocesses([]);
                            setsubProcessIDstr("");
                          }
                          else {
                            addToast("Please select a client", {
                              appearance: "error",
                              autoDismiss: true,
                            });
                          }
                        }
                        else {
                          updateNewsData(true);
                          setApplicationNewsTypeText("");
                          setPriorityText("");
                          setNewsBodyText("");
                          setExpiryDateText("");
                          setAdvanceText("");
                        }
                      }
                      else {
                        addToast("Please fill mandatory fields marked with *", {
                          appearance: "error",
                          autoDismiss: true,
                        });
                      }
                    }}
                  >
                    {buttonText}
                  </button>


                  <Link
                    to={`../ManageNews`}
                    state={{
                      nav,
                      assignedWorkItemDetails: navData,
                      checkedNewsType: sessionStorage.getItem("checkedValue")
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-2"
                    >
                      Back
                    </button>
                  </Link>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddEditNews;

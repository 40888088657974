import React, {useState, useEffect} from "react";
import {Paper} from "@material-ui/core";
import NavDataContext from "../../../../contexts/NavDataContext";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import Header from "../../../../components/header/Header";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import Modal from "react-bootstrap/Modal";
import {useNavigate } from "react-router-dom";
import {useToasts } from "react-toast-notifications";
import EditIcon from "@mui/icons-material/Edit";
import editicon from "../../../../assets/images/icons/edit_black_18dp.svg";

function SubProcessFieldValueGroupConfig() {
    const location = useLocation();
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const processingDashboardParam = location.state;
    const { REACT_APP_API_URL } = process.env;
    const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
    const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
    const { React_APP_FetchAllProcessSubProcessByGuid } = process.env;
    const { REACT_APP_GetWorkItemFieldsbySubProcessIDForGroupApproval } = process.env;
    const { REACT_APP_GetDisplayApprovalConfigList } = process.env;
    const { REACT_APP_GetFieldValuesForTheWorkItemField } = process.env;
    const { REACT_APP_SaveGroupApprovalConfigbySubProcess } = process.env;
    const { REACT_APP_DeleteWorkItemFieldUserConfig } = process.env;
    let approvalFields ="";
    const selectedSubprocess = processingDashboardParam.selectedSubprocess;
    const nav = processingDashboardParam.nav;
    const userRoles = processingDashboardParam.userRoles;
    const selectedWorkItemDetails = processingDashboardParam.selectedWorkItemDetails;
    let currentAcitvity = "";
    if (typeof processingDashboardParam.row.activityName !== "undefined") {
        currentAcitvity = processingDashboardParam.row.activityName;
    } else {
        currentAcitvity = processingDashboardParam.currentAcitvity;
    }
    let navData = [];
    if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
        navData = processingDashboardParam.assignedWorkItemDetails;
    } else {
        navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
    }
    const activityID = processingDashboardParam.nav[0].workflowStateID;

    const [isLoading, setIsLoading] = useState(0);
    const [companyDeparmentID, setcompanyDeparmentID] = useState("");
    const [locationClientID, setlocationClientID] = useState("");
    const [processSubProcessID, setprocessSubProcessID] = useState("");
    const [departments, setDepartments] = useState([]);
    const [clientLocations, setClientLocations] = useState([]);
    const [processSubprocess, setprocessSubprocess] = useState([]);
    const [UserList, setUserList] = useState([]);
    const [WorkitemFields, setWorkitemFields] = useState([]);
    const [isEnabled, setisEnabled] = useState(1);
    const [isSelectedMaptoField, setIsSelectedMaptoField] = useState(0);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [viewEditModal, setviewEditModal] = useState(false);
    const [AssignedToID, setAssignedToID] = useState("");
    const [fieldValueforWorkitem, setfieldValueforWorkitem] = useState([]);
    const [WorkitemFieldvalues, setWorkitemFieldvalues] = useState([]);
    const [maptofield, setmaptofield] = useState("");

    const getAPIURL = api => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
    };

    const columns = [
        {
            title: "Field List",
            field: "labelText",
        },
        {
            title: "User Name",
            field: "assignTo",
        }
    ];

    const modalcolumns = [
        {
            title: "Select",
            field: "select",
            editComponent: (props) => {
                return (
                    <input
                        type="checkbox"
                        checked={props.value}
                        onChange={(e) => {
                            props.onChange(e.target.checked);
                            handleCheckboxChange(props.rowData.value);
                        }}
                    />
                );
            },
            render: (rowData) => (
                <>
                    <input
                        type="checkbox"
                        id="chkisselected"
                        checked={rowData["select"]}
                        onChange={() => handleCheckboxChange(rowData.value)}
                    />
                </>
            ),
            cellStyle: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
        },
        {
            title: "Value",
            field: "value",
        }
    ];

    const handleCheckboxChange = (value) => {
        setfieldValueforWorkitem(prevList =>
            prevList.map(item => {
                if (item.value == value) {
                    return { ...item, select: !item.select, initialSelection: !item.initialSelection};
                }
                return item; 
            })
        );
    };

    useEffect(() => {
        FetchDepartmentbyGUID();
        FetchDefaultValueForSelectedSubprocess(
            sessionStorage.getItem("selectedDepartment"),
            sessionStorage.getItem("selectedLocation"),
            sessionStorage.getItem("ProcessId"),
        );
    }, []);

    const FetchProcessSubProcessByGuid = (e) => {
        setUserList([]);
        setisEnabled(true);
        setlocationClientID("");
        setprocessSubProcessID("");
        setWorkitemFields([]);
        if (e.target.value == "Select") {
            setprocessSubprocess([]);
        }
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: e.target.value.split("/")[1],
        };

        let loginUrl = getAPIURL(React_APP_FetchAllProcessSubProcessByGuid);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setprocessSubprocess(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    setprocessSubprocess([]);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    const FetchLocationClientsByGuid = (e) => {
        setUserList([]);
        setprocessSubprocess([]);
        setisEnabled(true);
        setWorkitemFields([]);
        setcompanyDeparmentID("");
        setlocationClientID("");
        setprocessSubProcessID("");
        if (e.target.value == "Select") {
            setClientLocations([]);
        }
        if (e.target.value !== "Select") {
            let body = {
                guid: sessionStorage.getItem("GUID"),
                departmentId: e.target.value.split("/")[1],
            };

            let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
            let req = new Request(`${loginUrl}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    UserName: sessionStorage.getItem("UserName"),
                    Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                },
                body: JSON.stringify(body),
            });
            fetch(req)
                .then((res) => res.json())
                .then((result) => {
                    if (result.status == 200) {
                        setClientLocations(result.data);
                    } else if (result.status === 401) {
                        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                        sessionStorage.clear();
                        navigate("/");
                    }
                })
                .catch((error) => {
                    console.log("ERROR: ", error);
                });
        }
    };

    const FetchDepartmentbyGUID = () => {
        let body = {
            memberShipGuid: sessionStorage.getItem("GUID"),
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setDepartments(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            departmentId: departmentId,
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setClientLocations(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: clientId,
        };

        let loginUrl = getAPIURL(React_APP_FetchAllProcessSubProcessByGuid);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    setprocessSubprocess(result.data);
                    fetchWorkitemFields();
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else{
                    addToast("An Unexpected Error Occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    const FetchDefaultValueForSelectedSubprocess = (
        companyDeparmentID,
        locationClientID,
        processSubProcessID
    ) => {
        setcompanyDeparmentID(companyDeparmentID);
        FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
        setlocationClientID(locationClientID);
        FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
        setprocessSubProcessID(processSubProcessID);
    };

    const handleSubprocesschange = (e) => {
        sessionStorage.setItem("ProcessId", e.target.value);
        fetchWorkitemFields();
        setisEnabled(true);
        setUserList([]);
        setfieldValueforWorkitem([]);
    }

    const handleWorkitemchange = (e) => {
        if (e.target.value != "Select") {
            setIsTableLoading(true);
            fetchUserList();
            let mappedField = e.target.value.split('~')[0];
            setmaptofield(mappedField);
        } else {
            setUserList([]);
        }
    }

    const fetchUserList = () => {
        let APIURL = getAPIURL(REACT_APP_GetDisplayApprovalConfigList);
        let inputbody = {
            subProcessID: sessionStorage.getItem("ProcessId")
        };
        let req = new Request(`${APIURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(inputbody),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setisEnabled(false);
                    setUserList(result.data);
                    setIsTableLoading(false);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const fetchWorkitemFields = () => {
        let APIURL = getAPIURL(REACT_APP_GetWorkItemFieldsbySubProcessIDForGroupApproval);
        let inputbody = {
            subProcessID: sessionStorage.getItem("ProcessId"),
        };
        let req = new Request(`${APIURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(inputbody),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setWorkitemFields(result.data);
                    if (result.data[0].SelectedMaptoField != "" && result.data[0].SelectedMaptoField != null) {
                        setIsSelectedMaptoField(1);
                    }
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    setWorkitemFields([]);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const handleEdit = (e) => {
        deleteWorkItem();
    }

    const deleteWorkItem = () => {
        let APIURL = getAPIURL(REACT_APP_DeleteWorkItemFieldUserConfig);
        let inputbody = {
            subProcessID: sessionStorage.getItem("ProcessId"),
        };
        let req = new Request(`${APIURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(inputbody),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setisEnabled(1);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("An Unexpected Error Occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const EditUserGroupFieldValues = (e) => {
        setAssignedToID(e["assignedToID"]);
        setviewEditModal(true);
        fetchWorkitemFieldsforEdit(e);
    }

    const fetchWorkitemFieldsforEdit = (rowData) => {
        let APIURL = getAPIURL(REACT_APP_GetFieldValuesForTheWorkItemField);
        let inputbody = {
            subProcessID: sessionStorage.getItem("ProcessId"),
            mapToField: maptofield,
            personID: rowData.assignedToID
        };
        let req = new Request(`${APIURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(inputbody),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setfieldValueforWorkitem(result.data);
                    setWorkitemFieldvalues(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("An Unexpected Error Occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const viewEditCloseAndReset = () => {
        setviewEditModal(false);
    }

    const handleSave = () =>{
        fieldValueforWorkitem.forEach(
            (element) => {
                if(element.select){
                    approvalFields+=element.value;
                    approvalFields+=",";
                }
            }
        );
        if(approvalFields.length>0)
        {
        let APIURL = getAPIURL(REACT_APP_SaveGroupApprovalConfigbySubProcess);
        let inputbody = {
            subProcessID: sessionStorage.getItem("ProcessId"),
            mapToField: maptofield,
            lastUpdatedBy: sessionStorage.getItem("PersonID"),
            assignedTo: AssignedToID,
            groupApprovalConfigFields:approvalFields
          };
        let req = new Request(`${APIURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(inputbody),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    fetchUserList();
                    viewEditCloseAndReset();
                    addToast("Field value configuration saved successfully!", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("An Unexpected Error Occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
        }
        else{
            addToast("Please select Workitem Field value(s).", {
                appearance: "error",
                autoDismiss: true,
            });
        }
    }

    const handleReset = () =>{
        setfieldValueforWorkitem(WorkitemFieldvalues);
    };

    const handleMarkAll = () =>{
        setfieldValueforWorkitem(prevList =>
            prevList.map(item => {
                return { ...item, select: 1, initialSelection: 1};
            })
        );
    }

    const handleUnmarkAll = () =>{
        setfieldValueforWorkitem(prevList =>
            prevList.map(item => {
                return { ...item, select: 0, initialSelection: 0};
            })
        );
    }

    return (
        <><Header
            onpreferenceShow={null}
            pageName="Sub-Process Field Value Group Config"
            timeCaptureActivityName="GroupApprovalConfig"
        />
            <ApplicationUserContext.Provider
                value={processingDashboardParam.userRoles}
            >
                <DashboardContext.Provider value={processingDashboardParam.nav}>
                    <NavDataContext.Provider value={navData}>
                        <Nav selectedMenu="configuration" />
                    </NavDataContext.Provider>
                </DashboardContext.Provider>
            </ApplicationUserContext.Provider>
            <Modal
                show={viewEditModal}
                dialogClassName="modal-100w"
                onHide={viewEditCloseAndReset}
                fullscreen={"lg-down"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Sub-Process User Group Field Value Config</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="timeBreak">
                        <ul className="timeBreak-form">
                            <div>Note: By default, a user belongs to all the groups, unless the group selection is modified.</div>
                            {isTableLoading ? (
                                <LoadingSpinnerFigma />
                            ) : (
                                <div className="tableWrap-body">
                                    <table className="table table-borderless MatTable">
                                        <MaterialTable
                                            localization={{
                                                pagination: {
                                                },
                                                toolbar: {
                                                    exportCSVName: "Export as Excel",
                                                    exportPDFName: "Export as pdf"
                                                },
                                                header: {
                                                    actions: ""
                                                }
                                            }}
                                            options={{
                                                headerStyle: {
                                                    position: "sticky",
                                                    top: "-5px"
                                                },
                                                maxBodyHeight: "420px",
                                                showTitle: false,
                                                exportButton: false,
                                                sorting: true,
                                                search: false,
                                                selection: false,
                                                toolbarButtonAlignment: "left",
                                                overflowY: false,
                                                headerStyle: {
                                                    height: 30
                                                },
                                                rowStyle: {
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontSize: "13px"
                                                },
                                                tableLayout: "auto",
                                                headerStyle: {
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontWeight: "inherit",
                                                    fontSize: "14px",
                                                    textAlign: "left !Important"
                                                }
                                            }}
                                            title="Field Value Group Config"
                                            columns={modalcolumns}
                                            icons={tableIcons}
                                            data={fieldValueforWorkitem}
                                            components={{
                                                Container: props => (
                                                    <Paper {...props} elevation={0} />
                                                ),
                                                Toolbar: props => (
                                                    <div
                                                        style={{
                                                            backgroundColor: "rgb(206 206 206)",
                                                            fontSize: "0.75em"
                                                        }}
                                                    >
                                                        <MTableToolbar
                                                            {...props}
                                                            style={{
                                                                fontSize: "5px"
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </table>
                                </div>)}
                        </ul>
                    </div>
                    <div className="col-6">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary ms-3"
                            title="Save"
                            position="row"
                            onClick={() => handleSave()}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="btn btn-sm btn-primary ms-3"
                            title="Reset"
                            position="row"
                            onClick={() => handleReset()}
                        >
                            Reset
                        </button>
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary ms-3"
                            title="Click here to select all the rows"
                            position="row"
                            onClick={() => handleMarkAll()}
                        >
                            Mark All
                        </button>
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary ms-3"
                            title="Click here to deselect all the rows"
                            position="row"
                            onClick={() => handleUnmarkAll()}
                        >
                            Un-Mark All
                        </button>
                        <button
                            type="submit"
                            className="btn btn-sm btn-primary ms-3"
                            title="Close"
                            position="row"
                            onClick={() => viewEditCloseAndReset()}
                        >
                            Close
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                
                </Modal.Footer>
            </Modal>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="centerSection">
                        <div className="pageBody">
                            <div className="dashBoxs mb-3">
                                <div className="formWrap">
                                    <div className="row align-items-end">
                                        <div className="col-3">
                                            <div className="mb-3">
                                                <label className="form-label"> Company Department</label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    onChange={FetchLocationClientsByGuid}
                                                    title="Select a Company-Department"
                                                    required
                                                >
                                                    <option>Select</option>
                                                    {departments.map((department) => (
                                                        <option
                                                            value={
                                                                department.companyDepartment +
                                                                "/" +
                                                                department.departmentIDString
                                                            }
                                                            selected={department.departmentIDString == companyDeparmentID}
                                                        >
                                                            {department.companyDepartment}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="mb-3">
                                                <label className="form-label">Location Client</label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    onChange={FetchProcessSubProcessByGuid}
                                                    title="Select a Location-Client"
                                                    required
                                                >
                                                    <option selected>Select</option>
                                                    {clientLocations.map((location) => (
                                                        <option
                                                            value={
                                                                location.locationClient +
                                                                "/" +
                                                                location.clientIDString
                                                            }
                                                            selected={location.clientIDString == locationClientID}
                                                        >
                                                            {location.locationClient}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="mb-3">
                                                <label className="form-label">Process SubProcess</label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    onChange={handleSubprocesschange}
                                                    title="Select a Process-SubProcess"
                                                    required
                                                >
                                                    <option selected>Select</option>
                                                    {processSubprocess.map((subprocess) => (
                                                        <option
                                                            value={subprocess.subProcessIDString}
                                                            selected={subprocess.subProcessIDString == processSubProcessID}
                                                        >
                                                            {subprocess.processSubProcess}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {!isSelectedMaptoField ?
                                            <div className="col-2">
                                                <div className="mb-3">
                                                    <label className="form-label">WorkItem Fields</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        onChange={handleWorkitemchange}
                                                        title="Select a WorkItem Field"
                                                        required
                                                        disabled={!isEnabled}
                                                    >
                                                        <option selected>Select</option>
                                                        {WorkitemFields.map((item) => (
                                                            <option
                                                                value={item.mapToFieldAndDataType}
                                                            >
                                                                {item.labeltext}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div> :
                                            <div className="col-2">
                                                <div className="mb-3">
                                                    <label className="form-label">WorkItem Fields</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        onChange={handleWorkitemchange}
                                                        title="Select a WorkItem Field"
                                                        required
                                                        disabled
                                                    >
                                                        <option>Select</option>
                                                        {WorkitemFields.map((item) => (
                                                            <option
                                                                value={item.MaptoFieldAndDataType}
                                                                selected={item.MaptoFieldAndDataType == WorkitemFields[0].MaptoFieldAndDataType}
                                                            >
                                                                {item.labeltext}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>}
                                        <div className="col-1">
                                            <div className="mb-4">
                                                <div title="Edit WorkItem Field">
                                                    <img
                                                        id="iconEditWorkitemFields"
                                                        className="editProcessIcon"
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={handleEdit}
                                                        src={editicon}
                                                        alt="Edit"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isTableLoading ? (
                                <LoadingSpinnerFigma />
                            ) : (
                                <div className="tableWrap-body">
                                    <table className="table table-borderless MatTable">
                                        <MaterialTable
                                            localization={{
                                                pagination: {
                                                },
                                                toolbar: {
                                                    exportCSVName: "Export as Excel",
                                                    exportPDFName: "Export as pdf"
                                                },
                                                header: {
                                                    actions: ""
                                                }
                                            }}
                                            actions={[
                                                {
                                                    icon: () => <EditIcon />,
                                                    tooltip: "Edit Workitem Field Details",
                                                    onClick: (event, rowData) => EditUserGroupFieldValues(rowData),
                                                    position: "row",
                                                }
                                            ]}
                                            options={{
                                                headerStyle: {
                                                    position: "sticky",
                                                    top: "-5px"
                                                },
                                                maxBodyHeight: "420px",
                                                showTitle: false,
                                                exportButton: false,
                                                sorting: true,
                                                search: false,
                                                selection: false,
                                                toolbarButtonAlignment: "left",
                                                overflowY: false,
                                                headerStyle: {
                                                    height: 30
                                                },
                                                rowStyle: {
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontSize: "13px"
                                                },
                                                tableLayout: "auto",
                                                headerStyle: {
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontWeight: "inherit",
                                                    fontSize: "14px",
                                                    textAlign: "left !Important"
                                                }
                                            }}
                                            title="Field Value Group Config"
                                            columns={columns}
                                            icons={tableIcons}
                                            data={UserList}
                                            components={{
                                                Container: props => (
                                                    <Paper {...props} elevation={0} />
                                                ),
                                                Toolbar: props => (
                                                    <div
                                                    style={{
                                                        backgroundColor: "rgb(206 206 206)",
                                                        fontSize: "0.75em"
                                                    }}
                                                >
                                                    <MTableToolbar
                                                        {...props}
                                                        style={{
                                                            fontSize: "5px"
                                                        }}
                                                    />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </table>
                                </div>)}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
export default SubProcessFieldValueGroupConfig;
import React, {
    useState,
    useEffect,
    useCallback
  } from "react";
  import debounce from "lodash.debounce";
  import { useNavigate, useLocation } from "react-router-dom";
  import { Link} from "react-router-dom";
  import Header from "../../../../components/header/Header";
  import Footer from "../../../../components/footer/Footer";
  import Nav from "../../../../components/nav/Nav";
  import tableIcons from "../../../../MaterialTableIcons";
  import MaterialTable, { MTableToolbar } from "material-table";
  import { createTheme} from "@material-ui/core/styles";
  import DashboardContext from "../../../../contexts/DashboardContext";
  import NavDataContext from "../../../../contexts/NavDataContext";
  import {useToasts } from "react-toast-notifications";
  
  import {Paper} from "@material-ui/core";
    function SubProcessStatusHistoryMapping () {
      const location = useLocation();
      let navigate = useNavigate();
      const processingDashboardParam = location.state;
    
      let navData = [];
    
      if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
        navData = processingDashboardParam.assignedWorkItemDetails;
      } else {
        navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
      }
      const { REACT_APP_API_URL } = process.env;
      const { REACT_APP_GETWORKITEMFIELDSSTATUSHISTORY } = process.env;
      const { REACT_APP_ADDEDITSUBPROCESSSTATUSHISTORYCONFIG } = process.env;
      const getAPIURL = (api) => {
          let apiURL = REACT_APP_API_URL;      
          return `${apiURL}${api}`;
        };
        const [displayOrderArry,setdisplayOrderArry] =useState([]);
        const [numberOfRecord,setNumberofRecords]=useState(18);
        const [timeOutId,setTimeOutId]=useState(null);
        const [labelName,setLabelName]=useState("");
        const [rowData,setRowData]=useState("");
        const [ExcelColumns,setExcelColumns] = useState([]);
        const [ishandleChange,setIsHandleChange]=useState(true);
        const { addToast } = useToasts();
        useEffect(() => {
          GetWorkItemStatusHistory();
          // setCheck(false);
          setdisplayOrderArry([]);
          for (let index = 1; index <= 18; index++) {
            setdisplayOrderArry(oldArray => [...oldArray, index]);
          }
        }, []);
        // useEffect(() => {
        //   const timeoutId = setTimeout(() => {
        //     debugger;
        //     let newExcelColumns = ExcelColumns.map((item)=>{
        //       if(item.workItemFieldID==rowData.workItemFieldID)
        //         {
        //           return{...item, labelName:labelName}
        //         }
        //         else
        //         {
        //           return item;
        //         }
        //     })
        //     setExcelColumns(newExcelColumns);
        //   }, 1000
        // );
        //   return () => clearTimeout(timeoutId);
        //   }, 
        //   [labelName]);
  
        const updateColumns = useCallback(
          (keys) => {
            const workflowstateID = sessionStorage.getItem("workflowstateID");
            const activityId = sessionStorage.getItem("PageId");
            let col = [];
            keys.forEach((item) => {
              //console.log(item)
      
              if (item === "WorkItemID") {
                col.push({
                  title: item,
                  field: item,
                  // cellStyle: (e, rowData) => {
                  //   return { backgroundColor: rowData.BGColor };
                  // },
                  render: (row) => (
                    <>
                      <span
                        style={{ backgroundColor: row.BGColor }}
                        class="dot"
                        title="Why this color? Click to see."
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasColor"
                        aria-controls="offcanvasColor"
                      ></span>
                      <Link
                        to="/ActivityScreen"
                        state={{
                          row,
                          // selectedSubprocess,
                          // nav,
                          // userRoles,
                          employeeVersionID:
                            sessionStorage.getItem("EmployeeVersionID"),
                          workflowstateID,
                          activityId,
                          assignedWorkItemDetails: navData,
                          workItemID: row[item],
                          // currentAcitvity: currentAcitvity,
                        }}
                      >
                        {row[item]}
                      </Link>
                    </>
                  ),
                  editable: false,
                  //filtering: true,
                });
              } else {
                col.push({
                  title: item,
                  field: item
                });
              }
            });
          },
          // [GridColLen]
        );
        const HandleDisplayOrder = (e, rowData) => {
      
          const newData = [...ExcelColumns];
      
          newData[rowData.tableData.id]["displayOrder"] = e.target.value;
          setExcelColumns(newData);
        };
  
        const columns = [
          {
              // title: "Select",
              field: "isSelected",
            editComponent: (props) => {
                // console.log(props);
                return (
                    <input
                        type="checkbox"
                        checked={props.value}
                        onChange={ (e) => {
                            props.onChange(e.target.checked);
                            handleCheckboxChange(props.rowData.workItemFieldID);
                        }}
                    />
                );
            },
            render: (rowData) => (
                <>
                    <input
                        type="checkbox"
                        id=""
                        checked={rowData.isSelected}
                        onChange={() => handleCheckboxChange(rowData.workItemFieldID)}
                    />
                </>
            ),
            },
          {
            title: "Field Name",
            field: "fieldName",
            render: (rowData) => (
              
              <>
              { rowData.labelText}
                {/* {token.fieldName} */}
                  </>
            ),
          },
          {
            title: "Label Name",
            field: "labelName",     
          render: (rowData) => (
              <>
                  {/* <CustomEditableCell rowData={rowData} value={rowData.labelName} onChange={ handleLabelNameChange}/> */}
                  <input
                          className="form-control form-control-sm"
                          type="text"
                          // maxLength={50}                                                    
                          value={rowData.labelName}
                          onChange={(e) => handleLabelNameChange(e,rowData)}
                          // onKeyDown={handleLabelNameChangeWithDelay}
                          title={"; < > ' \" are not allowed."}
                          
                        />
              </>
          ),
          },
          {
            title: "Display Order",
            field: "displayOrder",
            render: rowData => (
              <>
                <select
                  id="displayOrder"
                  className="form-control form-control-sm"
                  onChange={e => HandleDisplayOrder(e, rowData)}
                >
                  {displayOrderArry.map(state => (
                    <option value={state} selected={state == rowData["displayOrder"]}>
                      {state}
                    </option>
                  ))}
                </select>
              </>
            )
          },
        ];
        
        const GetWorkItemStatusHistory = () => {
          let body ={      
            intSubProcessID: sessionStorage.getItem("ProcessId"),
            userId: sessionStorage.getItem("GUID")
          };
          let GetWorkItemStatusHistoryUrl = getAPIURL(REACT_APP_GETWORKITEMFIELDSSTATUSHISTORY);
          let req = new Request(`${GetWorkItemStatusHistoryUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status == 200) {
                // setCheck(false);
                // updateColumns(Object.keys(result.data[0]));
                setExcelColumns(result.data);
                setNumberofRecords(result.data.length);
              } else if (result.status === 401) {
                //unauthorized
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                // localStorage.clear();
                navigate("/");
              }
            })
            .catch((error) => {
              console.log("ERROR: ", error);
            });
        //   }
        // }
        }
        // const handleLabelNameChangeWithDelay = (e) =>{
        //   debugger;
        //   setIsHandleChange(false);
        //   setTimeout(()=>{setIsHandleChange(true)}, 2000)
        // }
        
        
        // useEffect(() => {
        //   // debugger;
        //   const timeOut = setTimeout(()=>{
        //     // debugger;
        //     console.log(labelName);
            

        //   },2000)
        //   return ()=>{
        //     clearTimeout(timeOut);
        //   }
        // }, [labelName]);
        // const debounceInputChange = useCallback(debounce((e, rowData) => {
        //   debugger;
        //   handleLabelNameChange(e, rowData);
        // }, 2000), []);
        const handleLabelNameChange = (e,rowData) => {
          // debugger;
          
          // clearTimeout(timeOutId);
          // const Id = setTimeout(()=>{
          //   debugger;
          //   let newExcelColumns = ExcelColumns.map((item)=>{
          //     if(item.workItemFieldID==rowData.workItemFieldID)
          //       {
          //         return{...item, labelName:e.target.value}
          //       }
          //       else
          //       {
          //         return item;
          //       }
          //   })
          //   setExcelColumns(newExcelColumns);
            
          // },2000)
          // setTimeOutId(Id);
          // e.preventDefault();
          // if(!ishandleChange){
          //   return;}
          
          // console.log(e.target.value);
          if(e.target.value.includes(";")||e.target.value.includes("<")||e.target.value.includes(">")||e.target.value.includes("'")||e.target.value.includes("\"")){
            setExcelColumns(ExcelColumns);
          }
          else{
          // setLabelName(e.target.value);
          // setRowData(rowData);
          const newData = ExcelColumns.map((item)=>{
            if(item.workItemFieldID==rowData.workItemFieldID){
              return{...item, labelName:e.target.value}
            }
            else
                {
                  return item;
                }
          })
          setExcelColumns(newData);
          }
          
      };
      
        const handleCheckboxChange = (itemId) => {

          setExcelColumns(prevList =>
              prevList.map(item => {
                  if (item.workItemFieldID === itemId) {
                      return { ...item, isSelected: !item.isSelected };
                  }
                  return item;
              })
          );
      };
      
      const handleSave = async () => {
        // debugger;
        let assignmentXMLRequest = [];
 
        ExcelColumns.forEach(
            (element) => {
              // debugger;
                if (element.isSelected) {
                    const input = {
                        subProcessId: sessionStorage.getItem("ProcessId"),
                        subProcessStatusHistoryConfigID:element.subProcessStatusHistoryMappingConfigID,                          
                        labelName:element.labelName,
                        workItemFieldID: element.workItemFieldID,
                        displayOrder: element.displayOrder                        
                    };
                    assignmentXMLRequest.push(input);
                }
            }
        );          
        AddEditSubProcStatHistConfig(assignmentXMLRequest);
      };
      const AddEditSubProcStatHistConfig = (assignmentXML) => {
      // debugger;
        let body ={
          
          intSubProcessID: sessionStorage.getItem("ProcessId"),
          SubprocessStatusHistoryConfigDetails: assignmentXML
          
        };
        let AddEditSubProcStatHistConfigUrl = getAPIURL(REACT_APP_ADDEDITSUBPROCESSSTATUSHISTORYCONFIG);
        let req = new Request(`${AddEditSubProcStatHistConfigUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              // debugger;
              addToast("Configuration saved successfully.", {
                appearance: "success",
                autoDismiss: true,
                });  
              updateColumns(Object.keys(result.data[0]));       
            } 
            else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              // localStorage.clear();
              navigate("/");
            }
            else if (result.status === 404) {
              //unauthorized
              addToast("Please select at least 1 Field name.", {
                appearance: "error",
                autoDismiss: true,
              });
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      //   }
      // }
      }
        return (
          <>
            <Header
              onpreferenceShow={null}
              pageName= "Sub-Process Status History Config"
              timeCaptureActivityName="Work Item Management"
            />
            <DashboardContext.Provider value={processingDashboardParam.nav}>
              <NavDataContext.Provider value={navData}>
                <Nav selectedMenu="activities" />
              </NavDataContext.Provider>
            </DashboardContext.Provider>
            {/* <div className="centerSection"> */}
              <div className="pageBody">
      
                {/* <h4 class="mb-3">Delegation Settings</h4>                     */}
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <div className="dashBoxs">
                      <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                        <div className="row align-items-center">
                          <div className="col-4">
                            <h6>Status History Configuration Grid</h6>                         
                          </div>
                        </div>
                      </div>
                      <table
                        className="table table-borderless MatTable"
                        style={{ zIndex: "1", position: "relative" }}
                      >
                        <MaterialTable
                          title="Status History Configuration Grid"
                          options={{
                            tableLayout: "auto",
                            overflowY: true,
                            exportButton: false,
                            sorting: true,
                            filtering: true,
                            search: false,
                            paging: true,
                            showTitle: false,
                            pageSize: numberOfRecord,
                            pageSizeOptions: [20, 50, 100],
                          }}
                          components={{
                            Container: (props) => <Paper {...props} elevation={0} />,
                            Toolbar: (props) => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em",
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{ fontSize: "5px" }}
                                />
                              </div>
                            ),
                          }}
                          columns={columns}
                          icons={tableIcons}
                          data={ExcelColumns}
                        />
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">   
                  <div className="col-6 text-start mt-4">
                    <button
                      className="btn btn-primary ms-3"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-primary ms-3"
                      onClick={GetWorkItemStatusHistory}                   
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            {/* </div> */}
            <Footer />
      
      
          </>
        );
  
    }
    export default SubProcessStatusHistoryMapping;
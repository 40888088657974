import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
 
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import Modal from "react-bootstrap/Modal";
 
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { ToastProvider, useToasts } from "react-toast-notifications";
import MaterialTable, { MTableToolbar } from "material-table";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import tableIcons from "../../../../MaterialTableIcons";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

 
import {
  MenuItem,
  Paper, Select,
  Typography
} from "@material-ui/core";
import session from "redux-persist/lib/storage/session";
import { set } from "date-fns";
import { faL } from "@fortawesome/free-solid-svg-icons";




 
function SubProcessBulkUpdateFileConfig() {
  const { addToast } = useToasts();
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_UPLOADEXCELCOLUMNS } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GetSubProcessBulkUpdateFileConfigList } = process.env;
  const {REACT_APP_GetBulkUpdateDetailByFileID} = process.env;
  const { REACT_APP_GetBulkUpdateFileConfigSPFieldDetails } = process.env;
  const { REACT_APP_GETExistingBulkUpdateFileConfig } = process.env;
  const { REACT_APP_GetUploadExcelColumnsBySubProcessID } = process.env;
  const { REACT_APP_GetWorkitemTypeRequiredForSubprocess } = process.env;
  const { REACT_APP_AddEditBulkUpdateFileConfig } = process.env;
  const { REACT_APP_DeleteSubProcessBulkUpdateConfig } = process.env;
 
  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [showModalButton, setShowModalButton] = useState(false);
  const [PriortyForSubProcessDetails, setPriortyForSubProcessDetails] =
    useState([]);

  const [Isdepartments, setIsDepartments] = useState([]);
  const [IsclientLocations, setIsClientLocations] = useState([]);
  const [IsprocessSubprocess, setIsprocessSubprocess] = useState([]);
  const [IsActivitiesForSubprocess, setIsActivitiesForSubprocess] = useState([]);
  const [ExcelColumns,setExcelColumns] = useState([]);
  const [tableColumns,setTableColumns] = useState([]);

  
  const [ BulkFileNameInput, setBulkFileNameInput ] = useState("")


  const [ editBulkFileNameInput, seteditBulkFileNameInput] = useState(
    ""
  )
  
const [ BulkUpdateDetails , setBulkUpdateDetails] = useState([]);

const [ BulkUpdateSPFields , setBulkUpdateSPFields] = useState([]);

const [ editBulkUpdateSPFields , seteditBulkUpdateSPFields] = useState([]);

const [ existingBulkDetails, setexistingBulkDetails] = useState("");

const [ WorkItemTypeRequired, setWorkItemTypeRequired] = useState([])

const [ bulkIDinEdit, setbulkIDinEdit] = useState("")


//For error message
const [ ErrorCompanyDepartment ,setErrorCompanyDepartment] = useState("DepartmentID")
 const [ errorlocationclient ,seterrorlocationclient] =useState("ClientID")

 const [ errorprocesssubprocess, seterrorprocesssubprocess] = useState(sessionStorage.getItem("ProcessId"))


 const [ areAllcheckboxesEnabled , setareAllcheckboxesEnabled ] = useState(false);





 const LocationClientRef = useRef("");
 const companyDepartmentRef = useRef("");
 const processSubprocessRef = useRef("");

 const processSubprocessRef1 = useRef("");


 

  





  const [openPopUp, setopenPopUp] = useState(false);
  const [EditPopUp, setEditPopUp] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const StatusNameRef = useRef("");
  const StatusNameRefpop = useRef("");
  const WorkItemStatusIDRef = useRef(0);
  const WorkItemStatusIDRefpop = useRef(0);
  const displayOrderRef = useRef("");
  const displayOrderRefpop = useRef("");
  const RemarksRef = useRef("");
  const RemarksRefpop = useRef("");
  const isDeletedRef = useRef("");
  const isDeletedRefpop = useRef("");
  const ButtonTextRef = useRef("");
  const [displayOrderArry, setdisplayOrderArry] = useState([]);
  const [searchDivVisible, setsearchDivVisible] = useState(true);
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [numberOfRecord, setnumberOfRecord] = useState(100);
  const [SaveDisabled, setSaveDisabled] = useState(true);
  const [col,setColumns] = useState([]);
  const testRef = useRef(null);
  const [workitemFields, setWorkitemFields] = useState([]);
  const [UserConfigList, setUserConfigList] = useState([]);
  const [filtercriteria, setfiltercriteria] = useState('All');
  const userRef = useRef(null);
  const userGroupRef = useRef(null);
  const [saveuserGroupCheck, setsaveuserGroupCheck] = useState("");
  const [updateBulkFileCheck , setupdateBulkFileCheck ] = useState("");
  
  const [ SubProcessID , setSubProcessID ] = useState(sessionStorage.getItem("ProcessId"))
  const [ clientID , setclientID ] = useState("")

  const [ columnNumberforOrder, setcolumnNumberforOrder] = useState(1);


  const handleselectchange = (event) => {
    const value = event.target.value;
    setTableColumns([]);
    setfiltercriteria(value);
    sessionStorage.setItem('displayCriteria',value);
    GetBulkUpdateFileConfigList();
    
  };
 
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });

  const pop = () => {
    setopenPopUp(true);
    seterrorMessage("");
    setBulkFileNameInput("");
    setsaveuserGroupCheck("");
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    setSubProcessID(sessionStorage.getItem("ProcessId"))
    AddGetBulkUpdateFileConfigSPFieldDetails();
    FetchDepartmentbyGUID();
  };
  const popupClose = () => {
    setopenPopUp(false);
    seterrorMessage("");
    setclientID("");
    setBulkUpdateSPFields([]);
    GetBulkUpdateFileConfigList();
    setareAllcheckboxesEnabled(false);
    
  };
  const EditpopupClose = () => {
    setEditPopUp(false);
    seterrorMessage("");
    setBulkUpdateSPFields([]);
    GetBulkUpdateFileConfigList();
    seteditBulkFileNameInput("");
    setupdateBulkFileCheck("");
    setBulkUpdateDetails([]);
  };
  

  const resetForm = () => {
    StatusNameRef.current.value = "";
    displayOrderRef.current.value = "Select";
    RemarksRef.current.value = "";
    isDeletedRef.current.checked = false;
    seterrorMessage("");
  };



  const downloadExcel = () => {
    const exportType = "xlsx";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const filteredColumn = tableColumns.map(item => ({
      
        "Client Name": item.clientName,
        "Process Name": item.processName,
        "SubProcess Name": item.subProcessName,
        "Update File Name": item.bulkUpdateFileName
    }));
 
      const fileName = "ListofSubProcessVendorPortalDetails";
      const ws = XLSX.utils.json_to_sheet(filteredColumn);
      const wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
  };
 
  const [IsFile, setIsFile] = useState(false);
 
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

   
 
  const FetchLocationClientsByGuid = (e) => {
    seterrorlocationclient("Select");
    seterrorprocesssubprocess("Select")

  setErrorCompanyDepartment(e.target.value);
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setShowModalButton(false);
      setClientLocations([]);
      setIsDepartments(false);
      setIsClientLocations(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
      setBulkUpdateSPFields([]);
    }
    if (e.target.value !== "Select") {
      setBulkUpdateSPFields([]);
      setIsDepartments(true);
      sessionStorage.setItem(
        "selectedDepartment",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedDepartmentName",
        e.target.value.split("/")[0]
      );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };
 
      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };
 
  const FetchProcessSubProcessByGuid = (e) => {
    seterrorlocationclient(e.target.value)
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setPriortyForSubProcessDetails([]);
      setPriortyForSubProcessDetails([]);
      setShowModalButton(false);
      setIsprocessSubprocess(false);
      setIsActivitiesForSubprocess(false);
      setBulkUpdateDetails([]);
      setIsClientLocations(false);
    } else {
      if (e.target.value !== "Select") {
        setSelectedLocation(e.target.value);
        setIsClientLocations(true);
        setBulkUpdateSPFields([]);
        let result = "";
        let locationArray = e.target.value.split("/");
        for (let i = 0; i < locationArray.length - 1; i++) {
          result = result.concat(locationArray[i]);
          if (i < locationArray.length - 2) {
            result = result.concat("/");
          }
        }
        sessionStorage.setItem(
          "selectedLocation",
          e.target.value.split("/")[e.target.value.split("/").length - 1]
        );
        sessionStorage.setItem("selectedLocationName", result);
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: locationArray[locationArray.length - 1],
        };
 
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              
              setprocessSubprocess(result.data);
            } else if (result.status === 401) {
              //unauthorized
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
              setprocessSubprocess([]);
            } else {
              setprocessSubprocess([]);
            }
          })
          .catch((error) => {
            //if (result.status === 401) {
            console.log("ERROR: ", error);
            //   navigate("/");
            // }
          });
      }
    }
  };

  
 
    const saveConfiguration  = async () => {
      try {
        await
      GetWorkItemTypeRequiredForSubprocess();
      
      const shouldProceed = await GETExistingBulkUpdateFileConfig();
      if (!shouldProceed) {
        return;
      }
      await
      AddEditBulkUpdateFileConfig();
      }
      catch (error) {
        console.log("Error" , error);
      }
      
    } ;

    const GetWorkItemTypeRequiredForSubprocess = async () => {
      try {
      let body = {
        subProcessID: errorprocesssubprocess,
      }
      let activityUrl = getAPIURL(REACT_APP_GetWorkitemTypeRequiredForSubprocess);
      let req = new Request(`${activityUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      let res = await fetch(req);
      let result = await res.json();
      // fetch(req)
      //   .then((res) => res.json())
      //   .then((result) => {
          
          if (result.status == 200) {
            setWorkItemTypeRequired(
              result.data
            );
          }
        
      
      }
         catch (error) {
          console.log("ERROR: ", error);
        };
    }

    const GETExistingBulkUpdateFileConfig  = async () => {
      
      try {
      let body = {
        longSubProcessID : errorprocesssubprocess,
        fileConfigName : BulkFileNameInput,
      }
      let activityUrl = getAPIURL(REACT_APP_GETExistingBulkUpdateFileConfig);
      let req = new Request(`${activityUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      let res = await fetch(req);
      let result = await res.json();
          
          if (result.status == 200) {
            if (result.data[0].result[0] == 'T') {
             const confirmation = window.confirm(`Configuration already exists with name ${BulkFileNameInput}. Do you want to Delete and Create New Configuration?`);
              if (!confirmation) {
                return false;
              }          
            }
            setexistingBulkDetails(
              result.data[0].result.split(';')
            );
          }
          return true;
        
      } catch(error) {
          console.log("ERROR: ", error);
          return false;
        };

    }

    const userDataXML = (array) => {
      let xmlString = "<UploadExcelColumns>";
      const filteredColumn = array.filter(item => item.isSelected );

      if (filteredColumn.length === 0) {
        return "no data"
      }
      const columnNumbers = filteredColumn.map(item => item.columnNo);
      const uniqueColumnNumbers = new Set(columnNumbers);
      if (uniqueColumnNumbers.size !== columnNumbers.length) {
        return "same"
      }
      filteredColumn.forEach((item) => {
          xmlString += `<UploadExcelColumn><UploadExcelColumnID></UploadExcelColumnID><ColumnNo>${item.columnNo}</ColumnNo><WorkItemFieldID>${item.workItemFieldID}</WorkItemFieldID><AllowBlank>${item.allowBlank}</AllowBlank><IsPartOfUniqueKey>${item.isPartOfUniqueKey}</IsPartOfUniqueKey><UpdatedBy>${sessionStorage.getItem("PersonID")}</UpdatedBy>
  
  </UploadExcelColumn>`;
      })
      xmlString += "</UploadExcelColumns>";
      return xmlString;
    }
  
    const AddEditBulkUpdateFileConfig = async () => {
      
      try{
      let error = {};
    let save = true;
    if (companyDepartmentRef.current.value == "Select"){
      error.ErrorCompanyDepartment="Company Department is required."
      save = false;
    } 
    if (LocationClientRef.current.value == "Select"){
      error.errorlocationclient="Location Client is required."
      save = false;
    }  
    if (processSubprocessRef.current.value=="Select"){
      error.errorprocesssubprocess = "Process Sub-Process is required."
      save = false
    }
    if (BulkFileNameInput === ""){
      error.BulkFileNameInput="Group Name is required."
      save = false;
    }
    setsaveuserGroupCheck({...error});
    if (save) {
      let uploadExcelColumn = userDataXML(BulkUpdateSPFields);

      if (uploadExcelColumn === "same") {
        alert("Same column number cannot be set for more than one selected workitem field.");
        return;
      }

      if (uploadExcelColumn === "no data") {
        const confirmation = window.confirm("You have not configured any OnBase Index Field(s). Are you sure you want to save?");
        if (!confirmation) {
          return;
        }
      }
      
      let intoverwrite = existingBulkDetails[1] !== 0 ? existingBulkDetails[1] : 0;
      let body = {
        longSubProcessID : sessionStorage.getItem("ProcessId"),
        workItemTypeRequired : WorkItemTypeRequired.workItemTypeRequired,
        workItemTypeColumnNo : WorkItemTypeRequired.workItemTypeColumnNo,
        strUpdateFileName : BulkFileNameInput,
        intOverWrite : intoverwrite,
        intUpdateFileID : 0,
        uploadExcelColumn : userDataXML(BulkUpdateSPFields),

      }
      let activityUrl = getAPIURL(REACT_APP_AddEditBulkUpdateFileConfig);
      let req = new Request(`${activityUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
        let res = await fetch (req);
        let result = await res.json();
          
          if (result.status == 200) {
            popupClose();
            addToast("Sub-Process Bulk Update File Configuration saved successfully.", {
              appearance: "success",
              autoDismiss: true,
            });
          }
        
      }}
      catch (error) {
          console.log("ERROR: ", error);
        } ;
    }

    const UpdateConfiguration = () => {
      GetWorkItemTypeRequiredForSubprocess();
      UpdateBulkUpdateFileConfig();
      editGetBulkUpdateFileConfigSPFieldDetails();
    }
    const UpdateBulkUpdateFileConfig = () => {
      
      let error = {};
      let update = {};
      let uploadExcelColumn = userDataXML(BulkUpdateSPFields);

      if (uploadExcelColumn === "no data") {
        const confirmation = window.confirm("You have not configured any OnBase Index Field(s). Are you sure you want to save?");
        if (!confirmation) {
          return;
        }
        
      }

      if (editBulkFileNameInput === ""){
        error.editBulkFileNameInput="Group Name is required."
        update = false;
      }
      setupdateBulkFileCheck({...error});
      if (update){
      if (uploadExcelColumn === "same") {
        alert("Same column number cannot be set for more than one selected workitem field.");
        return;
      }
      let body = {
        longSubProcessID : sessionStorage.getItem("ProcessId"),
        workItemTypeRequired : WorkItemTypeRequired[0].workItemTypeRequired,
        workItemTypeColumnNo : WorkItemTypeRequired[0].workItemTypeColumnNo,
        strUpdateFileName : editBulkFileNameInput,
        intOverWrite : 0,
        intUpdateFileID : bulkIDinEdit,
        uploadExcelColumn : userDataXML(BulkUpdateSPFields),

      }
      let activityUrl = getAPIURL(REACT_APP_AddEditBulkUpdateFileConfig);
      let req = new Request(`${activityUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          
          if (result.status == 200) {
            alert("Sub-Process Bulk Update File Configuration saved successfully.") 
            // {
            //   appearance: "success",
            //   autoDismiss: true,
            // });
          }

        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }}

    
    const EditConfiguration =(row) => {
      setprocessSubProcessID(row.subProcessID);
      setEditPopUp(true);
      GetBulkUpdateDetailsByFileID(row);
      GetBulkUpdateFileConfigSPFieldDetails(row);
    }

    const AddConfiguration = () => {
      AddGetBulkUpdateFileConfigSPFieldDetails();
    }
    const deptID = useRef(null)
    const cliID = useRef(null)
    const pSRef = useRef(null);
        const GetBulkUpdateDetailsByFileID = (row) => {
          

        pSRef.current = row.subProcessID

          setbulkIDinEdit(row.bulkUpdateFileID)
          setprocessSubProcessID(row.subProcessID);
          pSRef.current = row.subProcessID;
          let body = {
            intFileID : row.bulkUpdateFileID,
          }
          let activityUrl = getAPIURL(REACT_APP_GetBulkUpdateDetailByFileID);
          let req = new Request(`${activityUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              
              if (result.status == 200) {
                setBulkUpdateDetails(
                  result.data
                );
                deptID.current=result.data[0].departmentID;
                FetchLocationClientsByGuidOnPageLoad(deptID.current);
                cliID.current = result.data[0].clientID;
                FetchProcessSubProcessByGuidOnPageLoad(cliID.current);
              }
            })
            .catch((error) => {
              console.log("ERROR: ", error);
            });
        }

        const AddGetBulkUpdateFileConfigSPFieldDetails = () => {
            
            let body = {
              longSubProcessID : SubProcessID,
              intFileTypeID : 0,
      
            }
            let activityUrl = getAPIURL(REACT_APP_GetBulkUpdateFileConfigSPFieldDetails);
            let req = new Request(`${activityUrl}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
              },
              body: JSON.stringify(body),
            });
            fetch(req)
              .then((res) => res.json())
              .then((result) => {
                
                if (result.status == 200) {
                  setBulkUpdateSPFields(
                    result.data
                  );
                }
              })
              .catch((error) => {
                console.log("ERROR: ", error);
              });
          }

          const editGetBulkUpdateFileConfigSPFieldDetails = () => {
            
            let body = {
              longSubProcessID : Number(BulkUpdateDetails[0].subProcessID),
              intFileTypeID : bulkIDinEdit,
      
            }
            let activityUrl = getAPIURL(REACT_APP_GetBulkUpdateFileConfigSPFieldDetails);
            let req = new Request(`${activityUrl}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
              },
              body: JSON.stringify(body),
            });
            fetch(req)
              .then((res) => res.json())
              .then((result) => {
                
                if (result.status == 200) {
                  seteditBulkUpdateSPFields(
                    result.data
                  );
                }
              })
              .catch((error) => {
                console.log("ERROR: ", error);
              });
          }
        
        const GetBulkUpdateFileConfigSPFieldDetails = (row) => {
          
          let body = {
            longSubProcessID : row.subProcessID,
            intFileTypeID : row.bulkUpdateFileID,
    
          }
          let activityUrl = getAPIURL(REACT_APP_GetBulkUpdateFileConfigSPFieldDetails);
          let req = new Request(`${activityUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              
              if (result.status == 200) {
                setBulkUpdateSPFields(
                  result.data
                );
              }
            })
            .catch((error) => {
              console.log("ERROR: ", error);
            });
        }
      const GetBulkUpdateFileConfigList = () => {
        
        if (sessionStorage.getItem("ProcessId") != null && sessionStorage.getItem("displayCriteria") == null) {
            sessionStorage.setItem('displayCriteria',"subprocess")
        }
        let stringFilterKey = sessionStorage.getItem("displayCriteria");
        let longFilterValue = sessionStorage.getItem("ProcessId");
        if (stringFilterKey === "subprocess") {
        }
        else if (stringFilterKey === "process") {
          longFilterValue = longFilterValue.slice(0,-4) + "0000";
        }
        else if (stringFilterKey === "client") {
          longFilterValue = longFilterValue.slice(0,-8) + "00000000";
        }
        let body = {
          UserID: sessionStorage.getItem("GUID"),
          stringFilterKey: stringFilterKey,
          longFilterValue: longFilterValue
        }

        let activityUrl = getAPIURL(REACT_APP_GetSubProcessBulkUpdateFileConfigList);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              
              setTableColumns(
                result.data
              );
            }
            else if (result.status == 404) {
              setTableColumns([]);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };

      const DeleteConfiguration = (row) => {
        
        const confirmation = window.confirm("Are you sure you want to delete the Sub-Process Bulk Update File Config?")
        if (confirmation === true) {
        let body = {
          intConfigID : row.bulkUpdateFileID,
          longSubProcessID: row.subProcessID,
        }
        let activityUrl = getAPIURL(REACT_APP_DeleteSubProcessBulkUpdateConfig);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            
            if (result.status == 200) {
              addToast("User Group Configuration Deleted Successfully.", {
                appearance: "success",
                autoDismiss: true,
              })
            }
            GetBulkUpdateFileConfigList();
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      }
    };
 
      
      useEffect(() => {
        sessionStorage.setItem('displayCriteria',"subprocess")
        FetchDefaultValueForSelectedSubprocess(
          sessionStorage.getItem("selectedDepartment"),
          sessionStorage.getItem("selectedLocation"),
          sessionStorage.getItem("ProcessId")
        );
        FetchDepartmentbyGUID();

        GetBulkUpdateFileConfigList();
        
      }, []);


      
 
      const FetchDepartmentbyGUID = () => {
        let body = {
          memberShipGuid: sessionStorage.getItem("GUID"),
        };
   
        let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setDepartments(result.data);
              
            } else if (result.status === 401) {
              
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);

          });
      };
 
      const FetchDefaultValueForSelectedSubprocess = (
        companyDeparmentID,
        locationClientID,
        processSubProcessID
      ) => {
        
        setcompanyDeparmentID(JSON.parse(sessionStorage.selectedEntitiesDetail).departmentID);
        setSelectedDepartment(JSON.parse(sessionStorage.selectedEntitiesDetail).departmentID);
        seterrorlocationclient(JSON.parse(sessionStorage.selectedEntitiesDetail).clientID)
        sessionStorage.setItem("selectedDepartment", companyDeparmentID);
        FetchLocationClientsByGuidOnPageLoad(JSON.parse(sessionStorage.selectedEntitiesDetail).departmentID);
        setlocationClientID(JSON.parse(sessionStorage.selectedEntitiesDetail).clientID);
        setSelectedLocation(JSON.parse(sessionStorage.selectedEntitiesDetail).clientID);
        seterrorprocesssubprocess(JSON.parse(sessionStorage.selectedEntitiesDetail).subProcessID);
        sessionStorage.setItem("selectedLocation", locationClientID);
        FetchProcessSubProcessByGuidOnPageLoad(JSON.parse(sessionStorage.selectedEntitiesDetail).clientID);
        setprocessSubProcessID(JSON.parse(sessionStorage.selectedEntitiesDetail).subProcessID);
        sessionStorage.getItem("ProcessId",processSubProcessID);
        FetchSubprocessName(JSON.parse(sessionStorage.selectedEntitiesDetail).subProcessID
          , JSON.parse(sessionStorage.selectedEntitiesDetail).clientID);
      };
 
      const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
        let body = {
          guid: sessionStorage.getItem("GUID"),
          departmentId: departmentId,
        };
   
        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setClientLocations(result.data);
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: "",
          clientId: clientId,
        };
   
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setprocessSubprocess(result.data);
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
 
      const FetchSubprocessName = (subprocessId, clientId) => {

        let body = {
          guid: sessionStorage.getItem("GUID"),
          subProcessId: subprocessId,
          clientId: clientId,
        };
        let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              sessionStorage.setItem(
                "SelctedSubprocessName",
                result.data[0].processSubProcess
              );
            } else if (result.status === 401) {
              addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                appearance: "error",
                autoDismiss: true,
              });
              sessionStorage.clear();
              sessionStorage.clear();
              navigate("/");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
 

var rows = [], i = 1, len = 200;
while (i <= len)
{
  rows.push(i);
  i++;
}

  const numbers = Array.from({length : 30 }, (v, k) => k +1);

const HandleDisplayOrder = (e, rowData) => {
const newValue = e.target.value;
  setBulkUpdateSPFields(prevList =>
    prevList.map(item => {
      if (item.workItemFieldID === rowData.workItemFieldID) {
        return { ...item, columnNo: newValue };
      }
      return item;
    })
  );

};

const handleCheckboxChange = (itemId) => {
  setBulkUpdateSPFields(prevList =>
    prevList.map(item => {
      if (item.workItemFieldID === itemId) {
        const isSelected = !item.isSelected;
        return { ...item, isSelected, allowBlank: isSelected? item.allowBlank : false , isPartOfUniqueKey: isSelected? item.isPartOfUniqueKey :false, isSelected: !item.isSelected };
      }
      return item;
    })
  );
  setareAllcheckboxesEnabled(true);
};
const handleAllowBlankChange = (itemId) => {
  setBulkUpdateSPFields(prevList =>
    prevList.map(item => {
      if (item.workItemFieldID === itemId) {
        return { ...item, allowBlank: !item.allowBlank, isPartOfUniqueKey: !item.allowBlank ? item.isPartOfUniqueKey : false };
      }
      return item;
    })
  );
};
const handleKeyChange = (itemId) => {
  setBulkUpdateSPFields(prevList =>
    prevList.map(item => {
      if (item.workItemFieldID === itemId) {
        return { ...item, isPartOfUniqueKey : !item.isPartOfUniqueKey, allowBlank: !item.isPartOfUniqueKey ? item.allowBlank : false };
      }
      return item;
    })
  );
};



      const SPcolumns =[
        
          {
              title: "Select",
              field: "isSelected",
            editComponent: (props) => {
                // console.log(props);
                return (
                    <input
                        type="checkbox"
                        checked={props.value}
                        onChange={ (e) => {
                            props.onChange(e.target.checked);
                            handleCheckboxChange(props.rowData.workItemFieldID);
                        }}
                    />
                );
            },
            
            render: (rowData) => (
                <>
                    <input
                        type="checkbox"
                        id=""
                        checked={rowData.isSelected}
                        onChange={() => handleCheckboxChange(rowData.workItemFieldID)}
                    />
                </>
            ),
            },
          
          {
            title: "Workitem Field",
            field: "labelText",
            render: (rowData) => (
             
              <>
              { rowData.labelText}
                  </>
            ),
          },
          {
            title: "Column Number",
            field: "columnNo",
            render: rowData => (
              <>
                <select
                value={rowData.columnNo}
                  id="displayOrder"
                  className="form-control form-control-sm"
                  onChange={e => HandleDisplayOrder(e, rowData)}
                >
                  {numbers.map(state => (
                    <option value={state} 
                    key={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </>
            )
          },

          {
            title: "Allow Blank",
            field: "allowBlank",
          editComponent: (props) => {
              // console.log(props);
              return (
                  <input
                      type="checkbox"
                      checked={props.value}
                      onChange={ (e) => {
                          props.onChange(e.target.checked);
                          handleAllowBlankChange(props.rowData.workItemFieldID);
                      }}
                      disabled={!areAllcheckboxesEnabled || props.rowData.isPartOfUniqueKey}
                  />
              );
          },
          
          render: (rowData) => (
              <>
                  <input
                      type="checkbox"
                      id=""
                      checked={rowData.allowBlank}
                      onChange={() => handleAllowBlankChange(rowData.workItemFieldID)}
                      disabled={!areAllcheckboxesEnabled || rowData.isPartOfUniqueKey}
                  />
              </>
          ),
          },
          {
            title: "Key",
            field: "isPartOfUniqueKey",
          editComponent: (props) => {
              // console.log(props);
              return (
                  <input
                      type="checkbox"
                      checked={props.value}
                      onChange={ (e) => {
                          props.onChange(e.target.checked);
                          handleKeyChange(props.rowData.workItemFieldID);
                      }}
                      disabled={!areAllcheckboxesEnabled || props.rowData.allowBlank}
                  />
              );
          },
          
          render: (rowData) => (
              <>
                  <input
                      type="checkbox"
                      id=""
                      checked={rowData.isPartOfUniqueKey}
                      onChange={() => handleKeyChange(rowData.workItemFieldID)}
                      disabled={!areAllcheckboxesEnabled ||rowData.allowBlank}
                  />
              </>
          ),
          },       
        ];
      
    

      const columns = [

       
        {
          title: "Client Name",
          field: "clientName",
        },
        {
          title: "Process Name",
          field: "processName",
       
        },
        {
          title: "SubProcess Name",
          field: "subProcessName",
         
        },
        {
          title: "Update File Name",
          field: "bulkUpdateFileName",
         
        },
        
       
      ];
      useEffect (()=>{
        AddGetBulkUpdateFileConfigSPFieldDetails()
      },[SubProcessID]);


      const subprocesOnchange = (e) => {
        seterrorprocesssubprocess(e.target.value)
        setSubProcessID(e.target.value);
        if(e.target.value != "Select"){
        setTableColumns([]);
        setSaveDisabled(true);
        setColumns([]);
        setBulkUpdateSPFields([]);
        }
        if(e.target.value == "Select"){
          setBulkUpdateSPFields([]);
        }
      };
   
 
      const GetAddEditExcelColumns = () => {
        let body = {
          subprocessId: sessionStorage.getItem("ProcessId"),
        };
        let activityUrl = getAPIURL(REACT_APP_UPLOADEXCELCOLUMNS);
        let req = new Request(`${activityUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            UserName: sessionStorage.getItem("UserName"),
            Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
          },
          body: JSON.stringify(body),
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.status == 200) {
              setExcelColumns(
                result.data
              );
             
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
          });
      };
      useEffect(() => {
        GetAddEditExcelColumns();
      }, []);
 
      const NavElements = useMemo(() => {
        return (
          <>
            <Header onpreferenceShow={null} pageName="Sub-Process Bulk Update File Config List" />
            <ApplicationUserContext.Provider
              value={processingDashboardParam.userRoles}
            >
              <DashboardContext.Provider value={processingDashboardParam.nav}>
                <NavDataContext.Provider value={navData}>
                  <Nav selectedMenu="Configuration" />
                </NavDataContext.Provider>
              </DashboardContext.Provider>
            </ApplicationUserContext.Provider>
          </>
        );
      }, [navData]);
 
      useEffect(() => {
        
        if (BulkUpdateDetails && BulkUpdateDetails.length>0 ) {
          if (BulkUpdateDetails[0].bulkUpdateFileName) {
            seteditBulkFileNameInput(BulkUpdateDetails[0].bulkUpdateFileName)}}
      }, [BulkUpdateDetails]) ;
      


    return (
      <>
      {NavElements}
      <div className="centerSection">
        <div className="pageBody">
        
          <div className="dashBoxs mb-3">
          
            <div className="formWrap">
            <div className="mb-3">
            <label htmlFor="mb-3"><b>Display Criterion</b></label><br></br>
            <select id="mb-3" defaultValue={filtercriteria} onChange={handleselectchange}>
              <option value="subprocess">Sub-Process</option>
              <option value="process">Process</option>
              <option value="client">Client</option>
              <option value="all">All</option>
            </select>
          </div>
          
              <div className="row align-items-end">
              
              </div>
            </div>
          </div>
          <table
              className="table table-borderless MatTable"
              style={{ zIndex: "1", position: "relative" }}
          >
            <ThemeProvider theme={theme}>
                <MaterialTable
                  localization={{
                    pagination: {
                      labelRowsPerPage: "{50, 100, 250,500}",
                    },
                    toolbar: {
                      exportCSVName: "Export as Excel",
                      exportPDFName: "Export as pdf",
                    },
                  }}
                  actions={[
                    {
                      icon: () => <EditIcon />,
                      tooltip: "Click here to Edit Sub-Process Bulk Update File Configuration.",
                      onClick: (event, rowData) => 
                        EditConfiguration(rowData),
                      position: "row"
                    },
                    {
                      icon: () => <DeleteIcon />,
                      tooltip: "Click here to Delete Sub-Process Bulk Update File Configuration.",
                      onClick: (event, rowData) =>
                        DeleteConfiguration(rowData),
                      position: "row",
                    },
                  ]}
                  options={{
                    paging: false,
                    search: false,
                    overflowY: false,
                    showTitle: false,
                    filtering: true,
                    maxBodyHeight: '350px',
                    cellStyle: {
                    },
                    headerStyle: {
                      position: "sticky",
                    },
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                    },
                    selection:false,
                  }}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                          marginBottom:0,
                          paddingBottom:0,
                        }}
                      >
                        <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                        <Typography variant="body2" style={{padding:'0 16px'}}>
                          <b>
                            {props.data.length > 0 ? `1 - ${props.data.length} out of ${props.data.length} records displayed` : '0 out of 0 records displayed.'}</b>
                          {/* 1 - {props.data.length} out of {props.data.length} records displayed  */}
                        </Typography>
                      </div>
                    ),
                  }}
                  columns={columns}
                  icons={tableIcons}
                  data={tableColumns}
                />
              </ThemeProvider>
            </table>
            <div className="col-3">
                <div className="mb-3">
             
                  
                </div>
              </div>
              <div className="col-3">
                <div class="buttons d-flex">
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-1"
                      onClick={() => {
                        pop();
                      }}
                      
                    >
                     Add New Bulk Update File Configuration
                   
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-1"
                      onClick={() => {
                        downloadExcel();
                      }}
                    >
                     Export to Excel
                   
                    </button>
                </div>
             
              </div>
        </div>
      </div>
 
    <Footer />  


    <Modal
        show={openPopUp}
        dialogClassName="modal-100w"
        onHide={popupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Sub-Process Bulk Update File Config</Modal.Title>
          
        </Modal.Header>

        <Modal.Body>
        
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Company-Department</b><span style={{color:"red"}}> *</span></label>
                    <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.ErrorCompanyDepartment}</span>
                    </div>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchLocationClientsByGuid}
                      title="Select a Company-Department."
                      ref = {companyDepartmentRef}
                    >
                      <option>Select</option>
                      {departments.map((department) => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.departmentIDString == companyDeparmentID
                          }
                        >
                          {department.companyDepartment}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Location-Client</b><span style={{color:"red"}}> *</span></label>
                    <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.errorlocationclient}</span>
                    </div>
                    <select
                      className="form-control form-control-sm"
                      title="Select a Location-Client."
                      onChange={FetchProcessSubProcessByGuid}
                      ref={LocationClientRef}
                    >
                      <option>Select</option>
                      {clientLocations.map((location) => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.clientIDString == locationClientID}
                        >
                          {location.locationClient}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Process-SubProcess</b><span style={{color:"red"}}> *</span></label>
                    <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.errorprocesssubprocess}</span>
                    </div>
                    <select
                      className="form-control form-control-sm"
                      onChange={(e) => subprocesOnchange(e)}
                      title="Select a Process-SubProcess."
                      ref={processSubprocessRef}
                    >
                      <option>Select</option>
                      {processSubprocess.map((subprocess) => (
                        <option
                          value={
                            subprocess.subProcessIDString 
                          }
                          selected={
                            subprocess.subProcessIDString == processSubProcessID
                          }
                        >
                          {subprocess.processSubProcess}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              
                <div className="row align-items-end">
                

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>Bulk Update File Config Name</b><span
                         style={{color:"red"}}> *</span></label>
                          <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.BulkFileNameInput}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          title="Provide Bulk Update File Config Name"
                         maxLength = "100"
                         height={200}
                         value={BulkFileNameInput}
                         onChange={(e)=>setBulkFileNameInput(e.target.value)}
                         
                        />   
                  </div>
                </div>
                <table
              className="table table-borderless MatTable"
              style={{ zIndex: "1", position: "relative" }}
          >
            <ThemeProvider theme={theme}>
              {/* <div> */}
                <Typography variant="h6" style={{margin: 0}}>
                Set entries for the Selected Sub-Process
                </Typography>
                <MaterialTable
                
                  localization={{
                    pagination: {
                      labelRowsPerPage: "{50, 100, 250,500}",
                    },
                    toolbar: {
                      exportCSVName: "Export as Excel",
                      exportPDFName: "Export as pdf",
                    },
                  }}
                  options={{
                    search: false,
                    paging: false,
                    overflowY: false,
                    showTitle: false,
                    filtering: false,
                    maxBodyHeight: '300px',
                    cellStyle: {
                    },
                    headerStyle: {
                      position: "sticky",
                    },
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                    },
                    selection:false,
                  }}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                      </div>
                    ),
                  }}
                  columns={SPcolumns}
                  icons={tableIcons}
                  data={BulkUpdateSPFields}
                />
                {/* </div> */}
              </ThemeProvider>
          </table>
                

                
       </div>
      </div>

            <div className="fieldSet pt-1">
                <div className="fieldSet pt-1">
                  <button type="button" 
                  class="btn btn-sm btn-primary btn-link" 
                  onClick={saveConfiguration}
                  title="Click here to Save/Update the Sub-Process Bulk Update File Configuration."
                  ><b>Save</b></button>
                
                  
                  
                  </div>
                </div>  
            </div>
          </div>
        </div>
      


            
            
          </Modal.Body>
        
        
        
        </Modal>   

        <Modal
        show={EditPopUp}
        dialogClassName="modal-100w"
        onHide={EditpopupClose}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Sub-Process Bulk Update File Config</Modal.Title>
          
        </Modal.Header>

        <Modal.Body>
        
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Company-Department</b><span style={{color:"red"}}> *</span></label>
                    <select
                      className="form-control form-control-sm"
                      disabled
                      title="Select a Company-Department."
                    >
                      <option>Select</option>
                      {departments.map((department) => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.departmentIDString == deptID.current
                          }
                        >
                          {department.companyDepartment}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Location-Client</b><span style={{color:"red"}}> *</span></label>
                    {/* <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.locationID}</span>
                    </div> */}
                    <select
                      className="form-control form-control-sm"
                      disabled
                      title="Select a Location-Client."
                    >
                      <option>Select</option>
                      {clientLocations.map((location) => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.clientIDString == cliID.current}
                        >
                          {location.locationClient}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"><b>Process-SubProcess</b><span style={{color:"red"}}> *</span></label>
                    <div>
                      <span style = {{color:'red'}}>{saveuserGroupCheck.selectedSubprocess}</span>
                    </div>
                    {/* <textarea readonly="readonly" name="Text1" cols="40" rows="2" maxLength="4000" ref = {pSRef} disabled></textarea> */}
                    <select
                      className="form-control form-control-sm"
                      disabled
                      title="Select a Process-SubProcess."
                    >
                      <option>Select</option>
                      {processSubprocess.map((subprocess) => (
                        <option
                          value={
                            subprocess.subProcessIDString 
                          }
                          selected={
                            subprocess.subProcessIDString == pSRef.current
                          }
                        >
                          {subprocess.processSubProcess}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              
                <div className="row align-items-end">
                

                <div className="col-4">
                  <div className="mb-3">
                        <label className="form-label"><b>Bulk Update File Config Name</b><span
                         style={{color:"red"}}> *</span></label>
                          <div>
                      <span style = {{color:'red'}}>{updateBulkFileCheck.editBulkFileNameInput}</span>
                    </div>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                         maxLength = "100"
                         height={200}
                         value={editBulkFileNameInput}
                         onChange={(e)=>seteditBulkFileNameInput(e.target.value)}
                         disabled
                         title="Provide Bulk Update File Config Name"
                        />   
                  </div>
                </div>
                <table
              className="table table-borderless MatTable"
              style={{ zIndex: "1", position: "relative" }}
          >
            <ThemeProvider theme={theme}>
            <Typography variant="h6" style={{margin: 0}}>
                Set entries for the Selected Sub-Process
                </Typography>
                <MaterialTable
                
                  localization={{
                    pagination: {
                      labelRowsPerPage: "{50, 100, 250,500}",
                    },
                    toolbar: {
                      exportCSVName: "Export as Excel",
                      exportPDFName: "Export as pdf",
                    },
                  }}
                  
                  options={{
                    search: false,
                    paging: false,
                    overflowY: false,
                    showTitle: false,
                    filtering: false,
                    maxBodyHeight:'300px',
                    cellStyle: {
                    },
                    headerStyle: {
                      position: "sticky",
                    },
                    rowStyle: {
                      fontFamily: '"Yantramanav", sans-serif',
                    },
                    selection:false,
                  }}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar {...props} style={{ fontSize: "5px" }} />
                      </div>
                    ),
                  }}
                  columns={SPcolumns}
                  icons={tableIcons}
                  data={BulkUpdateSPFields}
                />
              </ThemeProvider>
          </table>
                

                
       </div>
      </div>

            <div className="fieldSet pt-1">
                <div className="fieldSet pt-1">
                  <button type="button" 
                  class="btn btn-sm btn-primary btn-link" 
                  onClick={UpdateConfiguration}
                  title="Click here to Save/Update the Sub-Process Bulk Update File Configuration."
                  ><b>Save</b></button>
                
                  
                  
                  </div>
                </div>  
            </div>
          </div>
        </div>
      


            
            
          </Modal.Body>
        </Modal>   
      </>
    );
    
};
  export default SubProcessBulkUpdateFileConfig;
import React, { useState, useEffect, useMemo, useRef } from "react";

import Header from "../../../../../components/header/Header";
import Footer from "../../../../../components/footer/Footer";
import Nav from "../../../../../components/nav/Nav";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import ApplicationUserContext from "../../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../../contexts/NavDataContext";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import tableIcons from "../../../../../MaterialTableIcons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashboardContext from "../../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { FcViewDetails } from "react-icons/fc";
import { FcFinePrint } from "react-icons/fc";
import { FcDeleteColumn } from "react-icons/fc";
import PendingActionsIcon from "@mui/icons-material/";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
import LoadingSpinner from "../../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../../LoadingSpinnerFigma";
import Iframe from "react-iframe";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";

import user01 from "../../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../../assets/images/icons/icon_empty.svg";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
//import sessionStorage from "redux-persist/es/storage/session";
//import sessionStorage from "redux-persist/es/storage/session";

const StatusReason = () => {
  const clientNameRef = useRef(null);
  const processNameRef = useRef(0);  
  const subProcessNameRef = useRef(null);
  const companyRef = useRef(null);
  const processSubprocessref = useRef(null);
  const departmentRef = useRef(null);
  const locationRef = useRef("");
  const companyDepartmentNameRef = useRef();
  const locationClientNameRef = useRef();
  const errorCategoryNameRef = useRef(null);
  const statusReasonRef = useRef();
  const errorCategoryIdRef = useRef(0);  
  const displayOrderRef = useRef(null);
  const RemarksRef = useRef(null);
  const setworkItemStatusesData = useRef(null);
  const isDeletedRef = useRef(null);
  const ButtonTextRef = useRef("");
  const subProcessIDStringref = useRef("");
  const WorkItemStatusID = useRef(null);
  const Reasonsref = useRef(null);
  const Remarksref = useRef(null);
  const statusNameref = useRef(null);
  const isdeleted = useRef(null);
  const valueRef = useRef(null);
  const DisplayCriterion = useRef();

  const { addToast } = useToasts();
  const { REACT_APP_GETSTATUSESREASONS } = process.env;
  const { REACT_APP_GETSUBPROCESSSTATUSREASONSPOPPAGE } = process.env;
  const { REACT_APP_FILLSTATUSREASONDATAS} = process.env;
  const { REACT_APP_UPDATESTATUSREASONDATAS } = process.env;
  const { REACT_APP_CREATESTATUSREASONDATAS } = process.env;
  //const { REACT_APP_GET_STATUSESFORCURRENTWORKFLOWSTATE } = process.env;
  const { REACT_APP_DELETESTATUSREASONDATAS } = process.env;
  const { REACT_APP_DELETESTATUSREASONBYSUBPROCESSID } =process.env;
  const { REACT_APP_GETERRORCATEGORIES } = process.env;
  const { REACT_APP_UPDATEERRORCATEGORIES } = process.env;
  const { REACT_APP_DELETEERRORCATEGORIES } = process.env;
  const { REACT_APP_ADDERRORCATEGORIES } = process.env;
  const { REACT_APP_GETEMPLOYEEACTIVITYSUMMARY } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GETWORKFLOWSTATES } = process.env;
  const { REACT_APP_GETWORKFLOWSTATUS } = process.env;
  const { REACT_APP_GETWORKFLOWITEMFIELDS } = process.env;
  const { REACT_APP_GET_EMPLOYEESFORSUBPROCESSACTIVITY } = process.env;
  const { REACT_APP_FETCH_EMPLOYEESFORSUBPROCESS } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env; 
  const { REACT_APP_GET_STATUSESFORCURRENTWORKFLOWSTATE } = process.env; 
  const { REACT_APP_GETWORKITEMSTATUSES } = process.env; 
  const { REACT_APP_STATUSREASONBYSUBPROCESSID } = process.env;

  let interval = null;
  const location = useLocation();
  let navigate = useNavigate();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const [error, seterror]=useState("");
  const [clientName, setclientName] = useState("");
  const [processName, setprocessName] = useState("");
  const [subProcessName, setsubProcessName] = useState("");
  const [company, setcompany] = useState("");
  const[ department, setdepartment] = useState("");
  //const [location, setlocation] = useState("");
  const [workitemId, setworkitemId] = useState("");

  const [viewstatusReasonPopUp, setviewstatusReasonPopUp] = useState(false);
  const [editstatusReasonPopUp, seteditstatusReasonPopUp] = useState(false);
  const [StatusReasonBySubProcessID,setStatusReasonBySubProcessID] = useState([]);
  const [currentState, setcurrentState] = useState("");
  const [currentStateID, setcurrentStateID] = useState(0);
  const [currentStatus, setcurrentStatus] = useState("");
  const [subproseeworkflowConfigid, setsubproseeworkflowConfigid] =
    useState("");
  const [currentWith, setcurrentWith] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [imageUrlTemplate, setimageUrlTemplate] = useState("");
  const [uplodadedDateTimeFrom, setuplodadedDateTimeFrom] = useState("");
  const [uplodadedDateTimeTo, setuplodadedDateTimeTo] = useState("");
  const [scanDateTimeFrom, setscanDateTimeFrom] = useState("");
  const [scanDateTimeTo, setscanDateTimeTo] = useState("");
  const [activityDateTimeFrom, setactivityDateTimeFrom] = useState("");
  const [activityDateTimeTo, setactivityDateTimeTo] = useState("");
  const [checkArchive, setcheckArchive] = useState(false);
  const [showStatusReasonComment, setshowStatusReasonComment] = useState(false);
  const [showDropdown, setshowDropdown] = useState(false);
  const [prefenceValue, setprefenceValue] = useState(3);
  const [advSearchField, setadvSearchField] = useState("");
  const [advSearchMapToField, setadvSearchMapToField] = useState("");
  const [advCondition, setadvCondition] = useState("");
  const [advSearchText, setadvSearchText] = useState("");
  const [advSearchCondition, setadvSearchCondition] = useState("");
  const [searchWorkItemData, setsearchWorkItemData] = useState([]);
  const [statusReasonData, setstatusReasonData] = useState([]);
  const [statusReasonPopUpData, setstatusReasonPopUpData] = useState([]);
  const [editstatusReasonData, seteditstatusReasonData] = useState([]);
  const [Reasons, setReasons] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [Deleted, setDeleted] = useState("");
  const [SubProcessID, setSubProcessID] = useState("");
  const [statusReasonID ,setstatusReasonID] = useState();
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartmentName, setcompanyDepartmentName] = useState("");
  const [companyAndDepartment, setcompanyAndDepartment] = useState("");
  const [LocationAndClient,setLocationAndClient] = useState("");
  const [ProcessAndSubProcess,setProcessAndSubProcess] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [workItemStatus, setWorkItemStatus] = useState("");
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [Reason, setReason]=useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [SelectedClientLocation, setSelectedClientLocation] = useState("");
  const [ClientLocation, setClientLocation] = useState("");
  const [workItemData, setworkItemData] = useState([]);
  const [workItemStatusData, setworkItemStatusData] = useState([]);
  const [title,settitle] = useState([]);
//   const [columns, setcolumns] = useState([]);
  const [advSearchDetails, setadvSearchDetails] = useState([]);
  const [advSearchMapToFieldDetails, setadvSearchMapToFieldDetails] = useState(
    []
  );
  const [departments, setDepartments] = useState([]);
  //const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [WorKFlowStates, setWorkFlowStates] = useState([]);
  const [array,setArray] = useState([]);
  const [displayOrderArry,setdisplayOrderArry] =useState([]);
  const [CurrentStateID_Client, setCurrentStateID_Client] = useState("");
  const [WorkFlowWorkItemFields, setWorkFlowWorkItemFields] = useState([]);
  const [WorkFlowStatuses, setWorkFlowStatuses] = useState([]);
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [numberOfRecord, setnumberOfRecord] = useState(100);
  const [searchDivVisible, setsearchDivVisible] = useState(true);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [buttonText, setbuttonText] = useState("Save");
  const [isLoading,setisLoading] = useState(false);
  const [addNewStatusReason, setaddNewStatusReason] = useState(false);
  const [Datacount, setDatacount] = useState(0);
  //const Val = processingDashboardParam.selectedSubprocess;
  //let dropdown = false;
  const resetForm = () => {
    // errorCategoryNameRef.current.value="";
    // displayOrderRef.current.value="Select";
    // RemarksRef.current.value="";
    // isDeletedRef.current.checked=false;
    //alert(errorCategoryNameRef.current.value);
    companyDepartmentNameRef.current.value="";
    locationClientNameRef.current.value="";
    subProcessIDStringref.current.value="";
    setworkItemStatusesData.current.value="";
    statusReasonRef.current.value="";
    RemarksRef.current.value="";
    isDeletedRef.current.checked=false;
    // setaddNewStatusReason(false);
    // GetStatusesReasons();
};
const returnForm=() =>{
  Remarksref.current.value="";
  Reasonsref.current.value="";
  isdeleted.current.checked = false;
  // seteditstatusReasonPopUp(false);
  // GetStatusesReasons();
}
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const updateColumns = (keys) => {
    // console.log(sessionStorage.getItem("EmployeeVersionID"));
    let col = [];
    keys.forEach((item) => {
        if(item!="updatedBy")
            col.push({ title: item, field: item });
    });
    //col.pop();
    //setcolumns(col);
  };

  const saveStatusReasonDatas = () => {
    seterror("");
    let loginUrl;
    let body;
    let err={};
    let isSave = true;
    
    if(companyDepartmentNameRef.current.value=="Select"){
        err.CompDept = "Company~Department is required.";
        isSave= false;
    }
    if(locationClientNameRef.current.value=="Select"){
      err.LocClient = "Location~Client is required.";
      isSave = false;
    }
    if(subProcessIDStringref.current.value=="Select"){
      err.proc = "Process~SubProcess is required.";
      isSave = false;
    }
    if(setworkItemStatusesData.current.value=="Select"){
      err.status = "WorkItemStatus is required.";
      isSave = false;
    }
    if(statusReasonRef.current.value.length ==0 && statusReasonRef.current.value==""){
      err.strreason = "Status Reason is required.";
      isSave = false;
    }
    
    seterror({...err});
    
      
      if(isSave){
      loginUrl = getAPIURL(REACT_APP_CREATESTATUSREASONDATAS);
      
      body = {
        CompanyDepartmentName: companyDepartmentNameRef.current.value,
        LocationClientName : locationClientNameRef.current.value,
        strRemarks: RemarksRef.current.value,
        includeDeleted: isDeletedRef.current.checked,
        WorkItemStatusID : setworkItemStatusesData.current.value,
        //ErrorCategoryID: 0,
        SubProcessID : subProcessIDStringref.current.value,
        UpdatedBy:sessionStorage.getItem("PersonID"),
        strStatusReason: statusReasonRef.current.value,
      };
    
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setaddNewStatusReason(false);
          //debugger;
          addToast("Status Reason saved successfully", {
            appearance: "success",
            autoDismiss: true,

          });
         // GetStatusesReasons();
      } 
      else  if (result.StatusCode === 500 && result.Message === 'Record already exists.') {
        setaddNewStatusReason(false);
        addToast("Record already exists.", {
          appearance: "warning",
          autoDismiss: true,
        });
        // sessionStorage.clear();
        // sessionStorage.clear();
        // navigate("/");
        }
      else if (result.status === 401) {
        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
          appearance: "error",
          autoDismiss: true,
        });
        sessionStorage.clear();
        sessionStorage.clear();
        navigate("/");
       
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
    }
  };

  const columns=[
    {
        title: "Client Name",
        field: "clientName",
      },
      {
        title: "Process Name",
        field: "processName",
      },
      {
        title: "Sub Process Name",
        field: "subProcessName",
      },
      {
        title: "Company",
        field: "company",
        // render: (rowData) => (            
        //     <>
        //       <input type="checkbox" id="chkIsDeleted" disabled checked={rowData["isDeleted"] } />
        //     </>
        //   ),
      },
      {
          title: "Department",
          field: "department",
      },
      {
        title: "Location",
        field: "location",
      }
  ];
  const columns2=[
    {
      title: "Status",
      field: "statusName",
    },
    {
      title: "StatusReason",
      field: "reason",
    }
  ];

  const columns1=[
    {
      title : "Work Item Status",
      field: "statusName",
    },
    {
      title: "Status Reason",
      field: "reason"
    },
    {
      title: "Remarks",
      field: "remarks",
    },
    {
      title: "IsDeleted",
      field: "isDeleted",
      render: (rowData) => (            
          <>
            <input type="checkbox" id="isDeleted" disabled checked={rowData["isDeleted"] } />
          </>
        ),
    }
  ];

  
  // const editErrorCategory = (item) => {
  //    //debugger;
  //     companyDepartmentNameRef.current.value=item.errorCategoryName;
  //     errorCategoryIdRef.current=item.errorCategoryID;
  //     displayOrderRef.current.value=item.displayOrder;
  //     RemarksRef.current.value=item.remarks;
  //     isDeletedRef.current.checked=item.isDeleted;
  //     ButtonTextRef.current.value="Update";
  //     setbuttonText("Update");
  // };
  const viewStatusReason = (row) => {
    //debugger
      let body = {
        SubProcessID: row.processID,
      };
      let loginUrl = getAPIURL(REACT_APP_GETSUBPROCESSSTATUSREASONSPOPPAGE);
      let req = new Request(`${loginUrl}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
        if (result.status == 200) {
          //debugger
          setstatusReasonData(result.data);
          //GetActivitiesForSubprocess();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  }
  const showStatusReason = (row) =>{
    seterror("");
    
        setviewstatusReasonPopUp(true);
        settitle(row.clientName+"~"+row.processName+"~"+row.subProcessName);
        GetPopUpStatusesReasons(row);
        //const title = row.clientName+"~"+row.processName+"~"+row.subProcessName
  };
  const viewStatusReasonDatas = (row) =>{
    
    seterror("");
        setisLoading(true);
        editStatusReason(row);
        seteditstatusReasonPopUp(true);
        setviewstatusReasonPopUp(false);      
  };
  const hideEditStatusReasonPopUp = () =>{
    seteditstatusReasonPopUp(false);
    seterror("");
  }
  //const FillStatusReasonDatas
  const GetPopUpStatusesReasons = (row) => {
    
    
    seterror("");
    setstatusReasonPopUpData([]);
      let body = {
          UserId: sessionStorage.getItem("GUID"),
           // SubProcessID : subProcessIDStringref.current.value,
          SubProcessID: row.processID,
      }
      let GetStatusesReasonsURL= getAPIURL(REACT_APP_GETSUBPROCESSSTATUSREASONSPOPPAGE);
      let req1 = new Request(`${GetStatusesReasonsURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
         body: JSON.stringify(body),
      });
      fetch(req1)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
             // setisLoadingFigma(false);
              //updateColumns(Object.keys(result.data[0]));
              
              setstatusReasonPopUpData(result.data._listViewStatusReason);
              
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
  };
  const openStatusReasonPopUp=() =>{
    seterror("");
        setviewstatusReasonPopUp(true);
  };
  const closeStatusReasonPopUp=() =>{
    seterror("");
    setviewstatusReasonPopUp(false);
    GetStatusesReasons();
};

const DeleteStatusReason = (row,resolve) =>{
  //debugger
  seterror("");
        if(row.isAllDeleted)
        {
          addToast("Status Reason - "+row.subProcessName+" is already deleted.", {
                        appearance: "success",
                        autoDismiss: true,
                        
                    });
                    return false;
        };
        //if(window.confirm('Are you sure you want to delete all the Status Reason(s) for  - '+ row.subProcessName +' ?'))
     // {
        let body = {
            SubProcessID:row.processID,
            UpdatedBy:sessionStorage.getItem("PersonID"),
          };
          let loginUrl = getAPIURL(REACT_APP_DELETESTATUSREASONBYSUBPROCESSID);
          let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status === 200) {
                 //debugger;
                  addToast("Status Reasons deleted successfully", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  GetStatusesReasons();
              } else if (result.status === 401) {
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                sessionStorage.clear();
                navigate("/");
              }
            })
            .catch((error) => {
              console.log("ERROR: ", error);
              //navigate("/");
            });
    //resolve();
    //}
};
const DeleteStatusReasonDatas = (row, resolve) =>{
  seterror("");
        
        if(row.isDeleted)
        {
          addToast("Status Reason - "+row.reason+" is already deleted.", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    return false;
        };
        // if(window.confirm('Are you sure you want to delete the Status Reason - '+ row.reason +' ?'))
      //{
        let body = {
            statusReasonID : row.statusReasonID,
            SubProcessID:row.processID,
           //SubProcessID : subProcessIDStringref.current.value,
            UpdatedBy:sessionStorage.getItem("PersonID"),
          };
          let loginUrl = getAPIURL(REACT_APP_DELETESTATUSREASONDATAS);
          let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status === 200) {
                 //debugger;
                  addToast("Status Reasons deleted successfully", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  GetPopUpStatusesReasons(row);
              } else if (result.status === 401) {
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                sessionStorage.clear();
                navigate("/");
              }
            })
            .catch((error) => {
              console.log("ERROR: ", error);
              //navigate("/");
            });
    
    //}
};

  const getAdvanceSearchField = () => {
    let body = {
      subProcessID: processingDashboardParam.selectedSubprocess,
      userId: sessionStorage.getItem("GUID"),
    };
    let loginUrl = getAPIURL(REACT_APP_GETWORKFLOWITEMFIELDS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setWorkFlowWorkItemFields(result.data._listWorkItemFields);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  useEffect(() => {
    if (runSaveTImer == true && interval == null) {
      interval = setInterval(() => {
        SaveTimerOnTick();
        //clearInterval(interval);
      }, 60000);
    }
    return () => {
      //  console.log("ending dashboard 1");
      clearInterval(interval);
    };
  }, [runSaveTImer]);
  const SaveTimerOnTick = () => {
    // console.log("entered in savetimeontick");
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakactivity")
          : sessionStorage.getItem("ActivityId") == null
          ? 0
          : sessionStorage.getItem("ActivityId"), //for home page
      workItemID: 0,
      employeeTimeUtilization:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
        sessionStorage.getItem("empTimeUtilization") == "undefined" ||
        sessionStorage.getItem("empTimeUtilization") == null
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
        sessionStorage.getItem("selectedEntitiesDetail") == "undefined" ||
        sessionStorage.getItem("selectedEntitiesDetail") == null
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime:
        sessionStorage.getItem("breakStartTime") == "" ||
        sessionStorage.getItem("breakStartTime") == "undefined" ||
        sessionStorage.getItem("breakStartTime") == null
          ? ""
          : JSON.parse(sessionStorage.getItem("breakStartTime")),
      txtBreakComments:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakcomment")
          : "",
      timerValue: JSON.parse(
        sessionStorage.getItem("FetchBreakTimerOnLoadData")
      ).timerValue,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 60000,
    };

    let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_SAVETIMEONTICK);
    let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result != null) {
          if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            let FetchBreakTimerOnLoadData = JSON.parse(
              sessionStorage.getItem("FetchBreakTimerOnLoadData")
            );
            FetchBreakTimerOnLoadData.timerStart = result.timerStart;
            FetchBreakTimerOnLoadData.timerValue = result.timerValue;
            FetchBreakTimerOnLoadData.tSaveTimerEnabled =
              result.tSaveTimerEnabled;
            FetchBreakTimerOnLoadData.btnStartWorkTimerDisabled =
              result.btnStartWorkTimerDisabled;
            // FetchBreakTimerOnLoadData.txtBreakCommentsEnabled =
            //   result.txtBreakCommentsEnabled;
            // FetchBreakTimerOnLoadData.ddlActivityEnabled =
            //   result.ddlActivityEnabled;
            FetchBreakTimerOnLoadData.btnShowBreakPanelDisabled =
              result.btnShowBreakPanelDisabled;
            // sessionStorage.setItem(
            //   "objdataview",
            //   JSON.stringify(result.data[0].objdataview)
            // );
            sessionStorage.setItem(
              "FetchBreakTimerOnLoadData",
              JSON.stringify(FetchBreakTimerOnLoadData)
            );
            sessionStorage.setItem(
              "empTimeUtilization",
              JSON.stringify(result.employeeTimeUtilization)
            );
          }
        }
        // else if(result.status === 401)
        // {
        //   navigate("/")
        // }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    //sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    //sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    //GetStatusReasonBySubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
    //GetWorkItemStatus();
  };
  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          //GetActivitiesForSubprocess();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const GetWorkItemStatus = () => {
    seterror("");
    let WorkItemStatusesURL= getAPIURL(REACT_APP_GETWORKITEMSTATUSES);
    let req1 = new Request(`${WorkItemStatusesURL}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      // body: JSON.stringify(body1),
    });
    fetch(req1)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {

          setworkItemStatusData(result.data._listWorkItemStatus);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
};
  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // sessionStorage.setItem(
          //   "SelctedSubprocessName",
          //   result.data[0].processSubProcess
          // );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  
  useEffect(() => {
    
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    ); 
   // GetWorkItemStatus(); 
    GetStatusesReasons();
    setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
    // FetchDepartmentbyGUID();
    setsearchDivVisible(true);
    // GetWorkItemStatus();
   // setviewstatusReasonPopUp(true);
    setisLoadingFigma(false);
   // GetWorkItemStatus();
    // ButtonTextRef.current.value="Save";
    // setbuttonText("Save");
  }, []);
  const OpenExistingEntries = (e)=>{
    
      GetStatusReasonBySubProcessID(e.target.value);
  };
  // const OpenExistingEntries = ()=>{
  //   debugger
  //     GetStatusReasonBySubProcessID(processingDashboardParam.selectedSubprocess);
  // };

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };
 
  const FetchProcessSubProcessByGuid = (e) => {
    
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
    }
    setSelectedLocation(e.target.value);
    setSelectedClientLocation(e.target.value.split("/")[1]);
    // sessionStorage.setItem("selectedLocation", e.target.value.split("/")[1]);
    // sessionStorage.setItem(
    //   "selectedLocationName",
    //   e.target.value.split("/")[0]
    // );
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value.split("/")[1],
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          setprocessSubprocess([]);
        } else {
          setprocessSubprocess([]);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const editStatusReason=(row)=>{
    
    seterror("");
    GetStatusReasonBySubProcessID(row.processID);
    let body = {
      intStatusReasonID: row.statusReasonID,
      includeDeleted: row.isAllDeleted,
      UserId: sessionStorage.getItem("GUID"),
      SubProcessID: row.processID,
      
  }
  let GetStatusesReasonsURL= getAPIURL(REACT_APP_FILLSTATUSREASONDATAS);
  let req1 = new Request(`${GetStatusesReasonsURL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      UserName: sessionStorage.getItem("UserName"),
      Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
    },
     body: JSON.stringify(body),
  });
  fetch(req1)
    .then((res) => res.json())
    .then((result) => {
      if (result.status == 200) {
         // setisLoadingFigma(false);
          //updateColumns(Object.keys(result.data[0]));
          
          seteditstatusReasonData(result.data._listStatusReasonData);
          // setReasons(row.reason);
          // setcompanyAndDepartment(row.department);
          // setLocationAndClient(row.clientName);
          // setProcessAndSubProcess(row.subProcessName);
          // setWorkItemStatus(row.statusName);
          // setRemarks(row.remarks);
          // setDeleted(row.isDeleted);
          // setstatusReasonID(row.statusReasonID);
          // setSubProcessID(row.processID)
          setisLoading(false);
          let reasons = Reasonsref.current.value;
          Reasonsref.current.value = result.data._listStatusReasonData[0].reason;
          //setReasons(result.data._listStatusReasonData[0].reason);
          let remarks = Remarksref.current.value;
          Remarksref.current.value = result.data._listStatusReasonData[0].remarks;
          // let isDelete = Deletedref.current.value;
          // Deletedref.current.value = result.data._listStatusReasonData[0].isDeleted;
          let departments = departmentRef.current.value;
          departmentRef.current.value = result.data._listStatusReasonData[0].company+"/"+result.data._listStatusReasonData[0].department;

          let locations = locationRef.current.value;
          locationRef.current.value = result.data._listStatusReasonData[0].location+"/"+result.data._listStatusReasonData[0].clientName;

          let processAndsubprocess = processSubprocessref.current.value;
          processSubprocessref.current.value = result.data._listStatusReasonData[0].processName+"/"+result.data._listStatusReasonData[0].subProcessName;

          let workItemStatusName = statusNameref.current.value;
          statusNameref.current.value = result.data._listStatusReasonData[0].statusName;
          //setcompanyAndDepartment(result.data._listStatusReasonData[0].company+"/"+result.data._listStatusReasonData[0].department);
          //setLocationAndClient(result.data._listStatusReasonData[0].location+"/"+result.data._listStatusReasonData[0].clientName);
          //setProcessAndSubProcess(result.data._listStatusReasonData[0].processName+"/"+result.data._listStatusReasonData[0].subProcessName);
          //setWorkItemStatus(result.data._listStatusReasonData[0].statusName);
          // setRemarks(result.data._listStatusReasonData[0].remarks);
          let checkeddeleted = isdeleted.current.checked;
          isdeleted.current.checked = result.data._listStatusReasonData[0].isDeleted;
          //setDeleted(result.data._listStatusReasonData[0].isDeleted);
          setstatusReasonID(result.data._listStatusReasonData[0].statusReasonID);
          setSubProcessID(result.data._listStatusReasonData[0].processID);
          //setisLoading(false);
      }
    })
    .catch((error) => {
      console.log("ERROR: ", error);
    });
    //companyDepartmentNameRef.current.value = item.
    // setisLoading(false);
  }

  const FetchLocationClientsByGuid = (e) => {
    //debugger
    subProcessIDStringref.current.value = "Select";
    setStatusReasonBySubProcessID([]);
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setClientLocations([]);
    }
    if (e.target.value !== "Select") {
      // sessionStorage.setItem(
      //   "selectedDepartment",
      //   e.target.value.split("/")[1]
      // );
      // sessionStorage.setItem(
      //   "selectedDepartmentName",
      //   e.target.value.split("/")[0]
      // );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };
 
  const updateStatusReasonDatas = () =>{
    seterror("");
     
    let Err = {};
    let isSubmit= true;
    if(Reasonsref.current.value.length == 0 && Reasonsref.current.value == ""){
    //   addToast("Status Reason is Required.", {appearance: "error",autoDismiss: true,});
      Err.name = "Status Reason is Required.";
     isSubmit = false;
    }
    seterror({...Err});
    if(isSubmit){
    let body = {
        intStatusReasonID: statusReasonID,
        strStatusReason: Reasonsref.current.value,
        strRemarks: Remarksref.current.value,
        //WorkItemStatusID : setworkItemStatusesData.current.value,
        //ErrorCategoryID: 0,
        SubProcessID : SubProcessID,
        UpdatedBy:sessionStorage.getItem("PersonID"),
        includeDeleted: isdeleted.current.checked,
    }
    let UpdateStatusesReasonsURL= getAPIURL(REACT_APP_UPDATESTATUSREASONDATAS);
      let req1 = new Request(`${UpdateStatusesReasonsURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
         body: JSON.stringify(body),
      });
      fetch(req1)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
             // setisLoadingFigma(false);
              //updateColumns(Object.keys(result.data[0]));
              //debugger
              //setstatusReasonData(result.data._listStatusReason);
              addToast("Status Reason Updated Successfully", {
                appearance: "success",
                autoDismiss: true,
                //StatusReasonBySubProcessID(); 
            });
            //setReasons("");
            hideEditStatusReasonPopUp();
            closeStatusReasonPopUp();
            
             setStatusReasonBySubProcessID([]);
          }
          else  if (result.Message === 'Record already exists.') {
            //setaddNewStatusReason(false);
            addToast("Record already exists.", {
              appearance: "warning",
              autoDismiss: true,
            });
            hideEditStatusReasonPopUp();
            closeStatusReasonPopUp();
            
             setStatusReasonBySubProcessID([]);
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        }); 
  }};
  const GetStatusReasonBySubProcessID = (item) => {
    
      setStatusReasonBySubProcessID([]);
      let body = {
          SubProcessID: item
      }
      let GetStatusREasonBySubProcessIDURL= getAPIURL(REACT_APP_STATUSREASONBYSUBPROCESSID);
      let req1 = new Request(`${GetStatusREasonBySubProcessIDURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
         body: JSON.stringify(body),
      });
      fetch(req1)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
             // setisLoadingFigma(false);
              //updateColumns(Object.keys(result.data[0]));
              //debugger
              //setstatusReasonData(result.data._listStatusReason);
              setStatusReasonBySubProcessID(result.data._listStatusReasonBySubProcessID)
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
  };

  const GetStatusesReasons = () => {
    seterror("");
    
      let SelectedDisplayCriterion = ""
      //let SelectedDisplayCriterion = DisplayCriterion.current == undefined?"":DisplayCriterion.current.value
      let Val = processingDashboardParam.selectedSubprocess
      if(Val != null)
      {
         // dropdown = true;
         setshowDropdown(true);
         SelectedDisplayCriterion = DisplayCriterion.current == undefined?"0":DisplayCriterion.current.value
      }
      let Val1 = "all"
      let Val2 = 0
      
      if(SelectedDisplayCriterion == "0")
      {
          Val1 = "subprocess"
          Val2 = processingDashboardParam.selectedSubprocess
      }
      else if(SelectedDisplayCriterion == "1")
      {
          Val1 = "process"
          //Val2 = (processingDashboardParam.selectedSubprocess / 10000) * 10000
          Val2 = Math.floor(processingDashboardParam.selectedSubprocess/10000)*10000
      }
      else if(SelectedDisplayCriterion == "2")
      {
          Val1 = "client"
          Val2 = locationClientID
      }
      else if(SelectedDisplayCriterion == "3")
      {
          Val1 = "all"
          Val2 = 0
      }
      let body = {
          UserId: sessionStorage.getItem("GUID"),
          FilterKey : Val1,
          FilterValue : Val2,
          // FilterKey : "all",
          // FilterValue : 0,
          //FilterKey : "subprocess",
          //FilterValue : processingDashboardParam.selectedSubprocess,
          // FilterKey : "subprocess",
          // FilterValue : sessionStorage.getItem("ProcessId")
         // if(sessionStorage.processID!=null)
         //const value = sessionStorage.getItem("processID")
        //  valueRef = sessionStorage.getItem("processID"),  
      }
      let GetStatusesReasonsURL= getAPIURL(REACT_APP_GETSTATUSESREASONS);
      let req1 = new Request(`${GetStatusesReasonsURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
         body: JSON.stringify(body),
      });
      fetch(req1)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
             // setisLoadingFigma(false);
              //updateColumns(Object.keys(result.data[0]));
              //debugger
              setstatusReasonData(result.data._listStatusReason);
              setDatacount(result.data._listStatusReason.length);
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
  };
  const popUpOpener = () =>{
    seterror("");
    
    setStatusReasonBySubProcessID([]);
    setaddNewStatusReason(true);
    //OpenExistingEntries();
    GetStatusReasonBySubProcessID(processingDashboardParam.selectedSubprocess);
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    FetchDepartmentbyGUID();
    GetWorkItemStatus();
  }
  const popUpCloser = () =>{
    setaddNewStatusReason(false);
    seterror("");
  }
  const NavElements = useMemo(() => {
    return (
      <>
        <Header pageName={"StatusReasons"}/>
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
  return (
    <>
      {NavElements}
      <GlobalStyles
        styles={{
          ".MuiTableCell-root": {
            padding: "5px !important",
            paddingbottom: "50px !important",
            "min-width": "10px !Important",
          },
          ".MuiTableFooter-root": {
            position: "absolute !Important",
          },
          ".MuiTypography-root": {
            "font-size": "1.0rem !important",
          },
          ".MuiCheckbox-root": { padding: "5px !important" },
          ".MuiSvgIcon-root": {
            width: "0.7em !important",
            height: "0.7em !important",

            "font-size": "1.5rem !important",
          },
          ".MuiIconButton-root": {
            "font-size": "1rem !important",
          },
          ".MuiTablePagination-toolbar": {
            width: "0px !important",
            // "max-width": "1000px !important",
          },
          ".MuiToolbar-regular": {
            "min-height": "0px !important",
          },
          ".MuiTableFooter-root": {
            position: "sticky!Important",
            "background-color": "#fff6f0 !Important",
            padding: "0px !important",
          },
        }}
      />

      {/* <div className="centerSection"> */}
        <div className="pageBody">
          
          {searchDivVisible ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header" style={{ maxHeight: "45px" }}>
              <div class = "row  align-items-center">
              <div class="col-6"><h6 className= "pageName"> MANAGE STATUS REASONS</h6></div>
              {/* <div class="col-2"><h6 className= "pageName"> MANAGE STATUS REASONS</h6></div> */}
              <div className="col-3 textend">
                   {showDropdown?(
                      <div className="input-group input-group-sm">
                        <label className="input-group-text">Display-Criterion
                        </label>
                        <div>
                        </div> 
                        <select
                          className="form-select font-Standard-11"
                          onChange={GetStatusesReasons}
                          ref = {DisplayCriterion}
                        >
                          <option value={0}>Sub-Process</option>
                          <option value={1}>Process</option>
                          <option value={2}>Client</option>
                          <option value={3}>All</option>
                      </select>
                      </div>):null}
                         
                    </div>
              <div class="col-3 textend">
              <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
              <button
                    type="button"
                    className="btn btn-sm btn-primary ms-3"
                    //style = "float: right;"
                    //color = "#f194ff"
                    onClick={popUpOpener}
                >
                  + Add New Status Reason
                </button>
                </div>
                </div>
                </div>
                </div>
              {/* <div className="dashBoxs-header">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                      <div className="d-inline">
                        <div className="input-group input-group-sm">
                          <small
                            className="noteText"
                            style={{ padding: "5px", "padding-right": "35px" }}
                          >
                          </small>                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-right mt-2"></div>
                </div>
              </div> */}
              {isLoadingFigma ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="tableWrap">
                  <span
                    style={{
                      fontSize: "13px",
                      color: "blue",
                      marginLeft: "1rem"
                    }}
                  >
                    {Datacount} out of {Datacount} records displayed
                  </span>
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                      //title="Table"
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                            
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          {
                            icon: () => <VisibilitySharpIcon />,
                            tooltip: "View Status Reason(s)",
                            onClick: (event, rowData) => showStatusReason(rowData),
                            position: "row",
                          },
                          // {
                          //   icon: () => <DeleteIcon />,
                          //   tooltip: "Delete Status Reason",
                          //   onClick: (event, rowData) => DeleteStatusReason(rowData),
                          //   position: "row",
                          // }
                        ]}
                        editable={{
                          
                          onRowDelete: (selectedRow) =>
                            new Promise((resolve) => {
                              DeleteStatusReason(selectedRow, resolve);
                              resolve();
                            }),
                        }}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          paging: false,
                          //pageSizeOptions: [100, 200, 500, 1000],
                          exportButton: true,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "right",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important",
                          },
                        }}
                        // editable={{
                        //     onRowDelete: (oldData) =>
                        //       new Promise((resolve) => {
                        //         handleRowDelete(oldData, resolve);
                        //       }),
                        //   }}
                        title="Manage Status Reasons"
                        columns={columns}
                        icons={tableIcons}
                        data={statusReasonData}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                              
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                              {/* <div className = "mb-2 text-end">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary ms-3"
                                onClick={popUpOpener}
                                >
                                Add New Status Reason
                                </button>
                              </div> */}
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : null},
        </div>
      {/* </div> */}
      <Footer />
      <Modal
        show={viewstatusReasonPopUp}
       // onBackdropClick={closeStatusReasonPopUp}
        onHide={closeStatusReasonPopUp}
        dialogClassName="modal-100w"
        //onHide={viewSearchCloseAndRest}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Status Reasons for {title} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="pageBody pd-top-0">
          {/* <div className="dashBoxs mb-3">   
              <div className="formWrap">
                <div className="fieldSet">
                  <h4 className="mb-4">Edit Status Reason</h4>
                  <div className="row align-items-end">
                    <div className="col-3">
                      <div className="mb-2">
                        <label className="form-label">Company-Department*</label>
                        <select
                          className="form-control form-control-sm"
                          // onChange={FetchLocationClientsByGuid}
                          // ref={setClientLocation}
                          //onChange={getWorkitemId}
                        > 
                      </select>
                      </div>
                    </div>
                    <div className="col-3" style={{ "height": "81px" }}>
                      <div className="mb-2">
                        <label className="form-label">Location-Client*</label>
                        <select
                          className="form-control form-control-sm"
                          // onChange={FetchProcessSubProcessByGuidOnPageLoad}
                          // ref ={ClientLocation}
                        >
                        </select>
                      </div>
                    </div>
                    <div className="col-3" >
                      <div className="mb-2">
                      <label className="form-label">Process-SubProcess*</label>
                      <select
                        className="form-control form-control-sm"
                        //onChange={FetchLockedWorkitemsBySubProcessID}
                        // ref={subProcessIDStringref}
                      >
                      </select>
                      </div>
                    </div>
                    <div className="col-3" >
                      <div className="mb-2">
                      <label className="form-label">WorkItem Status*</label>
                      <select
                        className="form-control form-control-sm"
                        //onChange={FetchLockedWorkitemsBySubProcessID}
                        // ref={subProcessIDStringref}
                      >
                      </select>
                      </div>
                    </div>
                    <div className="col-3"/>
                    <div className="col-2" style={{ "height": "81px" }}>
                        
                      <div className="mb-2">
                      <label className="form-label">Status Reason</label>
                      <textarea name="Text1" cols="40" rows="2" maxLength="4000" ref={statusReasonRef}></textarea>
                      </div>
                    </div>

                    <div className="col-3"/>
                    <div className="col-2" style={{ "height": "81px" }}>
                        
                      <div className="mb-2">
                      <label className="form-label">Remarks</label>
                      <textarea name="Text1" cols="40" rows="2" maxLength="4000" ref={RemarksRef}></textarea>
                      </div>
                    </div>
                    <div className="col-3"/>
                    <div className="col-3" style={{ "height": "81px" }}>
                      <div className="mb-2">
                      <label className="form-label">Is Deleted</label>
                      <input type="checkbox" id="isDeleted" ref={isDeletedRef}  />
                      </div>
                    </div>
                      </div>
                  
                </div>
                {/* <hr className="mb-4" /> */}
                {/* <hr className="mb-4" />
                <div className="fieldSet">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2">
                        
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetForm}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          // onClick={() => {
                          //   saveErrorCategoryData();
                          // }}
                        >
                          {buttonText}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>           
          </div>  */}
                  <div className="tableWrap">
                     <div className="tableWrap-body">
                       <table className="table table-borderless MatTable">
                         {/* <ThemeProvider theme={theme}> */}
                         <MaterialTable
                          localization={{
                            pagination: {
                              //labelDisplayedRows: '{from}-{to} of {count}'
                            },
                            toolbar: {
                              // nRowsSelected: '{0} row(s) selected'
                              // exportCSVName: "Export as Excel",
                              // exportPDFName: "Export as pdf",
                            },
                            header: {
                              actions: "",
                            },
                          }}
                          actions={[
                            {
                              icon: () => <EditIcon />,
                              tooltip: "Edit Status Reason",
                              onClick: (event, rowData) =>
                              viewStatusReasonDatas(rowData),
                              //onclick:{},
                              position: "row",
                            },
                            // {
                            //   icon: () => <DeleteIcon />,
                            //   tooltip: "Delete",
                            //   onClick: (event, rowData) => DeleteStatusReasonDatas(rowData),
                            //   position: "row",
                            // }
                          ]}
                          editable={{
                          
                            onRowDelete: (selectedRow) =>
                              new Promise((resolve) => {
                                DeleteStatusReasonDatas(selectedRow, resolve);
                                resolve();
                              }),
                          }}
                          options={{
                            headerStyle: {
                              position: "sticky",
                              top: "-5px",
                            },
                            maxBodyHeight: "420px",
                            //pagination:true,
                            //paginationType:"normal",
                            //paginationPosition: "bottom",
                            pageSize: numberOfRecord,
                            pageSizeOptions: [100, 200, 500, 1000],
                            exportButton: true,
                            sorting: true,
                            filtering: true,
                            search: false,
                            selection: false,
                            tableLayout: "auto",
                            overflowY: false,
                            showTitle: false,
                            toolbarButtonAlignment: "left",
                            headerStyle: {
                              height: 30,
                            },
                            rowStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontSize: "13px",
                              // whiteSpace: "nowrap",
                            },
                            tableLayout: "auto",
                            headerStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              fontSize: "14px",
                              textAlign: "left !Important",
                            },
                          }}
                          columns={columns1}
                          icons={tableIcons}
                          data={statusReasonPopUpData}
                          components={{
                            Container: (props) => (
                              <Paper {...props} elevation={0} />
                            ),
                            Toolbar: (props) => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em",
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{
                                    fontSize: "5px",
                                  }}
                                />
                                
                              </div>
                            ),
                          }}
                        />
                        {/* </ThemeProvider> */}
                      </table>
                    </div>
                  </div>
                  </div>

        </Modal.Body>
      </Modal>
      <Modal
        show={addNewStatusReason}
       // onBackdropClick={closeStatusReasonPopUp}
        onHide={popUpCloser}
        dialogClassName="modal-100w"
        //onHide={viewSearchCloseAndRest}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Status Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="dashBoxs mb-3">   
              <div className="formWrap">
                <div className="fieldSet">
                  {/* <h4 className="mb-4">Add New Status Reasons</h4> */}

                  <div className="row align-items-end">
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="mandatory">Company-Department
                        <span id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span>
                        </label>
                        <div>
                        <span  style = {{color:'red'}}>{error.CompDept}</span>
                        </div>
                        
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchLocationClientsByGuid}
                          ref={companyDepartmentNameRef}
                          //onChange={getWorkitemId}
                        >
                          <option>Select</option>
                        {departments.map((department) => (
                          <option
                            value={
                              department.companyDepartment +
                              "/" +
                              department.departmentIDString
                            }
                            selected={
                              department.departmentIDString ==
                              companyDeparmentID
                            }
                          >
                            {department.companyDepartment}
                          </option>
                        ))}
                      </select>
                      </div>
                    </div>
                    <div className="col-3" >
                      <div className="mb-3">
                        <label className="mandatory">Location-Client<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                        <span style = {{color:'red'}}>{error.LocClient}</span>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchProcessSubProcessByGuid}
                          ref ={locationClientNameRef}
                        >
                          <option>Select</option>
                          {clientLocations.map((location) => (
                            <option
                              value={
                                location.locationClient +
                              "/" +
                              location.clientIDString
                              }
                              selected={
                                location.clientIDString == locationClientID
                              }
                            >
                              {location.locationClient}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-3" >
                      <div className="mb-3">
                      <label className="mandatory">Process-SubProcess<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      <div>
                      <span style = {{color:'red'}}>{error.proc}</span>
                      </div>
                      <select
                        className="form-control form-control-sm"
                        ref={subProcessIDStringref}
                        onChange={OpenExistingEntries}
                      >
                      <option>Select</option>
                        {processSubprocess.map((subprocess) => (
                          <option
                            value={subprocess.subProcessIDString}
                            selected={
                              subprocess.subProcessIDString ==
                              processSubProcessID
                            }
                            
                          >
                            {subprocess.processSubProcess}
                          </option>
                        ))}
                      </select>
                      </div>
                    </div>
                    <div className="col-3" >
                      <div className="mb-3">
                      <label className="mandatory">WorkItem Status<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      <div>
                      <span style = {{color:'red'}}>{error.status}</span>
                      </div>
                      <select
                        className="form-control form-control-sm"
                        //onChange={FetchLockedWorkitemsBySubProcessID}
                        ref={setworkItemStatusesData}
                        //ref ={setworkItemStatusData}
                      >
                      
                      <option>Select</option>
                        {workItemStatusData.map((workItemData) => (
                          <option
                            value={workItemData.workItemStatusID}
                            
                            selected = {
                              workItemData.workItemStatusID == WorkItemStatusID
                            }
                          >
                            {workItemData.statusName}
                          </option>
                        ))}
                      </select>
                      </div>
                    </div>
                    </div>
                  <div className="row align-items-end">

                    <div className="col-3">
                    {/* <div className="col-2"> */}
                        
                      <div className="mb-1">
                      <label className = "mandatory">Status Reason<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      <div>
                      <span style = {{color:'red'}}>{error.strreason}</span>
                      </div>
                      <textarea className="form-control form-control-sm" cols="38" rows="2" maxLength="4000" ref={statusReasonRef}></textarea>
                      </div>
                    </div>

                    <div className="col-3">
                    {/* <div className="col-2" style={{ "height": "81px" }} /> */}
                        
                      <div className="mb-1">
                      <label className="mandatory">Remarks</label>
                      <textarea className="form-control form-control-sm" cols="38" rows="2" maxLength="4000" ref={RemarksRef}></textarea>
                      </div>
                    </div>

                    <div className="col-3">
                    {/* <div className="col-3" style={{ "height": "81px" }}> */}
                      <div className="mb-4">
                      <label className="form-label">Is Deleted</label>
                      <input type="checkbox" id="isDeleted" ref={isDeletedRef}  />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <hr className="mb-4" /> */}
                <hr className="mb-4" />
                <div className="fieldSet">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2">
                        
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetForm}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={() => {
                            //OpenExistingEntries(subProcessIDStringref);
                            saveStatusReasonDatas();
                            GetStatusReasonBySubProcessID(subProcessIDStringref.current.value);  
                          }}
                        >
                          Save & Continue
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          onClick={() => {
                            //setaddNewStatusReason(false);
                            saveStatusReasonDatas();
                            //GetStatusesReasons();
                          }}
                        >
                          {buttonText}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        // actions={[
                        //   {
                        //     icon: () => <VisibilitySharpIcon />,
                        //     tooltip: "View Status Reason",
                        //     //onClick: (event, rowData) =>
                        //     //viewStatusReason(rowData),
                        //    // onclick:(event, rowData)=>showStatusReason(rowData),
                            
                        //       onClick: (event, rowData) => showStatusReason(rowData),
                        //     position: "row",
                        //   },
                        //   {
                        //     icon: () => <DeleteIcon />,
                        //     tooltip: "Delete Status Reason",
                        //     onClick: (event, rowData) => DeleteStatusReason(rowData),
                        //     position: "row",
                        //   }
                        // ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          pageSizeOptions: [100, 200, 500, 1000],
                          exportButton: false,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important",
                          },
                        }}
                        // editable={{
                        //     onRowDelete: (oldData) =>
                        //       new Promise((resolve) => {
                        //         handleRowDelete(oldData, resolve);
                        //       }),
                        //   }}
                        columns={columns2}
                        icons={tableIcons}
                        data={StatusReasonBySubProcessID}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                              
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              </div>           
          </div>

        </Modal.Body>
      </Modal>
      <Modal
        show={editstatusReasonPopUp}
       // data={editstatusReasonData}
       // onBackdropClick={closeStatusReasonPopUp}
        onHide={hideEditStatusReasonPopUp}
        dialogClassName="modal-100w"
        //onHide={viewSearchCloseAndRest}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Status Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>  
        <div className="dashBoxs mb-3">   
              <div className="formWrap">
                <div className="fieldSet">
                  {/* <h4 className="mb-4">Edit Status Reason</h4> */}

                  <div className="row align-items-end">
                    <div className="col-3">
                      <div className="mb-1">
                        <label for="text1" className="form-label" >Company-Department<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      <div>
                      <input className="form-control form-control-sm" id="text1"  ref={departmentRef} disabled></input>
                      </div>
                      </div>
                    </div>
                    <div className="col-3" >
                      <div className="mb-1">
                        <label className="form-label">Location-Client<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                        {/* <select
                          className="form-control form-control-sm"
                          //onChange={FetchProcessSubProcessByGuid}
                          ref ={locationClientNameRef}
                        >
                        </select> */}
                        <input className="form-control form-control-sm" ref={locationRef} disabled></input>
                      </div>
                    </div>
                    <div className="col-3" >
                      <div className="mb-1">
                      <label className="form-label">Process-SubProcess<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      {/* <select
                        className="form-control form-control-sm"
                        //onChange={FetchLockedWorkitemsBySubProcessID}
                        ref={subProcessIDStringref}
                      >
                      </select> */}
                      <input className="form-control form-control-sm" ref = {processSubprocessref} disabled></input>
                      </div>
                    </div>
                    <div className="col-3" >
                      <div className="mb-1">
                      <label className="form-label">WorkItem Status<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      {/* <select
                        className="form-control form-control-sm"
                        //onChange={FetchLockedWorkitemsBySubProcessID}
                        ref={setworkItemStatusesData}
                        //ref ={setworkItemStatusData}
                      >
                      </select> */}
                      <input  className="form-control form-control-sm" ref = {statusNameref} disabled></input>
                      </div>
                    </div>
                    </div>
                    <div className="row align-items-end">
                    <div className="col-3">
                    {/* <div className="col-2"> */}
                        
                      <div className="mb-1">
                      <label className="form-label">Status Reason<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      <span  style={{color: 'red'}}>{error.name}</span>
                      <textarea className="form-control form-control-sm" cols="40" rows="2" maxLength="4000" ref = {Reasonsref}></textarea>
                      </div>
                    {/* </div> */}
                    </div>

                    <div className="col-3">
                    {/* <div className="col-2"> */}
                        
                      <div className="mb-1">
                      <label className="form-label">Remarks</label>
                      <textarea className="form-control form-control-sm" cols="40" rows="2" maxLength="4000" ref = {Remarksref}></textarea>
                      </div>
                    {/* </div> */}
                    </div>
                    <div className="col-3">
                    {/* <div className="col-3" style={{ "height": "81px" }}> */}
                      <div className="mb-1">
                      <label className="form-label">Is Deleted</label>
                      <input type="checkbox" id="isDeleted" ref={isdeleted} />
                      </div>
                     {/* </div>   */}
                    </div>
                  </div>
                </div>
                {/* <hr className="mb-4" /> */}
                <hr className="mb-4" />
                <div className="fieldSet">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2">
                        
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={returnForm}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          ref={ButtonTextRef}
                          onClick={() => {
                            updateStatusReasonDatas();
                          }}
                        >
                          {buttonText}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: "",
                            
                          },
                        }}
                        // actions={[
                        //   {
                        //     icon: () => <VisibilitySharpIcon />,
                        //     tooltip: "View Status Reason",
                        //     //onClick: (event, rowData) =>
                        //     //viewStatusReason(rowData),
                        //    // onclick:(event, rowData)=>showStatusReason(rowData),
                            
                        //       onClick: (event, rowData) => showStatusReason(rowData),
                        //     position: "row",
                        //   },
                        //   {
                        //     icon: () => <DeleteIcon />,
                        //     tooltip: "Delete Status Reason",
                        //     onClick: (event, rowData) => DeleteStatusReason(rowData),
                        //     position: "row",
                        //   }
                        // ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          pageSizeOptions: [100, 200, 500, 1000],
                          //exportButton: true,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important",
                          },
                        }}
                        // editable={{
                        //     onRowDelete: (oldData) =>
                        //       new Promise((resolve) => {
                        //         handleRowDelete(oldData, resolve);
                        //       }),
                        //   }}
                        columns={columns2}
                        icons={tableIcons}
                        data={StatusReasonBySubProcessID}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                              
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              </div>           
          </div>
          </>
          )}
        </Modal.Body>
      </Modal>
      
    </>
  );
};

export default React.memo(StatusReason);

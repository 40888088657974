import React from "react";

import "../../assets/scss/modules/_footer.scss";
import { Link } from "react-router-dom";
const { REACT_APP_AllowCustomerSupport } = process.env;
const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <small>
                &copy; {new Date().getFullYear()} Copyright{" "}
                {new Date().getFullYear()} ExlService Holdings, Inc. All Rights
                Reserved.
              </small>
            </div>
            <div className="col-6 text-end">
              <Link className="" to="/About">
                About
              </Link>{" "}
              {sessionStorage.getItem("UserName") !== null &&
              REACT_APP_AllowCustomerSupport === "true" ? (
                <a
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  Help
                </a>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React from "react";
import "./App.css";

export function HomeGraphPlaceholder() {
  return (
    <div className="card-text placeholder-glow col-4">
      <span
        className="placeholder col-12 bg-light"
        style={{ height: "300px" }}
      ></span>
    </div>
  );
}

export function EmailListPlaceholder() {
  return (
    <div className="email-content placeholder-glow">
      <div className=" placeholder d-flex justify-content-between align-items-center mb-2 h-50">
        <div className="d-flex flex-column"></div>
      </div>
      <div className=" placeholder d-flex justify-content-between align-items-center mb-2 h-50">
        <div className="d-flex flex-column"></div>
      </div>
    </div>
  );
}
export function HomeDashboardPlaceholder() {
  return (
    <>
      <div className="card-text placeholder-glow col-12">
        <span
          className="placeholder col-12 bg-light"
          style={{ height: "100px" }}
        ></span>
      </div>
      <div className="card-text placeholder-glow col-4">
        <span
          className="placeholder col-12 bg-light"
          style={{ height: "300px" }}
        ></span>
      </div>
      <div className="card-text placeholder-glow col-4">
        <span
          className="placeholder col-12 bg-light"
          style={{ height: "300px" }}
        ></span>
      </div>
    </>
  );
}

import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import Dropdown from "react-bootstrap/Dropdown";
import DashboardContext from "../../../../contexts/DashboardContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import user01 from "../../../../assets/images/user/user01.jpg";
import PowerBiFunctions from "../../../../common/PowerBiFunctions";
import { useMemo } from "react";
const EmbeddedReports = () => {
  const { addToast } = useToasts();
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_API_URL } = process.env;
  let navigate = useNavigate();
  const { reportids } = useSelector((state) => state);
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const [PBIReportID, setPBIReportID] = useState("");
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const FetchBreakTimerOnLoad = () => {
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID: 14, //for Report
      workItemID: 0,
      employeeTimeUtilization:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
        sessionStorage.getItem("empTimeUtilization") == "undefined"
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
        sessionStorage.getItem("selectedEntitiesDetail") == "undefined"
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime: "",
      txtBreakComments: "",
      timerValue: 1,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 600000,
    };

    let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_FETCHBREAKTIMERONLOAD);
    let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          sessionStorage.setItem(
            "objdataview",
            JSON.stringify(result.data[0].objdataview)
          );
          sessionStorage.setItem(
            "FetchBreakTimerOnLoadData",
            JSON.stringify(result.data[0])
          );
          sessionStorage.setItem(
            "empTimeUtilization",
            JSON.stringify(result.data[0].employeeTimeUtilization)
          );
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  useEffect(() => {
    //modeRef.current.classList.toggle("mini-mode");
    //FetchBreakTimerOnLoad();
    // console.log("in embedded report");
    // console.log(reportids);
  }, []);
  const PowerBIReportView = useMemo(() => {
    // console.log(PBIReportID);
    return (
      <>
        <PowerBiFunctions
          state={{
            PowerbiWorkspaceId: sessionStorage.getItem("PowerBiWorkspaceID"),
            PowerbiReportid: PBIReportID,
            cssStyle: "PowerBiHome",
            centersetion: "CentersetionHome",
            IsHome: "false",
          }}
        />
      </>
    );
  }, [PBIReportID]);
  return (
    <>
      <Header pageName="Embedded Reports" timeCaptureActivityName="Reporting" />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="reports" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="">
              <div className="">
                <div className="">
                  <div className="row">
                    <div className="col-4">
                      <div
                        style={{ marginLeft: "1rem", marginBottom: "0.3rem" }}
                      >
                        <select
                          title=" On selecting specific report, it will load the view in the
                        section below:"
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            setPBIReportID(e.target.value);
                          }}
                        >
                          <option>Select Report</option>
                          {reportids[0].map((report) => (
                            <option value={report.pbiReportID}>
                              {report.pbiReportTitle}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {PowerBIReportView}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmbeddedReports;

import React, { useState, useEffect, useMemo, useRef } from "react";

import Header from "../../../../../components/header/Header";
import Footer from "../../../../../components/footer/Footer";
import Nav from "../../../../../components/nav/Nav";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import ApplicationUserContext from "../../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../../contexts/NavDataContext";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import tableIcons from "../../../../../MaterialTableIcons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashboardContext from "../../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { FcViewDetails } from "react-icons/fc";
import { FcFinePrint } from "react-icons/fc";
import { FcDeleteColumn } from "react-icons/fc";
import PendingActionsIcon from "@mui/icons-material/";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { ToastProvider, useToasts } from "react-toast-notifications";
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
import LoadingSpinner from "../../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../../LoadingSpinnerFigma";
import Iframe from "react-iframe";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";

import user01 from "../../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../../assets/images/icons/icon_empty.svg";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";



function ErrorFields(){
  const { addToast } = useToasts();
  const {REACT_APP_GETERRORFIELDS} = process.env;
  const { REACT_APP_API_URL } = process.env;
  const {REACT_APP_VIEWERRORFIELDS} = process.env;
  const {REACT_APP_DELETEERRORFIELDS} = process.env;
  const {REACT_APP_DELETEERRORFIELDSBYSUBPROCESSID} = process.env;
  const {REACT_APP_FETCH_LOCATIONCLIENTSBYGUID} = process.env;
  const {REACT_APP_FETCH_DEPARTMENTBYGUID} = process.env;
  const {REACT_APP_FETCH_PROCESSSUBPROCESS} = process.env;
  const {REACT_APP_GET_SUBPROCERRORFIELDS} = process.env;
  const {REACT_APP_CREATESUBPROCERRORFIELDS} = process.env;
  const {REACT_APP_UPDATESUBPROCERRORFIELDS} = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;

  const [ErrorFields, setErrorFields] = useState([]);
  const [error, seterror]=useState("");
  const [errorFieldIDValue, seterrorFieldIDValue]=useState(null);
  const [showErrorFields, setshowErrorFields] = useState([]);
  const [searchDivVisible, setsearchDivVisible] = useState(true);
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [numberOfRecord, setnumberOfRecord] = useState(100);
  const [displayOrderArry,setdisplayOrderArry] = useState([]);
  const [showDropdown, setshowDropdown] = useState(false);
  const [viewErrorFieldPopUp, setviewErrorFieldPopUp] = useState(false);
  const [isLoading,setisLoading] = useState(false);
  const [UpdateErrorFieldPopUp, setUpdateErrorFieldPopUp] = useState(false);
  const [addNewErrorField, setaddNewErrorField] = useState(false);
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [departments, setDepartments] = useState([]);
  const [SelectedClientLocation, setSelectedClientLocation] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [showErrorFieldsDetails, setshowErrorFieldsDetails] = useState([]);
  const [title, settitle] = useState("");
  const [Datacount, setDatacount] = useState(0);

  const companyDepartmentRef = useRef();
  const DisplayCriterion = useRef();
  const locationClientRef = useRef();
  const processSubProcessRef = useRef();
  const ErrorFieldNameref = useRef();
  const subProcessIDStringref = useRef();
  const displayOrderRef = useRef();
  const remarksRef = useRef();
  const IsDeletedRef = useRef();
  // const [clientName, setclientName] = useState("");
  // const [processName, setprocessName] = useState("");
  // const [subProcessName, setsubProcessName] = useState("");
  // const [company, setcompany] = useState("");
  // const[ department, setdepartment] = useState("");
  // const [location, setlocation] = useState("");
  let interval = null;
  let navigate = useNavigate();
  const location = useLocation();
const processingDashboardParam = location.state;
const userRoles = processingDashboardParam.userRoles;
let navData = [];
if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
  navData = processingDashboardParam.assignedWorkItemDetails;
} else {
  navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
}

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  useEffect(()=>{
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    FetchDepartmentbyGUID(); 
    GetErrorFields();
    for (let index = 1; index <= 30; index++) {
      setdisplayOrderArry(oldArray => [...oldArray, index]);}
    
  },[]);
  
  const OpenPopUp =(items) =>{
    seterror("");
    setviewErrorFieldPopUp(true)
    settitle(items.clientName+"~"+items.processName+"~"+items.subProcessName);
    ViewErrorFields(items.strSubProcessID);
  }
  const ClosePopUp =() =>{
    seterror("");
    setviewErrorFieldPopUp(false)
    //ViewErrorFields(rowData)
  }
  const OpenExistingEntries=(e)=>{
    seterror("");
    ViewErrorFields(e.target.value);
  }
  const GetErrorFields=()=>{
    seterror("");
    let SelectedDisplayCriterion = ""
    let Val = processingDashboardParam.selectedSubprocess
    if(Val != null)
    {
       setshowDropdown(true);
       SelectedDisplayCriterion = DisplayCriterion.current == undefined?"0":DisplayCriterion.current.value
    }
    let Val1 = "all"
    let Val2 = 0
    
    if(SelectedDisplayCriterion == "0")
    {
        Val1 = "subprocess"
        Val2 = processingDashboardParam.selectedSubprocess
    }
    else if(SelectedDisplayCriterion == "1")
    {
        Val1 = "process"
        Val2 = Math.floor(processingDashboardParam.selectedSubprocess/10000)*10000
    }
    else if(SelectedDisplayCriterion == "2")
    {
        Val1 = "client"
        Val2 = locationClientID
    }
    else if(SelectedDisplayCriterion == "3")
    {
        Val1 = "all"
        Val2 = 0
    }
    
    let body= {
      UserId: sessionStorage.getItem("GUID"),
      FilterKey : Val1,
      FilterValue : Val2   
  }
  let GetErrorFieldsURL= getAPIURL(REACT_APP_GETERRORFIELDS);
      let req1 = new Request(`${GetErrorFieldsURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
         body: JSON.stringify(body),
      });
      fetch(req1)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            
            setisLoadingFigma(false);
            
              setErrorFields(result.data._listErrorFields);
              setDatacount(result.data._listErrorFields.length);
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });

  }
  const ViewErrorFields=(item)=>{
    seterror("");
    
    setshowErrorFields([]);
    // settitle(item.clientName+"~"+item.processName+"~"+item.subProcessName);
    //OpenPopUp();
    let body= {
      SubProcessID : item,
  }
  let ViewErrorFieldsURL= getAPIURL(REACT_APP_VIEWERRORFIELDS);
      let req1 = new Request(`${ViewErrorFieldsURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
         body: JSON.stringify(body),
      });
      fetch(req1)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setshowErrorFields(result.data._listSubProcessErrorFields);
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });

  }
  const returnForm=()=>{
    ErrorFieldNameref.current.value="";
    displayOrderRef.current.value="Select";
    remarksRef.current.value=""
    IsDeletedRef.current.checked=false;
  }
  const DeleteErrorField=(rowData)=>{
    seterror("");
    //setshowErrorFields([]);
    //OpenPopUp();
    if(rowData.isDeleted)
        {
          addToast("Error Field- "+rowData.errorFieldName+" is already deleted.", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    return false;
        };
         if(window.confirm('Are you sure you want to delete the Error Field - '+ rowData.errorFieldName +' ?'))
      {
    let body= {
      ErrorFieldID : rowData.errorFieldID,
      UpdatedBy: rowData.updatedBy,
  }
  let DeleteErrorFieldsURL= getAPIURL(REACT_APP_DELETEERRORFIELDS);
      let req1 = new Request(`${DeleteErrorFieldsURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
         body: JSON.stringify(body),
      });
      fetch(req1)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            addToast("Error Fields deleted successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            ViewErrorFields(rowData);
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
      }
  }
  const DeleteErrorFieldsBySubProcessID=(rowData)=>{
    seterror("");
    //setshowErrorFields([]);
    //OpenPopUp();
    if(rowData.isDeleted)
        {
          addToast("Error Field- "+rowData.subProcessName+" is already deleted.", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    return false;
        };
         if(window.confirm('Are you sure you want to delete the Error Field - '+ rowData.subProcessName +' ?'))
      {
    let body= {
      SubProcessID: rowData.strSubProcessID,
      //StrCurrentUserID: rowData.strCurrentUserID,
      StrCurrentUserID:sessionStorage.getItem("PersonID")
  }
  let DeleteErrorFieldsBySubProcessIDURL= getAPIURL(REACT_APP_DELETEERRORFIELDSBYSUBPROCESSID);
      let req1 = new Request(`${DeleteErrorFieldsBySubProcessIDURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
         body: JSON.stringify(body),
      });
      fetch(req1)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            addToast("Error Fields deleted successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            GetErrorFields();
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
      }
  }
  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };
  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // sessionStorage.setItem(
          //   "SelctedSubprocessName",
          //   result.data[0].processSubProcess
          // );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          //GetActivitiesForSubprocess();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  useEffect(() => {
    if (runSaveTImer == true && interval == null) {
      interval = setInterval(() => {
        SaveTimerOnTick();
        //clearInterval(interval);
      }, 60000);
    }
    return () => {
      //  console.log("ending dashboard 1");
      clearInterval(interval);
    };
  }, [runSaveTImer]);
  const SaveTimerOnTick = () => {
    // console.log("entered in savetimeontick");
    let today = new Date();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    let currentdate =
      today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let strTime =
      currentdate + " " + hours + ":" + minutes + ":" + seconds + " " + ampm;

    let body = {
      timerStart: strTime,
      subProcessID:
        sessionStorage.getItem("ProcessId") == null
          ? ""
          : sessionStorage.getItem("ProcessId"),
      activityID:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakactivity")
          : sessionStorage.getItem("ActivityId") == null
          ? 0
          : sessionStorage.getItem("ActivityId"), //for home page
      workItemID: 0,
      employeeTimeUtilization:
        sessionStorage.getItem("empTimeUtilization") == "null" ||
        sessionStorage.getItem("empTimeUtilization") == "undefined" ||
        sessionStorage.getItem("empTimeUtilization") == null
          ? []
          : JSON.parse(sessionStorage.getItem("empTimeUtilization")),
      selectedEntitiesDetail:
        sessionStorage.getItem("selectedEntitiesDetail") == "null" ||
        sessionStorage.getItem("selectedEntitiesDetail") == "undefined" ||
        sessionStorage.getItem("selectedEntitiesDetail") == null
          ? {}
          : JSON.parse(sessionStorage.getItem("selectedEntitiesDetail")),
      breakStartTime:
        sessionStorage.getItem("breakStartTime") == "" ||
        sessionStorage.getItem("breakStartTime") == "undefined" ||
        sessionStorage.getItem("breakStartTime") == null
          ? ""
          : JSON.parse(sessionStorage.getItem("breakStartTime")),
      txtBreakComments:
        JSON.parse(sessionStorage.getItem("FetchBreakTimerOnLoadData"))
          .timerValue == 2
          ? sessionStorage.getItem("breakcomment")
          : "",
      timerValue: JSON.parse(
        sessionStorage.getItem("FetchBreakTimerOnLoadData")
      ).timerValue,
      personID: sessionStorage.getItem("PersonID"),
      tSaveTimerInterval: 60000,
    };

    let FetchBreakTimerOnLoadUrl = getAPIURL(REACT_APP_SAVETIMEONTICK);
    let req = new Request(`${FetchBreakTimerOnLoadUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result != null) {
          if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            let FetchBreakTimerOnLoadData = JSON.parse(
              sessionStorage.getItem("FetchBreakTimerOnLoadData")
            );
            FetchBreakTimerOnLoadData.timerStart = result.timerStart;
            FetchBreakTimerOnLoadData.timerValue = result.timerValue;
            FetchBreakTimerOnLoadData.tSaveTimerEnabled =
              result.tSaveTimerEnabled;
            FetchBreakTimerOnLoadData.btnStartWorkTimerDisabled =
              result.btnStartWorkTimerDisabled;
            // FetchBreakTimerOnLoadData.txtBreakCommentsEnabled =
            //   result.txtBreakCommentsEnabled;
            // FetchBreakTimerOnLoadData.ddlActivityEnabled =
            //   result.ddlActivityEnabled;
            FetchBreakTimerOnLoadData.btnShowBreakPanelDisabled =
              result.btnShowBreakPanelDisabled;
            // sessionStorage.setItem(
            //   "objdataview",
            //   JSON.stringify(result.data[0].objdataview)
            // );
            sessionStorage.setItem(
              "FetchBreakTimerOnLoadData",
              JSON.stringify(FetchBreakTimerOnLoadData)
            );
            sessionStorage.setItem(
              "empTimeUtilization",
              JSON.stringify(result.employeeTimeUtilization)
            );
          }
        }
        // else if(result.status === 401)
        // {
        //   navigate("/")
        // }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const popUpOpener = () =>{
    seterror("");
    
    setshowErrorFields([]);
    setaddNewErrorField(true);
    ViewErrorFields(processingDashboardParam.selectedSubprocess);
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    ); 
    FetchDepartmentbyGUID();
    // if(processingDashboardParam.selectedSubprocess!= null)
    // {
    //   displayOrderRef.current = 1;
    // }
  }
  const popUpCloser = () =>{
    seterror("");
    setaddNewErrorField(false);
  }
  const resetForm=()=>{
    companyDepartmentRef.current.value="Select";
    locationClientRef.current.value="Select";
    processSubProcessRef.current.value="Select";
    ErrorFieldNameref.current.value="";
    displayOrderRef.current.value="Select";
    remarksRef.current.value="";
    IsDeletedRef.current.checked=false;
  }
  const FetchLocationClientsByGuid = (e) => {
    
    seterror("");
    setshowErrorFields([]);
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setClientLocations([]);
    }
    if (e.target.value !== "Select") {
      
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };
      let loginURL =  getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };
  const FetchDepartmentbyGUID = () => {
    seterror("");
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };
  const FetchProcessSubProcessByGuid = (e) => {
    seterror("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
    }
    setSelectedLocation(e.target.value);
    setSelectedClientLocation(e.target.value.split("/")[1]);
    setshowErrorFields([]);
    // );
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value.split("/")[1],
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          setprocessSubprocess([]);
        } else {
          setprocessSubprocess([]);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };
  const GetSubProcessErrorFieldDetails=(item)=>{
    seterror("");
    
    seterrorFieldIDValue(item.errorFieldID);
    ViewErrorFields(item.strSubProcessID);
    let body= {
      SubProcessErrorFieldID: item.errorFieldID,
      IncludeDeleted : item.isDeleted,
      SubProcessID : item.strSubProcessID,
      UserID : sessionStorage.getItem("GUID"),
      }
      let GetSubProcErrorFieldURL= getAPIURL(REACT_APP_GET_SUBPROCERRORFIELDS);
      let req1 = new Request(`${GetSubProcErrorFieldURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
         body: JSON.stringify(body),
      });
      fetch(req1)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            
            setshowErrorFieldsDetails(result.data._listSubProcessErrorFieldBySubProcessIDs);
            
            companyDepartmentRef.current.value = result.data._listSubProcessErrorFieldBySubProcessIDs[0].company+"~"+result.data._listSubProcessErrorFieldBySubProcessIDs[0].department;
            //let CompanyDepartment = companyDepartmentRef.current;
            locationClientRef.current.value = result.data._listSubProcessErrorFieldBySubProcessIDs[0].location+"~"+result.data._listSubProcessErrorFieldBySubProcessIDs[0].clientName;
            processSubProcessRef.current.value = result.data._listSubProcessErrorFieldBySubProcessIDs[0].processName+"~"+result.data._listSubProcessErrorFieldBySubProcessIDs[0].subProcessName;
            ErrorFieldNameref.current.value = result.data._listSubProcessErrorFieldBySubProcessIDs[0].errorFieldName;
            displayOrderRef.current.value = result.data._listSubProcessErrorFieldBySubProcessIDs[0].displayOrder;
            remarksRef.current.value = result.data._listSubProcessErrorFieldBySubProcessIDs[0].remarks;
            IsDeletedRef.current.checked = result.data._listSubProcessErrorFieldBySubProcessIDs[0].isDeleted;
          }
         
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
  }
  const editerrorFieldPopUp =(rowData)=>{
    seterror("");
    setshowErrorFields([]);
    setUpdateErrorFieldPopUp(true);
    setviewErrorFieldPopUp(false);
    GetSubProcessErrorFieldDetails(rowData);
  }
  const hideEditErrorFieldPopUp =()=>{
    seterror("");
    setUpdateErrorFieldPopUp(false);
    GetErrorFields();
  }
  const CreateNewErrorField = () =>{
    
    seterror("");
    let body;
    let err={};
    let isSave = true;
    if(companyDepartmentRef.current.value=="Select"){
        err.CompDept = "Company~Department is required.";
        isSave= false;
    }
    if(locationClientRef.current.value=="Select"){
      err.LocClient = "Location~Client is required.";
      isSave = false;
    }
    if(subProcessIDStringref.current.value=="Select"){
      err.procsubproc = "Process~SubProcess is required.";
      isSave = false;
    }
    if(ErrorFieldNameref.current.value.length ==0 && ErrorFieldNameref.current.value==""){
      err.errorFieldName = "Error Field Name is required.";
      isSave = false;
    }
    if(displayOrderRef.current.value =="Select"){
      err.displayorder = "Display Order is required.";
      isSave = false;
    }
    seterror({...err});
    if(isSave)
    {
    let body= {
      SubProcessID : subProcessIDStringref.current.value,
      ErrorFieldName : ErrorFieldNameref.current.value,
      DisplayOrder : displayOrderRef.current.value,
      Remarks : remarksRef.current.value,
      IsDeleted : IsDeletedRef.current.checked,
      UpdatedBy : sessionStorage.getItem("PersonID")
      }
      let CreateSubProcErrorFieldURL= getAPIURL(REACT_APP_CREATESUBPROCERRORFIELDS);
      let req1 = new Request(`${CreateSubProcErrorFieldURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
         body: JSON.stringify(body),
      });
      fetch(req1)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            
            popUpCloser();
            //setshowErrorFields(result.data._listSubProcessErrorFieldBySubProcessIDs);
            addToast("Error Field created successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            GetErrorFields();
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
      }
  }
  const UpdateErrorField=()=>{

    let Err ={};
    let isUpdate = true;
    if(ErrorFieldNameref.current.value.length == 0 && ErrorFieldNameref.current.value == ""){
        Err.name = "Error Field Name is Required.";
       isUpdate= false;
      }
      if(displayOrderRef.current.value =="Select"){
        Err.displayorder = "Display Order is required.";
        isUpdate = false;
      }
      seterror({...Err});
      if(isUpdate){
    let body={
      //SubProcessID : subProcessIDStringref.current.value,
      ErrorFieldID : errorFieldIDValue,
      ErrorFieldName : ErrorFieldNameref.current.value,
      DisplayOrder : displayOrderRef.current.value,
      Remarks : remarksRef.current.value,
      IsDeleted : IsDeletedRef.current.checked,
      UpdatedBy : sessionStorage.getItem("PersonID")
    }
    let UpdateSubProcErrorFieldURL= getAPIURL(REACT_APP_UPDATESUBPROCERRORFIELDS);
    let req = new Request(`${UpdateSubProcErrorFieldURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
       body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          
         // popUpCloser();
          //setshowErrorFields(result.data._listSubProcessErrorFieldBySubProcessIDs);
          hideEditErrorFieldPopUp();
          addToast("Error Field updated successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          GetErrorFields();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
      }
  }
  const columns=[
    {
        title: "Client Name",
        field: "clientName",
      },
      {
        title: "Process Name",
        field: "processName",
      },
      {
        title: "Sub Process Name",
        field: "subProcessName",
      },
      {
        title: "Company",
        field: "company",
      },
      {
          title: "Department",
          field: "department",
      },
      {
        title: "Location",
        field: "location",
      }
  ];
  const columns1=[
    {
        title: "Error Field Name",
        field: "errorFieldName",
      },
      {
        title: "Display Order",
        field: "displayOrder",
      },
      {
        title: "Remarks",
        field: "remarks",
      },
      {
        title: "IsDeleted",
        field: "isDeleted",
        render: (rowData) => (            
            <>
              <input type="checkbox" id="isDeleted" disabled checked={rowData["isDeleted"] } />
            </>
          ),
      }
  ];
  const columns2=[
    {
        title: "Error Field Name",
        field: "errorFieldName",
      },
      {
        title: "Display Order",
        field: "displayOrder",
      },
  ];
  const NavElements = useMemo(() => {
    return (
      <>
        <Header pageName={"ErrorFields"} />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
    return (
      <>
      {NavElements}
      <GlobalStyles
        styles={{
          ".MuiTableCell-root": {
            padding: "5px !important",
            paddingbottom: "50px !important",
            "min-width": "10px !Important",
          },
          ".MuiTableFooter-root": {
            position: "absolute !Important",
          },
          ".MuiTypography-root": {
            "font-size": "1.0rem !important",
          },
          ".MuiCheckbox-root": { padding: "5px !important" },
          ".MuiSvgIcon-root": {
            width: "0.7em !important",
            height: "0.7em !important",

            "font-size": "1.5rem !important",
          },
          ".MuiIconButton-root": {
            "font-size": "1rem !important",
          },
          ".MuiTablePagination-toolbar": {
            width: "0px !important",
            // "max-width": "1000px !important",
          },
          ".MuiToolbar-regular": {
            "min-height": "0px !important",
          },
          ".MuiTableFooter-root": {
            position: "sticky!Important",
            "background-color": "#fff6f0 !Important",
            padding: "0px !important",
          },
        }}
      />
      
        <div className="pageBody">
          {searchDivVisible ? (
            <div className="dashBoxs">
              <div className="dashBoxs-header"style={{ maxHeight: "45px" }}>
                <div className="row align-items-center">
                <div class="col-6"><h6 className= "pageName"> MANAGE ERROR FIELDS</h6></div>
              <div className="col-3 textend">
                   {showDropdown?(
                      <div className="input-group input-group-sm">
                        <label className="input-group-text">Display-Criterion
                        </label>
                        <div>
                        </div> 
                        <select
                          className="form-select font-Standard-11"
                          onChange={GetErrorFields}
                           ref = {DisplayCriterion}
                        >
                          <option value={0}>Sub-Process</option>
                          <option value={1}>Process</option>
                          <option value={2}>Client</option>
                          <option value={3}>All</option>
                      </select>
                      </div>):null}
                      {/* {showDropdown?(
                        <div className="d-inline">
                          <div className="input-group input-group-sm">
                            <label 
                              className="input-group-text"
                              for="inputGroupSelect01">
                                Display-Criterion
                                </label>
                                <select
                          className="form-control form-control-sm"
                          onChange={GetErrorFields}
                           ref = {DisplayCriterion}
                        >
                          <option value={0}>Sub-Process</option>
                          <option value={1}>Process</option>
                          <option value={2}>Client</option>
                          <option value={3}>All</option>
                      </select>
                      </div>
                      </div>):null} */}
                         
                    </div>
              <div class="col-3 textend">
              <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
              <button
                    type="button"
                    className="btn btn-sm btn-primary ms-3"
                    //style = "float: right;"
                    //color = "#f194ff"
                     onClick={popUpOpener}
                >
                  + Add New Error Field
                </button>
                </div>
                </div>
                </div>
                </div>
                  {/* <div className="col-12">
                    <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                      <div className="d-inline">
                        <div className="input-group input-group-sm">
                                                   
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-right mt-2"></div>
                </div>
              </div> */}
              {isLoadingFigma ? (
                <LoadingSpinnerFigma />
              ) : (
                <div className="tableWrap">
                  <span
                    style={{
                      fontSize: "13px",
                      color: "blue",
                      marginLeft: "1rem"
                    }}
                  >
                    {Datacount} out of {Datacount} records displayed
                  </span>
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        actions={[
                          {
                            icon: () => <VisibilitySharpIcon />,
                            tooltip: "View Error Field(s)",
                            // onClick: (event, rowData) =>
                            // editErrorCategory(rowData),
                            // position: "row",
                            onClick: (event,rowData) => OpenPopUp(rowData),
                            position: "row"
                          },
                          {
                            icon: () => <DeleteIcon />,
                            tooltip: "Delete ",
                             onClick: (event, rowData) => DeleteErrorFieldsBySubProcessID(rowData),
                            position: "row",
                          }
                        ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          paging:false,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          //pageSizeOptions: [100, 200, 500, 1000],
                          exportButton: true,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "right",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important",
                          },
                        }}
                        // editable={{
                        //     onRowDelete: (oldData) =>
                        //       new Promise((resolve) => {
                        //         handleRowDelete(oldData, resolve);
                        //       }),
                        //   }}
                        title = "Manage Error Fields"
                        columns={columns}
                        icons={tableIcons}
                        
                        data={ErrorFields}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      
      <Footer />
      <Modal
        show={viewErrorFieldPopUp}
       // onBackdropClick={closeStatusReasonPopUp}
        onHide={ClosePopUp}
        dialogClassName="modal-100w"
        //onHide={viewSearchCloseAndRest}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Error Field for {title} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="pageBody pd-top-0">
                  <div className="tableWrap">
                     <div className="tableWrap-body">
                       <table className="table table-borderless MatTable">
                         {/* <ThemeProvider theme={theme}> */}
                         <MaterialTable
                          localization={{
                            pagination: {
                              //labelDisplayedRows: '{from}-{to} of {count}'
                            },
                            toolbar: {
                              // nRowsSelected: '{0} row(s) selected'
                              // exportCSVName: "Export as Excel",
                              // exportPDFName: "Export as pdf",
                            },
                            header: {
                              actions: "",
                            },
                          }}
                          actions={[
                            {
                              icon: () => <EditIcon />,
                              tooltip: "Edit Error Field",
                              onClick: (event, rowData) =>
                              editerrorFieldPopUp(rowData),
                              //onclick:{},
                              position: "row",
                            },
                            {
                              icon: () => <DeleteIcon />,
                              tooltip: "Delete",
                              onClick: (event, rowData) => DeleteErrorField(rowData),
                              position: "row",
                            }
                          ]}
                          editable={{
                          
                            // onRowDelete: (selectedRow) =>
                            //   new Promise((resolve) => {
                            //     DeleteStatusReasonDatas(selectedRow, resolve);
                            //     resolve();
                            //   }),
                          }}
                          options={{
                            headerStyle: {
                              position: "sticky",
                              top: "-5px",
                            },
                            maxBodyHeight: "420px",
                            //pagination:true,
                            //paginationType:"normal",
                            //paginationPosition: "bottom",
                            pageSize: numberOfRecord,
                            pageSizeOptions: [100, 200, 500, 1000],
                            exportButton: true,
                            sorting: true,
                            filtering: true,
                            search: false,
                            selection: false,
                            tableLayout: "auto",
                            overflowY: false,
                            showTitle: false,
                            toolbarButtonAlignment: "left",
                            headerStyle: {
                              height: 30,
                            },
                            rowStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontSize: "13px",
                              // whiteSpace: "nowrap",
                            },
                            tableLayout: "auto",
                            headerStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                              fontWeight: "inherit",
                              fontSize: "14px",
                              textAlign: "left !Important",
                            },
                          }}
                          columns={columns1}
                          icons={tableIcons}
                          data={showErrorFields}
                          components={{
                            Container: (props) => (
                              <Paper {...props} elevation={0} />
                            ),
                            Toolbar: (props) => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em",
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{
                                    fontSize: "5px",
                                  }}
                                />
                                
                              </div>
                            ),
                          }}
                        />
                        {/* </ThemeProvider> */}
                      </table>
                    </div>
                  </div>
                  </div>

        </Modal.Body>
      </Modal>
      <Modal
        show={addNewErrorField}
       // onBackdropClick={closeStatusReasonPopUp}
        onHide={popUpCloser}
        dialogClassName="modal-100w"
        //onHide={viewSearchCloseAndRest}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Error Field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="dashBoxs mb-3">   
              <div className="formWrap">
                <div className="fieldSet">
                  {/* <h4 className="mb-4">Add New Error Field</h4> */}

                  <div className="row align-items-end">
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="mandatory">Company-Department
                        <span id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span>
                        </label>
                        <div>
                        <span  style = {{color:'red'}}>{error.CompDept}</span>
                        </div>
                        
                        <select
                          className="form-control form-control-sm"
                           onChange={FetchLocationClientsByGuid}
                          ref={companyDepartmentRef}
                        >
                          <option>Select</option>
                        {departments.map((department) => (
                          <option
                            value={
                              department.companyDepartment +
                              "/" +
                              department.departmentIDString
                            }
                            selected={
                              department.departmentIDString ==
                              companyDeparmentID
                            }
                          >
                            {department.companyDepartment}
                          </option>
                        ))}
                      </select>
                      </div>
                    </div>
                    <div className="col-4" >
                      <div className="mb-3">
                        <label className="mandatory">Location-Client<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                                  <div>
                        <span style = {{color:'red'}}>{error.LocClient}</span></div>
                        <select
                          className="form-control form-control-sm"
                           onChange={FetchProcessSubProcessByGuid}
                           ref ={locationClientRef}
                        >
                          <option>Select</option>
                          {clientLocations.map((location) => (
                            <option
                              value={
                                location.locationClient +
                              "/" +
                              location.clientIDString
                              }
                              selected={
                                location.clientIDString == locationClientID
                              }
                            >
                              {location.locationClient}
                            </option>
                          ))}
                        </select> 
                      </div>
                    </div>
                    <div className="col-4" >
                      <div className="mb-3">
                      <label className="mandatory">Process-SubProcess<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      <div>
                      <span style = {{color:'red'}}>{error.procsubproc}</span>
                      </div>
                      <select
                        className="form-control form-control-sm"
                        ref={subProcessIDStringref}
                        onChange={OpenExistingEntries}
                      >
                      <option>Select</option>
                        {processSubprocess.map((subprocess) => (
                          <option
                            value={subprocess.subProcessIDString}
                            selected={
                              subprocess.subProcessIDString ==
                              processSubProcessID
                            }
                            
                          >
                            {subprocess.processSubProcess}
                          </option>
                        ))}
                      </select>
                      </div>
                    </div>
                    </div>
                    <div className="row align-items-end">
                    <div className="col-3">
                      <div className="mb-2">
                      <label className = "mandatory">Error Field Name<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      <div>
                      <span style = {{color:'red'}}>{error.errorFieldName}</span>
                      </div>
                      {/* <textarea name="Text1" cols="38" rows="2" maxLength="4000" ref={statusReasonRef}></textarea> */}
                      <textarea className="form-control form-control-sm" cols="38" rows="2" maxLength="4000" ref={ErrorFieldNameref} ></textarea> 
                      </div>
                    </div>
                    <div className="col-3">
                    <div className="mb-5">
                        <label className="mandatory">Display Order
                        <span
                              id=""
                              className="mandatory"
                              style={{ color: "#ff0000" }}
                        >
                          *
                        </span>
                        </label>
                        <div>
                      <span style = {{color:'red'}}>{error.displayorder}</span>
                      </div>
                        <div>
                        </div> 
                        <select
                          className="form-control form-control-sm"
                          // onChange={GetStatusesReasons}
                           ref = {displayOrderRef}
                        >
                          <option>Select</option>
                          {displayOrderArry.map(state => (
                          <option value={state}>{state}</option>
                          ))}
                      </select>
                      </div>
                      </div>
                    <div className="col-3">
                      <div className="mb-2">
                      <label className="mandatory">Remarks</label>
                       <textarea className="form-control form-control-sm" cols="38" rows="2" maxLength="4000" ref = {remarksRef}></textarea> 
                      </div>
                      </div>
                    
                    <div className ="col-1" />
                    
                    <div className="col-2" style={{ "height": "81px" }}>
                      <div className="mb-1">
                      <label className="form-label">Is Deleted</label>
                      <input type="checkbox" id="isDeleted" ref={IsDeletedRef}  />
                      
                      </div>
                    </div>
                      </div>
                </div>
                {/* <hr className="mb-4" /> */}
                <hr className="mb-4" />
                <div className="fieldSet">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2">
                        
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={resetForm}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                          onClick={() => {
                            //OpenExistingEntries(subProcessIDStringref);
                            // saveStatusReasonDatas();
                            
                            // GetStatusReasonBySubProcessID(subProcessIDStringref.current.value);
                            CreateNewErrorField();  
                          }}
                        >
                          Save & Continue
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          // ref={ButtonTextRef}
                          onClick={() => {
                            //setaddNewStatusReason(false);
                            //saveStatusReasonDatas();
                            //GetStatusesReasons();
                            CreateNewErrorField();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: "",
                          },
                        }}
                        // actions={[
                        //   {
                        //     icon: () => <VisibilitySharpIcon />,
                        //     tooltip: "View Status Reason",
                        //     //onClick: (event, rowData) =>
                        //     //viewStatusReason(rowData),
                        //    // onclick:(event, rowData)=>showStatusReason(rowData),
                            
                        //       onClick: (event, rowData) => showStatusReason(rowData),
                        //     position: "row",
                        //   },
                        //   {
                        //     icon: () => <DeleteIcon />,
                        //     tooltip: "Delete Status Reason",
                        //     onClick: (event, rowData) => DeleteStatusReason(rowData),
                        //     position: "row",
                        //   }
                        // ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          pageSizeOptions: [100, 200, 500, 1000],
                          exportButton: false,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important",
                          },
                        }}
                        // editable={{
                        //     onRowDelete: (oldData) =>
                        //       new Promise((resolve) => {
                        //         handleRowDelete(oldData, resolve);
                        //       }),
                        //   }}
                        columns={columns2}
                        icons={tableIcons}
                        data={showErrorFields}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                              
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              </div>           
          </div>


        </Modal.Body>
      </Modal>
      <Modal
        show={UpdateErrorFieldPopUp}
       // data={editstatusReasonData}
       // onBackdropClick={closeStatusReasonPopUp}
        onHide={hideEditErrorFieldPopUp}
        dialogClassName="modal-100w"
        //onHide={viewSearchCloseAndRest}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Error Field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>  
        <div className="dashBoxs mb-3">   
              <div className="formWrap">
                <div className="fieldSet">
                  {/* <h4 className="mb-4">Edit Error Field</h4> */}

                  <div className="row align-items-end">
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="mandatory" >Company-Department<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                        {/* <select
                          className="form-control form-control-sm"
                         // onChange={FetchLocationClientsByGuid}
                          //ref={companyDepartmentNameRef}
                          //onChange={getWorkitemId}
                          //data = {editstatusReasonData}
                            Value= {companyAndDepartment}
                        >
                      </select> */}
                      {/* <textarea className="input-disabled" readonly="readonly" name="Text1" cols="40" rows="2" maxLength="4000" ref={departmentRef} disabled></textarea> */}
                      <input className="form-control form-control-sm"  ref={companyDepartmentRef} disabled></input>
                      </div>
                    </div>
                    <div className="col-4" >
                      <div className="mb-3">
                        <label className="mandatory">Location-Client<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                        {/* <select
                          className="form-control form-control-sm"
                          //onChange={FetchProcessSubProcessByGuid}
                          ref ={locationClientNameRef}
                        >
                        </select> */}
                        {/* <textarea readonly="readonly" name="Text1" cols="40" rows="2" maxLength="4000" ref={locationRef} disabled></textarea> */}
                        <input className="form-control form-control-sm" ref ={locationClientRef} disabled></input>
                      </div>
                    </div>
                    <div className="col-4" >
                      <div className="mb-3">
                      <label className="mandatory">Process-SubProcess<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      {/* <select
                        className="form-control form-control-sm"
                        //onChange={FetchLockedWorkitemsBySubProcessID}
                        ref={subProcessIDStringref}
                      >
                      </select> */}
                      {/* <textarea readonly="readonly" name="Text1" cols="40" rows="2" maxLength="4000" ref = {processSubprocessref} disabled></textarea> */}
                      <input className="form-control form-control-sm" ref={processSubProcessRef} disabled></input>
                      </div>
                    </div>
                    
                    <div className="col-3">
                      <div className="mb-2">
                      <label className="mandatory">Error Field Name<span
                                    id=""
                                    className="mandatory"
                                    style={{ color: "#ff0000" }}
                                  >
                                    *
                                  </span></label>
                      <span  style={{color: 'red'}}>{error.name}</span>
                      <textarea className="form-control form-control-sm" cols="38" rows="2" maxLength="4000" ref = {ErrorFieldNameref}></textarea>
                      </div>
                    </div>
                    <div className="col-3">
                    <div className="mb-5">
                        <label className="mandatory">Display Order
                        <span
                              id=""
                              className="mandatory"
                              style={{ color: "#ff0000" }}
                        >
                          *
                        </span>
                        </label>
                        <div>
                        </div> 
                        <select
                          className="form-control form-control-sm"
                           ref = {displayOrderRef}
                        >
                          <option>Select</option>
                          {displayOrderArry.map(state => (
                          <option value={state}>{state}</option>
                          ))}
                      </select>
                      </div>
                      </div>

                    <div className="col-3">
                      <div className="mb-2">
                      <label className="mandatory">Remarks</label>
                      {/* <textarea name="Text1" cols="40" rows="2" maxLength="4000" ref = {Remarksref}></textarea> */}
                      <textarea className="form-control form-control-sm" cols="40" rows="2" maxLength="4000" ref ={remarksRef}></textarea>
                      </div>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-2"style={{ "height": "81px" }}>
                      <div className="mb-1">
                      <label className="form-label">Is Deleted</label>
                      <input type="checkbox" id="isDeleted" ref={IsDeletedRef} />
                      </div>
                    </div>
                      </div>
                </div>
                <hr className="mb-4" />
                <div className="fieldSet">
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-2">
                        
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-2 text-end">
                        <button
                          type="button"
                          className="btn btn-sm btn-link"
                           onClick={returnForm}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-primary ms-3"
                          // ref={ButtonTextRef}
                          onClick={() => {
                            UpdateErrorField();
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      {/* <ThemeProvider theme={theme}> */}
                      <MaterialTable
                        localization={{
                          pagination: {
                            //labelDisplayedRows: '{from}-{to} of {count}'
                          },
                          toolbar: {
                            // nRowsSelected: '{0} row(s) selected'
                            // exportCSVName: "Export as Excel",
                            // exportPDFName: "Export as pdf",
                          },
                          header: {
                            actions: "",
                            
                          },
                        }}
                        // actions={[
                        //   {
                        //     icon: () => <VisibilitySharpIcon />,
                        //     tooltip: "View Status Reason",
                        //     //onClick: (event, rowData) =>
                        //     //viewStatusReason(rowData),
                        //    // onclick:(event, rowData)=>showStatusReason(rowData),
                            
                        //       onClick: (event, rowData) => showStatusReason(rowData),
                        //     position: "row",
                        //   },
                        //   {
                        //     icon: () => <DeleteIcon />,
                        //     tooltip: "Delete Status Reason",
                        //     onClick: (event, rowData) => DeleteStatusReason(rowData),
                        //     position: "row",
                        //   }
                        // ]}
                        options={{
                          headerStyle: {
                            position: "sticky",
                            top: "-5px",
                          },
                          maxBodyHeight: "420px",
                          //pagination:true,
                          //paginationType:"normal",
                          //paginationPosition: "bottom",
                          pageSize: numberOfRecord,
                          pageSizeOptions: [100, 200, 500, 1000],
                          //exportButton: true,
                          sorting: true,
                          filtering: true,
                          search: false,
                          selection: false,
                          tableLayout: "auto",
                          overflowY: false,
                          showTitle: false,
                          toolbarButtonAlignment: "left",
                          headerStyle: {
                            height: 30,
                          },
                          rowStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontSize: "13px",
                            // whiteSpace: "nowrap",
                          },
                          tableLayout: "auto",
                          headerStyle: {
                            fontFamily: '"Yantramanav", sans-serif',
                            fontWeight: "inherit",
                            fontSize: "14px",
                            textAlign: "left !Important",
                          },
                        }}
                        // editable={{
                        //     onRowDelete: (oldData) =>
                        //       new Promise((resolve) => {
                        //         handleRowDelete(oldData, resolve);
                        //       }),
                        //   }}
                        columns={columns2}
                        icons={tableIcons}
                        data={showErrorFields}
                        components={{
                          Container: (props) => (
                            <Paper {...props} elevation={0} />
                          ),
                          Toolbar: (props) => (
                            <div
                              style={{
                                backgroundColor: "rgb(206 206 206)",
                                fontSize: "0.75em",
                              }}
                            >
                              <MTableToolbar
                                {...props}
                                style={{
                                  fontSize: "5px",
                                }}
                              />
                              
                            </div>
                          ),
                        }}
                      />
                      {/* </ThemeProvider> */}
                    </table>
                  </div>
                </div>
              </div>           
          </div>
          </>
          )}
        </Modal.Body>
      </Modal>
      
    </>
  );
};

export default ErrorFields;
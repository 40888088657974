import React, {
    useState,
    useEffect
} from "react";
import {
    Paper
} from "@material-ui/core";
import ReactTooltip from 'react-tooltip';
import NavDataContext from "../../../../contexts/NavDataContext";
import { useToasts } from "react-toast-notifications";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import { useLocation } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import {
    DownloadExcel,
    ArrayWithoutExtraColumn
  } from "../../Comman/ExcelFileDownload";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
const QueueManagementDashboard = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const { addToast } = useToasts();
    const processingDashboardParam = location.state;
    const [refreshTime, setRefreshTime] = useState(600);
    const [selectedTimezone, setSelectedTimezone] = useState('Europe/London');
    const [selectedTimezoneLabel,setSelectedTimezoneLabel] = useState('BST')
    const [requestDate, setRequestDate] = useState(new Date());
    const [isHovered, setIsHovered] = useState(false);
    const [queueManagementReportData, setQueueManagementReportData] = useState([]);
    const [tableVisibility,setTableVisibility] = useState(false);
    const [currentTime, setCurrentTime] = useState('');
    const { REACT_APP_API_URL } = process.env
    const { REACT_APP_GetQueueManagementDetailsReport } = process.env

    const selectedSubprocess = processingDashboardParam.selectedSubprocess;
    const nav = processingDashboardParam.nav;
    const userRoles = processingDashboardParam.userRoles;
    const selectedWorkItemDetails = processingDashboardParam.selectedWorkItemDetails;
    let currentAcitvity = "";
    if (typeof processingDashboardParam.row.activityName !== "undefined") {
        currentAcitvity = processingDashboardParam.row.activityName;
    } else {
        currentAcitvity = processingDashboardParam.currentAcitvity;
    }
    let navData = [];
    if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
        navData = processingDashboardParam.assignedWorkItemDetails;
    } else {
        navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
    }

    function convertToISOString(dateString) {
        const dateObject = new Date(dateString);
        dateObject.setMinutes(dateObject.getMinutes());
        return dateObject.toISOString();
      }
    const getAPIURL = (api) => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
    };
   
    const timezones = [
        { label: 'BST', value: 'Europe/London' },
        { label: 'Alaska', value: 'America/Anchorage' },
        { label: 'Atlantic', value: 'America/Halifax' },
        { label: 'Central', value: 'America/Chicago' },
        { label: 'Eastern', value: 'America/New_York'  },
        { label: 'Hawaii', value: 'Pacific/Honolulu' },
        { label: 'Mountain', value: 'America/Denver' },
        { label: 'Pacific', value: 'America/Los_Angeles' }
    ];

    const columns = [
        {
            title: "Process Name",
            field: "processName",
        },
        {
            title: "SubProcess Name",
            field: "subProcessName",
        },
        {
            title: "Bucket",
            field: "bucket",
        },
        {
            title: "Opening",
            field: "opening",
        },
        {
            title: "Received",
            field: "received",
        },
        {
            title: "Live Count",
            field: "liveCount",
        },
        {
            title: "Staff Count",
            field: "staffCount",
        },
        {
            title: "Status",
            field: "isStatus",
        }
    ];

    for (let i = 5; i >= 1; i--) {
        const previousDate = new Date();
        previousDate.setDate(previousDate.getDate() - i);
        const formattedDate = previousDate.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric"
        });
        columns.push({
            title: formattedDate,
            field: `day${i}`
        });
    }


    const buttonStyle = {
        backgroundColor: isHovered ? '#FB4E0B' : 'transparent',
        color: isHovered ? '#fff' : '#FB4E0B',
        border: '1px solid #FB4E0B',
        padding: '5px 10px',
        cursor: 'pointer',
    };
 
    const GetQueueManagementDetailsReport = () => {
        
        let body = {
            timeZone: selectedTimezoneLabel,
            date: convertToISOString(currentTime),
            subprocessID: sessionStorage.getItem("ProcessId")

        };
        let loginUrl = getAPIURL(REACT_APP_GetQueueManagementDetailsReport);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status == 200) {
                    // console.log(result.data);
                    setQueueManagementReportData(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    useEffect(() => {
        updateCurrentTime();
        const intervalId = setInterval(handleRefreshPage, refreshTime * 1000);

        return () => clearInterval(intervalId); 
    }, [refreshTime,selectedTimezone]); 
    const handleExporttoExcel = () => {
        DownloadExcel(queueManagementReportData, "Queue Management Details");
    }
    const handleRefreshButtonClick = () => {
        handleRefreshPage();
    }

    const handleRefreshTimeChange = (e) => {
        let value = parseInt(e.target.value);
        if (value < 10) {
          value = 10; 
        }
        setRefreshTime(value);
      }
    const handleGenerateReport = () => {
        GetQueueManagementDetailsReport();
        setTableVisibility(true);
    }
    const handleRefreshPage = () => {
        GetQueueManagementDetailsReport();

    }
    const handleChange = (e) => {
        
        const matchingTimezone = timezones.find(tz => tz.value === e.target.value);
        if (matchingTimezone) {
            setSelectedTimezoneLabel(matchingTimezone.label);
        }
        setSelectedTimezone(e.target.value);
    };

    const updateCurrentTime = () => {
        const options = {
            hour12: true, 
            timeZone: selectedTimezone, 
            day: '2-digit', 
            month: '2-digit', 
            year: 'numeric', 
        };
        const currentDate = new Date().toLocaleTimeString('en-US', options);
        setCurrentTime(currentDate);
    };


    return (
        <>
            <Header onpreferenceShow={null} pageName="Queue Management Dashboard" />
            <ApplicationUserContext.Provider
                value={processingDashboardParam.userRoles}
            >
                <DashboardContext.Provider value={processingDashboardParam.nav}>
                    <NavDataContext.Provider value={navData}>
                        <Nav selectedMenu="admin" />
                    </NavDataContext.Provider>
                </DashboardContext.Provider>
            </ApplicationUserContext.Provider>

            <div className="centerSection">
                <div className="pageBody">
                    <div className="formWrap">
                        <div className="dashBoxs mb-3">
                            <div className="row queue-marginleft">
                                <div className="col-2">
                                    <label className="form-label" htmlFor="timezone">Timezone</label>
                                    <select className="form-control form-control-sm mb-3" id="timezone" value={selectedTimezone} onChange={handleChange}>
                                        {timezones.map((timezone, index) => (
                                            <option key={index} value={timezone.value}>{timezone.label}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-2">
                                    <div className="mb-3">
                                        <label className="form-label"> Request Date </label>
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            selected={requestDate}
                                            onChange={(date) => {
                                                setTableVisibility(false)
                                                setRequestDate(date)
                                                }}
                                            dateFormat="MM/dd/yyyy" 
                                            placeholderText="Select a date"
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="mt-4 generate-report">
                                        <button
                                            style={buttonStyle}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}

                                            type="button"
                                            className="btn btn-sm btn-outline-primary ms-2"
                                            onClick={handleGenerateReport}
                                        >
                                            <b className="icon-edit"></b> Generate Report
                                        </button>
                                    </div>
                                </div>
                                <div className="col-2">
                                <label className="form-label" htmlFor="refreshTime">Current {selectedTimezoneLabel} Time </label>
                                {currentTime}
                                </div>
                                <div className="col-2 text-right">
                                    <label className="form-label" htmlFor="refreshTime">Refresh Time in sec.</label>
                                    <input
                                        className="form-control form-control-sm"
                                        type="number"
                                        id="refreshTime"
                                        name="refreshTime"
                                        value={refreshTime}
                                        onChange={handleRefreshTimeChange}
                                    />
                                </div>
                                <div className="col-2 d-flex refresh-icon">
                                    <button
                                        className="material-symbols-outlined submenu-icon mb-1 custom-refresh-button"
                                        onClick={handleRefreshButtonClick}
                                        data-tip="Click here to Refresh"
                                    >
                                        Refresh
                                    </button>
                                    <ReactTooltip />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formWrap">
                        <div className="dashBoxs mb-3">
                            <div className="tableWrap">
                                <div className="tableWrap-body">
                                    {tableVisibility ? <table className="table table-borderless MatTable">
                                 
                                        <MaterialTable
                                            localization={{
                                                pagination: {
                                                   
                                                },
                                                toolbar: {
                                                   
                                                },
                                                header: {
                                                    actions: "",
                                                },
                                            }}
                                            actions={[
                                               
                                            ]}
                                            options={{
                                                headerStyle: {
                                                    position: "sticky",
                                                    top: "-5px",
                                                },
                                                maxBodyHeight: "420px",
                                                pageSizeOptions: [10, 20, 50, 100],
                                                exportButton: false,
                                                sorting: true,
                                                filtering: true,
                                                search: false,
                                                selection: false,

                                                overflowY: false,
                                                showTitle: false,
                                                toolbarButtonAlignment: "left",
                                                headerStyle: {
                                                   
                                                },
                                                cellStyle: {
                                                    width: 200,
                                                    minWidth: 200,
                                                    whiteSpace: "Normal",
                                                    warap: true,

                                                },
                                                rowStyle: {
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                   
                                                    whiteSpace: "wrap",
                                                },
                                                headerStyle: {
                                                 
                                                },
                                            }}
                                          
                                            columns={columns}
                                            icons={tableIcons}
                                            data={queueManagementReportData}
                                            components={{
                                                Container: (props) => (
                                                    <Paper {...props} elevation={0} />
                                                ),
                                                Toolbar: (props) => (
                                                    <div
                                                        style={{
                                                            backgroundColor: "rgb(206 206 206)",
                                                            fontSize: "0.75em",
                                                        }}
                                                    >
                                                        <MTableToolbar
                                                            {...props}
                                                            style={{
                                                                fontSize: "5px",
                                                            }}
                                                        />
                                                    </div>
                                                ),
                                            }}
                                        />
                                    </table> :null}

                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="btn btn-primary ms-3"
                        onClick={handleExporttoExcel}
                    >
                        Export to Excel
                    </button>
                    <Footer />
                </div>
            </div>
        </>
    );

}

export default QueueManagementDashboard;
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import UnsecuredApp from "./cws/containers/pages/UnsecuredApp";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
//import store, { persistor } from "./redux/store";
import { store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

// Won't render the application if WebApp is under Clickjacking attack

const msalInstance = new PublicClientApplication(msalConfig);
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
  }
});
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App instance={msalInstance} />);

if ((window.self === window.top) || (document.location.search =="?SSOAuth=Yes")) {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          {/* <PersistGate loading={null} persistor={persistor}> */}
            <App instance={msalInstance}/>
          {/* </PersistGate> */}
        </Provider>
      </Router>
    </React.StrictMode>,

    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <UnsecuredApp />
    </React.StrictMode>,

    document.getElementById("root")
  );
}

reportWebVitals();

import React from "react";
import "./App.css";
import GridLoader from "react-spinners/GridLoader";

export default function LoadingSpinnerFigma() {
  return (
    <body>
      <div className="containerfigma">
        <GridLoader
          color="#FB4E0B"
          loading="true"
          cssOverride={{
            display: "block",
            margin: "0 auto",
            borderColor: "red",
          }}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </body>
  );
}

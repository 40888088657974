import LoadingSpinner from "../../../LoadingSpinner";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";

import { ToastProvider, useToasts } from "react-toast-notifications";

const { REACT_APP_LOGIN } = process.env;
const { REACT_APP_API_URL } = process.env;
const { REACT_APP_NAME } = process.env;
const getAPIURL = (api) => {
  let apiURL = REACT_APP_API_URL;
  return `${apiURL}${api}`;
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function SupportForm() {
  let navigate = useNavigate();
  const { addToast } = useToasts();
  const [username, set_username] = useState("");
  const [password, set_password] = useState("");
  const [isLoading, set_IsLoading] = useState(false);
  const [isShow, set_isShow] = useState(false);
  const [roles, setRoles] = useState([]);
  const [error_msg, set_error_msg] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [is_error, set_is_error] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [is_authenticated, set_is_authenticated] = useState(false);
  //roles
  const [is_admin, set_is_admin] = useState(false);
  const [is_superadmin, set_is_superadmin] = useState(false);
  const [is_Configuration, set_is_Configuration] = useState(false);
  const [is_workItemFieldadmin, set_is_workItemFieldadmin] = useState(false);
  const [is_instructionauthor, set_is_instructionauthor] = useState(false);
  const [is_instructionapprover, set_is_instructionapprover] = useState(false);
  const [is_manageUsers, set_is_manageUsers] = useState(false);
  const [is_report, set_is_report] = useState(false);
  const [is_activities, set_is_activities] = useState(false);
  const [is_upload, set_is_upload] = useState(false);
  const [is_client, set_is_client] = useState(false);
  const [is_exceptions, set_is_exceptions] = useState(false);
  const [is_complete, set_is_complete] = useState(false);
  const [is_vendor, set_is_vendor] = useState(false);
  const [is_workFlowstatechange, set_is_workFlowstatechange] = useState(false);
  const [is_reassignment, set_is_reassignment] = useState(false);
  const [is_adhocEditing, set_is_adhocEditing] = useState(false);
  const [is_undoActivity, set_is_undoActivity] = useState(false);
  const [is_clientDailyInfo, set_is_clientDailyInfo] = useState(false);
  const [is_workitemBulkUpdation, set_is_workitemBulkUpdation] =
    useState(false);
  const [is_cwsRestrictedSearch, set_is_cwsRestrictedSearch] = useState(false);

  var settings = {
    dots: true,
    infinite: true,
    speed: 1600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    adaptiveHeight: false,
  };

  const ChangeHandle = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      set_password(value);
    }
    if (name === "username") {
      set_username(value);
    }
  };

  const ForceLoginHandle = (username, password) => {
    set_IsLoading(true);
    setFormErrors(validate(username, password));
    setIsSubmit(true);
    if (username != "" && password != "") {
      set_isShow(true);
      let UserToken = "";
      let indexp = 0;
      password.split("").forEach((character) => {
        UserToken = UserToken + "=c" + password.charCodeAt(indexp);
        indexp = indexp + 1;
      });
      var Buffer = require("buffer/").Buffer;

      let buff = new Buffer(UserToken);
      let encryptedUserToken = buff.toString("base64");
      //let encryptedpassword = Buffer.from(password, "base64");
      let body = {
        username: username,
        UserToken: encryptedUserToken,
        applicationname: REACT_APP_NAME,
        ForceLogin: true,
      };
      let loginUrl = getAPIURL(REACT_APP_LOGIN);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          set_IsLoading(false);
          if (result.status == 500) {
            set_is_authenticated(false);
            set_is_error(true);
            set_isShow(false);
            addToast(result.message, {
              appearance: "error",
              autoDismiss: true,
            });
          } else if (result.status != 200) {
            set_is_authenticated(false);
            set_is_error(true);
            set_isShow(false);
            addToast("Incorrect Username or Password!", {
              appearance: "error",
              autoDismiss: true,
            });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            if (result.status == 200) {
              addToast(result.message, {
                appearance: "info",
                autoDismiss: true,
              });
            }

            //If any change in this portion adding value in session.
            //Please add this session change to SSOLogin page also --Omkar

            sessionStorage.setItem("authorizationToken", result.data[0].token);
            sessionStorage.setItem("RefreshToken", result.data[0].refreshToken);
            if (result.data[0].isMultipleSubProcessAllowed) {
              sessionStorage.setItem("is_userPreference", true);
            } else {
              sessionStorage.setItem("is_userPreference", false);
            }
            const rolesName = result.data[0].rolesName;
            setRoles(rolesName);
            // Navigation Blacklisting according to current development- Aman
            sessionStorage.setItem("rolesList", JSON.stringify(rolesName));
            //Added by Gajendra  for local timezone name
            var timezonename = new Date()
              .toLocaleTimeString("en-us", { timeZoneName: "long" })
              .split(" ")[2];
            sessionStorage.setItem("LocalTimezoneName", timezonename);

            for (let i = 0; i < rolesName.length; i++) {
              if (rolesName[i].roleName === "Admin1")
                sessionStorage.setItem("is_admin", true);
              else if (
                rolesName[i].roleName === "Super Admin" ||
                rolesName[i].roleName === "SuperAdmin"
              )
                sessionStorage.setItem("is_superadmin", true);
              else if (rolesName[i].roleName === "One And Done")
                sessionStorage.setItem("is_OneandDone", true);
              else if (rolesName[i].roleName === "Upload")
                sessionStorage.setItem("is_upload", true);
              else if (
                rolesName[i].roleName === "Activities" ||
                rolesName[i].roleName === "CWSActivities"
              )
                sessionStorage.setItem("is_activities", true);
              else if (rolesName[i].roleName === "Client")
                sessionStorage.setItem("is_client", true);
              else if (rolesName[i].roleName === "Exceptions")
                sessionStorage.setItem("is_exceptions", true);
              else if (rolesName[i].roleName === "Complete")
                sessionStorage.setItem("is_complete", true);
              else if (rolesName[i].roleName === "Vendor")
                sessionStorage.setItem("is_vendor", true);
              else if (
                rolesName[i].roleName === "Report" ||
                rolesName[i].roleName === "Reports"
              )
                sessionStorage.setItem("is_report", true);
              else if (rolesName[i].roleName === "Manage WorkItems")
                sessionStorage.setItem("is_manageWorkItems", true);
              else if (
                rolesName[i].roleName === "CWSSearch" ||
                rolesName[i].roleName === "CWSRestrictedSearch"
              )
                sessionStorage.setItem("is_search", true);
              else if (rolesName[i].roleName === "Upload and Assignment")
                sessionStorage.setItem("is_UploadandAssignment", true);
              else if (rolesName[i].roleName === "Queue Management")
                sessionStorage.setItem("is_QueueManagement", true); 
              else if (rolesName[i].roleName === "Bucket Management")
                sessionStorage.setItem("is_BucketManagement", true);
              else if (rolesName[i].roleName === "Real Time Dashboard")
                sessionStorage.setItem("is_RealTimeDashboard", true);
              else if (rolesName[i].roleName === "Configuration")
                sessionStorage.setItem("is_Configuration", true);
              else if (rolesName[i].roleName === "User Classification Mapping")
                sessionStorage.setItem("is_UserClassificationMapping", true);
              else if (rolesName[i].roleName === "User Classification List")
                sessionStorage.setItem("is_UserClassificationList", true);
              else if (rolesName[i].roleName === "Workitem Fields Rules")
                sessionStorage.setItem("is_WorkitemFieldsRules", true);
            }
            set_is_authenticated(true);
            sessionStorage.setItem("is_Authenticated", true);
            sessionStorage.setItem("authorizationToken", result.data[0].token);
            sessionStorage.setItem("UserName", username);
            sessionStorage.setItem("UserFullName", result.data[0].fullName);
            sessionStorage.setItem("UserEmail", result.data[0].email);
            sessionStorage.setItem("GUID", result.data[0].guid);
            sessionStorage.setItem("PersonID", result.data[0].personID);
            sessionStorage.setItem("PersonType", result.data[0].personType);
            sessionStorage.setItem(
              "HRISEmployeeID",
              result.data[0].hrisEmployeeID
            );

            sessionStorage.setItem(
              "EmployeeVersionID",
              result.data[0].employeeVersionID
            );
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    } else {
      set_isShow(set_isShow);
    }
  };
  const LoginHandle = (e) => {
    set_IsLoading(true);
    e.preventDefault();
    setFormErrors(validate(username, password));
    setIsSubmit(true);
    if (username != "" && password != "") {
      set_isShow(true);
      let UserToken = "";
      let indexp = 0;
      password.split("").forEach((character) => {
        UserToken = UserToken + "=c" + password.charCodeAt(indexp);
        indexp = indexp + 1;
      });
      var Buffer = require("buffer/").Buffer;

      let buff = new Buffer(UserToken);
      let encryptedUserToken = buff.toString("base64");
      //let encryptedpassword = Buffer.from(password, "base64");
      let body = {
        username: username,
        UserToken: encryptedUserToken,
        applicationname: REACT_APP_NAME,
        ForceLogin: false,
      };
      let loginUrl = getAPIURL(REACT_APP_LOGIN);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          set_IsLoading(false);
          if (result.status == 500) {
            set_is_authenticated(false);
            set_is_error(true);
            set_isShow(false);

            const confirmBox = window.confirm(
              "You are already Loggedin in other place! If you want to login here click OK"
            );
            if (confirmBox === true) {
              ForceLoginHandle(username, password);
            }
            // addToast(result.message, {
            //   appearance: "error",
            //   autoDismiss: true,
            // });
          } else if (result.status == 200) {
            addToast(result.message, {
              appearance: "info",
              autoDismiss: true,
            });

            //If any change in this portion adding value in session.
            //Please add this session change to SSOLogin page also --Omkar

            sessionStorage.setItem("authorizationToken", result.data[0].token);
            sessionStorage.setItem("RefreshToken", result.data[0].refreshToken);
            if (result.data[0].isMultipleSubProcessAllowed) {
              sessionStorage.setItem("is_userPreference", true);
            } else {
              sessionStorage.setItem("is_userPreference", false);
            }
            const rolesName = result.data[0].rolesName;

            setRoles(rolesName);

            //Added by Gajendra  for local timezone name

            var timezonename = new Date()
              .toLocaleTimeString("en-us", { timeZoneName: "long" })
              .split(" ")[2];
            sessionStorage.setItem("LocalTimezoneName", timezonename);

            // Navigation Blacklisting according to current development- Aman
            sessionStorage.setItem("rolesList", JSON.stringify(rolesName));
            for (let i = 0; i < rolesName.length; i++) {
              if (rolesName[i].roleName === "Admin1")
                sessionStorage.setItem("is_admin", true);
              else if (rolesName[i].roleName === "One And Done")
                sessionStorage.setItem("is_OneandDone", true);
              else if (
                rolesName[i].roleName === "Super Admin" ||
                rolesName[i].roleName === "SuperAdmin"
              )
                sessionStorage.setItem("is_superadmin", true);
              else if (rolesName[i].roleName === "Upload")
                sessionStorage.setItem("is_upload", true);
              else if (
                rolesName[i].roleName === "Activities" ||
                rolesName[i].roleName === "CWSActivities"
              )
                sessionStorage.setItem("is_activities", true);
              else if (rolesName[i].roleName === "Client")
                sessionStorage.setItem("is_client", true);
              else if (rolesName[i].roleName === "Exceptions")
                sessionStorage.setItem("is_exceptions", true);
              else if (rolesName[i].roleName === "Complete")
                sessionStorage.setItem("is_complete", true);
              else if (rolesName[i].roleName === "Vendor")
                sessionStorage.setItem("is_vendor", true);
              //       else if (rolesName[i].roleName === "WorkFlow State Change")
              //       sessionStorage.setItem("is_workFlowstatechange", true);
              //   else if (rolesName[i].roleName === "Reassignment")
              //   sessionStorage.setItem("is_reassignment", true);
              //else if (rolesName[i].roleName === "AdhocEditing")
              // sessionStorage.setItem("is_adhocEditing", true);
              //else if (rolesName[i].roleName === "Undo Activity")
              // sessionStorage.setItem("is_undoActivity", true);
              //else if (rolesName[i].roleName === "ClientDailyInfo")
              // sessionStorage.setItem("is_clientDailyInfo", true);
              // else if (rolesName[i].roleName === "Workitem Bulk Updation")
              //   sessionStorage.setItem("is_workitemBulkUpdation", true);
              // else if (rolesName[i].roleName === "CWSRestrictedSearch")
              //  sessionStorage.setItem("is_cwsRestrictedSearch", true);
              else if (
                rolesName[i].roleName === "Report" ||
                rolesName[i].roleName === "Reports"
              )
                sessionStorage.setItem("is_report", true);
              else if (rolesName[i].roleName === "Manage WorkItems")
                sessionStorage.setItem("is_manageWorkItems", true);
              else if (
                rolesName[i].roleName === "CWSSearch" ||
                rolesName[i].roleName === "CWSRestrictedSearch"
              )
                sessionStorage.setItem("is_search", true);
              else if (rolesName[i].roleName === "Upload and Assignment")
                sessionStorage.setItem("is_UploadandAssignment", true);
              else if (rolesName[i].roleName === "Queue Management")
                sessionStorage.setItem("is_QueueManagement", true);
              else if (rolesName[i].roleName === "Bucket Management")
                sessionStorage.setItem("is_BucketManagement", true); 
              else if (rolesName[i].roleName === "Real Time Dashboard")
                sessionStorage.setItem("is_RealTimeDashboard", true);
              // else if (rolesName[i].roleName === "ManageUsers")
              //   sessionStorage.setItem("is_manageUsers", true);
              // else if (rolesName[i].roleName === "WorkItemField Admin")
              //   sessionStorage.setItem("is_workItemFieldadmin", true);
              else if (rolesName[i].roleName === "Configuration")
                sessionStorage.setItem("is_Configuration", true);
              else if (rolesName[i].roleName === "User Classification Mapping")
                sessionStorage.setItem("is_UserClassificationMapping", true);
              else if (rolesName[i].roleName === "User Classification List")
                sessionStorage.setItem("is_UserClassificationList", true);
              else if (rolesName[i].roleName === "Workitem Fields Rules")
                sessionStorage.setItem("is_WorkitemFieldsRules", true);
              //  else if (rolesName[i].roleName === "Instruction Author")
              //    sessionStorage.setItem("is_instructionauthor", true);
              //  else if (rolesName[i].roleName === "Instruction Approver")
              //   sessionStorage.setItem("is_instructionapprover", true);
            }
            set_is_authenticated(true);
            sessionStorage.setItem("is_Authenticated", true);
            sessionStorage.setItem("authorizationToken", result.data[0].token);
            sessionStorage.setItem("UserName", username);
            sessionStorage.setItem("UserFullName", result.data[0].fullName);
            sessionStorage.setItem("UserEmail", result.data[0].email);
            sessionStorage.setItem("GUID", result.data[0].guid);
            sessionStorage.setItem("PersonID", result.data[0].personID);
            sessionStorage.setItem("PersonType", result.data[0].personType);
            sessionStorage.setItem(
              "HRISEmployeeID",
              result.data[0].hrisEmployeeID
            );
            sessionStorage.setItem(
              "EmployeeVersionID",
              result.data[0].employeeVersionID
            );
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          } else {
            set_is_authenticated(false);
            set_is_error(true);
            set_isShow(false);

            //set_error_msg("Username or Password is wrong!");
            addToast("Incorrect Username or Password!", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    } else {
      set_isShow(set_isShow);
    }
  };

  useEffect(() => {
    if (formErrors.length === 0 && isSubmit) {
    }
  }, [formErrors]);

  const validate = async (username, password) => {
    const errors = {};
    set_error_msg("");
    const regax = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (!username) {
      addToast("Username is required!", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (!password) {
      addToast("Password is required!", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    return errors;
  };
  if (!is_authenticated) {
    sessionStorage.setItem("is_Authenticated", false);
  }

  const handleLoginRedirect = () => {
    navigate("/SSOLogin");
  };

  return (
    <>
      <div className="">
        {/* <h1 style={{ color: "#fb4e0b", marginLeft: "173px" }}>
              Custom Workflow Solutions
            </h1> */}

        <div className="">
          <div className="">
            <div className="supportForm-main">
              {/* <div className="brandName">
                    <img src={logo} alt="#" />
                  </div> */}
              <h2>Submit your request below</h2>
              {/* {is_error ? <Alert severity="error">{error_msg}</Alert> : null} */}
              <form onSubmit={LoginHandle}>
                <ul>
                  <li>
                    <label>User Info</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="UserInfo"
                      name="username"
                      title="Change if you want to raise this for another user."
                      value={
                        sessionStorage.getItem("UserName") +
                        " - " +
                        sessionStorage.getItem("UserEmail")
                      }
                      onChange={ChangeHandle}
                    />
                    {/* <p style={{color: "red", fontSize: "15px"}}>{formErrors.username}</p> */}
                    {typeof formErrors.username === "string" ? (
                      <Alert severity="error">{formErrors.username}</Alert>
                    ) : null}
                  </li>
                  <li>
                    <label>Process Info</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Process Info"
                      name="ProcessInfo"
                      disabled="disabled"
                      value={
                        sessionStorage.getItem("SelctedSubprocessName") +
                        " (" +
                        sessionStorage.getItem("ProcessId") +
                        ")"
                      }
                      onChange={ChangeHandle}
                    />
                    {/* <p style={{color: "red", fontSize: "15px"}}>{formErrors.username}</p> */}
                    {typeof formErrors.username === "string" ? (
                      <Alert severity="error">{formErrors.username}</Alert>
                    ) : null}
                  </li>
                  <li>
                    <label>Issue Type</label>
                    <select
                      className="form-control"
                      title=""

                      //defaultValue={values.workItemDropdownValues[1]}
                      //value={values.workItemDropdownValues[0]}
                    >
                      <option selected="true" value="Select" key="">
                        Select
                      </option>

                      <option selected="true" value="Type1" key="Type1">
                        Type 1
                      </option>
                      <option value="Type2" key="Type2">
                        Type 2
                      </option>
                    </select>
                    {/* <p style={{color: "red", fontSize: "15px"}}>{formErrors.username}</p> */}
                    {typeof formErrors.username === "string" ? (
                      <Alert severity="error">{formErrors.username}</Alert>
                    ) : null}
                  </li>
                  <li>
                    <label>Issue Description</label>
                    <textarea
                      className="form-control "
                      //name={values.}

                      maxLength="10"
                      datatype="text"
                    />
                    {/* <p style={{color: "red", fontSize: "15px"}}>{formErrors.username}</p> */}
                    {typeof formErrors.username === "string" ? (
                      <Alert severity="error">{formErrors.username}</Alert>
                    ) : null}
                  </li>
                  <li>
                    <label>Attachment</label>
                    <div class="mb-3">
                      <input
                        class="form-control"
                        type="file"
                        id="formFileMultiple"
                        multiple
                      />
                    </div>
                    {/* <p style={{color: "red", fontSize: "15px"}}>{formErrors.username}</p> */}
                    {typeof formErrors.username === "string" ? (
                      <Alert severity="error">{formErrors.username}</Alert>
                    ) : null}
                  </li>
                  <li>
                    {!isShow ? (
                      <>
                        <button
                          className="btn btn-primary d-block w-100"
                          type="submit"
                        >
                          Submit
                        </button>
                        {/* <button className="btn btn-primary" onClick={handleLoginRedirect}>
                            SSO Login
                          </button> */}
                      </>
                    ) : (
                      <LoadingSpinner />
                    )}
                    {/* <p style={{ color: "red", fontSize: "15px" }}>
                          {error_msg}
                        </p> */}
                    {/* {typeof error_msg === "string"?<Alert severity="error">{error_msg}</Alert>:null} */}
                  </li>
                </ul>
              </form>
              <h2 className="ssoDivision">
                <span>OR</span>
              </h2>
              <span style={{ color: "Black" }}>
                Email your request to #cwssupport@exlservice.com
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportForm;

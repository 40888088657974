import React, { useState } from "react";



import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

//import Countdown from "react-countdown";

const Workitem = () => {


    return (
        <>
            <Header />
            <Nav />
            <div className="centerSection">
                <div className="pageHeader highLight">
                    <div className="row  align-items-center">
                        <div className="col-6">
                            <h2 className="pageName">Workitem Assignment Details</h2>
                            {/*  <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Processing Dashboard</li>
                                </ol>
                            </nav>
                            */}
                        </div>
                        {/* <div className="col-6 text-end">

                            <div className="timeCountdown">
                                <label>Time Left</label>
                                <div>
                                    <Countdown date={Date.now() + 1000000} />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="pageBody">
                    <div className="dashBoxs mb-3">
                        <div className="tableWrap">
                            <div className="tableWrap-body">
                                <table className="table table-borderless brandTable">
                                    <thead>
                                            <th>Description</th>
                                            <th>Assigned By</th>
                                            <th>Assignment Date</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tableWrap-footer">
                                <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                                    <div className="col-sm mb-2 mb-sm-0">
                                        <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                                            <span className="me-2 text-info">Showing:</span>
                                            <span className="text-info">1-5 out of 20 records</span>
                                        </div>
                                    </div>

                                    <div className="col-sm-auto">
                                        <div className="d-flex justify-content-center justify-content-sm-end">
                                            <nav>
                                                <div className="paging_simple_numbers">
                                                    <ul className="pagination">
                                                        <li className="paginate_item page-item disabled"><a className="paginate_button previous page-link"><span>Prev</span></a></li>
                                                        <li className="paginate_item page-item active"><a className="paginate_button page-link">1</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">2</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">3</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">4</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button next page-link"><span aria-hidden="true">Next</span></a></li>
                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashBoxs mb-3">
                        <div className="tableWrap">
                            <div className="tableWrap-body">
                                <table className="table table-borderless brandTable">
                                    <thead>
                                            <th>Description</th>
                                            <th>Assigned By</th>
                                            <th>Assignment Date</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tableWrap-footer">
                                <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                                    <div className="col-sm mb-2 mb-sm-0">
                                        <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                                            <span className="me-2 text-info">Showing:</span>
                                            <span className="text-info">1-5 out of 20 records</span>
                                        </div>
                                    </div>

                                    <div className="col-sm-auto">
                                        <div className="d-flex justify-content-center justify-content-sm-end">
                                            <nav>
                                                <div className="paging_simple_numbers">
                                                    <ul className="pagination">
                                                        <li className="paginate_item page-item disabled"><a className="paginate_button previous page-link"><span>Prev</span></a></li>
                                                        <li className="paginate_item page-item active"><a className="paginate_button page-link">1</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">2</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">3</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">4</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button next page-link"><span aria-hidden="true">Next</span></a></li>
                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashBoxs mb-3">
                        <div className="tableWrap">
                            <div className="tableWrap-body">
                                <table className="table table-borderless brandTable">
                                    <thead>
                                            <th>Description</th>
                                            <th>Assigned By</th>
                                            <th>Assignment Date</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                        <tr>
                                            <td>Initially opened by Rajnikant Tripathi[169034] for Processing Activity</td>
                                            <td>
                                                <span class="avatar avatar-sm avatar-circle"> 
                                                    <img class="avatar-img" src="/static/media/user.b377838785333f560db3.jpg" alt="Image_Description" />
                                                </span>
                                                <span className="ms-2">
                                                    John Doe
                                                </span>
                                            </td>
                                            <td>09/05/2022 03:47:55 PM</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="tableWrap-footer">
                                <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
                                    <div className="col-sm mb-2 mb-sm-0">
                                        <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
                                            <span className="me-2 text-info">Showing:</span>
                                            <span className="text-info">1-5 out of 20 records</span>
                                        </div>
                                    </div>

                                    <div className="col-sm-auto">
                                        <div className="d-flex justify-content-center justify-content-sm-end">
                                            <nav>
                                                <div className="paging_simple_numbers">
                                                    <ul className="pagination">
                                                        <li className="paginate_item page-item disabled"><a className="paginate_button previous page-link"><span>Prev</span></a></li>
                                                        <li className="paginate_item page-item active"><a className="paginate_button page-link">1</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">2</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">3</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button page-link">4</a></li>
                                                        <li className="paginate_item page-item"><a className="paginate_button next page-link"><span aria-hidden="true">Next</span></a></li>
                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />

        </>
    )
};

export default Workitem;
import React, { useState } from "react";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import pdfViewer from "../../../../assets/images/placeholder/pdf_viewer.jpg";

const ProcessingScreen = () => {
  // SETTING MODAL FUNCTION
  const [settingModal, settingShow] = useState(false);

  const settingClose = () => settingShow(false);
  const settingOpen = () => settingShow(true);

  return (
    <>
      <Header onpreferenceShow={null} pageName="ActivityDashboard" />
      <Nav selectedMenu="activities" />
      <div className="centerSection">
        <div className="pageHeader">
          <div className="row  align-items-center">
            <div className="col-6">
              <h2 className="pageName">Processing Screen</h2>
            </div>
            <div className="col-6 text-end">
              <button
                type="button"
                className="btn btn-sm btn-outline-primary ms-2"
                onClick={settingShow}
              >
                <b className="icon-settings"></b> Change Status
              </button>
            </div>
          </div>
        </div>

        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-0">
                    <div className="form-check form-switch mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                      <label
                        className="form-check-label form-label"
                        for="flexSwitchCheckDefault"
                      >
                        Use Screen Filter
                      </label>
                    </div>
                    <select
                      className="form-control form-control-sm"
                      title="Select a field to apply filter"
                    >
                      <option>Select Screen Filter</option>
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-0">
                    <button className="btn btn-outline-primary btn-sm">
                      Get Next Work Item
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="fieldSet">
                <h4 className="mb-4">Onbase Document Upload</h4>
                <div className="row">
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Document Type</label>
                      <select className="form-control form-control-sm">
                        <option>Select</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Upload Document</label>
                      <input
                        className="form-control  form-control-sm"
                        type="file"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr className="mb-4" />

              <div className="fieldSet pt-1">
                <h4 className="mb-4">Customer Details</h4>
                <div className="row">
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">PRO Number</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Payment Terms</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-12 pb-3"></div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Shipper Name</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Shipper Address</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Shipper City</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Shipper State</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Shipper Zip Code</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-12 pb-3"></div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Consignee Name</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Consignee Address</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Consignee City</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Consignee State</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Consignee Zip Code</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-12 pb-3"></div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Bill To Name</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Bill To Address</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Bill To City</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Bill To State</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Bill To Zip Code</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-12 pb-3"></div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">
                        Commodity Piece Count
                      </label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Commodity PKG Type</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Commodity Weight</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Commodity Class</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">
                        Commodity Description
                      </label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Commodity NFMC</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-12 pb-3"></div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Customer Number</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">
                        Customer Order Number
                      </label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">PO Number</label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">
                        Bill of Lading Number
                      </label>
                      <input
                        className="form-control  form-control-sm"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-12 pb-3"></div>
                  <div className="col-12">
                    <div className="pb-3">
                      <label className="form-label">Special Instructions</label>
                      <textarea className="form-control"></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="mb-4" />

              <div className="fieldSet pt-1">
                <h4 className="mb-4">Upload Files</h4>
                <div className="row">
                  <div className="col-4">
                    <div className="pb-3">
                      <label className="form-label">Select Document</label>
                      <input
                        className="form-control  form-control-sm"
                        type="file"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="formWrapFooter">
            <div className="row">
              <div className="col-6 text-start">
                <button className="btn btn-outline-ntv ">
                  Back to dashboard
                </button>
                <button className="btn btn-link ms-3">Skip</button>
              </div>
              <div className="col-6 text-end">
                <button className="btn btn-link text-primary">Save Data</button>
                <button className="btn btn-outline-primary ms-3">
                  Submit & Add New
                </button>
                <button className="btn btn-outline-primary ms-3">
                  Submit & Continue
                </button>
                <button className="btn btn-primary ms-3">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/** ASSIGN & CHANGE STATUS MODAL **/}
      <Modal
        show={settingModal}
        dialogClassName="modal-25w"
        onHide={settingClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <li>
                <label className="form-label">Change Status</label>
                <select className="form-control form-control-sm">
                  <option>Select</option>
                </select>
              </li>
              <li>
                <label className="form-label">Status Comment</label>
                <textarea
                  className="form-control"
                  placeholder="Type here...."
                ></textarea>
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-ntv mx-2" onClick={settingClose}>
            Cancel
          </button>
          <button className="btn btn-primary">Submit</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProcessingScreen;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import user01 from "../../../../assets/images/user/user01.jpg";
import green from "../../../../assets/images/icons/green.png";
import red from "../../../../assets/images/icons/red.png";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DateRangePicker from "react-bootstrap-daterangepicker";
import GlobalStyles from "@mui/material/GlobalStyles";
import * as FileSaver from "file-saver";

import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

const RealtimeDashboard = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const { addToast } = useToasts();
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_GETRUNASEMPLOYEE } = process.env;
  const { REACT_APP_GETREALTIMEDASHBOARD } = process.env;

  const ShowDate = (e) => {
    var dtnew = new Date();
    var dd = dtnew.getDate();
    var mm = dtnew.getMonth() + 1;
    var yy = dtnew.getFullYear();
    var date = mm + "/" + dd + "/" + yy;
    Setlabeldate(date);
  };

  const columns = [
    {
      title: "Employee Id",
      field: "employeeId",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Supervisor",
      field: "supervisor",
    },
    {
      title: "Present",
      field: "present",
      render: (rowData) =>
        rowData["employeeId"] == "Total" ? null : rowData["login"] != null &&
          rowData["logout"] == null ? (
          <img src={green} className="avatar-img" alt="Image_Description" />
        ) : (
          <img src={red} className="avatar-img" alt="Image_Description" />
        ),
    },
    {
      title: "Login",
      field: "login",
    },
    {
      title: "Logout",
      field: "logout",
    },
    {
      title: "Break Type",
      field: "breakType",
    },
    {
      title: "OverShoot",
      field: "breakOverShoot",
    },
    {
      title: "Total",
      field: "total",
    },
    {
      title: "15",
      field: "_15",
    },
    {
      title: "30",
      field: "_30",
    },
    {
      title: "45",
      field: "_45",
    },
    {
      title: "60",
      field: "_60",
    },
    {
      title: "Prod%",
      field: "productivity",
    },
    {
      title: "Occp%	",
      field: "occupancy",
    },
    {
      title: "BP",
      field: "billsProcessed",
    },
    {
      title: "Prod%	",
      field: "forecastProductivity",
    },
    {
      title: "BPD",
      field: "bpd",
    },
    {
      title: "BPH",
      field: "bph",
    },
    {
      title: "Prod%",
      field: "avgProductivity",
    },
    {
      title: "Occp%",
      field: "avgOccupancy",
    },
  ];

  const [isLoadingDashboard, set_isLoadingDashboard] = useState(false);
  const [selectedEmployeeID, setselectedEmployeeID] = useState([]);
  const [RunAsEmployee, setRunAsEmployee] = useState([]);
  const [RealtimeDashboardHead, setRealtimeDashboardHead] = useState([]);
  //const [RealtimeDashboardDtls, setRealtimeDashboardDtls] = useState([]);
  const [RealtimeDashboardCount, setRealtimeDashboardCount] = useState([]);
  const [labeldate, Setlabeldate] = useState([]);
  const [RefreshTime, setRefreshTime] = useState(600);
  const [IntervalTime, setIntervalTime] = useState(0);

  const downloadExcel = (condition) => {
    const exportType = "xls";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    // if(condition=="Employee Break")
    // {
    const fileName = "RealtimeDashboardHead";
    const ws = XLSX.utils.json_to_sheet(RealtimeDashboardHead);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    // }
    // else
    // {
    //     const fileName = 'EmployeeActivityDetails'
    //     const ws = XLSX.utils.json_to_sheet(EmployeeActivityDetails);
    //     const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //     const data = new Blob([excelBuffer], {type: fileType});
    //     FileSaver.saveAs(data, fileName + fileExtension);
    // }
  };

  const setselectedEmployee_ID = (e) => {
    setselectedEmployeeID((s) => (s = e.target.value));
    //alert(e.target.value);
    getRealtimeDashboard(e.target.value);
    GetReloadpages(e.target.value);
  };
  const getRefreshTime = (e) => {
    setRefreshTime(e.target.value);
  };

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const getRunAsEmployee = () => {
    let body = {
      EmployeeID: sessionStorage.getItem("PersonID"),
      UserName: sessionStorage.getItem("UserFullName"),
    };
    let loginUrl = getAPIURL(REACT_APP_GETRUNASEMPLOYEE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          setRunAsEmployee(result.data._listStatus);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const getRealtimeDashboard = (selectEmp) => {
    set_isLoadingDashboard(true);
    let SupervisorIds;
    if (selectEmp == "Select") {
      SupervisorIds = "0";
    } else if (selectEmp != "") {
      SupervisorIds = selectEmp;
    } else {
      SupervisorIds = sessionStorage.getItem("PersonID");
    }
    let body = {
      SupervisorId: SupervisorIds,
      // SupervisorId: "188402",
      SubProcessID: sessionStorage.getItem("ProcessId"),
    };
    let loginUrl = getAPIURL(REACT_APP_GETREALTIMEDASHBOARD);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        UserName: sessionStorage.getItem("UserName"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          // debugger;
          setRealtimeDashboardHead(result.data._listStatusHead);
          //setRealtimeDashboardDtls(result.data._listStatusDtls);
          setRealtimeDashboardCount(result.data._listStatusCount[0]);

          set_isLoadingDashboard(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          set_isLoadingDashboard(false);
          addToast("Something went wrong!", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const GetReloadpages = (selectEmp) => {
    // debugger;
    let time;
    let val = RefreshTime;
    time = 1000 * val;
    // alert(selectEmp);
    getRealtimeDashboard(selectEmp);

    if (time > 1000 * 59) {
      clearInterval(IntervalTime);
      let interval = setInterval(() => {
        // aa &&
        // addToast("This will be called every seconds "+selectEmp+" OO", {
        //     appearance: "success",
        //     autoDismiss: true,
        // });
        getRealtimeDashboard(selectEmp);
      }, time);
      setIntervalTime(interval);
    } else {
      clearInterval(IntervalTime);
      alert("Maximum refresh time is 60 seconds.");
      let interval2 = setInterval(() => {
        // addToast("This will be called every "+selectEmp+ "seconds", {
        //     appearance: "success",
        //     autoDismiss: true,
        // });
        getRealtimeDashboard(selectEmp);
      }, 1000 * 60);
      setRefreshTime("60");
      setIntervalTime(interval2);
    }

    // setselectedEmployeeID(selectEmp);
    // let time;
    // //var val = document.getElementById('<%=txtRefreshmin.ClientID%>').value;
    // let val = RefreshTime;
    // time = 1000 * val;
    // alert(val);
    // if (time > (1000 * 59))
    //     setInterval(getRealtimeDashboard(selectedEmployeeID), time);
    // else {
    //     alert("Maximum refresh time is 60 seconds.");
    //     //document.getElementById('<%=txtRefreshmin.ClientID%>').value = 60;
    //     setRefreshTime("60");
    //     setInterval(getRealtimeDashboard(selectedEmployeeID), 1000 * 60);
    // }

    // let interval2=null;
    // // console.log(IntervalTime);
    // clearInterval(IntervalTime);
    // let interval = setInterval(() => {
    //     // aa &&
    //     addToast("This will be called every seconds"+selectEmp+" OO", {
    //         appearance: "success",
    //         autoDismiss: true,
    //     });
    //     // getRealtimeDashboard(selectEmp)
    // }, 4000);
    // // console.log(interval);
    // setIntervalTime(interval);
  };

  const Reloadpage = () => {
    GetReloadpages(selectedEmployeeID);
  };

  useEffect(() => {
    ShowDate();
    getRunAsEmployee();
    setselectedEmployeeID(sessionStorage.getItem("PersonID"));
    getRealtimeDashboard(sessionStorage.getItem("PersonID"));
    GetReloadpages(sessionStorage.getItem("PersonID"));
  }, []);
  return (
    <>
      <Header
        onpreferenceShow={null}
        pageName="Real time Dashboard"
        timeCaptureActivityName="Reporting"
      />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="reports" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>

      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="">
                <div className="row align-items-end">
                  {/* <div className="col-4">
                                        <div className="mb-3">
                                            <label className="form-label"> Company Department</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="mb-3">
                                            <label className="form-label">Location Client</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="mb-3">
                                            <label className="form-label">Process SubProcess</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div> */}
                  <div className="col-4">
                    <div className="mb-3">
                      <label className="form-label">Date</label>
                      <p>{labeldate}</p>
                    </div>
                  </div>
                  {/* <div className="col-4">
                                        <div className="mb-3">
                                            <label className="form-label">Activity</label>
                                            <select className="form-control form-control-sm"><option>Select</option></select>
                                        </div>
                                    </div> */}
                  <div className="col-4">
                    <div className="mb-3">
                      <label className="form-label">Run As</label>
                      {/* <select className="form-control form-control-sm"><option>Select</option></select> */}
                      <select
                        className="form-control form-control-sm"
                        onChange={setselectedEmployee_ID}
                      >
                        <option>Select</option>
                        {RunAsEmployee.map((Emp) => (
                          <option
                            value={Emp.employeeID}
                            selected={Emp.employeeID == selectedEmployeeID}
                          >
                            {Emp.employeeName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="mb-3">
                      <label className="form-label">Refresh Time In Sec</label>
                      <div className="input-group">
                        {/* <input className="form-control form-control-sm" placeholder="600" /> */}
                        {/* <button class="btn btn-outline-primary" type="button" id="button-addon2">
                                                    <b class="icon-refresh-ccw"></b>
                                                </button> */}
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          // placeholder="600"
                          // value="600"
                          value={RefreshTime}
                          onChange={getRefreshTime}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={Reloadpage}
                        >
                          <b class="icon-refresh-ccw"></b>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row align-items-end">
                                    <div className="col-4">
                                        <div className="mb-3">
                                            <label className="form-label">Refresh Time In Sec</label>
                                            <div className="input-group">
                                                <input className="form-control form-control-sm" placeholder="600" />
                                                <button class="btn btn-outline-primary" type="button" id="button-addon2">
                                                    <b class="icon-refresh-ccw"></b>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>

          <div className="dashBoxs">
            <div className="dashBoxs-header pb-3 pt-2">
              <div className="row align-items-center">
                <div className="col-3">
                  <h6>Realtime Dashboard</h6>
                </div>
                <div className="col-9">
                  <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                    <div className="d-inline">
                      <div className="input-group input-group-sm">
                        <label
                          className="input-group-text"
                          for="inputGroupSelect01"
                        >
                          Total: {RealtimeDashboardCount.totalWorkitems}
                        </label>
                      </div>
                    </div>
                    <div className="d-inline ms-3 mx-0">
                      <div className="input-group input-group-sm">
                        <label
                          className="input-group-text"
                          for="inputGroupSelect02"
                        >
                          Completed: {RealtimeDashboardCount.completedWorkitems}
                        </label>
                      </div>
                    </div>
                    <div className="d-inline ms-3 mx-0">
                      <div className="input-group input-group-sm">
                        <label
                          className="input-group-text"
                          for="inputGroupSelect02"
                        >
                          Remaining: {RealtimeDashboardCount.remainingWorkitems}
                        </label>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-2"
                      onClick={() => {
                        downloadExcel("Employee Break");
                      }}
                    >
                      <b></b>Export
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isLoadingDashboard ? (
              <LoadingSpinnerFigma />
            ) : (
              <div className="tableWrap">
                <div className="tableWrap-body MatTable">
                  <table>
                    <MaterialTable
                      title="Realtime Dashboard"
                      options={{
                        fixedColumns: {
                          left: 5.0,
                          right: 0,
                        },
                        // tableLayout: "auto",
                        // overflowY: true,
                        exportButton: false,
                        exportCsv: (columns, data) => {
                          downloadExcel("Employee Break");
                          //alert('You should develop a code to export ' + data.length + ' rows');
                        },
                        sorting: true,
                        filtering: true,
                        search: false,
                        paging: true,
                        showTitle: false,
                        cellStyle: {
                          // width: 100,
                          // minWidth: 100,
                          "white-space": "unset",
                        },
                        headerStyle: {
                          // width: 100,
                          // minWidth: 100,
                          // fontFamily: '"Yantramanav", sans-serif',
                          // fontWeight: "inherit",
                          // fontSize: "16px",
                          // height: 40,
                          // textAlign: "left",
                        },
                        rowStyle: {
                          // fontFamily: '"Yantramanav", sans-serif',
                        },
                      }}
                      components={{
                        Container: (props) => (
                          <Paper {...props} elevation={0} />
                        ),
                        Toolbar: (props) => (
                          <div
                            style={{
                              backgroundColor: "rgb(206 206 206)",
                              fontSize: "0.75em",
                            }}
                          >
                            <MTableToolbar
                              {...props}
                              style={{ fontSize: "5px" }}
                            />
                          </div>
                        ),
                      }}
                      columns={columns}
                      icons={tableIcons}
                      data={RealtimeDashboardHead}
                    />
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RealtimeDashboard;

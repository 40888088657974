import logo from "src/assets/images/logo/exl_21_logo_org.svg";

function ResetPassword() {
  return (
    <>
      <div className="authCenter">
        <div className="authWrap">
          <div className="authOnly">
            <div className="authForm-main">
              <div className="brandName">
                <img src={logo} alt="#" />
              </div>
              <h2>Reset Password</h2>
              <form>
                <ul>
                  <li>
                    <label>Please enter your username below</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Username"
                    />
                  </li>
                  <li className="pt-3">
                    <button className="btn btn-primary" type="submit">
                      Request Reset
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;

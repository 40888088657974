import scItem_01 from "./assets/images/icons/icon_folder.png";
import scItem_02 from "./assets/images/icons/icon_customization.png";
import scItem_03 from "./assets/images/icons/icon_sub_process.png";
import scItem_04 from "./assets/images/icons/icon_manage.png";
import scItem_05 from "./assets/images/icons/icon_analysis.png";
import scItem_06 from "./assets/images/icons/icon_review.png";
import scItem_07 from "./assets/images/icons/icon_authorization.png";
import Slider from "react-slick";

import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { object } from "prop-types";
import { red } from "@material-ui/core/colors";

import { ToastProvider, useToasts } from "react-toast-notifications";
import bcrypt from "bcryptjs";
import { Buffer } from "buffer";
import LoadingSpinner from "./LoadingSpinner";

const { REACT_APP_BuildNo } = process.env;
const { REACT_APP_API_URL } = process.env;
const { REACT_APP_NAME } = process.env;

//toast.configure()
function About() {
  let navigate = useNavigate();

  var settings = {
    dots: true,
    infinite: true,
    speed: 1600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    adaptiveHeight: false,
  };

  return (
    <>
      <div className="authCenter">
        {sessionStorage.getItem("UserName") === null ? (
          <Link to="/" style={{ marginLeft: "2rem" }}>
            Back to Login
          </Link>
        ) : (
          <Link to="/Home" style={{ marginLeft: "2rem" }}>
            Back to Home
          </Link>
        )}

        <div className="authWrap">
          <div className="authLeft">
            <div className="aboutDetail">
              <h1>Custom Workflow Solution</h1>
              <p>
                Is a powerful workflow based application that works in tandem
                with EXL’s production dashboard and enables EXL’s client users
                to collaborate with EXL employees in real-time by acting upon
                the work items routed to them.
              </p>
              <div>
                <Slider className="scWrap" {...settings}>
                  <div>
                    <div className="scWrap-item">
                      <div className="scWrap-icon">
                        <img src={scItem_01} alt="#" />
                      </div>
                      <p className="scWrap-text">
                        Configurable set of data fields and activities specific
                        to each sub-process
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="scWrap-item">
                      <div className="scWrap-icon">
                        <img src={scItem_02} alt="#" />
                      </div>
                      <p className="scWrap-text">
                        Configurable dashboards and screens for each sub-process
                        activity
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="scWrap-item">
                      <div className="scWrap-icon">
                        <img src={scItem_03} alt="#" />
                      </div>
                      <p className="scWrap-text">
                        Configurable sub-process workflow to efficiently route
                        workitems between activities
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="scWrap-item">
                      <div className="scWrap-icon">
                        <img src={scItem_04} alt="#" />
                      </div>
                      <p className="scWrap-text">
                        Facility to upload, create, assign, search and manage
                        sub-process level workitems
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="scWrap-item">
                      <div className="scWrap-icon">
                        <img src={scItem_05} alt="#" />
                      </div>
                      <p className="scWrap-text">
                        Facility to generate powerful reports to track and
                        manage day-to-day operations at process / sub-process
                        level
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="scWrap-item">
                      <div className="scWrap-icon">
                        <img src={scItem_06} alt="#" />
                      </div>
                      <p className="scWrap-text">
                        Facility to monitor process health through SLA
                        compliance and performance review reports
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="scWrap-item">
                      <div className="scWrap-icon">
                        <img src={scItem_07} alt="#" />
                      </div>
                      <p className="scWrap-text">
                        Role based authorization to ensure right-level of access
                        to team members, leads, managers and EXL’s Client users
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="aboutRight">
            <div className="aboutImage"></div>
          </div>
        </div>

        <center>
          {" "}
          <small>
            &copy; {new Date().getFullYear()} Copyright{" "}
            {new Date().getFullYear()} ExlService Holdings, Inc. All Rights
            Reserved. &#124; Build No : {REACT_APP_BuildNo}
          </small>
        </center>
      </div>
    </>
  );
}

export default About;

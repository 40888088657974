import React, { useState, useEffect, useMemo, useContext, useRef } from "react";

import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

import Countdown from "react-countdown";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";

import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { FcSearch } from "react-icons/fc";
import { FcImageFile } from "react-icons/fc";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import axios from "axios";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import { useNavigate, useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { ToastProvider, useToasts } from "react-toast-notifications";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import LoadingSpinner from "../../../../LoadingSpinner";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import GlobalStyles from "@mui/material/GlobalStyles";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import { Link, Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import {
//   assignWorkItem,
//   unassignWorkItem,
// } from "../../../../redux/AssignWorkItemSlice";
//import { addSelectedEmployeeID } from "../../../../redux/AssignWorkItemSlice";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import { toDate } from "date-fns";
import session from "redux-persist/lib/storage/session";

function UploadAssignment() {
  const { addToast } = useToasts();
  const { REACT_APP_FETCH_SUBPROCESSUPLOADEXCELCOLUMNS } = process.env;
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_FETCH_FETCHDEFAULTVALUE } = process.env;
  const { REACT_APP_FETCH_ASSIGNEDWORKITEMDETAILSFORUSER } = process.env;
  const { REACT_APP_GETUPLOADFILEDETAILS } = process.env;
  const { REACT_APP_UPLOADEXCELDATA } = process.env;
  const { REACT_APP_NAME } = process.env;
  const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const { REACT_APP_WORKITEMDELETE } = process.env;
  const { REACT_APP_FETCHUPLOADEDDATA } = process.env;
  const { REACT_APP_WORKITEMSHOWCONDITION } = process.env;
  const { REACT_APP_WORKITEMMARKURGENT } = process.env;
  const { REACT_APP_GETUPLOADHISTORYDATA } = process.env;
  const { REACT_APP_ASSIGNMENTDROPDOWN } = process.env;
  const { REACT_APP_ASSIGNWORKITEM } = process.env;
  const { REACT_APP_GETWORKITEMUPLOADDETAILSFORSUBPROCESSANDBATCH } =
    process.env;

  let interval = null;
  const location = useLocation();
  let navigate = useNavigate();
  // NOTIFY MODAL FUNCTION
  const [notifyModal, setShow] = useState(false);
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [autoAssigned, setautoAssigned] = useState("on");
  const [auto, setauto] = useState(true);
  const [autoAssignedForFileName, setautoAssignedForFileName] = useState("on"); // Added by Mahinder Singh
  const [autoForFileName, setautoForFileName] = useState(true); // Added by Mahinder Singh
  const [activityName, setactivityName] = useState(
    sessionStorage.getItem("activityName")
  );
  const [workflowStateID, setworkflowStateID] = useState(
    sessionStorage.getItem("workflowstateId")
  );
  const [BatchUploadValue, setBatchUploadValue] = useState(
    "Detailed Batch Upload"
  );
  const [AllocationUnit, setAllocationUnit] = useState("");
  const [ShowDetailIcon, setShowDetailIcon] = useState(""); //Added by Trupti
  const [rblBatchUploadTypeVisible, setrblBatchUploadTypeVisible] =
    useState(false);
  const [divBatchUploadTypeVisible, setdivBatchUploadTypeVisible] =
    useState(false);

  const [isFigma, setisFigma] = useState(false);
  const [department, setdepartment] = useState(true);
  const [client, setclient] = useState(true);
  const [wdcUploadDateMaxValue, setwdcUploadDateMaxValue] = useState("");
  const [wdcUploadDateValue, setwdcUploadDateValue] = useState("");
  const [subprocess, setsubprocess] = useState(true);
  const [dateRange, setdateRange] = useState(true);
  const [date, setdate] = useState("");
  const [fileToUpload, setfileToUpload] = useState(true);
  const [showUpload, setshowUpload] = useState(false);
  const [totalCount, settotalCount] = useState(0);

  const [uploadedFile, setuploadedFile] = useState("");
  const [excelValue, setexcelValue] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [subprocessValue, setsubprocessValue] = useState("");
  const [assignedWorkItemDetails, setAssignedWorkItemDetails] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [ExcelColumnsConfig, setExcelColumnsConfig] = useState([]);
  const [workSheet, setworkSheet] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [SelectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedFile, setselectedFile] = useState();
  const [json, setjson] = useState([]);
  const [ExcelColumnsSheet, setExcelColumnsSheet] = useState(""); //Added by Pradip
  const [files, setfiles] = useState([]);
  const [uploadExcelDetails, setuploadExcelDetails] = useState([]);
  const [uploadExcelDetailsoriginal, setuploadExcelDetailsoriginal] = useState(
    []
  );
  const [columns, setcolumns] = useState([]);
  const [DuplicateColumns, setDuplicateColumns] = useState([]);
  const [duplicateExcelDetails, setduplicateExcelDetails] = useState([]);
  const [IsDuplicate, setIsDuplicate] = useState(false);
  const [isExcelGrid, setisExcelGrid] = useState(false);
  const [isLoading, set_IsLoading] = useState(false);
  const [isLoading_Subprocess, setisLoading_Subprocess] = useState(false);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const [isExcelGridRecordDeleted, setisExcelGridRecordDeleted] =
    useState(false);
  const [UploadExcelColumnsStatus, setUploadExcelColumnsStatus] =
    useState(false);

  const [Page, setPage] = useState(5);
  const [PageNo, setPageNo] = useState(0);
  const [DepartmentbyGUID, setDepartmentbyGUID] = useState(false);
  const [DefaultValue, setDefaultValue] = useState(false);
  const [OpenPopUp, setOpenPopUp] = useState(false);
  const [ClosePopUp, setClosePopUp] = useState(false);
  const [DropDown, setDropDown] = useState([]);
  const [Arr, setArr] = useState([]);
  const [Emp, setEmp] = useState([]);
  const initialValues = { EmployeeVersionID: "", AssignedDashboard: "" };
  //const [Add, setAdd] = useState(initialValues);
  const [Add, setAdd] = useState([]);
  const [WorkItemIDs, setWorkItemIds] = useState([]);
  const [BatchItemIds, setBatchItemIds] = useState([]);
  const [RefreshData, setRefreshData] = useState(false);
  const SelectedDashboardref = useRef(null);
  const SelectedEmployeeref = useRef(null);
  const [dependency, setdependency] = useState("");
  const tableref = useRef();
  const tableRef1 = useRef();
  // const { assignWorkItem, selectedAssignmentDetails } = useSelector(
  //   (state) => state
  // );
  // const dispatch = useDispatch();

  function notifyClose() {
    return setShow(false);
  }
  const notifyShow = () => setShow(true);

  // SETTING MODAL FUNCTION
  const [settingModal, settingShow] = useState(false);
  const [divCompanyDepartmentVisible, setdivCompanyDepartmentVisible] =
    useState(false);
  const [divLocationClientVisible, setdivLocationClientVisible] =
    useState(false);
  const [divProcessSubProcessVisible, setdivProcessSubProcessVisible] =
    useState(false);
  const [btnUploadVisible, setbtnUploadVisible] = useState(false);
  const [fuUploadExcelEnabled, setfuUploadExcelEnabled] = useState(false);
  const [uplodadedDateTimeFrom, setuplodadedDateTimeFrom] = useState("");
  const [uplodadedDateTimeTo, setuplodadedDateTimeTo] = useState("");
  const [viewWorkitemModal, setviewWorkitemModal] = useState(false);
  const [workitemDetails, setworkitemDetails] = useState([]); //Added by Trupti
  const [workitemMode, setworkitemMode] = useState("0"); //Added by Trupti
  const [CurrentBatchNumber, setCurrentBatchNumber] = useState("");
  const [ApplicableUploadDate, setApplicableUploadDate] = useState("");
  const viewWorkitemClose = () => {
    setworkitemDetails([]);
    setviewWorkitemModal(false);
  };
  const [assignconfig, setassignconfig] = useState([]);
  const settingClose = () => settingShow(false);
  const settingOpen = () => settingShow(true);

  // HISTORY MODAL FUNCTION
  const [historyModal, historyShow] = useState(false);

  const historyClose = () => historyShow(false);
  const displayCriteriaIndex = useRef(0);
  const [selectedCriteria, setSelectedCriteria] = useState(2);

  const historyOpen = () => {
    historyShow(true);
    //GetUploadHistoryData();
  };

  //new
  const handlepagechange = (page) => {
    
    setPageNo(page);
    if (page != 0 || Page != 0) {
      let a = Page * page;
      const Finalvalue = uploadExcelDetailsoriginal.slice(a, a + Page);
      settotalCount(uploadExcelDetailsoriginal.length);
      setuploadExcelDetails(Finalvalue);
    } else {
      const Finalvalue = uploadExcelDetailsoriginal.slice(0, 5);
      settotalCount(uploadExcelDetailsoriginal.length);
      setuploadExcelDetails(Finalvalue);
    }
  };
  const handlepagesizechange = (pagesize) => {
    
    setPage(pagesize);
    if (pagesize != 0 || PageNo != 0) {
      let a = PageNo * pagesize;
      const Finalvalue = uploadExcelDetailsoriginal.slice(a, a + pagesize);
      settotalCount(uploadExcelDetailsoriginal.length);
      setuploadExcelDetails(Finalvalue);
    } else {
      const Finalvalue = uploadExcelDetailsoriginal.slice(0, 5);
      settotalCount(uploadExcelDetailsoriginal.length);
      setuploadExcelDetails(Finalvalue);
    }

    //const originalvalue = uploadExcelDetailsoriginal;
    // settotalcount(uploadExcelDetailsoriginal.length);
    // let a = PageNo * pagesize;
    // const Finalvalue = uploadExcelDetailsoriginal.slice(a, a + pagesize);

    // setuploadExcelDetails(Finalvalue);
  };
  // const handlechange = row => {
  //   debugger;
  //   //const originalvalue = uploadExcelDetailsoriginal;
  //   if (Page != 0 || PageNo != 0) {
  //     let a = PageNo * Page;
  //     const Finalvalue = uploadExcelDetailsoriginal.slice(a, a + Page);
  //     settotalCount(uploadExcelDetailsoriginal.length);
  //     setuploadExcelDetails(Finalvalue);
  //   } else {
  //     const Finalvalue = uploadExcelDetailsoriginal.slice(0, 5);
  //     settotalCount(uploadExcelDetailsoriginal.length);
  //     setuploadExcelDetails(Finalvalue);
  //   }
  // };
  // const handlesearch = search => {
  //   debugger;
  //   setuploadExcelDetails(uploadExcelDetails);
  // };
  // const uploadExcelDetails1 = query =>
  //   new Promise(resolve => {
  //     // prepare the data
  //     debugger;
  //     // setPage(query.pageSize);
  //     // setPageNo(query.page);

  //     let a = query.page * query.pageSize;
  //     resolve({
  //       data: uploadExcelDetails.slice(a, a + query.pageSize),
  //       page: query.page,
  //       totalCount: uploadExcelDetails.length
  //     });

  //     setisExcelGrid(false);
  //   });

  //jinnat

  const [uploadHistoryData, setUploadHistoryData] = useState([]);
  useEffect(() => {}, [uploadExcelDetails]);

  const columnsGetUploadDetails = [
    {
      title: "VIEW ERROR DETAILS",
      field: "errorData",
      //size: 500, //small column
    },
    {
      title: "Upload Date",
      field: "uploadDate",
      type: "date",
      dateSetting: { locale: "en-GB" },
    },
    {
      title: "TOTAL NO. OF WORK ITEMS",
      field: "totalNoOfWorkitems",
    },
    {
      title: "TOTAL NO. OF WORK ITEMS SUCCESFULLY UPLOADED",
      field: "noOfWorkitemsUpload",
    },
    {
      title: "No Of Error Items",
      field: "noOfErrorItems",
    },
  ];

  const listUploadAndAssignmentColumns = [
    { title: "WorkItemID", field: "workItemID" },
    {
      title: "ScanDate",
      field: "scanDate",
      type: "date",
      dateSetting: { locale: "en-GB" },
    },
    { title: "BatchNo", field: "batchNo" },
    { title: "TotalWorkItems", field: "totalWorkItems" },
    {
      title: "Upload Date",
      field: "uploadDate",
      type: "date",
      dateSetting: { locale: "en-GB" },
    },
    { title: "Uploaded By", field: "uploadedBy" },
    { title: "Processing", field: "processing" },
    { title: "Reviewer", field: "reviewer" },
    { title: "Client", field: "client" },
    { title: "EXLExpert", field: "exlExpert" },
  ];
  const handleApplyUpload = (event, picker) => {
    setuplodadedDateTimeFrom(picker.startDate.format("MM/DD/YYYY"));
    setuplodadedDateTimeTo(picker.endDate.format("MM/DD/YYYY"));
  };

  const GetUploadHistoryData = () => {
    if (uplodadedDateTimeFrom == "" || uplodadedDateTimeTo == "") {
      alert("Plase select From Date and To Date");
      return false;
    }

    let body = {
      subprocessId: SelectedSubprocess,
      fromDate: uplodadedDateTimeFrom,
      toDate: uplodadedDateTimeTo,
    };
    let activityUrl = getAPIURL(REACT_APP_GETUPLOADHISTORYDATA);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setUploadHistoryData(result.data.uploadeHistoryData);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const uploadExcel = () => {
    setisFigma(true);
    setdepartment(false);
    setclient(false);
    setsubprocess(false);
    setdateRange(false);
    setfileToUpload(false);
    setshowUpload(false);
    if (workSheet == "Sheet1") {
      // if (json.length < 1000) {
      let is_Configuration = true;
      let count = 0;
      let configuredValue = [];
      ExcelColumnsConfig.forEach((element) => {
        let column = element.LabelText;
        configuredValue.push(column);
      });

      let uploadingValue = Object.keys(json[0]);
      //Added by Pradip Rai
      let uploadExcelHeader = ExcelColumnsSheet.split("\n");
      uploadExcelHeader = uploadExcelHeader[0].split(",");
      //Ended by Pradip Rai

      // for (let i = 0; i < uploadingValue.length; i++) {
      //   if (!configuredValue.includes(uploadingValue[i])) {
      //     is_Configuration = false;
      //     break;
      //   }
      // }
      for (let i = 0; i < configuredValue.length; i++) {
        //if (!uploadingValue.includes(configuredValue[i])) {
        if (!uploadExcelHeader.includes(configuredValue[i])) {
          is_Configuration = false;
          break;
        }
      }
      if (is_Configuration) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        let loginUrl = getAPIURL(REACT_APP_GETUPLOADFILEDETAILS);
        let req = new Request(`${loginUrl}`, {
          method: "POST",
          headers: {
            //"Content-Type": "multipart/form-data",
            UserName: sessionStorage.getItem("UserName"),
            Authorization:
              "Bearer " + sessionStorage.getItem("authorizationToken")
          },
          body: formData,
        });
        fetch(req)
          .then((res) => res.json())
          .then((result) => {
            if (result.path !== "") {
              // console.log(result.path);
              uploadExcelDataDetails(result.path);
              setSelectedDepartment("Select");
              setSelectedLocation("Select");
              setsubprocessValue("Select");
              setdate("");
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
            //navigate("/");
          });
        setdepartment(true);
        setclient(true);
        setsubprocess(true);
        setdateRange(true);
        setfileToUpload(true);
        setisFigma(false);
        setuploadedFile("");
      } else {
        setisFigma(false);
        setdepartment(true);
        setclient(true);
        setsubprocess(true);
        setdateRange(true);
        setfileToUpload(true);
        setuploadedFile("");
        addToast(
          "Upload File does not match with the Excel Configuration defined in Admin - Upload Excel Columns.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
    //else {
    //     setisFigma(false);
    //     setdepartment(true);
    //     setclient(true);
    //     setsubprocess(true);
    //     setdateRange(true);
    //     setfileToUpload(true);
    //     setuploadedFile("");
    //     addToast(
    //       "The uploaded excel has " +
    //         json.length +
    //         " rows. Only " +
    //         1000 +
    //         " rows can be uploaded in production dashboard at a time.",
    //       {
    //         appearance: "error",
    //         autoDismiss: true
    //       }
    //     );
    //   }
    else {
      setisFigma(false);
      setdepartment(true);
      setclient(true);
      setsubprocess(true);
      setdateRange(true);
      setfileToUpload(true);
      setuploadedFile("");
      addToast(
        "The excel should contain a sheet named - Sheet1. Confirm that it exists and try again. If the problem persists, contact the Production Dashboard Team.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  };
  const getBatchUpload = (e) => {
    setBatchUploadValue(e.target.value);
  };

  //Added By Trupti

  const viewWorkitemShow = (item) => {
    setviewWorkitemModal(true);
    setCurrentBatchNumber(item.BatchItemID);
    setApplicableUploadDate(item.ApplicableUploadDate);
    getSelectedWorkItem(item.BatchItemID, item.ApplicableUploadDate);
  };

  //Added By Trupti
  const getSelectedWorkItem = (BatchItemID, UploadDate) => {
    setisExcelGrid(true);
    UploadDate = UploadDate.split(" ")[0];
    let body = {
      SubProcessId: sessionStorage.getItem("ProcessId"),
      BatchItemID: BatchItemID,
      UploadDate: UploadDate,
      WorkItemMode:
        sessionStorage.getItem("WorkitemMode") == null
          ? 0
          : +sessionStorage.getItem("WorkitemMode"),
      personId: sessionStorage.getItem("PersonID"),
    };
    let loginUrl = getAPIURL(
      REACT_APP_GETWORKITEMUPLOADDETAILSFORSUBPROCESSANDBATCH
    );
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          // console.log(result.data);
          setworkitemDetails(result.data.uploadAndAssignData);
          setisExcelGrid(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const uploadExcelDataDetails = (path) => {
    sessionStorage.setItem("WorkitemMode", "0");
    setisExcelGrid(true);

    // let uploadType = "All";
    // if (displayCriteriaIndex.current.value == 0) uploadType = "UnAssigned";
    // else if (displayCriteriaIndex.current.value == 1) uploadType = "Self";
    // else if (displayCriteriaIndex.current.value == 2) uploadType = "All";

    let body = {
      filePath: path,
      subprocessId: SelectedSubprocess,
      userId: sessionStorage.getItem("GUID"),
      personId: +sessionStorage.getItem("PersonID"),
      rblBatchUploadType: rblBatchUploadTypeVisible,
      rblBatchUploadTypeValue: BatchUploadValue,
      rblBatchUploadTypeIndex: 0,
      rbautoassign: autoAssigned,
      uploadDate: wdcUploadDateValue,
      allocationUnit: AllocationUnit,
      fileName: autoAssignedForFileName === "on" ? selectedFile.name : "",
    };
    let loginUrl = getAPIURL(REACT_APP_UPLOADEXCELDATA);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          let body = {
            allocationUnit: AllocationUnit,
            subprocessId: SelectedSubprocess,
            uploadDate: wdcUploadDateValue,
            rblBatchUploadTypeValue: BatchUploadValue,
            rblBatchUploadTypeIndex: 0,
            userId: sessionStorage.getItem("GUID"),
            personId: sessionStorage.getItem("PersonID"),
            uploadType: "UnAssigned",
            UserFullName: sessionStorage.UserFullName,
          };
          GetUploadData(body);
          displayCriteriaIndex.current.value = "0";
          // debugger;
          // if (result.data.dataGrid != null && result.data.dataGrid.length > 0) {
          //   // debugger;
          //   setuploadExcelDetails(result.data.dataGrid);

          //   updateColumns(Object.keys(result.data.dataGrid[0]));
          // }
          sessionStorage.setItem("WorkitemMode", "0");
          if (
            result.data.wdgDuplicateRecords != null &&
            result.data.wdgDuplicateRecords.length > 0
          ) {
            setIsDuplicate(true);
            updateDuplicateColumns(
              Object.keys(result.data.wdgDuplicateRecords[0])
            );
            setduplicateExcelDetails(result.data.wdgDuplicateRecords);
          }

          //setisExcelGrid(false);
          //tableRef1.current.onQueryChange();
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setisExcelGrid(false);
        }
        if (result.data != null && result.data != undefined) {
          if (result.data.lblRowsUploaded != null) {
            let message = result.data.lblRowsUploaded.split("</b>")[1];
            addToast(message, {
              appearance: "success",
              autoDismiss: true,
            });
          }
          if (result.data.litErrors != null) {
            let errorMessage = result.data.litErrors;
            addToast(errorMessage, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const updateDuplicateColumns = (keys) => {
    let col = [];
    keys.forEach((item) => {
      col.push({ title: item, field: item });
    });
    //col.pop();
    setDuplicateColumns(col);
  };
  const updateColumns = (keys) => {
    let col = [];
    keys.forEach((item) => {
      if (rblBatchUploadTypeVisible) rblBatchUploadTypeVisibleValue = true;

      if (rblBatchUploadTypeVisibleValue) {
        if (
          item != "BatchItemID" &&
          item != "UploadDetailID" &&
          item != "SubProcessID" &&
          item != "CountofWorkitem"
        )
          if (item == "ApplicableUploadDate")
            col.push({ title: "Upload Date", field: item });
          else if (item == "BatchNo")
            col.push({ title: "Batch No", field: item });
          else if (item == "TotalWorkItems")
            col.push({ title: "Total WorkItems", field: item });
          else if (item == "PendingWorkItems")
            col.push({ title: "Pending WorkItems", field: item });
          else if (item == "UploadedBy")
            col.push({ title: "Uploaded By", field: item });
          else col.push({ title: item, field: item });
      } else {
        if (
          item != "BatchItemID" &&
          item != "UploadDetailID" &&
          item != "ParentWorkItemID" &&
          item != "Select1" &&
          item != "UploadDetailID" &&
          item != "ParentWorkItemID"
        )
          if (item == "ApplicableUploadDate")
            col.push({ title: "Upload Date", field: item });
          else col.push({ title: item, field: item });
      }
    });
    //col.pop();
    setcolumns(col);
  };
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const fetchSubProcessUploadExcelColumnsOnChange = (subprocessValue) => {
    let uploadType = "All";
    if (displayCriteriaIndex.current.value == 0) uploadType = "UnAssigned";
    else if (displayCriteriaIndex.current.value == 1) uploadType = "Self";
    else if (displayCriteriaIndex.current.value == 2) uploadType = "All";
    setisLoading_Subprocess(true);
    setdepartment(!department);
    setclient(!client);
    setsubprocess(!subprocess);
    setdateRange(!dateRange);
    setfileToUpload(!fileToUpload);
    //setshowUpload(!showUpload);
    let body = {
      subProcessId: subprocessValue,
      userid: sessionStorage.getItem("GUID"),
      applicationName: REACT_APP_NAME,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_SUBPROCESSUPLOADEXCELCOLUMNS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setwdcUploadDateMaxValue(
            result.data.wdcUploadDateMaxValue.split("T")[0]
          );
          setwdcUploadDateValue(result.data.wdcUploadDateValue.split("T")[0]);
          setAllocationUnit(result.data.allocationUnit);
          setShowDetailIcon(result.data.allocationUnit); // Trupti
          setUploadExcelColumnsStatus(true);
          setExcelColumnsConfig(result.data.columnSchema);
          setdivCompanyDepartmentVisible(
            result.data.divCompanyDepartmentVisible
          );
          setdivLocationClientVisible(result.data.divLocationClientVisible);
          setdivProcessSubProcessVisible(
            result.data.divProcessSubProcessVisible
          );
          setbtnUploadVisible(result.ata.btnUploadVisible);
          setfuUploadExcelEnabled(result.data.fuUploadExcelEnabled);
          setfuUploadExcelEnabled(result.data.fuUploadExcelEnabled);
          setdivBatchUploadTypeVisible(result.data.divBatchUploadTypeVisible);
          rblBatchUploadTypeVisibleValue =
            result.data.rblBatchUploadTypeVisible;
          setrblBatchUploadTypeVisible(result.data.rblBatchUploadTypeVisible);
          let body = {
            allocationUnit: result.data.allocationUnit,
            subprocessId: sessionStorage.getItem("ProcessId"),
            uploadDate: result.data.wdcUploadDateValue.split("T")[0],
            rblBatchUploadTypeValue: BatchUploadValue,
            rblBatchUploadTypeIndex: 0,
            personId: sessionStorage.getItem("PersonID"),
            // userId: sessionStorage.getItem("GUID"),
            // uploadType: "UnAssigned",
            uploadType: uploadType,
            UserFullName: sessionStorage.UserFullName,
          };
          GetUploadData(body);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Something went wrong!", {
            appearance: "error",
            autoDismiss: true,
          });
        }
        setisExcelGrid(false);
        //set_IsLoading(false);
        setisLoading_Subprocess(false);
        setdepartment(department);
        setclient(client);
        setsubprocess(subprocess);
        setdateRange(dateRange);
        setfileToUpload(fileToUpload);
        //setshowUpload(showUpload);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const fetchSubProcessUploadExcelColumns = () => {
    let uploadType = "All";
    if (displayCriteriaIndex.current.value == 0) uploadType = "UnAssigned";
    else if (displayCriteriaIndex.current.value == 1) uploadType = "Self";
    else if (displayCriteriaIndex.current.value == 2) uploadType = "All";
    let body = {
      subProcessId:
        subprocessValue === "" || subprocessValue === "Select"
          ? sessionStorage.getItem("ProcessId")
          : subprocessValue,
      userid: sessionStorage.getItem("GUID"),
      applicationName: REACT_APP_NAME,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_SUBPROCESSUPLOADEXCELCOLUMNS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setwdcUploadDateMaxValue(
            result.data.wdcUploadDateMaxValue.split("T")[0]
          );
          setwdcUploadDateValue(result.data.wdcUploadDateValue.split("T")[0]);
          setAllocationUnit(result.data.allocationUnit);
          setShowDetailIcon(result.data.allocationUnit); // Trupti
          setUploadExcelColumnsStatus(true);
          setExcelColumnsConfig(result.data.columnSchema);
          setdivCompanyDepartmentVisible(
            result.data.divCompanyDepartmentVisible
          );
          setdivLocationClientVisible(result.data.divLocationClientVisible);
          setdivProcessSubProcessVisible(
            result.data.divProcessSubProcessVisible
          );
          setbtnUploadVisible(result.data.btnUploadVisible);
          setfuUploadExcelEnabled(result.data.fuUploadExcelEnabled);
          setfuUploadExcelEnabled(result.data.fuUploadExcelEnabled);
          setdivBatchUploadTypeVisible(result.data.divBatchUploadTypeVisible);
          rblBatchUploadTypeVisibleValue =
            result.data.rblBatchUploadTypeVisible;
          setrblBatchUploadTypeVisible(result.data.rblBatchUploadTypeVisible);
          let body = {
            allocationUnit: result.data.allocationUnit,
            subprocessId: sessionStorage.getItem("ProcessId"),
            uploadDate: result.data.wdcUploadDateValue.split("T")[0],
            rblBatchUploadTypeValue: BatchUploadValue,
            rblBatchUploadTypeIndex:
              sessionStorage.getItem("WorkitemMode") == null
                ? 0
                : +sessionStorage.getItem("WorkitemMode"),
            personId: sessionStorage.getItem("PersonID"),
            userId: sessionStorage.getItem("GUID"),
            //uploadType: "All",
            uploadType: uploadType,
            UserFullName: sessionStorage.UserFullName,
          };
          GetUploadData(body);
          //tableRef1.current.onQueryChange();
          //setisExcelGrid(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          addToast("Something went wrong!", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const setJsonValues = () => {
    if (json.length < 3000) {
      const reader = new FileReader();
      setexcelValue(json);
      reader.readAsArrayBuffer(files);
      setshowUpload(true);
    } else {
      addToast(
        "The uploaded excel has " +
          json.length +
          " rows. Only " +
          3000 +
          " rows can be uploaded in production dashboard at a time.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  };
  const getDate = (e) => {
    if (e.target.value > wdcUploadDateMaxValue) {
      addToast("Selected Date is exceeding the max range", {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      setwdcUploadDateValue(e.target.value);
    }
  };
  const readUploadFile = (e) => {
    setuploadedFile(e.target.value);
    setselectedFile(e.target.files[0]);
    setshowUpload(false);
    e.preventDefault();
    if (sessionStorage.getItem("ProcessId") != null) {
      if (
        e.target.files[0].type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        e.target.files[0].type == "application/vnd.ms-excel"
      ) {
        if (e.target.files[0].size < 3145728) {
          if (ExcelColumnsConfig.length >= 0) {
            if (e.target.files) {
              const reader = new FileReader();
              reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                /***********Added Pradip Rai**************/
                const wb = XLSX.read(data, { type: "binary" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const localExcelColumnsSheet = XLSX.utils.sheet_to_csv(ws, {
                  header: 1,
                });
                setExcelColumnsSheet(localExcelColumnsSheet);
                /*****Pradip Ended*******/
                setexcelValue(json);
                setworkSheet(sheetName);
                setjson(json);

                //setfiles(e.target.files[0]);
                //console.log(e.target.files[0])
              };
              reader.readAsArrayBuffer(e.target.files[0]);
            }
            setshowUpload(true);
          } else {
            setuploadedFile("");
            addToast(
              "Your SubProcess does not have a upload configuration, hence we cannot upload any workitems. Please contact the Production Dashboard Team.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }
        } else {
          setuploadedFile("");
          addToast(
            "This file is too big in size. Please select a file with size 3 MB.",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      } else {
        setuploadedFile("");
        addToast("Please select an excel file.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      setuploadedFile("");
      addToast("Please select a sub-process.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const FetchLocationClientsByGuid = (e) => {
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setClientLocations([]);
      setprocessSubProcessID("");
      setlocationClientID("");
    }
    setSelectedDepartment(e.target.value);
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: e.target.value,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartmentbyGUID(true);
          setDepartments(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const ProcessSubprocessOnChange = (e) => {
    //setisLoading_Subprocess(true)
    setsubprocessValue(e.target.value.split("/")[0]);
    setSelectedSubprocess(e.target.value.split("/")[0]);
    setprocessSubProcessID(e.target.value.split("/")[0]);
    fetchSubProcessUploadExcelColumnsOnChange(e.target.value.split("/")[0]);
    sessionStorage.setItem("ProcessId", e.target.value.split("/")[0]);
    sessionStorage.setItem(
      "SelectedSubprocessName",
      e.target.value.split("/")[1]
    );
  };
  const FetchProcessSubProcessByGuid = (e) => {
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setprocessSubProcessID("");
    }
    setSelectedLocation(e.target.value);
    setlocationClientID(e.target.value);
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const FetchDefaultValue = () => {
    setcompanyDeparmentID(sessionStorage.getItem("selectedDepartment"));
    //setcompanyDepartment(result.data.companyDepartment);
    FetchLocationClientsByGuidOnPageLoad(
      sessionStorage.getItem("selectedDepartment")
    );
    setlocationClientID(sessionStorage.getItem("selectedLocation"));
    //setlocationClient(result.data.locationClient);
    FetchProcessSubProcessByGuidOnPageLoad(
      sessionStorage.getItem("selectedLocation")
    );
    setprocessSubProcessID(sessionStorage.getItem("ProcessId"));
    //setprocessSubProcess(result.data.processSubProcess);
    FetchSubprocessName(
      sessionStorage.getItem("ProcessId"),
      sessionStorage.getItem("selectedLocation")
    );
    preferenceDefault(sessionStorage.getItem("ProcessId"));
  };
  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          sessionStorage.setItem(
            "SubprocessName",
            result.data[0].processSubProcess
          );
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };
  const getAutoAssigned = (e) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setautoAssigned("on");
      setauto(true);
    } else {
      setautoAssigned("off");
      setauto(false);
    }
  };
  const getAutoAssignedForIncludingFileName = (e) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setautoAssignedForFileName("on");
      setautoForFileName(true);
    } else {
      setautoAssignedForFileName("off");
      setautoForFileName(false);
    }
  };
  const preferenceDefault = (processSubProcessID) => {
    setSelectedSubprocess(processSubProcessID);
    sessionStorage.setItem("ProcessId", processSubProcessID);
    let body = {
      subprocessID: processSubProcessID,
      personID: sessionStorage.getItem("PersonID"),
      personType: sessionStorage.getItem("PersonType"),
      userName: sessionStorage.getItem("UserName"),
      HRISEmployeeID: sessionStorage.getItem("HRISEmployeeID"),
      ApplicationName: sessionStorage.getItem("ApplicationURL"),
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_ASSIGNEDWORKITEMDETAILSFORUSER);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setAssignedWorkItemDetails(result.data);
          //setnav(result.data);
          // const navdata = result.data;
          // assignedWorkItemDetails.map((item) => {
          //   statusArr.push(getList(assignedWorkItemDetails, item.activityName));
          // });
          // setList(statusArr);
          // set_isLoadingDashboard(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  var rblBatchUploadTypeVisibleValue = false;
  useEffect(() => {
    setIsDuplicate(false);
    setdependency(processingDashboardParam.selectedSubprocess);
    setRefreshData(false);
    FetchDepartmentbyGUID();
    FetchDefaultValue();
    fetchSubProcessUploadExcelColumns();
    setviewWorkitemModal(false);
    //  setuploadExcelDetails([]);
  }, [RefreshData]);

  const deleteWorkItem = (rowdata) => {
    
    const confirmBox = window.confirm(
      "Are you sure want to delete this record?"
    );
    if (confirmBox === true) {
      let allocationType = "";
      let WorkitemType = "";

      if (rowdata[0].WorkItemID != undefined) {
        setAllocationUnit("Workitem");
        allocationType = "Workitem";
        WorkitemType = "Workitem";
      }
      else if (rowdata[0].workItemID != undefined) {
        setAllocationUnit("Workitem");
        allocationType = "Workitem";
        WorkitemType = "BatchWorkitem";
      } 
      else {
        setAllocationUnit("Batch");
        allocationType = "Batch";
        WorkitemType = "Batch";
      }

      setWorkItemIds([]);

      if (allocationType.toUpperCase() == "BATCH") {
        for (let i = 0; i < rowdata.length; i++) {
          WorkItemIDs.push(parseInt(rowdata[i].BatchItemID));
        }
      } else if (rowdata[0].WorkItemID != undefined) {
        for (let i = 0; i < rowdata.length; i++) {
          WorkItemIDs.push(parseInt(rowdata[i].WorkItemID));
        }
      }
      else if (rowdata[0].workItemID != undefined) {
        for (let i = 0; i < rowdata.length; i++) {
          WorkItemIDs.push(parseInt(rowdata[i].workItemID));
        }
      }
      deleteWorkItemAPI(WorkItemIDs, allocationType, WorkitemType);
    }
  };
  const downloadExcel = () => {
    const fileName = "UploadHistory";
    const exportType = "xls";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(uploadHistoryData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const deleteWorkItemAPI = (WorkItemIDs, allocationType, WorkitemType) => {
    let body = {
      WorkItemIDs: WorkItemIDs,
      BatchIDs: WorkItemIDs,
      allocationUnit: allocationType,
      subprocessId: SelectedSubprocess,
      uploadDate: wdcUploadDateValue.split(" ")[0],
      rblBatchUploadTypeValue: BatchUploadValue,
      rblBatchUploadTypeIndex:
        sessionStorage.getItem("WorkitemMode") == null
          ? 0
          : +sessionStorage.getItem("WorkitemMode"), //parseInt(displayCriteriaIndex.current.value),
      personId: sessionStorage.getItem("PersonID"),
    };

    let loginUrl = getAPIURL(REACT_APP_WORKITEMDELETE);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          if (result.data.dataGrid != null && result.data.dataGrid.length > 0) {
            setuploadExcelDetails(result.data.dataGrid);
            setuploadExcelDetailsoriginal(result.data.dataGrid);
            settotalCount(result.data.dataGrid.length);
            setuploadExcelDetails(result.data.dataGrid.slice(0, 5));
            setPageNo(0);
            setPage(5);
            setRefreshData(true);
            //set_IsLoading(false);
          }

          addToast(result.data.successRecordCount, {
            appearance: "success",
            autoDismiss: true,
          });

          if (result.data.failidWorkItemList != null)
            addToast(result.data.failidWorkItemList, {
              appearance: "error",
              autoDismiss: true,
            });

          if (WorkitemType === "BatchWorkitem") {
            setviewWorkitemModal(true);
            getSelectedWorkItem(CurrentBatchNumber, ApplicableUploadDate);
          }
          fetchSubProcessUploadExcelColumns();
        } else if (result.status === 401) {
          addToast(
            "Workitem has been not set or Session Expired/UnAuthorized. Please LogIn Again",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          sessionStorage.clear();
          
          
          navigate("/");
        }
      })
      .catch((error) => {
        set_IsLoading(false);
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const GetUploadData = (body) => {
    setisExcelGrid(true);
    let loginUrl = getAPIURL(REACT_APP_FETCHUPLOADEDDATA);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        
        if (result.status === 200) {
          if (result.data.dataGrid != null ) 
            {
            if(result.data.dataGrid.length > 0)
              {
            updateColumns(Object.keys(result.data.dataGrid[0]));
            setuploadExcelDetails(result.data.dataGrid);
            setuploadExcelDetailsoriginal(result.data.dataGrid);
            settotalCount(result.data.dataGrid.length);
            setuploadExcelDetails(result.data.dataGrid.slice(0, 5));
            setPageNo(0);
            setPage(5);
            set_IsLoading(false);
            setisExcelGrid(false);
            // let DropDown = [];
            var temp = result.data.assignmentDetails.map((e) => ({
              value: e.activityID,
              label: e.activityName,
            }));
            // console.log(temp);
            setArr(temp);
            // console.log(Arr);
          }
          else {
            //updateColumns(Object.keys(result.data.dataGrid[0]));
            setuploadExcelDetails(result.data.dataGrid);
            setuploadExcelDetailsoriginal(result.data.dataGrid);
            settotalCount(result.data.dataGrid.length);
            setuploadExcelDetails(result.data.dataGrid.slice(0, 5));
            setPageNo(0);
            setPage(5);
            setisExcelGrid(false);
          }
        }
        setisExcelGrid(false);
       } 
       else if (result.status === 401) {
          addToast(
            "Workitem has been not set or Session Expired/UnAuthorized. Please LogIn Again",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          set_IsLoading(false);
          //tableRef1.current.onQueryChange();
          setisExcelGrid(false);
        }
      })
      .catch((error) => {
        set_IsLoading(false);
        console.log("ERROR: ", error);
      });
    //tableRef1.current.onQueryChange();
    //setisExcelGrid(false);
  };
  const PopUpOpener = (rowdata) => {
    const confirmBox = window.confirm(
      "Are you sure you want to assign the selected work item(s)"
    );
    if (confirmBox === true) {
      let allocationType = "";

      // console.log("rowdata", rowdata);
      if (
        rowdata[0].WorkItemID != undefined ||
        rowdata[0].workItemID != undefined
      ) {
        setAllocationUnit("Workitem");
        allocationType = "Workitem";
      } else {
        setAllocationUnit("Batch");
        allocationType = "Batch";
      }

      let WorkItemIDs = [];
      let BatchItemIDs = [];

      if (allocationType.toUpperCase() == "BATCH") {
        for (let i = 0; i < rowdata.length; i++) {
          BatchItemIDs.push(rowdata[i].BatchItemID);
        }
        setBatchItemIds(BatchItemIDs);
      } else {
        if (rowdata[0].WorkItemID != undefined) {
          for (let i = 0; i < rowdata.length; i++) {
            WorkItemIDs.push(parseInt(rowdata[i].WorkItemID));
          }
        } else if (rowdata[0].workItemID != undefined) {
          for (let i = 0; i < rowdata.length; i++) {
            WorkItemIDs.push(parseInt(rowdata[i].workItemID));
          }
        }
        setWorkItemIds(WorkItemIDs);
      }
      setOpenPopUp(true);
    }
  };
  const PopUpCloser = () => {
    setAdd([]);
    setOpenPopUp(false);
  };

  const AssignmentDropDown = (e) => {
    //setAdd({...Add,[e.target.name]:e.target.SelectedIndex});
    var data = SelectedDashboardref.current.value;
    let body = {
      SubProcessId: processingDashboardParam.selectedSubprocess,
      ActivityID: data,
    };
    let loginUrl = getAPIURL(REACT_APP_ASSIGNMENTDROPDOWN);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        set_IsLoading(false);
        // debugger;
        var temp = result.data;
        // console.log(temp);
        setEmp(temp);
      })
      .catch((error) => {
        set_IsLoading(false);
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const handleSave = () => {
    let body = {
      SubProcessId: processingDashboardParam.selectedSubprocess,
      ActivityandEmployee: Add,
      BatchItemIDs: BatchItemIds,
      personId: sessionStorage.getItem("PersonID"),
      WorkItemIDs: WorkItemIDs,
      AllocationUnit: AllocationUnit,
    };

    let loginUrl = getAPIURL(REACT_APP_ASSIGNWORKITEM);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        set_IsLoading(false);
        
        if (result.status === 200) {
          //setcolumns([]);
          setuploadExcelDetails([]);
          setuploadExcelDetailsoriginal([]);
          // setRefreshData(true);

          setcolumns([]);
          setOpenPopUp(false);
          setAdd([]);

          if (AllocationUnit === "Workitem" && viewWorkitemModal == true) {
            setviewWorkitemModal(true);
            getSelectedWorkItem(CurrentBatchNumber, ApplicableUploadDate);
          }
          fetchSubProcessUploadExcelColumns();
          //displayCriteriaIndex.current.value = "0";
        }
      })
      .catch((error) => {
        set_IsLoading(false);
        console.log("ERROR: ", error);
        //navigate("/");
      });

    setWorkItemIds([]);
  };
  const handleAssign = (e) => {
    var assignedDashboard = SelectedDashboardref.current.value;
    var assignedEmployee = SelectedEmployeeref.current.value;

    if (assignedDashboard === "Select" || assignedEmployee === "Select") {
      alert("Please select Activity and Employee");
      SelectedDashboardref.current.selectedIndex = 0;
      SelectedEmployeeref.current.selectedIndex = 0;
      return false;
    }

    var activityName =
      SelectedDashboardref.current[SelectedDashboardref.current.selectedIndex]
        .text;
    var EmployeeName =
      SelectedEmployeeref.current[SelectedEmployeeref.current.selectedIndex]
        .text;

    var temp1 = [];
    // setAdd((temp1)=>[...temp1,{employeeVersionID: assignedEmployee, activity:assignedDashboard
    // ,activityName:activityName,employeeName:EmployeeName}]);

    setAdd((temp1) => {
      const isDuplicate = temp1.some(
        (item) => item.activityName === activityName
      );
      if (!isDuplicate) {
        return [
          ...temp1,
          {
            employeeVersionID: assignedEmployee,
            activity: assignedDashboard,
            activityName: activityName,
            employeeName: EmployeeName,
          },
        ];
      } else {
        // If it's a duplicate, return the original array unchanged
        alert("Activity already assigned.");
        return temp1;
      }
    });

    SelectedDashboardref.current.selectedIndex = 0;
    SelectedEmployeeref.current.selectedIndex = 0;
  };
  let advanceColumns = [
    { title: "Employee Name", field: "employeeName" },
    { title: "Activity Name", field: "activityName" },
  ];
  const handleRowDelete = (oldData, resolve) => {
    const dataDelete = [...Add];
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    setAdd([...dataDelete]);
    resolve();
  };

  const DisplayCriteriaOnChange = (e) => {
    setisExcelGrid(true);
    sessionStorage.setItem("WorkitemMode", e.target.value);
    setworkitemMode(e.target.value);

    let uploadType = "All";
    if (e.target.value == "0") uploadType = "UnAssigned";
    else if (e.target.value == "1") uploadType = "Self";

    setuploadExcelDetails([]);
    setuploadExcelDetailsoriginal([]);

    let body = {
      allocationUnit: AllocationUnit,
      subprocessId: SelectedSubprocess,
      uploadDate: wdcUploadDateValue,
      userId: sessionStorage.getItem("GUID"),
      personId: sessionStorage.getItem("PersonID"),
      uploadType: uploadType, // e.target.value, //"Self",//UnAssigned
      UserFullName: sessionStorage.UserFullName,
      //rblBatchUploadType: rblBatchUploadTypeVisible,
      //rblBatchUploadTypeValue: BatchUploadValue,
      rblBatchUploadTypeIndex: parseInt(displayCriteriaIndex.current.value),
    };

    let loginUrl = getAPIURL(REACT_APP_WORKITEMSHOWCONDITION);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        set_IsLoading(false);
        
        if (result.data.dataGrid != null ) {
          if(result.data.dataGrid.length > 0)
            {
          //setuploadExcelDetails(result.data.dataGrid);
          updateColumns(Object.keys(result.data.dataGrid[0]));
          setuploadExcelDetails(result.data.dataGrid);
          setuploadExcelDetailsoriginal(result.data.dataGrid);
          settotalCount(result.data.dataGrid.length);
          setuploadExcelDetails(result.data.dataGrid.slice(0, 5));
          setPageNo(0);
          setPage(5);

          // let DropDown = [];
          var temp = result.data.assignmentDetails.map((e) => ({
            value: e.activityID,
            label: e.activityName,
          }));
          // console.log(temp);
          setArr(temp);
          // console.log(Arr);
        //}
        } else {
          setuploadExcelDetails(result.data.dataGrid);
          setuploadExcelDetailsoriginal(result.data.dataGrid);
          settotalCount(result.data.dataGrid.length);
          setuploadExcelDetails(result.data.dataGrid.slice(0, 5));
          setPageNo(0);
          setPage(5);
        }
      }

        setisExcelGrid(false);
      })

      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
    //tableRef1.current.onQueryChange();
  };

  return (
    <>
      <Header
        onpreferenceShow={null}
        pageName="Upload and Assignment"
        timeCaptureActivityName="Work Item Management"
      />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="activities" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="centerSection">
            <div className="pageBody">
              <div className="dashBoxs mb-3">
                <div className="formWrap">
                  <div className="row align-items-end">
                    {divCompanyDepartmentVisible ? (
                      <div className="col-3">
                        <div className="mb-3">
                          <label className="form-label">
                            Company Department
                          </label>
                          <select
                            className="form-control form-control-sm"
                            onChange={FetchLocationClientsByGuid}
                            disabled={!department}
                            //value={selectedDepartment}
                          >
                            <option>Select</option>
                            {departments.map((department) => (
                              <option
                                value={department.departmentIDString}
                                selected={
                                  department.departmentIDString ==
                                  companyDeparmentID
                                }
                              >
                                {department.companyDepartment}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : null}
                    {divLocationClientVisible ? (
                      <div className="col-3">
                        <div className="mb-3">
                          <label className="form-label">Location Client</label>
                          <select
                            className="form-control form-control-sm"
                            onChange={FetchProcessSubProcessByGuid}
                            disabled={!client}
                            //value={selectedLocation}
                          >
                            <option>Select</option>
                            {clientLocations.map((location) => (
                              <option
                                value={location.clientIDString}
                                selected={
                                  location.clientIDString == locationClientID
                                }
                              >
                                {location.locationClient}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : null}
                    {divProcessSubProcessVisible ? (
                      <div className="col-3">
                        <div className="mb-3">
                          <label className="form-label">
                            Process Sub-process
                          </label>
                          <select
                            className="form-control form-control-sm"
                            onChange={ProcessSubprocessOnChange}
                            disabled={!subprocess}
                            //value={subprocessValue}
                          >
                            <option>Select</option>
                            {processSubprocess.map((subprocess) => (
                              <option
                                value={
                                  subprocess.subProcessIDString +
                                  "/" +
                                  subprocess.processSubProcess
                                }
                                selected={
                                  subprocess.subProcessIDString ==
                                  processSubProcessID
                                }
                              >
                                {subprocess.processSubProcess}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : null}
                    <div className="col-3">
                      <div className="mb-3">
                        <label className="form-label">Upload Date</label>
                        {/* <DateRangePicker
                      initialSettings={{
                        startDate: "04/01/2022",
                        endDate: "05/21/2022",
                        autoApply: true,
                        opens: "left",
                      }}
                    > */}
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          onChange={getDate}
                          value={wdcUploadDateValue}
                          disabled={!dateRange}
                        />
                        {/* </DateRangePicker> */}
                      </div>
                    </div>
                    {fuUploadExcelEnabled ? (
                      <div className="col-3">
                        <div>
                          <label className="form-label">File to upload</label>
                          <input
                            className="form-control form-control-sm"
                            type="file"
                            onChange={readUploadFile}
                            disabled={!fileToUpload}
                            value={uploadedFile}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className="col-2" style={{ paddingBottom: "1px" }}>
                      {" "}
                      <div className="form-check form-switch mb-0 pt-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={auto}
                          onChange={getAutoAssigned}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                          //disabled="false"
                        >
                          Auto Assignment Mode
                        </label>
                      </div>
                    </div>
                    <div className="col-2" style={{ paddingBottom: "1px" }}>
                      {" "}
                      <div className="form-check form-switch mb-0 pt-1">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefaultForFileName"
                          checked={autoForFileName}
                          onChange={getAutoAssignedForIncludingFileName}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefaultForFileName"
                          //disabled="false"
                        >
                          Include File Name
                        </label>
                      </div>
                    </div>
                    {divBatchUploadTypeVisible && rblBatchUploadTypeVisible ? (
                      <>
                        <div
                          className="col-2"
                          style={{ paddingLeft: "5px", paddingTop: "2px" }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            //checked="yes"
                            value="Batch Upload"
                            onChange={getBatchUpload}
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio1"
                          >
                            Batch Upload
                          </label>
                        </div>
                        <div
                          className="col-2"
                          style={{ paddingleft: "5px", paddingtop: "2px" }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            checked="yes"
                            value="Detailed Batch Upload"
                            onChange={getBatchUpload}
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio1"
                          >
                            Detailed Batch Upload
                          </label>
                        </div>
                      </>
                    ) : null}

                    {btnUploadVisible ? (
                      <div className="col-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary ms-2"
                            onClick={() => {
                              uploadExcel();
                            }}
                            disabled={!showUpload}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* {isLoading_Subprocess ? <LoadingSpinnerFigma /> : null} */}
                </div>
              </div>

              <div className="dashBoxs">
                <div className="dashBoxs-header pb-2 pt-2">
                  <div className="row align-items-center">
                    <div className="col-4">
                      <h6>Upload Details</h6>
                      {/* //<h5>Processing Dashboard</h5> */}
                    </div>
                    <div className="col-8">
                      <div className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2">
                        <div className="d-inline">
                          <div className="input-group input-group-sm">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                            >
                              Show
                            </label>
                            <select
                              className="form-select"
                              id="inputGroupSelect01"
                              onChange={(e) => DisplayCriteriaOnChange(e)}
                              ref={displayCriteriaIndex}
                              defaultValue="0"
                            >
                              {sessionStorage.getItem("WorkitemMode") ==
                              null ? (
                                <>
                                  <option selected value="0">
                                    Unassigned WorkItems
                                  </option>
                                  <option value="1">My Workitems</option>
                                  <option value="2">All Work Items</option>
                                </>
                              ) : +sessionStorage.getItem("WorkitemMode") ==
                                0 ? (
                                <>
                                  <option selected value="0">
                                    Unassigned WorkItems
                                  </option>
                                  <option value="1">My Workitems</option>
                                  <option value="2">All Work Items</option>
                                </>
                              ) : +sessionStorage.getItem("WorkitemMode") ==
                                1 ? (
                                <>
                                  <option value="0">
                                    Unassigned WorkItems
                                  </option>
                                  <option selected value="1">
                                    My WorkItems
                                  </option>
                                  <option value="2">All Work Items</option>
                                </>
                              ) : (
                                <>
                                  <option value="0">
                                    Unassigned WorkItems
                                  </option>
                                  <option value="1">My WorkItems</option>
                                  <option selected value="2">
                                    All Work Items
                                  </option>
                                </>
                              )}

                              {/* <option value="0">Unassigned Work Items</option>
                              <option value="1">My Work Items</option>
                              <option value="2">All Work Items</option> */}
                            </select>
                          </div>
                        </div>

                        {/* <button
                          type="button"
                          className="btn btn-sm btn-outline-danger ms-2"
                        >
                          <b className="icon-trash-2"></b> Delete
                        </button> */}
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={historyOpen}
                        >
                          View History
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {isExcelGrid ? (
                  <LoadingSpinnerFigma />
                ) : (
                  <>
                    <div className="tableWrap">
                      <div className="tableWrap-body">
                        <table className="table table-borderless MatTable">
                          <ThemeProvider theme={theme}>
                            <MaterialTable
                              localization={{
                                pagination: {
                                  //labelDisplayedRows: '{from}-{to} of {count}'
                                },
                                toolbar: {
                                  // nRowsSelected: '{0} row(s) selected'
                                },
                                header: {
                                  actions: "",
                                },
                              }}
                              tableRef={tableRef1}
                              actions={[
                                {
                                  icon: () =>
                                    ShowDetailIcon === "Batch" ? (
                                      <FcSearch />
                                    ) : (
                                      ""
                                    ),
                                  tooltip:
                                    ShowDetailIcon === "Batch"
                                      ? "View workitem's detail"
                                      : "",
                                  onClick: (event, rowData) =>
                                    viewWorkitemShow(rowData),
                                  position: "row",
                                  hidden:
                                    ShowDetailIcon === "Batch" ? false : true,
                                  disabled:
                                    ShowDetailIcon === "Batch" ? false : true,
                                },
                                {
                                  tooltip: "Actions",
                                  icon: () => (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-outline-danger ms-2"
                                    >
                                      <b className="icon-trash-2"></b> Delete
                                    </button>
                                  ),
                                  onClick: (event, rowData) =>
                                    deleteWorkItem(rowData),
                                },
                                {
                                  tooltip: "Actions",
                                  icon: () => (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-primary ms-2"
                                    >
                                      <b className="icon-edit-3"></b> Assign
                                    </button>
                                  ),
                                  onClick: (event, rowData) =>
                                    PopUpOpener(rowData),
                                },
                              ]}
                              options={{
                                showTitle: false,
                                headerStyle: { position: "sticky", top: 0 },
                                search: false,
                                overflowY: false,
                                showTitle: false,
                                sorting: true,
                                filtering: true,
                                selection: true,
                                tableLayout: "auto",

                                pageSizeOptions: [5, 50, 100, 500],
                                toolbarButtonAlignment: "left",
                                headerStyle: {
                                  // height: 30,
                                },
                                rowStyle: {
                                  // fontFamily: '"Yantramanav", sans-serif',
                                  // fontSize: "14px",
                                },
                                tableLayout: "auto",
                                headerStyle: {
                                  // fontFamily: '"Yantramanav", sans-serif',
                                  // fontWeight: "inherit",
                                  // fontSize: "14px",
                                  // textAlign: "left !Important",
                                },
                              }}
                              // components={{
                              //   Container: (props) => (
                              //     <div style={{ height: 500 }}>
                              //       {props.children}
                              //     </div>
                              //   ),
                              // }}
                              title="Upload Details"
                              columns={columns}
                              icons={tableIcons}
                              data={uploadExcelDetails}
                              onChangePage={(PageNo) =>
                                handlepagechange(PageNo)
                              }
                              onChangeRowsPerPage={(Page) =>
                                handlepagesizechange(Page)
                              }
                              //onOrderChange={search}
                              //onOrderChange={search => handlesearch(search)}

                              //onOrderChange={row => handlechange(row)}
                              //selected={}
                              page={PageNo}
                              totalCount={totalCount}
                            />
                          </ThemeProvider>
                        </table>
                      </div>
                    </div>
                    <hr />
                    {IsDuplicate ? (
                      <div className="tableWrap">
                        <div className="tableWrap-body">
                          <table className="table table-borderless MatTable">
                            <tbody>
                              <ThemeProvider theme={theme}>
                                <MaterialTable
                                  localization={{
                                    // pagination: {
                                    //   //labelDisplayedRows: '{from}-{to} of {count}'
                                    // },
                                    toolbar: {
                                      // nRowsSelected: '{0} row(s) selected'
                                    },
                                    header: {
                                      actions: "",
                                    },
                                  }}
                                  options={{
                                    exportButton: true,
                                    sorting: true,
                                    filtering: true,
                                    search: false,
                                    selection: true,
                                    tableLayout: "auto",
                                    overflowY: true,
                                    showTitle: true,
                                    toolbarButtonAlignment: "left",
                                    pageSizeOptions: [5, 50, 100, 500, 1000],
                                    headerStyle: {
                                      height: 30,
                                    },
                                    rowStyle: {
                                      fontFamily: '"Yantramanav", sans-serif',
                                      fontSize: "14px",
                                    },
                                    tableLayout: "auto",
                                    headerStyle: {
                                      fontFamily: '"Yantramanav", sans-serif',
                                      fontWeight: "inherit",
                                      fontSize: "14px",

                                      textAlign: "left !Important",
                                    },
                                  }}
                                  title="Duplicate Records"
                                  columns={DuplicateColumns}
                                  icons={tableIcons}
                                  data={duplicateExcelDetails}
                                />
                              </ThemeProvider>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
          <Footer />
          <Modal
            show={viewWorkitemModal}
            dialogClassName="modal-100w"
            onHide={viewWorkitemClose}
            fullscreen={"lg-down"}
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton> Upload and Assignment</Modal.Header>
            <Modal.Body>
              <div className="timeBreak">
                <div className="dashBoxs">
                  <div
                    className="dashBoxs-header "
                    style={{ maxHeight: "45px" }}
                  >
                    <div className="row align-items-center">
                      <div className="col-4">
                        <h6>Showing Workitems</h6>
                      </div>
                    </div>
                  </div>
                  {isExcelGrid ? (
                    <LoadingSpinnerFigma />
                  ) : (
                    <>
                      <ul className="timeBreak-form">
                        <div className="tableWrap">
                          <div className="tableWrap-body">
                            <table
                              className="table table-borderless MatTable"
                              style={{ zIndex: "1", position: "relative" }}
                            >
                              <MaterialTable
                                title="Showing Workitems"
                                options={{
                                  search: false,
                                  overflowY: false,
                                  showTitle: false,
                                  selection: true,
                                  cellStyle: {
                                    // width: 100,
                                    // minWidth: 100,
                                    whiteSpace: "Normal",
                                  },
                                  headerStyle: {
                                    position: "sticky",
                                    // top: "-5px",
                                  },
                                  rowStyle: {
                                    fontFamily: '"Yantramanav", sans-serif',
                                  },
                                }}
                                actions={[
                                  {
                                    tooltip: "Actions",
                                    icon: () => (
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-outline-danger ms-2"
                                      >
                                        <b className="icon-trash-2"></b> Delete
                                      </button>
                                    ),
                                    onClick: (event, rowData) =>
                                      deleteWorkItem(rowData),
                                  },
                                  {
                                    tooltip: "Actions",
                                    icon: () => (
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary ms-2"
                                      >
                                        <b className="icon-edit-3"></b> Assign
                                      </button>
                                    ),
                                    onClick: (event, rowData) =>
                                      PopUpOpener(rowData),
                                  },
                                ]}
                                columns={listUploadAndAssignmentColumns}
                                icons={tableIcons}
                                data={workitemDetails}
                                components={{
                                  Container: (props) => (
                                    <Paper {...props} elevation={0} />
                                  ),
                                  Toolbar: (props) => (
                                    <div
                                      style={{
                                        backgroundColor: "rgb(206 206 206)",
                                        fontSize: "0.75em",
                                      }}
                                    >
                                      <MTableToolbar
                                        {...props}
                                        style={{ fontSize: "5px" }}
                                      />
                                    </div>
                                  ),
                                }}
                              />
                            </table>
                          </div>
                        </div>
                      </ul>
                    </>
                  )}

                  <ul className="timeBreak-form"></ul>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/** NOTIFY MODAL **/}
          <Modal
            show={notifyModal}
            dialogClassName="modal-25w"
            onHide={notifyClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Notify</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="timeBreak">
                <ul className="timeBreak-form">
                  <li>
                    <label className="form-label">Send To</label>
                    <textarea className="form-control"></textarea>
                    <div class="form-text">
                      Note: Separate multiple email IDs using comma (,)
                    </div>
                  </li>
                  <li>
                    <label className="form-label">Message</label>
                    <textarea
                      className="form-control"
                      placeholder="Type here...."
                    ></textarea>
                  </li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-outline-ntv mx-2"
                onClick={notifyClose}
              >
                Cancel
              </button>
              <button className="btn btn-primary">Send</button>
            </Modal.Footer>
          </Modal>

          {/** ASSIGN MODAL **/}
          <Modal
            show={OpenPopUp}
            dialogClassName="modal-25w"
            onHide={PopUpCloser}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Assign</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="timeBreak">
                <div className="row align-items-end">
                  <div className="col-5">
                    <div className="mb-3">
                      <label className="form-label">Select Activity</label>
                      <select
                        className="form-control form-control-xs "
                        title="Select Activity Name"
                        onmouseenter=""
                        ref={SelectedDashboardref}
                        onChange={AssignmentDropDown}
                      >
                        <option>Select</option>
                        {Arr.map((state) => (
                          <option value={state.value}>{state.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="mb-3">
                      <label className="form-label">Select Employee</label>
                      <select
                        className="form-control form-control-xs "
                        title="Select Activity Name"
                        onmouseenter=""
                        ref={SelectedEmployeeref}
                        //onChange = {handleAssign}
                      >
                        <option>Select</option>
                        <option value="1">--Unassign--</option>
                        {Emp.map((state) => (
                          <option value={state.employeeVersionID}>
                            {state.empName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="mb-3">
                      <button
                        className="btn btn-primary"
                        onClick={handleAssign}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="pt-2 pb-2">
                    <div className="mb-5">
                      <div className="tableWrap">
                        <div className="tableWrap-body">
                          <table
                            className="table table-borderless MatTable"
                            style={{ zIndex: "1", position: "relative" }}
                          >
                            <ThemeProvider theme={theme}>
                              <MaterialTable
                                tableRef={tableref}
                                localization={{
                                  pagination: {
                                    //labelDisplayedRows: '{from}-{to} of {count}'
                                  },
                                  toolbar: {
                                    // nRowsSelected: '{0} row(s) selected'
                                  },
                                  header: {
                                    actions: "",
                                  },
                                }}
                                options={{
                                  search: false,
                                  // selection: true,
                                  tableLayout: "auto",
                                  overflowY: false,
                                  showTitle: false,
                                  toolbarButtonAlignment: "left",
                                  paging: false,
                                  toolbar: false,
                                  headerStyle: {
                                    height: 20,
                                  },
                                  rowStyle: {
                                    fontFamily: '"Yantramanav", sans-serif',
                                  },
                                  tableLayout: "auto",
                                  headerStyle: {},
                                }}
                                editable={{
                                  onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                      handleRowDelete(oldData, resolve);
                                    }),
                                }}
                                columns={advanceColumns}
                                icons={tableIcons}
                                data={Add}
                              />
                            </ThemeProvider>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-outline-ntv mx-2"
                onClick={PopUpCloser}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={handleSave}>
                Assign
              </button>
            </Modal.Footer>
          </Modal>

          {/** VIEW HISTORY MODAL **/}
          <Modal
            show={historyModal}
            dialogClassName="modal-100w"
            onHide={historyClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>View Upload History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="dashBoxs-header pb-3 pt-2">
                  <div className="row align-items-center">
                    <div className="col-4">Upload Details</div>
                    <div className="col-8">
                      <div
                        className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                        style={{ marginTop: "-10px" }}
                      >
                        <div className="d-inline">
                          <div className="input-group input-group-sm">
                            <label
                              className="input-group-text"
                              for="inputGroupSelect01"
                            >
                              From Date & To Date
                            </label>
                            <DateRangePicker
                              initialSettings={{
                                autoApply: true,
                                autoUpdateInput: false,
                                opens: "right",
                                timePicker: false,
                                locale: { format: "M/DD/YY" },
                              }}
                              onApply={handleApplyUpload}
                            >
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Select Date"
                                value={
                                  uplodadedDateTimeFrom +
                                  "-" +
                                  uplodadedDateTimeTo
                                }
                              />
                            </DateRangePicker>
                            <button
                              className="btn btn-sm btn-primary ms-2"
                              onClick={GetUploadHistoryData}
                            >
                              Get Error Details
                            </button>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            downloadExcel();
                          }}
                        >
                          <b></b>Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tableWrap">
                  <div className="tableWrap-body">
                    <table className="table table-borderless MatTable">
                      <ThemeProvider theme={theme}>
                        <MaterialTable
                          options={{
                            showTitle: false,
                            exportButton: false,
                            sorting: true,
                            filtering: true,
                            search: false,
                            selection: false,
                            tableLayout: "auto",

                            pageSizeOptions: [5, 50, 100, 6000],
                            toolbarButtonAlignment: "left",
                            headerStyle: {
                              //   height: 30,
                            },
                            rowStyle: {
                              // fontFamily: '"Yantramanav", sans-serif',
                              // fontSize: "14px",
                            },
                            tableLayout: "auto",
                            headerStyle: {
                              // fontFamily: '"Yantramanav", sans-serif',
                              // fontWeight: "inherit",
                              // fontSize: "14px",
                              // textAlign: "left !Important",
                            },
                          }}
                          title="Upload Details"
                          columns={columnsGetUploadDetails}
                          icons={tableIcons}
                          data={uploadHistoryData}
                        />
                      </ThemeProvider>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
          <button className="btn btn-outline-ntv " onClick={historyClose}>
            Cancel
          </button>
        </Modal.Footer> */}
          </Modal>
        </>
      )}
    </>
  );
}
export default UploadAssignment;

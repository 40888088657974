import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ToastProvider, useToasts } from "react-toast-notifications";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { triggerGlobalRelogin } from "src/components/common/functions";
const { REACT_APP_LOGIN } = process.env;
const { REACT_APP_API_URL } = process.env;
const { REACT_APP_NAME } = process.env;
const { REACT_APP_AllowClientResetPassword } = process.env;
const { REACT_APP_CLIENTURL } = process.env;
const getAPIURL = (api) => {
  let apiURL = REACT_APP_API_URL;
  return `${apiURL}${api}`;
}; 

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ReloginModal = ({ show, handleClose }) => {

 const { addToast } = useToasts();
  const [username, set_username] = useState("");
  const [password, set_password] = useState("");
  const [isLoading, set_IsLoading] = useState(false);
  const [isShow, set_isShow] = useState(false);
  const [roles, setRoles] = useState([]);
  const [error_msg, set_error_msg] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [is_error, set_is_error] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [is_authenticated, set_is_authenticated] = useState(false);
  const [showAutoLogoutMessage, setShowAutoLogoutMessage] = useState(document.location.search.includes("?autoLogout") ?? false);


  const ChangeHandle = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      set_password(value);
    }
    if (name === "username") {
      set_username(value);
    }
  };
  const validate = async (username, password) => {
    const errors = {};
    set_error_msg("");
    const regax = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (!username) {
      addToast("Username is required!", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (!password) {
      addToast("Password is required!", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    return errors;
  };
  const ReLoginUser = (body) => {
    set_IsLoading(true);
    // setFormErrors(validate(username, password));
    setIsSubmit(true);
    sessionStorage.setItem("ApplicationURL", window.location.origin);
    if (body != null && body!= "") {
      set_isShow(true);

     
      let loginUrl = getAPIURL(REACT_APP_LOGIN);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
        },
        credentials: 'include',
        body: JSON.stringify(body)
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          set_IsLoading(false);
          if (result.status == 500) {
            set_is_authenticated(false);
            set_is_error(true);
            set_isShow(false);
            addToast(result.message, {
              appearance: "error",
              autoDismiss: true,
            });
          } else if (result.status != 200) {
            set_is_authenticated(false);
            set_is_error(true);
            set_isShow(false);
            addToast("Incorrect Username or Password!", {
              appearance: "error",
              autoDismiss: true,
            });
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            // sessionStorage.clear();
            // sessionStorage.clear();
            // navigate("/");
          } else {
            if(result.data[0].restrictCWSActive=="Y" && sessionStorage.getItem("platform")=='null')
            {
              const confirmBox = window.confirm(
                result.data[0].descriptions
              );
              if (confirmBox === true) {
                // window.open(result.data[0].redirectURLLink);
                window.location.href = result.data[0].redirectURLLink;
              }
            }
            else
            {
              if (result.status == 200) {
                addToast("Relogin - "+result.message, {
                  appearance: "info",
                  autoDismiss: true
                });
              }

              //If any change in this portion adding value in session.
              //Please add this session change to SSOLogin page also --Omkar

              sessionStorage.setItem("authorizationToken", result.data[0].token);
              sessionStorage.setItem("RefreshToken", result.data[0].refreshToken);
              if (result.data[0].isMultipleSubProcessAllowed) {
                sessionStorage.setItem("is_userPreference", true);
              } else {
                sessionStorage.setItem("is_userPreference", false);
              }
              const rolesName = result.data[0].rolesName;
              setRoles(rolesName);
              // Navigation Blacklisting according to current development- Aman
              sessionStorage.setItem("rolesList", JSON.stringify(rolesName));
              //Added by Gajendra  for local timezone name
              var timezonename = new Date()
                .toLocaleTimeString("en-us", { timeZoneName: "long" })
                .split(" ")[2];
              sessionStorage.setItem("LocalTimezoneName", timezonename);

              for (let i = 0; i < rolesName.length; i++) {
                if (rolesName[i].roleName === "Admin1")
                  sessionStorage.setItem("is_admin", true);
                else if (rolesName[i].roleName === "Super Admin" || rolesName[i].roleName === "SuperAdmin")
                  sessionStorage.setItem("is_superadmin", true);
                else if (rolesName[i].roleName === "One And Done")
                  sessionStorage.setItem("is_OneandDone", true);
                else if (rolesName[i].roleName === "Upload")
                  sessionStorage.setItem("is_upload", true);
                else if (
                  rolesName[i].roleName === "Activities" ||
                  rolesName[i].roleName === "CWSActivities"
                )
                  sessionStorage.setItem("is_activities", true);
                else if (rolesName[i].roleName === "Client")
                  sessionStorage.setItem("is_client", true);
                else if (rolesName[i].roleName === "Exceptions")
                  sessionStorage.setItem("is_exceptions", true);
                else if (rolesName[i].roleName === "Complete")
                  sessionStorage.setItem("is_complete", true);
                else if (rolesName[i].roleName === "Configuration")
                  sessionStorage.setItem("is_Configuration", true);
                else if (rolesName[i].roleName === "Vendor")
                  sessionStorage.setItem("is_vendor", true);
                else if (
                  rolesName[i].roleName === "Report" ||
                  rolesName[i].roleName === "Reports"
                )
                  sessionStorage.setItem("is_report", true);

                else if (rolesName[i].roleName === "Manage WorkItems")
                  sessionStorage.setItem("is_manageWorkItems", true);
                else if (rolesName[i].roleName === "CWSSearch" || rolesName[i].roleName === "CWSRestrictedSearch")
                  sessionStorage.setItem("is_search", true);
                else if (rolesName[i].roleName === "Upload and Assignment")
                  sessionStorage.setItem("is_UploadandAssignment", true);
                else if (rolesName[i].roleName === "Queue Management")
                  sessionStorage.setItem("is_QueueManagement", true);
                else if (rolesName[i].roleName === "Bucket Management")
                  sessionStorage.setItem("is_BucketManagement", true);
                else if (rolesName[i].roleName === "Real Time Dashboard")
                  sessionStorage.setItem("is_RealTimeDashboard", true);
                else if (rolesName[i].roleName === "Configuration")
                  sessionStorage.setItem("is_Configuration", true);
                else if (rolesName[i].roleName === "User Classification Mapping")
                  sessionStorage.setItem("is_UserClassificationMapping", true);
                else if (rolesName[i].roleName === "User Classification List")
                  sessionStorage.setItem("is_UserClassificationList", true);
                else if (rolesName[i].roleName === "Workitem Fields Rules")
                  sessionStorage.setItem("is_WorkitemFieldsRules", true);
                else if (rolesName[i].roleName === "SubprocessView")
                  sessionStorage.setItem("is_SubprocessView", true);
              }
              set_is_authenticated(true);

              //sessionStorage.setItem("ApplicationURL", window.location.origin);
              sessionStorage.setItem("is_Authenticated", true);
                sessionStorage.setItem("authorizationToken", result.data[0].token);
              //  console.log("before refresh token timer");

                // let timerid = setupRefreshTokenTimer();
                // clearTimeout(timerid);
              let userNameVal = "";
              if (body.SSOSource == "nervehub") {
                  userNameVal = result.data[0].CWSUserName;
              } else {
                  userNameVal = username;
              }
              sessionStorage.setItem("UserName", userNameVal);
              sessionStorage.setItem("UserFullName", result.data[0].fullName);
              sessionStorage.setItem("UserEmail", result.data[0].email);
              sessionStorage.setItem("GUID", result.data[0].guid);
              sessionStorage.setItem("PersonID", result.data[0].personID);
              sessionStorage.setItem("PersonType", result.data[0].personType);
              sessionStorage.setItem(
                "HRISEmployeeID",
                result.data[0].hrisEmployeeID
              );

            sessionStorage.setItem(
              "EmployeeVersionID",
              result.data[0].employeeVersionID
            );
            set_is_authenticated(true);
            triggerGlobalRelogin(false);
          }
          }
        })
        .catch((error) => {
          addToast("Relogin - Failed", {
            appearance: "error",
            autoDismiss: true
          });
          //navigate("/");
        });
    } else {
      set_isShow(set_isShow);
    }
  };
  const ReLoginHandle = (e) => {
    setShowAutoLogoutMessage(false);
    e.preventDefault();
    setFormErrors(validate(username, password));
    setIsSubmit(true);
    sessionStorage.setItem("ApplicationURL", window.location.origin);
    if (username != "" && password != "") {
      set_isShow(true);
      let UserToken = "";
      let indexp = 0;
      password.split("").forEach((character) => {
        UserToken = UserToken + "=c" + password.charCodeAt(indexp);
        indexp = indexp + 1;
      });
      var Buffer = require("buffer/").Buffer;

      let buff = new Buffer(UserToken);
      let encryptedUserToken = buff.toString("base64");
      //let encryptedpassword = Buffer.from(password, "base64");
      let body = {
        username: username,
        UserToken: encryptedUserToken,
        applicationname: sessionStorage.getItem("ApplicationURL"),
        ForceLogin: true,
      };
     
      ReLoginUser(body);
    } else {
      set_isShow(set_isShow);
    }
  };
  return (
    <>
    {showAutoLogoutMessage && (
      <div>
        <Alert severity="error">
          You are logged out as the session has expired
        </Alert>
        <br />
      </div>
    )}
    <Modal show={show}  backdrop="static" keyboard={false}  centered>
      <Modal.Header closeButton>
        <Modal.Title>Relogin Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ReLoginHandle}>
          <Form.Group controlId="formEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control
              id="txtUserName"
              className="form-control"
              type="text"
              placeholder="Username"
              name="username"
              value={username}
              onChange={ChangeHandle}
            />
            
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              id="password"
              className="form-control"
              type="password"
              placeholder="Password"
              name="password"
              //value={password}
              onChange={ChangeHandle}
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3">
            Relogin
          </Button>
        </Form>
      </Modal.Body>
    </Modal></>
  );
};

export default ReloginModal;

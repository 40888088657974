import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import CryptoJS from "crypto-js";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
//import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
//import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "react-table-filter/lib/styles.css";
import GlobalStyles from "@mui/material/GlobalStyles";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../../../../MaterialTableIcons";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LoadingSpinner from "../../../../LoadingSpinner";
import LoadingSpinnerFigma from "../../../../LoadingSpinnerFigma";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import NavDataContext from "../../../../contexts/NavDataContext";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FcSearch } from "react-icons/fc";
import { FcFinePrint } from "react-icons/fc";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import WorkActivityScreenDetailsPage from "../WorkActivityScreen/WorkActivityScreenDetailsPage";
import FormContext from "../../../../contexts/FormContext";
import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import { debuggerStatement } from "@babel/types";
import Alert from "@material-ui/lab/Alert";
//import { setDatasets } from "react-chartjs-2/dist/utils";

const ActivityScreenConfig = ({ onDataSend, reference }) => {
  const { addToast } = useToasts();

  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCHSUBPROCESSACTIVITYFORSUBPROCESSID } = process.env;
  const { REACT_APP_FETCHCOPYSUBPROCESSACTIVITYFORSUBPROCESSID } = process.env;
  const { REACT_APP_FETCHSUBPROCESSACTIVITYSCREENDETAILS } = process.env;
  const { REACT_APP_SAVEANDUPDATESCREENACTIVITYDETAILS } = process.env;
  const { REACT_APP_CheckValidScreenConfig } = process.env;

  let navigate = useNavigate();
  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  //Jinnat

  const [Subprocessworkactivity, setSubprocessworkactivity] = useState([]);
  const [CopySubprocessworkactivity, setCopySubprocessworkactivity] = useState(
    []
  );

  const [isLoading, set_IsLoading] = useState(false);
  const [checkedValue, setcheckedValue] = useState("");
  const [Data, setData] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});

  //useState

  const [Workactivity, setWorkactivity] = useState("");

  const [Copyscreenconfig, setCopyscreenconfig] = useState("");

  const [Excludeworkitemsolderthan, setExcludeworkitemsolderthan] = useState(0);

  const [enable, setenable] = useState(false);
  const [copyEnable, setcopyEnable] = useState(false);

  const [workActivityforConfigId, setworkActivityforConfigId] = useState("0");

  //Handle Onchange
  const handleworkactivity = (e) => {
    //debugger;
    if (e.target.value != "0" && e.target.value != null) {
      GetScreenActivityDetails(e.target.value);
      setWorkactivity(e.target.value);
    }
  };

  const CopyScreenConfigFromHandle = (e) => {
    //debugger;

    if (e.target.value != "0" && e.target.value != null) {
      setcopyEnable(true);
    } else {
      setcopyEnable(false);
    }
    setCopyscreenconfig(e.target.value);
  };
  const EnableUniqueCheckforthisactivityasHandle = (e) => {
    //debugger;
    setenable(EnableUniqueCheckforthisactivityasRef.current.checked);
  };

  const CopyScreenConfiguration = () => {
    if (
      workActivityforConfigId != "0" &&
      workActivityforConfigId != null &&
      workActivityforConfigId != ""
    ) {
      if (
        Copyscreenconfig != null &&
        Copyscreenconfig != "0" &&
        Copyscreenconfig != ""
      ) {
        GetCopyScreenActivityDetails(Copyscreenconfig);
      } else {
        addToast("Please select an activity to Copy", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } else {
      addToast("Sub-Process Work Activity is Required.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const ExcludeworkitemsolderthanHandle = (e) => {
    //debugger;
    setExcludeworkitemsolderthan(e.target.value);
  };

  //DocumentIdField

  //End Handle Onchange

  const ShowOnbaseDocumentLinkRef = useRef(false);
  const UploadDocumenttoOnbaseRef = useRef(false);
  const AppendImagetoOnbasedocumentRef = useRef(false);
  const HideWorkitemAuditTrailRef = useRef(false);
  const HideWorkitemAssignmentDetailsRef = useRef(false);
  const HideWorkitemChecklistCommentDetailsRef = useRef(false);
  const AllowassignuserfornextactivityRef = useRef(false);
  const AllowuploadattachmentfortheworkitemsRef = useRef(false);
  const AllowskipbuttonRef = useRef(false);
  const EnableUniqueCheckforthisactivityasRef = useRef(false);
  const AllowAutoPopupofImageRef = useRef(false);
  const ShowCountdownTimerRef = useRef(false);
  const AllowDataValidationRef = useRef(false);

  //First

  // const HandleSaveandUpdate = () => {
  //   debugger;
  //   if (buttonSave) {
  //   SaveSubprocessConfig();
  //   } else {
  //     UpdateSubprocessConfig();
  //   }
  // };
  const GetSubProcessActivityForSubProcessID = () => {
    

    let body = {
      subprocessID: reference.SubprocessID,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCHSUBPROCESSACTIVITYFORSUBPROCESSID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          
          setSubprocessworkactivity(result.data);
          setcopyEnable(false);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",

            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          //navigate("/");
        } else {
          addToast("Fetching Activity details Failed", {
            appearance: "error",

            autoDismiss: true,
          });
        }
      })

      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  const GetCopySubProcessActivityForSubProcessID = () => {
    //debugger;

    let body = {
      subprocessID: reference.SubprocessID,
    };

    let loginUrl = getAPIURL(
      REACT_APP_FETCHCOPYSUBPROCESSACTIVITYFORSUBPROCESSID
    );
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 200) {
          

          setCopySubprocessworkactivity(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",

            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          //navigate("/");
        } else {
          addToast("Failed to copy Workactivity Screen Details", {
            appearance: "error",

            autoDismiss: true,
          });
        }
      })

      .catch((error) => {
        console.log("ERROR: ", error);
        //navigate("/");
      });
  };

  //const GetScreenActivityDetails = SubProcessWorkActivityConfigID => {
  //debugger;
  const GetCopyScreenActivityDetails = (SubProcessWorkActivityConfigID) => {
    if (workActivityforConfigId == SubProcessWorkActivityConfigID) {
      window.alert(
        "Sub-Process Work Activity and Copy screen Configuration From values should not be same"
      );
    } else {
      let body = {
        SubProcessId: reference.SubprocessID,
        //SubProcessWorkActivityConfigID: SubProcessWorkActivityConfigID
        SubProcessWorkActivityConfigID: SubProcessWorkActivityConfigID,
      };

      let loginUrl = getAPIURL(REACT_APP_FETCHSUBPROCESSACTIVITYSCREENDETAILS);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            
            addToast("Workactivity Screen Details copied successfully", {
              appearance: "success",

              autoDismiss: true,
            });
            if (result.data.getScreenActivityDetails[0] != null) {
              ShowOnbaseDocumentLinkRef.current.checked =
                result.data.getScreenActivityDetails[0].showOnbaseImage;
              UploadDocumenttoOnbaseRef.current.checked =
                result.data.getScreenActivityDetails[0].allowOnbaseDocumentUpload;
              AppendImagetoOnbasedocumentRef.current.checked =
                result.data.getScreenActivityDetails[0].isAppendImageToOnbaseDocument;
              HideWorkitemAuditTrailRef.current.checked =
                result.data.getScreenActivityDetails[0].showWorkitemAuditTrail;
              HideWorkitemAssignmentDetailsRef.current.checked =
                result.data.getScreenActivityDetails[0].showWorkitemAssignmentDetails;
              HideWorkitemChecklistCommentDetailsRef.current.checked =
                result.data.getScreenActivityDetails[0].showWorkitemChecklistCommentDetails;
              AllowassignuserfornextactivityRef.current.checked =
                result.data.getScreenActivityDetails[0].allowAssignmentfornextactivity;
              AllowuploadattachmentfortheworkitemsRef.current.checked =
                result.data.getScreenActivityDetails[0].allowUploadForWorkItem;
              AllowskipbuttonRef.current.checked =
                result.data.getScreenActivityDetails[0].isAllowSkipButton;
              EnableUniqueCheckforthisactivityasRef.current.checked =
                result.data.getScreenActivityDetails[0].hasUniqueCheckForActivity;
              AllowAutoPopupofImageRef.current.checked =
                result.data.getScreenActivityDetails[0].chkAutoImagePopUp;
              ShowCountdownTimerRef.current.checked =
                result.data.getScreenActivityDetails[0].countDownTimer;
              AllowDataValidationRef.current.checked =
                result.data.getScreenActivityDetails[0].isShowScreenPopup;

              setExcludeworkitemsolderthan(
                result.data.getScreenActivityDetails[0]
                  .excludeWorkitemsOlderthanthis
              );
              setenable(
                result.data.getScreenActivityDetails[0]
                  .hasUniqueCheckForActivity
              );
              setcheckedValue(
                result.data.getScreenActivityDetails[0].hasUniqueCheckForActivityMessageType.toString()
              );
            }
            if (result.data.setScreenActivityDetails != null) {
              setData(result.data.setScreenActivityDetails);
            }
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",

              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            //navigate("/");
          } else {
            addToast("Fetching Workactivity Screen Details Failed", {
              appearance: "error",

              autoDismiss: true,
            });
          }
        })

        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
    }
  };

  const GetScreenActivityDetails = (e) => {
    
      set_IsLoading(true);
      let body = {
        SubProcessId: reference.SubprocessID,
        //SubProcessWorkActivityConfigID: SubProcessWorkActivityConfigID
        SubProcessWorkActivityConfigID: e.target.value.toString(),
      };

      let loginUrl = getAPIURL(REACT_APP_FETCHSUBPROCESSACTIVITYSCREENDETAILS);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            set_IsLoading(false);
            
            if (result.data.getScreenActivityDetails[0] != null) {
              ShowOnbaseDocumentLinkRef.current.checked =
                result.data.getScreenActivityDetails[0].showOnbaseImage;
              UploadDocumenttoOnbaseRef.current.checked =
                result.data.getScreenActivityDetails[0].allowOnbaseDocumentUpload;
              AppendImagetoOnbasedocumentRef.current.checked =
                result.data.getScreenActivityDetails[0].isAppendImageToOnbaseDocument;
              HideWorkitemAuditTrailRef.current.checked =
                result.data.getScreenActivityDetails[0].showWorkitemAuditTrail;
              HideWorkitemAssignmentDetailsRef.current.checked =
                result.data.getScreenActivityDetails[0].showWorkitemAssignmentDetails;
              HideWorkitemChecklistCommentDetailsRef.current.checked =
                result.data.getScreenActivityDetails[0].showWorkitemChecklistCommentDetails;
              AllowassignuserfornextactivityRef.current.checked =
                result.data.getScreenActivityDetails[0].allowAssignmentfornextactivity;
              AllowuploadattachmentfortheworkitemsRef.current.checked =
                result.data.getScreenActivityDetails[0].allowUploadForWorkItem;
              AllowskipbuttonRef.current.checked =
                result.data.getScreenActivityDetails[0].isAllowSkipButton;
              EnableUniqueCheckforthisactivityasRef.current.checked =
                result.data.getScreenActivityDetails[0].hasUniqueCheckForActivity;
              AllowAutoPopupofImageRef.current.checked =
                result.data.getScreenActivityDetails[0].chkAutoImagePopUp;
              ShowCountdownTimerRef.current.checked =
                result.data.getScreenActivityDetails[0].countDownTimer;
              AllowDataValidationRef.current.checked =
                result.data.getScreenActivityDetails[0].isShowScreenPopup;

              setExcludeworkitemsolderthan(
                result.data.getScreenActivityDetails[0]
                  .excludeWorkitemsOlderthanthis
              );
              setenable(
                result.data.getScreenActivityDetails[0]
                  .hasUniqueCheckForActivity
              );
              setcheckedValue(
                result.data.getScreenActivityDetails[0].hasUniqueCheckForActivityMessageType.toString()
              );
            }
            if (result.data.setScreenActivityDetails != null) {
              setData(result.data.setScreenActivityDetails);
              
            }
          } else if (result.status === 401) {
            set_IsLoading(false);
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",

              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            //navigate("/");
          } else {
            set_IsLoading(false);
            addToast("Fetching Workactivity Screen Details Failed", {
              appearance: "error",

              autoDismiss: true,
            });
          }
        })

        .catch((error) => {
          console.log("ERROR: ", error);
          //navigate("/");
        });
  };
  //Second
  const [displayOrderArry, setdisplayOrderArry] = useState([]);

  useEffect(() => {
    // debugger;
    setdisplayOrderArry([]);
    for (let index = 1; index <= 300; index++) {
      setdisplayOrderArry((oldArray) => [...oldArray, index]);
    }
  }, []);

  const HandleIsSelected = (e, rowData) => {
    
    let a = 0;
    if (e.target.checked) {
      a = 1;
    } else {
      a = 0;
    }
    const newData = [...Data];

    newData[rowData.tableData.id]["isSelected"] = a;
    setData(newData);
  };

  const HandleIsReadOnly = (e, rowData) => {
    
    let a = 0;
    if (e.target.checked) {
      a = 1;
    } else {
      a = 0;
    }
    const newData = [...Data];

    newData[rowData.tableData.id]["isReadOnly"] = a;
    setData(newData);
  };

  const HandleIsAutoCopy = (e, rowData) => {
    
    let a = 0;
    if (e.target.checked) {
      a = 1;
    } else {
      a = 0;
    }
    const newData = [...Data];

    newData[rowData.tableData.id]["isAutoCopy"] = a;
    setData(newData);
  };

  const HandleIsOfUniqueKey = (e, rowData) => {
    
    let a = 0;
    if (e.target.checked) {
      a = 1;
    } else {
      a = 0;
    }
    const newData = [...Data];

    newData[rowData.tableData.id]["isPartOfUniqueKey"] = a;
    setData(newData);
  };

  const HandleIsShowToScreen = (e, rowData) => {
    
    let a = 0;
    if (e.target.checked) {
      a = 1;
    } else {
      a = 0;
    }
    const newData = [...Data];

    newData[rowData.tableData.id]["isShowToScreen"] = a;
    setData(newData);
  };

  const HandleIsMobile = (e, rowData) => {
    
    let a = 0;
    if (e.target.checked) {
      a = 1;
    } else {
      a = 0;
    }
    const newData = [...Data];

    newData[rowData.tableData.id]["isMobile"] = a;
    setData(newData);
  };

  const HandleDisplayOrder = (e, rowData) => {
    

    const newData = [...Data];

    newData[rowData.tableData.id]["displayOrder"] = e.target.value;
    setData(newData);
  };

  // const handleFocus = () => {
  //   debugger;
  //   //toolTipRef.current.value = e.target.value;
  //   if (toolTipRef.current) {
  //     toolTipRef.current.focus();
  //   }
  // };
  //const [a, seta] = useState(false);
  const HandleToolTip = (e, rowData) => {
    

    //toolTipRef.current.value = e.target.value;
    const newData = [...Data];

    newData[rowData.tableData.id]["toolTip"] = e.target.value;
    setData(newData);

    //toolTipRef.current.value = e.target.value;
  };

  // const HandleToolTip1 = (e, rowData) => {
  //   debugger;
  //   //toolTipRef.current.value = e.target.value;
  //   const newData = [...Data];

  //   newData[rowData.tableData.id]["toolTip"] = e.target.value;
  //   //setData(newData);
  //   // document.getElementById(rowData.tableData.id).value = e.target.value;
  // };

  const HandleGroupName = (e, rowData) => {
    

    const newData = [...Data];

    newData[rowData.tableData.id]["groupName"] = e.target.value;
    setData(newData);
    setFocusedLabelText(rowData.labelText)
  };

  const toolTipRef = useRef(null);
  const groupNameRef = useRef("");
  const inputRefs = useRef([]);
  const [focusedLabelText, setFocusedLabelText] = useState(null);
  useEffect(() => {
    // Focus on the last input field that was changed
    
    if (focusedLabelText !== null && inputRefs.current[focusedLabelText]) {
      inputRefs.current[focusedLabelText].focus();
    }
  }, [Data,focusedLabelText]);
  const columns = [
    {
      title: "Select",
      field: "isSelected",
      render: (isSelected) => (
        <>
          <input
            type="checkbox"
            id="chkIsisSelected"
            onChange={(e) => HandleIsSelected(e, isSelected)}
            checked={isSelected["isSelected"] == 1}
          />
        </>
      ),
    },
    {
      title: "Work Item Field",
      field: "labelText",
    },
    // {
    //   title: "Select Color",
    //   field: "setColor"
    // },
    {
      title: "Group Name",
      field: "groupName",
      render: (groupName) => (
        <>
          <TextField
            value={groupName.groupName}
            onChange={(e) => HandleGroupName(e, groupName)}
            variant="outlined"
            size="small"
            inputRef={(input) => {
              if (input) {
                inputRefs.current[groupName.labelText] = input;
              }
            }}
          />
          {/* <input
            type="text"
            className="form-label"
            style={{ fontSize: "12px", color: "black" }}
            id="txtgroupName"
            onChange={e => HandleGroupName(e, groupName)}
            value={groupName["groupName"]}
            ref={groupNameRef}
            //onBlur={e => HandleBlurToolTip(e, toolTip)}

            //checked={toolTip["toolTip"]}
          /> */}
        </>
      ),
    },
    {
      title: "Display Order",
      field: "displayOrder",
      render: (rowData) => (
        <>
          <select
            id="displayOrder"
            className="form-control form-control-sm"
            //onChange={HandleIsSelected}
            onChange={(e) => HandleDisplayOrder(e, rowData)}
            //onChange={(e) => selectedEmployee_ID(e, rowData)}
          >
            {displayOrderArry.map((state) => (
              <option value={state} selected={state == rowData["displayOrder"]}>
                {state}
              </option>
            ))}
          </select>
        </>
      ),
    },
    {
      title: "ToolTip",
      field: "toolTip",
      render: (toolTip) => (
        <>
          <input
            type="text"
            className="form-label"
            style={{ fontSize: "12px", color: "black" }}
            id="txttoolTip"
            onChange={(e) => HandleToolTip(e, toolTip)}
            value={toolTip["toolTip"]}
            // value={document.getElementById(toolTip.tableData.id).value}
            // ref={toolTipRef}
            // onFocus={handleFocus}
            //autoFocus="autoFocus"
            //onBlur={e => HandleToolTip(e, toolTip)}

            //checked={toolTip["toolTip"]}
          />
        </>
      ),
    },

    {
      title: "Is Read Only",
      field: "isReadOnly",
      //type: "boolean"
      render: (isReadOnly) => (
        <>
          <input
            type="checkbox"
            className="form-check-label"
            id="chkisReadOnly"
            onChange={(e) => HandleIsReadOnly(e, isReadOnly)}
            disabled={
              isReadOnly["isAutoCopy"] == 1 ||
              ((isReadOnly["maptoTable"] == "ProductionDashboard.BatchItem" ||
                isReadOnly["maptoTable"] ==
                  "ProductionDashboard.UploadDetails") &&
                isReadOnly["isSelected"] == 1)
            }
            checked={
              isReadOnly["isReadOnly"] == 1 ||
              ((isReadOnly["maptoTable"] == "ProductionDashboard.BatchItem" ||
                isReadOnly["maptoTable"] ==
                  "ProductionDashboard.UploadDetails") &&
                isReadOnly["isSelected"] == 1)
            }
          />
        </>
      ),
    },
    {
      title: "Is Auto Copy",
      field: "isAutoCopy",
      //type: "boolean"
      render: (isAutoCopy) => (
        <>
          <input
            type="checkbox"
            id="chkisAutoCopy"
            onChange={(e) => HandleIsAutoCopy(e, isAutoCopy)}
            disabled={
              isAutoCopy["isReadOnly"] == 1 ||
              ((isAutoCopy["maptoTable"] == "ProductionDashboard.BatchItem" ||
                isAutoCopy["maptoTable"] ==
                  "ProductionDashboard.UploadDetails") &&
                isAutoCopy["isSelected"] == 1)
            }
            checked={isAutoCopy["isAutoCopy"] == 1}
          />
        </>
      ),
    },

    {
      title: "Is Unique Check Field",
      field: "isPartOfUniqueKey",
      hidden: !enable,
      //type: "boolean"
      render: (isPartOfPrimaryKey) => (
        <>
          <input
            type="checkbox"
            id="chkisPartOfPrimaryKey"
            onChange={(e) => HandleIsOfUniqueKey(e, isPartOfPrimaryKey)}
            checked={isPartOfPrimaryKey["isPartOfUniqueKey"] == 1}
          />
        </>
      ),
    },
    {
      title: "Is Data Validation",
      field: "isShowToScreen",
      //hidden: enable,
      //type: "boolean"
      render: (isShowToScreen) => (
        <>
          <input
            type="checkbox"
            id="chkisShowToScreen"
            onChange={(e) => HandleIsShowToScreen(e, isShowToScreen)}
            checked={isShowToScreen["isShowToScreen"] == 1}
          />
        </>
      ),
    },
    {
      title: "Is Mobile",
      field: "isMobile",
      //type: "boolean"

      render: (isMobile) => (
        <>
          <input
            type="checkbox"
            id="chkisMobile"
            onChange={(e) => HandleIsMobile(e, isMobile)}
            disabled={isMobile["isSelected"] == 0}
            checked={isMobile["isMobile"]}
          />
        </>
      ),
    },
  ];

  //onbase

  //Onbase

  const [buttonSave, setbuttonSave] = useState(true);

  //Start SaveSubprocessConfig
  // const HandleScreenValid=()=>{
  //   let body = {
  //     SubProcessID: reference.SubprocessID,
  //   };
  //   let dashboardValidationUrl = getAPIURL(REACT_APP_CheckValidScreenConfig);
  //   let req = new Request(`${dashboardValidationUrl}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       UserName: sessionStorage.getItem("UserName"),
  //       Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
  //     },
  //     body: JSON.stringify(body),
  //   });
  //   fetch(req)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.status == 200) {
  //         debugger;
  //         if (result.data) {
  //           onDataSend(true);
  //         } else {
  //           onDataSend(false);
  //         }
  //       } else if (result.status === 401) {
  //         onDataSend(false);
  //         addToast("Session Expired/UnAuthorized. Please LogIn Again", {
  //           appearance: "error",
  //           autoDismiss: true,
  //         });
  //         sessionStorage.clear();
  //         sessionStorage.clear();
  //         navigate("/");
  //       } else {
  //         onDataSend(false);
  //       }
  //     })
  //     .catch((error) => {
  //       onDataSend(false);
  //       console.log("ERROR: ", error);
  //     });
  // }
  const SaveSubprocessConfig = () => {
    // set_IsLoading(true);
    if(Data.filter(e=>e.isSelected==1).filter(e=>e.groupName=="").length>0)
      {
        addToast("Group Name is missing in selected rows", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      else
      {
        let isSubmit = true;
        if (
          workActivityforConfigId == 0 ||
          workActivityforConfigId == "0" ||
          workActivityforConfigId  == ""
        ) {
          // err.Allocationunit = "Allocation Unit is Required.";
          addToast("Sub-Process Work Activity is Required.", {
            appearance: "error",
            autoDismiss: true,
          });
          isSubmit = false;
        }
        //seterrorMessage({ ...err });
    
        
        if (isSubmit) {
          //set_IsLoading(true);
          //debugger;
          let body = {
            HasUniqueCheckForActivity:
              EnableUniqueCheckforthisactivityasRef.current.checked,
            Activity: parseInt(workActivityforConfigId),
            CopyActivity: Copyscreenconfig == "" ? 0 : parseInt(Copyscreenconfig),
            ShowOnbaseImage: ShowOnbaseDocumentLinkRef.current.checked,
            ExcludeWorkitemsOlderthanthis: Excludeworkitemsolderthan,
            AllowAssignmentfornextactivity:
              AllowassignuserfornextactivityRef.current.checked,
            AllowUploadForWorkItem:
              AllowuploadattachmentfortheworkitemsRef.current.checked,
            HasUniqueCheckForActivityMessageType:
              checkedValue == "true" ? true : false,
            AllowOnbaseDocumentUpload: UploadDocumenttoOnbaseRef.current.checked,
            isAppendImageToOnbaseDocument:
              AppendImagetoOnbasedocumentRef.current.checked,
            isAllowSkipButton: AllowskipbuttonRef.current.checked,
            chkAutoImagePopUp: AllowAutoPopupofImageRef.current.checked,
            countDownTimer: ShowCountdownTimerRef.current.checked,
            IsShowScreenPopup: AllowDataValidationRef.current.checked,
            ShowWorkitemAuditTrail: HideWorkitemAuditTrailRef.current.checked,
            ShowWorkitemAssignmentDetails:
              HideWorkitemAssignmentDetailsRef.current.checked,
            ShowWorkitemChecklistCommentDetails:
              HideWorkitemChecklistCommentDetailsRef.current.checked,
            ScreenActivityDetails: Data,
            UpdatedBy: sessionStorage.getItem("PersonID")
          };
    
          let loginUrl = getAPIURL(REACT_APP_SAVEANDUPDATESCREENACTIVITYDETAILS);
          let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              UserName: sessionStorage.getItem("UserName"),
              Authorization:
                "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
          });
          fetch(req)
            .then((res) => res.json())
            .then((result) => {
              if (result.status === 200) {
                //set_IsLoading(false);
                setbuttonSave(true);
                GetCopySubProcessActivityForSubProcessID();
                addToast("Subprocess Activity Screen Config saved successfully", {
                  appearance: "success",
                  autoDismiss: true,
                });
                //debugger;
              } else if (result.status === 401) {
                //set_IsLoading(false);
                addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                  appearance: "error",
                  autoDismiss: true,
                });
                sessionStorage.clear();
                sessionStorage.clear();
                //navigate("/");
              } else {
                //set_IsLoading(false);
                addToast("SubProcess Activity Screen Config Failed to save", {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            })
            .catch((error) => {
              console.log("ERROR: ", error);
              //navigate("/");
            });
        }
      }
    
  };

  const handleWorkActivityDropdownChange = (e) => {
    // console.log(typeof(e.target.value));
    setworkActivityforConfigId(e.target.value);
    GetScreenActivityDetails(e);
  }

  useEffect(
    () => {
      GetCopySubProcessActivityForSubProcessID();
      GetSubProcessActivityForSubProcessID();
    },
    [
      //reference.SubprocessID
    ]
  );

  /*useEffect(() => {
    GetScreenActivityDetails();
  }, [reference.workActivityforConfig]);*/

  return (
    <>
      {/* One Scenario */}
      {isLoading ? (
        <div className="pt-4">
          {" "}
          <LoadingSpinner />
        </div>
      ) : (
        <div>
          {/* <GlobalStyles
            styles={{
              ".MuiButtonBase-root.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26) !important",
                cursor: "default !important",
                PointerEvent: "none !important",
              },
              ".MuiButton-root": {
                color: "#1976d2 !important",               
              },
            }}
          /> */}
          <div className="dashBoxs mb-3" style={{ marginTop: "1.0rem" }}>
            <div className="formWrap">
              <div className="fieldSet mb-4">
                {" "}
                <div className="row align-items-end">
                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px", color: "black" }}
                      >
                        Sub-Process Work Activity 
                        <span
                          id=""
                          className="mandatory"
                          style={{ color: "#ff0000" }}
                        >
                          *
                        </span>
                      </label>

                      <select
                        className="form-control form-control-sm"
                        //required={true}
                        //disabled={true}
                        //ref={AllocationunitRef}
                        //onChange={handleworkactivity}
                        //value={Workactivity}
                        //defaultValue={selectedTask != "" ? selectedTask : ""}
                        value={workActivityforConfigId}
                        onChange={handleWorkActivityDropdownChange}
                        defaultValue="Select"
                      >
                        <option value="0">Select</option>
                        {Subprocessworkactivity.map((activity) => (
                          <option
                            selected={
                              activity.subProcessWorkActivityConfigID ==
                              workActivityforConfigId
                            }
                            value={activity.subProcessWorkActivityConfigID}
                          >
                            {activity.activityName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="mb-3">
                      <label
                        className="form-label"
                        style={{ fontSize: "13px", color: "black" }}
                      >
                        Copy Screen Configuration From
                      </label>
                      <select
                        className="form-control form-control-sm"
                        value={Copyscreenconfig}
                        onChange={CopyScreenConfigFromHandle}
                      >
                        <option value="0">Select</option>
                        {CopySubprocessworkactivity.map((activity) => (
                          <option
                            value={activity.subProcessWorkActivityConfigID}
                          >
                            {activity.activityName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary ms-3"
                        //ref={ButtonTextRef}
                        disabled={!copyEnable}
                        onClick={() => {
                          CopyScreenConfiguration();
                        }}
                      >
                        Copy Screen Configuration
                      </button>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          // value="option1"
                          // onChange={handleunassignedcheckbox}
                          ref={ShowOnbaseDocumentLinkRef}
                        />
                        <label
                          className="form-check-label"
                          for="inlineCheckbox1"
                          style={{ fontSize: "13px" }}
                        >
                          Show Onbase Document Link
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          ref={UploadDocumenttoOnbaseRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox2"
                          style={{ fontSize: "13px" }}
                        >
                          Upload Document to Onbase
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox3"
                          ref={AppendImagetoOnbasedocumentRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox3"
                          style={{ fontSize: "13px" }}
                        >
                          Append Image to Onbase document
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox4"
                          ref={HideWorkitemAuditTrailRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox4"
                          style={{ fontSize: "13px" }}
                        >
                          Hide Workitem Audit Trail
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox5"
                          ref={HideWorkitemAssignmentDetailsRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox5"
                          style={{ fontSize: "13px" }}
                        >
                          Hide Workitem Assignment Details
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox6"
                          ref={HideWorkitemChecklistCommentDetailsRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox6"
                          style={{ fontSize: "13px" }}
                        >
                          Hide Workitem Checklist Comment Details
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox7"
                          ref={AllowassignuserfornextactivityRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox7"
                          style={{ fontSize: "13px" }}
                        >
                          Allow assign user for next activity
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox8"
                          ref={AllowuploadattachmentfortheworkitemsRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox8"
                          style={{ fontSize: "13px" }}
                        >
                          Allow upload attachment for the workitems
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox9"
                          ref={AllowskipbuttonRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox9"
                          style={{ fontSize: "13px" }}
                        >
                          Allow skip button
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox10"
                          ref={EnableUniqueCheckforthisactivityasRef}
                          onChange={EnableUniqueCheckforthisactivityasHandle}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox10"
                          style={{ fontSize: "13px" }}
                        >
                          Enable Unique Check for this activity as
                        </label>
                      </div>

                      <div className="form-check form-check-inline ms-4 mx-0">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="true"
                          disabled={!enable}
                          checked={checkedValue === "true" ? "yes" : null}
                          //onChange={getPreference}
                        />
                        <label
                          className="form-check-label"
                          for="inlineRadio1"
                          style={{ fontSize: "13px" }}
                        >
                          Warning
                        </label>
                      </div>
                      <div className="form-check form-check-inline ms-4 mx-0">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="false"
                          checked={checkedValue === "false" ? "yes" : null}
                          //onChange={getPreference}
                          disabled={!enable}
                        />
                        <label
                          className="form-check-label"
                          for="inlineRadio2"
                          style={{ fontSize: "13px" }}
                        >
                          Error
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div
                      className="mb-1 d-flex flex-row"
                      style={{ width: "430px" }}
                    >
                      <label
                        className="form-label"
                        style={{ fontSize: "13px", color: "black" }}
                      >
                        Exclude workitems older than
                      </label>
                      <input
                        className="form-control form-control-sm"
                        style={{ width: "60px" }}
                        type="number"
                        pattern="/^[0-9]*$/"
                        onChange={ExcludeworkitemsolderthanHandle}
                        value={Excludeworkitemsolderthan}
                        disabled={!enable}
                      />
                      <label
                        className="form-label"
                        style={{
                          marginLeft: ".5rem",
                          fontSize: "13px",
                          color: "black",
                        }}
                      >
                        days
                      </label>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox11"
                          ref={AllowAutoPopupofImageRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox11"
                          style={{ fontSize: "13px" }}
                        >
                          Allow Auto Popup of Image
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox12"
                          ref={ShowCountdownTimerRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox12"
                          style={{ fontSize: "13px" }}
                        >
                          Show Countdown Timer
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mb-3">
                      <div className="form-check form-check-inline ms-0 mx-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox13"
                          ref={AllowDataValidationRef}
                        />

                        <label
                          className="form-check-label"
                          for="inlineCheckbox13"
                          style={{ fontSize: "13px" }}
                        >
                          Allow Data Validation
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-11">
              <label
                className="form-check-label"
                style={{
                  fontSize: "13px",
                  color: "blue",
                  marginLeft: "0.725rem",
                }}
              >
                Set Work Activity Screen For a Sub-process Work Activity
              </label>
              <table className="table table-borderless MatTable">
                {/* <ThemeProvider theme={theme}> */}
                <MaterialTable
                  localization={{
                    pagination: {
                      //labelDisplayedRows: '{from}-{to} of {count}'
                    },
                    toolbar: {},

                    header: {
                      actions: "",
                    },
                  }}
                  actions={[]}
                  //title="Set Work Activity Screen For a Sub-process Work Activity"
                  options={{
                    headerStyle: {
                      // position: "sticky",
                      // top: "-5px"
                    },
                    //maxBodyHeight: "420px",
                    //pagination:true,
                    //paginationType:"normal",
                    //paginationPosition: "bottom",
                    //pageSize: numberOfRecord,
                    //pageSizeOptions: [100, 200, 500, 1000],
                    paging: false,
                    //draggable: true,
                    sorting: false,
                    exportButton: false,
                    sorting: false,
                    filtering: false,
                    search: false,
                    selection: false,
                    tableLayout: "auto",
                    overflowY: true,
                    showTitle: false,
                    toolbarButtonAlignment: "right",
                    headerStyle: {
                      // height: 30
                    },
                    rowStyle: {
                      // fontFamily: '"Yantramanav", sans-serif',
                      // fontSize: "13px"
                      // whiteSpace: "nowrap",
                    },
                    tableLayout: "auto",
                    headerStyle: {
                      // fontFamily: '"Yantramanav", sans-serif',
                      // fontWeight: "inherit",
                      // fontSize: "14px",
                      // textAlign: "left !Important"
                    },
                  }}
                  columns={columns}
                  icons={tableIcons}
                  data={Data}
                  components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                    Toolbar: (props) => (
                      <div
                        style={{
                          backgroundColor: "rgb(206 206 206)",
                          fontSize: "0.75em",
                        }}
                      >
                        <MTableToolbar
                          {...props}
                          style={{
                            fontSize: "5px",
                          }}
                        />
                      </div>
                    ),
                  }}
                />
                {/* </ThemeProvider> */}
              </table>
            </div>
          </div>

          {/* {buttonSave ? (
              // <div className="mb-3">
              <button
                style={{ display: "none" }}
                ref={reference.SubprocessConfigRef}
                //ref={ButtonTextRef}
                onClick={() => {
                  SaveSubprocessConfig();
                }}
              ></button>
            ) : (
              // </div>
              // <div className="mb-3"> */}
          <button
            // type="submit"
            // className="btn btn-sm btn-primary ms-3"

            ref={reference.ActivityConfigRef}
            style={{ display: "none" }}
            onClick={() => {
              SaveSubprocessConfig();
            }}
          >
            {/* Update */}
          </button>
          {/* <button
            ref={reference.ActivityConfigRef}
            style={{ display: "none" }}
            onClick={() => {
              HandleScreenValid();
            }}
          >
          </button> */}
          {/* //{" "} */}
          {/* </div> */}
          {/* )}
          </div> */}
        </div>
      )}
    </>
  );
};

export default ActivityScreenConfig;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import { ToastProvider, useToasts } from "react-toast-notifications";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";
import DashboardContext from "../../../../contexts/DashboardContext";
import FormContext from "../../../../contexts/FormContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import GlobalStyles from "@mui/material/GlobalStyles";

import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addWorkItem,
  deleteWorkItem,
} from "../../../../redux/addWorkItemSlice";
import { addSelectedEmployeeID } from "../../../../redux/selectedEmployeeIDSlice";

const OutofOffice = () => {
  let interval = null;
  const location = useLocation();
  let navigate = useNavigate();
  const processingDashboardParam = location.state;

  let navData = [];

  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const [EmployeeActivityDetails, setEmployeeActivityDetails] = useState([]);
  const { REACT_APP_FETCHEMPLOYEEACTIVITYDETAILS } = process.env;
  const { REACT_APP_SAVEOUTOFOFFICECONFIGBYACTIVITY } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCHWORKITEMFIELDS } = process.env;
  const { REACT_APP_FETCHDELEGATETOLIST } = process.env;
  const { REACT_APP_NAME } = process.env;
  const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;
  const [DateTimeTo, setDateTimeTo] = useState(new Date().toLocaleDateString());
  const [DateTimeFrom, setDateTimeFrom] = useState(
    new Date().toLocaleDateString()
  );
  const { addToast } = useToasts();
  const [checkOutofOfficeStatus, setcheckOutofOfficeStatus] = useState(true);
  const [checkDelegationRuleStatus, setcheckDelegationRuleStatus] =
    useState(false);
  const [ViewDelegationSettingsModal, setViewDelegationSettingsModal] =
    useState(false);
  const [WorkItemFieldDetails, setWorkItemFieldDetails] = useState([]);
  const [DelegateToDetails, setDelegateToDetails] = useState([]);
  const [numericOperator, setnumericOperator] = useState(false);
  const [isDateField, setisDateField] = useState(false);
  const [advWorkItemText, setadvWorkItemText] = useState("");
  const [advCondition, setadvCondition] = useState("");
  const [SelectedEmployeeActivityDetails, setSelectedEmployeeActivityDetails] =
    useState([]);
  const [advWorkItemField, setadvWorkItemField] = useState("");
  const [advDelegateFieldText, setadvDelegateFieldText] = useState("");
  const [advDelegateFieldID, setadvDelegateFieldID] = useState("");
  const [advWorkItemMapToField, setadvWorkItemMapToField] = useState("");
  const [notEqual, SetnotEqual] = useState("<>");
  const [lessThan, SetlessThan] = useState("<");
  const [lessThanEqual, SetlessThanEqual] = useState("<=");
  const [greaterThen, SetgreaterThen] = useState(">");
  const [greaterThenEqual, SetgreaterThenEqual] = useState(">=");
  const [outofOfficeConfigID, setOutofOfficeConfigID] = useState("");
  const [outofOfficeUniqueID, setOutofOfficeUniqueID] = useState("");
  const [isSuccess, setisSuccess] = useState(false);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const { advWorkItemDetails, selectedEmployeeData } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Client",
      field: "clientName",
    },
    {
      title: "Process",
      field: "processName",
    },
    {
      title: "Sub Process",
      field: "subProcessName",
    },
    {
      title: "Activity",
      field: "activityName",
    },
    {
      title: "Default Delegate To",
      field: "employees",
      render: (rowData) => (
        <>
          <select
            id="ddlEmployeeList"
            className="form-control form-control-sm"
            onChange={(e) => selectedEmployee_ID(e, rowData)}
          >
            <option>Select</option>

            {rowData["employees"].map((Emp) => (
              <option
                value={Emp.empID}
                selected={Emp.empID == matchingData(rowData)}
              >
                {Emp.empName}
              </option>
            ))}
          </select>
        </>
      ),
    },
    {
      render: (rowData) => (
        <>
          <a
            title="Select Process/Sub-Process"
            onClick={() => showAdvanceDelegationSettings(rowData)}
          >
            <b className="icon-settings"></b>
          </a>
        </>
      ),
    },
  ];
  const matchingData = (rowData) => {
    if (
      selectedEmployeeData.filter((obj) => obj.rowId === rowData.tableData.id)
        .length != 0
    ) {
      return selectedEmployeeData.filter(
        (obj) => obj.rowId === rowData.tableData.id
      )[0].empId;
    }
  };
  let advanceColumns = [
    { title: "Work Item Field", field: "WorkItemField" },
    { title: "Operator", field: "condition" },
    { title: "Value", field: "searchText" },
    { title: "Assign To", field: "DelegateField" },
  ];

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FB4E0B",
      },
      secondary: {
        main: "#FB4E0B",
      },
    },
  });

  const showAdvanceDelegationSettings = (rowData) => {
    setViewDelegationSettingsModal(true);
    setadvWorkItemText("");
    setOutofOfficeConfigID(rowData.outofOfficeConfigID);
    setOutofOfficeUniqueID(rowData.tableData.id);
    showWorkItemFields(rowData.subProcessID);
    showDelegateToList(rowData.subProcessID, rowData.activityID);
  };

  const showDelegateToList = (SelectedSubProcessID, SelectedActivityID) => {
    let body = {
      PersonID: sessionStorage.getItem("PersonID"),
      SubProcessID: SelectedSubProcessID,
      ActivityID: SelectedActivityID,
    };

    let activityUrl = getAPIURL(REACT_APP_FETCHDELEGATETOLIST);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDelegateToDetails(result.data._listStatus);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const getAdvanceText = (e) => {
    setadvWorkItemText(e.target.value);
  };

  const showWorkItemFields = (SelectedSubProcessID) => {
    let body = {
      SubProcessID: SelectedSubProcessID,
    };
    let activityUrl = getAPIURL(REACT_APP_FETCHWORKITEMFIELDS);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setWorkItemFieldDetails(result.data._listStatus);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const CloseDelegationSettingsModal = () => {
    setViewDelegationSettingsModal(false);
  };

  const handleApply = (event, picker) => {
    setDateTimeFrom(picker.startDate.format("MM/DD/YYYY"));
    setDateTimeTo(picker.endDate.format("MM/DD/YYYY"));
  };

  const selectedEmployee_ID = (e, rowData) => {
    dispatch(
      addSelectedEmployeeID({
        rowId: rowData.tableData.id,
        empId: e.target.value,
      })
    );
  };

  const SaveOutofOfficeData = () => {
    for (let i = 0; i < SelectedEmployeeActivityDetails.length; i++) {
      SelectedEmployeeActivityDetails[i].employees = [];
      for (let j = 0; j < selectedEmployeeData.length; j++) {
        if (
          SelectedEmployeeActivityDetails[i].tableData.id ==
          selectedEmployeeData[j].rowId
        ) {
          SelectedEmployeeActivityDetails[i].employees.push(
            selectedEmployeeData[j].empId
          );
        }
      }
    }
    let body = {
      PersonID: sessionStorage.getItem("PersonID"),
      FromDate: DateTimeFrom, //"11/01/2022",
      ToDate: DateTimeTo, //"11/24/2022",
      IsOutofOffice: checkOutofOfficeStatus,
      IscheckDelegationRuleStatus: checkDelegationRuleStatus,
      ListOutofOffice: SelectedEmployeeActivityDetails,
      OutofOfficeConfigFields: advWorkItemDetails,
    };

    let activityUrl = getAPIURL(REACT_APP_SAVEOUTOFOFFICECONFIGBYACTIVITY);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // addToast("Out of office config saved successfully!", {
          //   appearance: "success",
          //   autoDismiss: true
          // });
          alert("Out of office config saved successfully!");

          setisSuccess(true);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;

    return `${apiURL}${api}`;
  };

  const getOutofOfficeValue = () => {
    setcheckOutofOfficeStatus(!checkOutofOfficeStatus);
  };

  const getDelegationRuleValue = (e) => {
    setcheckDelegationRuleStatus(!checkDelegationRuleStatus);
  };

  const FetchEmployeeActivityDetails = () => {
    let body = {
      personID: sessionStorage.getItem("PersonID"),
    };
    let activityUrl = getAPIURL(REACT_APP_FETCHEMPLOYEEACTIVITYDETAILS);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setEmployeeActivityDetails(result.data._listStatus);
          setSelectedEmployeeActivityDetails(result.data._listStatus);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const getAdvanceCondition = (e) => {
    setadvCondition(e.target.value);
  };

  const getAdvanceDelegateField = (e) => {
    setadvDelegateFieldText(e.target.value.split("/")[1]);
    setadvDelegateFieldID(e.target.value.split("/")[0]);
  };

  const handleRowDelete = (oldData, resolve) => {
    const dataDelete = [...advWorkItemDetails];
    const index = oldData.tableData.id;
    dispatch(deleteWorkItem(index));
    resolve();
  };

  const getAdvanceField = (e) => {
    setnumericOperator(false);
    setisDateField(false);
    setadvWorkItemText("");

    let datetimeValue = e.target.value.split("/")[0].includes("DateTime");
    let intValue = e.target.value.split("/")[0].includes("Int");
    let DecimalValue = e.target.value.split("/")[0].includes("Decimal");
    let SLADueDateValue = e.target.value.split("/")[0].includes("SLADueDate");
    let ScanDateValue = e.target.value.split("/")[0].includes("ScanDate");
    let UploadDateValue = e.target.value.split("/")[0].includes("UploadDate");
    if (
      datetimeValue > 0 ||
      intValue > 0 ||
      DecimalValue > 0 ||
      SLADueDateValue > 0 ||
      ScanDateValue > 0 ||
      UploadDateValue > 0
    ) {
      setnumericOperator(true);
      setadvCondition("=");
    } else {
      setadvCondition("Equals");
    }
    if (datetimeValue > 0) {
      setisDateField(true);
    }

    setadvWorkItemField(e.target.value.split("/")[1]);
    setadvWorkItemMapToField(e.target.value.split("/")[0]);
  };

  const addAdvanceDelegationConditions = () => {
    let condition = {
      WorkItemField: advWorkItemField,
      WorkItemMapToField: advWorkItemMapToField,
      condition: advCondition,
      searchText: advWorkItemText,
      DelegateField: advDelegateFieldText,
      DelegateFieldID: advDelegateFieldID,
      outofOfficeConfigID,
      outofOfficeUniqueID,
    };

    dispatch(addWorkItem(condition));
  };

  useEffect(() => {
    setEmployeeActivityDetails([]);
    FetchEmployeeActivityDetails();
  }, [isSuccess]);
  return (
    <>
      <Header
        onpreferenceShow={null}
        pageName="Out of Office Settings"
        timeCaptureActivityName="Work Item Management"
      />
      <DashboardContext.Provider value={processingDashboardParam.nav}>
        <NavDataContext.Provider value={navData}>
          <Nav selectedMenu="activities" />
        </NavDataContext.Provider>
      </DashboardContext.Provider>
      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-4">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-2">
                  <div className="mb-3">
                    <div class="form-check form-check-inline ms-0 mx-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="outofOfficeCheckDefault"
                        onChange={getOutofOfficeValue}
                        checked={checkOutofOfficeStatus}
                      />
                      <label
                        className="form-check-label"
                        for="outofOfficeCheckDefault"
                      >
                        I am out of office
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Date From - To</label>
                    <DateRangePicker
                      initialSettings={{
                        autoApply: true,
                        autoUpdateInput: false,
                        opens: "right",
                        timePicker: false,
                        locale: { format: "M/DD/YY hh:mm A" },
                      }}
                      onApply={handleApply}
                    >
                      <input
                        id="txtDateSelector"
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Select Date"
                        value={DateTimeFrom + "-" + DateTimeTo}
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <h4 class="mb-3">Delegation Settings</h4>                     */}
          <div className="tableWrap">
            <div className="tableWrap-body">
              <div className="dashBoxs">
                <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                  <div className="row align-items-center">
                    <div className="col-4">
                      <h6>Delegation Settings</h6>
                      {/* //<h5>Processing Dashboard</h5> */}
                    </div>
                    {/* <div className="col-8">
                      <div
                        className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                        style={{ marginTop: "-10px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                          onClick={() => {
                            downloadExcel("Employee Break");
                          }}
                        >
                          <b></b>Export
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                <table
                  className="table table-borderless MatTable"
                  style={{ zIndex: "1", position: "relative" }}
                >
                  <MaterialTable
                    title="Delegation Settings"
                    options={{
                      tableLayout: "auto",
                      overflowY: true,
                      exportButton: false,
                      sorting: true,
                      filtering: true,
                      search: false,
                      paging: true,
                      showTitle: false,
                      cellStyle: {
                        //   width: 100,
                        //   minWidth: 100,
                      },
                      headerStyle: {
                        // width: 100,
                        // minWidth: 100,
                        // fontFamily: '"Yantramanav", sans-serif',
                        // fontWeight: "inherit",
                        // fontSize: "16px",
                        // height: 40,
                        // textAlign: "left",
                      },
                      rowStyle: {
                        // fontFamily: '"Yantramanav", sans-serif',
                      },
                    }}
                    components={{
                      Container: (props) => <Paper {...props} elevation={0} />,
                      Toolbar: (props) => (
                        <div
                          style={{
                            backgroundColor: "rgb(206 206 206)",
                            fontSize: "0.75em",
                          }}
                        >
                          <MTableToolbar
                            {...props}
                            style={{ fontSize: "5px" }}
                          />
                        </div>
                      ),
                    }}
                    columns={columns}
                    icons={tableIcons}
                    data={EmployeeActivityDetails}
                  />
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 text-start mt-4">
              <div class="form-check form-check-inline ms-0 mx-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="delegationRuleCheckDefault"
                  onChange={getDelegationRuleValue}
                  checked={checkDelegationRuleStatus}
                />
                <label
                  className="form-check-label"
                  for="delegationRuleCheckDefault"
                >
                  Apply Delegation Rules and Reassign Work items immediately
                  upon save
                </label>
              </div>
            </div>
            <div className="col-6 text-end mt-2">
              <button
                className="btn btn-primary ms-3"
                onClick={SaveOutofOfficeData}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        show={ViewDelegationSettingsModal}
        dialogClassName="modal-100w"
        onHide={CloseDelegationSettingsModal}
        fullscreen={"lg-down"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Advanced Delegation Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="timeBreak">
            <ul className="timeBreak-form">
              <div className="tableWrap">
                <div className="tableWrap-body">
                  <table className="table brandTable">
                    <div className="pageBody">
                      <div className="dashBoxs mb-3">
                        <div className="formWrap">
                          <div className="">
                            <div className="row align-items-end">
                              <div className="col-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    WorkItem Field
                                  </label>
                                  <select
                                    className="form-control form-control-sm"
                                    title="Select a field to apply filter"
                                    onChange={getAdvanceField}
                                  >
                                    <option>Select</option>
                                    {WorkItemFieldDetails.map((item) => (
                                      <option
                                        value={
                                          item.maptoFieldAndDataType +
                                          "/" +
                                          item.labeltext
                                        }
                                      >
                                        {item.labeltext}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Condition
                                  </label>
                                  {numericOperator ? (
                                    <select
                                      className="form-control form-control-sm"
                                      title="Select a field to apply filter"
                                      onChange={getAdvanceCondition}
                                    >
                                      <option value="Select">Select</option>
                                      <option value="=">=</option>
                                      <option value="<>">{notEqual}</option>
                                      <option value="<">{lessThan}</option>
                                      <option value="<=">
                                        {lessThanEqual}
                                      </option>
                                      <option value=">">{greaterThen}</option>
                                      <option value=">=">
                                        {greaterThenEqual}
                                      </option>
                                    </select>
                                  ) : (
                                    <select
                                      className="form-control form-control-sm"
                                      title="Select a field to apply filter"
                                      onChange={getAdvanceCondition}
                                    >
                                      <option value="Select">Select</option>
                                      <option value="Equals">Equals</option>
                                      <option value="Begin with">
                                        Begin with
                                      </option>
                                      <option value="Contains">Contains</option>
                                      <option value="End with">End with</option>
                                      <option value="Does Not Contain">
                                        Does Not Contain
                                      </option>
                                    </select>
                                  )}
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Enter Value
                                  </label>

                                  {isDateField ? (
                                    <input
                                      type="date"
                                      className="form-control form-control-sm"
                                      onChange={getAdvanceText}
                                      value={advWorkItemText}
                                    />
                                  ) : numericOperator ? (
                                    <input
                                      type="number"
                                      className="form-control form-control-sm"
                                      onChange={getAdvanceText}
                                      value={advWorkItemText}
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      onChange={getAdvanceText}
                                      value={advWorkItemText}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Delegate To
                                  </label>
                                  <select
                                    className="form-control form-control-sm"
                                    onChange={getAdvanceDelegateField}
                                  >
                                    <option>Select</option>
                                    {DelegateToDetails.map((item) => (
                                      <option
                                        value={item.personID + "/" + item.name}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="mb-3">
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary ms-3"
                                    onClick={addAdvanceDelegationConditions}
                                  >
                                    {" "}
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <h4 class="mb-3">Delegation Rules</h4>

                      <div className="pt-2 pb-2">
                        <div className="mb-5">
                          <div>
                            <table className="table table-borderless MatTable">
                              <ThemeProvider theme={theme}>
                                <MaterialTable
                                  localization={{
                                    pagination: {
                                      //labelDisplayedRows: '{from}-{to} of {count}'
                                    },
                                    toolbar: {
                                      // nRowsSelected: '{0} row(s) selected'
                                    },
                                    header: {
                                      actions: "",
                                    },
                                  }}
                                  options={{
                                    search: false,
                                    // selection: true,
                                    tableLayout: "auto",
                                    overflowY: false,
                                    showTitle: false,
                                    toolbarButtonAlignment: "left",
                                    paging: false,
                                    toolbar: false,
                                    headerStyle: {
                                      height: 30,
                                    },
                                    rowStyle: {
                                      fontFamily: '"Yantramanav", sans-serif',
                                      fontSize: "14px",
                                    },
                                    tableLayout: "auto",
                                    headerStyle: {
                                      fontFamily: '"Yantramanav", sans-serif',
                                      fontWeight: "inherit",
                                      fontSize: "14px",

                                      textAlign: "left !Important",
                                    },
                                  }}
                                  editable={{
                                    onRowDelete: (oldData) =>
                                      new Promise((resolve) => {
                                        handleRowDelete(oldData, resolve);
                                      }),
                                  }}
                                  columns={advanceColumns}
                                  icons={tableIcons}
                                  data={advWorkItemDetails
                                    .filter(
                                      (o) =>
                                        o.outofOfficeUniqueID ===
                                        outofOfficeUniqueID
                                    )
                                    .map((o) => ({ ...o }))}
                                />
                              </ThemeProvider>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default OutofOffice;

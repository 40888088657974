import React from "react";
import "./App.css";
import HashLoader from "react-spinners/HashLoader";

export default function LoadingSpinner() {
  return (
    <HashLoader
      color="#FFCB05"
      loading="true"
      cssOverride={{
        display: "block",
        margin: "0 auto",
        borderColor: "red",
        speedMultiplier: "0.5",
      }}
      size={40}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
}

import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import Nav from "../../../../components/nav/Nav";
import GlobalStyles from "@mui/material/GlobalStyles";
import EmployeeConfig from "./EmployeeConfig";
import SubprocessConfig from "./SubprocessConfig";
import DashboardConfig from "./DashboardConfig";
import FormBuilderDemo from "./FormBuilderConfig";
import ActivityScreenConfig from "./ActivityScreenConfig";
import BpmnEditor from "./WorkFlowConfig";
import UploadExcelColumns from "./UploadExcelColumns";
import WorkActivityConfig from "./WorkActivityConfig";
import ManageWorkflowConfig from "./ManageWorkflowConfig";
import WorkItemStatus from "../Admin/WorkItemStatus";
import WorkFlowState from "../Admin/WorkFlowState";
import EmailDomain from "../Admin/EmailDomain";
import ValidationRule from "../Admin/ValidationRule";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ConfigContext from "../../../../contexts/ConfigContext";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";
import { Container, Link } from "react-floating-action-button";
import { Button as ButtonFloat } from "react-floating-action-button";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StatusReason from "../Admin/StatusReason/StatusReason";
import Activities from "../Admin/Activities/Activities";
import ErrorFields from "../Admin/ErrorFields/ViewErrorFields";
import EmailConfiguration from "../Admin/EmailConfiguration";
import SubProcessInstructionFieldConfig from "../Admin/SubProcessInstructionFieldConfig";
import UploadDropdownValues from "../Admin/UploadDropdownValues";
import SubProcessUserGroupConfiguration from "../Admin/SubProcessUserGroupConfiguration";
import ImportSubProcessConfiguration from "../Admin/ImportSubProcessConfiguration";
import HierachyReportConfig from "../Admin/HierarchyReportConfig";
import SubProcessStatusHistoryMapping from "../Admin/SubProcessStatusHistoryMapping";

const steps = [
  "Create WorkFlow",
  "Create Workitem Field",
  "Sub-Process Configuration",
  "Dashboard Configuration",
  "Sub - Process Work Activity Screen Config",
  "Employee Access Configuration",
  "Upload Columns Configuration",
  "Validate Configuration",
  "Upload Workitem Fields Dropdown values",
  "Validation Rule",
  "Email Domain",
  "Status Reason",
  "Activities",
  "Error Fields",
  "WorkItem Statuses",
  "WorkFlow State",
  "Email Configuration",
  "Sub-Process Instruction Field Config",
  "Import Sub-Process Configuration",
  "Hierarchy Report Configuration",
  "Sub-Process Status History Config",
  "Sub-Process User Group Configuration"
];
const stepStyle = {
  padding: 0.5,
  height: "25px",
  "& .MuiButtonBase-root": {
    "& .MuiButton-root": {
      color: "#198754"
    }
  },
  "& .Mui-active": {
    "&.MuiStepIcon-root": {
      //   color: "#FB4E0B",
      fontSize: "5rem"
    },
    "& .MuiStepConnector-line": {
      borderColor: "#198754"
    }
  },
  "& .Mui-completed": {
    "&.MuiStepIcon-root": {
      color: "#198754",
      fontSize: "5rem"
    },
    "& .MuiStepConnector-line": {
      borderColor: "#198754"
    }
  }
};
const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    adaptiveHeight: true
  };

  return (
    <Slider {...settings}>
      <div>{/* Content for Step 1 */}</div>
      <div>{/* Content for Step 2 */}</div>
      {/* Add more slides as needed */}
    </Slider>
  );
};
function WorkflowWizard() {
  let navigate = useNavigate();
  let navData = [];
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_FETCHPROCESSSUBPROCESSCONFIGURATION } = process.env;
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_UILOGEXTENSION } = process.env;
  const { REACT_APP_GETSUBPROCESSIDACTIVITYFORSUBPROCESSID } = process.env;
  const { REACT_APP_SUBPROCESSWORKFLOWPUBLISH } = process.env;
  const { REACT_APP_CheckValidDashBoardConfig } = process.env;
  const { REACT_APP_CheckValidScreenConfig } = process.env;

  const { addToast } = useToasts();
  const currentPage = window.location.href;
  const FormBuilderConfigRef = useRef(null);
  const DashBoardConfigRef = useRef(null);
  const SubprocessConfigRef = useRef(null);
  const FlowConfigRef = useRef(null);
  // const ValidateDashBoardConifgRef = useRef(null);
  // const ValidateScreenConfigRef = useRef(null);
  const location = useLocation();
  const EmployeeConfigRef = useRef(null);
  const ActivityConfigRef = useRef(null);
  const UploadExcelConfigRef = useRef(null);
  const ExportExcelColRef = useRef(null);
  const processingDashboardParam = location.state;
  const [preferenceModal, setShow] = useState(false);
  const [IsNextDisable, setIsNextDisable] = useState(false);
  const [departments, setdepartments] = useState([]);
  const [ClientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [ActivityforConfig, setActivityforConfig] = useState(0);
  const [ActivityforConfigData, setActivityforConfigData] = useState(0);
  const [
    ActivityforEmployeeConfigData,
    setActivityforEmployeeConfigData
  ] = useState(0);
  const [
    workActivityforEmployeeConfig,
    setworkActivityforEmployeeConfig
  ] = useState(0);
  const [workActivityforConfig, setworkActivityforConfig] = useState(0);
  const [ActivityNameforConfig, setActivityNameforConfig] = useState("");
  const [SubprocessID, setSubprocessID] = useState(
    sessionStorage.getItem("ProcessId")
  );
  const [isSkipToAdminPages, setisSkipToAdminPages] = useState(false);
  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }
  const NavElements = useMemo(() => {
    return (
      <>
        <Header pageName="Configuration Wizard" />
        <ApplicationUserContext.Provider
          value={processingDashboardParam.userRoles}
        >
          <DashboardContext.Provider value={processingDashboardParam.nav}>
            <NavDataContext.Provider value={navData}>
              <Nav />
            </NavDataContext.Provider>
          </DashboardContext.Provider>
        </ApplicationUserContext.Provider>
      </>
    );
  }, [navData]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [activities, setactivities] = useState([]);
  const [skipped, setSkipped] = React.useState(new Set());
  const [open, setOpen] = useState(false);
  const [IsNext, setIsNext] = useState(false);

  useEffect(() => {
    setSubprocessID(sessionStorage.getItem("ProcessId"));
    FetchDepartmentbyGUID();
  }, []);
  useEffect(() => {
    //sessionStorage.setItem("IsPublished", "false");
    setIsNext(sessionStorage.getItem("IsPublished") === "true" ? true : false);
  }, []);
  const getAPIURL = api => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };
  const publishWorkflow = async () => {
    
    let body = {
      SubProcessID: SubprocessID,
      WorkflowXml: null
    };
    let fetchUrl = getAPIURL(REACT_APP_SUBPROCESSWORKFLOWPUBLISH);
    let req = new Request(`${fetchUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
          //setIsPublish(true);
          sessionStorage.setItem("IsPublished", "true");
          addToast("Subprocess Validated Successfully", {
            appearance: "success",
            autoDismiss: true
          });
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          localStorage.clear();
          navigate("/");
        } else {
          addToast("Subprocess validation failed", {
            appearance: "error",
            autoDismiss: true
          });
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        //setIsDashboardload(false);
        //navigate("/");
      });
  };
  const FetchDepartmentbyGUID = () => {
    //setprocessSubprocess([]);
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID")
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setdepartments(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
        if (error.name == "TypeError" && error.message == "Failed to fetch") {
          BodydetailsinLog(error.toString() + " FetchDepartmentbyGUID ", body);
        }
      });
  };
  const GetActivitiesForDashboardConfig = e => {
    setActivityforConfig(+e.target.value);
    setActivityNameforConfig(e.target.options[e.target.selectedIndex].text);
  };
  const GetActivitiesForWorkactivityConfig = e => {
    setActivityforConfigData(+e.target.value);
  };
  const GetActivityForEmployeeConfig = e => {
    setActivityforEmployeeConfigData(+e.target.value);
  };
  const HandleworkActivityforEmployeeConfig = () => {
    setShow(false);
    setworkActivityforEmployeeConfig(ActivityforEmployeeConfigData);
  };

  const BodydetailsinLog = (error, body) => {
    let Bodydetails = "";
    for (let i = 0; i < Object.keys(body).length; i++) {
      if (
        Object.values(body)[i] != null &&
        typeof Object.values(body)[i] == "object"
      ) {
        Bodydetails = Bodydetails + ", \n" + Object.keys(body)[i] + ": {";
        for (let j = 0; j < Object.keys(Object.values(body)[i]).length; j++) {
          if (j != 0) {
            Bodydetails = Bodydetails + ",";
          }
          if (
            Object.values(Object.values(body)[i])[j] != null &&
            typeof Object.values(Object.values(body)[i])[j] == "object"
          ) {
            Bodydetails =
              Bodydetails +
              " " +
              Object.keys(Object.values(body)[i])[j] +
              ": {";
            for (
              let a = 0;
              a < Object.keys(Object.values(Object.values(body)[i])[j]).length;
              a++
            ) {
              if (a != 0) {
                Bodydetails = Bodydetails + ",";
              }
              Bodydetails =
                Bodydetails +
                " " +
                Object.keys(Object.values(Object.values(body)[i])[j])[a] +
                ": " +
                Object.values(Object.values(Object.values(body)[i])[j])[a];
            }
            Bodydetails = Bodydetails + " } ";
          } else {
            Bodydetails =
              Bodydetails +
              " " +
              Object.keys(Object.values(body)[i])[j] +
              ": " +
              Object.values(Object.values(body)[i])[j];
          }
        }
        Bodydetails = Bodydetails + " } ";
      } else {
        Bodydetails =
          Bodydetails +
          ", \n" +
          Object.keys(body)[i] +
          ": " +
          Object.values(body)[i];
      }
    }
    CorsLoggingError(error.toString() + " " + Bodydetails.toString());
  };
  const subprocessSubmit = () => {
    setShow(false);
    //setcompanyDeparmentID("");
    //setClientLocations([]);
    //setprocessSubprocess([]);
    //setlocationClientID("");
    //setActiveStep((prevActiveStep) => prevActiveStep + 0);
  };

  const FetchProcessSubprocess = e => {
    setSubprocessID(e.target.value.split("/")[0]);
  };
  const CorsLoggingError = error => {
    let logUrl = getAPIURL(REACT_APP_UILOGEXTENSION);
    let body = {
      personID: +sessionStorage.getItem("PersonID"),
      lanID: sessionStorage.getItem("UserName"),
      message: "Cors Error: " + error,
      pageName: currentPage.split("/")[currentPage.split("/").length - 1],
      logType: true
    };
    let req = new Request(`${logUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status === 200) {
        } else {
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };
  const FetchLocationClientsByGuid = e => {
    setClientLocations([]);
    setprocessSubprocess([]);
    if (e.target.value == "Select") {
      setdepartments([]);
      setClientLocations([]);
    } else {
      setcompanyDeparmentID(e.target.value.split("/")[1]);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1]
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(
              error.toString() + " FetchLocationClientsByGuid ",
              body
            );
          }
        });
    }
  };
  const FetchProcessSubProcessByGuid = e => {
    setprocessSubprocess([]);
    setlocationClientID(
      e.target.value.split("/")[e.target.value.split("/").length - 1]
    );
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
    } else {
      let body = {
        Guid: sessionStorage.getItem("GUID").toString(),
        SubProcessId: "0",
        ClientId: e.target.value
          .split("/")
        [e.target.value.split("/").length - 1].toString()
      };
      let loginUrl = getAPIURL(REACT_APP_FETCHPROCESSSUBPROCESSCONFIGURATION);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization:
            "Bearer " + sessionStorage.getItem("authorizationToken")
        },
        body: JSON.stringify(body)
      });
      fetch(req)
        .then(res => res.json())
        .then(result => {
          if (result.status == 200) {
            setprocessSubprocess(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
            setprocessSubprocess([]);
          } else {
            setprocessSubprocess([]);
          }
        })
        .catch(error => {
          console.log("ERROR: ", error);
          if (error.name == "TypeError" && error.message == "Failed to fetch") {
            BodydetailsinLog(
              error.toString() + " FetchProcessSubProcessByGuid 1 ",
              body
            );
          }
        });
    }
  };
  const preferenceClose = () => {
    setShow(false);
    //setcompanyDeparmentID("");
    //setlocationClientID("");
    //setSubprocessID("");
    //setprocessSubprocess([]);
    //setClientLocations([]);
    //setprocessSubprocess([]);
  };

  const HandleworkActivityforConfig = () => {
    setShow(false);
    setworkActivityforConfig(ActivityforConfigData);
  };
  const isStepOptional = step => {
    return step === 1;
  };
  const isStepSkipped = step => {
    return skipped.has(step);
  };
  const PublishClickForm = () => {
    FormBuilderConfigRef.current.click();
  };
  const EmpConfig = () => {
    EmployeeConfigRef.current.click();
  };
  const SaveUploadExcelConfig = () => {
    UploadExcelConfigRef.current.click();
  };
  const ExportUploadExcelCol = () => {
    ExportExcelColRef.current.click();
  };
  const SaveDashboardConfig = () => {
    
    DashBoardConfigRef.current.click();
  };
  const SubConfig = () => {
    SubprocessConfigRef.current.click();
  };

  const PublishClickFlow = () => {
    FlowConfigRef.current.click();
  };
  const ValidateScreenConfig = () => {
    let body = {
      SubProcessID: SubprocessID,
    };
    let dashboardValidationUrl = getAPIURL(REACT_APP_CheckValidScreenConfig);
    let req = new Request(`${dashboardValidationUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          
          if (result.data) {
            setIsNextDisable(true);
            addToast("Screen Config published successfully", {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            setIsNextDisable(false);
            addToast("All Screen Are not Configured.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        } else if (result.status === 401) {
          setIsNextDisable(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setIsNextDisable(false);
        }
      })
      .catch((error) => {
        setIsNextDisable(false);
        console.log("ERROR: ", error);
      });
  };
  const ValidateDashboardConfig = () => {
    
    let body = {
      SubProcessID: SubprocessID,
    };
    let dashboardValidationUrl = getAPIURL(REACT_APP_CheckValidDashBoardConfig);
    let req = new Request(`${dashboardValidationUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          
          if (result.data) {
            setIsNextDisable(true);
            addToast("Dashboard Config published successfully", {
              appearance: "success",
              autoDismiss: true,
            });
          } else {
            setIsNextDisable(false);
            addToast("All Dashboard Are not Configured.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        } else if (result.status === 401) {
          setIsNextDisable(false);
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
          setIsNextDisable(false);
        }
      })
      .catch((error) => {
        setIsNextDisable(false);
        console.log("ERROR: ", error);
      });
  };
  const ActivityConfig = () => {
    ActivityConfigRef.current.click();
  };

  const GetSubProcessActivityForSubProcessID = () => {
    let body = {
      SubProcessId: SubprocessID
    };
    let workflowConfigUrl = getAPIURL(
      REACT_APP_GETSUBPROCESSIDACTIVITYFORSUBPROCESSID
    );
    let req = new Request(`${workflowConfigUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken")
      },
      body: JSON.stringify(body)
    });
    fetch(req)
      .then(res => res.json())
      .then(result => {
        if (result.status == 200) {
          setactivities(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else {
        }
      })
      .catch(error => {
        console.log("ERROR: ", error);
      });
  };
  const getDisplayedSteps = () => {
    const startIndex = activeStep > 3 ? activeStep - 3 : 0;
    const endIndex = Math.min(startIndex + 4, steps.length);
    return steps.slice(startIndex, endIndex);
  };

  const HandleSkipToAdminPages = () => {
    setActiveStep(activeStep + 8);
    setisSkipToAdminPages(true);
  }
  const HandleSkipToWorkflowConfig = () => {
    setActiveStep(0);
    setisSkipToAdminPages(false);
  }

  const handleNext = () => {
    
    //setIsNextDisable(false);
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep + 1 == 3) {
      GetSubProcessActivityForSubProcessID();
    }
    
    let currentStep = activeStep + 1;
    if (
      currentStep != 1 &&
      currentStep != 2 &&
      currentStep != 5 &&
      currentStep != 6 &&
      currentStep != 7 &&
      currentStep != 8 &&
      currentStep != 9 &&
      currentStep != 10 &&
      currentStep != 11 &&
      currentStep != 12 &&
      currentStep != 13 &&
      currentStep != 14 &&
      currentStep != 15 &&
      currentStep != 16 &&
      currentStep != 17 &&
      currentStep != 18 &&
      currentStep != 19 &&
      currentStep != 20
    ) {
      setIsNextDisable(false);
    }
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep == steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(prevActiveStep =>
        Math.min(prevActiveStep + 1, steps.length - 1)
      );
    }

    setSkipped(newSkipped);

    setworkActivityforConfig(0);
    setworkActivityforEmployeeConfig(0);
  };
  const handleBack = () => {
    //setActiveStep((prevActiveStep) => prevActiveStep - 1);
    let currentStep = activeStep + 1;
    if (
      currentStep != 1 &&
      currentStep != 2 &&
      currentStep != 5 &&
      currentStep != 6 &&
      currentStep != 7
    ) {
      setIsNextDisable(true);
    }
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));

    setworkActivityforConfig(0);
    setworkActivityforEmployeeConfig(0);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const handleOpen = () => {
    setShow(true);
  };
  const handleDataReceive = (data) => {
    setIsNextDisable(data);
  };

  return (
    <>
      {NavElements}
      <GlobalStyles
        styles={{
          ".MuiTableCell-root": {
            padding: "5px !important",
            paddingbottom: "50px !important",
            "min-width": "10px !Important"
          },
          ".MuiTableFooter-root": {
            position: "absolute !Important"
          },
          ".MuiTypography-root": {
            "font-size": "1.0rem !important"
          },
          ".MuiCheckbox-root": { padding: "5px !important" },
          ".MuiSvgIcon-root": {
            width: "0.7em !important",
            height: "0.7em !important",

            "font-size": "1.5rem !important"
          },
          ".MuiIconButton-root": {
            "font-size": "1rem !important"
          },
          ".MuiTablePagination-toolbar": {
            width: "0px !important"
            // "max-width": "1000px !important",
          },
          ".MuiToolbar-regular": {
            "min-height": "0px !important"
          },
          ".MuiTableFooter-root": {
            position: "sticky!Important",
            "background-color": "#fff6f0 !Important",
            padding: "0px !important"
          },
          ".MuiButtonBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.26) !important",
            cursor: "default !important",
            PointerEvent: "none !important",
          },
          ".MuiButton-root": {
            color: "#1976d2 !important",
          },
        }}
      />
      <div className="centerSection">
        <div className="">
          <div
            className="dashBoxs"
            style={{ padding: "0", backgroundColor: "#eeeeee", border: "0" }}
          >
            <div className="row align-items-end">
              <div className="col-12">
                <div className="mb-3">
                  <div className="row" style={{ paddingTop: "15px" }}>
                    <Box sx={{ width: "100%", position: "relative" }}>
                      <Box
                        sx={{
                          position: "relative",
                          borderBottom: "1px solid #FB4E0B",
                          backgroundColor: "#fff",
                          zIndex: 900,
                          paddingBottom: "10px !important",
                          paddingTop: "10px !important",
                          boxShadow: "0 0 5px #acacac",
                          borderRadius: "5px"
                        }}
                      >
                        <Stepper activeStep={activeStep} sx={stepStyle}>
                          {getDisplayedSteps().map((label, index) => {
                            const stepIndex =
                              index + (activeStep > 3 ? activeStep - 3 : 0);
                            const isCompleted = stepIndex < activeStep;
                            const isActive = stepIndex === activeStep;
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepSkipped(index)) {
                              stepProps.completed = false;
                            }
                            return (
                              <Step
                                key={label}
                                index={stepIndex}
                                {...stepProps}
                                style={{ paddingRight: "4px" }}
                                completed={isCompleted}
                                active={isActive}
                              >
                                <StepLabel>
                                  <b>{label}</b>
                                </StepLabel>
                              </Step>
                            );
                          })}
                          {/* {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepSkipped(index)) {
                              stepProps.completed = false;
                            }
                            return (
                              <Step
                                key={label}
                                {...stepProps}
                                style={{ paddingRight: "4px" }}
                              >
                                <StepLabel>
                                  <b>{label}</b>
                                </StepLabel>
                              </Step>
                            );
                          })} */}
                        </Stepper>
                      </Box>
                      <Box
                        sx={{
                          height: "475px",
                          overflowY: "auto",
                          marginTop: "-15px"
                          //marginTop: `${headerHeight}px`,
                        }}
                      >
                        {activeStep === steps.length ? (
                          <React.Fragment style={{ paddingTop: "25px" }}>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              All steps completed - you&apos;re finished
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: 2
                              }}
                            >
                              <Box sx={{ flex: "1 1 auto" }} />
                              {/* <Button onClick={handleReset}>Reset</Button> */}
                            </Box>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              {activeStep == 0 ? (
                                // <ManageWorkflowConfig />
                                <BpmnEditor
                                  onDataSend={handleDataReceive}
                                  reference={{ FlowConfigRef, SubprocessID }}
                                />
                              ) : activeStep == 1 ? (
                                <FormBuilderDemo
                                  reference={{
                                    FormBuilderConfigRef,
                                    SubprocessID
                                  }}
                                />
                              ) : activeStep == 2 ? (
                                <SubprocessConfig
                                  reference={{
                                    SubprocessConfigRef,
                                    SubprocessID
                                  }}
                                />
                              ) : activeStep == 3 ? (
                                <DashboardConfig
                                  //onDataSend={handleDataReceive}
                                  reference={{
                                    SubprocessID,
                                    ActivityforConfig,
                                    ActivityNameforConfig,
                                    DashBoardConfigRef
                                  }}
                                />
                              ) : activeStep == 4 ? (
                                <ActivityScreenConfig
                                  //onDataSend={handleDataReceive}
                                  reference={{
                                    ActivityConfigRef,
                                    workActivityforConfig,
                                    SubprocessID
                                  }}
                                />
                              ) : activeStep == 5 ? (
                                <EmployeeConfig
                                  reference={{
                                    EmployeeConfigRef,
                                    workActivityforEmployeeConfig,
                                    SubprocessID
                                  }}
                                />
                              ) : activeStep == 6 ? (
                                <UploadExcelColumns
                                  reference={{
                                    UploadExcelConfigRef,
                                    ExportExcelColRef,
                                    SubprocessID
                                  }}
                                />
                              ) : activeStep == 8 ? (
                                <UploadDropdownValues />
                              )
                                : activeStep == 9 ? (
                                  <ValidationRule />
                                ) : activeStep == 10 ? (
                                  <EmailDomain />
                                ) : activeStep == 11 ? (
                                  <StatusReason />
                                ) : activeStep == 12 ? (
                                  <Activities />
                                ) : activeStep == 13 ? (
                                  <ErrorFields />
                                ) : activeStep == 14 ? (
                                  <WorkItemStatus />
                                ) : activeStep == 15 ? (
                                  <WorkFlowState />
                                ) : activeStep == 16 ? (
                                  <EmailConfiguration />
                                ) : activeStep == 17 ? (
                                  <SubProcessInstructionFieldConfig />
                                ) : activeStep == 18 ? (
                                  <ImportSubProcessConfiguration />
                                ) : activeStep == 19 ? (
                                  <HierachyReportConfig />
                                ) : activeStep == 20 ? (
                                  <SubProcessStatusHistoryMapping />
                                )
                                  : activeStep == 21 ? (
                                    <SubProcessUserGroupConfiguration />
                                  ) : null}
                            </Typography>
                          </React.Fragment>
                        )}
                      </Box>
                      <Box
                        sx={{
                          position: "relative",
                          borderTop: "1px solid #FB4E0B",
                          backgroundColor: "#fff",
                          zIndex: 900,
                          boxShadow: "0 0 5px #acacac",
                          borderRadius: "5px",
                          height: "45px"
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: "5px"
                          }}
                        >
                          {isSkipToAdminPages && activeStep === 8 ? (
                            <Button
                              disabled={true}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                          ) : (
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                          )}

                          {activeStep === 7 ? (
                            <Button
                              //disabled={activeStep === 6}
                              onClick={publishWorkflow}
                              sx={{ mr: 1 }}
                            >
                              Publish
                            </Button>
                          ) : null}
                          {activeStep == 1 ? (
                            <Button onClick={PublishClickForm} sx={{ mr: 1 }}>
                              Save
                            </Button>
                          ) : null}
                          {/* {activeStep == 2 ? (
                            <>
                              <Button onClick={PublishClickForm} sx={{ mr: 1 }}>
                                Save & Continue
                              </Button>
                              <Button onClick={PublishClickForm} sx={{ mr: 1 }}>
                                Save
                              </Button>
                            </>
                          ) : null} */}

                          {activeStep == 2 ? (
                            <Button onClick={SubConfig} sx={{ mr: 1 }}>
                              Save
                            </Button>
                          ) : activeStep == 4 ? (
                            <Button onClick={ActivityConfig} sx={{ mr: 1 }}>
                              Save
                            </Button>
                          ) : activeStep == 3 ? (
                            <Button
                              onClick={SaveDashboardConfig}
                              sx={{ mr: 1 }}
                            >
                              Save
                            </Button>
                          ) : activeStep == 5 ? (
                            <Button onClick={EmpConfig} sx={{ mr: 1 }}>
                              Save
                            </Button>
                          ) : null}
                          {activeStep == 6 ? (
                            <>
                              <Button
                                onClick={SaveUploadExcelConfig}
                                sx={{ mr: 1 }}
                              >
                                Save
                              </Button>
                              <Button
                                onClick={ExportUploadExcelCol}
                                sx={{ mr: 1 }}
                              >
                                Export Excel Columns
                              </Button>
                            </>
                          ) : null}
                          {activeStep == 0 ? (
                            <>
                              <Button onClick={PublishClickFlow} sx={{ mr: 1 }}>
                                Publish Workflow
                              </Button>
                            </>
                          ) : null}
                          {/* {activeStep == 1 ? (
                            <>
                              <Button
                                onClick={ValidateWorkitemFile}
                                sx={{ mr: 1 }}
                              >
                                Publish Workiten Field
                              </Button>
                            </>
                          ) : null} */}
                          {activeStep == 3 ? (
                            <>
                              <Button
                                onClick={ValidateDashboardConfig}
                                sx={{ mr: 1 }}
                              >
                                Publish Dashboard Config
                              </Button>
                            </>
                          ) : null}
                          {activeStep == 4 ? (
                            <>
                              <Button
                                onClick={ValidateScreenConfig}
                                sx={{ mr: 1 }}
                              >
                                Publish Screen Config
                              </Button>
                            </>
                          ) : null}
                          <Box sx={{ flex: "1 1 auto" }} />
                          {/* {isStepOptional(activeStep) && (
                            <Button onClick={handleSkip} sx={{ mr: 1 }}>
                              Skip
                            </Button>
                          )} */}
                          {/* {IsNext ? ( */}
                          {activeStep == 0 ? (
                            <>
                              <Button
                                onClick={HandleSkipToAdminPages}
                                sx={{ mr: 1 }}
                              >
                                Skip to Admin pages
                              </Button>
                            </>
                          ) : null}
                          {activeStep >= 8 ? (
                            <>
                              <Button
                                onClick={HandleSkipToWorkflowConfig}
                                sx={{ mr: 1 }}
                              >
                                Back to Create Workflow
                              </Button>
                            </>
                          ) : null}
                          <Button
                            onClick={handleNext}
                            //disabled={activeStep === steps.length}
                            disabled={!IsNextDisable}
                          >
                            {activeStep === steps.length - 1
                              ? "Finish"
                              : "Next"}
                          </Button>

                          {/* ) : 
                          null} */}
                          {/* <Button onClick={handleNext}>
                            {activeStep === steps.length - 1
                              ? "Finish"
                              : "Next"}
                          </Button> */}
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
            {activeStep == 1 || activeStep == 2 || activeStep == 4 || activeStep == 5 || activeStep == 6 || activeStep == 7 ? null : (
              <Container>
                {activeStep == 5 ? (
                  <>
                    {/* <Link className="Floaticon-sub">
                      <span
                        onClick={handleOpen}
                        class="material-symbols-outlined submenu-icon"
                      >
                        checklist
                      </span>
                    </Link> */}
                  </>
                ) : activeStep == 4 ? (
                  <>
                    {/* <Link className="Floaticon-sub">
                      <span
                        onClick={handleOpen}
                        class="material-symbols-outlined submenu-icon"
                      >
                        checklist
                      </span>
                    </Link> */}
                  </>
                ) : activeStep == 3 ? (
                  <>
                    <Link className="Floaticon-sub">
                      <span
                        onClick={handleOpen}
                        class="material-symbols-outlined submenu-icon"
                      >
                        checklist
                      </span>
                    </Link>
                  </>
                ) : activeStep == 3 ? (
                  <>
                    <Link className="Floaticon-sub">
                      <span
                        onClick={handleOpen}
                        class="material-symbols-outlined submenu-icon"
                      >
                        checklist
                      </span>
                    </Link>
                  </>
                ) : (
                  <>
                    {/* <Link className="Floaticon-sub">
                    <span
                      onClick={handleOpen}
                      class="material-symbols-outlined submenu-icon"
                    >
                      checklist
                    </span>
                  </Link> */}
                    <Link className="Floaticon-sub">
                      <span
                        onClick={handleOpen}
                        class="material-symbols-outlined submenu-icon"
                      >
                        filter_list
                      </span>
                    </Link>
                  </>
                )}

                <ButtonFloat className="MuiFab-Custom" rotate={true}>
                  <span class="material-symbols-outlined submenu-icon">
                    page_info
                  </span>
                </ButtonFloat>
              </Container>
            )}
          </div>
        </div>

        {activeStep == 5 ? (
          <>
            <Modal
              show={preferenceModal}
              size="md"
              onHide={preferenceClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              animation
            >
              {/* <Modal.Header closeButton>
                <Modal.Title>Select Activity</Modal.Title>
              </Modal.Header> */}
              <Modal.Body>
                <div className="optionWrap">
                  <div className="row">
                    <div className="col-7">
                      <div className="pb-3">
                        <label className="form-label">
                          Sub-Process Work Activity
                        </label>
                        <select
                          className="form-control form-control-sm"
                          onChange={GetActivityForEmployeeConfig}
                        >
                          <option>Select</option>
                          {activities.map(e => (
                            <option
                              selected={
                                e.activityID == workActivityforEmployeeConfig
                              }
                              value={e.activityID}
                            >
                              {e.activityName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-outline-primary me-3"
                  onClick={preferenceClose}
                //disabled={!showModalButton}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-outline-primary me-3"
                  onClick={HandleworkActivityforEmployeeConfig}
                //disabled={!showModalButton || isSubmit}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </>
        ) : activeStep == 4 ? (
          <>
            <Modal
              show={preferenceModal}
              size="md"
              onHide={preferenceClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              animation
            >
              {/* <Modal.Header closeButton>
                <Modal.Title>Select Activity</Modal.Title>
              </Modal.Header> */}
              <Modal.Body>
                <div className="optionWrap">
                  <div className="row">
                    <div className="col-7">
                      <div className="pb-3">
                        <label className="form-label">
                          Sub-Process Work Activity
                        </label>
                        <select
                          className="form-control form-control-sm"
                          onChange={GetActivitiesForWorkactivityConfig}
                        >
                          <option>Select</option>
                          {activities.map(e => (
                            <option
                              selected={
                                e.subProcessWorkActivityConfigID ==
                                workActivityforConfig
                              }
                              value={e.subProcessWorkActivityConfigID}
                            >
                              {e.activityName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-outline-primary me-3"
                  onClick={preferenceClose}
                //disabled={!showModalButton}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-outline-primary me-3"
                  onClick={HandleworkActivityforConfig}
                //disabled={!showModalButton || isSubmit}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </>
        ) : activeStep == 3 ? (
          <>
            <Modal
              show={preferenceModal}
              size="md"
              onHide={preferenceClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              animation
            >
              {/* <Modal.Header closeButton>
                <Modal.Title>Select Activity</Modal.Title>
              </Modal.Header> */}
              <Modal.Body>
                <div className="optionWrap">
                  <div className="row">
                    <div className="col-7">
                      <div className="pb-3">
                        <label className="form-label">Activities</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={GetActivitiesForDashboardConfig}
                        >
                          <option>Select</option>
                          {activities.map(e => (
                            <option value={e.subProcessWorkActivityConfigID}>
                              {e.activityName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-outline-primary me-3"
                  onClick={preferenceClose}
                //disabled={!showModalButton}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-outline-primary me-3"
                  onClick={subprocessSubmit}
                //disabled={!showModalButton || isSubmit}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <>
            <Modal
              show={preferenceModal}
              size="lg"
              onHide={preferenceClose}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              animation
            >
              <Modal.Header closeButton>
                <Modal.Title>Select Sub-Process</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="optionWrap">
                  <div className="row">
                    <div className="col-4">
                      <div className="pb-3">
                        <label className="form-label">Company Department</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchLocationClientsByGuid}
                        >
                          <option>Select</option>
                          {departments.map(department => (
                            <option
                              value={
                                department.companyDepartment +
                                "/" +
                                department.departmentIDString
                              }
                              selected={
                                department.departmentIDString ==
                                companyDeparmentID
                              }
                            >
                              {department.companyDepartment}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="pb-3">
                        <label className="form-label">Location Client</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchProcessSubProcessByGuid}
                        >
                          <option>Select</option>
                          {ClientLocations.map(location => (
                            <option
                              value={
                                location.locationClient +
                                "/" +
                                location.clientIDString
                              }
                              selected={
                                location.clientIDString == locationClientID
                              }
                            >
                              {location.locationClient}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="pb-3">
                        <label className="form-label">
                          Process Sub-Process
                        </label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchProcessSubprocess}
                        >
                          <option>Select</option>
                          {processSubprocess.map(subprocess => (
                            <option
                              value={
                                subprocess.subProcessIDString +
                                "/" +
                                subprocess.processSubProcess
                              }
                              selected={
                                subprocess.subProcessIDString == SubprocessID
                              }
                            >
                              {subprocess.processSubProcess}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-outline-primary me-3"
                  onClick={preferenceClose}
                //disabled={!showModalButton}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-outline-primary me-3"
                  onClick={subprocessSubmit}
                //disabled={!showModalButton || isSubmit}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}
export default WorkflowWizard;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";

import Tooltip from "react-bootstrap/Tooltip";

import DashboardContext from "../../../../contexts/DashboardContext";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import { ToastProvider, useToasts } from "react-toast-notifications";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import NavDataContext from "../../../../contexts/NavDataContext";
import * as FileSaver from "file-saver";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { Paper } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { isDisabled } from "@testing-library/user-event/dist/utils";

function ManageNews() {
  const { REACT_APP_NAME } = process.env;
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;

  const { REACT_APP_GETALLNEWS } = process.env;
  const { REACT_APP_GETSUBSCRIBEDEMPLOYEES } = process.env;
  const { REACT_APP_GETEMAILIDBYEENTITIES } = process.env;
  const { REACT_APP_DELETENEWS } = process.env;
  //const newsTitleRef = useRef(null);

  let navigate = useNavigate();
  const location = useLocation();
  const processingDashboardParam = location.state;
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];


  const { addToast } = useToasts();

  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);

  const [SelectedClientLocation, setSelectedClientLocation] = useState("");
  const [isLoadingFigma, setisLoadingFigma] = useState(true);
  const [numberOfRecord, setnumberOfRecord] = useState(100);
  const [getNews, setgetNews] = useState([]);
  const [getSubscribedEmployees, setgetSubscribedEmployees] = useState([]);

  const [subprocessID, setSubprocessID] = useState("");
  const [EmailData, setEmailData] = useState([]);
  const [checkedValue, setcheckedValue] = useState("0");
  //const [prefenceValue, setprefenceValue] = useState(0);

  const getPreference = (e) => {
    // debugger;
    // console.log(e.target.value);

    setcheckedValue(e.target.value);
    sessionStorage.setItem("checkedValue", e.target.value);
    //setprefenceValue(parseInt(e.target.value));

    GetAllNews(e.target.value);
  };

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
    }
    setSelectedLocation(e.target.value);
    setSelectedClientLocation(e.target.value.split("/")[1]);

    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value.split("/")[1],
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          setprocessSubprocess([]);
        } else {
          setprocessSubprocess([]);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setClientLocations([]);
    }
    if (e.target.value !== "Select") {
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
  };

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };

  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {

        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  // added by archana
  const updateColumns = (keys) => {
    // console.log(sessionStorage.getItem("EmployeeVersionID"));
    let col = [];
    keys.forEach((item) => {
      if (item != "updatedBy") col.push({ title: item, field: item });
    });
  };

  let allNewsData = [];
  const downloadExcel = (condition) => {
    allNewsData = [];

    if (sessionStorage.getItem("checkedValue") == "0") {
      getNews.forEach((data) => {
        allNewsData.push({
          NewsTitle: data.newsTitle,
          ExpiryDate: data.formattedExpiryDate,
          Priority: data.priority,
          CreatedBy: data.createdBy1,
          CreatedOn: data.formattedCreatedOn,
          UpdatedBy: data.updatedBy1,
          UpdatedOn: data.formattedUpdatedOn,
          ApplicationNewsType: data.applicationNewsType,
  
        });
      });
    }
    else if (sessionStorage.getItem("checkedValue") == "1") {
      getNews.forEach((data) => {
        allNewsData.push({
          NewsTitle: data.newsTitle,
          ExpiryDate: data.formattedExpiryDate,
          Priority: data.priority,
          CreatedBy: data.createdBy1,
          CreatedOn: data.formattedCreatedOn,
          UpdatedBy: data.updatedBy1,
          UpdatedOn: data.formattedUpdatedOn,
          ApplicationNewsType: data.applicationNewsType,
          EntityType: data.entityType
        });
      });
    }
    else
      {
        getNews.forEach((data) => {
          allNewsData.push({
            NewsTitle: data.newsTitle,
            ExpiryDate: data.formattedExpiryDate,
            Priority: data.priority,
            CreatedBy: data.createdBy1,
            CreatedOn: data.formattedCreatedOn,
            UpdatedBy: data.updatedBy1,
            UpdatedOn: data.formattedUpdatedOn,
            ApplicationNewsType: data.applicationNewsType,
          });
        });
      }

    
    DownloadExcel(allNewsData, "getNews");
  };

  //Added by Archana
  function DownloadExcel(sheetData, sheetName) {
    const fileName = sheetName;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(sheetData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const GetAllNews = (newsType) => {

    let body = {
      NewsType: newsType,
      UserID: sessionStorage.getItem("GUID"),
    };

    let GelAllNewsURL = getAPIURL(REACT_APP_GETALLNEWS);
    let req1 = new Request(`${GelAllNewsURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req1)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setisLoadingFigma(false);

          //setgetNews(result.data);
          const formattedData = result.data.map(item => ({
            ...item,
            formattedExpiryDate: new Date(item.expiryDate).toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            }),
            formattedCreatedOn: new Date(item.createdOn).toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            }),
            formattedUpdatedOn: new Date(item.updatedOn).toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            }),
          }));

          setgetNews(formattedData);

        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const GetSubscribedEmployees = () => {
    let GetSubscribedEmployeesURL = getAPIURL(REACT_APP_GETSUBSCRIBEDEMPLOYEES);
    let req1 = new Request(`${GetSubscribedEmployeesURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },

    });
    fetch(req1)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setisLoadingFigma(false);
          updateColumns(Object.keys(result.data[0]));
          //setgetSubscribedEmployees(result.data);

          const formattedData = result.data.map(item => ({
            ...item,
            formattedSubscribedDate: new Date(item.subscribedDate).toLocaleDateString("en-US", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            })
          }));

          setgetSubscribedEmployees(formattedData);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const editNews = (item) => {
    // debugger;
    // console.log(item);
    // newsTitleRef.current.value = item.newsTitle;
    // expiryDateRef.current.value = item.expiryDate;
    // priorityRef.current.value = item.priority;
    // applicationNewsTypeRef.current.value = item.applicationNewsType;
    navigate("/AddEditNews", {
      state: {
        nav,
        assignedWorkItemDetails: navData,
        newsID: item.newsID,
        checkedNewsType: checkedValue
      },
    });
  };

  const DeleteNews = (row) => {
    // console.log(row);
    if (row.isDeleted) {
      addToast(
        "News - " + row.newsTitle + " is already deleted.",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      return false;
    }
    if (
      window.confirm(
        "Are you sure you want to delete the Selected News - " +
        row.newsTitle +
        " ?"
      )
    ) {
      let body = {
        stringNewsIDs: row.newsID,

      };
      let loginUrl = getAPIURL(REACT_APP_DELETENEWS);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 200) {
            // debugger;
            addToast("Selected News deleted successfully", {
              appearance: "success",
              autoDismiss: true,
            });
            //GetAllNews();
            if (sessionStorage.getItem("checkedValue") == "0") {
              GetAllNews(0);
              setcheckedValue("0");
            }
            else if (sessionStorage.getItem("checkedValue") == "1") {
              GetAllNews(1);
              setcheckedValue("1");
            }
            else
              GetAllNews(0);
          } else if (result.status === 401) {
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
  };

  const GetEmailID = (e) => {

    if (e != undefined) {
      let taskselected = e.target.value;
      if (e.target.value != "Select") {
        setSubprocessID(e.target.value);
        sessionStorage.setItem(
          "SubProcessIDNew",
          e.target.value
        );
      }
    }
    let body = {
      stringFilterType: "subprocess",
      longFilterValue: locationClientID,
      stringApplicationName: REACT_APP_NAME,

    };
    // console.log(body);
    let loginUrl = getAPIURL(REACT_APP_GETEMAILIDBYEENTITIES);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          let jsondata = JSON.stringify(result.data)

          let jsonFinal = jsondata.replace(/[{},]/, '');
          let jsonFinal1 = jsonFinal.replace(/[{},]/, '');
          setEmailData(jsonFinal1);

        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const Subscribecolumns = [
    {
      title: "User Name",
      field: "userName",
    },
    {
      title: "Subscribed Date",
      //field: "subscribedDate",
      field: "formattedSubscribedDate"
    },
    {
      title: "HRIS EmployeeId",
      field: "hrisEmployeeID",
    },
  ];
  const Newscolumns = [
    {
      title: "News Title",
      field: "newsTitle",
    },
    {
      title: "Expiry Date",
      //field: "expiryDate",
      field: "formattedExpiryDate",
    },
    {
      title: "Priority",
      field: "priority",
    },
    {
      title: "Created By",
      field: "createdBy1",
    },
    {
      title: "Created On",
      //field: "createdOn",
      field: "formattedCreatedOn",
    },
    {
      title: "Updated By",
      field: "updatedBy1",
    },
    {
      title: "Updated On",
      //field: "updatedOn",
      field: "formattedUpdatedOn",
    },
    {
      title: "Application Type",
      field: "applicationNewsType",
    },
    {
      title: "Entity Type",
      field: "entityType",
      hidden: checkedValue === "0",
      // Hidden: prefenceValue===1
    }

  ];

  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    setSelectedClientLocation(sessionStorage.getItem("selectedLocation"));
    FetchDepartmentbyGUID();

    if (sessionStorage.getItem("checkedValue") == "0") {
      GetAllNews(0);
      setcheckedValue("0");
    }
    else if (sessionStorage.getItem("checkedValue") == "1") {
      GetAllNews(1);
      setcheckedValue("1");
    }
    else
      GetAllNews(0);

    GetSubscribedEmployees();
    GetEmailID();
    sessionStorage.setItem("buttonText", "Save");
  }, []);


  return (
    <>
      <Header onpreferenceShow={null} pageName="Manage News" />
      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="admin" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>


      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">

              {/* <div className="form-check form-check-inline mx-0" style={{ float: 'right' }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="prefenceValue"
                  id="clientradio"
                  value="1"
                  checked={checkedValue === "1" ? true : false}
                  onChange={getPreference}
                //checked={prefenceValue === '1'}
                />
                <label
                  className="form-check-label"
                  for="inlineRadio4"
                >
                  Client/Process/Sub-Process Specific
                </label>
              </div>

              <div className="form-check form-check-inline mx-0" style={{ float: 'right' }} >
                <input
                  className="form-check-input"
                  type="radio"
                  name="prefenceValue"
                  id="GlobalRadio"
                  value="0"
                  checked={checkedValue === "0" ? true : false}
                  onChange={getPreference}
                // checked={prefenceValue === '0'}
                />
                <label
                  className="form-check-label "
                  for="inlineRadio4">
                  Global
                </label>
              </div> */}

              <div className="row align-items-end justify-content-end">
                <div className="col-1 d-inline mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="prefenceValue"
                    id="GlobalRadio"
                    value="0"
                    checked={checkedValue === "0" ? true : false}
                    onChange={getPreference}
                  // checked={prefenceValue === '0'}
                  />
                  <label
                    className="form-check-label "
                    style={{ padding: '4px' }}
                    for="inlineRadio4">
                    Global
                  </label>
                </div>

                <div className="col-3 d-inline mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="prefenceValue"
                    id="clientradio"
                    value="1"
                    checked={checkedValue === "1" ? true : false}
                    onChange={getPreference}
                  //checked={prefenceValue === '1'}
                  />
                  <label
                    className="form-check-label"
                    style={{ padding: '4px' }}
                    for="inlineRadio4"
                  >
                    Client/Process/Sub-Process Specific
                  </label>
                </div>
              </div>

              <Tabs
                defaultActiveKey="News"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="News" title="News">

                  <div className="dashBoxs" >
                    <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
                      <div className="row align-items-center">
                        <div className="col-4">
                          <h6>Total Records</h6>

                        </div>
                      </div>
                    </div>

                    <div className="tableWrap">
                      <div className="tableWrap-body">
                        <table className="table table-borderless MatTable" id="table_with_data">

                          <MaterialTable
                            localization={{
                              pagination: {
                              },
                              header: {
                                actions: "",
                              },
                            }}
                            actions={[
                              {
                                icon: () => <EditIcon />,
                                tooltip: "Edit News",
                                onClick: (event, rowData) => {
                                  editNews(rowData);
                                  sessionStorage.setItem("buttonText", "Update");
                                },
                                position: "row",
                              },
                              {
                                icon: () => <DeleteIcon />,
                                tooltip: "Delete",
                                onClick: (event, rowData) =>
                                  DeleteNews(rowData),
                                position: "row",
                              },
                            ]}
                            options={{
                              headerStyle: {
                                position: "sticky",
                                top: "-5px",
                              },
                              maxBodyHeight: "300px",

                              pageSize: numberOfRecord,
                              pageSizeOptions: [10, 20, 50, 100],
                              exportButton: false,
                              sorting: true,
                              filtering: true,
                              search: false,
                              selection: false,

                              overflowY: false,
                              showTitle: false,
                              toolbarButtonAlignment: "left",
                              headerStyle: {
                              },
                              rowStyle: {
                                fontFamily: '"Yantramanav", sans-serif',
                              },

                            }}

                            columns={Newscolumns}
                            icons={tableIcons}
                            data={getNews}
                            components={{
                              Container: (props) => (
                                <Paper {...props} elevation={0} />
                              ),
                              Toolbar: (props) => (
                                <div
                                  style={{
                                    backgroundColor: "rgb(206 206 206)",
                                    fontSize: "0.75em",
                                  }}
                                >
                                  <MTableToolbar
                                    {...props}
                                    style={{
                                      fontSize: "5px",
                                    }}
                                  />
                                </div>
                              ),
                            }}
                          />

                        </table>
                      </div>
                    </div>
                    <div class="mb-3" style={{ marginTop: '10px' }}>

                      <Link
                        to={`../AddEditNews`}
                        state={{
                          nav,
                          assignedWorkItemDetails: navData,
                          checkedNewsType: checkedValue
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ms-2"
                        >
                          <b className="icon-plus"></b>Add News
                        </button>
                      </Link>

                      <button type="submit" class="btn btn-sm btn-primary ms-3"
                        onClick={() => {
                          downloadExcel("");
                        }}>
                        Export To Excel
                      </button>

                    </div>
                  </div>
                </Tab>

                <Tab eventKey="Subscribed Users" title="Subscribed Users">

                  <div className="row align-items-end">
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="form-label"> Company Department</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchLocationClientsByGuid}
                        >
                          <option>Select</option>
                          {departments.map((department) => (
                            <option
                              value={
                                department.companyDepartment +
                                "/" +
                                department.departmentIDString
                              }
                              selected={
                                department.departmentIDString == companyDeparmentID
                              }
                            >
                              {department.companyDepartment}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="form-label">Location Client</label>
                        <select
                          className="form-control form-control-sm"
                          onChange={FetchProcessSubProcessByGuid}
                        >
                          <option>Select</option>
                          {clientLocations.map((location) => (
                            <option
                              value={
                                location.locationClient +
                                "/" +
                                location.clientIDString
                              }
                              selected={location.clientIDString == locationClientID}
                            >
                              {location.locationClient}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="mb-3">
                        <label className="form-label">Process SubProcess</label>
                        <select
                          className="form-control form-control-sm"
                        // onChange={GetEmailID}
                        >
                          <option>Select</option>
                          {processSubprocess.map((subprocess) => (
                            <option
                              value={subprocess.subProcessIDString}
                              selected={
                                subprocess.subProcessIDString == processSubProcessID
                              }
                            >
                              {subprocess.processSubProcess}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="tableWrap">
                    <div className="tableWrap-body">
                      <table className="table table-borderless MatTable">

                        <MaterialTable
                          localization={{
                            pagination: {
                            },
                            toolbar: {

                            },
                            header: {
                              actions: "",
                            },
                          }}

                          options={{
                            headerStyle: {
                              position: "sticky",
                              top: "-5px",
                            },
                            maxBodyHeight: "250px",

                            pageSize: numberOfRecord,
                            pageSizeOptions: [10, 20, 50, 100],
                            exportButton: false,
                            sorting: true,
                            filtering: true,
                            search: false,
                            selection: false,

                            overflowY: false,
                            showTitle: false,
                            toolbarButtonAlignment: "left",
                            headerStyle: {
                            },
                            rowStyle: {
                              fontFamily: '"Yantramanav", sans-serif',
                            },

                          }}

                          columns={Subscribecolumns}
                          icons={tableIcons}
                          data={getSubscribedEmployees}
                          components={{
                            Container: (props) => (
                              <Paper {...props} elevation={0} />
                            ),
                            Toolbar: (props) => (
                              <div
                                style={{
                                  backgroundColor: "rgb(206 206 206)",
                                  fontSize: "0.75em",
                                }}
                              >
                                <MTableToolbar
                                  {...props}
                                  style={{
                                    fontSize: "5px",
                                  }}
                                />
                              </div>
                            ),
                          }}
                        />
                      </table>
                    </div>
                    <span> </span>

                    <div class="mb-3" style={{ marginTop: '10px' }}>
                      <button type="submit" class="btn btn-sm btn-primary ms-3"
                        onClick={() => {
                          GetEmailID();
                        }}
                      >Fetch Email</button>
                    </div>

                    <div className="mb-2">
                      <textarea
                        name="Text1"
                        cols="60"
                        rows="5"
                        maxLength="6000"
                        value={EmailData}
                        onChange={(e) => setEmailData(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </Tab>

              </Tabs>
            </div>
          </div>

        </div>

      </div>
      <Footer />
    </>
  );
}

export default ManageNews;


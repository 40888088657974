import React, { useState, useEffect } from "react";
import "src/assets/scss/modules/_emailbox.scss";
import { ListGroup } from "react-bootstrap";
import {
  FaFlag,
  FaEnvelope,
  FaReply,
  FaPaperclip,
  FaExclamation,
  FaAngleDoubleDown,
} from "react-icons/fa";

const EmailListBox = ({ emails, handleItemClick, shortView }) => {
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [emailList, setemailList] = useState([]);
  const [clickedItem, setClickedItem] = useState(null);
  const [showAll, setShowAll] = useState(false);
  useEffect(() => {
    console.log("in email list", emails ? emails[0] : "");
    if (emails) {
      setemailList(emails);
    }

    const interval = setInterval(() => {
      const now = new Date();
      setCurrentDateTime(formatDateTime(now));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // const handleItemClick = (e, index, webLink) => {
  //   e.preventDefault();
  //   setClickedItem(index);
  //   window.open(webLink, "", "width=600, height=600");
  // };

  const trucateBody = (bodyPreview, size) => {
    if (bodyPreview.length > size) {
      return bodyPreview.substring(0, size - 3) + "...";
    } else {
      return bodyPreview;
    }
  };
  const formatDateTime = (dateString) => {
    var date = new Date(dateString);

    const currentDate = new Date();
    const delta = currentDate - date;
    const millisecondsPerDay = 1000 * 60 * 60 * 24;

    if (delta < millisecondsPerDay * 7) {
      // Within current week, format as "Mon 11:25 PM"
      return date.toLocaleString("en-US", {
        weekday: "short",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else {
      // More than a week, format as "03/18/24"
      return date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "2-digit",
      });
    }
  };
  const handleShowAll = () => {
    setShowAll(true);
  };

  const visibleEmails = showAll
    ? emailList
    : emailList.slice(0, Math.min(emailList?.length, 2));

  return (
    <>
      <ListGroup>
        {visibleEmails?.map((email, index) => {
          return (
            <>
              <ListGroup.Item
                action
                onMouseEnter={() => console.log("Hovered")}
                onClick={(e) => {
                  setClickedItem(index);
                  handleItemClick(e, index, email);
                }}
                className={`email-list-item  ${
                  index === clickedItem ? "active1" : ""
                }`}
              >
                <div
                  className={`email-content ${
                    index === clickedItem ? "active1" : ""
                  }`}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                      <div className="d-flex email-to-text">
                        <p className="me-2 mb-0">
                          <strong>To:</strong>{" "}
                          {shortView === true
                            ? trucateBody(
                                email.toRecipients
                                  .map((item) => item.emailAddress.address)
                                  .join(","),
                                50
                              )
                            : trucateBody(
                                email.toRecipients
                                  .map((item) => item.emailAddress.address)
                                  .join(","),
                                100
                              )}
                        </p>
                        <p className="mb-0">
                          <strong>From:</strong>{" "}
                          {email.from.emailAddress.address.toString()}
                        </p>
                      </div>
                      {shortView === true ? (
                        <h6
                          className="mb-0 text-secondary"
                          style={{ fontSize: "13px", fontWeight: "800" }}
                        >
                          {email.subject}
                        </h6>
                      ) : (
                        <h6
                          className="mb-0 text-secondary"
                          style={{ fontSize: "15px", fontWeight: "800" }}
                        >
                          {email.subject}
                        </h6>
                      )}
                    </div>
                    <div className="d-flex align-items-center">
                      {email.flag.flagStatus != "notFlagged" ? (
                        <FaFlag className="me-2" color="#F7DC6F" />
                      ) : (
                        <></>
                      )}
                      {email.importance != "normal" ? (
                        <FaExclamation className="me-2" color="#db3434" />
                      ) : (
                        <></>
                      )}
                      {email.hasAttachments ? (
                        <FaPaperclip className="me-2" color="#85C1E9" />
                      ) : (
                        <></>
                      )}
                      {email.subject.startsWith("RE:") ? (
                        <FaReply color="#85C1E9" />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                      <div className="d-flex">
                        <p className="mb-0 text-muted">
                          {shortView === true
                            ? trucateBody(email.bodyPreview, 50)
                            : trucateBody(email.bodyPreview, 100)}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <small className="me-3">
                        {formatDateTime(email.receivedDateTime)}
                      </small>
                    </div>
                  </div>
                </div>
              </ListGroup.Item>
            </>
          );
        })}

        {/* Add more email list items as needed */}
      </ListGroup>
      {!showAll && emailList.length > 2 && (
        <div className="text-center">
          <span
            variant="primary"
            className="text-secondary"
            role="button"
            title="Click to see more"
            onClick={handleShowAll}
          >
            <div>Show more</div>
            <div>
              {" "}
              <FaAngleDoubleDown className="ml-1 text-secondary" />
            </div>
          </span>
        </div>
      )}
    </>
  );
};

export default EmailListBox;

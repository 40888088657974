import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
function DownloadExcel (sheetData,sheetName) {
    const fileName = sheetName;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(sheetData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};

function ArrayWithoutExtraColumn (arrayData,colToRemove) {
  let sheetDataAfterManipulation = arrayData.map(item =>
    Object.keys(item).reduce((acc, key) => {
      if (!colToRemove.has(key)) acc[key] = item[key];
      return acc;
    }, {}));
    return sheetDataAfterManipulation;
};
export { DownloadExcel,ArrayWithoutExtraColumn };
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import Nav from "../../../../components/nav/Nav";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import user01 from "../../../../assets/images/user/user01.jpg";
import emptyImg from "../../../../assets/images/icons/icon_empty.svg";
import DashboardContext from "../../../../contexts/DashboardContext";
import tableIcons from "../../../../MaterialTableIcons";
import MaterialTable, { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import { ToastProvider, useToasts } from "react-toast-notifications";
import * as FileSaver from "file-saver";
import { DisplayTimeZone, UploaddateLocalTime } from "../../Comman/Timezone";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import GlobalStyles from "@mui/material/GlobalStyles";

import NavDataContext from "../../../../contexts/NavDataContext";

import {
  Checkbox,
  ListItemText,
  Input,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Container,
  ListItem,
  TextField,
} from "@material-ui/core";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  PieChart,
  Pie,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

function UnlockWorkitems() {
  const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
  const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_FETCH_PROCESSSUBPROCESS } = process.env;
  const { REACT_APP_GETACTIVITIESFORSUBPROCESS } = process.env;
  const { REACT_APP_FETCHLOCKEDWORKITEMS } = process.env;
  const { REACT_APP_UNLOCKWORKITEMS } = process.env;
  const { REACT_APP_FETCHACTIVITYDETAILSBYNAME } = process.env;
  const { REACT_APP_FETCHBREAKTIMERONLOAD } = process.env;
  const { REACT_APP_SAVETIMEONTICK } = process.env;

  let interval = null;
  const location = useLocation();
  const Roles = location.state;
  let navigate = useNavigate();
  const processingDashboardParam = location.state;
  const { addToast } = useToasts();

  const [companyDeparmentID, setcompanyDeparmentID] = useState("");
  const [companyDepartment, setcompanyDepartment] = useState("");
  const [locationClientID, setlocationClientID] = useState("");
  const [locationClient, setlocationClient] = useState("");
  const [processSubProcessID, setprocessSubProcessID] = useState("");
  const [processSubProcess, setprocessSubProcess] = useState("");
  const [selectedSubprocess, setSelectedSubprocess] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [nav, setnav] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [clientLocations, setClientLocations] = useState([]);
  const [processSubprocess, setprocessSubprocess] = useState([]);
  const [showBtn, setUserButtonShow] = useState(false);
  const [assignedWorkItemDetails, setAssignedWorkItemDetails] = useState([]);
  const [allowLocation, setAllowLocation] = useState(false);
  const [allowSubprocess, setAllowSubprocess] = useState(false);
  const [showModalButton, setShowModalButton] = useState(false);
  const [ActivitiesForSubprocess, setActivitiesForSubprocess] = useState([]);
  const [LockedWorkitemsDetails, setLockedWorkitemsDetails] = useState([]);
  const [runSaveTImer, setrunSaveTImer] = useState(false);
  const downloadExcel = () => {
    const fileName = "Unlock Workitems";
    const exportType = "xls";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(LockedWorkitemsDetails);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const columns = [
    {
      title: "WorkitemID",
      field: "workitemID",
    },
    {
      title: "LockedBy",
      field: "lockedBy",
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "LockedOn",
      field: "lockedOn",
    },
    {
      title: "UploadDate",
      field: "uploadDate",
    },
  ];

  const getAPIURL = (api) => {
    let apiURL = REACT_APP_API_URL;
    return `${apiURL}${api}`;
  };

  const GetLockedWorkitems = () => {
    let body = {
      subProcessID: sessionStorage.getItem("ProcessId"),
      personID: sessionStorage.getItem("PersonID"),
    };
    let activityUrl = getAPIURL(REACT_APP_FETCHLOCKEDWORKITEMS);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          DisplayTimeZone(result.data._listStatus);
          UploaddateLocalTime(result.data._listStatus);
          setLockedWorkitemsDetails(result.data._listStatus);
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const userRoles = processingDashboardParam.userRoles;
  let navData = [];

  if (processingDashboardParam.assignedWorkItemDetails.length > 0) {
    navData = processingDashboardParam.assignedWorkItemDetails;
  } else {
    navData = JSON.parse(sessionStorage.getItem("assignedWorkItemDetails"));
  }

  const FetchLockedWorkitemsBySubProcessID = (e) => {
    sessionStorage.setItem("ProcessId", e.target.value);
    GetLockedWorkitems();
  };

  // const FetchInvalidProcessSubprocessByGuid = (e) => {
  //   if (e.target.value == "Select") {
  //     setShowModalButton(false);
  //   } else setShowModalButton(true);
  //   setSelectedSubprocess(e.target.value.split("/")[0]);
  //   sessionStorage.setItem("ProcessId", e.target.value.split("/")[0]);
  //   sessionStorage.setItem(
  //     "SelctedSubprocessName",
  //     e.target.value.split("/")[1]
  //   );
  //   GetActivitiesForSubprocess();
  // };

  const FetchProcessSubProcessByGuid = (e) => {
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setShowModalButton(false);
    }
    setSelectedLocation(e.target.value);
    sessionStorage.setItem("selectedLocation", e.target.value.split("/")[1]);
    sessionStorage.setItem(
      "selectedLocationName",
      e.target.value.split("/")[0]
    );
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: e.target.value.split("/")[1],
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
          setprocessSubprocess([]);
        } else {
          setprocessSubprocess([]);
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  const FetchLocationClientsByGuid = (e) => {
    setcompanyDeparmentID("");
    setcompanyDepartment("");
    setlocationClientID("");
    setlocationClient("");
    setprocessSubProcessID("");
    setprocessSubProcess("");
    if (e.target.value == "Select") {
      setprocessSubprocess([]);
      setShowModalButton(false);
      setClientLocations([]);
    }
    if (e.target.value !== "Select") {
      sessionStorage.setItem(
        "selectedDepartment",
        e.target.value.split("/")[1]
      );
      sessionStorage.setItem(
        "selectedDepartmentName",
        e.target.value.split("/")[0]
      );
      setSelectedDepartment(e.target.value);
      let body = {
        guid: sessionStorage.getItem("GUID"),
        departmentId: e.target.value.split("/")[1],
      };

      let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
      let req = new Request(`${loginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          UserName: sessionStorage.getItem("UserName"),
          Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
        },
        body: JSON.stringify(body),
      });
      fetch(req)
        .then((res) => res.json())
        .then((result) => {
          if (result.status == 200) {
            setClientLocations(result.data);
          } else if (result.status === 401) {
            //unauthorized
            addToast("Session Expired/UnAuthorized. Please LogIn Again", {
              appearance: "error",
              autoDismiss: true,
            });
            sessionStorage.clear();
            sessionStorage.clear();
            navigate("/");
          }
        })
        .catch((error) => {
          //if (result.status === 401) {
          console.log("ERROR: ", error);
          //   navigate("/");
          // }
        });
    }
  };

  const FetchDepartmentbyGUID = () => {
    let body = {
      memberShipGuid: sessionStorage.getItem("GUID"),
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setDepartments(result.data);
          // if (result.data.length > 1) {
          //   setUserButtonShow(true);
          // }
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //navigate("/");
        //}
      });
  };

  const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      departmentId: departmentId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setClientLocations(result.data);
        } else if (result.status === 401) {
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        //if (result.status === 401) {
        console.log("ERROR: ", error);
        //   navigate("/");
        // }
      });
  };

  // const GetActivitiesForSubprocess = () => {
  //   let body = {
  //     subProcessID: sessionStorage.getItem("ProcessId"),
  //   };
  //   let activityUrl = getAPIURL(REACT_APP_GETACTIVITIESFORSUBPROCESS);
  //   let req = new Request(`${activityUrl}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       // UserName: sessionStorage.getItem("UserName"),
  //       // Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
  //     },
  //     body: JSON.stringify(body),
  //   });
  //   fetch(req)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.status == 200) {
  //         setActivitiesForSubprocess(result.data._listworkitemgridData);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("ERROR: ", error);
  //     });
  // };

  const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: "",
      clientId: clientId,
    };

    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          setprocessSubprocess(result.data);
          //GetActivitiesForSubprocess();
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };
  const typemapping = [
    {
      WorkitemID: "",
      LockedBy: "",
      Name: "",
      LockedOn: "",
      UploadDate: "",
    },
  ];

  const FetchDefaultValueForSelectedSubprocess = (
    companyDeparmentID,
    locationClientID,
    processSubProcessID
  ) => {
    setcompanyDeparmentID(companyDeparmentID);
    setSelectedDepartment(companyDeparmentID);
    sessionStorage.setItem("selectedDepartment", companyDeparmentID);
    FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
    setlocationClientID(locationClientID);
    setSelectedLocation(locationClientID);
    sessionStorage.setItem("selectedLocation", locationClientID);
    FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
    setprocessSubProcessID(processSubProcessID);
    FetchSubprocessName(processSubProcessID, locationClientID);
  };

  const UnlockMyWorkitems = (e) => {
    // sessionStorage.setItem("WorkitemID", e.target.value);
    // let body = {
    //   WorkitemID: sessionStorage.getItem("WorkitemID"),
    // };
    let activityUrl = getAPIURL(REACT_APP_UNLOCKWORKITEMS);
    let req = new Request(`${activityUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      //body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          // debugger;
          //setLockedWorkitemsDetails(result.data);
          GetLockedWorkitems();
          if(result.data=="")
          {
            addToast("Locked Workitems Not Exists!", {
              appearance: "error",
              autoDismiss: true,
            });
          }
          else
          {
            addToast("Workitems Unlocked Successfully!", {
              appearance: "success",
              autoDismiss: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const FetchSubprocessName = (subprocessId, clientId) => {
    let body = {
      guid: sessionStorage.getItem("GUID"),
      subProcessId: subprocessId,
      clientId: clientId,
    };
    let loginUrl = getAPIURL(REACT_APP_FETCH_PROCESSSUBPROCESS);
    let req = new Request(`${loginUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        UserName: sessionStorage.getItem("UserName"),
        Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
      },
      body: JSON.stringify(body),
    });
    fetch(req)
      .then((res) => res.json())
      .then((result) => {
        if (result.status == 200) {
          sessionStorage.setItem(
            "SelctedSubprocessName",
            result.data[0].processSubProcess
          );
          //SubProcessSubmitForTimer();
        } else if (result.status === 401) {
          //unauthorized
          addToast("Session Expired/UnAuthorized. Please LogIn Again", {
            appearance: "error",
            autoDismiss: true,
          });
          sessionStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  useEffect(() => {
    FetchDefaultValueForSelectedSubprocess(
      sessionStorage.getItem("selectedDepartment"),
      sessionStorage.getItem("selectedLocation"),
      sessionStorage.getItem("ProcessId")
    );
    FetchDepartmentbyGUID();

    GetLockedWorkitems();
  }, [sessionStorage.getItem("navMenu")]);

  return (
    <>
      <Header
        onpreferenceShow={null}
        pageName="Unlock Work Items"
        timeCaptureActivityName="Work Item Management"
      />

      <ApplicationUserContext.Provider
        value={processingDashboardParam.userRoles}
      >
        <DashboardContext.Provider value={processingDashboardParam.nav}>
          <NavDataContext.Provider value={navData}>
            <Nav selectedMenu="activities" />
          </NavDataContext.Provider>
        </DashboardContext.Provider>
      </ApplicationUserContext.Provider>
      <div className="centerSection">
        <div className="pageBody">
          <div className="dashBoxs mb-3">
            <div className="formWrap">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label"> Company Department</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchLocationClientsByGuid}
                    >
                      <option>Select</option>
                      {departments.map((department) => (
                        <option
                          value={
                            department.companyDepartment +
                            "/" +
                            department.departmentIDString
                          }
                          selected={
                            department.departmentIDString == companyDeparmentID
                          }
                        >
                          {department.companyDepartment}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Location Client</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchProcessSubProcessByGuid}
                    >
                      <option>Select</option>
                      {clientLocations.map((location) => (
                        <option
                          value={
                            location.locationClient +
                            "/" +
                            location.clientIDString
                          }
                          selected={location.clientIDString == locationClientID}
                        >
                          {location.locationClient}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-3">
                    <label className="form-label">Process SubProcess</label>
                    <select
                      className="form-control form-control-sm"
                      onChange={FetchLockedWorkitemsBySubProcessID}
                    >
                      <option>Select</option>
                      {processSubprocess.map((subprocess) => (
                        <option
                          value={subprocess.subProcessIDString}
                          selected={
                            subprocess.subProcessIDString == processSubProcessID
                          }
                        >
                          {subprocess.processSubProcess}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashBoxs mt-2">
            <div className="dashBoxs-header " style={{ maxHeight: "45px" }}>
              <div className="row align-items-center">
                <div className="col-4">
                  <h6>Unlock Workitems</h6>
                  {/* //<h5>Processing Dashboard</h5> */}
                </div>
                <div className="col-8">
                  <div
                    className="d-grid d-sm-flex justify-content-md-end align-items-center gap-2"
                    style={{ marginTop: "-10px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-2"
                      onClick={() => {
                        downloadExcel();
                      }}
                    >
                      <b></b>Export
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary ms-2"
                      onClick={() => {
                        UnlockMyWorkitems();
                      }}
                    >
                      <b></b>Unlock My Workitems
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="tableWrap">
              <div className="tableWrap-body">
                <table
                  className="table table-borderless MatTable"
                  style={{ zIndex: "1", position: "relative" }}
                >
                  <MaterialTable
                    localization={
                      {
                        // toolbar: {
                        //   exportCSVName: "Export as Excel",
                        //   exportPDFName: "Export as pdf",
                        // },
                      }
                    }
                    title="Unlock Workitems"
                    options={{
                      search: false,
                      overflowY: false,
                      showTitle: false,
                      filtering: true,
                      cellStyle: {
                        // width: 100,
                        // minWidth: 100,
                      },
                      headerStyle: {
                        position: "sticky",
                        // top: "-5px",
                      },
                      rowStyle: {
                        fontFamily: '"Yantramanav", sans-serif',
                      },
                    }}
                    components={{
                      Container: (props) => <Paper {...props} elevation={0} />,
                      Toolbar: (props) => (
                        <div
                          style={{
                            backgroundColor: "rgb(206 206 206)",
                            fontSize: "0.75em",
                          }}
                        >
                          <MTableToolbar
                            {...props}
                            style={{ fontSize: "5px" }}
                          />
                        </div>
                      ),
                    }}
                    columns={columns}
                    icons={tableIcons}
                    data={LockedWorkitemsDetails}
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 text-end d-flex">
           
            <button
              type="button"
              className="btn btn-primary ms-3"
              onClick={UnlockMyWorkitems}
            >
              Unlock My Workitems
            </button>
          </div>
        </div> */}
      </div>
      <Footer />
    </>
  );
}

export default UnlockWorkitems;

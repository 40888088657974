import GetBrowserOnlineStatus from "./AppGlobalSlice";
//import SetEmpUtilizations from "./EmployeeUtlizationSlice";
import { configureStore } from "@reduxjs/toolkit";
import addWorkItemReducer from "./addWorkItemSlice";
import selectedEmployeeDataReducer from "./selectedEmployeeIDSlice";
import addOriginalElementReducer from "./addOriginalElementSlice";
import GetReportidsReducer from "./reportsSlice";
import  GetUpdatedTimeCapturing  from "./updateTimeCapturingSlice";

export const store = configureStore({
  reducer: {
    advWorkItemDetails: addWorkItemReducer,
    selectedEmployeeData: selectedEmployeeDataReducer,
    reportids: GetReportidsReducer,
    BrowserOnlineStatus: GetBrowserOnlineStatus,
    selectedFlowIds:addOriginalElementReducer,
    UpdatedTimeCapturing: GetUpdatedTimeCapturing
    // emputilizations: SetEmpUtilizations,
    
  }
});

import React, { useRef, useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import NavDataContext from "../../../../contexts/NavDataContext";
import Nav from "../../../../components/nav/Nav";
import ApplicationUserContext from "../../../../contexts/ApplicationUserContext";
import DashboardContext from "../../../../contexts/DashboardContext";
import Header from "../../../../components/header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../LoadingSpinner";
import { useToasts } from "react-toast-notifications";
import tableIcons from "src/MaterialTableIcons";
import LoadingSpinnerFigma from "src/LoadingSpinnerFigma";
import MaterialTable from "material-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { MTableToolbar } from "material-table";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Modal from "react-bootstrap/Modal";

function VendorPortalConfig() {
    const location = useLocation();
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const processingDashboardParam = location.state;
    const { REACT_APP_API_URL } = process.env;
    const { REACT_APP_FETCH_DEPARTMENTBYGUID } = process.env;
    const { REACT_APP_FETCH_LOCATIONCLIENTSBYGUID } = process.env;
    const { React_APP_FetchAllProcessSubProcessByGuid } = process.env;
    const { React_App_VendorPortalConfigList } = process.env;
    const { React_App_GetVendorPortalUserAccessDetails } = process.env;
    const { React_App_GetWorkItemFieldsForVendor } = process.env;
    const { React_App_GetMapVendorValuesBySubprocess } = process.env;
    const { React_App_GetVendorPortalValuesByEmployee } = process.env;
    const { React_App_DeleteVendorWorkitemFieldValueDetails } = process.env;
    const { React_App_GetVendorPortalDetailsByID } = process.env;
    const { React_App_GetMapVendorPortalInitiateQueryConfig } = process.env;
    const { React_App_GetVendorPortalPaidStatusFields } = process.env;
    const { React_App_SaveVendorPortalPaidStatusFields } = process.env;
    const { React_App_GetVendorPortalConfigFieldsBySubprocessID } = process.env;
    const { React_App_SaveVendorPortalInitiateQueryFields } = process.env;
    const { React_App_SaveOrUpdateVendorPortalDetails } = process.env;
    const { React_App_SaveVendorWorkitemFieldValueDetails } = process.env;

    const [companyDeparmentID, setcompanyDeparmentID] = useState("");
    const [locationClientID, setlocationClientID] = useState("");
    const [processSubProcessID, setprocessSubProcessID] = useState("");
    const [departments, setDepartments] = useState([]);
    const [clientLocations, setClientLocations] = useState([]);
    const [processSubprocess, setprocessSubprocess] = useState([]);
    const filterValue = useRef("")
    const navData = processingDashboardParam.nav;

    const [isTableLoading, setTableLoading] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [workItemFieldList, setWorkItemFieldList] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [selectedDropDown, setSelectedDropDown] = useState("");
    const [isModalTableLoading, setisModalTableLoading] = useState(false);
    const [vendorField, setVendorField] = useState("");
    const [isEdit, setisEdit] = useState(false);
    const [selectedVendorField, setselectedVendorField] = useState("");
    const [vendorPortalDropdownlist, setVendorDropdownList] = useState([]);
    const [vendorPortalValues, setVendorPortalValues] = useState([]);
    const [vendorAccessValue, setVendorAccessValue] = useState("");
    const [assignedToID, setassignedToID] = useState("");
    const [workitemFieldID, setWorkitemFieldID] = useState("");
    const [vendorPortalID, setVendorPortalID] = useState("0");
    const [fieldValueforPaidCriteria, setfieldValueforPaidCriteria] = useState([]);
    const [paidStatusModal, setpaidStatusModal] = useState(false);
    const [vendorQueryModal, setvendorQueryModal] = useState(false);
    const [isPaidTableLoading, setisPaidTableLoading] = useState(false);
    const [isAccessTableLoading, setisAccessTableLoading] = useState(false);
    const [vendorQueryList, setvendorQueryList] = useState([]);
    const [ddlvalues, setddlvalues] = useState([]);
    const [initiateVendorQueryID, setinitiateVendorQueryID] = useState("");
    const [isQueryTableLoading, setisQueryTableLoading] = useState(false);
    let selectedpaidStatusFields = "";
    let assignmentXMLRequest = [];

    const columns = [
        { title: "Client Name", field: "clientName" },
        { title: "Process Name", field: "processName" },
        { title: "SubProcess Name", field: "subProcessName" },
        { title: "Vendor Field", field: "vendorMapField" },
        { title: "Vendor Query Sub-Process", field: "initiateVendorQuery" },
    ]

    const Modalcolumns = [
        { title: "User Name", field: "assignTo" },
        { title: "Vendor Access List", field: "labelText" }
    ]

    const EditModalcolumns = [
        { title: "Value", field: "value" }
    ]

    const PaidModalcolumns = [
        {
            title: "Select",
            field: "isselected",
            editComponent: (props) => {
                return (
                    <input
                        type="checkbox"
                        checked={props.labelText}
                        onChange={(e) => {
                            props.onChange(e.target.checked);
                            handleCheckboxChange(props.rowData.labelText);
                        }}
                    />
                );
            },
            render: (rowData) => (
                <>
                    <input
                        type="checkbox"
                        id="chkisselected"
                        checked={rowData["isselected"]}
                        onChange={() => handleCheckboxChange(rowData.labelText)}
                    />
                </>
            ),
            cellStyle: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
        },
        {
            title: "Fields",
            field: "labelText",
        }
    ];

    const vendorQueryColumns = [
        {
            title: "Select",
            field: "isselected",
            editComponent: (props) => {
                return (
                    <input
                        type="checkbox"
                        checked={props.vendorWorkitemFieldID}
                        onChange={(e) => {
                            props.onChange(e.target.checked);
                            handleQueryCheckboxChange(props.rowData.vendorWorkitemFieldID);
                        }}
                    />
                );
            },
            render: (rowData) => (
                <>
                    <input
                        type="checkbox"
                        id="chkisselected"
                        checked={rowData["isselected"]}
                        onChange={() => handleQueryCheckboxChange(rowData.vendorWorkitemFieldID)}
                    />
                </>
            ),
            cellStyle: {
                whiteSpace: 'nowrap',
                width: '10%',
            },
        },
        { title: "Vendor Query Fields", field: "vendorWorkItemField" },
        {
            title: "Parent Sub-Process Fields",
            field: "",
            render: rowData => (
                <>
                    <select
                        className="form-control form-control-sm"
                        onChange={e => handleDropdownchange(e, rowData)}
                    >
                        <option value="Select">-- Select --</option>
                        {ddlvalues.map((item) => (
                            <option 
                                value={item.workItemFieldID}
                                selected={item.workItemFieldID == rowData["vendorMapWorkitemFieldID"]}
                            >
                                {item.labelText}
                            </option>
                        ))}
                    </select>
                </>
            )
        }
    ];

    const handleQueryCheckboxChange = (itemId) => {
        setvendorQueryList(prevList =>
            prevList.map(item => {
                if (item.vendorWorkitemFieldID === itemId) {
                    return { ...item, isselected: !item.isselected };
                }
                return item;
            })
        );
    };

    const handleDropdownchange = (e, rowData) => {
        const newDataType = e.target.value.split("~")[1];
        const oldDataType = rowData.vendorWorkItemFieldDataType;
        if (newDataType === oldDataType || e.target.value == "Select") {
            if (e.target.value != "Select") {
                const temp = [...vendorQueryList];
                temp[rowData.tableData.id]["vendorMapWorkitemFieldID"] = e.target.value;
                setvendorQueryList(temp);
            } else {
                const temp = [...vendorQueryList];
                temp[rowData.tableData.id]["vendorMapWorkitemFieldID"] = "";
                setvendorQueryList(temp);
            }
        } else {
            addToast("Please Select the appropriate field for the mentioned Vendor Field.", {
                appearance: "error",
                autoDismiss: true,
            });
            window.confirm("Please Select the appropriate field for the mentioned Vendor Field- "+rowData.vendorWorkItemField + ".")
        }
    };

    const handleCheckboxChange = (value) => {
        setfieldValueforPaidCriteria(prevList =>
            prevList.map(item => {
                if (item.labelText == value) {
                    return { ...item, isselected: !item.isselected };
                }
                return item;
            })
        );
    };

    const handleMarkAll = () => {
        setfieldValueforPaidCriteria(prevList =>
            prevList.map(item => {
                return { ...item, isselected: 1 };
            })
        );
    }

    const handleUnmarkAll = () => {
        setfieldValueforPaidCriteria(prevList =>
            prevList.map(item => {
                return { ...item, isselected: 0 };
            })
        );
    }

    const getAPIURL = api => {
        let apiURL = REACT_APP_API_URL;
        return `${apiURL}${api}`;
    };

    useEffect(() => {
        getVendorPortalList("all");
    }, []);

    const DisplayCriteriaOnChange = (e) => {
        setTableLoading(true);
        getVendorPortalList(e.target.value);
        setSelectedDropDown(e.target.value);
    }

    const getVendorPortalList = (filterKey) => {
        let APIURL = getAPIURL(React_App_VendorPortalConfigList);
        if (filterKey === 'subprocess') {
            let input = processingDashboardParam.selectedSubprocess;
            filterValue.current = input;
        } else if (filterKey === 'process') {
            let longSubProcessId = Number(processingDashboardParam.selectedSubprocess)
            let input = (Math.floor(longSubProcessId / 10000) * 10000)
            let stringInput = input.toString();
            filterValue.current = stringInput;
        } else if (filterKey === 'client') {
            let input = sessionStorage.getItem("selectedLocation");
            filterValue.current = input;
        } else if (filterKey === 'all') {
            filterValue.current = '0';
        }
        let inputbody = {
            userID: sessionStorage.getItem("GUID"),
            stringFilterKey: filterKey,
            longFilterValue: filterValue.current
        };
        let req = new Request(`${APIURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(inputbody),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setTableLoading(false);
                    setVendorList(result.data);
                } else if (result.status === 404) {
                    setVendorList([]);
                    setTableLoading(false);
                    addToast("No data available for selected display criterion", {
                        appearance: "error",
                        autoDismiss: true
                    });
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true
                    });
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const EditVendorPortalConfig = (rowData) => {
        setisEdit(true);
        setViewModal(true);
        FetchDepartmentbyGUID();
        let selectedLocation = rowData.subProcessID.slice(0, -8) + '00000000';
        let selectedDepartment = rowData.subProcessID.slice(0, -14) + '00000000000000';
        sessionStorage.setItem("selectedProcessId", rowData.subProcessID);
        setselectedVendorField(rowData.vendorMapField);
        FetchDefaultValueForSelectedSubprocess(
            selectedDepartment,
            selectedLocation,
            rowData.subProcessID,
        );
        fetchVendorPortalDetailsByIDforEdit(rowData.vendorPortalID);
        setVendorPortalID(rowData.vendorPortalID);
    }

    const fetchVendorPortalDetailsByIDforEdit = (e) => {
        let body = {
            vendorPortalID: e,
            subProcessID: "0"
        };

        let loginUrl = getAPIURL(React_App_GetVendorPortalDetailsByID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setWorkitemFieldID(result.data[0].workItemFieldID);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("An Unexpected Error Occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const fetchVendorPortalDetailsByIDforAdd = (e) => {
        let body = {
            vendorPortalID: "0",
            subProcessID: e
        };

        let loginUrl = getAPIURL(React_App_GetVendorPortalDetailsByID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setWorkitemFieldID(result.data[0].workItemFieldID);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("An Unexpected Error Occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const AddNewVendorPortal = () => {
        setViewModal(true);
        FetchDepartmentbyGUID();
    }

    const downloadExcel = () => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const filteredColumn = vendorList.map(item => ({
            "Client Name": item.clientName,
            "Process Name": item.processName,
            "SubProcess Name": item.subProcessName,
            "Vendor Field": item.vendorMapField,
            "Vendor Query Sub-Process": item.initiateVendorQuery
        }));
        const fileName = "ListofSubProcessVendorPortalDetails";
        const ws = XLSX.utils.json_to_sheet(filteredColumn);
        const wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const FetchDefaultValueForSelectedSubprocess = (
        companyDeparmentID,
        locationClientID,
        processSubProcessID
    ) => {
        setcompanyDeparmentID(companyDeparmentID);
        FetchLocationClientsByGuidOnPageLoad(companyDeparmentID);
        setlocationClientID(locationClientID);
        FetchProcessSubProcessByGuidOnPageLoad(locationClientID);
        setprocessSubProcessID(processSubProcessID);
    };

    const FetchLocationClientsByGuidOnPageLoad = (departmentId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            departmentId: departmentId,
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setClientLocations(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("Error while loading Location~Client.", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    const FetchProcessSubProcessByGuidOnPageLoad = (clientId) => {
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: clientId,
        };

        let loginUrl = getAPIURL(React_APP_FetchAllProcessSubProcessByGuid);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setprocessSubprocess(result.data);
                    fetchUserAccessList(sessionStorage.getItem("selectedProcessId"));
                    fetchWorkitemFieldsforVendor(sessionStorage.getItem("selectedProcessId"));
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("Error while loading Process~SubProcess.", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    const FetchDepartmentbyGUID = () => {
        let body = {
            memberShipGuid: sessionStorage.getItem("GUID"),
        };

        let loginUrl = getAPIURL(REACT_APP_FETCH_DEPARTMENTBYGUID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setDepartments(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    const FetchLocationClientsByGuid = (e) => {
        setprocessSubprocess([]);
        setcompanyDeparmentID(e.target.value.split("/")[1]);
        setlocationClientID("");
        setprocessSubProcessID("");
        if (e.target.value === "Select") {
            setClientLocations([]);
        }
        if (e.target.value !== "Select") {
            let body = {
                guid: sessionStorage.getItem("GUID"),
                departmentId: e.target.value.split("/")[1],
            };

            let loginUrl = getAPIURL(REACT_APP_FETCH_LOCATIONCLIENTSBYGUID);
            let req = new Request(`${loginUrl}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    UserName: sessionStorage.getItem("UserName"),
                    Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                },
                body: JSON.stringify(body),
            });
            fetch(req)
                .then((res) => res.json())
                .then((result) => {
                    if (result.status === 200) {
                        setClientLocations(result.data);
                    } else if (result.status === 401) {
                        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                        sessionStorage.clear();
                        navigate("/");
                    }
                })
                .catch((error) => {
                    console.log("ERROR: ", error);
                });
        }
    };

    const FetchProcessSubProcessByGuid = (e) => {
        setlocationClientID(e.target.value.split("/")[1]);
        setprocessSubProcessID("");
        if (e.target.value === "Select") {
            setprocessSubprocess([]);
        }
        let body = {
            guid: sessionStorage.getItem("GUID"),
            subProcessId: "",
            clientId: e.target.value.split("/")[1],
        };

        let loginUrl = getAPIURL(React_APP_FetchAllProcessSubProcessByGuid);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setprocessSubprocess(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    setprocessSubprocess([]);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    };

    const handleSubprocesschange = (e) => {
        setVendorField("");
        setprocessSubProcessID(e.target.value);
        fetchUserAccessList(e.target.value);
        fetchWorkitemFieldsforVendor(e.target.value);
        fetchVendorPortalDetailsByIDforAdd(e.target.value);
    }

    const fetchUserAccessList = (e) => {
        setisModalTableLoading(true);
        let body = {
            subProcessID: e,
            applicationName: "ProductionDashboard"
        };

        let loginUrl = getAPIURL(React_App_GetVendorPortalUserAccessDetails);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setisEdit(true);
                    setUserList(result.data);
                    setisModalTableLoading(false);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("No user data available for selected criterion", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    setisModalTableLoading(false);
                    setUserList([]);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const fetchWorkitemFieldsforVendor = (e) => {
        let body = {
            subProcessID: e
        };
        let loginUrl = getAPIURL(React_App_GetWorkItemFieldsForVendor);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setWorkItemFieldList(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("No workitem fields available for selected criterion", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    setWorkItemFieldList([]);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const handleVendorFieldChange = (e) => {
        setVendorField(e.target.value);
    }

    const EditVendorPortalDetails = (rowData) => {
        setEditModal(true);
        fetchMapVendorValuesbySubprocessID();
        setassignedToID(rowData.assignedToID);
        fetchVendorPortalValuesbyEmployee(rowData.assignedToID);
    }

    const fetchMapVendorValuesbySubprocessID = () => {
        let body = {
            mapFieldID: workitemFieldID,
            subProcessID: processSubProcessID
        };
        let loginUrl = getAPIURL(React_App_GetMapVendorValuesBySubprocess);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setVendorDropdownList(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("No vendor data available for selected criterion", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    setVendorDropdownList([]);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const fetchVendorPortalValuesbyEmployee = (rowData) => {
        setisAccessTableLoading(true);
        let body = {
            personID: rowData,
            subProcessID: processSubProcessID
        };
        let loginUrl = getAPIURL(React_App_GetVendorPortalValuesByEmployee);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setisAccessTableLoading(false);
                    setVendorPortalValues(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("No data available for selected criterion", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    setisAccessTableLoading(false);
                    setVendorPortalValues([]);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const EditModalClose = () => {
        setEditModal(false);
        fetchUserAccessList(processSubProcessID);
    }

    const handleVendorAccessDropdownChange = (e) => {
        setVendorAccessValue(e.target.value);
    }

    const AddVendor = () => {
        let body = {
            personID: assignedToID,
            subProcessID: processSubProcessID,
            selectedVendorValues: vendorAccessValue,
            personUserID: sessionStorage.getItem("PersonID")
        };
        let loginUrl = getAPIURL(React_App_SaveVendorWorkitemFieldValueDetails);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    addToast("Vendor values added Successfully", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    fetchVendorPortalValuesbyEmployee(assignedToID);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("An unexpected error occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const DeleteVendor = (rowData) => {
        if(window.confirm("Are you sure you want to remove the vendor: "+ rowData.value+ "?")){
        let body = {
            selectedVendorPortalEmployeeID: rowData.vendorPortalEmployeeID
        };
        let loginUrl = getAPIURL(React_App_DeleteVendorWorkitemFieldValueDetails);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    addToast("Selected values deleted Successfully", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    fetchVendorPortalValuesbyEmployee(assignedToID);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("An unexpected error occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
        }
    }

    const handleSave = () => {
        let body = {
            subProcessID: processSubProcessID,
            workItemFieldID: vendorField,
            vendorPortalID: vendorPortalID,
            personID: sessionStorage.getItem("PersonID")
        };
        let loginUrl = getAPIURL(React_App_SaveOrUpdateVendorPortalDetails);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    addToast("Vendor Portal Details Saved Successfully", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    viewModalClose();
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    viewModalClose();
                    addToast("An unexpected error occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const fetchPaidStatusFields = () => {
        setisPaidTableLoading(true);
        let body = {
            subProcessID: processSubProcessID
        };
        let loginUrl = getAPIURL(React_App_GetVendorPortalPaidStatusFields);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setisPaidTableLoading(false);
                    setfieldValueforPaidCriteria(result.data);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("An unexpected error occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    setisPaidTableLoading(false);
                    setfieldValueforPaidCriteria([]);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const handlePaidStatusSave = () => {
        fieldValueforPaidCriteria.forEach(
            (element) => {
                if (element.isselected) {
                    selectedpaidStatusFields += element.workitemfieldID;
                    selectedpaidStatusFields += ",";
                }
            }
        );
        if (selectedpaidStatusFields.length > 0) {
            let selectedValues = selectedpaidStatusFields.slice(0,-1);
            let body = {
                selectedWorkitemFields: selectedValues,
                subProcessID: processSubProcessID
            };
            let loginUrl = getAPIURL(React_App_SaveVendorPortalPaidStatusFields);
            let req = new Request(`${loginUrl}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    UserName: sessionStorage.getItem("UserName"),
                    Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
                },
                body: JSON.stringify(body),
            });
            fetch(req)
                .then((res) => res.json())
                .then((result) => {
                    if (result.status === 200) {
                        addToast("Paid Field values saved successfully", {
                            appearance: "success",
                            autoDismiss: true,
                        });
                        paidModalClose();
                    } else if (result.status === 401) {
                        addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                        sessionStorage.clear();
                        navigate("/");
                    } else {
                        addToast("An unexpected error occured", {
                            appearance: "error",
                            autoDismiss: true,
                        });
                        paidModalClose();
                    }
                })
                .catch((error) => {
                    console.log("ERROR: ", error);
                });
        }
        else {
            window.confirm(
                "Please select atleast one field."
            );
        }
    }

    const fetchMapVendorPortalInitiateQueryConfig = () => {
        let body = {
            subProcessID: processSubProcessID
        };
        let loginUrl = getAPIURL(React_App_GetMapVendorPortalInitiateQueryConfig);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setddlvalues(result.data);
                    setinitiateVendorQueryID(result.message.split('~')[0]);
                    if (result.message.split('~')[0] != null && result.message.split('~')[0] != '0') {
                        fetchVendorPortalConfigFieldsBySubprocessID(result.message.split('~')[0]);
                    }
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("An unexpected error occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    setddlvalues([]);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const fetchVendorPortalConfigFieldsBySubprocessID = (e) => {
        setisQueryTableLoading(true);
        let body = {
            subProcessID: processSubProcessID,
            initiateVendorPortalSID: e,
            vendorPortalID: vendorPortalID
        };
        let loginUrl = getAPIURL(React_App_GetVendorPortalConfigFieldsBySubprocessID);
        let req = new Request(`${loginUrl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(body),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    setvendorQueryList(result.data);
                    setisQueryTableLoading(false);
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    sessionStorage.clear();
                    navigate("/");
                } else {
                    addToast("An unexpected error occured", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                    setvendorQueryList([]);
                    setisQueryTableLoading(false);
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const handleVendorQuerySave = () => {
        vendorQueryList.forEach(
            (element) => {
                if (element.isselected) {
                    const mappedinput = element.vendorMapWorkitemFieldID.split("~")[0];
                    const input = {
                        SelectedVendorFieldsID:element.vendorWorkitemFieldID,
                        SelectedMapVendorFieldsID:mappedinput
                    };
                    assignmentXMLRequest.push(input);
                }
            }
        );
        
        if (window.confirm("Record already exixts for Parent Sub-Process. Do you want to overwrite?")) {
            if (assignmentXMLRequest.length == 0) {
                window.confirm("Please select Vendor Field(s).")
            } else {
                SaveVendorQueryConfig(assignmentXMLRequest);
            }
        }
    }
    const SaveVendorQueryConfig = (e) => {
        let APIURL = getAPIURL(React_App_SaveVendorPortalInitiateQueryFields);
        let inputbody = {
            subProcessID: processSubProcessID,
            initiateVendorQuerySubprocessID:initiateVendorQueryID,
            selectedVendorFieldsIDs:assignmentXMLRequest,
            personID:sessionStorage.getItem("PersonID")
        }
        let req = new Request(`${APIURL}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                UserName: sessionStorage.getItem("UserName"),
                Authorization: "Bearer " + sessionStorage.getItem("authorizationToken"),
            },
            body: JSON.stringify(inputbody),
        });
        fetch(req)
            .then((res) => res.json())
            .then((result) => {
                if (result.status === 200) {
                    addToast("Vendor Query Configuration Saved Successfully.", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    vendorQueryModalClose();
                } else if (result.status === 401) {
                    addToast("Session Expired/UnAuthorized. Please LogIn Again", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                } else {
                    addToast("Error in saving configuration.", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            })
            .catch((error) => {
                console.log("ERROR: ", error);
            });
    }

    const viewModalClose = () => {
        setViewModal(false);
        getVendorPortalList(selectedDropDown);
        setUserList([]);
        setisEdit(false);
        setClientLocations([]);
        setprocessSubprocess([]);
        setWorkItemFieldList([]);
        setcompanyDeparmentID("");
        setlocationClientID("");
        setprocessSubProcessID("");
    }

    const paidStatusCriteria = () => {
        setpaidStatusModal(true);
        fetchPaidStatusFields();
    }

    const paidModalClose = () => {
        setpaidStatusModal(false);
        setfieldValueforPaidCriteria([]);
    }

    const vendorQueryConfiguration = () => {
        setvendorQueryModal(true);
        fetchMapVendorPortalInitiateQueryConfig();
    }

    const vendorQueryModalClose = () => {
        setvendorQueryModal(false);
        setddlvalues([]);
        setvendorQueryList([]);
    }

    const handleVendorQuerySubprocessChange = (e) => {
        fetchVendorPortalConfigFieldsBySubprocessID(e.target.value);
        setinitiateVendorQueryID(e.target.value);
    }

    return (
        <><Header
            onpreferenceShow={null}
            pageName="Sub-Process Vendor Portal Config"
            timeCaptureActivityName="VendorPortalConfig"
        />
            <ApplicationUserContext.Provider
                value={processingDashboardParam.userRoles}
            >
                <DashboardContext.Provider value={processingDashboardParam.nav}>
                    <NavDataContext.Provider value={navData}>
                        <Nav selectedMenu="configuration" />
                    </NavDataContext.Provider>
                </DashboardContext.Provider>
            </ApplicationUserContext.Provider>
            <>
                <Modal
                    show={viewModal}
                    dialogClassName="modal-100w"
                    onHide={viewModalClose}
                    fullscreen={"lg-down"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Sub-Process Vendor Portal Configuration</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="pageBody">
                            <div className="dashBoxs mb-3">
                                <div className="formWrap">
                                    {isEdit ?
                                        <div className="row align-items-end">
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label className="form-label"> Company Department</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        title="Select a Company-Department"
                                                        disabled
                                                        required
                                                    >
                                                        <option>Select</option>
                                                        {departments.map((department) => (
                                                            <option
                                                                value={
                                                                    department.companyDepartment +
                                                                    "/" +
                                                                    department.departmentIDString
                                                                }
                                                                selected={department.departmentIDString == companyDeparmentID}
                                                            >
                                                                {department.companyDepartment}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label className="form-label">Location Client</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        title="Select a Location-Client"
                                                        disabled
                                                        required
                                                    >
                                                        <option selected>Select</option>
                                                        {clientLocations.map((location) => (
                                                            <option
                                                                value={
                                                                    location.locationClient +
                                                                    "/" +
                                                                    location.clientIDString
                                                                }
                                                                selected={location.clientIDString == locationClientID}
                                                            >
                                                                {location.locationClient}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label className="form-label">Process SubProcess</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        title="Select a Process-SubProcess"
                                                        required
                                                        disabled
                                                    >
                                                        <option selected>Select</option>
                                                        {processSubprocess.map((subprocess) => (
                                                            <option
                                                                value={subprocess.subProcessIDString}
                                                                selected={subprocess.subProcessIDString == processSubProcessID}
                                                            >
                                                                {subprocess.processSubProcess}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label className="form-label">Select Vendor Field</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        onChange={handleVendorFieldChange}
                                                        title="Select a Vendor Field which identifies the vendor. Example: VendorName, VendorCode, VendorID, etc."
                                                        required
                                                    >
                                                        <option>Select</option>
                                                        {workItemFieldList.map((item) => (
                                                            <option
                                                                value={item.workItemFieldID}
                                                                selected={item.workItemFieldID == workitemFieldID}
                                                            >
                                                                {item.labelText}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="row align-items-end">
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label className="form-label"> Company Department</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        onChange={FetchLocationClientsByGuid}
                                                        title="Select a Company-Department"
                                                        required
                                                    >
                                                        <option>Select</option>
                                                        {departments.map((department) => (
                                                            <option
                                                                value={
                                                                    department.companyDepartment +
                                                                    "/" +
                                                                    department.departmentIDString
                                                                }
                                                            >
                                                                {department.companyDepartment}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label className="form-label">Location Client</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        onChange={FetchProcessSubProcessByGuid}
                                                        title="Select a Location-Client"
                                                        required
                                                    >
                                                        <option selected>Select</option>
                                                        {clientLocations.map((location) => (
                                                            <option
                                                                value={
                                                                    location.locationClient +
                                                                    "/" +
                                                                    location.clientIDString
                                                                }
                                                            >
                                                                {location.locationClient}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label className="form-label">Process SubProcess</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        onChange={handleSubprocesschange}
                                                        title="Select a Process-SubProcess"
                                                        required
                                                    >
                                                        <option selected>Select</option>
                                                        {processSubprocess.map((subprocess) => (
                                                            <option
                                                                value={subprocess.subProcessIDString}
                                                            >
                                                                {subprocess.processSubProcess}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label className="form-label">Select Vendor Field</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        onChange={handleVendorFieldChange}
                                                        title="Select a Vendor Field which identifies the vendor. Example: VendorName, VendorCode, VendorID, etc."
                                                        required
                                                    >
                                                        <option selected>Select</option>
                                                        {workItemFieldList.map((item) => (
                                                            <option
                                                                value={item.workItemFieldID}
                                                            >
                                                                {item.labelText}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            {isModalTableLoading ? (
                                <LoadingSpinnerFigma />
                            ) : (
                                <div className="tableWrap-body">
                                    <table className="table table-borderless MatTable">

                                        <MaterialTable
                                            actions={[
                                                {
                                                    icon: () => <EditIcon />,
                                                    tooltip: "Edit Vendor Portal Details",
                                                    onClick: (event, rowData) => EditVendorPortalDetails(rowData),
                                                    position: "row",
                                                }
                                            ]}
                                            options={{
                                                maxBodyHeight: "420px",
                                                showTitle: false,
                                                exportButton: false,
                                                filtering: true,
                                                sorting: true,
                                                search: false,
                                                selection: false,
                                                toolbarButtonAlignment: "left",
                                                overflowY: false,
                                                rowStyle: {
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontSize: "13px"
                                                },
                                                tableLayout: "auto",
                                                headerStyle: {
                                                    position: "sticky",
                                                    top: "-5px",
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontWeight: "inherit",
                                                    fontSize: "14px",
                                                    textAlign: "left !Important",
                                                    height: 30
                                                }
                                            }}
                                            title="Vendor Portal User Access List"
                                            columns={Modalcolumns}
                                            icons={tableIcons}
                                            data={userList}
                                            components={{
                                                OverlayLoading: props => <LoadingSpinnerFigma />,
                                                Container: props => (
                                                    <Paper {...props} elevation={0} />
                                                ),
                                                Toolbar: props => (
                                                    <div
                                                        style={{
                                                            backgroundColor: "rgb(206 206 206)",
                                                            fontSize: "0.75em"
                                                        }}
                                                    >
                                                        <MTableToolbar
                                                            {...props}
                                                            style={{ fontSize: "5px" }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </table>
                                </div>)}

                            <div className="col-6 text-start mt-4">
                                {isEdit ? <button type="button"
                                    title="Click here to update configuration"
                                    class="btn btn-sm btn-primary ms-2"
                                    onClick={handleSave}
                                ><b>Update</b></button> :
                                    <button type="button"
                                        title="Click here to save configuration"
                                        class="btn btn-sm btn-primary ms-2"
                                        onClick={handleSave}
                                    ><b>Save</b></button>}
                                {isEdit ? <button type="button"
                                    class="btn btn-sm btn-primary ms-2"
                                    onClick={vendorQueryConfiguration}
                                    title="Click here to configure fields for Vendor Query"
                                ><b>Vendor Query Configuration</b></button> : null}
                                {isEdit ? <button type="button"
                                    class="btn btn-sm btn-primary ms-2"
                                    onClick={paidStatusCriteria}
                                    title="Click here to configure PAID Status Criteria, using this vendor dashboard will show the PAID Invoice Data"
                                ><b>Paid Status Criteria Configuration</b></button> : null}
                                <button type="button"
                                    class="btn btn-sm btn-primary ms-2"
                                    onClick={viewModalClose}
                                    title="Click here to close this modal"
                                ><b>Back</b></button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={editModal}
                    dialogClassName="modal-25w"
                    onHide={EditModalClose}
                    fullscreen={"lg-down"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Vendor Access Configuration</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="pageBody">
                            <div className="dashBoxs mb-3">
                                <div className="formWrap">
                                    <div className="row align-items-end">
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label className="form-label"> Vendor List </label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    title="Select a Company-Department"
                                                    onChange={handleVendorAccessDropdownChange}
                                                    required
                                                >
                                                    <option value="Select">-- Select Vendor Value --</option>
                                                    {vendorPortalDropdownlist.map((item) => (
                                                        <option
                                                            value={item.value}
                                                        >
                                                            {item.value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="mb-3">
                                                <button type="button"
                                                    class="btn btn-sm btn-primary"
                                                    onClick={AddVendor}
                                                    title="Click here to close this modal"
                                                >
                                                    <b>Add</b>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isAccessTableLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <div className="tableWrap-body">
                                    <table className="table table-borderless MatTable">

                                        <MaterialTable
                                            actions={[
                                                {
                                                    icon: () => <DeleteIcon />,
                                                    tooltip: "Click here to remove Vendor",
                                                    onClick: (event, rowData) => DeleteVendor(rowData),
                                                    position: "row",
                                                }
                                            ]}
                                            options={{
                                                maxBodyHeight: "420px",
                                                showTitle: false,
                                                exportButton: false,
                                                filtering: true,
                                                sorting: true,
                                                search: false,
                                                selection: false,
                                                toolbarButtonAlignment: "left",
                                                overflowY: false,
                                                rowStyle: {
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontSize: "13px"
                                                },
                                                tableLayout: "auto",
                                                headerStyle: {
                                                    position: "sticky",
                                                    top: "-5px",
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontWeight: "inherit",
                                                    fontSize: "14px",
                                                    textAlign: "left !Important",
                                                    height: 30
                                                }
                                            }}
                                            title="Vendor Portal Values by Employee"
                                            columns={EditModalcolumns}
                                            icons={tableIcons}
                                            data={vendorPortalValues}
                                            components={{
                                                OverlayLoading: props => <LoadingSpinner />,
                                                Container: props => (
                                                    <Paper {...props} elevation={0} />
                                                ),
                                                Toolbar: props => (
                                                    <div
                                                        style={{
                                                            backgroundColor: "rgb(206 206 206)",
                                                            fontSize: "0.75em"
                                                        }}
                                                    >
                                                        <MTableToolbar
                                                            {...props}
                                                            style={{ fontSize: "5px" }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </table>
                                </div>)}
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={paidStatusModal}
                    dialogClassName="modal-25w"
                    onHide={paidModalClose}
                    fullscreen={"lg-down"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Configuration for PAID Status Vendor Dashboard</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="timeBreak">
                            <ul className="timeBreak-form">
                                <div>Note: Selected Field values will be used to filter PAID status items in vendor Dashboard.</div>
                                {isPaidTableLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <div className="tableWrap-body">
                                        <table className="table table-borderless MatTable">

                                            <MaterialTable
                                                options={{
                                                    maxBodyHeight: "420px",
                                                    showTitle: false,
                                                    exportButton: false,
                                                    filtering: true,
                                                    sorting: true,
                                                    search: false,
                                                    selection: false,
                                                    toolbarButtonAlignment: "left",
                                                    overflowY: false,
                                                    rowStyle: {
                                                        fontFamily: '"Yantramanav", sans-serif',
                                                        fontSize: "13px"
                                                    },
                                                    tableLayout: "auto",
                                                    headerStyle: {
                                                        position: "sticky",
                                                        top: "-5px",
                                                        fontFamily: '"Yantramanav", sans-serif',
                                                        fontWeight: "inherit",
                                                        fontSize: "14px",
                                                        textAlign: "left !Important",
                                                        height: 30
                                                    }
                                                }}
                                                title="Paid Status Criteria"
                                                columns={PaidModalcolumns}
                                                icons={tableIcons}
                                                data={fieldValueforPaidCriteria}
                                                components={{
                                                    OverlayLoading: props => <LoadingSpinner />,
                                                    Container: props => (
                                                        <Paper {...props} elevation={0} />
                                                    ),
                                                    Toolbar: props => (
                                                        <div
                                                            style={{
                                                                backgroundColor: "rgb(206 206 206)",
                                                                fontSize: "0.75em"
                                                            }}
                                                        >
                                                            <MTableToolbar
                                                                {...props}
                                                                style={{ fontSize: "5px" }}
                                                            />
                                                        </div>
                                                    )
                                                }}
                                            />
                                        </table>
                                    </div>)}
                            </ul>
                        </div>
                        <div className="col-6">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary ms-3"
                                title="Save"
                                position="row"
                                onClick={() => handlePaidStatusSave()}
                            >
                                Save
                            </button>
                            <button
                                type="submit"
                                className="btn btn-sm btn-primary ms-3"
                                title="Click here to select all the rows"
                                position="row"
                                onClick={() => handleMarkAll()}
                            >
                                Mark All
                            </button>
                            <button
                                type="submit"
                                className="btn btn-sm btn-primary ms-3"
                                title="Click here to deselect all the rows"
                                position="row"
                                onClick={() => handleUnmarkAll()}
                            >
                                Un-Mark All
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={vendorQueryModal}
                    dialogClassName="modal-25w"
                    onHide={vendorQueryModalClose}
                    fullscreen={"lg-down"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Raise Vendor Query Configuration</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="pageBody">
                            <div className="dashBoxs mb-3">
                                <div className="formWrap">
                                    <div className="row align-items-end">
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label className="form-label">Vendor Query Sub-Process</label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    title="Select a Company-Department"
                                                    onChange={handleVendorQuerySubprocessChange}
                                                    required
                                                >
                                                    <option value="Select">-- Select Process ~ SubProcess --</option>
                                                    {processSubprocess.map((subprocess) => (
                                                        <option
                                                            value={subprocess.subProcessIDString}
                                                            selected={subprocess.subProcessIDString == initiateVendorQueryID}
                                                        >
                                                            {subprocess.processSubProcess}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isQueryTableLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <div className="tableWrap-body">
                                    <table className="table table-borderless MatTable">

                                        <MaterialTable
                                            options={{
                                                maxBodyHeight: "420px",
                                                showTitle: false,
                                                exportButton: false,
                                                filtering: true,
                                                sorting: true,
                                                search: false,
                                                selection: false,
                                                toolbarButtonAlignment: "left",
                                                overflowY: false,
                                                rowStyle: {
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontSize: "13px"
                                                },
                                                tableLayout: "auto",
                                                headerStyle: {
                                                    position: "sticky",
                                                    top: "-5px",
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontWeight: "inherit",
                                                    fontSize: "14px",
                                                    textAlign: "left !Important",
                                                    height: 30
                                                }
                                            }}
                                            title="Vendor Query Configuration"
                                            columns={vendorQueryColumns}
                                            icons={tableIcons}
                                            data={vendorQueryList}
                                            components={{
                                                OverlayLoading: props => <LoadingSpinner />,
                                                Container: props => (
                                                    <Paper {...props} elevation={0} />
                                                ),
                                                Toolbar: props => (
                                                    <div
                                                        style={{
                                                            backgroundColor: "rgb(206 206 206)",
                                                            fontSize: "0.75em"
                                                        }}
                                                    >
                                                        <MTableToolbar
                                                            {...props}
                                                            style={{ fontSize: "5px" }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </table>
                                </div>)}
                        </div>
                        <div className="col-6">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary ms-3"
                                title="Save"
                                position="row"
                                onClick={() => handleVendorQuerySave()}
                            >
                                Save
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>

                <div className="centerSection">
                    <div className="pageBody">
                        <div className="dashBoxs">
                            <div className="dashBoxs-header ">
                                <div className="row align-items-center pb-1">
                                    <div className="col-4">
                                        <h6>Sub-Process Vendor Portal Config List</h6></div>
                                    <div className="col-4">
                                        <div className="d-inline">
                                            <div className="input-group input-group-sm">
                                                <label
                                                    className="input-group-text"
                                                    for="inputGroupSelect01"
                                                >
                                                    Display Criterion
                                                </label>
                                                <select
                                                    className="form-select font-Standard-11"
                                                    id="inputGroupSelect01"
                                                    onChange={DisplayCriteriaOnChange}
                                                    title="Display Criterion"
                                                >
                                                    {(
                                                        <>
                                                            <option value="subprocess">Sub-Process</option>
                                                            <option value="process">Process</option>
                                                            <option value="client">Client</option>
                                                            <option selected value="all">All</option>
                                                        </>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="d-inline">
                                            {(
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary ms-2"
                                                    onClick={() => {
                                                        AddNewVendorPortal();
                                                    }}
                                                    title="Click here to add new Move Workitem Configuration"
                                                >
                                                    <b className="icon-plus"></b> Add New Vendor Portal Configuration
                                                </button>
                                            )}
                                        </div>
                                        <div className="d-inline">
                                            {(
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary ms-2"
                                                    onClick={() => {
                                                        downloadExcel();
                                                    }}
                                                    title="Click here to export this table"
                                                >
                                                    <b></b>Export to Excel
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isTableLoading ? (
                                <LoadingSpinnerFigma />
                            ) : (
                                <div className="tableWrap-body">
                                    <table className="table table-borderless MatTable">

                                        <MaterialTable
                                            actions={[
                                                {
                                                    icon: () => <EditIcon />,
                                                    tooltip: "Click here to edit Sub-Process Vendor Portal configuration",
                                                    onClick: (event, rowData) => EditVendorPortalConfig(rowData),
                                                    position: "row",
                                                }
                                            ]}
                                            options={{
                                                maxBodyHeight: "420px",
                                                showTitle: false,
                                                exportButton: false,
                                                filtering: true,
                                                sorting: true,
                                                search: false,
                                                selection: false,
                                                toolbarButtonAlignment: "left",
                                                overflowY: false,
                                                rowStyle: {
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontSize: "13px"
                                                },
                                                tableLayout: "auto",
                                                headerStyle: {
                                                    position: "sticky",
                                                    top: "-5px",
                                                    fontFamily: '"Yantramanav", sans-serif',
                                                    fontWeight: "inherit",
                                                    fontSize: "14px",
                                                    textAlign: "left !Important",
                                                    height: 30
                                                }
                                            }}
                                            title="Vendor Portal List"
                                            columns={columns}
                                            icons={tableIcons}
                                            data={vendorList}
                                            components={{
                                                OverlayLoading: props => <LoadingSpinnerFigma />,
                                                Container: props => (
                                                    <Paper {...props} elevation={0} />
                                                ),
                                                Toolbar: props => (
                                                    <div
                                                        style={{
                                                            backgroundColor: "rgb(206 206 206)",
                                                            fontSize: "0.75em"
                                                        }}
                                                    >
                                                        <MTableToolbar
                                                            {...props}
                                                            style={{ fontSize: "5px" }}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        />
                                    </table>
                                </div>)}
                        </div>
                    </div>
                </div>
            </>
        </>
    );
}
export default VendorPortalConfig;